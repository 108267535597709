import type { FunctionComponent } from 'react';
import cx from 'classnames';
// eslint-disable-next-line no-restricted-imports
import moment from 'moment';

import { forTemplate } from '@trello/legacy-i18n';
import { WarningIcon } from '@trello/nachos/icons/warning';

import styles from './PowerUpListingOverview.less';

const formatDeprecationMessage = forTemplate('plugin_deprecation');

export interface PowerUpListingOverviewProps {
  overview: string;
  deprecation?: {
    sunsetDate: string | null;
    infoLink: string | null;
  };
  size?: 'medium' | 'small';
}

export const PowerUpListingOverview: FunctionComponent<
  PowerUpListingOverviewProps
> = ({ overview, deprecation, size = 'medium' }) => {
  const { sunsetDate, infoLink } = deprecation ?? {};

  if (!sunsetDate) {
    return (
      <span
        className={cx({
          [styles.clampedOverview]: size === 'medium',
          [styles.clampedOverviewSmall]: size === 'small',
        })}
      >
        {overview}
      </span>
    );
  }

  const message = formatDeprecationMessage('plugin-overview', {
    sunsetDate: moment(sunsetDate).utc().subtract(1, 'day').format('LL'),
  });

  return (
    <span
      className={cx({
        [styles.overview]: size === 'medium',
        [styles.overviewSmall]: size === 'small',
      })}
    >
      <WarningIcon size="small" />
      <span>
        {message}
        {infoLink && (
          <>
            {' '}
            <a target="_blank" href={infoLink}>
              {formatDeprecationMessage('learn-more')}
            </a>
          </>
        )}
      </span>
    </span>
  );
};
