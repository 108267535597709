/* eslint-disable
    eqeqeq,
*/
// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS205: Consider reworking code to avoid use of IIFEs
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import { isOverLimit } from 'app/scripts/lib/limits';
import { limitErrorPopover } from 'app/scripts/views/lib/limitErrorPopover';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const canAddCard = function (options: any) {
  const { destinationBoard, destinationList } = options;

  return (
    !(destinationBoard != null
      ? isOverLimit('cards', 'openPerBoard', destinationBoard.get('limits'))
      : undefined) &&
    !(destinationBoard != null
      ? isOverLimit('cards', 'totalPerBoard', destinationBoard.get('limits'))
      : undefined) &&
    !(destinationList != null
      ? isOverLimit('cards', 'openPerList', destinationList.get('limits'))
      : undefined) &&
    !(destinationList != null
      ? isOverLimit('cards', 'totalPerList', destinationList.get('limits'))
      : undefined) &&
    !(destinationBoard != null
      ? isOverLimit('attachments', 'perBoard', destinationBoard.get('limits'))
      : undefined) &&
    !(destinationBoard != null
      ? isOverLimit('checklists', 'perBoard', destinationBoard.get('limits'))
      : undefined)
  );
};

const trackingArea = 'cardLimit';
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const maybeDisplayLimitsErrorOnCardAdd = function (options: any) {
  const {
    $elem,
    hasChecklists,
    hasAttachments,
    destinationBoard,
    destinationList,
  } = options;

  const message = (() => {
    if (
      destinationBoard != null
        ? isOverLimit('cards', 'openPerBoard', destinationBoard.get('limits'))
        : undefined
    ) {
      return 'card exceeds open per board limit';
    } else if (
      destinationBoard != null
        ? isOverLimit('cards', 'totalPerBoard', destinationBoard.get('limits'))
        : undefined
    ) {
      return 'card exceeds total per board limit';
    } else if (
      destinationList != null
        ? isOverLimit('cards', 'openPerList', destinationList.get('limits'))
        : undefined
    ) {
      return 'card exceeds open per list limit';
    } else if (
      destinationList != null
        ? isOverLimit('cards', 'totalPerList', destinationList.get('limits'))
        : undefined
    ) {
      return 'card exceeds total per list limit';
    } else if (
      hasAttachments &&
      (destinationBoard != null
        ? isOverLimit('attachments', 'perBoard', destinationBoard.get('limits'))
        : undefined)
    ) {
      return 'card exceeds attachments per board limit';
    } else if (
      hasChecklists &&
      (destinationBoard != null
        ? isOverLimit('checklists', 'perBoard', destinationBoard.get('limits'))
        : undefined)
    ) {
      return 'card exceeds checklists per board limit';
    }
  })();

  if (message != null) {
    limitErrorPopover({
      elem: $elem,
      message,
      trackingArea,
      params: {
        boardName: destinationBoard ? destinationBoard.get('name') : undefined,
        boardId: destinationBoard ? destinationBoard.get('id') : undefined,
        listName: destinationList ? destinationList.get('name') : undefined,
      },
    });
    return true;
  }
  return false;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const maybeDisplayLimitsErrorOnCardOpen = function (options: any) {
  const { $elem, hasAttachments, destinationBoard, destinationList } = options;

  const message = (() => {
    if (
      destinationBoard != null
        ? isOverLimit('cards', 'openPerBoard', destinationBoard.get('limits'))
        : undefined
    ) {
      return 'card exceeds open per board limit';
    } else if (
      destinationList != null
        ? isOverLimit('cards', 'openPerList', destinationList.get('limits'))
        : undefined
    ) {
      return 'card exceeds open per list limit';
    } else if (
      hasAttachments &&
      (destinationBoard != null
        ? isOverLimit('attachments', 'perBoard', destinationBoard.get('limits'))
        : undefined)
    ) {
      return 'card exceeds attachments per board limit';
    }
  })();

  if (message != null) {
    limitErrorPopover({
      trackingArea,
      elem: $elem,
      message,
      params: {
        boardName:
          destinationBoard != null ? destinationBoard.get('name') : undefined,
        boardId:
          destinationBoard != null ? destinationBoard.get('id') : undefined,
        listName:
          destinationList != null ? destinationList.get('name') : undefined,
      },
    });
    return true;
  }
  return false;
};

export { canAddCard };
export { maybeDisplayLimitsErrorOnCardAdd };
export { maybeDisplayLimitsErrorOnCardOpen };
