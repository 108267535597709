/* eslint-disable @trello/disallow-filenames */
interface IconProps {
  url: string;
}

export interface HeroImageUrl {
  '@1x': string;
  '@2x': string;
}

export interface BadgeProps {
  button?: JSX.Element;
  usage?: number;
  darkMode?: boolean;
  staffPick: boolean;
  integration: boolean;
  hideInstallCues?: boolean;
}

export interface AtomProps {
  button?: JSX.Element;
  className?: string;
  icon: IconProps;
  name: string;
  subtitle?: string;
  usage?: number;
  darkMode?: boolean;
  staffPick: boolean;
  integration: boolean;
  deprecation?: {
    sunsetDate: string | null;
    infoLink: string | null;
  };
}

export interface DescriptionAtomProps extends AtomProps {
  overview: string;
}

export interface FeaturedAtomProps extends DescriptionAtomProps {
  heroImageUrl: HeroImageUrl | undefined | null;
}

export type ListingAtomProps = AtomProps;

export type PowerUpAtomProps =
  | AtomProps
  | DescriptionAtomProps
  | FeaturedAtomProps;

// eslint-disable-next-line no-restricted-syntax
export enum PowerUpItemType {
  Basic = 0,
  Description = 1,
  Featured = 2,
  Listing = 3,
  Integration = 4,
}
