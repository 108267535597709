import type { FunctionComponent, MouseEvent as ReactMouseEvent } from 'react';
import classnames from 'classnames';

import { Analytics } from '@trello/atlassian-analytics';
import { forTemplate } from '@trello/legacy-i18n';
import { TrelloMarkdown } from '@trello/markdown';
import { Button } from '@trello/nachos/button';
import type { PowerUpTestIds } from '@trello/test-ids';
import { getTestId } from '@trello/test-ids';
import { makeSlug } from '@trello/urls';

import { PowerUp, PowerUpItemType } from 'app/src/components/PowerUp';
import type { AtomProps, HeroImageUrl } from 'app/src/components/PowerUp/types';

import styles from './PowerUpItem.less';
const format = forTemplate('directory_power_up_item');

import {
  gmailId,
  gmailUrl,
  microsoftTeamsId,
  microsoftTeamsUrl,
} from '@trello/config';
import { navigate } from '@trello/router/navigate';
import { RouterLink } from '@trello/router/router-link';

import type { Plugin } from './types';

interface PowerUpItemProps {
  readonly type: PowerUpItemType;
  readonly plugin: Plugin;
}

// eslint-disable-next-line @trello/no-module-logic
const markdown = new TrelloMarkdown();

const getDescription = (plugin: Plugin): string => {
  const { listing } = plugin;
  const overview = listing ? listing.overview : '';
  const description = listing ? listing.description : '';

  return markdown.text(overview || description || '').output;
};

const getPluginTrackingName = (plugin: Plugin) => {
  // We only want to include public power-up names in
  // GAS events to avoid UGC violations for private power-ups
  const pluginName = plugin.listing ? plugin.listing.name : '';
  return plugin.public ? pluginName : undefined;
};

const navigateToListing = (
  e: ReactMouseEvent<Element>,
  props: PowerUpItemProps,
) => {
  if ((e.target as HTMLElement).tagName !== 'BUTTON') {
    const { plugin, type } = props;
    const pluginName = plugin.listing ? plugin.listing.name : '';
    if (type !== PowerUpItemType.Basic) {
      Analytics.sendUIEvent({
        action: 'clicked',
        actionSubject: 'card',
        actionSubjectId: 'powerUpCard',
        objectType: 'powerUp',
        objectId: plugin.id,
        source: 'publicDirectoryScreen',
        attributes: {
          powerUpName: plugin.public ? pluginName : undefined,
          tags: plugin.tags,
        },
      });

      navigate(`/power-ups/${plugin.id}/${makeSlug(pluginName)}`, {
        trigger: true,
      });
    }
  }
};

const AddButton: FunctionComponent<PowerUpItemProps> = ({ plugin }) => {
  return (
    <RouterLink
      onClick={(e: ReactMouseEvent<HTMLAnchorElement>) => {
        Analytics.sendClickedButtonEvent({
          buttonName: 'powerUpAddButton',
          source: 'powerUpCard',
          attributes: {
            powerUpId: plugin.id,
            powerUpName: getPluginTrackingName(plugin),
            isPowerUpPublic: plugin.public,
            idOrganizationOwner: plugin.idOrganizationOwner,
            tags: plugin.tags,
          },
        });
      }}
      href={`/power-ups/${plugin.id}/enable`}
      className={styles.buttonAnchor}
    >
      <Button
        appearance="primary"
        className={styles.addButton}
        data-testid={getTestId<PowerUpTestIds>('pup-add-button')}
      >
        {format('add')}
      </Button>
    </RouterLink>
  );
};

const ConfigureButton: FunctionComponent<PowerUpItemProps> = ({ plugin }) => {
  const pluginName = plugin.listing?.name ?? '';

  let configureUrl;
  if (plugin.id === microsoftTeamsId) {
    configureUrl = microsoftTeamsUrl;
  } else if (plugin.id === gmailId) {
    configureUrl = gmailUrl;
  } else {
    return <></>;
  }

  return (
    <RouterLink
      onClick={(e: ReactMouseEvent<HTMLAnchorElement>) => {
        Analytics.sendClickedButtonEvent({
          buttonName: 'powerUpAddButton',
          objectType: 'powerUp',
          objectId: plugin.id,
          source: 'powerUpCard',
          attributes: {
            powerUpName: plugin.public ? pluginName : undefined,
            tags: plugin.tags,
          },
        });
      }}
      href={configureUrl}
      className={styles.buttonAnchor}
      target="_blank"
    >
      <Button appearance="primary" className={styles.addButton}>
        {format('configure')}
      </Button>
    </RouterLink>
  );
};

export const PowerUpItem: FunctionComponent<PowerUpItemProps> = (props) => {
  const { plugin, type } = props;
  const { icon, listing, deprecation } = plugin;
  let heroImageUrl = plugin.heroImageUrl as HeroImageUrl | undefined | null;

  if (plugin.heroImageUrl && typeof plugin.heroImageUrl === 'string') {
    heroImageUrl = JSON.parse(plugin.heroImageUrl);
  }

  const containerClassNames = classnames(styles.powerUpItem, {
    [styles.listingPowerUpItem]: type === PowerUpItemType.Basic,
  });

  const isIntegration = (plugin.tags || []).includes('integration');

  return (
    <div
      className={containerClassNames}
      onClick={(e) => navigateToListing(e, props)}
      role="presentation"
    >
      <PowerUp
        type={type}
        atomProps={{
          overview: getDescription(plugin),
          icon,
          heroImageUrl,
          name: listing ? listing.name : '',
          darkMode: (plugin.tags || []).includes('darkMode'),
          staffPick: (plugin.tags || []).includes('staff-pick'),
          usage: plugin.usageBrackets?.boards || 0,
          deprecation: deprecation as AtomProps['deprecation'],
          button: isIntegration ? (
            <ConfigureButton {...props} />
          ) : (
            <AddButton {...props} />
          ),
          integration: isIntegration,
        }}
      />
    </div>
  );
};
