import * as Types from '@trello/graphql/generated';

import DocumentNode from '@trello/graphql/src/documentNode';
import * as Apollo from '@apollo/client';
export type BoardPowerUpsContextBoardPluginsAndEnterpriseFragment = (
  { __typename: 'Board' }
  & Pick<Types.Board, 'id'>
  & { boardPlugins: Array<(
    { __typename: 'BoardPlugin' }
    & Pick<Types.BoardPlugin, 'id' | 'idPlugin'>
  )>, enterprise?: Types.Maybe<(
    { __typename: 'Enterprise' }
    & Pick<Types.Enterprise, 'id' | 'idPluginsAllowed' | 'pluginWhitelistingEnabled'>
  )> }
);

export const BoardPowerUpsContextBoardPluginsAndEnterpriseFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: {
        kind: 'Name',
        value: 'BoardPowerUpsContextBoardPluginsAndEnterprise',
      },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Board' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'boardPlugins' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'idPlugin' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'enterprise' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'idPluginsAllowed' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'pluginWhitelistingEnabled' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

interface UseBoardPowerUpsContextBoardPluginsAndEnterpriseFragmentOptions
  extends Omit<
    Apollo.UseFragmentOptions<
      BoardPowerUpsContextBoardPluginsAndEnterpriseFragment,
      Apollo.OperationVariables
    >,
    'fragment' | 'fragmentName' | 'from'
  > {
  from: Apollo.StoreObject | Apollo.Reference;
  returnPartialData?: boolean;
}

interface UseBoardPowerUpsContextBoardPluginsAndEnterpriseFragmentResult
  extends Omit<
    Apollo.UseFragmentResult<BoardPowerUpsContextBoardPluginsAndEnterpriseFragment>,
    'data'
  > {
  data?: BoardPowerUpsContextBoardPluginsAndEnterpriseFragment;
}

export const useBoardPowerUpsContextBoardPluginsAndEnterpriseFragment = ({
  from,
  returnPartialData,
  ...options
}: UseBoardPowerUpsContextBoardPluginsAndEnterpriseFragmentOptions): UseBoardPowerUpsContextBoardPluginsAndEnterpriseFragmentResult => {
  const result =
    Apollo.useFragment<BoardPowerUpsContextBoardPluginsAndEnterpriseFragment>({
      ...options,
      fragment: BoardPowerUpsContextBoardPluginsAndEnterpriseFragmentDoc,
      fragmentName: 'BoardPowerUpsContextBoardPluginsAndEnterprise',
      from: {
        __typename: 'Board',
        ...from,
      },
    });

  // Ensure that the fragment result is not typed as a DeepPartial.
  if (!result.complete && !returnPartialData) {
    return {
      ...result,
      data: undefined,
    };
  }

  return {
    ...result,
    data: result.data as BoardPowerUpsContextBoardPluginsAndEnterpriseFragment,
  };
};
