import { toQueryStringWithDecodedFilterParams } from 'app/src/components/ViewFilters/toQueryStringWithDecodedFilterParams';

const QUERY_PARAMS_TO_KEEP = new Set(['filter', 'search_id']);

/**
 * Explicitly allowlists query parameters to keep when navigating to a board
 * view. Historically, the logic in display-board would clear out all query
 * params on page load. Ideally, this should actually be a blocklist, since most
 * query parameters should be safe to keep, but without an audit of all of the
 * query parameters that are in use today, this allowlist lets us keep what we
 * need with minimal blast radius.
 */
export const getQueryParamsToKeepOnBoardView = () => {
  const params = new URLSearchParams(location.search);
  const newParams = new URLSearchParams();
  for (const [key, value] of params) {
    if (QUERY_PARAMS_TO_KEEP.has(key)) {
      newParams.set(key, value);
    }
  }
  const result = toQueryStringWithDecodedFilterParams(newParams);
  return result.length ? `?${result}` : '';
};
