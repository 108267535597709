// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sMt3DKNBvvmWUK {
  display: inline-flex;
  position: relative;
  box-sizing: border-box;
  padding: 6px 12px 6px 6px;
  overflow: hidden;
  border-radius: 3px;
  text-decoration: none;
}
.XYjStM9I7RGci6 {
  background: var(--ds-background-discovery, #f3f0ff);
}
.XYjStM9I7RGci6:hover {
  background: var(--ds-background-discovery-hovered, #dfd8fd);
}
.XYjStM9I7RGci6 .xs7aFVnA8mbGSf {
  background: var(--ds-background-discovery-bold, #6e5dc6);
}
.XYjStM9I7RGci6 .DJOT9NJk3uLduo svg {
  color: var(--ds-icon-inverse, #ffffff);
}
.XYjStM9I7RGci6 .ZjMnnQdEcd0Z3j {
  color: var(--ds-text-subtle, #44546f);
}
.e2jB7ekI1hapWn {
  background: var(--ds-background-discovery-bold, #6e5dc6);
}
.e2jB7ekI1hapWn:hover {
  background: var(--ds-background-discovery-bold-hovered, #583f68);
}
.e2jB7ekI1hapWn:hover .DJOT9NJk3uLduo svg {
  color: var(--ds-icon-discovery, #583f68);
}
.e2jB7ekI1hapWn .xs7aFVnA8mbGSf {
  background: var(--ds-surface-raised, #ffffff);
}
.e2jB7ekI1hapWn .DJOT9NJk3uLduo svg {
  color: var(--ds-icon-discovery, #8270db);
}
.e2jB7ekI1hapWn .ZjMnnQdEcd0Z3j {
  color: var(--ds-text-inverse, #ffffff);
}
.eWgfM9rknLBp67 {
  margin: 0;
  background: linear-gradient(97.78deg, var(--ds-background-accent-purple-bolder, #4423a3) 17.5%, var(--ds-background-accent-magenta-subtle, #aa63cb) 113.39%);
}
.eWgfM9rknLBp67:hover {
  background: linear-gradient(270deg, var(--ds-background-accent-purple-bolder, #403294) 0%, var(--ds-background-neutral-bold-pressed, #172b4d) 100%);
}
.eWgfM9rknLBp67 .DJOT9NJk3uLduo svg {
  color: var(--ds-icon-inverse, #ffffff);
}
.eWgfM9rknLBp67 .ZjMnnQdEcd0Z3j {
  color: var(--ds-text-inverse, #ffffff);
}
.xs7aFVnA8mbGSf {
  width: 20px;
  height: 20px;
  margin-right: 8px;
  border-radius: 2px;
}
.QAjRiuEhW0ebT8 {
  display: flex;
  width: 100%;
}
.k4GIhtiqopyWlH {
  justify-content: center;
}
.r_hNkwOaj7BLGW {
  justify-content: left;
}
.DJOT9NJk3uLduo {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 20px;
}
`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"upgradePromptButton": `sMt3DKNBvvmWUK`,
	"upgradePromptButtonLightTheme": `XYjStM9I7RGci6`,
	"upgradePromptButtonIconBackground": `xs7aFVnA8mbGSf`,
	"upgradePromptButtonIcon": `DJOT9NJk3uLduo`,
	"upgradePromptButtonCTA": `ZjMnnQdEcd0Z3j`,
	"upgradePromptButtonDarkTheme": `e2jB7ekI1hapWn`,
	"upgradePromptButtonGradientTheme": `eWgfM9rknLBp67`,
	"upgradePromptFitContainer": `QAjRiuEhW0ebT8`,
	"upgradePromptJustifyCenter": `k4GIhtiqopyWlH`,
	"upgradePromptJustifyLeft": `r_hNkwOaj7BLGW`
};
export default ___CSS_LOADER_EXPORT___;
