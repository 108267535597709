import type { FunctionComponent } from 'react';
import { startTransition, useEffect, useMemo, useState } from 'react';

import { dontUpsell } from '@trello/browser';
import { PremiumFeatures } from '@trello/entitlements';
import {
  ChunkLoadErrorBoundary,
  ErrorBoundary,
} from '@trello/error-boundaries';
import { intl } from '@trello/i18n';
import { useBoardId, useWorkspaceId } from '@trello/id-context';
import {
  PopoverSection,
  usePersistentCollapsiblePopoverSection,
} from '@trello/nachos/experimental-popover-section';
import { PopoverMenuItemSeparator } from '@trello/nachos/popover-menu';
import { DeferredSuspense, useLazyComponent } from '@trello/use-lazy-component';

import { useBoardPremiumFeaturesFragment } from 'app/src/components/FileSizeAlert/BoardPremiumFeaturesFragment.generated';
import {
  MaybePremiumLozenge,
  useFreeTrialEligibilityRules,
} from 'app/src/components/FreeTrial';
import { ListColorPickerSkeleton } from './ListColorPickerSkeleton';
import { ListColorPickerUpgradePrompt } from './ListColorPickerUpgradePrompt';

import styles from './LazyListColorPicker.less';

interface LazyListColorPickerProps {
  onHide?: () => void;
}

export const LazyListColorPicker: FunctionComponent<
  LazyListColorPickerProps
> = ({ onHide }) => {
  const boardId = useBoardId();
  const workspaceId = useWorkspaceId();

  const { data } = useBoardPremiumFeaturesFragment({ from: { id: boardId } });
  const hasPremiumFeature = useMemo(
    () => data?.premiumFeatures.includes(PremiumFeatures.listColors),
    [data?.premiumFeatures],
  );

  const { isCollapsed, onToggleCollapsed } =
    usePersistentCollapsiblePopoverSection('listActionsPopoverColorPicker');

  // Defer rendering the lazy color picker behind a non-blocking transition:
  const [shouldRenderContent, setShouldRenderContent] = useState(false);
  useEffect(() => {
    if (!shouldRenderContent && !isCollapsed) {
      startTransition(() => setShouldRenderContent(true));
    }
  }, [isCollapsed, shouldRenderContent]);

  const ListColorPicker = useLazyComponent(
    () =>
      import(/* webpackChunkName: "list-color-picker" */ './ListColorPicker'),
    {
      namedImport: 'ListColorPicker',
      preload: hasPremiumFeature && shouldRenderContent,
    },
  );

  const { isEligible } = useFreeTrialEligibilityRules(workspaceId ?? undefined);
  if (!hasPremiumFeature && (dontUpsell() || !isEligible)) {
    return null;
  }

  return (
    <ErrorBoundary
      tags={{
        ownershipArea: 'trello-web-eng',
        feature: 'List Color Picker',
      }}
    >
      <PopoverMenuItemSeparator fullWidth={true} />

      <PopoverSection
        title={
          <div className={styles.popoverTitle}>
            {intl.formatMessage({
              id: 'templates.list_menu.change-list-color',
              defaultMessage: 'Change list color',
              description: 'Header for the list color picker section',
            })}
            <MaybePremiumLozenge
              className={styles.premiumLozenge}
              featureHighlighted="list-color-picker"
            />
          </div>
        }
        isCollapsible={true}
        isCollapsed={isCollapsed}
        onToggleCollapsed={onToggleCollapsed}
      >
        <ChunkLoadErrorBoundary fallback={null}>
          <DeferredSuspense fallback={<ListColorPickerSkeleton />}>
            {hasPremiumFeature ? (
              <ListColorPicker />
            ) : (
              <ListColorPickerUpgradePrompt onClickCta={onHide} />
            )}
          </DeferredSuspense>
        </ChunkLoadErrorBoundary>
      </PopoverSection>
    </ErrorBoundary>
  );
};
