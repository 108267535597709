import { createContext } from 'react';

import type { EventContainer, SourceType } from '@trello/atlassian-analytics';

export interface SmartLinkAnalyticsContextType {
  source: SourceType;
  attributes?: {
    fromSection?: string;
    component?: string;
  };
  containers?: EventContainer;
}

const defaultContext: SmartLinkAnalyticsContextType = {
  source: 'unknown',
};

export const SmartLinkAnalyticsContext =
  createContext<SmartLinkAnalyticsContextType>(defaultContext);
