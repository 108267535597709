// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AEUpkLfBcFBRBx {
  display: block;
  text-align: center;
}
.AEUpkLfBcFBRBx h4 {
  margin: 20px 16px 24px;
  font-size: 20px;
}
.AEUpkLfBcFBRBx p {
  margin: 0 0 8px;
  font-size: 16px;
}
`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"errorMessage": `AEUpkLfBcFBRBx`
};
export default ___CSS_LOADER_EXPORT___;
