/* eslint-disable
    @typescript-eslint/no-unused-vars,
*/
// @ts-expect-error TS(6133): 'Promise' is declared but its value is never read.
import Promise from 'bluebird';
import Hearsay from 'hearsay';

import { sandboxParams } from 'app/scripts/data/plugin-iframe-sandbox';
import { teacupWithHelpers } from 'app/scripts/views/internal/teacupWithHelpers';
import { PluginView } from 'app/scripts/views/plugin/PluginView';
const t = teacupWithHelpers();

// eslint-disable-next-line @trello/no-module-logic, @typescript-eslint/no-explicit-any
const template = t.renderable(function ({ content }: any) {
  t.iframe('.plugin-iframe', {
    allow: 'microphone; camera',
    sandbox: sandboxParams,
    src: content.url,
  });
  t.div('.plugin-iframe-popover-overlay');
});

interface PluginViewView {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  content: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  filterSlot: any;
}

class PluginViewView extends PluginView {
  static initClass() {
    // @ts-expect-error TS(2322): Type 'string' is not assignable to type '() => str... Remove this comment to see the full error message
    this.prototype.className = 'plugin-view js-plugin-iframe-container';
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  initialize({ content }: any) {
    this.content = content;

    this.filterSlot = new Hearsay.Slot(null);

    this.listenTo(this.model.filter, 'change', () => {
      this.filterSlot.set(new Date());
    });

    return this.retain(this.content);
  }

  renderOnce() {
    this.$el.html(template({ content: this.content }));
    this.initIFrames(this.model, undefined, this.filterSlot);
    return this;
  }
}
PluginViewView.initClass();
export { PluginViewView };
