import type { ModelCache } from 'app/scripts/db/ModelCache';
import type { ActionAttributes } from '../Action';

type ActionData = ActionAttributes['display'];

type MemberEntity = NonNullable<ActionData['entities']['member']>;

const getMemberEntityWithName = ({
  entity: rawEntity,
  modelCache,
}: {
  entity: MemberEntity;
  modelCache: typeof ModelCache;
}): MemberEntity => {
  const entity = { ...rawEntity } as MemberEntity;
  const memberModel = modelCache.get('Member', entity.id);
  /* TRELP-3056
   * It's possible that the server provides us with a member in the
   * entities but no extra information about that member so the only
   * details we have are those in the entity. There are lots of code
   * that assumes that a member entity will be in the ModelCache and
   * does things like show a popover. The truth however is if we can't
   * find a member in the ModelCache this code used to fail and lot's
   * of other code fails too because of the assumption on data existence.
   * The fix for this is to not assume a member will exist in the cache,
   * furthermore if we can't find a member in the cache we should change
   * the type on this entity for rendering to be a text type so that the
   * UI will just output the value and not treat it in any special manner.
   */
  if (!memberModel) {
    entity.type = 'text';
  } else {
    entity.text = memberModel.get('fullName') || entity.text;
  }

  return entity;
};

// This does preprocessing on the entities, specifically member entities, to
// include any nonPublic data we may have available
export const getDisplayWithMemberDetails = ({
  display,
  modelCache,
}: {
  display?: ActionData;
  modelCache: typeof ModelCache;
}): ActionData | null => {
  if (!display) {
    return null;
  }
  const memberEntities = Object.entries(display.entities).filter(
    ([, entity]) => entity.type === 'member',
  );
  if (memberEntities.length === 0) {
    // shallow clone
    return { ...display } as ActionData;
  }
  const updatedMemberEntities: [string, MemberEntity][] = memberEntities.map(
    ([key, entity]) => {
      // @ts-expect-error We need to improve types of ActionData.entities
      return [key, getMemberEntityWithName({ entity, modelCache })];
    },
  );
  return {
    ...display,
    entities: {
      ...display.entities,
      // More backward compatible version of ...Object.fromEntries(updatedMemberEntities),
      ...updatedMemberEntities.reduce(
        (acc, [key, value]) => Object.assign(acc, { [key]: value }),
        {},
      ),
    },
  } as ActionData;
};
