import type { FunctionComponent } from 'react';
import { Suspense } from 'react';

import { ChunkLoadErrorBoundary } from '@trello/error-boundaries';
import { useLazyComponent } from '@trello/use-lazy-component';

import { useNativeGraphqlMigrationMilestone1 } from 'app/src/components/App/useNativeGraphqlMigrationMilestone1';
import type { BoardHeaderProps } from './BoardHeader';

export const LazyBoardHeader: FunctionComponent<BoardHeaderProps> = (props) => {
  const shouldUseNativeGraphQL = useNativeGraphqlMigrationMilestone1();

  const BoardHeader = useLazyComponent(
    () => import(/* webpackChunkName: "board-header" */ './BoardHeader'),
    { namedImport: 'BoardHeader' },
  );

  const LegacyBoardHeader = useLazyComponent(
    () => import(/* webpackChunkName: "board-header" */ './LegacyBoardHeader'),
    { namedImport: 'LegacyBoardHeader' },
  );

  return (
    <ChunkLoadErrorBoundary fallback={null}>
      <Suspense fallback={null}>
        {shouldUseNativeGraphQL ? (
          <BoardHeader {...props} />
        ) : (
          <LegacyBoardHeader {...props} />
        )}
      </Suspense>
    </ChunkLoadErrorBoundary>
  );
};
