import type { Emoji, EmojiMartData } from '@emoji-mart/data';

import { clientVersion } from '@trello/config';
import type { EmojiMartIndexEmoji, EmojiMartSkinNumber } from '@trello/emoji';
import { sendNetworkErrorEvent } from '@trello/error-reporting';
import { trelloFetch } from '@trello/fetch';
import { currentLocale } from '@trello/locale';
import { getNetworkClient } from '@trello/network-client';
import { importWithRetry } from '@trello/use-lazy-component';

interface EmojiDataResponseSkinVaration {
  unified: string;
  native: string;
  sheetX: number;
  sheetY: number;
}

interface EmojiDataResponse {
  spriteSheets?: {
    twitter: {
      [key in '16' | '20' | '32' | '64']: {
        url: string;
      };
    };
  };
  trello: {
    category: string;
    keywords?: string[];
    name: string | null;
    native: string;
    sheetX: number;
    sheetY: number;
    shortName: string;
    shortNames: string[];
    skinVariations?: {
      [key: string]: EmojiDataResponseSkinVaration;
    };
    text: string | null;
    texts: string[] | null;
    tts?: string;
    unified: string;
  }[];
}

const SKINS = ['1F3FB', '1F3FC', '1F3FD', '1F3FE', '1F3FF'];
const SHORTCODES_REGEX = /^(?::([^:]+):)(?::skin-tone-(\d):)?$/;

// Adapted from EmojiMart build.js
function unifiedToNative(unified: string) {
  const unicodes = unified.split('-');
  const codePoints = unicodes.map((u) => `0x${u}`);

  // @ts-expect-error
  return String.fromCodePoint(...codePoints);
}

class EmojiProvider {
  categories = [
    ['Smileys & People', 'people'],
    ['Animals & Nature', 'nature'],
    ['Food & Drink', 'foods'],
    ['Activities', 'activity'],
    ['Travel & Places', 'places'],
    ['Objects', 'objects'],
    ['Symbols', 'symbols'],
    ['Flags', 'flags'],
  ];
  defaultEmojiSet = 'twitter'; // we override twitter with trello's emojis

  private data: EmojiMartData | null = null;
  private spriteSheets: EmojiDataResponse['spriteSheets'];
  private fetching: null | Promise<EmojiMartData | null> = null;

  private async doFetchOnce() {
    if (!this.data && !this.fetching) {
      this.fetching = this.fetchEmoji();
    }

    return this.fetching;
  }

  private async fetchEmoji() {
    try {
      const queryParams = new URLSearchParams({
        spritesheets: 'true',
        locale: currentLocale,
      });
      const networkClient = getNetworkClient();
      const apiUrl = networkClient.getUrl(`/1/emoji?${queryParams.toString()}`);
      const response = await trelloFetch(apiUrl, undefined, {
        clientVersion,
        networkRequestEventAttributes: {
          source: 'trelloFetch',
          operationName: 'fetchEmoji',
        },
      });

      if (!response.ok) {
        sendNetworkErrorEvent({
          url: apiUrl,
          response: await response.clone().text(),
          status: response.status,
          operationName: 'fetchEmoji',
        });
      }

      const emojis = await response.json();
      const { init } = await importWithRetry(
        () => import(/* webpackChunkName: "emoji-mart" */ 'emoji-mart'),
      );

      this.data = this.buildEmojiMartData(emojis);

      // EmojiMart's init modifies data in place
      await init({ data: this.data });

      return this.data;
    } catch (e) {
      console.error(e);
      return null;
    }
  }

  private formatName(name: string) {
    // This is a list of words that should not be capitalized for title case.
    const nonTitlecasedWords = [
      'and',
      'or',
      'nor',
      'a',
      'an',
      'the',
      'so',
      'but',
      'to',
      'of',
      'at',
      'by',
      'from',
      'into',
      'on',
      'onto',
      'off',
      'out',
      'in',
      'over',
      'with',
      'for',
    ];

    return name
      .toLowerCase()
      .replace(/[-_]/g, ' ')
      .split(' ')
      .map((word) =>
        nonTitlecasedWords.includes(word)
          ? word
          : word.replace(/\b\w/g, (l) => l.toUpperCase()),
      )
      .join(' ');
  }

  // Adapted from Emoji-Marts build process
  // @emoji-mart/data/build.js
  private buildEmojiMartData(emojiSource: EmojiDataResponse) {
    const categoriesIndex: { [key: string]: number } = {};
    const data: EmojiMartData = {
      categories: [],
      emojis: {},
      aliases: {},
      sheet: { cols: 52, rows: 52 },
    };

    this.categories.forEach((category, i) => {
      const [name, id] = category;
      data.categories[i] = { id, emojis: [] };
      categoriesIndex[name] = i;
    });

    emojiSource.trello.forEach((datum) => {
      const unified = datum.unified.toLowerCase();
      const native = unifiedToNative(unified);
      const name = this.formatName(datum.name || datum.shortName);

      const ids = datum.shortNames || [];
      if (ids.indexOf(datum.shortName) === -1) {
        ids.unshift(datum.shortName);
      }

      for (const id of ids) {
        if (id === ids[0]) continue;
        data.aliases[id] = ids[0];
      }

      const id = ids[0];

      const emoticons = datum.texts || [];
      if (datum.text && emoticons.indexOf(datum.text) === -1) {
        emoticons.unshift(datum.text);
      }

      if (id === 'expressionless') {
        if (emoticons.indexOf('-_-') === -1) {
          emoticons.push('-_-');
        }
      }

      const keywords = datum.keywords || [];
      const skins = [{ unified, native, x: datum.sheetX, y: datum.sheetY }];

      if (datum.skinVariations) {
        for (const skin of SKINS) {
          const skinDatum = datum.skinVariations[skin];

          // eslint-disable-next-line @typescript-eslint/no-shadow
          const unified = skinDatum.unified.toLowerCase();
          // eslint-disable-next-line @typescript-eslint/no-shadow
          const native = unifiedToNative(skinDatum.unified);

          skins.push({
            unified,
            native,
            x: skinDatum.sheetX,
            y: skinDatum.sheetY,
          });
        }
      }

      const emoji: Emoji = {
        id,
        name,
        emoticons,
        keywords,
        skins,
        version: 1,
      };

      if (!emoji.emoticons?.length) {
        delete emoji.emoticons;
      }

      if (datum.category !== 'Skin Tones') {
        const categoryIndex = categoriesIndex[datum.category];
        data.categories[categoryIndex].emojis.push(emoji.id);
        data.emojis[emoji.id] = emoji;
      }
    });

    const flags = data.categories[categoriesIndex['Flags']];
    flags.emojis = flags.emojis.sort();

    this.data = data;
    this.spriteSheets = emojiSource.spriteSheets;

    return data;
  }

  getData() {
    return this.doFetchOnce();
  }

  getDataSync() {
    return this.data;
  }

  getEmojiData(shortCodes: string) {
    return this.doFetchOnce().then(() => this.getEmojiDataSync(shortCodes));
  }

  getEmojiDataSync(shortCodes: string) {
    const matches = shortCodes.match(SHORTCODES_REGEX);
    let emojiId = shortCodes;
    let skinNumber: EmojiMartSkinNumber = 1;

    if (matches) {
      emojiId = matches[1];

      if (matches[2]) {
        skinNumber = parseInt(matches[2], 10) as EmojiMartSkinNumber;
      }
    }

    const emoji = this.data?.emojis[emojiId] as EmojiMartIndexEmoji;
    if (!emoji) {
      return undefined;
    }

    const emojiSkin = emoji.skins[skinNumber - 1];
    const label = [emojiSkin.native]
      .concat(emoji.aliases || emoji.id)
      .filter(Boolean)
      .join(', ');

    return {
      ...emoji,
      ...emojiSkin,
      skin: skinNumber,
      label,
    };
  }

  getSheetData() {
    return this.doFetchOnce().then(() => this.data?.sheet || null);
  }

  getSheetDataSync() {
    return this.data?.sheet || null;
  }

  getSpritesheets() {
    return this.doFetchOnce().then(() => this.spriteSheets);
  }

  getSpritesheetsSync() {
    return this.spriteSheets;
  }

  getSpritesheetsUrl(set: 'twitter', sheetSize: 16 | 20 | 32 | 64 = 64) {
    if (this.spriteSheets?.[set]?.[sheetSize]) {
      return this.spriteSheets[set][sheetSize].url;
    }

    return '';
  }

  getDefaultEmojiSet() {
    return this.defaultEmojiSet;
  }
}

// eslint-disable-next-line @trello/no-module-logic
const emojiProvider = new EmojiProvider();
export { emojiProvider as EmojiProvider };
