import { markdown } from './markdown';

export function markdownAsText(s: string, args?: object) {
  if (!args) {
    args = {};
  }
  if (!s) {
    return '';
  }
  return markdown.description.text(s, args).output;
}
