import type { FunctionComponent } from 'react';
import { Suspense } from 'react';

import { ChunkLoadErrorBoundary } from '@trello/error-boundaries';
import { useLazyComponent } from '@trello/use-lazy-component';

import type { PowerUpsDeprecationBannerProps } from './PowerUpsDeprecationBanner';

export const LazyPowerUpsDeprecationBanner: FunctionComponent<
  PowerUpsDeprecationBannerProps
> = (props) => {
  const hasDeprecatedPups = props.deprecatedPowerUps.length > 0;
  const PowerUpsDeprecationBanner = useLazyComponent(
    () =>
      import(
        /* webpackChunkName: "power-ups-deprecation-banner" */ 'app/src/components/PowerUpsDeprecationBanner'
      ),
    {
      namedImport: 'PowerUpsDeprecationBanner',
      preload: hasDeprecatedPups,
    },
  );

  if (!hasDeprecatedPups) {
    return null;
  }

  return (
    <ChunkLoadErrorBoundary fallback={null}>
      <Suspense fallback={null}>
        <PowerUpsDeprecationBanner {...props} />
      </Suspense>
    </ChunkLoadErrorBoundary>
  );
};
