/* eslint-disable @trello/disallow-filenames */
// eslint-disable-next-line no-restricted-syntax
export enum ViewType {
  BOARD = 'board',
  DASHBOARD = 'dashboard',
  MAP = 'map',
  TIMELINE = 'timeline',
  CALENDAR = 'calendar',
  TABLE = 'table',
}

// eslint-disable-next-line no-restricted-syntax
export enum NavigationDirection {
  PREV = 'PREV',
  NEXT = 'NEXT',
  TODAY = 'TODAY',
  UP = 'UP',
  DOWN = 'DOWN',
}

// eslint-disable-next-line no-restricted-syntax
export enum ZoomLevel {
  DAY = 'day',
  WEEK = 'week',
  MONTH = 'month',
  QUARTER = 'quarter',
  YEAR = 'year',
}

// eslint-disable-next-line no-restricted-syntax
export enum GroupByOption {
  LIST = 'list',
  MEMBER = 'member',
  LABEL = 'label',
  NONE = 'none',
}

export interface DropdownOption {
  value: string;
  isSelected?: boolean;
  label: string;
}

export interface Member {
  id: string;
  name: string;
  avatarUrl?: string;
}

export interface Label {
  id: string;
  name?: string;
  color?: string | null;
}

// eslint-disable-next-line no-restricted-syntax
export enum AddNewType {
  CARD = 'card',
  LIST = 'list',
}

// eslint-disable-next-line no-restricted-syntax
export enum AddCardTrigger {
  BUTTON = 'button',
  LANE = 'lane',
}
