import { forTemplate } from '@trello/legacy-i18n';

const format = forTemplate('markdown');

export const applyBidiTooltip = (el: HTMLElement): void => {
  // Paranoid check for a valid element (this method has untyped consumers)
  if (!el || !(el instanceof Element)) {
    return;
  }

  Array.from(el.querySelectorAll('span[data-bidi-char]')).forEach((node) => {
    node.setAttribute('title', format('bidi-code-tooltip'));
  });
};
