import Promise from 'bluebird';

import { Analytics } from '@trello/atlassian-analytics';
import { ComponentWrapper, renderReactRoot } from '@trello/component-wrapper';
import { PLAIN_LINK_TITLE } from '@trello/editor';
import { getScreenFromUrl } from '@trello/marketing-screens';
import type {
  ResolveResponse,
  SmartCardAppearance,
  SmartLinkAnalyticsContextType,
} from '@trello/smart-card';
import { getSmartCardAppearanceFromTitle, SmartLink } from '@trello/smart-card';

import { KnownServices } from 'app/scripts/db/known-services';
import { ModelCache } from 'app/scripts/db/ModelCache';
import { sendPluginTrackEvent } from 'app/scripts/lib/plugins/plugin-behavioral-analytics';
import type { Board } from 'app/scripts/models/Board';
import { pluginRunner } from 'app/scripts/views/internal/plugins/PluginRunner';
import { teacupWithHelpers } from 'app/scripts/views/internal/teacupWithHelpers';
import { trelloLinkHandler } from 'app/src/trelloLinkHandler';

interface FriendlyLinkOptions {
  renderInline?: boolean;
  analyticsContext: SmartLinkAnalyticsContextType;
}

const t = teacupWithHelpers();

const formatCache = new window.Map();
const formatRequestCache = new window.Map();

const generateFriendlyLinkAnalyticsPayload = (
  meta: ResolveResponse['meta'],
) => ({
  /**
   * This code path should not be reachable when and `meta` is defined, because
   * that field is only found in responses processed by the Object Resolver
   * Service via KnownServices.interpret. Those responses also contain a `url`
   * field, which leads the call to `friendlyLinks` below to return early and
   * render a `SmartLink` component instead of rendering the teacup template
   * and firing these friendly link render/click events.
   *
   * HOWEVER, we'll still redact the data here that was previously derived from
   * potential UGC strings, just in case.
   */
  application: 'REDACTED',
  resourceType: 'REDACTED',
  visibility: meta?.resolvedUrl?.meta?.visibility,
});

const handleFriendlyLinkClick = ({ meta }: ResolveResponse) => {
  Analytics.sendClickedLinkEvent({
    linkName: 'friendlyLink',
    source: getScreenFromUrl(),
    attributes: generateFriendlyLinkAnalyticsPayload(meta),
  });
};

const handleFriendlyLinkRender = ({ meta }: ResolveResponse) => {
  Analytics.sendOperationalEvent({
    action: 'rendered',
    actionSubject: 'friendlyLink',
    source: getScreenFromUrl(),
    attributes: generateFriendlyLinkAnalyticsPayload(meta),
  });
};

interface TemplateProps {
  icon: string;
  idPlugin: string;
  monochrome: boolean;
  text: string;
}

// eslint-disable-next-line @trello/no-module-logic
const template = t.renderable(
  ({ icon, idPlugin, monochrome, text }: TemplateProps) => {
    t.img({
      class: t.classify({
        'known-service-icon': true,
        'plugin-icon': !!idPlugin,
        'mod-reset': monochrome === false,
      }),
      src: icon,
    });
    return t.text(text);
  },
);

interface SmartLinkProps {
  url: string;
  appearance: SmartCardAppearance | null;
  analyticsContext: SmartLinkAnalyticsContextType;
}

const renderSmartLink = (
  el: HTMLElement,
  { url, appearance, analyticsContext }: SmartLinkProps,
) => {
  el.classList.add('atlaskit-smart-link');
  return renderReactRoot(
    <ComponentWrapper>
      <SmartLink
        url={url}
        appearance={appearance}
        analyticsContext={analyticsContext}
        plainLink={() => <a href={url}>{url}</a>}
        handleTrelloLinkClick={trelloLinkHandler}
      />
    </ComponentWrapper>,
    el,
  ).unmount;
};

export const friendlyLinks = (
  el: HTMLElement,
  board: Board,
  options: FriendlyLinkOptions = {
    renderInline: false,
    analyticsContext: { source: 'unknown' },
  },
) => {
  const els =
    el && el.tagName === 'A' ? [el] : Array.from(el?.querySelectorAll('a'));

  // eslint-disable-next-line @typescript-eslint/no-shadow
  return Promise.map(els as HTMLAnchorElement[], (el: HTMLAnchorElement) => {
    /**
     * The URL serializer in HTMLHyperlinkElementUtils.href
     * (https://url.spec.whatwg.org/#concept-url-serializer) appends a slash
     * to the base url (e.g. <a href="http://www.trello.com">Trello</a>
     * will return http://www.trello.com/). Unfortunately, this breaks any
     * comparison we make with the link's actual text node if the URL supplied
     * is a base URL with no trailing slash.
     */
    let parsedUrl: URL;
    try {
      parsedUrl = new URL(el.href);
    } catch (e) {
      // If parsing the URL fails, it isn't a friendly link, so return early.
      return;
    }

    const url =
      el.textContent === parsedUrl.origin ? parsedUrl.origin : parsedUrl.href;

    const title = el.getAttribute('title') ?? undefined;

    // If the link already has custom text, leave it alone.
    if (url !== el.textContent) {
      // In some cases, the href is escaped and the textContent
      // isn't, so also check against the decoded url.
      try {
        const decoded = decodeURIComponent(url);
        if (decoded !== el.textContent) {
          // Neither the original URL nor the decoded URL match the
          // text, assume they have custom text and don't change it
          return;
        }
      } catch (ignored) {
        // Don't worry if the URL can't be decoded and assume they've
        // got custom text
        return;
      }
    }

    /**
     * Editor sets the titles of plain links to a zero-width, non-joining
     * character (ZWNJ). If the link has this for a title, we need to return
     * early, so we don't render this as an inline Smart Card by default. And
     * we remove the title so that browsers don't try to show an empty tooltip
     * for these links.
     */
    if (title === PLAIN_LINK_TITLE) {
      el.removeAttribute('title');
      return;
    }

    const smartCardAppearance = title
      ? getSmartCardAppearanceFromTitle(title)
      : null;

    if (smartCardAppearance) {
      el.removeAttribute('title');
    }

    const cacheKey = `${board?.id}:${url}`;

    return (
      Promise.try(() => {
        if (!board) {
          // @ts-expect-error - Error is assigned in initClass method
          throw new pluginRunner.Error.NotHandled('no board specified');
        }

        if (formatCache.get(cacheKey)) {
          return formatCache.get(cacheKey);
        }

        if (formatRequestCache.get(cacheKey)) {
          return formatRequestCache.get(cacheKey);
        }

        const formatRequest = pluginRunner.one({
          command: 'format-url',
          board,
          options: {
            url,
          },
        });

        formatRequestCache.set(cacheKey, formatRequest);
        return formatRequest;
      })
        // @ts-expect-error - Error is assigned in initClass method
        .catch(pluginRunner.Error.NotHandled, () => null)
        .then((pluginResult) => {
          formatRequestCache.delete(cacheKey);
          if (pluginResult?.text) {
            // there might not be an idPlugin because we are attaching a trello card
            // not a link that is being formatted by a power-up
            if (pluginResult.idPlugin) {
              sendPluginTrackEvent({
                idPlugin: pluginResult.idPlugin,
                idBoard: board.id,
                event: {
                  action: 'formatted',
                  actionSubject: 'url',
                  source: 'powerUpFormatUrl',
                },
              });
            }

            return pluginResult;
          } else {
            return KnownServices.interpret(el.href, ModelCache);
          }
        })
        .then((known) => {
          if (!known) {
            return;
          }

          formatCache.set(cacheKey, known);

          const isTrelloLink = [
            'trello board',
            'trello card',
            'trello action',
          ].includes(known.type);

          // resolved data comes from KnownServices.interpret
          if (isTrelloLink || smartCardAppearance || known.url) {
            const appearance = options.renderInline
              ? 'inline'
              : smartCardAppearance;

            return renderSmartLink(el, {
              url: known.url ?? url,
              appearance,
              analyticsContext: options.analyticsContext,
            });
          }

          // else from pluginResult
          el.classList.add('known-service-link');
          handleFriendlyLinkRender(known);
          el.addEventListener(
            'click',
            handleFriendlyLinkClick.bind(null, known),
          );
          return (el.innerHTML = template(known));
        })
    );
  });
};
