import type { Board, Member } from '@trello/model-types';

import { isMemberOfOrganization } from '../organization/isMemberOfOrganization';
import { getMemberTypeFromBoard } from './getMemberTypeFromBoard';
import { isAdminOfBoard } from './isAdminOfBoard';

/**
 * Checks if a member has permissions to invite new members to the board.
 */
export const canInviteMembers = (
  member: Pick<Member, 'id' | 'idPremOrgsAdmin' | 'memberType'> & {
    idEnterprisesAdmin?: string[] | null;
  },
  board: {
    organization?:
      | (Pick<Board['organization'], 'id' | 'offering'> & {
          enterprise?: Pick<
            Board['organization']['enterprise'],
            'id' | 'idAdmins'
          > | null;
          memberships: Pick<
            Board['organization']['memberships'][number],
            'idMember' | 'memberType' | 'deactivated' | 'unconfirmed'
          >[];
        })
      | null;
    prefs?: Pick<Board['prefs'], 'invitations' | 'canInvite'> | null;
    memberships: Pick<
      Board['memberships'][number],
      'id' | 'idMember' | 'memberType' | 'deactivated' | 'unconfirmed'
    >[];
  },
): boolean => {
  const workspace = board.organization;

  // If member isn't in workspace, they cannot invite others if the workspace has restricted invites
  if (
    (!workspace || !isMemberOfOrganization(member, workspace)) &&
    !board.prefs?.canInvite
  )
    return false;

  if (
    board.prefs?.invitations === 'admins' &&
    !(workspace && isAdminOfBoard(member, board))
  )
    return false;

  const memberType = getMemberTypeFromBoard(member, board);

  return (
    memberType === 'admin' ||
    (board.prefs?.invitations === 'members' && memberType === 'normal')
  );
};
