import type { ReactNode } from 'react';
import { useMemo } from 'react';

import { useMemberId } from '@trello/authentication';
import { customFieldsId as CUSTOM_FIELDS_ID } from '@trello/config';
import { useBoardId } from '@trello/id-context';
import { dangerouslyConvertPrivacyString } from '@trello/privacy';

import { BUTLER_POWER_UP_ID } from 'app/scripts/data/butler-id';
import type { BoardPowerUpsContextValue } from './BoardPowerUpsContext';
import { BoardPowerUpsContext } from './BoardPowerUpsContext';
import { useBoardPowerUpsContextBoardPluginsAndEnterpriseFragment } from './BoardPowerUpsContextBoardPluginsAndEnterpriseFragment.generated';
import { useBoardPowerUpsContextMemberLocaleFragment } from './BoardPowerUpsContextMemberLocaleFragment.generated';
import { useBoardPowerUpsContextQuery } from './BoardPowerUpsContextQuery.generated';

export function BoardPowerUpsContextProvider({
  children,
}: {
  children: ReactNode;
}) {
  const boardId = useBoardId();
  const memberId = useMemberId();

  const { data: member } = useBoardPowerUpsContextMemberLocaleFragment({
    from: { id: memberId },
  });

  const { data: board } =
    useBoardPowerUpsContextBoardPluginsAndEnterpriseFragment({
      from: { id: boardId },
    });

  const { data } = useBoardPowerUpsContextQuery({
    skip: member?.prefs?.locale === undefined,
    variables: {
      boardId,
      preferredLocales:
        dangerouslyConvertPrivacyString(member?.prefs?.locale) || 'en',
    },
    waitOn: ['None'],
    fetchPolicy: 'cache-first',
  });

  const contextValue = useMemo(() => {
    const publicPowerUps = data?.publicPlugins || [];
    const privatePowerUps = data?.board?.plugins || [];
    const allPowerUps: BoardPowerUpsContextValue['allPowerUps'] = [];
    const enabledPowerUpIds =
      board?.boardPlugins.map((boardPlugin) => boardPlugin.idPlugin) ?? [];
    const enabledPowerUps: BoardPowerUpsContextValue['enabledPowerUps'] = [];
    [...publicPowerUps, ...privatePowerUps].forEach((powerUp) => {
      if (
        powerUp.id !== BUTLER_POWER_UP_ID &&
        powerUp.id !== CUSTOM_FIELDS_ID
      ) {
        allPowerUps.push(powerUp);
      }

      if (enabledPowerUpIds.includes(powerUp.id)) {
        if (
          powerUp.id !== BUTLER_POWER_UP_ID &&
          powerUp.id !== CUSTOM_FIELDS_ID
        ) {
          enabledPowerUps.push(powerUp);
        }
      }
    });

    return {
      allPowerUps,
      enabledPowerUps,
    };
  }, [board?.boardPlugins, data?.board?.plugins, data?.publicPlugins]);

  return (
    <BoardPowerUpsContext.Provider value={contextValue}>
      {children}
    </BoardPowerUpsContext.Provider>
  );
}
