import type { FunctionComponent, MouseEvent } from 'react';
import { useCallback, useMemo, useState } from 'react';

import type {
  CardAppearance as SmartCardAppearance,
  CardProps as SmartCardProps,
} from '@atlaskit/smart-card';
import { Analytics } from '@trello/atlassian-analytics';
import { ErrorBoundary } from '@trello/error-boundaries';

import type { SmartCardClientResponse } from './SmartCardClient';
import { SmartCardWrapper } from './SmartCardWrapper';
import type { SmartLinkAnalyticsContextType } from './SmartLinkAnalyticsContext';

interface Props {
  url: string;
  appearance?: SmartCardAppearance | null;
  plainLink: () => JSX.Element;
  showFrame?: boolean;
  analyticsContext: SmartLinkAnalyticsContextType;
  handleTrelloLinkClick: (e: MouseEvent, linkElem: HTMLAnchorElement) => void;
  onClick?: () => void;
}

export const SmartLink: FunctionComponent<Props> = ({
  url,
  appearance,
  plainLink,
  showFrame = true,
  analyticsContext,
  handleTrelloLinkClick,
  onClick,
}) => {
  const { source, attributes, containers } = analyticsContext;
  const [shouldRender, setShouldRender] = useState(false);
  const [resolvedUrl, setResolvedUrl] = useState<
    SmartCardClientResponse | undefined
  >();

  const smartCardProps = useMemo(() => {
    const props: SmartCardProps = {
      appearance: appearance || 'inline',
      inheritDimensions: true,
      showActions: true,
      showAuthTooltip: true,
      showServerActions: true,
    };

    // disable inheritDimensions for "embed" Smart Cards
    if (props['appearance'] === 'embed') {
      props.frameStyle = showFrame ? 'show' : 'hide';
      props.inheritDimensions = false;
    }

    return props;
  }, [appearance, showFrame]);

  const accessType = resolvedUrl?.meta?.requestAccess?.accessType;
  const cloudId = resolvedUrl?.meta?.requestAccess?.cloudId;
  const definitionId = resolvedUrl?.meta?.definitionId;
  const urlAccess = resolvedUrl?.meta?.access;

  const smartLinkAttAnalytics = useMemo(
    () => ({
      source,
      attributes: {
        component: 'smartLink',
        definitionId,
        accessType,
        cloudId,
        urlAccess,
        ...(attributes && attributes),
        ...(containers && containers),
      },
    }),
    [
      source,
      definitionId,
      accessType,
      cloudId,
      urlAccess,
      attributes,
      containers,
    ],
  );

  const handleLinkResolved = useCallback(
    (resolved: SmartCardClientResponse) => {
      setResolvedUrl(resolved);
      setShouldRender(true);
    },
    [],
  );

  const handleLinkUnresolved = useCallback(() => {
    setResolvedUrl(undefined);
    setShouldRender(false);
  }, []);

  const handleSmartCardClick = useCallback(
    (e: MouseEvent) => {
      const target = e.target as HTMLElement;
      const button = target.closest('button');

      // Stop propagation on buttons because we need to catch clicks on any
      // buttons within the SmartCard and stop the event from bubbling up and
      // triggering the default behavior on the <a> tag wrapping this component.
      if (button) {
        e.stopPropagation();
      }

      // onClick allows for additional custom behavior from the parent component,
      // and will override the default Analytics event
      if (onClick) {
        onClick();
        return;
      }
      // We are allowing a UGCString type within smartLinkAttAnalytics to get passed
      // into this analytics function because there is redaction logic above.
      Analytics.sendClickedLinkEvent({
        linkName: 'smartLink',
        ...smartLinkAttAnalytics,
      });
    },
    [smartLinkAttAnalytics, onClick],
  );

  return (
    <ErrorBoundary
      tags={{
        ownershipArea: 'trello-web-eng',
        feature: 'Smart Link',
      }}
      errorHandlerComponent={plainLink}
    >
      <SmartCardWrapper
        url={url}
        plainLink={plainLink}
        smartCardProps={smartCardProps}
        onLinkResolved={handleLinkResolved}
        onLinkUnresolved={handleLinkUnresolved}
        shouldRender={shouldRender}
        onSmartCardClick={handleSmartCardClick}
        handleTrelloLinkClick={handleTrelloLinkClick}
        analyticsContextData={smartLinkAttAnalytics}
      />
    </ErrorBoundary>
  );
};
