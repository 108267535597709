import { useCallback, useEffect } from 'react';

import type { RouteId } from '@trello/router';
import { useRouteParams } from '@trello/router';
import { TrelloStorage } from '@trello/storage';

import { switchCurrentBoardView } from 'app/src/components/Board/switchCurrentBoardView';
import { ZoomLevel } from 'app/src/components/ViewsGenerics';

interface CalendarDeepLinkingProps {
  currentZoomLevel: ZoomLevel;
  selectedDate: Date;
}

export const useCalendarDeepLinking = ({
  currentZoomLevel,
  selectedDate,
}: CalendarDeepLinkingProps) => {
  const { view } = useRouteParams<RouteId.BOARD>();

  useEffect(() => {
    if (view !== 'calendar') {
      return;
    }

    if (currentZoomLevel === ZoomLevel.MONTH) {
      switchCurrentBoardView({
        routeParams: {
          view,
          year: `${selectedDate.getFullYear()}`,
          month: `${selectedDate.getMonth() + 1}`,
        },
        navigateOptions: {
          replace: true,
        },
      });
    } else if (currentZoomLevel === ZoomLevel.WEEK) {
      switchCurrentBoardView({
        routeParams: {
          view,
          year: `${selectedDate.getFullYear()}`,
          month: `${selectedDate.getMonth() + 1}`,
          day: `${selectedDate.getDate()}`,
        },
        navigateOptions: {
          replace: true,
        },
      });
    } else if (currentZoomLevel === ZoomLevel.DAY) {
      switchCurrentBoardView({
        routeParams: {
          view,
          year: `${selectedDate.getFullYear()}`,
          month: `${selectedDate.getMonth() + 1}`,
          day: `${selectedDate.getDate()}`,
        },
        navigateOptions: {
          replace: true,
        },
      });
    }
  }, [currentZoomLevel, selectedDate, view]);
};

export const useCalendarDefaultZoom = () => {
  const routeParams = useRouteParams<RouteId.BOARD>();

  const calendarDefaultZoom = useCallback((): string | undefined => {
    if (routeParams.view !== 'calendar') {
      return undefined;
    }

    const storedZoomLevel = TrelloStorage.get('calendarZoomLevel');
    let defaultZoom = storedZoomLevel || 'month';

    if (routeParams.day && defaultZoom === 'month') {
      defaultZoom = 'week';
    } else if (
      defaultZoom !== 'month' &&
      typeof routeParams.day === 'undefined' &&
      routeParams.month
    ) {
      defaultZoom = 'month';
    }
    return defaultZoom;
    // eslint-disable-next-line @trello/exhaustive-deps
  }, [routeParams]);
  return calendarDefaultZoom;
};
