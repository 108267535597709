import { useCallback, useEffect, useMemo } from 'react';

import type { SourceType } from '@trello/atlassian-analytics';
import { Analytics } from '@trello/atlassian-analytics';
import { useMemberId } from '@trello/authentication';
import { useIsMemberOfOrganization } from '@trello/business-logic-react/organization';
import { useFeatureGate } from '@trello/feature-gate-client';
import { useSharedStateSelector } from '@trello/shared-state';
import { TrelloStorage } from '@trello/storage';
import { workspaceState } from '@trello/workspaces';

import { useHasReverseTrialExperienceQuery } from './HasReverseTrialExperienceQuery.generated';
import { REVERSE_TRIALS_EXPERIMENTS } from './useAssignCohortForReverseTrial';
import { useFreeTrialEligibilityRules } from './useFreeTrialEligibilityRules';

export const FREE_TRIAL_ATTEMPT_COUNT_STORAGE_KEY_PREFIX =
  'freeTrialAttemptCount';
export const MAX_FREE_TRIAL_ATTEMPT_COUNT = 3;
const FREE_TRIAL_NEXT_ATTEMPT_DATE_STORAGE_KEY_PREFIX =
  'freeTrialNextAttemptDate';
const FREE_TRIAL_NEXT_ATTEMPT_DELAY_MS = 1000;

interface ReverseTrialOptions {
  ensureTrialExists?: boolean;
}

export const useHasReverseTrialExperience = ({
  ensureTrialExists = false,
}: ReverseTrialOptions = {}): boolean => {
  const workspaceId =
    useSharedStateSelector(
      workspaceState,
      useCallback((state) => state?.workspaceId, []),
    ) || '';

  const { data } = useHasReverseTrialExperienceQuery({
    variables: { workspaceId },
    skip: !workspaceId,
    waitOn: [],
  });
  const workspace = data?.organization;
  const {
    isExpired: isFreeTrialExpired,
    isEligible: isEligibleForTrial,
    startFreeTrial,
    isTrialActive,
    loading: freeTrialStatusLoading,
  } = useFreeTrialEligibilityRules(workspace?.id, { skip: !workspace?.id });
  const memberId = useMemberId();
  const isMemberOfWorkspace = useIsMemberOfOrganization({
    idMember: memberId,
    idOrganization: workspace?.id,
  });
  const { value: isGateEnabled } = useFeatureGate(
    'trello_enterprise_reverse_trial_experience',
  );

  const freeTrialAttemptCountKey = useMemo(
    () =>
      workspaceId.length
        ? `${FREE_TRIAL_ATTEMPT_COUNT_STORAGE_KEY_PREFIX}-${workspaceId}`
        : null,
    [workspaceId],
  );
  const freeTrialNextAttemptDateKey = useMemo(
    () =>
      workspaceId.length
        ? `${FREE_TRIAL_NEXT_ATTEMPT_DATE_STORAGE_KEY_PREFIX}-${workspaceId}`
        : null,
    [workspaceId],
  );

  // HACK: there's a bug we've been unable to fix where the workspace is in the
  // reverse trial cohort, but doesn't get a free trial. In this scenario, we'll
  // create a trial here.
  // We need to limit the number of trial attempts and space them out.
  useEffect(() => {
    const ensureTrial = async () => {
      if (!freeTrialAttemptCountKey || !freeTrialNextAttemptDateKey) {
        return;
      }
      const freeTrialAttemptCount = TrelloStorage.get(freeTrialAttemptCountKey);
      const freeTrialNextAttemptDate = TrelloStorage.get(
        freeTrialNextAttemptDateKey,
      );

      if (
        ensureTrialExists &&
        isGateEnabled &&
        workspace?.reverseTrialTag === 'q4_actual_experiment' &&
        isEligibleForTrial &&
        !isTrialActive &&
        !freeTrialStatusLoading &&
        (freeTrialAttemptCount === null ||
          freeTrialAttemptCount < MAX_FREE_TRIAL_ATTEMPT_COUNT) &&
        (freeTrialNextAttemptDate === null ||
          new Date(freeTrialNextAttemptDate) < new Date())
      ) {
        await startFreeTrial({
          count: 14,
          redirect: false,
          via: 'reverse-trial',
        });
        TrelloStorage.set(
          freeTrialAttemptCountKey,
          freeTrialAttemptCount === null ? 0 : freeTrialAttemptCount + 1,
        );
        TrelloStorage.set(
          freeTrialNextAttemptDateKey,
          new Date(Date.now() + FREE_TRIAL_NEXT_ATTEMPT_DELAY_MS),
        );
      }
    };

    ensureTrial();
  }, [
    ensureTrialExists,
    freeTrialAttemptCountKey,
    freeTrialNextAttemptDateKey,
    freeTrialStatusLoading,
    isEligibleForTrial,
    isGateEnabled,
    isTrialActive,
    startFreeTrial,
    workspace?.reverseTrialTag,
  ]);

  useEffect(() => {
    if (!isGateEnabled) {
      return;
    }

    if (!workspace?.id) {
      return;
    }

    if (!isMemberOfWorkspace) {
      return;
    }

    const experimentTags = REVERSE_TRIALS_EXPERIMENTS.q4Experiment.tags;
    if (!Object.values(experimentTags).includes(workspace?.reverseTrialTag)) {
      return;
    }

    Analytics.sendTrackEvent({
      action: 'exposed',
      actionSubject: 'feature',
      source: 'trello' as SourceType,
      attributes: {
        experimentName: 'trello_enterprise_reverse_trials',
        value:
          workspace?.reverseTrialTag === experimentTags.control
            ? 'control'
            : 'experiment',
      },
    });
  }, [
    isGateEnabled,
    isMemberOfWorkspace,
    workspace?.id,
    workspace?.reverseTrialTag,
  ]);

  if (!isGateEnabled) {
    return false;
  }

  if (!workspace?.id) {
    return false;
  }

  if (!isMemberOfWorkspace) {
    return false;
  }

  // workspace does NOT have a premium trial created via reverse trial
  if (
    !workspace?.credits?.some(
      (credit) => credit.type === 'freeTrial' && credit.via === 'reverse-trial',
    )
  ) {
    return false;
  }

  if (isFreeTrialExpired) {
    return false;
  }

  // workspace not in reverse trial cohort
  if (workspace.reverseTrialTag !== 'q4_actual_experiment') {
    return false;
  }
  return true;
};
