/* eslint-disable
    eqeqeq,
*/
// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * DS104: Avoid inline assignments
 * DS204: Change includes calls to have a more natural evaluation order
 * DS206: Consider reworking classes to avoid initClass
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */

import Promise from 'bluebird';
import _ from 'underscore';

import FeatureGates from '@atlaskit/feature-gate-js-client';
import { Analytics, tracingCallback } from '@trello/atlassian-analytics';
import { ComponentWrapper, renderReactRoot } from '@trello/component-wrapper';
import { ErrorBoundary } from '@trello/error-boundaries';
// eslint-disable-next-line no-restricted-imports
import $ from '@trello/jquery';
import {
  getKey,
  Key,
  registerShortcutHandler,
  Scope,
  unregisterShortcutHandler,
} from '@trello/keybindings';
import {
  ELEVATION_ATTR,
  getHighestVisibleElevation,
} from '@trello/layer-manager';
import { forTemplate } from '@trello/legacy-i18n';
import { getScreenFromUrl } from '@trello/marketing-screens';
import { isTrelloAttachmentUrl } from '@trello/urls';
import { importWithRetry } from '@trello/use-lazy-component';

import { Dates } from 'app/scripts/lib/dates';
import { l } from 'app/scripts/lib/localize';
import { Util } from 'app/scripts/lib/util';
import type { Attachment } from 'app/scripts/models/Attachment';
import { serializeAttachments } from 'app/scripts/views/internal/plugins/PluginModelSerializer';
import { pluginRunner } from 'app/scripts/views/internal/plugins/PluginRunner';
import { templates } from 'app/scripts/views/internal/templates';
import { View } from 'app/scripts/views/internal/View';
import { PopOver } from 'app/scripts/views/lib/PopOver';
import { AttachmentViewerFrameDetailsTemplate } from 'app/scripts/views/templates/AttachmentViewerFrameDetailsTemplate';
import { AttachmentViewerFrameTemplate } from 'app/scripts/views/templates/AttachmentViewerFrameTemplate';
import { AttachmentViewerTemplate } from 'app/scripts/views/templates/AttachmentViewerTemplate';
import { stopPropagationAndPreventDefault } from 'app/src/stopPropagationAndPreventDefault';
import { Helpers as AttachmentHelpers } from './Helpers';
const format = forTemplate('attachment_viewer_frame');

interface AttachmentViewerView {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  PDFRendererPromise: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  allowsCardCovers: any;
  currentAttachment: Attachment | undefined;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  idPluginAttachments: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  idTrelloAttachments: any;

  pdfUnmounts: {
    [key: string]: () => void;
  };
}

class AttachmentViewerView extends View {
  static initClass() {
    // @ts-expect-error TS(2322): Type 'string' is not assignable to type '() => str... Remove this comment to see the full error message
    this.prototype.className = 'attachment-viewer';

    this.prototype.events = {
      // @ts-expect-error TS(2322): Type '{ 'click .js-show-next-frame': string; 'clic... Remove this comment to see the full error message
      'click .js-show-next-frame': 'showNextFrame',
      'click .js-show-prev-frame': 'showPrevFrame',
      'mouseenter .js-show-next-frame': 'hintNextFrame',
      'mouseenter .js-show-prev-frame': 'hintPrevFrame',

      'mouseleave .js-show-next-frame': 'clearHint',
      'mouseleave .js-show-prev-frame': 'clearHint',

      'click .js-stop': 'stop',
      'click .js-close-viewer': 'close',

      'click .js-open-delete-confirm': 'openDeleteConfirm',
      'click .js-close-delete-confirm': 'closeDeleteConfirm',
      'click .js-delete': 'delete',
      'click .js-make-cover': 'makeCover',
      'click .js-remove-cover': 'removeCover',
    };
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor(options: any) {
    super(options);
    this.onShortcut = this.onShortcut.bind(this);
    registerShortcutHandler(this.onShortcut, { scope: Scope.Overlay });
    this.PDFRendererPromise = importWithRetry(() =>
      import(/* webpackChunkName: "pdf-renderer" */ './PDFRenderer').then(
        (module) => module.PDFRenderer,
      ),
    );
    this.pdfUnmounts = {};
  }

  initialize() {
    PopOver.hide();
    this.allowsCardCovers = this.model?.getBoard()?.getPref('cardCovers');
    this.currentAttachment = this.options.attachmentModel as
      | Attachment
      | undefined;
    const idAtt = this.options.idAttachment;
    if (idAtt != null) {
      this.currentAttachment = this.model.attachmentList.find(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (model: any) => model.id === idAtt,
      );
    }

    this.idPluginAttachments = [];

    this.idTrelloAttachments = [];
    this.getIdTrelloAttachments();

    Analytics.sendScreenEvent({
      name: 'attachmentViewerModal',
      containers: this.currentAttachment?.getCard()?.getAnalyticsContainers(),
      attributes: {
        type: this.currentAttachment?.getType(),
        cardIsTemplate: this.model.get('isTemplate'),
        cardIsClosed: this.model.get('closed'),
      },
    });

    // Expected to execute getIdPluginAttachments() on init.
    return this.listenTo(
      this.model.attachmentList,
      'add remove reset',
      this.getIdPluginAttachments,
    );
  }

  remove() {
    this.cleanupAllPDFs();
    unregisterShortcutHandler(this.onShortcut);
    return super.remove(...arguments);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onShortcut(event: any) {
    switch (getKey(event)) {
      case Key.Escape:
        this.close();
        event.preventDefault();
        return Analytics.sendPressedShortcutEvent({
          shortcutName: 'escapeShortcut',
          source: 'attachmentViewerModal',
          keyValue: Key.Escape,
        });

      case Key.ArrowRight:
        // @ts-expect-error TS(2554): Expected 1 arguments, but got 0.
        this.showNextFrame();
        event.preventDefault();
        return Analytics.sendPressedShortcutEvent({
          shortcutName: 'moveRightShortcut',
          source: 'attachmentViewerModal',
          keyValue: Key.ArrowRight,
        });

      case Key.ArrowLeft:
        // @ts-expect-error TS(2554): Expected 1 arguments, but got 0.
        this.showPrevFrame();
        event.preventDefault();
        return Analytics.sendPressedShortcutEvent({
          shortcutName: 'moveLeftShortcut',
          source: 'attachmentViewerModal',
          keyValue: Key.ArrowLeft,
        });

      default:
        break;
    }
  }

  // getters
  getAttachmentModels() {
    const attachmentList = this.model.attachmentList.filter(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (attachment: any) => {
        return (
          !Array.from(this.idPluginAttachments).includes(attachment.id) &&
          !Array.from(this.idTrelloAttachments).includes(attachment.id)
        );
      },
    );
    const isAttachmentRedesignEnabled = FeatureGates.checkGate(
      'xf_card_back_attachments',
    );
    if (isAttachmentRedesignEnabled) {
      return attachmentList.filter((attachment: Attachment) =>
        attachment.get('isUpload'),
      );
    }
    return attachmentList;
  }

  getAttachmentModelsLength() {
    return this.getAttachmentModels().length;
  }

  getCurrentAttachmentIndex() {
    return _.indexOf(this.getAttachmentModels(), this.currentAttachment);
  }

  // NOTE: Prev increments and Next decrements because attachments are
  //       reverse sorted on the card back (descending `pos` order). Thus,
  //       pressing the right arrow key (next) should iterate *down*
  //       the list, and the left arrow key (prev) should go iterate *up*
  getPrevAttachmentIndex() {
    const idx = this.getCurrentAttachmentIndex() + 1;
    if (idx >= this.getAttachmentModelsLength()) {
      return null;
    } else {
      return idx;
    }
  }

  getPrevModel() {
    const idx = this.getPrevAttachmentIndex();
    if (idx != null) {
      return this.getAttachmentModels()[idx];
    } else {
      return null;
    }
  }

  getNextAttachmentIndex() {
    const idx = this.getCurrentAttachmentIndex() - 1;
    if (idx < 0) {
      return null;
    } else {
      return idx;
    }
  }

  getNextModel() {
    const idx = this.getNextAttachmentIndex();
    if (idx != null) {
      return this.getAttachmentModels()[idx];
    } else {
      return null;
    }
  }

  render() {
    if (!this.currentAttachment) {
      return this;
    }
    const data = this.model.toJSON();
    data.numAttachments = this.getAttachmentModelsLength();

    this.$el.html(templates.fill(AttachmentViewerTemplate, data));

    // Ensure the attachment viewer is given the highest elevation to interop
    // with outside click handlers using the @trello/layer-manager
    if (!this.$el.attr(ELEVATION_ATTR)) {
      const elevation = getHighestVisibleElevation() + 1;
      this.$el.attr(ELEVATION_ATTR, elevation);
    }

    if (
      Array.from(this.idPluginAttachments).includes(this.currentAttachment.id)
    ) {
      const models = this.getAttachmentModels();
      this.currentAttachment = models[models.length - 1];
    }

    if (!this.currentAttachment) {
      return this;
    }

    this.addNewFrame(this.currentAttachment, 'center');

    const nextModel = this.getNextModel();
    if (nextModel != null) {
      this.addNewFrame(nextModel, 'right');
    }

    const prevModel = this.getPrevModel();
    if (prevModel != null) {
      this.addNewFrame(prevModel, 'left');
    }

    this.setDetailsAndControls();

    return this;
  }

  setDetailsAndControls() {
    if (!this.currentAttachment) {
      return;
    }
    AttachmentHelpers.getAttachmentData(this.currentAttachment)
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .then((data: any) => {
        data.canMakeCover =
          this.allowsCardCovers &&
          (data.previews != null ? data.previews.length : undefined) > 0;
        data.isCover =
          this.allowsCardCovers &&
          this.model.get('idAttachmentCover') === this.currentAttachment?.id;

        const babbleKey = this.currentAttachment?.get('isUpload')
          ? 'delete attachment'
          : 'remove attachment';

        Object.assign(data, {
          removeButtonText: l([babbleKey, 'button']),
          removeMessageText: l([babbleKey, 'message']),
          removeConfirmText: l([babbleKey, 'confirm']),
          removeCancelText: l([babbleKey, 'cancel']),
        });

        $('.js-display-frame-details', this.$el).html(
          templates.fill(AttachmentViewerFrameDetailsTemplate, data, {
            editable: this.model.editable(),
          }),
        );

        $('.js-show-prev-frame', this.$el).toggleClass(
          'hide',
          this.getPrevAttachmentIndex() == null,
        );
        $('.js-show-next-frame', this.$el).toggleClass(
          'hide',
          this.getNextAttachmentIndex() == null,
        );

        return Dates.update(this.el);
      })
      .done();
  }

  addNewFrame(model: Attachment, direction: 'left' | 'right' | 'center') {
    AttachmentHelpers.getAttachmentData(model)
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .then((data: any) => {
        if (direction != null) {
          data.directionClass = `attachment-viewer-frame-${direction}`;
        }
        const isImagePreviewable =
          Array.from(AttachmentHelpers.imageExts()).includes(data.ext) ||
          (data.previews != null ? data.previews.length : undefined) > 0;
        const isAudioable = Array.from(AttachmentHelpers.audioExts()).includes(
          data.ext,
        );
        const isVideoable = Array.from(AttachmentHelpers.videoExts()).includes(
          data.ext,
        );
        const isIFrameable = Array.from(
          AttachmentHelpers.iFrameableExts(),
        ).includes(data.ext);
        const isGoogleViewerable =
          !isTrelloAttachmentUrl(data.url) &&
          Array.from(AttachmentHelpers.googleViewerableExts()).includes(
            data.ext,
          );
        // eslint-disable-next-line @trello/enforce-variable-case
        const isPDFViewerable = AttachmentHelpers.isViewerablePDF(data.url);

        if (data.isExternal && !isImagePreviewable) {
          data.isPlaceholder = true;
        } else if (isImagePreviewable) {
          // eslint-disable-next-line @trello/enforce-variable-case
          let middle, previewURL;
          data.isImage = true;
          // HACK: We're preferring to use a preview over the default attachment
          // URL because the preview selection will try to avoid versions of the
          // image that have an EXIF rotation set
          //
          // Except ...
          //
          // For attachments created between 2019-09-22 to 2019-10-08
          // the width/height of the entry for the original image in the previews
          // array reflected the width/height after accounting for any EXIF rotation
          // and the scaled property stayed set to true
          //
          // During this period, we would have been unable to tell if the original
          // image could be put as the src of an image tag without worrying that there
          // might be an EXIF rotation that wasn't being accounted for; this resulted
          // in us rendering some images that were rotated 90 degrees
          //
          // On 2019-10-08 we attempted to fix this issue by setting scaled on the
          // original image to false (meaning it would never be used), and this caused
          // users to write in frustrated that it wasn't easy to see/download the
          // original image
          //
          // For images uploaded after 2019-10-16 the scaled property for the original
          // image is only set to true if the image is correctly oriented
          //
          // For images created during that time period 2019-09-22 to 2019-10-16, we're
          // going to display the original image no matter what the width/height/scaled
          // are telling us.  The image might be upside down, or rotated 90 degrees, but
          // we're expecting that's less of a problem than showing a smaller thumbnail.
          // (Users can work around this by downloading the image and uploading it again)
          // eslint-disable-next-line @trello/enforce-variable-case
          const unableToExcludeEXIFRotation =
            '2019-09-22' <= (middle = model.get('date')) &&
            middle < '2019-10-17';

          if (unableToExcludeEXIFRotation) {
            // Send a tracking event so some day we can justify removing the
            // unableToExcludeEXIFRotation check
            Analytics.sendOperationalEvent({
              action: 'errored',
              actionSubject: 'attachment',
              source: 'attachmentViewerModal',
              attributes: {
                reason: "Couldn't exclude EXIF rotation",
              },
            });
          }

          if (
            data.previews != null &&
            !unableToExcludeEXIFRotation &&
            (previewURL = Util.biggestPreview(data.previews)?.url) != null
          ) {
            data.url = previewURL;
          }
        } else if (isAudioable) {
          data.isAudio = true;
        } else if (isVideoable) {
          data.isVideo = true;
        } else if (isPDFViewerable) {
          data.isPDFViewerable = true;
        } else if (isIFrameable) {
          data.isIFrameable = true;
        } else if (isGoogleViewerable) {
          data.isIFrameable = true;
          const urlParam = encodeURIComponent(data.url);
          data.url = `https://docs.google.com/viewer?embedded=true&url=${urlParam}`;
        } else {
          data.isPlaceholder = true;
          data.openText = format('download');
        }

        $('.js-frames', this.$el).append(
          templates.fill(AttachmentViewerFrameTemplate, data, {
            editable: this.model.editable(),
          }),
        );

        // Must be .last() because above we call .append()
        // Multiple frames are preloaded when a preview is activated

        const $pdfRenderer = $('.pdfRenderer', this.$el).last();

        if (isPDFViewerable && $pdfRenderer.length) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          this.PDFRendererPromise.then((PDFRenderer: any) => {
            $pdfRenderer.find('.pdfRendererLoading').remove();
            const { unmount } = renderReactRoot(
              <ComponentWrapper>
                <ErrorBoundary
                  tags={{
                    ownershipArea: 'trello-web-eng',
                    feature: 'PDF Renderer',
                  }}
                >
                  <PDFRenderer url={data.url} />
                </ErrorBoundary>
              </ComponentWrapper>,
              $pdfRenderer[0],
            );
            this.pdfUnmounts[data.id] = unmount;
          });
        }

        return this.defer(() =>
          $('.attachment-viewer-frame').addClass(
            'attachment-viewer-frame-loaded',
          ),
        );
      })
      .done();

    return this;
  }

  // Cleanup method that unmounts a single PDF viewers when needed
  cleanupPDF(id: string) {
    const unmount = this.pdfUnmounts[id];
    unmount?.();
    delete this.pdfUnmounts[id];
  }

  // Cleanup method that unmounts all PDF viewers when needed
  cleanupAllPDFs() {
    for (const id in this.pdfUnmounts) {
      this.cleanupPDF(id);
    }
    this.pdfUnmounts = {};
  }

  focusFrame(model: Attachment, direction: 'right' | 'left' | 'center') {
    let frameToRemove, modelForNewFrame, oldFocusFrameClass;
    this.currentAttachment = model;
    const focusFrame = _.find(
      $('.attachment-viewer-frame', this.$el),
      (frame) => $(frame).attr('data-idAttachment') === model.id,
    );

    switch (direction) {
      case 'right':
        frameToRemove = $('.attachment-viewer-frame-left', this.$el);
        oldFocusFrameClass = 'attachment-viewer-frame-left';
        modelForNewFrame = this.getNextModel();
        break;
      case 'left':
        frameToRemove = $('.attachment-viewer-frame-right', this.$el);
        oldFocusFrameClass = 'attachment-viewer-frame-right';
        modelForNewFrame = this.getPrevModel();
        break;
      default:
        break;
    }

    // the old center frame needs to be shifted.
    $('.attachment-viewer-frame-center', this.$el)
      .removeClass('attachment-viewer-frame-center')
      // @ts-expect-error TS(2345): Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
      .addClass(oldFocusFrameClass);

    // center the new current frame
    // @ts-expect-error TS(2769): No overload matches this call.
    $(focusFrame)
      // @ts-expect-error TS(2339): Property 'removeClass' does not exist on type 'JQu... Remove this comment to see the full error message
      .removeClass('attachment-viewer-frame-left attachment-viewer-frame-right')
      .addClass('attachment-viewer-frame-center');

    // only three frames. unmount pdf renderer and remove one.
    const frameToRemoveId = frameToRemove?.data('idattachment');
    this.cleanupPDF(frameToRemoveId);
    frameToRemove?.remove();

    // add a new frame. if there is no model for the frame (i.e. we are at the
    // end or beginning), then add a blank frame as a buffer.
    if (modelForNewFrame != null) {
      this.addNewFrame(modelForNewFrame, direction);
    } else {
      this.clearHint();
    }

    this.setDetailsAndControls();
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  showNextFrame(e: any) {
    stopPropagationAndPreventDefault(e);
    const model = this.getNextModel();
    if (model != null) {
      this.focusFrame(model, 'right');
    }
    Analytics.sendScreenEvent({
      name: 'attachmentViewerModal',
      containers: model?.getCard()?.getAnalyticsContainers(),
      attributes: {
        type: this.currentAttachment?.getType(),
      },
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  showPrevFrame(e: any) {
    stopPropagationAndPreventDefault(e);
    const model = this.getPrevModel();
    if (model != null) {
      this.focusFrame(model, 'left');
    }
    Analytics.sendScreenEvent({
      name: 'attachmentViewerModal',
      containers: model?.getCard()?.getAnalyticsContainers(),
      attributes: {
        type: this.currentAttachment?.getType(),
      },
    });
  }

  hintNextFrame() {
    this.$el.addClass('attachment-viewer-frames-next-hint');
  }

  hintPrevFrame() {
    this.$el.addClass('attachment-viewer-frames-prev-hint');
  }

  clearHint() {
    this.$el.removeClass(
      'attachment-viewer-frames-next-hint attachment-viewer-frames-prev-hint',
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  stop(e: any) {
    return e?.stopPropagation();
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  openDeleteConfirm(e: any) {
    stopPropagationAndPreventDefault(e);
    $('.js-meta', this.$el).addClass('hide');
    $('.js-confirm-delete', this.$el).removeClass('hide');
    Analytics.sendViewedComponentEvent({
      // @ts-expect-error TS(2820): Type '"confirmDeleteAttachmentSection"' is not ass... Remove this comment to see the full error message
      componentName: 'confirmDeleteAttachmentSection',
      componentType: 'section',
      source: getScreenFromUrl(),
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  closeDeleteConfirm(e: any) {
    stopPropagationAndPreventDefault(e);
    $('.js-meta', this.$el).removeClass('hide');
    $('.js-confirm-delete', this.$el).addClass('hide');
    Analytics.sendClosedComponentEvent({
      // @ts-expect-error TS(2820): Type '"confirmDeleteAttachmentSection"' is not ass... Remove this comment to see the full error message
      componentName: 'confirmDeleteAttachmentSection',
      componentType: 'section',
      source: getScreenFromUrl(),
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  delete(e: any) {
    stopPropagationAndPreventDefault(e);
    this.currentAttachment?.destroy();
    this.close();
    Analytics.sendTrackEvent({
      action: 'deleted',
      actionSubject: 'attachment',
      source: 'attachmentViewerModal',
    });
  }

  renderToggleCover() {
    const canMakeCover =
      this.allowsCardCovers &&
      this.currentAttachment &&
      this.currentAttachment.get('previews').length > 0;
    $('.js-cover-options', this.$el).toggleClass('hide', !canMakeCover);

    const isCover =
      this.allowsCardCovers &&
      this.model.get('idAttachmentCover') === this.currentAttachment?.id;
    $('.js-make-cover-box', this.$el).toggleClass('hide', isCover);
    $('.js-remove-cover-box', this.$el).toggleClass('hide', !isCover);
  }

  makeCover() {
    const traceId = Analytics.startTask({
      taskName: 'edit-card/idAttachmentCover',
      source: 'attachmentViewerModal',
    });
    this.model.makeCover(
      this.currentAttachment,
      traceId,
      tracingCallback(
        {
          taskName: 'edit-card/idAttachmentCover',
          traceId,
          source: 'attachmentViewerModal',
        },
        (err, card) => {
          if (card) {
            Analytics.sendUpdatedCardFieldEvent({
              // @ts-expect-error TS(2820): Type '"idAttachment"' is not assignable to type 'A... Remove this comment to see the full error message
              field: 'idAttachment',
              // @ts-expect-error TS(2322): Type '"idAttachmentCover"' is not assignable to ty... Remove this comment to see the full error message
              source: 'idAttachmentCover',
              value: this.currentAttachment?.id,
              containers: {
                // @ts-expect-error TS(2571): Object is of type 'unknown'.
                card: { id: card.id },
                // @ts-expect-error TS(2571): Object is of type 'unknown'.
                list: { id: card.idList },
                // @ts-expect-error TS(2571): Object is of type 'unknown'.
                board: { id: card.idBoard },
              },
              attributes: { taskId: traceId },
            });
          }
        },
      ),
    );
    this.renderToggleCover();
  }

  removeCover() {
    const traceId = Analytics.startTask({
      taskName: 'edit-card/idAttachmentCover',
      source: 'attachmentViewerModal',
    });
    this.model.removeCover(
      null,
      traceId,
      tracingCallback(
        {
          taskName: 'edit-card/idAttachmentCover',
          traceId,
          source: 'attachmentViewerModal',
        },
        (err, card) => {
          if (card) {
            Analytics.sendUpdatedCardFieldEvent({
              field: 'idAttachmentCover',
              source: 'attachmentViewerModal',
              value: null,
              containers: {
                // @ts-expect-error TS(2571): Object is of type 'unknown'.
                card: { id: card.id },
                // @ts-expect-error TS(2571): Object is of type 'unknown'.
                list: { id: card.idList },
                // @ts-expect-error TS(2571): Object is of type 'unknown'.
                board: { id: card.idBoard },
              },
              attributes: { taskId: traceId },
            });
          }
        },
      ),
    );
    this.renderToggleCover();
  }

  close() {
    this.remove();
    Analytics.sendClosedComponentEvent({
      // @ts-expect-error TS(2322): Type '"attachmentViewerModal"' is not assignable t... Remove this comment to see the full error message
      componentName: 'attachmentViewerModal',
      componentType: 'modal',
      source: getScreenFromUrl(),
    });
  }

  getIdPluginAttachments() {
    this.getIdTrelloAttachments();

    return pluginRunner
      .all({
        timeout: 5000,
        command: 'attachment-sections',
        card: this.model,
        board: this.model.getBoard(),
        options: {
          entries: serializeAttachments(this.model.attachmentList),
        },
      })
      .then((pluginAttachments) => {
        this.idPluginAttachments = _.chain(pluginAttachments)
          .map((section) =>
            (section != null ? section.claimed : undefined)?.map(
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              (attachment: any) => attachment.id,
            ),
          )
          .flatten()
          .value();

        return this.render();
      });
  }

  getIdTrelloAttachments() {
    return Promise.map(
      this.model.attachmentList.models,
      AttachmentHelpers.getAttachmentData,
    ).then((allAttachmentData) => {
      return (this.idTrelloAttachments = _.chain(allAttachmentData)
        .filter(
          (attachmentData) =>
            attachmentData.isKnownService &&
            ['trello card', 'trello board'].includes(attachmentData.type),
        )
        .map(({ id }) => id)
        .value());
    });
  }
}

AttachmentViewerView.initClass();
export { AttachmentViewerView };
