import type { FormEvent } from 'react';
import { Component } from 'react';

interface InlineLinkProps {
  text: string;
  href?: string;
  onClick?: (e: FormEvent) => void;
}

export class InlineLink extends Component<InlineLinkProps> {
  render() {
    const { text, href = '#', onClick = () => {} } = this.props;

    return (
      <a
        onClick={onClick}
        href={href}
        rel="noopener noreferrer"
        target="_blank"
      >
        {text}
      </a>
    );
  }
}
