import type { FunctionComponent } from 'react';
import { Suspense } from 'react';

import {
  ChunkLoadErrorBoundary,
  ErrorBoundary,
} from '@trello/error-boundaries';
import { useLazyComponent } from '@trello/use-lazy-component';

import type { BoardSidebarLabelsScreenProps } from './BoardSidebarLabelsScreen';

export const LazyBoardSidebarLabelsScreen: FunctionComponent<
  BoardSidebarLabelsScreenProps
> = (props) => {
  const BoardSidebarLabelsScreen = useLazyComponent(
    () =>
      import(
        /* webpackChunkName: "board-sidebar-labels-screen" */ './BoardSidebarLabelsScreen'
      ),
    { namedImport: 'BoardSidebarLabelsScreen' },
  );
  return (
    <ErrorBoundary
      tags={{ ownershipArea: 'trello-web-eng', feature: 'Labels Popover' }}
    >
      <ChunkLoadErrorBoundary fallback={null}>
        <Suspense fallback={null}>
          <BoardSidebarLabelsScreen {...props} />
        </Suspense>
      </ChunkLoadErrorBoundary>
    </ErrorBoundary>
  );
};
