/* eslint-disable
    eqeqeq,
*/
// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS104: Avoid inline assignments
 * DS204: Change includes calls to have a more natural evaluation order
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import { FormattedMessage } from 'react-intl';
import _ from 'underscore';

import { ComponentWrapper, renderReactRoot } from '@trello/component-wrapper';

import type { Board } from 'app/scripts/models/Board';
import type { Plugin } from 'app/scripts/models/Plugin';
import { View } from 'app/scripts/views/internal/View';
import { PowerUpItemType } from 'app/src/components/PowerUp';
import { sortFeaturedPowerUps } from 'app/src/components/PowerUpDirectory/HomePage';
import { DirectoryPluginItemView } from './DirectoryPluginItemView';
import type { DirectoryView } from './DirectoryView';
import { filterUnavailablePlugins } from './filterUnavailablePlugins';

function DirectoryHomeComponent() {
  return (
    <div>
      <div className="directory-content-body">
        <div className="directory-heading-wrapper">
          <FormattedMessage
            tagName="h2"
            id="templates.directory_home.featured-power-ups"
            defaultMessage="Featured Power-Ups"
            description="Title for the featured power-ups section of the directory home page"
          />
        </div>
        <div className="directory-featured-powerups js-featured-power-ups-container" />
        <div className="directory-heading-wrapper">
          <FormattedMessage
            tagName="h2"
            id="templates.directory_home.integrations"
            defaultMessage="Trello Integrations"
            description="Title for the integrations section of the directory home page"
          />
        </div>
        <div className="directory-featured-powerups js-integrations-container" />
        <div className="directory-heading-wrapper">
          <FormattedMessage
            tagName="h2"
            id="templates.directory_home.essential-power-ups"
            defaultMessage="Essential Power-Ups"
            description="Title for the essential power-ups section of the directory home page"
          />
        </div>
        <div className="directory-featured-powerups js-essential-power-ups-container" />
        <div className="directory-heading-wrapper">
          <FormattedMessage
            tagName="h2"
            id="templates.directory_home.other-great-power-ups"
            defaultMessage="Other great Power-Ups"
            description="Title for the other great power-ups section of the directory home page"
          />
        </div>
        <div className="directory-featured-powerups js-other-power-ups-container" />
      </div>
    </div>
  );
}

interface DirectoryHomeView {
  directoryView: DirectoryView;
  essentialPlugins: Plugin[];
  featuredPlugins: Plugin[];
  integrations: Plugin[];
}

class DirectoryHomeView extends View<Board> {
  initialize({ directoryView }: { directoryView: DirectoryView }) {
    this.directoryView = directoryView;
    return super.initialize(...arguments);
  }

  eventSource() {
    return 'boardDirectoryFeaturedScreen' as const;
  }

  getPluginsByTags(tags: ('featured' | 'essential' | 'integration')[]) {
    return _.filter(this.directoryView.plugins, (plugin) =>
      _.every(tags, (tag) => _.contains(plugin.get('tags'), tag)),
    );
  }

  getFeaturedPlugins() {
    const featuredPlugins = this.getPluginsByTags(['featured']);
    const sortedPluginsJson = sortFeaturedPowerUps(
      _.map(featuredPlugins, (plugin) => plugin.toJSON()),
    );
    const sortedPlugins = _.map(sortedPluginsJson, (pluginJSON) =>
      _.find(featuredPlugins, (plugin) => plugin.id === pluginJSON.id),
    ) as Plugin[];
    return (this.featuredPlugins = sortedPlugins);
  }

  getEssentialPlugins() {
    return (
      this.essentialPlugins ??
      (this.essentialPlugins = this.getPluginsByTags(['essential']))
    );
  }

  getIntegrations() {
    if (this.integrations != null) {
      return this.integrations;
    }
    const integrations = this.getPluginsByTags(['integration']);
    return (this.integrations = integrations);
  }

  getOtherPowerUps() {
    const excludeFeaturedAndEssential = _.filter(
      this.directoryView.plugins,
      function (plugin) {
        return (
          !_.contains(plugin.get('tags'), 'featured') &&
          !_.contains(plugin.get('tags'), 'essential') &&
          !_.contains(plugin.get('tags'), 'integration')
        );
      },
    );
    return _.sample<Plugin>(excludeFeaturedAndEssential, 12);
  }

  render() {
    renderReactRoot(
      <div>
        <ComponentWrapper>
          <DirectoryHomeComponent />
        </ComponentWrapper>
      </div>,
      this.el,
    );

    this.defer(() => this.renderItems());

    return this;
  }

  renderItems() {
    const featuredPlugins = filterUnavailablePlugins(this.getFeaturedPlugins());
    const essentialPlugins = filterUnavailablePlugins(
      this.getEssentialPlugins(),
    );
    const integrations = filterUnavailablePlugins(this.getIntegrations());
    const otherPlugins = filterUnavailablePlugins(this.getOtherPowerUps());
    const enabledPlugins = this.directoryView.getEnabledPlugins();

    const listedPlugins = _.flatten([
      featuredPlugins,
      integrations,
      essentialPlugins,
      otherPlugins,
    ]);

    const featuredPluginItemSubviews = _.map(featuredPlugins, (plugin) => {
      return this.subview(
        DirectoryPluginItemView,
        this.model,
        {
          atomType: PowerUpItemType.Featured,
          plugin,
          directoryView: this.directoryView,
        },
        plugin.id + '_featured',
      );
    });

    const essentialPluginItemSubviews = _.map(essentialPlugins, (plugin) => {
      return this.subview(
        DirectoryPluginItemView,
        this.model,
        {
          atomType: PowerUpItemType.Featured,
          plugin,
          directoryView: this.directoryView,
        },
        plugin.id + '_essential',
      );
    });

    const integrationItemSubviews = _.map(integrations, (plugin) => {
      return this.subview(
        DirectoryPluginItemView,
        this.model,
        {
          atomType: PowerUpItemType.Featured,
          plugin,
          directoryView: this.directoryView,
        },
        plugin.id,
      );
    });

    const otherPluginItemSubviews = _.map(otherPlugins, (plugin) => {
      return this.subview(
        DirectoryPluginItemView,
        this.model,
        {
          atomType: PowerUpItemType.Description,
          plugin,
          directoryView: this.directoryView,
        },
        plugin.id,
      );
    });

    this.appendSubviews(
      featuredPluginItemSubviews,
      this.$el.find('.js-featured-power-ups-container'),
    );
    this.appendSubviews(
      essentialPluginItemSubviews,
      this.$el.find('.js-essential-power-ups-container'),
    );
    this.appendSubviews(
      integrationItemSubviews,
      this.$el.find('.js-integrations-container'),
    );
    this.appendSubviews(
      otherPluginItemSubviews,
      this.$el.find('.js-other-power-ups-container'),
    );

    const listedPowerUpsEnabled = _.intersection(
      _.map(listedPlugins, 'id'),
      _.map(enabledPlugins, 'id'),
    );

    this.directoryView.trackScreenEvent(
      this.eventSource(),
      {
        totalListed: listedPlugins.length,
        totalListedEnabled: listedPowerUpsEnabled.length,
        totalEnabled: enabledPlugins.length,
        allPowerUpsEnabled: _.map(enabledPlugins, 'id'),
      },
      // @ts-expect-error I think this is a bug because trackScreenEvent doesn't accept a 3rd argument
      listedPowerUpsEnabled,
    );
  }
}

export { DirectoryHomeView };
