// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.YNFsevwDpYV60E {
  padding: 0;
  border: none;
  background-color: transparent;
  box-shadow: none;
  color: var(--ds-icon, #44546f);
  text-decoration: none;
}
.YNFsevwDpYV60E:active,
.YNFsevwDpYV60E:hover {
  border: none;
  outline: 0;
  background-color: transparent;
  box-shadow: none;
  color: var(--ds-icon-accent-gray, #758195);
  cursor: pointer;
}
`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"closeButton": `YNFsevwDpYV60E`
};
export default ___CSS_LOADER_EXPORT___;
