import { useCallback, useEffect, useState } from 'react';

import type { TouchpointSourceType } from '@trello/ad-sequencer';
import { Analytics } from '@trello/atlassian-analytics';
import type { ExperimentVariations } from '@trello/feature-gate-client';
import { useGetExperimentValue } from '@trello/feature-gate-client';

import { type TrelloCrossFlowExperiment } from '../TrelloCrossFlow';
import type { CrossFlowFeatureKeys } from './isSourceTargetedByFeatureFlag';
import { isSourceTargetedByFeatureKey } from './isSourceTargetedByFeatureFlag';

export interface CrossFlowExperimentParams<T> {
  experimentFeatureKey: T;
  source: TouchpointSourceType;
  eligibilityCheckResults: boolean[];
  deferredEligibilityCheckResults?: boolean[];
}

type CrossFlowExperimentReturnType<T extends CrossFlowFeatureKeys> = Omit<
  TrelloCrossFlowExperiment<ExperimentVariations<T, 'cohort'>>,
  'open'
> & {
  isImmediateRuntimeEligibilityChecksPassed: boolean;
  cohort: ExperimentVariations<T, 'cohort'>;
};

export const useTrelloCrossFlowExperiment = <T extends CrossFlowFeatureKeys>({
  experimentFeatureKey,
  source,
  eligibilityCheckResults,
  deferredEligibilityCheckResults = [],
}: CrossFlowExperimentParams<T>): CrossFlowExperimentReturnType<T> => {
  const [fireExposureEvent, setFireExposureEvent] = useState(false);

  const { cohort } = useGetExperimentValue({
    experimentName: experimentFeatureKey,
    parameter: 'cohort',
    fireExposureEvent,
  });

  const isSourceTargeted = isSourceTargetedByFeatureKey({
    featureKey: experimentFeatureKey,
    source,
  });

  const getIsTargeted = useCallback(() => {
    return cohort !== 'not-enrolled';
  }, [cohort]);

  const isImmediateRuntimeEligibilityChecksPassed = [
    ...eligibilityCheckResults,
    isSourceTargeted,
    getIsTargeted(),
  ].every((result) => result === true);

  const isDeferredRuntimeEligibilityChecksPassed =
    deferredEligibilityCheckResults.every((result) => result === true);

  const isEnrolled =
    isImmediateRuntimeEligibilityChecksPassed &&
    isDeferredRuntimeEligibilityChecksPassed;

  const getExperimentCohort = useCallback(() => {
    if (isEnrolled) {
      setFireExposureEvent(true);
      return cohort;
    }

    return 'not-enrolled';
  }, [cohort, isEnrolled]);

  useEffect(() => {
    if (experimentFeatureKey === 'trello_xf_template_gallery_v1_non_ppm') {
      Analytics.sendOperationalEvent({
        action: 'checked',
        actionSubject: 'crossFlowExperiment',
        source,
        attributes: {
          experiment: experimentFeatureKey,
          cohort,
          isImmediateRuntimeEligibilityChecksPassed,
          isDeferredRuntimeEligibilityChecksPassed,
        },
      });
    }
  }, [
    cohort,
    experimentFeatureKey,
    isDeferredRuntimeEligibilityChecksPassed,
    isEnrolled,
    isImmediateRuntimeEligibilityChecksPassed,
    source,
  ]);

  const enrolledCohort = isEnrolled ? cohort : 'not-enrolled';

  return {
    experimentName: experimentFeatureKey,
    cohort: enrolledCohort,
    isImmediateRuntimeEligibilityChecksPassed,
    isEnrolled,
    getIsTargeted,
    getExperimentCohort,
  };
};
