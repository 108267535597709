import type { FunctionComponent } from 'react';
import { Suspense } from 'react';

import { ChunkLoadErrorBoundary } from '@trello/error-boundaries';
import { useLazyComponent } from '@trello/use-lazy-component';

import type { EmailToBoardButtonProps } from './EmailToBoardButton';
import { EmailToBoardButtonSkeleton } from './EmailToBoardButtonSkeleton';

export const LazyEmailToBoardButton: FunctionComponent<
  EmailToBoardButtonProps
> = (emailToBoardButtonProps) => {
  const EmailToBoardButton = useLazyComponent(
    () =>
      import(
        /* webpackChunkName: "email-to-board-button" */ './EmailToBoardButton'
      ),
    {
      namedImport: 'EmailToBoardButton',
    },
  );

  return (
    <ChunkLoadErrorBoundary fallback={null}>
      <Suspense
        fallback={
          emailToBoardButtonProps.isPowerUpDirectory ? null : (
            <EmailToBoardButtonSkeleton />
          )
        }
      >
        <EmailToBoardButton {...emailToBoardButtonProps} />
      </Suspense>
    </ChunkLoadErrorBoundary>
  );
};
