import eightball from 'resources/images/emoji/8ball.png';
import number100 from 'resources/images/emoji/100.png';
import number1234 from 'resources/images/emoji/1234.png';
import a from 'resources/images/emoji/a.png';
import ab from 'resources/images/emoji/ab.png';
import abc from 'resources/images/emoji/abc.png';
import abcd from 'resources/images/emoji/abcd.png';
import accept from 'resources/images/emoji/accept.png';
import aerial_tramway from 'resources/images/emoji/aerial_tramway.png';
import airplane from 'resources/images/emoji/airplane.png';
import alarm_clock from 'resources/images/emoji/alarm_clock.png';
import alien from 'resources/images/emoji/alien.png';
import ambulance from 'resources/images/emoji/ambulance.png';
import anchor from 'resources/images/emoji/anchor.png';
import angel from 'resources/images/emoji/angel.png';
import anger from 'resources/images/emoji/anger.png';
import angry from 'resources/images/emoji/angry.png';
import anguished from 'resources/images/emoji/anguished.png';
import ant from 'resources/images/emoji/ant.png';
import apple from 'resources/images/emoji/apple.png';
import aquarius from 'resources/images/emoji/aquarius.png';
import aries from 'resources/images/emoji/aries.png';
import arrow_backward from 'resources/images/emoji/arrow_backward.png';
import arrow_double_down from 'resources/images/emoji/arrow_double_down.png';
import arrow_double_up from 'resources/images/emoji/arrow_double_up.png';
import arrow_down from 'resources/images/emoji/arrow_down.png';
import arrow_down_small from 'resources/images/emoji/arrow_down_small.png';
import arrow_forward from 'resources/images/emoji/arrow_forward.png';
import arrow_heading_down from 'resources/images/emoji/arrow_heading_down.png';
import arrow_heading_up from 'resources/images/emoji/arrow_heading_up.png';
import arrow_left from 'resources/images/emoji/arrow_left.png';
import arrow_lower_left from 'resources/images/emoji/arrow_lower_left.png';
import arrow_lower_right from 'resources/images/emoji/arrow_lower_right.png';
import arrow_right from 'resources/images/emoji/arrow_right.png';
import arrow_right_hook from 'resources/images/emoji/arrow_right_hook.png';
import arrow_up from 'resources/images/emoji/arrow_up.png';
import arrow_up_down from 'resources/images/emoji/arrow_up_down.png';
import arrow_up_small from 'resources/images/emoji/arrow_up_small.png';
import arrow_upper_left from 'resources/images/emoji/arrow_upper_left.png';
import arrow_upper_right from 'resources/images/emoji/arrow_upper_right.png';
import arrows_clockwise from 'resources/images/emoji/arrows_clockwise.png';
import arrows_counterclockwise from 'resources/images/emoji/arrows_counterclockwise.png';
import art from 'resources/images/emoji/art.png';
import articulated_lorry from 'resources/images/emoji/articulated_lorry.png';
import astonished from 'resources/images/emoji/astonished.png';
import athletic_shoe from 'resources/images/emoji/athletic_shoe.png';
import atm from 'resources/images/emoji/atm.png';
import b from 'resources/images/emoji/b.png';
import baby from 'resources/images/emoji/baby.png';
import baby_bottle from 'resources/images/emoji/baby_bottle.png';
import baby_chick from 'resources/images/emoji/baby_chick.png';
import baby_symbol from 'resources/images/emoji/baby_symbol.png';
import back from 'resources/images/emoji/back.png';
import baggage_claim from 'resources/images/emoji/baggage_claim.png';
import balloon from 'resources/images/emoji/balloon.png';
import ballot_box_with_check from 'resources/images/emoji/ballot_box_with_check.png';
import bamboo from 'resources/images/emoji/bamboo.png';
import banana from 'resources/images/emoji/banana.png';
import bangbang from 'resources/images/emoji/bangbang.png';
import bank from 'resources/images/emoji/bank.png';
import bar_chart from 'resources/images/emoji/bar_chart.png';
import barber from 'resources/images/emoji/barber.png';
import baseball from 'resources/images/emoji/baseball.png';
import basketball from 'resources/images/emoji/basketball.png';
import bath from 'resources/images/emoji/bath.png';
import bathtub from 'resources/images/emoji/bathtub.png';
import battery from 'resources/images/emoji/battery.png';
import bear from 'resources/images/emoji/bear.png';
import bee from 'resources/images/emoji/bee.png';
import beer from 'resources/images/emoji/beer.png';
import beers from 'resources/images/emoji/beers.png';
import beetle from 'resources/images/emoji/beetle.png';
import beginner from 'resources/images/emoji/beginner.png';
import bell from 'resources/images/emoji/bell.png';
import bento from 'resources/images/emoji/bento.png';
import bicyclist from 'resources/images/emoji/bicyclist.png';
import bike from 'resources/images/emoji/bike.png';
import bikini from 'resources/images/emoji/bikini.png';
import bird from 'resources/images/emoji/bird.png';
import birthday from 'resources/images/emoji/birthday.png';
import black_circle from 'resources/images/emoji/black_circle.png';
import black_joker from 'resources/images/emoji/black_joker.png';
import black_large_square from 'resources/images/emoji/black_large_square.png';
import black_medium_small_square from 'resources/images/emoji/black_medium_small_square.png';
import black_medium_square from 'resources/images/emoji/black_medium_square.png';
import black_nib from 'resources/images/emoji/black_nib.png';
import black_small_square from 'resources/images/emoji/black_small_square.png';
import black_square_button from 'resources/images/emoji/black_square_button.png';
import blossom from 'resources/images/emoji/blossom.png';
import blowfish from 'resources/images/emoji/blowfish.png';
import blue_book from 'resources/images/emoji/blue_book.png';
import blue_car from 'resources/images/emoji/blue_car.png';
import blue_heart from 'resources/images/emoji/blue_heart.png';
import blush from 'resources/images/emoji/blush.png';
import boar from 'resources/images/emoji/boar.png';
import boat from 'resources/images/emoji/boat.png';
import bomb from 'resources/images/emoji/bomb.png';
import book from 'resources/images/emoji/book.png';
import bookmark from 'resources/images/emoji/bookmark.png';
import bookmark_tabs from 'resources/images/emoji/bookmark_tabs.png';
import books from 'resources/images/emoji/books.png';
import boom from 'resources/images/emoji/boom.png';
import boot from 'resources/images/emoji/boot.png';
import bouquet from 'resources/images/emoji/bouquet.png';
import bow from 'resources/images/emoji/bow.png';
import bowling from 'resources/images/emoji/bowling.png';
import bowtie from 'resources/images/emoji/bowtie.png';
import boy from 'resources/images/emoji/boy.png';
import bread from 'resources/images/emoji/bread.png';
import bride_with_veil from 'resources/images/emoji/bride_with_veil.png';
import bridge_at_night from 'resources/images/emoji/bridge_at_night.png';
import briefcase from 'resources/images/emoji/briefcase.png';
import broken_heart from 'resources/images/emoji/broken_heart.png';
import bug from 'resources/images/emoji/bug.png';
import bulb from 'resources/images/emoji/bulb.png';
import bullettrain_front from 'resources/images/emoji/bullettrain_front.png';
import bullettrain_side from 'resources/images/emoji/bullettrain_side.png';
import bus from 'resources/images/emoji/bus.png';
import busstop from 'resources/images/emoji/busstop.png';
import bust_in_silhouette from 'resources/images/emoji/bust_in_silhouette.png';
import busts_in_silhouette from 'resources/images/emoji/busts_in_silhouette.png';
import cactus from 'resources/images/emoji/cactus.png';
import cake from 'resources/images/emoji/cake.png';
import calendar from 'resources/images/emoji/calendar.png';
import calling from 'resources/images/emoji/calling.png';
import camel from 'resources/images/emoji/camel.png';
import camera from 'resources/images/emoji/camera.png';
import cancer from 'resources/images/emoji/cancer.png';
import candy from 'resources/images/emoji/candy.png';
import capital_abcd from 'resources/images/emoji/capital_abcd.png';
import capricorn from 'resources/images/emoji/capricorn.png';
import car from 'resources/images/emoji/car.png';
import card_index from 'resources/images/emoji/card_index.png';
import carousel_horse from 'resources/images/emoji/carousel_horse.png';
import cat from 'resources/images/emoji/cat.png';
import cat2 from 'resources/images/emoji/cat2.png';
import cd from 'resources/images/emoji/cd.png';
import chart from 'resources/images/emoji/chart.png';
import chart_with_downwards_trend from 'resources/images/emoji/chart_with_downwards_trend.png';
import chart_with_upwards_trend from 'resources/images/emoji/chart_with_upwards_trend.png';
import checkered_flag from 'resources/images/emoji/checkered_flag.png';
import cherries from 'resources/images/emoji/cherries.png';
import cherry_blossom from 'resources/images/emoji/cherry_blossom.png';
import chestnut from 'resources/images/emoji/chestnut.png';
import chicken from 'resources/images/emoji/chicken.png';
import children_crossing from 'resources/images/emoji/children_crossing.png';
import chocolate_bar from 'resources/images/emoji/chocolate_bar.png';
import christmas_tree from 'resources/images/emoji/christmas_tree.png';
import church from 'resources/images/emoji/church.png';
import cinema from 'resources/images/emoji/cinema.png';
import circus_tent from 'resources/images/emoji/circus_tent.png';
import city_sunrise from 'resources/images/emoji/city_sunrise.png';
import city_sunset from 'resources/images/emoji/city_sunset.png';
import cl from 'resources/images/emoji/cl.png';
import clap from 'resources/images/emoji/clap.png';
import clapper from 'resources/images/emoji/clapper.png';
import clipboard from 'resources/images/emoji/clipboard.png';
import clock1 from 'resources/images/emoji/clock1.png';
import clock2 from 'resources/images/emoji/clock2.png';
import clock3 from 'resources/images/emoji/clock3.png';
import clock4 from 'resources/images/emoji/clock4.png';
import clock5 from 'resources/images/emoji/clock5.png';
import clock6 from 'resources/images/emoji/clock6.png';
import clock7 from 'resources/images/emoji/clock7.png';
import clock8 from 'resources/images/emoji/clock8.png';
import clock9 from 'resources/images/emoji/clock9.png';
import clock10 from 'resources/images/emoji/clock10.png';
import clock11 from 'resources/images/emoji/clock11.png';
import clock12 from 'resources/images/emoji/clock12.png';
import clock130 from 'resources/images/emoji/clock130.png';
import clock230 from 'resources/images/emoji/clock230.png';
import clock330 from 'resources/images/emoji/clock330.png';
import clock430 from 'resources/images/emoji/clock430.png';
import clock530 from 'resources/images/emoji/clock530.png';
import clock630 from 'resources/images/emoji/clock630.png';
import clock730 from 'resources/images/emoji/clock730.png';
import clock830 from 'resources/images/emoji/clock830.png';
import clock930 from 'resources/images/emoji/clock930.png';
import clock1030 from 'resources/images/emoji/clock1030.png';
import clock1130 from 'resources/images/emoji/clock1130.png';
import clock1230 from 'resources/images/emoji/clock1230.png';
import closed_book from 'resources/images/emoji/closed_book.png';
import closed_lock_with_key from 'resources/images/emoji/closed_lock_with_key.png';
import closed_umbrella from 'resources/images/emoji/closed_umbrella.png';
import cloud from 'resources/images/emoji/cloud.png';
import clubs from 'resources/images/emoji/clubs.png';
import cn from 'resources/images/emoji/cn.png';
import cocktail from 'resources/images/emoji/cocktail.png';
import coffee from 'resources/images/emoji/coffee.png';
import cold_sweat from 'resources/images/emoji/cold_sweat.png';
import collision from 'resources/images/emoji/collision.png';
import computer from 'resources/images/emoji/computer.png';
import confetti_ball from 'resources/images/emoji/confetti_ball.png';
import confounded from 'resources/images/emoji/confounded.png';
import confused from 'resources/images/emoji/confused.png';
import congratulations from 'resources/images/emoji/congratulations.png';
import construction from 'resources/images/emoji/construction.png';
import construction_worker from 'resources/images/emoji/construction_worker.png';
import convenience_store from 'resources/images/emoji/convenience_store.png';
import cookie from 'resources/images/emoji/cookie.png';
import cool from 'resources/images/emoji/cool.png';
import cop from 'resources/images/emoji/cop.png';
import copyright from 'resources/images/emoji/copyright.png';
import corn from 'resources/images/emoji/corn.png';
import couple from 'resources/images/emoji/couple.png';
import couple_with_heart from 'resources/images/emoji/couple_with_heart.png';
import couplekiss from 'resources/images/emoji/couplekiss.png';
import cow from 'resources/images/emoji/cow.png';
import cow2 from 'resources/images/emoji/cow2.png';
import credit_card from 'resources/images/emoji/credit_card.png';
import crescent_moon from 'resources/images/emoji/crescent_moon.png';
import crocodile from 'resources/images/emoji/crocodile.png';
import crossed_flags from 'resources/images/emoji/crossed_flags.png';
import crown from 'resources/images/emoji/crown.png';
import cry from 'resources/images/emoji/cry.png';
import crying_cat_face from 'resources/images/emoji/crying_cat_face.png';
import crystal_ball from 'resources/images/emoji/crystal_ball.png';
import cupid from 'resources/images/emoji/cupid.png';
import curly_loop from 'resources/images/emoji/curly_loop.png';
import currency_exchange from 'resources/images/emoji/currency_exchange.png';
import curry from 'resources/images/emoji/curry.png';
import custard from 'resources/images/emoji/custard.png';
import customs from 'resources/images/emoji/customs.png';
import cyclone from 'resources/images/emoji/cyclone.png';
import dancer from 'resources/images/emoji/dancer.png';
import dancers from 'resources/images/emoji/dancers.png';
import dango from 'resources/images/emoji/dango.png';
import dart from 'resources/images/emoji/dart.png';
import dash from 'resources/images/emoji/dash.png';
import date from 'resources/images/emoji/date.png';
import de from 'resources/images/emoji/de.png';
import deciduous_tree from 'resources/images/emoji/deciduous_tree.png';
import department_store from 'resources/images/emoji/department_store.png';
import diamond_shape_with_a_dot_inside from 'resources/images/emoji/diamond_shape_with_a_dot_inside.png';
import diamonds from 'resources/images/emoji/diamonds.png';
import disappointed from 'resources/images/emoji/disappointed.png';
import disappointed_relieved from 'resources/images/emoji/disappointed_relieved.png';
import dizzy from 'resources/images/emoji/dizzy.png';
import dizzy_face from 'resources/images/emoji/dizzy_face.png';
import do_not_litter from 'resources/images/emoji/do_not_litter.png';
import dog from 'resources/images/emoji/dog.png';
import dog2 from 'resources/images/emoji/dog2.png';
import dollar from 'resources/images/emoji/dollar.png';
import dolls from 'resources/images/emoji/dolls.png';
import dolphin from 'resources/images/emoji/dolphin.png';
import door from 'resources/images/emoji/door.png';
import doughnut from 'resources/images/emoji/doughnut.png';
import dragon from 'resources/images/emoji/dragon.png';
import dragon_face from 'resources/images/emoji/dragon_face.png';
import dress from 'resources/images/emoji/dress.png';
import dromedary_camel from 'resources/images/emoji/dromedary_camel.png';
import droplet from 'resources/images/emoji/droplet.png';
import dvd from 'resources/images/emoji/dvd.png';
import edashmail from 'resources/images/emoji/e-mail.png';
import ear from 'resources/images/emoji/ear.png';
import ear_of_rice from 'resources/images/emoji/ear_of_rice.png';
import earth_africa from 'resources/images/emoji/earth_africa.png';
import earth_americas from 'resources/images/emoji/earth_americas.png';
import earth_asia from 'resources/images/emoji/earth_asia.png';
import egg from 'resources/images/emoji/egg.png';
import eggplant from 'resources/images/emoji/eggplant.png';
import eight from 'resources/images/emoji/eight.png';
import eight_pointed_black_star from 'resources/images/emoji/eight_pointed_black_star.png';
import eight_spoked_asterisk from 'resources/images/emoji/eight_spoked_asterisk.png';
import electric_plug from 'resources/images/emoji/electric_plug.png';
import elephant from 'resources/images/emoji/elephant.png';
import email from 'resources/images/emoji/email.png';
import end from 'resources/images/emoji/end.png';
import envelope from 'resources/images/emoji/envelope.png';
import envelope_with_arrow from 'resources/images/emoji/envelope_with_arrow.png';
import es from 'resources/images/emoji/es.png';
import euro from 'resources/images/emoji/euro.png';
import european_castle from 'resources/images/emoji/european_castle.png';
import european_post_office from 'resources/images/emoji/european_post_office.png';
import evergreen_tree from 'resources/images/emoji/evergreen_tree.png';
import exclamation from 'resources/images/emoji/exclamation.png';
import expressionless from 'resources/images/emoji/expressionless.png';
import eyeglasses from 'resources/images/emoji/eyeglasses.png';
import eyes from 'resources/images/emoji/eyes.png';
import facepalm from 'resources/images/emoji/facepalm.png';
import facepunch from 'resources/images/emoji/facepunch.png';
import factory from 'resources/images/emoji/factory.png';
import fallen_leaf from 'resources/images/emoji/fallen_leaf.png';
import family from 'resources/images/emoji/family.png';
import fast_forward from 'resources/images/emoji/fast_forward.png';
import fax from 'resources/images/emoji/fax.png';
import fearful from 'resources/images/emoji/fearful.png';
import feelsgood from 'resources/images/emoji/feelsgood.png';
import feet from 'resources/images/emoji/feet.png';
import ferris_wheel from 'resources/images/emoji/ferris_wheel.png';
import file_folder from 'resources/images/emoji/file_folder.png';
import finnadie from 'resources/images/emoji/finnadie.png';
import fire from 'resources/images/emoji/fire.png';
import fire_engine from 'resources/images/emoji/fire_engine.png';
import fireworks from 'resources/images/emoji/fireworks.png';
import first_quarter_moon from 'resources/images/emoji/first_quarter_moon.png';
import first_quarter_moon_with_face from 'resources/images/emoji/first_quarter_moon_with_face.png';
import fish from 'resources/images/emoji/fish.png';
import fish_cake from 'resources/images/emoji/fish_cake.png';
import fishing_pole_and_fish from 'resources/images/emoji/fishing_pole_and_fish.png';
import fist from 'resources/images/emoji/fist.png';
import five from 'resources/images/emoji/five.png';
import flags from 'resources/images/emoji/flags.png';
import flashlight from 'resources/images/emoji/flashlight.png';
import flipper from 'resources/images/emoji/flipper.png';
import floppy_disk from 'resources/images/emoji/floppy_disk.png';
import flower_playing_cards from 'resources/images/emoji/flower_playing_cards.png';
import flushed from 'resources/images/emoji/flushed.png';
import foggy from 'resources/images/emoji/foggy.png';
import football from 'resources/images/emoji/football.png';
import footprints from 'resources/images/emoji/footprints.png';
import fork_and_knife from 'resources/images/emoji/fork_and_knife.png';
import fountain from 'resources/images/emoji/fountain.png';
import four from 'resources/images/emoji/four.png';
import four_leaf_clover from 'resources/images/emoji/four_leaf_clover.png';
import fr from 'resources/images/emoji/fr.png';
import free from 'resources/images/emoji/free.png';
import fried_shrimp from 'resources/images/emoji/fried_shrimp.png';
import fries from 'resources/images/emoji/fries.png';
import frog from 'resources/images/emoji/frog.png';
import frowning from 'resources/images/emoji/frowning.png';
import fu from 'resources/images/emoji/fu.png';
import fuelpump from 'resources/images/emoji/fuelpump.png';
import full_moon from 'resources/images/emoji/full_moon.png';
import full_moon_with_face from 'resources/images/emoji/full_moon_with_face.png';
import game_die from 'resources/images/emoji/game_die.png';
import gb from 'resources/images/emoji/gb.png';
import gem from 'resources/images/emoji/gem.png';
import gemini from 'resources/images/emoji/gemini.png';
import ghost from 'resources/images/emoji/ghost.png';
import gift from 'resources/images/emoji/gift.png';
import gift_heart from 'resources/images/emoji/gift_heart.png';
import girl from 'resources/images/emoji/girl.png';
import globe_with_meridians from 'resources/images/emoji/globe_with_meridians.png';
import goat from 'resources/images/emoji/goat.png';
import goberserk from 'resources/images/emoji/goberserk.png';
import godmode from 'resources/images/emoji/godmode.png';
import golf from 'resources/images/emoji/golf.png';
import grapes from 'resources/images/emoji/grapes.png';
import green_apple from 'resources/images/emoji/green_apple.png';
import green_book from 'resources/images/emoji/green_book.png';
import green_heart from 'resources/images/emoji/green_heart.png';
import grey_exclamation from 'resources/images/emoji/grey_exclamation.png';
import grey_question from 'resources/images/emoji/grey_question.png';
import grimacing from 'resources/images/emoji/grimacing.png';
import grin from 'resources/images/emoji/grin.png';
import grinning from 'resources/images/emoji/grinning.png';
import guardsman from 'resources/images/emoji/guardsman.png';
import guitar from 'resources/images/emoji/guitar.png';
import gun from 'resources/images/emoji/gun.png';
import haircut from 'resources/images/emoji/haircut.png';
import hamburger from 'resources/images/emoji/hamburger.png';
import hammer from 'resources/images/emoji/hammer.png';
import hamster from 'resources/images/emoji/hamster.png';
import hand from 'resources/images/emoji/hand.png';
import handbag from 'resources/images/emoji/handbag.png';
import hankey from 'resources/images/emoji/hankey.png';
import hash from 'resources/images/emoji/hash.png';
import hatched_chick from 'resources/images/emoji/hatched_chick.png';
import hatching_chick from 'resources/images/emoji/hatching_chick.png';
import headphones from 'resources/images/emoji/headphones.png';
import hear_no_evil from 'resources/images/emoji/hear_no_evil.png';
import heart from 'resources/images/emoji/heart.png';
import heart_decoration from 'resources/images/emoji/heart_decoration.png';
import heart_eyes from 'resources/images/emoji/heart_eyes.png';
import heart_eyes_cat from 'resources/images/emoji/heart_eyes_cat.png';
import heartbeat from 'resources/images/emoji/heartbeat.png';
import heartpulse from 'resources/images/emoji/heartpulse.png';
import hearts from 'resources/images/emoji/hearts.png';
import heavy_check_mark from 'resources/images/emoji/heavy_check_mark.png';
import heavy_division_sign from 'resources/images/emoji/heavy_division_sign.png';
import heavy_dollar_sign from 'resources/images/emoji/heavy_dollar_sign.png';
import heavy_exclamation_mark from 'resources/images/emoji/heavy_exclamation_mark.png';
import heavy_minus_sign from 'resources/images/emoji/heavy_minus_sign.png';
import heavy_multiplication_x from 'resources/images/emoji/heavy_multiplication_x.png';
import heavy_plus_sign from 'resources/images/emoji/heavy_plus_sign.png';
import helicopter from 'resources/images/emoji/helicopter.png';
import herb from 'resources/images/emoji/herb.png';
import hibiscus from 'resources/images/emoji/hibiscus.png';
import high_brightness from 'resources/images/emoji/high_brightness.png';
import high_heel from 'resources/images/emoji/high_heel.png';
import hocho from 'resources/images/emoji/hocho.png';
import honey_pot from 'resources/images/emoji/honey_pot.png';
import honeybee from 'resources/images/emoji/honeybee.png';
import horse from 'resources/images/emoji/horse.png';
import horse_racing from 'resources/images/emoji/horse_racing.png';
import hospital from 'resources/images/emoji/hospital.png';
import hotel from 'resources/images/emoji/hotel.png';
import hotsprings from 'resources/images/emoji/hotsprings.png';
import hourglass from 'resources/images/emoji/hourglass.png';
import hourglass_flowing_sand from 'resources/images/emoji/hourglass_flowing_sand.png';
import house from 'resources/images/emoji/house.png';
import house_with_garden from 'resources/images/emoji/house_with_garden.png';
import hurtrealbad from 'resources/images/emoji/hurtrealbad.png';
import hushed from 'resources/images/emoji/hushed.png';
import ice_cream from 'resources/images/emoji/ice_cream.png';
import icecream from 'resources/images/emoji/icecream.png';
import id from 'resources/images/emoji/id.png';
import ideograph_advantage from 'resources/images/emoji/ideograph_advantage.png';
import imp from 'resources/images/emoji/imp.png';
import inbox_tray from 'resources/images/emoji/inbox_tray.png';
import incoming_envelope from 'resources/images/emoji/incoming_envelope.png';
import information_desk_person from 'resources/images/emoji/information_desk_person.png';
import information_source from 'resources/images/emoji/information_source.png';
import innocent from 'resources/images/emoji/innocent.png';
import interrobang from 'resources/images/emoji/interrobang.png';
import iphone from 'resources/images/emoji/iphone.png';
import it from 'resources/images/emoji/it.png';
import izakaya_lantern from 'resources/images/emoji/izakaya_lantern.png';
import jack_o_lantern from 'resources/images/emoji/jack_o_lantern.png';
import japan from 'resources/images/emoji/japan.png';
import japanese_castle from 'resources/images/emoji/japanese_castle.png';
import japanese_goblin from 'resources/images/emoji/japanese_goblin.png';
import japanese_ogre from 'resources/images/emoji/japanese_ogre.png';
import jeans from 'resources/images/emoji/jeans.png';
import joy from 'resources/images/emoji/joy.png';
import joy_cat from 'resources/images/emoji/joy_cat.png';
import jp from 'resources/images/emoji/jp.png';
import key from 'resources/images/emoji/key.png';
import keycap_ten from 'resources/images/emoji/keycap_ten.png';
import kimono from 'resources/images/emoji/kimono.png';
import kiss from 'resources/images/emoji/kiss.png';
import kissing from 'resources/images/emoji/kissing.png';
import kissing_cat from 'resources/images/emoji/kissing_cat.png';
import kissing_closed_eyes from 'resources/images/emoji/kissing_closed_eyes.png';
import kissing_heart from 'resources/images/emoji/kissing_heart.png';
import kissing_smiling_eyes from 'resources/images/emoji/kissing_smiling_eyes.png';
import koala from 'resources/images/emoji/koala.png';
import koko from 'resources/images/emoji/koko.png';
import kr from 'resources/images/emoji/kr.png';
import lantern from 'resources/images/emoji/lantern.png';
import large_blue_circle from 'resources/images/emoji/large_blue_circle.png';
import large_blue_diamond from 'resources/images/emoji/large_blue_diamond.png';
import large_orange_diamond from 'resources/images/emoji/large_orange_diamond.png';
import last_quarter_moon from 'resources/images/emoji/last_quarter_moon.png';
import last_quarter_moon_with_face from 'resources/images/emoji/last_quarter_moon_with_face.png';
import laughing from 'resources/images/emoji/laughing.png';
import leaves from 'resources/images/emoji/leaves.png';
import ledger from 'resources/images/emoji/ledger.png';
import left_luggage from 'resources/images/emoji/left_luggage.png';
import left_right_arrow from 'resources/images/emoji/left_right_arrow.png';
import leftwards_arrow_with_hook from 'resources/images/emoji/leftwards_arrow_with_hook.png';
import lemon from 'resources/images/emoji/lemon.png';
import leo from 'resources/images/emoji/leo.png';
import leopard from 'resources/images/emoji/leopard.png';
import libra from 'resources/images/emoji/libra.png';
import light_rail from 'resources/images/emoji/light_rail.png';
import link from 'resources/images/emoji/link.png';
import lips from 'resources/images/emoji/lips.png';
import lipstick from 'resources/images/emoji/lipstick.png';
import lock from 'resources/images/emoji/lock.png';
import lock_with_ink_pen from 'resources/images/emoji/lock_with_ink_pen.png';
import lollipop from 'resources/images/emoji/lollipop.png';
import loop from 'resources/images/emoji/loop.png';
import loudspeaker from 'resources/images/emoji/loudspeaker.png';
import love_hotel from 'resources/images/emoji/love_hotel.png';
import love_letter from 'resources/images/emoji/love_letter.png';
import low_brightness from 'resources/images/emoji/low_brightness.png';
import m from 'resources/images/emoji/m.png';
import mag from 'resources/images/emoji/mag.png';
import mag_right from 'resources/images/emoji/mag_right.png';
import mahjong from 'resources/images/emoji/mahjong.png';
import mailbox from 'resources/images/emoji/mailbox.png';
import mailbox_closed from 'resources/images/emoji/mailbox_closed.png';
import mailbox_with_mail from 'resources/images/emoji/mailbox_with_mail.png';
import mailbox_with_no_mail from 'resources/images/emoji/mailbox_with_no_mail.png';
import man from 'resources/images/emoji/man.png';
import man_with_gua_pi_mao from 'resources/images/emoji/man_with_gua_pi_mao.png';
import man_with_turban from 'resources/images/emoji/man_with_turban.png';
import mans_shoe from 'resources/images/emoji/mans_shoe.png';
import maple_leaf from 'resources/images/emoji/maple_leaf.png';
import mask from 'resources/images/emoji/mask.png';
import massage from 'resources/images/emoji/massage.png';
import meat_on_bone from 'resources/images/emoji/meat_on_bone.png';
import mega from 'resources/images/emoji/mega.png';
import melon from 'resources/images/emoji/melon.png';
import memo from 'resources/images/emoji/memo.png';
import mens from 'resources/images/emoji/mens.png';
import metal from 'resources/images/emoji/metal.png';
import metro from 'resources/images/emoji/metro.png';
import microphone from 'resources/images/emoji/microphone.png';
import microscope from 'resources/images/emoji/microscope.png';
import milky_way from 'resources/images/emoji/milky_way.png';
import minibus from 'resources/images/emoji/minibus.png';
import minidisc from 'resources/images/emoji/minidisc.png';
import mobile_phone_off from 'resources/images/emoji/mobile_phone_off.png';
import money_with_wings from 'resources/images/emoji/money_with_wings.png';
import moneybag from 'resources/images/emoji/moneybag.png';
import monkey from 'resources/images/emoji/monkey.png';
import monkey_face from 'resources/images/emoji/monkey_face.png';
import monorail from 'resources/images/emoji/monorail.png';
import moon from 'resources/images/emoji/moon.png';
import mortar_board from 'resources/images/emoji/mortar_board.png';
import mount_fuji from 'resources/images/emoji/mount_fuji.png';
import mountain_bicyclist from 'resources/images/emoji/mountain_bicyclist.png';
import mountain_cableway from 'resources/images/emoji/mountain_cableway.png';
import mountain_railway from 'resources/images/emoji/mountain_railway.png';
import mouse from 'resources/images/emoji/mouse.png';
import mouse2 from 'resources/images/emoji/mouse2.png';
import movie_camera from 'resources/images/emoji/movie_camera.png';
import moyai from 'resources/images/emoji/moyai.png';
import muscle from 'resources/images/emoji/muscle.png';
import mushroom from 'resources/images/emoji/mushroom.png';
import musical_keyboard from 'resources/images/emoji/musical_keyboard.png';
import musical_note from 'resources/images/emoji/musical_note.png';
import musical_score from 'resources/images/emoji/musical_score.png';
import mute from 'resources/images/emoji/mute.png';
import nail_care from 'resources/images/emoji/nail_care.png';
import name_badge from 'resources/images/emoji/name_badge.png';
import neckbeard from 'resources/images/emoji/neckbeard.png';
import necktie from 'resources/images/emoji/necktie.png';
import negative_squared_cross_mark from 'resources/images/emoji/negative_squared_cross_mark.png';
import neutral_face from 'resources/images/emoji/neutral_face.png';
import newemoji from 'resources/images/emoji/new.png';
import new_moon from 'resources/images/emoji/new_moon.png';
import new_moon_with_face from 'resources/images/emoji/new_moon_with_face.png';
import newspaper from 'resources/images/emoji/newspaper.png';
import ng from 'resources/images/emoji/ng.png';
import nine from 'resources/images/emoji/nine.png';
import no_bell from 'resources/images/emoji/no_bell.png';
import no_bicycles from 'resources/images/emoji/no_bicycles.png';
import no_entry from 'resources/images/emoji/no_entry.png';
import no_entry_sign from 'resources/images/emoji/no_entry_sign.png';
import no_good from 'resources/images/emoji/no_good.png';
import no_mobile_phones from 'resources/images/emoji/no_mobile_phones.png';
import no_mouth from 'resources/images/emoji/no_mouth.png';
import no_pedestrians from 'resources/images/emoji/no_pedestrians.png';
import no_smoking from 'resources/images/emoji/no_smoking.png';
import non_potable_water from 'resources/images/emoji/non-potable_water.png';
import nose from 'resources/images/emoji/nose.png';
import notebook from 'resources/images/emoji/notebook.png';
import notebook_with_decorative_cover from 'resources/images/emoji/notebook_with_decorative_cover.png';
import notes from 'resources/images/emoji/notes.png';
import nut_and_bolt from 'resources/images/emoji/nut_and_bolt.png';
import o from 'resources/images/emoji/o.png';
import o2 from 'resources/images/emoji/o2.png';
import ocean from 'resources/images/emoji/ocean.png';
import octocat from 'resources/images/emoji/octocat.png';
import octopus from 'resources/images/emoji/octopus.png';
import oden from 'resources/images/emoji/oden.png';
import office from 'resources/images/emoji/office.png';
import ok from 'resources/images/emoji/ok.png';
import ok_hand from 'resources/images/emoji/ok_hand.png';
import ok_woman from 'resources/images/emoji/ok_woman.png';
import older_man from 'resources/images/emoji/older_man.png';
import older_woman from 'resources/images/emoji/older_woman.png';
import on from 'resources/images/emoji/on.png';
import oncoming_automobile from 'resources/images/emoji/oncoming_automobile.png';
import oncoming_bus from 'resources/images/emoji/oncoming_bus.png';
import oncoming_police_car from 'resources/images/emoji/oncoming_police_car.png';
import oncoming_taxi from 'resources/images/emoji/oncoming_taxi.png';
import one from 'resources/images/emoji/one.png';
import open_book from 'resources/images/emoji/open_book.png';
import open_file_folder from 'resources/images/emoji/open_file_folder.png';
import open_hands from 'resources/images/emoji/open_hands.png';
import open_mouth from 'resources/images/emoji/open_mouth.png';
import ophiuchus from 'resources/images/emoji/ophiuchus.png';
import orange_book from 'resources/images/emoji/orange_book.png';
import outbox_tray from 'resources/images/emoji/outbox_tray.png';
import ox from 'resources/images/emoji/ox.png';
import packageemoji from 'resources/images/emoji/package.png';
import page_facing_up from 'resources/images/emoji/page_facing_up.png';
import page_with_curl from 'resources/images/emoji/page_with_curl.png';
import pager from 'resources/images/emoji/pager.png';
import palm_tree from 'resources/images/emoji/palm_tree.png';
import panda_face from 'resources/images/emoji/panda_face.png';
import paperclip from 'resources/images/emoji/paperclip.png';
import parking from 'resources/images/emoji/parking.png';
import part_alternation_mark from 'resources/images/emoji/part_alternation_mark.png';
import partly_sunny from 'resources/images/emoji/partly_sunny.png';
import passport_control from 'resources/images/emoji/passport_control.png';
import paw_prints from 'resources/images/emoji/paw_prints.png';
import peach from 'resources/images/emoji/peach.png';
import pear from 'resources/images/emoji/pear.png';
import pencil from 'resources/images/emoji/pencil.png';
import pencil2 from 'resources/images/emoji/pencil2.png';
import penguin from 'resources/images/emoji/penguin.png';
import pensive from 'resources/images/emoji/pensive.png';
import performing_arts from 'resources/images/emoji/performing_arts.png';
import persevere from 'resources/images/emoji/persevere.png';
import person_frowning from 'resources/images/emoji/person_frowning.png';
import person_with_blond_hair from 'resources/images/emoji/person_with_blond_hair.png';
import person_with_pouting_face from 'resources/images/emoji/person_with_pouting_face.png';
import phone from 'resources/images/emoji/phone.png';
import pig from 'resources/images/emoji/pig.png';
import pig_nose from 'resources/images/emoji/pig_nose.png';
import pig2 from 'resources/images/emoji/pig2.png';
import pill from 'resources/images/emoji/pill.png';
import pineapple from 'resources/images/emoji/pineapple.png';
import pisces from 'resources/images/emoji/pisces.png';
import pizza from 'resources/images/emoji/pizza.png';
import point_down from 'resources/images/emoji/point_down.png';
import point_left from 'resources/images/emoji/point_left.png';
import point_right from 'resources/images/emoji/point_right.png';
import point_up from 'resources/images/emoji/point_up.png';
import point_up_2 from 'resources/images/emoji/point_up_2.png';
import police_car from 'resources/images/emoji/police_car.png';
import poodle from 'resources/images/emoji/poodle.png';
import poop from 'resources/images/emoji/poop.png';
import post_office from 'resources/images/emoji/post_office.png';
import postal_horn from 'resources/images/emoji/postal_horn.png';
import postbox from 'resources/images/emoji/postbox.png';
import potable_water from 'resources/images/emoji/potable_water.png';
import pouch from 'resources/images/emoji/pouch.png';
import poultry_leg from 'resources/images/emoji/poultry_leg.png';
import pound from 'resources/images/emoji/pound.png';
import pouting_cat from 'resources/images/emoji/pouting_cat.png';
import pray from 'resources/images/emoji/pray.png';
import princess from 'resources/images/emoji/princess.png';
import punch from 'resources/images/emoji/punch.png';
import purple_heart from 'resources/images/emoji/purple_heart.png';
import purse from 'resources/images/emoji/purse.png';
import pushpin from 'resources/images/emoji/pushpin.png';
import put_litter_in_its_place from 'resources/images/emoji/put_litter_in_its_place.png';
import question from 'resources/images/emoji/question.png';
import rabbit from 'resources/images/emoji/rabbit.png';
import rabbit2 from 'resources/images/emoji/rabbit2.png';
import racehorse from 'resources/images/emoji/racehorse.png';
import radio from 'resources/images/emoji/radio.png';
import radio_button from 'resources/images/emoji/radio_button.png';
import rage from 'resources/images/emoji/rage.png';
import rage1 from 'resources/images/emoji/rage1.png';
import rage2 from 'resources/images/emoji/rage2.png';
import rage3 from 'resources/images/emoji/rage3.png';
import rage4 from 'resources/images/emoji/rage4.png';
import railway_car from 'resources/images/emoji/railway_car.png';
import rainbow from 'resources/images/emoji/rainbow.png';
import raised_hand from 'resources/images/emoji/raised_hand.png';
import raised_hands from 'resources/images/emoji/raised_hands.png';
import raising_hand from 'resources/images/emoji/raising_hand.png';
import ram from 'resources/images/emoji/ram.png';
import ramen from 'resources/images/emoji/ramen.png';
import rat from 'resources/images/emoji/rat.png';
import recycle from 'resources/images/emoji/recycle.png';
import red_car from 'resources/images/emoji/red_car.png';
import red_circle from 'resources/images/emoji/red_circle.png';
import registered from 'resources/images/emoji/registered.png';
import relaxed from 'resources/images/emoji/relaxed.png';
import relieved from 'resources/images/emoji/relieved.png';
import repeat from 'resources/images/emoji/repeat.png';
import repeat_one from 'resources/images/emoji/repeat_one.png';
import restroom from 'resources/images/emoji/restroom.png';
import revolving_hearts from 'resources/images/emoji/revolving_hearts.png';
import rewind from 'resources/images/emoji/rewind.png';
import ribbon from 'resources/images/emoji/ribbon.png';
import rice from 'resources/images/emoji/rice.png';
import rice_ball from 'resources/images/emoji/rice_ball.png';
import rice_cracker from 'resources/images/emoji/rice_cracker.png';
import rice_scene from 'resources/images/emoji/rice_scene.png';
import ring from 'resources/images/emoji/ring.png';
import rocket from 'resources/images/emoji/rocket.png';
import roller_coaster from 'resources/images/emoji/roller_coaster.png';
import rooster from 'resources/images/emoji/rooster.png';
import rose from 'resources/images/emoji/rose.png';
import rotating_light from 'resources/images/emoji/rotating_light.png';
import round_pushpin from 'resources/images/emoji/round_pushpin.png';
import rowboat from 'resources/images/emoji/rowboat.png';
import ru from 'resources/images/emoji/ru.png';
import rugby_football from 'resources/images/emoji/rugby_football.png';
import runner from 'resources/images/emoji/runner.png';
import running from 'resources/images/emoji/running.png';
import running_shirt_with_sash from 'resources/images/emoji/running_shirt_with_sash.png';
import sa from 'resources/images/emoji/sa.png';
import sagittarius from 'resources/images/emoji/sagittarius.png';
import sailboat from 'resources/images/emoji/sailboat.png';
import sake from 'resources/images/emoji/sake.png';
import sandal from 'resources/images/emoji/sandal.png';
import santa from 'resources/images/emoji/santa.png';
import satellite from 'resources/images/emoji/satellite.png';
import satisfied from 'resources/images/emoji/satisfied.png';
import saxophone from 'resources/images/emoji/saxophone.png';
import school from 'resources/images/emoji/school.png';
import school_satchel from 'resources/images/emoji/school_satchel.png';
import scissors from 'resources/images/emoji/scissors.png';
import scorpius from 'resources/images/emoji/scorpius.png';
import scream from 'resources/images/emoji/scream.png';
import scream_cat from 'resources/images/emoji/scream_cat.png';
import scroll from 'resources/images/emoji/scroll.png';
import seat from 'resources/images/emoji/seat.png';
import secret from 'resources/images/emoji/secret.png';
import see_no_evil from 'resources/images/emoji/see_no_evil.png';
import seedling from 'resources/images/emoji/seedling.png';
import seven from 'resources/images/emoji/seven.png';
import shaved_ice from 'resources/images/emoji/shaved_ice.png';
import sheep from 'resources/images/emoji/sheep.png';
import shell from 'resources/images/emoji/shell.png';
import ship from 'resources/images/emoji/ship.png';
import shipit from 'resources/images/emoji/shipit.png';
import shirt from 'resources/images/emoji/shirt.png';
import shit from 'resources/images/emoji/shit.png';
import shoe from 'resources/images/emoji/shoe.png';
import shower from 'resources/images/emoji/shower.png';
import signal_strength from 'resources/images/emoji/signal_strength.png';
import six from 'resources/images/emoji/six.png';
import six_pointed_star from 'resources/images/emoji/six_pointed_star.png';
import ski from 'resources/images/emoji/ski.png';
import skull from 'resources/images/emoji/skull.png';
import sleeping from 'resources/images/emoji/sleeping.png';
import sleepy from 'resources/images/emoji/sleepy.png';
import slot_machine from 'resources/images/emoji/slot_machine.png';
import small_blue_diamond from 'resources/images/emoji/small_blue_diamond.png';
import small_orange_diamond from 'resources/images/emoji/small_orange_diamond.png';
import small_red_triangle from 'resources/images/emoji/small_red_triangle.png';
import small_red_triangle_down from 'resources/images/emoji/small_red_triangle_down.png';
import smile from 'resources/images/emoji/smile.png';
import smile_cat from 'resources/images/emoji/smile_cat.png';
import smiley from 'resources/images/emoji/smiley.png';
import smiley_cat from 'resources/images/emoji/smiley_cat.png';
import smiling_imp from 'resources/images/emoji/smiling_imp.png';
import smirk from 'resources/images/emoji/smirk.png';
import smirk_cat from 'resources/images/emoji/smirk_cat.png';
import smoking from 'resources/images/emoji/smoking.png';
import snail from 'resources/images/emoji/snail.png';
import snake from 'resources/images/emoji/snake.png';
import snowboarder from 'resources/images/emoji/snowboarder.png';
import snowflake from 'resources/images/emoji/snowflake.png';
import snowman from 'resources/images/emoji/snowman.png';
import sob from 'resources/images/emoji/sob.png';
import soccer from 'resources/images/emoji/soccer.png';
import soon from 'resources/images/emoji/soon.png';
import sos from 'resources/images/emoji/sos.png';
import sound from 'resources/images/emoji/sound.png';
import space_invader from 'resources/images/emoji/space_invader.png';
import spades from 'resources/images/emoji/spades.png';
import spaghetti from 'resources/images/emoji/spaghetti.png';
import sparkle from 'resources/images/emoji/sparkle.png';
import sparkler from 'resources/images/emoji/sparkler.png';
import sparkles from 'resources/images/emoji/sparkles.png';
import sparkling_heart from 'resources/images/emoji/sparkling_heart.png';
import speak_no_evil from 'resources/images/emoji/speak_no_evil.png';
import speaker from 'resources/images/emoji/speaker.png';
import speech_balloon from 'resources/images/emoji/speech_balloon.png';
import speedboat from 'resources/images/emoji/speedboat.png';
import squirrel from 'resources/images/emoji/squirrel.png';
import star from 'resources/images/emoji/star.png';
import star2 from 'resources/images/emoji/star2.png';
import stars from 'resources/images/emoji/stars.png';
import station from 'resources/images/emoji/station.png';
import statue_of_liberty from 'resources/images/emoji/statue_of_liberty.png';
import steam_locomotive from 'resources/images/emoji/steam_locomotive.png';
import stew from 'resources/images/emoji/stew.png';
import straight_ruler from 'resources/images/emoji/straight_ruler.png';
import strawberry from 'resources/images/emoji/strawberry.png';
import stuck_out_tongue from 'resources/images/emoji/stuck_out_tongue.png';
import stuck_out_tongue_closed_eyes from 'resources/images/emoji/stuck_out_tongue_closed_eyes.png';
import stuck_out_tongue_winking_eye from 'resources/images/emoji/stuck_out_tongue_winking_eye.png';
import sun_with_face from 'resources/images/emoji/sun_with_face.png';
import sunflower from 'resources/images/emoji/sunflower.png';
import sunglasses from 'resources/images/emoji/sunglasses.png';
import sunny from 'resources/images/emoji/sunny.png';
import sunrise from 'resources/images/emoji/sunrise.png';
import sunrise_over_mountains from 'resources/images/emoji/sunrise_over_mountains.png';
import surfer from 'resources/images/emoji/surfer.png';
import sushi from 'resources/images/emoji/sushi.png';
import suspect from 'resources/images/emoji/suspect.png';
import suspension_railway from 'resources/images/emoji/suspension_railway.png';
import sweat from 'resources/images/emoji/sweat.png';
import sweat_drops from 'resources/images/emoji/sweat_drops.png';
import sweat_smile from 'resources/images/emoji/sweat_smile.png';
import sweet_potato from 'resources/images/emoji/sweet_potato.png';
import swimmer from 'resources/images/emoji/swimmer.png';
import symbols from 'resources/images/emoji/symbols.png';
import syringe from 'resources/images/emoji/syringe.png';
import tada from 'resources/images/emoji/tada.png';
import tanabata_tree from 'resources/images/emoji/tanabata_tree.png';
import tangerine from 'resources/images/emoji/tangerine.png';
import taurus from 'resources/images/emoji/taurus.png';
import taxi from 'resources/images/emoji/taxi.png';
import tea from 'resources/images/emoji/tea.png';
import telephone from 'resources/images/emoji/telephone.png';
import telephone_receiver from 'resources/images/emoji/telephone_receiver.png';
import telescope from 'resources/images/emoji/telescope.png';
import tennis from 'resources/images/emoji/tennis.png';
import tent from 'resources/images/emoji/tent.png';
import thought_balloon from 'resources/images/emoji/thought_balloon.png';
import three from 'resources/images/emoji/three.png';
import thumbsdown from 'resources/images/emoji/thumbsdown.png';
import thumbsup from 'resources/images/emoji/thumbsup.png';
import ticket from 'resources/images/emoji/ticket.png';
import tiger from 'resources/images/emoji/tiger.png';
import tiger2 from 'resources/images/emoji/tiger2.png';
import tired_face from 'resources/images/emoji/tired_face.png';
import tm from 'resources/images/emoji/tm.png';
import toilet from 'resources/images/emoji/toilet.png';
import tokyo_tower from 'resources/images/emoji/tokyo_tower.png';
import tomato from 'resources/images/emoji/tomato.png';
import tongue from 'resources/images/emoji/tongue.png';
import top from 'resources/images/emoji/top.png';
import tophat from 'resources/images/emoji/tophat.png';
import tractor from 'resources/images/emoji/tractor.png';
import traffic_light from 'resources/images/emoji/traffic_light.png';
import train from 'resources/images/emoji/train.png';
import train2 from 'resources/images/emoji/train2.png';
import tram from 'resources/images/emoji/tram.png';
import trello from 'resources/images/emoji/trello.png';
import triangular_flag_on_post from 'resources/images/emoji/triangular_flag_on_post.png';
import triangular_ruler from 'resources/images/emoji/triangular_ruler.png';
import trident from 'resources/images/emoji/trident.png';
import triumph from 'resources/images/emoji/triumph.png';
import trolleybus from 'resources/images/emoji/trolleybus.png';
import trollface from 'resources/images/emoji/trollface.png';
import trophy from 'resources/images/emoji/trophy.png';
import tropical_drink from 'resources/images/emoji/tropical_drink.png';
import tropical_fish from 'resources/images/emoji/tropical_fish.png';
import truck from 'resources/images/emoji/truck.png';
import trumpet from 'resources/images/emoji/trumpet.png';
import tshirt from 'resources/images/emoji/tshirt.png';
import tulip from 'resources/images/emoji/tulip.png';
import turtle from 'resources/images/emoji/turtle.png';
import tv from 'resources/images/emoji/tv.png';
import twisted_rightwards_arrows from 'resources/images/emoji/twisted_rightwards_arrows.png';
import two from 'resources/images/emoji/two.png';
import two_hearts from 'resources/images/emoji/two_hearts.png';
import two_men_holding_hands from 'resources/images/emoji/two_men_holding_hands.png';
import two_women_holding_hands from 'resources/images/emoji/two_women_holding_hands.png';
import u6e80 from 'resources/images/emoji/u6e80.png';
import u7a7a from 'resources/images/emoji/u7a7a.png';
import u55b6 from 'resources/images/emoji/u55b6.png';
import u5272 from 'resources/images/emoji/u5272.png';
import u5408 from 'resources/images/emoji/u5408.png';
import u6307 from 'resources/images/emoji/u6307.png';
import u6708 from 'resources/images/emoji/u6708.png';
import u6709 from 'resources/images/emoji/u6709.png';
import u7121 from 'resources/images/emoji/u7121.png';
import u7533 from 'resources/images/emoji/u7533.png';
import u7981 from 'resources/images/emoji/u7981.png';
import uk from 'resources/images/emoji/uk.png';
import umbrella from 'resources/images/emoji/umbrella.png';
import unamused from 'resources/images/emoji/unamused.png';
import underage from 'resources/images/emoji/underage.png';
import unlock from 'resources/images/emoji/unlock.png';
import up from 'resources/images/emoji/up.png';
import us from 'resources/images/emoji/us.png';
import v from 'resources/images/emoji/v.png';
import vertical_traffic_light from 'resources/images/emoji/vertical_traffic_light.png';
import vhs from 'resources/images/emoji/vhs.png';
import vibration_mode from 'resources/images/emoji/vibration_mode.png';
import video_camera from 'resources/images/emoji/video_camera.png';
import video_game from 'resources/images/emoji/video_game.png';
import violin from 'resources/images/emoji/violin.png';
import virgo from 'resources/images/emoji/virgo.png';
import volcano from 'resources/images/emoji/volcano.png';
import vs from 'resources/images/emoji/vs.png';
import walking from 'resources/images/emoji/walking.png';
import waning_crescent_moon from 'resources/images/emoji/waning_crescent_moon.png';
import waning_gibbous_moon from 'resources/images/emoji/waning_gibbous_moon.png';
import warning from 'resources/images/emoji/warning.png';
import watch from 'resources/images/emoji/watch.png';
import water_buffalo from 'resources/images/emoji/water_buffalo.png';
import watermelon from 'resources/images/emoji/watermelon.png';
import wave from 'resources/images/emoji/wave.png';
import wavy_dash from 'resources/images/emoji/wavy_dash.png';
import waxing_crescent_moon from 'resources/images/emoji/waxing_crescent_moon.png';
import waxing_gibbous_moon from 'resources/images/emoji/waxing_gibbous_moon.png';
import wc from 'resources/images/emoji/wc.png';
import weary from 'resources/images/emoji/weary.png';
import wedding from 'resources/images/emoji/wedding.png';
import whale from 'resources/images/emoji/whale.png';
import whale2 from 'resources/images/emoji/whale2.png';
import wheelchair from 'resources/images/emoji/wheelchair.png';
import white_check_mark from 'resources/images/emoji/white_check_mark.png';
import white_circle from 'resources/images/emoji/white_circle.png';
import white_flower from 'resources/images/emoji/white_flower.png';
import white_large_square from 'resources/images/emoji/white_large_square.png';
import white_medium_small_square from 'resources/images/emoji/white_medium_small_square.png';
import white_medium_square from 'resources/images/emoji/white_medium_square.png';
import white_small_square from 'resources/images/emoji/white_small_square.png';
import white_square_button from 'resources/images/emoji/white_square_button.png';
import wind_chime from 'resources/images/emoji/wind_chime.png';
import wine_glass from 'resources/images/emoji/wine_glass.png';
import wink from 'resources/images/emoji/wink.png';
import wolf from 'resources/images/emoji/wolf.png';
import woman from 'resources/images/emoji/woman.png';
import womans_clothes from 'resources/images/emoji/womans_clothes.png';
import womans_hat from 'resources/images/emoji/womans_hat.png';
import womens from 'resources/images/emoji/womens.png';
import worried from 'resources/images/emoji/worried.png';
import wrench from 'resources/images/emoji/wrench.png';
import x from 'resources/images/emoji/x.png';
import yellow_heart from 'resources/images/emoji/yellow_heart.png';
import yen from 'resources/images/emoji/yen.png';
import yum from 'resources/images/emoji/yum.png';
import zap from 'resources/images/emoji/zap.png';
import zero from 'resources/images/emoji/zero.png';
import zzz from 'resources/images/emoji/zzz.png';

interface TrelloEmojiMap {
  [key: string]: string;
}

export const TrelloEmoji: TrelloEmojiMap = {
  100: number100,
  1234: number1234,
  '+1': thumbsup,
  '-1': thumbsdown,
  '8ball': eightball,
  a,
  ab,
  abc,
  abcd,
  accept,
  aerial_tramway,
  airplane,
  alarm_clock,
  alien,
  ambulance,
  anchor,
  angel,
  anger,
  angry,
  anguished,
  ant,
  apple,
  aquarius,
  aries,
  arrow_backward,
  arrow_double_down,
  arrow_double_up,
  arrow_down,
  arrow_down_small,
  arrow_forward,
  arrow_heading_down,
  arrow_heading_up,
  arrow_left,
  arrow_lower_left,
  arrow_lower_right,
  arrow_right,
  arrow_right_hook,
  arrow_up,
  arrow_up_down,
  arrow_up_small,
  arrow_upper_left,
  arrow_upper_right,
  arrows_clockwise,
  arrows_counterclockwise,
  art,
  articulated_lorry,
  astonished,
  athletic_shoe,
  atm,
  b,
  baby,
  baby_bottle,
  baby_chick,
  baby_symbol,
  back,
  baggage_claim,
  balloon,
  ballot_box_with_check,
  bamboo,
  banana,
  bangbang,
  bank,
  bar_chart,
  barber,
  baseball,
  basketball,
  bath,
  bathtub,
  battery,
  bear,
  bee,
  beer,
  beers,
  beetle,
  beginner,
  bell,
  bento,
  bicyclist,
  bike,
  bikini,
  bird,
  birthday,
  black_circle,
  black_joker,
  black_large_square,
  black_medium_small_square,
  black_medium_square,
  black_nib,
  black_small_square,
  black_square_button,
  blossom,
  blowfish,
  blue_book,
  blue_car,
  blue_heart,
  blush,
  boar,
  boat,
  bomb,
  book,
  bookmark,
  bookmark_tabs,
  books,
  boom,
  boot,
  bouquet,
  bow,
  bowling,
  bowtie,
  boy,
  bread,
  bride_with_veil,
  bridge_at_night,
  briefcase,
  broken_heart,
  bug,
  bulb,
  bullettrain_front,
  bullettrain_side,
  bus,
  busstop,
  bust_in_silhouette,
  busts_in_silhouette,
  cactus,
  cake,
  calendar,
  calling,
  camel,
  camera,
  cancer,
  candy,
  capital_abcd,
  capricorn,
  car,
  card_index,
  carousel_horse,
  cat,
  cat2,
  cd,
  chart,
  chart_with_downwards_trend,
  chart_with_upwards_trend,
  checkered_flag,
  cherries,
  cherry_blossom,
  chestnut,
  chicken,
  children_crossing,
  chocolate_bar,
  christmas_tree,
  church,
  cinema,
  circus_tent,
  city_sunrise,
  city_sunset,
  cl,
  clap,
  clapper,
  clipboard,
  clock1,
  clock10,
  clock1030,
  clock11,
  clock1130,
  clock12,
  clock1230,
  clock130,
  clock2,
  clock230,
  clock3,
  clock330,
  clock4,
  clock430,
  clock5,
  clock530,
  clock6,
  clock630,
  clock7,
  clock730,
  clock8,
  clock830,
  clock9,
  clock930,
  closed_book,
  closed_lock_with_key,
  closed_umbrella,
  cloud,
  clubs,
  cn,
  cocktail,
  coffee,
  cold_sweat,
  collision,
  computer,
  confetti_ball,
  confounded,
  confused,
  congratulations,
  construction,
  construction_worker,
  convenience_store,
  cookie,
  cool,
  cop,
  copyright,
  corn,
  couple,
  couple_with_heart,
  couplekiss,
  cow,
  cow2,
  credit_card,
  crescent_moon,
  crocodile,
  crossed_flags,
  crown,
  cry,
  crying_cat_face,
  crystal_ball,
  cupid,
  curly_loop,
  currency_exchange,
  curry,
  custard,
  customs,
  cyclone,
  dancer,
  dancers,
  dango,
  dart,
  dash,
  date,
  de,
  deciduous_tree,
  department_store,
  diamond_shape_with_a_dot_inside,
  diamonds,
  disappointed,
  disappointed_relieved,
  dizzy,
  dizzy_face,
  do_not_litter,
  dog,
  dog2,
  dollar,
  dolls,
  dolphin,
  door,
  doughnut,
  dragon,
  dragon_face,
  dress,
  dromedary_camel,
  droplet,
  dvd,
  'e-mail': edashmail,
  ear,
  ear_of_rice,
  earth_africa,
  earth_americas,
  earth_asia,
  egg,
  eggplant,
  eight,
  eight_pointed_black_star,
  eight_spoked_asterisk,
  electric_plug,
  elephant,
  email,
  end,
  envelope,
  envelope_with_arrow,
  es,
  euro,
  european_castle,
  european_post_office,
  evergreen_tree,
  exclamation,
  expressionless,
  eyeglasses,
  eyes,
  facepalm,
  facepunch,
  factory,
  fallen_leaf,
  family,
  fast_forward,
  fax,
  fearful,
  feelsgood,
  feet,
  ferris_wheel,
  file_folder,
  finnadie,
  fire,
  fire_engine,
  fireworks,
  first_quarter_moon,
  first_quarter_moon_with_face,
  fish,
  fish_cake,
  fishing_pole_and_fish,
  fist,
  five,
  flags,
  flashlight,
  flipper,
  floppy_disk,
  flower_playing_cards,
  flushed,
  foggy,
  football,
  footprints,
  fork_and_knife,
  fountain,
  four,
  four_leaf_clover,
  fr,
  free,
  fried_shrimp,
  fries,
  frog,
  frowning,
  fu,
  fuelpump,
  full_moon,
  full_moon_with_face,
  game_die,
  gb,
  gem,
  gemini,
  ghost,
  gift,
  gift_heart,
  girl,
  globe_with_meridians,
  goat,
  goberserk,
  godmode,
  golf,
  grapes,
  green_apple,
  green_book,
  green_heart,
  grey_exclamation,
  grey_question,
  grimacing,
  grin,
  grinning,
  guardsman,
  guitar,
  gun,
  haircut,
  hamburger,
  hammer,
  hamster,
  hand,
  handbag,
  hankey,
  hash,
  hatched_chick,
  hatching_chick,
  headphones,
  hear_no_evil,
  heart,
  heart_decoration,
  heart_eyes,
  heart_eyes_cat,
  heartbeat,
  heartpulse,
  hearts,
  heavy_check_mark,
  heavy_division_sign,
  heavy_dollar_sign,
  heavy_exclamation_mark,
  heavy_minus_sign,
  heavy_multiplication_x,
  heavy_plus_sign,
  helicopter,
  herb,
  hibiscus,
  high_brightness,
  high_heel,
  hocho,
  honey_pot,
  honeybee,
  horse,
  horse_racing,
  hospital,
  hotel,
  hotsprings,
  hourglass,
  hourglass_flowing_sand,
  house,
  house_with_garden,
  hurtrealbad,
  hushed,
  ice_cream,
  icecream,
  id,
  ideograph_advantage,
  imp,
  inbox_tray,
  incoming_envelope,
  information_desk_person,
  information_source,
  innocent,
  interrobang,
  iphone,
  it,
  izakaya_lantern,
  jack_o_lantern,
  japan,
  japanese_castle,
  japanese_goblin,
  japanese_ogre,
  jeans,
  joy,
  joy_cat,
  jp,
  key,
  keycap_ten,
  kimono,
  kiss,
  kissing,
  kissing_cat,
  kissing_closed_eyes,
  kissing_heart,
  kissing_smiling_eyes,
  koala,
  koko,
  kr,
  lantern,
  large_blue_circle,
  large_blue_diamond,
  large_orange_diamond,
  last_quarter_moon,
  last_quarter_moon_with_face,
  laughing,
  leaves,
  ledger,
  left_luggage,
  left_right_arrow,
  leftwards_arrow_with_hook,
  lemon,
  leo,
  leopard,
  libra,
  light_rail,
  link,
  lips,
  lipstick,
  lock,
  lock_with_ink_pen,
  lollipop,
  loop,
  loudspeaker,
  love_hotel,
  love_letter,
  low_brightness,
  m,
  mag,
  mag_right,
  mahjong,
  mailbox,
  mailbox_closed,
  mailbox_with_mail,
  mailbox_with_no_mail,
  man,
  man_with_gua_pi_mao,
  man_with_turban,
  mans_shoe,
  maple_leaf,
  mask,
  massage,
  meat_on_bone,
  mega,
  melon,
  memo,
  mens,
  metal,
  metro,
  microphone,
  microscope,
  milky_way,
  minibus,
  minidisc,
  mobile_phone_off,
  money_with_wings,
  moneybag,
  monkey,
  monkey_face,
  monorail,
  moon,
  mortar_board,
  mount_fuji,
  mountain_bicyclist,
  mountain_cableway,
  mountain_railway,
  mouse,
  mouse2,
  movie_camera,
  moyai,
  muscle,
  mushroom,
  musical_keyboard,
  musical_note,
  musical_score,
  mute,
  nail_care,
  name_badge,
  neckbeard,
  necktie,
  negative_squared_cross_mark,
  neutral_face,
  newemoji,
  new_moon,
  new_moon_with_face,
  newspaper,
  ng,
  nine,
  no_bell,
  no_bicycles,
  no_entry,
  no_entry_sign,
  no_good,
  no_mobile_phones,
  no_mouth,
  no_pedestrians,
  no_smoking,
  'non-potable_water': non_potable_water,
  nose,
  notebook,
  notebook_with_decorative_cover,
  notes,
  nut_and_bolt,
  o,
  o2,
  ocean,
  octocat,
  octopus,
  oden,
  office,
  ok,
  ok_hand,
  ok_woman,
  older_man,
  older_woman,
  on,
  oncoming_automobile,
  oncoming_bus,
  oncoming_police_car,
  oncoming_taxi,
  one,
  open_book,
  open_file_folder,
  open_hands,
  open_mouth,
  ophiuchus,
  orange_book,
  outbox_tray,
  ox,
  package: packageemoji,
  page_facing_up,
  page_with_curl,
  pager,
  palm_tree,
  panda_face,
  paperclip,
  parking,
  part_alternation_mark,
  partly_sunny,
  passport_control,
  paw_prints,
  peach,
  pear,
  pencil,
  pencil2,
  penguin,
  pensive,
  performing_arts,
  persevere,
  person_frowning,
  person_with_blond_hair,
  person_with_pouting_face,
  phone,
  pig,
  pig2,
  pig_nose,
  pill,
  pineapple,
  pisces,
  pizza,
  point_down,
  point_left,
  point_right,
  point_up,
  point_up_2,
  police_car,
  poodle,
  poop,
  post_office,
  postal_horn,
  postbox,
  potable_water,
  pouch,
  poultry_leg,
  pound,
  pouting_cat,
  pray,
  princess,
  punch,
  purple_heart,
  purse,
  pushpin,
  put_litter_in_its_place,
  question,
  rabbit,
  rabbit2,
  racehorse,
  radio,
  radio_button,
  rage,
  rage1,
  rage2,
  rage3,
  rage4,
  railway_car,
  rainbow,
  raised_hand,
  raised_hands,
  raising_hand,
  ram,
  ramen,
  rat,
  recycle,
  red_car,
  red_circle,
  registered,
  relaxed,
  relieved,
  repeat,
  repeat_one,
  restroom,
  revolving_hearts,
  rewind,
  ribbon,
  rice,
  rice_ball,
  rice_cracker,
  rice_scene,
  ring,
  rocket,
  roller_coaster,
  rooster,
  rose,
  rotating_light,
  round_pushpin,
  rowboat,
  ru,
  rugby_football,
  runner,
  running,
  running_shirt_with_sash,
  sa,
  sagittarius,
  sailboat,
  sake,
  sandal,
  santa,
  satellite,
  satisfied,
  saxophone,
  school,
  school_satchel,
  scissors,
  scorpius,
  scream,
  scream_cat,
  scroll,
  seat,
  secret,
  see_no_evil,
  seedling,
  seven,
  shaved_ice,
  sheep,
  shell,
  ship,
  shipit,
  shirt,
  shit,
  shoe,
  shower,
  signal_strength,
  six,
  six_pointed_star,
  ski,
  skull,
  sleeping,
  sleepy,
  slot_machine,
  small_blue_diamond,
  small_orange_diamond,
  small_red_triangle,
  small_red_triangle_down,
  smile,
  smile_cat,
  smiley,
  smiley_cat,
  smiling_imp,
  smirk,
  smirk_cat,
  smoking,
  snail,
  snake,
  snowboarder,
  snowflake,
  snowman,
  sob,
  soccer,
  soon,
  sos,
  sound,
  space_invader,
  spades,
  spaghetti,
  sparkle,
  sparkler,
  sparkles,
  sparkling_heart,
  speak_no_evil,
  speaker,
  speech_balloon,
  speedboat,
  squirrel,
  star,
  star2,
  stars,
  station,
  statue_of_liberty,
  steam_locomotive,
  stew,
  straight_ruler,
  strawberry,
  stuck_out_tongue,
  stuck_out_tongue_closed_eyes,
  stuck_out_tongue_winking_eye,
  sun_with_face,
  sunflower,
  sunglasses,
  sunny,
  sunrise,
  sunrise_over_mountains,
  surfer,
  sushi,
  suspect,
  suspension_railway,
  sweat,
  sweat_drops,
  sweat_smile,
  sweet_potato,
  swimmer,
  symbols,
  syringe,
  tada,
  tanabata_tree,
  tangerine,
  taurus,
  taxi,
  tea,
  telephone,
  telephone_receiver,
  telescope,
  tennis,
  tent,
  thought_balloon,
  three,
  thumbsdown,
  thumbsup,
  ticket,
  tiger,
  tiger2,
  tired_face,
  tm,
  toilet,
  tokyo_tower,
  tomato,
  tongue,
  top,
  tophat,
  tractor,
  traffic_light,
  train,
  train2,
  tram,
  trello,
  triangular_flag_on_post,
  triangular_ruler,
  trident,
  triumph,
  trolleybus,
  trollface,
  trophy,
  tropical_drink,
  tropical_fish,
  truck,
  trumpet,
  tshirt,
  tulip,
  turtle,
  tv,
  twisted_rightwards_arrows,
  two,
  two_hearts,
  two_men_holding_hands,
  two_women_holding_hands,
  u5272,
  u5408,
  u55b6,
  u6307,
  u6708,
  u6709,
  u6e80,
  u7121,
  u7533,
  u7981,
  u7a7a,
  uk,
  umbrella,
  unamused,
  underage,
  unlock,
  up,
  us,
  v,
  vertical_traffic_light,
  vhs,
  vibration_mode,
  video_camera,
  video_game,
  violin,
  virgo,
  volcano,
  vs,
  walking,
  waning_crescent_moon,
  waning_gibbous_moon,
  warning,
  watch,
  water_buffalo,
  watermelon,
  wave,
  wavy_dash,
  waxing_crescent_moon,
  waxing_gibbous_moon,
  wc,
  weary,
  wedding,
  whale,
  whale2,
  wheelchair,
  white_check_mark,
  white_circle,
  white_flower,
  white_large_square,
  white_medium_small_square,
  white_medium_square,
  white_small_square,
  white_square_button,
  wind_chime,
  wine_glass,
  wink,
  wolf,
  woman,
  womans_clothes,
  womans_hat,
  womens,
  worried,
  wrench,
  x,
  yellow_heart,
  yen,
  yum,
  zap,
  zero,
  zzz,
};
