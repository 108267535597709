import type { RouteId } from '@trello/router';
import { getLocation, getRouteParamsFromPathname } from '@trello/router';

import { switchCurrentBoardView } from 'app/src/components/Board/switchCurrentBoardView';

export const toggleButlerDirectory = async (): Promise<void> => {
  const pathname = getLocation().pathname;
  const params = getRouteParamsFromPathname<RouteId.BOARD>(pathname);
  if (params.view === 'butler') {
    switchCurrentBoardView({
      routeParams: {
        view: 'board',
      },
      navigateOptions: {
        trigger: true,
      },
    });
  } else {
    switchCurrentBoardView({
      routeParams: {
        view: 'butler',
      },
      navigateOptions: {
        trigger: true,
      },
    });
  }
};
