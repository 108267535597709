import type { MouseEvent } from 'react';
import { FormattedMessage } from 'react-intl';

import { AtlassianIntelligenceIcon } from '@trello/nachos/icons/atlassian-intelligence';
import type { ActionTestIds } from '@trello/test-ids';
import { getTestId } from '@trello/test-ids';

import styles from './ActionAppCreator.less';

export interface ActionAppCreatorProps {
  appCreator: {
    name: string | null;
    idPlugin?: string | null;
    iconClass?: string | null;
  };
  canLinkAppCreator: boolean;
  onClick?: (e: MouseEvent) => void;
}

export const ActionAppCreator = ({
  appCreator,
  canLinkAppCreator,
  onClick,
}: ActionAppCreatorProps) => {
  const appCreatorName =
    appCreator.idPlugin && canLinkAppCreator ? (
      <a className="quiet js-app-creator-link" href="#" onClick={onClick}>
        {appCreator.name}
      </a>
    ) : (
      appCreator.name
    );

  let appCreatorIcon: JSX.Element | null;
  if (appCreator?.iconClass) {
    appCreatorIcon = (
      <span
        className={`app-creator-icon icon-sm ${appCreator.iconClass}`}
        data-testid={getTestId<ActionTestIds>('app-creator-icon')}
      ></span>
    );
  } else if (appCreator.name === 'Atlassian Intelligence') {
    appCreatorIcon = (
      <AtlassianIntelligenceIcon
        dangerous_className={styles.aiIcon}
        testId={getTestId<ActionTestIds>('app-creator-icon')}
      />
    );
  } else {
    appCreatorIcon = null;
  }

  return (
    <span>
      {' '}
      <FormattedMessage
        id="templates.action.via"
        defaultMessage="via {integrationName}"
        description="Message showing which app created the action, with link when available"
        values={{
          integrationName: (
            <>
              {appCreatorIcon}
              {appCreatorName}
            </>
          ),
        }}
      />
    </span>
  );
};
