import type {
  Attachment,
  Card,
  CheckItem,
  Checklist,
  List,
} from '@trello/model-types';

type Item = List | Card | Attachment | CheckItem | Checklist;

export const INDEX_STEP = 65536; // 2^16

const isInPosition = (
  index: number,
  allItems: Pick<Item, 'id' | 'pos'>[],
  item?: Pick<Item, 'id' | 'pos'> | null,
) => {
  if (!item?.id) {
    return false;
  }
  const itemAtPosition = allItems[index];
  return itemAtPosition?.id === item.id;
};

export const calculateItemPosition = <
  InputItem extends Pick<Item, 'id' | 'pos'>,
>(
  index: number,
  allItems: InputItem[],
  item?: Pick<Item, 'id' | 'pos'> | null,
): number => {
  const items = allItems.filter((thisItem) => item?.id !== thisItem.id);

  // if the item is in position no point in moving it around
  if (item && isInPosition(index, allItems, item)) {
    return item.pos;
  }

  const indexBounded = Math.min(Math.max(index, 0), items.length);

  const itemPrev = items[indexBounded - 1];
  const itemNext = items[indexBounded];

  const posItemCurr = item?.pos ?? -1;
  const posItemPrev = itemPrev?.pos ?? -1;
  const posItemNext = itemNext?.pos ?? -1;

  if (posItemNext === -1) {
    // Ensure that the new pos comes after the prev card pos
    if (item && posItemCurr > posItemPrev) {
      // it's already after so no need to update
      return posItemCurr;
    } else {
      // bump it one past the last item
      return posItemPrev + INDEX_STEP;
    }
  } else {
    if (item && posItemCurr > posItemPrev && posItemCurr < posItemNext) {
      return posItemCurr;
    } else if (posItemPrev >= 0) {
      return (posItemNext + posItemPrev) / 2;
    } else {
      // halve the pos of the top item
      return posItemNext / 2;
    }
  }
};
