/* eslint-disable
    eqeqeq,
*/
// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * DS104: Avoid inline assignments
 * DS201: Simplify complex destructure assignments
 * DS205: Consider reworking code to avoid use of IIFEs
 * DS206: Consider reworking classes to avoid initClass
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */

import Promise from 'bluebird';
import _ from 'underscore';
// eslint-disable-next-line no-restricted-imports
import parseURL from 'url-parse';

import { ajax } from '@trello/ajax';
import { ApiError } from '@trello/error-handling';
import { drawFavicon } from '@trello/favicon';
import { idCache } from '@trello/id-cache';
import { markdown, TrelloMarkdown } from '@trello/markdown';
import { getSmartCardClient } from '@trello/smart-card/smart-card-client';
import { truncate } from '@trello/strings';

import { ModelLoader } from 'app/scripts/db/model-loader';
import { EntityDisplay } from 'app/scripts/lib/entity-display';
import { l } from 'app/scripts/lib/localize';
import { Util } from 'app/scripts/lib/util';
import { templates } from 'app/scripts/views/internal/templates';
import { KilnChangesetPreviewTemplate } from 'app/scripts/views/templates/KilnChangesetPreviewTemplate';
import { KilnChangesetThumbnailTemplate } from 'app/scripts/views/templates/KilnChangesetThumbnailTemplate';
import { getDisplayWithMemberDetails } from '../models/internal/getDisplayWithMemberDetails';
import type { ModelCache } from './ModelCache';

import bitbucketmarkcontainedgradientblue from 'resources/images/services/bitbucket-mark-contained-gradient-blue.svg';
import confluenceblogpreview from 'resources/images/services/confluence-blog-preview.svg';
import confluencemarkgradientblue from 'resources/images/services/confluence-mark-gradient-blue.svg';
import confluencepagepreview from 'resources/images/services/confluence-page-preview.svg';
import fogbugz from 'resources/images/services/fogbugz.png';
import github from 'resources/images/services/github.png';
import jiramarkgradientblue from 'resources/images/services/jira-mark-gradient-blue.svg';
import kiln from 'resources/images/services/kiln.png';
import trello from 'resources/images/services/trello.png';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const execMatch = function (match: any, url: any) {
  if (url == null) {
    return null;
  }

  const parsed = parseURL(url);
  let matchedValues = [url];

  for (const attr in match) {
    const expected = match[attr];
    if (_.isString(expected)) {
      // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      if (expected !== parsed[attr]) {
        return null;
      }
    } else if (_.isRegExp(expected)) {
      let results;
      // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      if ((results = expected.exec(parsed[attr])) != null) {
        matchedValues = matchedValues.concat(results.slice(1));
      } else {
        return null;
      }
    } else {
      throw Error('invalid specification');
    }
  }

  return matchedValues;
};

const requestCache = {};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const cachedModelLoad = ({ key, fromModelCache, fromApi }: any) => {
  const modelFromCache = fromModelCache();
  if (modelFromCache != null) {
    return Promise.resolve(modelFromCache);
  }

  // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  return requestCache[key] != null
    ? // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      requestCache[key]
    : // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      (requestCache[key] = fromApi()
        // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        .tap(() => delete requestCache[key])
        .catch(ApiError.NotFound, ApiError.Unauthorized, () => {
          // Keep these negative hits in the requestCache to avoid
          // retrying the API
          setTimeout(() => {
            // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
            return delete requestCache[key];
          }, Util.getMs({ minutes: 5 }));

          return undefined;
        }));
};

const cachedLoadCardLinkData = (
  idCard: string,
  modelCache: typeof ModelCache,
) =>
  cachedModelLoad({
    key: `cardLinkData-${idCard}`,
    fromModelCache() {
      return modelCache.get('Card', idCard);
    },
    fromApi() {
      return ModelLoader.loadCardLinkData(idCard);
    },
  });
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const drawBoardIcon = (prefs: any) => {
  let left;
  const { backgroundImageScaled, backgroundImage, backgroundColor } = prefs;

  const smallUrl =
    (left = Util.smallestPreviewBiggerThan(
      backgroundImageScaled,
      64,
      64,
    )?.url) != null
      ? left
      : backgroundImage;

  return Promise.try(() => {
    if (smallUrl != null) {
      return new Promise((resolve, reject) => {
        const img = document.createElement('img');
        img.setAttribute('crossorigin', 'anonymous');
        img.onload = () => resolve(img);
        img.onerror = () => reject();
        return (img.src = `${smallUrl}?favicon`);
      });
    }
  }).then((img) => {
    return drawFavicon(img as HTMLImageElement, {
      color: backgroundColor,
    });
  });
};

const boardIconCache = {};
const boardIconCachedLoad = ({
  key,
  fallback,
  fromModelCache,
  fromApi,
}: // eslint-disable-next-line @typescript-eslint/no-explicit-any
any) => {
  // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  if (boardIconCache[key] != null) {
    // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    return boardIconCache[key];
  }

  const modelFromCache = fromModelCache();
  const prefs =
    modelFromCache != null ? modelFromCache.get('prefs') : undefined;

  if ((prefs != null ? prefs.background : undefined) != null) {
    // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    return (boardIconCache[key] = drawBoardIcon(prefs).catch(() => {
      return fallback;
    }));
  }

  // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  return (boardIconCache[key] = fromApi()
    // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    .tap(() => delete boardIconCache[key])
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    .then((loadedPrefs: any) => {
      if (loadedPrefs) {
        return drawBoardIcon(loadedPrefs);
      } else {
        return fallback;
      }
    })
    .catch(ApiError.NotFound, ApiError.Unauthorized, () => {
      // Keep these negative hits in the boardIconCache to avoid
      // retrying the API
      setTimeout(() => {
        // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        return delete boardIconCache[key];
      }, Util.getMs({ minutes: 5 }));
      return fallback;
    })
    .catch(() => {
      return fallback;
    }));
};

class KnownServices {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  _knownUrls: any;
  constructor() {
    this._knownUrls = [
      {
        key: 'trello board',
        match: {
          protocol: location.protocol,
          host: location.host,
          pathname: new RegExp(`\
^\
/b/\
(\
[a-zA-Z0-9]{8}|\
[a-fA-F0-9]{24}\
)\
(?:$|/.*)\
`),
        },
        previewClass: 'attachment-thumbnail-preview-trello-logo',
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        getIcon(...args: any[]) {
          const [, shortLink] = Array.from(args[0]) as string[],
            modelCache = args[1];
          return boardIconCachedLoad({
            key: shortLink,
            fallback: trello,
            fromModelCache() {
              return modelCache.get('Board', idCache.getBoardId(shortLink));
            },
            fromApi() {
              return ModelLoader.loadBoardPrefs(shortLink);
            },
          });
        },
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        getText(...args: any[]) {
          const [fullUrl, shortLink] = Array.from(args[0]) as string[],
            modelCache = args[1];
          return cachedModelLoad({
            key: shortLink,
            fromModelCache() {
              return modelCache
                .get('Board', idCache.getBoardId(shortLink))
                ?.get('name');
            },
            fromApi() {
              return ModelLoader.loadBoardName(shortLink);
            },
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
          }).then((name: any) => (name != null ? name : fullUrl));
        },
      },
      {
        key: 'trello action',
        match: {
          protocol: location.protocol,
          host: location.host,
          pathname: new RegExp(`\
^\
/c/\
(\
[a-zA-Z0-9]{8}|\
[a-fA-F0-9]{24}\
)\
(?:$|/.*)\
`),
          hash: new RegExp(`\
^\
\\#(?:comment|action)-([0-9a-f]{24})\
$\
`),
        },
        previewClass: 'attachment-thumbnail-preview-trello-logo',
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        getShared(...args: any[]) {
          const [, , idAction] = Array.from(args[0]) as string[],
            modelCache = args[1];
          return Promise.try(function () {
            let left;
            return (left = modelCache.get('Action', idAction)) != null
              ? left
              : ModelLoader.loadAction(idAction).catch(
                  ApiError,
                  function () {},
                );
          }).then(function (action) {
            if (action != null) {
              return {
                action,
                memberCreator: modelCache.get(
                  'Member',
                  action.get('idMemberCreator'),
                ),
              };
            } else {
              return {};
            }
          });
        },

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        getIcon(...args: any[]) {
          let avatarUrl;
          const shared = args[2];
          const { memberCreator } = shared;
          if (
            (avatarUrl =
              memberCreator != null
                ? memberCreator.get('avatarUrl')
                : undefined) != null
          ) {
            return [avatarUrl, '30.png'].join('/');
          } else {
            return trello;
          }
        },

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        getText(...args: any[]) {
          const [fullUrl] = Array.from(args[0]),
            modelCache = args[1],
            shared = args[2];
          const { action } = shared;

          if (action == null) {
            return fullUrl;
          }

          const text = _.chain(
            new EntityDisplay('action').getEntities(
              getDisplayWithMemberDetails({
                display: action.get('display'),
                modelCache,
              }),
              action.get('data').card?.id,
            ),
          )
            // eslint-disable-next-line @typescript-eslint/no-shadow
            .map(function ({ type, text }) {
              if (type === 'member' && action.isCommentLike()) {
                return text + ':';
              } else if (type === 'comment') {
                return markdown.comments.textInline(text);
              } else {
                return text;
              }
            })
            .join('')
            .value();

          return truncate(text, 64);
        },
      },
      {
        key: 'trello card',
        match: {
          protocol: location.protocol,
          host: location.host,
          pathname: new RegExp(`\
^\
/c/\
(\
[a-zA-Z0-9]{8}|\
[a-fA-F0-9]{24}\
)\
(?:$|/.*)\
`),
          hash: new RegExp(`\
^\
(?!.*(comment|action)-[0-9a-f]{24}).*\
$\
`),
        },
        previewClass: 'attachment-thumbnail-preview-trello-logo',
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        getIcon(...args: any[]) {
          const [, shortLink] = Array.from(args[0]),
            modelCache = args[1];
          // @ts-expect-error
          return cachedLoadCardLinkData(shortLink, modelCache).then(function (
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            card: any,
          ) {
            const fallback = trello;
            if (!card) {
              return fallback;
            }

            const idBoard = card.get('idBoard');
            return boardIconCachedLoad({
              key: idBoard,
              fallback,
              fromModelCache() {
                return modelCache.get('Board', idBoard);
              },
              fromApi() {
                return ModelLoader.loadBoardPrefs(idBoard);
              },
            });
          });
        },
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        getText(...args: any[]) {
          const [fullUrl, shortLink] = Array.from(args[0]),
            modelCache = args[1];
          // @ts-expect-error
          return cachedLoadCardLinkData(shortLink, modelCache).then(function (
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            card: any,
          ) {
            let left;
            return (left = card != null ? card.get('name') : undefined) != null
              ? left
              : fullUrl;
          });
        },
      },
      {
        key: 'fogbugz case',
        match: {
          protocol: /^https?:$/,
          host: /^([^.]+)\.fogbugz\.com$/,
          pathname: new RegExp(`\
^\
/f/cases/\
([\\d]+)\
`),
        },
        previewClass: 'attachment-thumbnail-preview-fogbugz-logo',
        icon: fogbugz,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        getArgs(...args: any[]) {
          const [, , caseNumber] = Array.from(args[0]);
          return { caseNumber };
        },
      },
      {
        key: 'kiln commit',
        match: {
          protocol: /^https?:$/,
          host: new RegExp(`^([^\\.]+)\\.(?:fogbugz\\.com/kiln|kilnhg\\.com)$`),
          pathname: new RegExp(`\
^\
/Code/\
([^/]+)\
/\
([^/]+)\
/\
([^/]+)\
/History/\
([^/]+)\
`),
        },
        previewClass: 'attachment-thumbnail-preview-kiln-logo',
        icon: kiln,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        getText(...args: any[]) {
          const [, , project, repo, branch, hash] = Array.from(args[0]);
          if (repo === 'Group') {
            return `${project} » ${branch}: ${hash}`;
          } else {
            return `${project} » ${repo} » ${branch}: ${hash}`;
          }
        },
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        dataUrl(url: any) {
          return url.replace(new RegExp(`/Code/`), '/trello/Code/');
        },

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        _processJSON(url: any, json: any) {
          if (json.reviews != null) {
            for (const review of Array.from(json.reviews)) {
              // @ts-expect-error TS(2571): Object is of type 'unknown'.
              review.status = l([
                'known services',
                'kiln commit',
                'status',
                // @ts-expect-error TS(2571): Object is of type 'unknown'.
                review.sStatus,
              ]);
              // @ts-expect-error TS(2571): Object is of type 'unknown'.
              review.url = url.replace(
                new RegExp(`/Code/.*$`),
                // @ts-expect-error TS(2571): Object is of type 'unknown'.
                `/Review/${review.sReview}`,
              );
            }
          }

          if (json.sAuthor) {
            // Remove the email address from "John Doe <john@doe.org>"
            json.author = json.sAuthor.replace(/\s<.*$/, '');
          }

          if (json.hash) {
            json.shortHash = json.hash.substr(0, 6);
          }

          if (json.sDescription) {
            const inlineFormatter = new TrelloMarkdown({
              restrict: {
                block: ['paragraph', 'newline'],
                inline: ['break', 'email', 'emoji', 'url'],
              },
            });
            json.shortCommitMessage = json.sDescription.split('\n')[0];
            json.commitMessageHtml = inlineFormatter.format(
              json.sDescription,
            ).output;
          }

          json.url = url;
          return json;
        },

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        previewHtml(url: any, json: any) {
          return templates.fill(
            KilnChangesetPreviewTemplate,
            this._processJSON(url, json),
          );
        },

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        previewHtmlError(url: any, error: any) {
          if (/unable to access/i.test(error)) {
            url = new RegExp(`^https?://[-a-z0-9\\.]+(/kiln)?`).exec(url)?.[0];
            return {
              login: l('known services.kiln commit.error'),
            };
          } else {
            return null;
          }
        },

        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        thumbnailHtml(url: any, json: any, data: any) {
          return templates.fill(KilnChangesetThumbnailTemplate, {
            ...this._processJSON(url, json),
            ...data,
          });
        },
      },
      {
        key: 'github commit',
        match: {
          protocol: 'https:',
          host: 'github.com',
          pathname: new RegExp(`\
^\
/\
([^/]+)/\
([^/]+)/\
commit/\
([^/]{7,40})\
$\
`),
        },
        previewClass: 'attachment-thumbnail-preview-github-logo',
        icon: github,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        getText(...args: any[]) {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          const [, user, repo, hash] = Array.from(args[0]) as any[];
          return `${user}/${repo}: ${hash.substr(0, 7)}`;
        },
      },
      {
        key: 'github issue',
        match: {
          protocol: 'https:',
          host: 'github.com',
          pathname: new RegExp(`\
^\
/\
([^/]+)/\
([^/]+)/\
issues/\
(\\d+)\
$\
`),
        },
        previewClass: 'attachment-thumbnail-preview-github-logo',
        icon: github,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        getArgs(...args: any[]) {
          const [, user, repo, number] = Array.from(args[0]);
          return { user, repo, number };
        },
      },
      {
        name: 'GitHub Pull Request',
        match: {
          protocol: 'https:',
          host: 'github.com',
          pathname: new RegExp(`\
^\
/\
([^/]+)/\
([^/]+)/\
pull/\
(\\d+)\
$\
`),
        },
        previewClass: 'attachment-thumbnail-preview-github-logo',
        icon: github,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        getArgs(...args: any[]) {
          const [, user, repo, number] = Array.from(args[0]);
          return { user, repo, number };
        },
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        getText(...args: any[]) {
          const [, user, repo, number] = Array.from(args[0]);
          return `${user}/${repo}: Pull Request ${number}`;
        },
      },
    ];
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getPreviewClass(productName: any) {
    switch (false) {
      case productName !== 'Confluence':
        return 'attachment-thumbnail-preview-confluence-logo';
      case productName !== 'Jira':
        return 'attachment-thumbnail-preview-jira-logo';
      case productName !== 'Bitbucket' && productName !== 'git':
        return 'attachment-thumbnail-preview-bitbucket-logo';
      default:
        return undefined;
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getIcon(productName: any, resourceType: any) {
    switch (false) {
      case productName !== 'Confluence':
        if (resourceType === 'page') {
          return confluencepagepreview;
        }
        if (resourceType === 'blog') {
          return confluenceblogpreview;
        }
        return confluencemarkgradientblue;
      case productName !== 'Jira':
        return jiramarkgradientblue;
      case productName !== 'Bitbucket' && productName !== 'git':
        return bitbucketmarkcontainedgradientblue;
      default:
        return undefined;
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  interpretWithObjectResolverService(url: any) {
    const smartCardClient = getSmartCardClient();
    return smartCardClient
      .fetchData(url)
      .then((resolvedObject) => {
        if (!resolvedObject) {
          return null;
        }

        const { data, meta } = resolvedObject;

        if (!meta || !data) {
          // If we don't have data or meta objects on the response, it's not
          // a properly resolved url, so just render a normal link.
          return null;
        }

        // @ts-expect-error TS(2339): Property 'name' does not exist on type 'BaseData |... Remove this comment to see the full error message
        const name = data.name || data.url || url;

        const icon =
          // @ts-expect-error TS(2339): Property 'icon' does not exist on type 'BaseData |... Remove this comment to see the full error message
          data.icon?.url ||
          // @ts-expect-error TS(2339): Property 'generator' does not exist on type 'BaseD... Remove this comment to see the full error message
          data.generator?.icon?.url ||
          // @ts-expect-error TS(2339): Property 'generator' does not exist on type 'BaseD... Remove this comment to see the full error message
          this.getIcon(data.generator?.name, meta.resourceType);

        return {
          meta,
          name,
          text: name,
          // @ts-expect-error TS(2339): Property 'generator' does not exist on type 'BaseD... Remove this comment to see the full error message
          previewClass: this.getPreviewClass(data.generator?.name),
          // @ts-expect-error TS(2339): Property 'generator' does not exist on type 'BaseD... Remove this comment to see the full error message
          application: data.generator?.name,
          // @ts-expect-error TS(2339): Property 'tag' does not exist on type 'BaseData | ... Remove this comment to see the full error message
          tag: data.tag,
          icon,
          url,
        };
      })
      .catch((e) => null);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  interpret(url: any, modelCache: any) {
    for (const entry of Array.from(this._knownUrls)) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      let parts: any;
      // @ts-expect-error TS(2571): Object is of type 'unknown'.
      if ((parts = execMatch(entry.match, url)) != null) {
        // eslint-disable-next-line @typescript-eslint/no-shadow
        return (function (entry) {
          // @ts-expect-error TS(2339): Property 'name' does not exist on type 'unknown'.
          let { name, icon } = entry;
          const {
            // @ts-expect-error TS(2339): Property 'key' does not exist on type 'unknown'.
            key,
            // @ts-expect-error TS(2339): Property 'getText' does not exist on type 'unknown... Remove this comment to see the full error message
            getText,
            // @ts-expect-error TS(2339): Property 'getArgs' does not exist on type 'unknown... Remove this comment to see the full error message
            getArgs,
            // @ts-expect-error TS(2339): Property 'previewClass' does not exist on type 'un... Remove this comment to see the full error message
            previewClass,
            // @ts-expect-error TS(2339): Property 'getShared' does not exist on type 'unkno... Remove this comment to see the full error message
            getShared,
            // @ts-expect-error TS(2339): Property 'getIcon' does not exist on type 'unknown... Remove this comment to see the full error message
            getIcon,
          } = entry;
          return Promise.resolve(
            typeof getShared === 'function'
              ? getShared(parts, modelCache)
              : undefined,
          ).then(function (shared) {
            const text = (() => {
              if (getText != null) {
                return getText(parts, modelCache, shared);
              } else {
                let left;
                const args =
                  (left =
                    typeof getArgs === 'function'
                      ? getArgs(parts, modelCache)
                      : undefined) != null
                    ? left
                    : {};
                return l(['known services', key, 'text'], args);
              }
            })();

            if (getIcon != null) {
              icon = getIcon(parts, modelCache, shared);
            }

            if (name == null) {
              name = l(['known services', key, 'name']);
            }

            return Promise.props({
              name,
              previewClass,
              icon,
              text,
              // @ts-expect-error TS(2571): Object is of type 'unknown'.
              type: entry.key,
            });
          });
        })(entry);
      }
    }

    return Promise.resolve(this.interpretWithObjectResolverService(url));
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  _runHandler(name: any, url: any, ...rest: any[]) {
    const adjustedLength = Math.max(rest.length, 1),
      args = rest.slice(0, adjustedLength - 1),
      next = rest[adjustedLength - 1];
    for (const known of Array.from(this._knownUrls)) {
      // @ts-expect-error TS(2571): Object is of type 'unknown'.
      if (execMatch(known.match, url) != null && known[name] != null) {
        // @ts-expect-error TS(2339): Property 'dataUrl' does not exist on type 'unknown... Remove this comment to see the full error message
        const { dataUrl } = known;
        ajax({
          url: dataUrl(url),
          xhrFields: { withCredentials: true },
          headers: { 'X-Trello': "Is it me you're looking for?" },
        })
          .done((json) => {
            // @ts-expect-error TS(2571): Object is of type 'unknown'.
            return next(null, known[name](url, json, ...Array.from(args)));
          })
          .fail((xhr, error, errorText) => {
            const errorHandler = `${name}Error`;
            const loadError =
              // @ts-expect-error TS(2571): Object is of type 'unknown'.
              typeof known[errorHandler] === 'function'
                ? // @ts-expect-error TS(2571): Object is of type 'unknown'.
                  known[errorHandler](url, errorText, ...Array.from(args))
                : undefined;
            return next(loadError != null ? loadError : 'could not load');
          });
        return;
      }
    }
    next();
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  previewHtml(url: any, next: any) {
    return this._runHandler('previewHtml', url, next);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  thumbnailHtml(url: any, data: any, next: any) {
    return this._runHandler('thumbnailHtml', url, data, next);
  }
}

// eslint-disable-next-line @trello/no-module-logic
const knownServices = new KnownServices();
export { knownServices as KnownServices };
