import type { FunctionComponent } from 'react';
import { Suspense } from 'react';

import { ChunkLoadErrorBoundary } from '@trello/error-boundaries';
import { useLazyComponent } from '@trello/use-lazy-component';

export const LazyInviteeOrientation: FunctionComponent<{ idBoard: string }> = ({
  idBoard,
}) => {
  const InviteeOrientation = useLazyComponent(
    () =>
      import(
        /* webpackChunkName: "board-invitee-orientation" */ './InviteeOrientation'
      ),
    { namedImport: 'InviteeOrientation' },
  );

  return (
    <Suspense fallback={null}>
      <ChunkLoadErrorBoundary fallback={null}>
        <InviteeOrientation boardId={idBoard} />
      </ChunkLoadErrorBoundary>
    </Suspense>
  );
};
