import { createContext } from 'use-context-selector';

export interface BoardMembersContextValue {
  isMember: (memberId: string) => boolean;
  isMemberDeactivated: (memberId: string) => boolean;
  canDeleteCommentFromMember: (memberId: string) => boolean;
}

export const emptyBoardMembersContext: BoardMembersContextValue = {
  isMember: () => false,
  isMemberDeactivated: () => false,
  canDeleteCommentFromMember: () => false,
};

export const BoardMembersContext = createContext<BoardMembersContextValue>(
  emptyBoardMembersContext,
);
