// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS206: Consider reworking classes to avoid initClass
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */

import { Analytics, tracingCallback } from '@trello/atlassian-analytics';
import { isDesktop as _isDesktop } from '@trello/browser';
import ReactDOM from '@trello/react-dom-wrapper';

import type { Board } from 'app/scripts/models/Board';
import { BillableGuestListPopoverView } from 'app/scripts/views/board/BillableGuestListPopoverView';
import { View } from 'app/scripts/views/internal/View';
import { PopOver } from 'app/scripts/views/lib/PopOver';
import { PopoverReopenBoardTemplate } from 'app/scripts/views/templates/PopoverReopenBoardTemplate';
import { BillableGuestsAlert } from 'app/src/components/BillableGuestsAlert';

// eslint-disable-next-line @trello/no-module-logic
const isDesktop = _isDesktop();

interface ReopenBoardPopoverView {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  availableLicenseCount: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  board: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  newBillableGuests: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onReopen: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  org: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  reactRoot: any;
}

class ReopenBoardPopoverView extends View<Board> {
  static initClass() {
    this.prototype.viewTitleKey = 'reopen board';

    // @ts-expect-error TS(2322): Type '{ 'click .js-confirm-reopen': string; }' is ... Remove this comment to see the full error message
    this.prototype.events = { 'click .js-confirm-reopen': 'confirmReopen' };
  }

  initialize({
    board,
    org,
    newBillableGuests,
    availableLicenseCount,
    onReopen,
  }: // eslint-disable-next-line @typescript-eslint/no-explicit-any
  any) {
    this.board = board;
    this.org = org;
    this.newBillableGuests = newBillableGuests;
    this.availableLicenseCount = availableLicenseCount;
    this.onReopen = onReopen;
    return super.initialize(...arguments);
  }

  remove() {
    if (this.reactRoot) {
      ReactDOM.unmountComponentAtNode(this.reactRoot);
    }
    return super.remove(...arguments);
  }

  renderReactSection() {
    const props = {
      adminNames: this.org.adminList.map(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (member: any) => member.get('fullName') || member.get('username'),
      ),
      newBillableGuestsCount: this.newBillableGuests.length,
      availableLicenseCount: this.availableLicenseCount,
      orgName: this.org.get('displayName') || this.org.get('name'),
      isOrgAdmin: this.org.owned(),
      isDesktop,
      orgUrl: this.org.url,
      isReopen: true,
      onCountClick: () =>
        PopOver.pushView({
          view: new BillableGuestListPopoverView({
            newBillableGuests: this.newBillableGuests,
          }),
        }),
    };

    const Element = <BillableGuestsAlert {...props} />;
    if ((this.reactRoot = this.el.querySelector('.js-billable-guests-alert'))) {
      return ReactDOM.render(Element, this.reactRoot);
    }
  }

  render() {
    this.$el.html(PopoverReopenBoardTemplate());
    this.renderReactSection();
    return this;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  confirmReopen(e: any) {
    let keepBillableGuests = this.org.owned() && !isDesktop;
    if (
      this.org.owned() &&
      this.availableLicenseCount !== null &&
      this.availableLicenseCount < this.newBillableGuests.length
    ) {
      keepBillableGuests = false;
    }
    const traceId = Analytics.startTask({
      taskName: 'edit-board/closed',
      source: 'reopenBoardInlineDialog',
    });
    this.board.reopen(
      {
        newBillableGuests: this.newBillableGuests,
        keepBillableGuests,
        traceId,
      },
      tracingCallback(
        {
          taskName: 'edit-board/closed',
          source: 'reopenBoardInlineDialog',
          traceId,
        },
        (err) => {
          if (!err) {
            Analytics.sendTrackEvent({
              action: 'reopened',
              actionSubject: 'board',
              source: 'reopenBoardInlineDialog',
              containers: {
                board: {
                  id: this.model.id,
                },
                organization: {
                  id: this.model.getOrganization()?.id,
                },
              },
              attributes: {
                taskId: traceId,
              },
            });
          }
        },
      ),
    );
    if (typeof this.onReopen === 'function') {
      this.onReopen();
    }
    return PopOver.hide();
  }
}

ReopenBoardPopoverView.initClass();
export { ReopenBoardPopoverView };
