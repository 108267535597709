import { navigate } from '@trello/router/navigate';

import { setDocumentTitle } from 'app/src/components/DocumentTitle';

/**
 * @deprecated Call `setDocumentTitle` to set the title, and `navigate` to set the location.
 */
export function setTitleAndLocation({
  location,
  options,
  title,
}: {
  location?: string | undefined;
  options?: object;
  title: string;
}) {
  // eslint-disable-next-line eqeqeq
  if (title != null) {
    setDocumentTitle(title);
  }
  if (location) {
    if (/^#/.test(location)) {
      location = location.substr(1);
    }
    navigate(location, options);
  }
}
