// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.kZhGXdIzboti2f {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}
.JgAaV7rjSrw107 {
  display: flex;
  justify-content: center;
}
.E8M3W3Fh_TfXXV {
  flex: 1 1 auto;
  min-height: calc(100vh - 40px);
  padding-bottom: 32px;
  background-color: var(--ds-surface, #ffffff);
}
.E45NHrG57SOAkI {
  display: flex;
  justify-content: center;
  padding-top: 32px;
}
@media only screen and (max-width: 600px) {
  .E45NHrG57SOAkI {
    display: block;
    padding-top: 0;
  }
}
@media only screen and (max-width: 860px) {
  .E45NHrG57SOAkI.prcJRYxodlH7Fr {
    display: block;
    padding-top: 0;
  }
}
.qsHzFVpgtW66PO {
  width: 960px;
}
@media only screen and (max-width: 1242px) {
  .qsHzFVpgtW66PO {
    width: 640px;
  }
}
@media only screen and (max-width: 1007px) {
  .qsHzFVpgtW66PO {
    width: 100%;
  }
}
@media only screen and (max-width: 600px) {
  .qsHzFVpgtW66PO.hgJuOLn3gplYYR {
    display: none;
  }
}
.qsHzFVpgtW66PO.prcJRYxodlH7Fr {
  width: 960px;
}
@media only screen and (max-width: 1520px) {
  .qsHzFVpgtW66PO.prcJRYxodlH7Fr {
    width: 640px;
  }
}
@media only screen and (max-width: 1267px) {
  .qsHzFVpgtW66PO.prcJRYxodlH7Fr {
    width: 100%;
  }
}
@media only screen and (max-width: 860px) {
}
.LHCE8w34ZA8rs3 {
  display: flex;
  align-items: center;
  justify-content: center;
}
.ZoZ9nZFb0VHbqE {
  padding: 0 15%;
  color: var(--ds-text, #172b4d);
  font-size: 14px;
  line-height: 24px;
}
@media only screen and (max-width: 1242px) {
  .ZoZ9nZFb0VHbqE {
    padding: 0 10%;
  }
}
.ZoZ9nZFb0VHbqE .Ka1IVQ7x3qmSpr {
  width: 100%;
  margin: 16px auto;
}
.yHbL6clzxXJm6A {
  width: 100%;
  margin-bottom: 16px;
}
.zS2eTMvCqutl1f {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 14px;
}
@media only screen and (max-width: 951px) {
  .zS2eTMvCqutl1f {
    display: block;
  }
  .zS2eTMvCqutl1f h2 {
    text-align: center;
  }
}
@media only screen and (max-width: 1204px) {
  .zS2eTMvCqutl1f.prcJRYxodlH7Fr {
    display: block;
  }
  .zS2eTMvCqutl1f.prcJRYxodlH7Fr h2 {
    text-align: center;
  }
}
@media only screen and (max-width: 951px) {
  .GlVKTz_wTyqnO0 {
    justify-content: center;
  }
}
@media only screen and (max-width: 1204px) {
  .GlVKTz_wTyqnO0.prcJRYxodlH7Fr {
    justify-content: center;
  }
}
`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"flexRowWrap": `kZhGXdIzboti2f`,
	"flexAndCenter": `JgAaV7rjSrw107`,
	"directoryBody": `E8M3W3Fh_TfXXV`,
	"navigationAndContent": `E45NHrG57SOAkI`,
	"workspaceNavigationExpanded": `prcJRYxodlH7Fr`,
	"directoryContent": `qsHzFVpgtW66PO`,
	"navigationExpanded": `hgJuOLn3gplYYR`,
	"directorySpinnerContainer": `LHCE8w34ZA8rs3`,
	"listingWrapper": `ZoZ9nZFb0VHbqE`,
	"upgradePromptFullPupPage": `Ka1IVQ7x3qmSpr`,
	"directoryPowerUpsList": `yHbL6clzxXJm6A`,
	"directoryPowerUpsListHeader": `zS2eTMvCqutl1f`,
	"powerUpsList": `GlVKTz_wTyqnO0`
};
export default ___CSS_LOADER_EXPORT___;
