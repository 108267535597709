// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ZR9dbjGUur4MyY em-emoji-picker {
  width: auto;
  --border-radius: 8px;
  --color-border: var(--ds-border, #091e4224);
  --color-border-over: var(--ds-border-selected, #0c66e4);
  --font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Noto Sans', 'Ubuntu', 'Droid Sans', 'Helvetica Neue', sans-serif;
  --font-size: '16px';
}
`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"emojiPicker": `ZR9dbjGUur4MyY`
};
export default ___CSS_LOADER_EXPORT___;
