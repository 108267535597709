import { isMemberLoggedIn } from '@trello/authentication';
import {
  isCrossFlowIFrameOpenState,
  useEligibleWorkspacesForProvisioning,
} from '@trello/cross-flow/experimental';
import { useJwmLinkOrganization } from '@trello/jwm';
import { useSharedState } from '@trello/shared-state';

/**
 * 	Returns a boolean indicating whether the user is eligible to see JWM ads.
 *  Optionally takes a workspaceId parameter to check if that specific workspace is eligible to see JWM ads.
 *  If no workspaceId is given, returns whether the user can see JWM ads in any of their workspaces.
 */
export const useIsEligibleForJwmAds = (workspaceId?: string): boolean => {
  const workspaceLinkedData = useJwmLinkOrganization(workspaceId);

  const jwmEligibleOrganizations = useEligibleWorkspacesForProvisioning();

  const [isCrossFlowIFrameOpen] = useSharedState(isCrossFlowIFrameOpenState);

  if (!isMemberLoggedIn()) {
    return false;
  }

  if (isCrossFlowIFrameOpen) {
    return true;
  }

  if (workspaceId) {
    // not eligible for 1p ads if the Workspace is already linked
    if (workspaceLinkedData) {
      return false;
    }
    return true;
  }

  if (!workspaceId) {
    return jwmEligibleOrganizations.length > 0;
  }

  return false;
};
