import { useEffect, useMemo, useState } from 'react';

import { useCrossFlow } from '@atlassiansox/cross-flow-support';
import type { TouchpointSourceType } from '@trello/ad-sequencer';
import { isDesktop } from '@trello/browser';
import type { ExperimentVariations } from '@trello/feature-gate-client';

import {
  type TrelloCrossFlowExperiment,
  trelloCrossFlowOpen,
} from '../TrelloCrossFlow';
import { useHasEligibleWorkspaces } from './runtimeEligibilityChecks/useHasEligibleWorkspaces';
import { useHasNoAvailableSites } from './runtimeEligibilityChecks/useHasNoAvailableSites';
import { useIsCrossFlowMemberConfirmed } from './runtimeEligibilityChecks/useIsCrossFlowMemberConfirmed';
import { useIsMultiplayerUser } from './runtimeEligibilityChecks/useIsMultiplayerUser';
import { getSporkCffeExperimentalOption } from './getSporkCffeExperimentalOption';
import { useTrelloCrossFlowExperiment } from './useTrelloCrossFlowExperiment';

const experimentFeatureKey = 'trello_xf_spork_branding_positioning_multiplayer';

export type CrossFlowForSporkMultiplayerExperimentVariations =
  ExperimentVariations<typeof experimentFeatureKey, 'cohort'>;

export interface CrossFlowForMultiplayerSporkParams {
  source: TouchpointSourceType;
  workspaceId?: string;
  boardId?: string;
  hasValidAaSession: boolean;
}

export const useCrossFlowForMultiplayerSpork = ({
  source,
  workspaceId,
  boardId,
  hasValidAaSession,
}: CrossFlowForMultiplayerSporkParams): TrelloCrossFlowExperiment<CrossFlowForSporkMultiplayerExperimentVariations> => {
  const crossFlow = useCrossFlow();

  // 1. Check that the member is confirmed
  const { isEligible: isMemberConfirmed } = useIsCrossFlowMemberConfirmed();

  // 2. Check that the member has eligible workspaces
  const { isEligible: hasEligibleWorkspaces, eligibleWorkspaceOptions } =
    useHasEligibleWorkspaces({
      workspaceId,
      entitlementRequired: 'free',
    });

  // 3. Check that the user is not on desktop
  const isWeb = !isDesktop();

  const [
    isImmediateRuntimeEligibilityChecksPassed,
    setImmediateRuntimeEligibilityChecksPassed,
  ] = useState(false);
  // 4. Check that the member has no available sites
  const { isEligible: hasNoAvailableSites } = useHasNoAvailableSites({
    doLoadAvailableSites: isImmediateRuntimeEligibilityChecksPassed,
    source,
  });

  // 5. Check that the member is a multiplayer user
  const { isEligible: isMultiplayerUser } = useIsMultiplayerUser(boardId);

  const experiment = useTrelloCrossFlowExperiment({
    experimentFeatureKey,
    source,
    eligibilityCheckResults: [
      isMemberConfirmed,
      hasEligibleWorkspaces,
      crossFlow.isEnabled,
      isWeb,
      isMultiplayerUser,
    ],
    deferredEligibilityCheckResults: [hasNoAvailableSites],
  });

  useEffect(() => {
    setImmediateRuntimeEligibilityChecksPassed(
      experiment.isImmediateRuntimeEligibilityChecksPassed,
    );
  }, [experiment.isImmediateRuntimeEligibilityChecksPassed]);

  return useMemo(
    () => ({
      ...experiment,
      open: trelloCrossFlowOpen(
        crossFlow,
        source,
        eligibleWorkspaceOptions,
        hasValidAaSession,
        {
          workspaceId,
          boardId,
          ...getSporkCffeExperimentalOption(experiment.cohort),
        },
      ),
    }),
    [
      boardId,
      hasValidAaSession,
      crossFlow,
      eligibleWorkspaceOptions,
      experiment,
      source,
      workspaceId,
    ],
  );
};
