import type { FunctionComponent } from 'react';
import { useCallback } from 'react';
import cx from 'classnames';

import type { CaughtError } from '@trello/error-boundaries';
import { ErrorDetails } from '@trello/error-boundaries';
import { intl } from '@trello/i18n';
import { Button } from '@trello/nachos/button';

import styles from './RuntimeError.less';

interface RuntimeErrorProps {
  caughtError: CaughtError;
}

export const RuntimeError: FunctionComponent<RuntimeErrorProps> = ({
  caughtError,
}) => {
  const reload = useCallback(() => window.location.reload(), []);

  return (
    <div className={cx(styles.bigMessage, styles.quiet)}>
      <img
        alt="Taco Error"
        src={require('resources/images/error-pages/taco-sleeping@2x.png')}
        srcSet={
          require('resources/images/error-pages/taco-sleeping.png') +
          ', ' +
          require('resources/images/error-pages/taco-sleeping@2x.png')
        }
      />
      <h1>
        {intl.formatMessage({
          id: 'templates.runtime_error.an-error-occurred',
          description: 'Displays when there is an error on the page.',
          defaultMessage: 'An error occurred on this page',
        })}
      </h1>
      <p>
        {intl.formatMessage({
          id: 'templates.runtime_error.we-have-been-notified',
          description: 'Displays when there is an error on the page.',
          defaultMessage: 'Our team has been notified.',
        })}
      </p>
      <div className={cx(styles.tryAgainButton)}>
        <Button onClick={reload} appearance="primary">
          {intl.formatMessage({
            id: 'templates.runtime_error.reload',
            description: 'Button to reload page',
            defaultMessage: 'Reload Page',
          })}
        </Button>
      </div>
      <ErrorDetails caughtError={caughtError} />
    </div>
  );
};
