// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * DS206: Consider reworking classes to avoid initClass
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */

import Promise from 'bluebird';
// eslint-disable-next-line no-restricted-imports
import parseURL from 'url-parse';

import { Analytics } from '@trello/atlassian-analytics';
// eslint-disable-next-line no-restricted-imports
import $ from '@trello/jquery';

import { isOverLimit } from 'app/scripts/lib/limits';
import { AttachmentLimitExceededError as LimitExceeded } from 'app/scripts/views/attachment/AttachmentLimitExceededError';
import { View } from 'app/scripts/views/internal/View';
import { Alerts } from 'app/scripts/views/lib/Alerts';
import { CardAttachLinksTemplate } from 'app/scripts/views/templates/CardAttachLinksTemplate';
import { PopoverLimitExceededTemplate } from 'app/scripts/views/templates/PopoverLimitExceededTemplate';

class AttachLinksView extends View {
  static initClass() {
    this.prototype.viewTitleKey = 'attach links';

    // @ts-expect-error TS(2322): Type '{ 'click .js-attach:not([disabled])': string... Remove this comment to see the full error message
    this.prototype.events = { 'click .js-attach:not([disabled])': 'attach' };
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  initialize({ links }: any) {
    // @ts-expect-error
    this.links = links;

    return this.listenTo(
      this.model.attachmentList,
      'add remove reset',
      this.render,
    );
  }

  render() {
    if (!this.model.canAttach()) {
      if (isOverLimit('attachments', 'perCard', this.model.get('limits'))) {
        this.$el.html(PopoverLimitExceededTemplate('perCard'));
      } else if (
        isOverLimit(
          'attachments',
          'perBoard',
          this.model.getBoard().get('limits'),
        )
      ) {
        this.$el.html(PopoverLimitExceededTemplate('perBoard'));
      }

      return this;
    }

    // @ts-expect-error
    const entries = Array.from(this.links).map((link) => ({
      // @ts-expect-error TS(2698): Spread types may only be created from object types... Remove this comment to see the full error message
      ...link,
      // @ts-expect-error TS(2571): Object is of type 'unknown'.
      domain: parseURL(link.url).host,

      attached: this.model.attachmentList.any(
        // @ts-expect-error TS(2571): Object is of type 'unknown'.
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (att: any) => att.get('url') === link.url,
      ),
    }));

    Analytics.sendScreenEvent({
      name: 'attachLinkInlineDialog',
      containers: {
        card: {
          id: this.model.id,
        },
        board: {
          id: this.model.getBoard()?.id,
        },
        list: {
          id: this.model.getList()?.id,
        },
        organization: {
          id: this.model?.getBoard()?.getOrganization()?.id,
        },
      },
    });

    this.$el.html(CardAttachLinksTemplate(entries));
    return this;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  attach(e: any) {
    // eslint-disable-next-line @trello/enforce-variable-case
    const $target = $(e.currentTarget);
    const url = $target.attr('data-url');
    const text = $target.attr('data-text');

    $target.attr('disabled', 'disabled');
    return Promise.fromNode((next) =>
      this.model.uploadUrl({ url, name: text }, next),
    )
      .then(() =>
        Analytics.sendTrackEvent({
          action: 'attached',
          actionSubject: 'link',
          source: 'cardDetailScreen',
        }),
      )
      .catch(LimitExceeded, () =>
        Alerts.show('unable to attach link', 'error', 'linkattachment', 5000),
      );
  }
}

AttachLinksView.initClass();
export { AttachLinksView };
