import type { CSSProperties, MouseEvent as ReactMouseEvent } from 'react';
import { Component } from 'react';

import { makeRGB } from '@trello/colors';
import type { EmojiMartEmoji } from '@trello/emoji';
import { currentLocale } from '@trello/locale';
import { getTokenValue } from '@trello/theme';

import { EmojiProviderComponent } from './EmojiProviderComponent';
import { LazyEmojiMartPicker } from './LazyEmojiMartPicker';

import styles from './EmojiPicker.less';

export interface EmojiPickerProps {
  autoFocus?: boolean;
  dynamicWidth?: boolean;
  emojiButtonRadius?: string;
  emojiButtonSize?: number;
  emojiSize?: number;
  getSpritesheetURL?: (set: 'twitter', sheetSize?: 32 | 20 | 16 | 64) => string;
  icons?: 'auto' | 'outline' | 'solid';
  locale?: string;
  maxFrequentRows?: number;
  onEmojiSelect?(emoji: EmojiMartEmoji, e: ReactMouseEvent): void;
  set?: string;
  theme?: 'dark' | 'light';
}

export class EmojiPicker extends Component<EmojiPickerProps> {
  static defaultProps: EmojiPickerProps = {
    autoFocus: true,
    dynamicWidth: false,
    emojiButtonRadius: '3px',
    emojiButtonSize: 42,
    emojiSize: 28,
    maxFrequentRows: 2,
    icons: 'outline',
    set: 'twitter',
    theme: 'light', // theme is overridden by styles.emojiPicker
  };

  render() {
    const hexColors = {
      background: getTokenValue('elevation.surface.overlay', '#FFFFFF'),
      text: getTokenValue('color.text', '#172B4D'),
      input: getTokenValue('color.background.input', '#FFFFFF'),
    };

    // emoji-mart custom styles only support rgb values
    // other styling is handled in EmojiPicker.less
    const emojiMartCustomStyles = {
      '--rgb-background': makeRGB(hexColors.background),
      '--rgb-color': makeRGB(hexColors.text),
      '--rgb-input': makeRGB(hexColors.input),
    } as CSSProperties;

    return (
      <div className={styles.emojiPicker} style={emojiMartCustomStyles}>
        <EmojiProviderComponent>
          {(sheet, backgroundImageFn) => {
            // if sheet is defined, we've loaded emoji-mart
            if (sheet) {
              return (
                <LazyEmojiMartPicker
                  {...this.props}
                  locale={currentLocale.slice(0, 2)} // emoji-mart locales are only two characters
                  getSpritesheetURL={backgroundImageFn}
                />
              );
            } else {
              <></>;
            }
          }}
        </EmojiProviderComponent>
      </div>
    );
  }
}
