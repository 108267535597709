// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import { teacupWithHelpers } from 'app/scripts/views/internal/teacupWithHelpers';
import { ActionFooterTemplate } from './ActionFooterTemplate';
import { EntityTemplate } from './EntityTemplate';

const t = teacupWithHelpers('action');
const contentEntityTypes = ['attachmentPreview', 'comment', 'checkList'];

// eslint-disable-next-line @trello/no-module-logic
export const ActionTemplate = t.renderable(function (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  context: any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  options: any,
) {
  const { entities, saved, dateLastEdited, date, url, appCreator } = data;
  const { member, showReactions, showInlineReactionButton } = context;
  const { useEmbedPreviews, useCommentUnfurl, usePreviews } = options;
  let needsDate = true;

  t.div('.phenom-creator', function () {
    if (member) {
      return t.div('.member.js-show-mem-menu.js-member-avatar-root', {
        idmember: member.id,
      });
    }
    const isPUpDeprecationAction = data.entities?.find(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (e: any) =>
        e.idContext?.translationKey === 'action_removed_deprecated_plugin',
    );
    if (isPUpDeprecationAction) {
      return t.span('.icon-lg.icon-power-up');
    }
    return t.span('.icon-lg.icon-member');
  });

  t.div('.phenom-desc', function () {
    for (const entity of Array.from(entities)) {
      // @ts-expect-error TS(2571): Object is of type 'unknown'.
      if (Array.from(contentEntityTypes).includes(entity.type)) {
        // we only want to render the date before the entity that contains content
        needsDate = false;
        t.span('.inline-spacer', () => t.text(' '));
        t.span('.phenom-date.quiet', function () {
          if (saved) {
            if (url) {
              t.a('.date.js-hide-on-sending.js-highlight-link.past', {
                dt: date,
                href: url,
              });
            } else {
              t.span('.date.js-hide-on-sending.past', { dt: date, href: url });
            }
          }
          if (dateLastEdited) {
            return t.span(
              '.js-date-title.js-hide-on-sending.past',
              { dt: dateLastEdited },
              function () {
                t.text(' ');
                return t.format('edited');
              },
            );
          }

          if (appCreator) {
            t.span('.js-action-app-footer-react-root');
          }
        });
      }
      EntityTemplate(entity, context, options);
    }

    if (useEmbedPreviews) {
      t.div('.js-embed-previews');
    }

    if (useCommentUnfurl) {
      t.div('.hide.unfurled-comment.comment-preview');
    }

    if (usePreviews) {
      return t.div('.hide.previews.js-previews');
    }
  });

  if (showReactions && saved) {
    return t.div('.phenom-reactions', function () {
      t.div('.js-reaction-piles.reaction-piles-container', {
        class: t.classify({
          last: showInlineReactionButton,
          'reaction-piles-container-full': !showInlineReactionButton,
        }),
      });
      return ActionFooterTemplate(data, context, { ...options, needsDate });
    });
  } else {
    return ActionFooterTemplate(data, context, { ...options, needsDate });
  }
});
