import type { Board, Member } from '@trello/model-types';

import { isMemberOfBoard } from '../board/isMemberOfBoard';
import { isMemberTypeOfBoard } from '../board/isMemberTypeOfBoard';
import { getMembershipTypeFromOrganization } from '../organization/getMembershipTypeFromOrganization';

/**
 * Checks if a user has permission to comment or react to a board/card notification.
 */
export const canComment = (
  member: Pick<
    Member,
    'id' | 'idPremOrgsAdmin' | 'memberType' | 'idEnterprisesAdmin'
  >,
  board: {
    idOrganization?: string | null;
    organization?:
      | (Pick<Board['organization'], 'id' | 'offering'> & {
          memberships: Pick<
            Board['organization']['memberships'][number],
            'idMember' | 'memberType' | 'deactivated' | 'unconfirmed'
          >[];
        })
      | null;
    prefs?: {
      comments?: Board['prefs']['comments'] | null;
    } | null;
    memberships: Pick<
      Board['memberships'][number],
      'idMember' | 'memberType' | 'deactivated' | 'unconfirmed'
    >[];
  },
): boolean => {
  if (!board.prefs?.comments) {
    return false;
  }

  const isMember = isMemberOfBoard(board, member.id);
  const isMemberOrg =
    board?.idOrganization &&
    board?.organization &&
    ['admin', 'normal'].includes(
      getMembershipTypeFromOrganization(member, board.organization) || '',
    );
  const isMemberObserver = isMemberTypeOfBoard(member, board, 'observer');
  const isImplicitAdmin = isMemberTypeOfBoard(member, board, 'admin');

  switch (board.prefs.comments) {
    case 'public':
      return !!member.id;
    case 'org':
      return isMember || isMemberOrg || isMemberObserver;
    case 'observers':
      return isMember || isMemberObserver;
    case 'members':
      return (isMember && !isMemberObserver) || isImplicitAdmin;
    default:
      return false;
  }
};
