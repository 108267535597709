// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import _ from 'underscore';

import { renderReactRoot } from '@trello/component-wrapper';

import type { Board } from 'app/scripts/models/Board';
import { ReactRootComponent } from 'app/scripts/views/internal/ReactRootComponent';
import { View } from 'app/scripts/views/internal/View';
import { PowerUpItemType } from 'app/src/components/PowerUp';
import { LoadingSkeleton } from 'app/src/components/PowerUp/LoadingSkeleton';

function DirectoryLoading({
  numOfAtoms,
  atomType,
}: {
  numOfAtoms: number;
  atomType: PowerUpItemType;
}) {
  return (
    <div className="directory-content-body">
      <div className="directory-listing">
        {_.times(numOfAtoms, (index: number) => (
          <div key={index} className="directory-listing-powerup">
            <LoadingSkeleton type={atomType} />
          </div>
        ))}
      </div>
    </div>
  );
}

function DirectorySpinner() {
  return (
    <div className="directory-listing">
      <div className="directory-spinner">
        <div className="spinner loading centered" />
      </div>
    </div>
  );
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
class DirectorySpinnerView extends View<Board, any> {
  eventSource() {
    return 'boardDirectorySpinnerScreen' as const;
  }

  render() {
    const { section, powerUpId } = this.options;

    renderReactRoot(
      <div>
        <ReactRootComponent>
          {['category', 'custom', 'enabled'].includes(section) ? (
            <DirectoryLoading
              numOfAtoms={24}
              atomType={PowerUpItemType.Description}
            />
          ) : powerUpId ? (
            <DirectorySpinner />
          ) : (
            <DirectoryLoading
              numOfAtoms={6}
              atomType={PowerUpItemType.Featured}
            />
          )}
        </ReactRootComponent>
      </div>,
      this.el,
    );

    return this;
  }
}

export { DirectorySpinnerView };
