/* eslint-disable
    eqeqeq,
*/
// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS104: Avoid inline assignments
 * DS204: Change includes calls to have a more natural evaluation order
 * DS205: Consider reworking code to avoid use of IIFEs
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */

import _ from 'underscore';

import { Analytics } from '@trello/atlassian-analytics';
import {
  ComponentWrapper,
  deferUntil,
  renderReactRoot,
} from '@trello/component-wrapper';
import {
  customFieldsId as CUSTOM_FIELDS_ID,
  e2bId,
  mapPowerUpId as MAP_POWER_UP_ID,
} from '@trello/config';
import { LazyDiscoveryAdPowerUpDirectoryContainer } from '@trello/discovery-ads/LazyDiscoveryAdPowerUpDirectoryContainer';
import { getFeatureGateAsync } from '@trello/feature-gate-client';
import { ApolloProvider } from '@trello/graphql';
import { BoardIdProvider } from '@trello/id-context';
// eslint-disable-next-line no-restricted-imports
import $ from '@trello/jquery';
import {
  Key,
  registerShortcut,
  Scope,
  unregisterShortcut,
} from '@trello/keybindings';
import ReactDOM from '@trello/react-dom-wrapper';
import type { BoardPowerUpsViewParams, RouteId } from '@trello/router';
import {
  getLocation,
  getRouteParamsFromPathname,
  routerState,
} from '@trello/router';
import { navigate } from '@trello/router/navigate';
import {
  workspaceNavigationHiddenState,
  workspaceNavigationState,
} from '@trello/workspace-navigation';

import { getBoardUrlFromShortLink } from 'app/scripts/controller/urls';
import { BUTLER_POWER_UP_ID } from 'app/scripts/data/butler-id';
import { categories } from 'app/scripts/data/directory';
import { Auth } from 'app/scripts/db/Auth';
import type { Board } from 'app/scripts/models/Board';
import type { Plugin } from 'app/scripts/models/Plugin';
import type { BoardView } from 'app/scripts/views/board/BoardView';
import { pluginIOCache } from 'app/scripts/views/internal/plugins/PluginIoCache';
import type { ViewOptions } from 'app/scripts/views/internal/View';
import { View } from 'app/scripts/views/internal/View';
import { createSearchIndex } from 'app/src/components/PowerUpDirectory/SearchHelper';
import { LazyPowerUpsDeprecationBanner } from 'app/src/components/PowerUpsDeprecationBanner/LazyPowerUpsDeprecationBanner';
import { BoardPowerUpsContextProvider } from './BoardPowerUpsContextProvider';
import { DirectoryEnabledView } from './DirectoryEnabledView';
import { DirectoryHeader } from './DirectoryHeaderView';
import { DirectoryHomeView } from './DirectoryHomeView';
import { DirectoryLegacyPowerUps as directoryLegacyPowerUps } from './DirectoryLegacyPowerUps';
import { DirectoryListingView } from './DirectoryListingView';
import { DirectoryPageView } from './DirectoryPageView';
import { DirectoryPluginEnableView } from './DirectoryPluginEnableView';
import { DirectorySearchView } from './DirectorySearchView';
import { DirectorySidebarView } from './DirectorySidebarView';
import { DirectorySpinnerView } from './DirectorySpinnerView';
import { filterUnavailablePlugins } from './filterUnavailablePlugins';
import { isDirectoryRenderedSharedState } from './isDirectoryRenderedSharedState';
import { toggleNavigationMenu } from './toggleNavigationMenu';

type SearchIndex = ReturnType<typeof createSearchIndex>;

interface DirectoryView {
  $directoryBody: JQuery<HTMLDivElement>;
  activeSubview:
    | DirectorySpinnerView
    | DirectorySearchView
    | DirectoryHomeView
    | DirectoryEnabledView
    | DirectoryListingView
    | DirectoryPluginEnableView
    | DirectoryPageView;
  boardView: BoardView;
  featuredPlugins: Plugin[];
  index: SearchIndex;
  isLoading: boolean;
  onOrganizationChangeDebounced: () => void;
  plugins: Plugin[];
  search: string | null;
  section: BoardPowerUpsViewParams['section'];
  unsubscribeFromRouterState: () => void;
  unsubscribeFromWorkspaceNavigationHiddenState: () => void;
  unsubscribeFromWorkspaceNavigationState: () => void;
}

interface DirectoryViewOptions extends ViewOptions {
  section: BoardPowerUpsViewParams['section'];
}

function getPluginById(plugins: Plugin[], pluginId: string) {
  return _.find(plugins, (p) => p.id === pluginId);
}

// Get plugins for the current category
function getPluginsForCategory(plugins: Plugin[], category: string) {
  return _.chain(plugins)
    .filter((plugin) => plugin.get('categories').includes(category))
    .shuffle()
    .value();
}

// Get plugins with `made-by-trello` tag
function getTrelloPlugins(plugins: Plugin[]) {
  return _.chain(plugins)
    .filter((plugin) => plugin.get('tags').includes('made-by-trello'))
    .sortBy((plugin) => pluginIOCache.get(plugin).getName().toLowerCase())
    .value();
}

class DirectoryView extends View<Board, DirectoryViewOptions> {
  className() {
    return 'directory-wrapper';
  }

  unmount: () => void = () => {};
  state: {
    isRenderReactRootEnabled: boolean;
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor(options: any) {
    super(options);
    isDirectoryRenderedSharedState.setValue(true);
    this.unsubscribeFromRouterState = routerState.subscribe((state) => {
      const isPowerUpDirectory =
        state.params &&
        'view' in state.params &&
        (state.params.view === 'power-ups' || state.params.view === 'power-up');
      if (isPowerUpDirectory) {
        this.$directoryBody?.scrollTop?.(0);
        this.renderSubview();
      }
    });
    this.onShortcut = this.onShortcut.bind(this);
    registerShortcut(this.onShortcut, {
      scope: Scope.PowerUpsDirectory,
      key: Key.Escape,
    });
    this.state = {
      isRenderReactRootEnabled: false,
    };
  }

  async initializeFeatureGate() {
    this.state.isRenderReactRootEnabled = await getFeatureGateAsync(
      'trello_plat_migrate_directoryview',
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  initialize({ boardView }: any) {
    this.boardView = boardView;
    super.initialize(...arguments);

    // State
    this.featuredPlugins = [];
    this.isLoading = true;
    this.plugins = [];
    this.search = null;

    // Routes
    this.section = this.options.section;

    const routeParams = getRouteParamsFromPathname<RouteId.BOARD>(
      getLocation().pathname,
    );

    const { shortLink, shortName } = routeParams;
    const category =
      'category' in routeParams ? routeParams.category : undefined;

    // Redirect invalid routes to home.
    if (
      this.section != null &&
      !_.contains(
        ['enabled', 'category', 'custom', 'made-by-trello'],
        this.section,
      )
    ) {
      navigate(`${getBoardUrlFromShortLink(shortLink, shortName)}/power-ups`, {
        trigger: true,
      });
    }

    if (category != null && !_.contains(categories, category)) {
      navigate(`${getBoardUrlFromShortLink(shortLink, shortName)}/power-ups`, {
        trigger: true,
      });
    }

    this.preparePlugins();

    this.makeDebouncedMethods('onOrganizationChange');

    this.listenTo(this.model, {
      'change:idOrganization': this.onOrganizationChangeDebounced,
    });

    this.listenTo(
      this.model.boardPluginList,
      'add remove reset',
      this.frameDebounce(this.renderDeprecatedPluginsBanner),
    );

    // listen for changes to workspace nav expanded/collapsed
    const toggleWorkspaceNavClasses = () => {
      const directoryWrapper = $('.directory-wrapper');

      if (workspaceNavigationHiddenState.value.hidden) {
        directoryWrapper.removeClass(
          'directory-wrapper--workspace-nav-expanded',
        );
        directoryWrapper.removeClass(
          'directory-wrapper--workspace-nav-collapsed',
        );
      } else if (workspaceNavigationState.value.expanded) {
        directoryWrapper.addClass('directory-wrapper--workspace-nav-expanded');
        directoryWrapper.removeClass(
          'directory-wrapper--workspace-nav-collapsed',
        );
      } else {
        directoryWrapper.addClass('directory-wrapper--workspace-nav-collapsed');
        directoryWrapper.removeClass(
          'directory-wrapper--workspace-nav-expanded',
        );
      }
    };
    this.unsubscribeFromWorkspaceNavigationState =
      workspaceNavigationState.subscribe(toggleWorkspaceNavClasses);
    this.unsubscribeFromWorkspaceNavigationHiddenState =
      workspaceNavigationHiddenState.subscribe(toggleWorkspaceNavClasses);

    return (this.trackSearch = _.debounce(this.trackSearch, 5000, true));
  }

  remove() {
    isDirectoryRenderedSharedState.setValue(false);
    if (this.state.isRenderReactRootEnabled) {
      this.unmount();
    } else {
      ReactDOM.unmountComponentAtNode(this.el);
    }
    this.unsubscribeFromRouterState();
    unregisterShortcut(this.onShortcut);
    this.unsubscribeFromWorkspaceNavigationState();
    this.unsubscribeFromWorkspaceNavigationHiddenState();
    return super.remove(...arguments);
  }

  onShortcut() {
    if (!$('.js-directory-search', this.$el).is(':focus')) {
      const routeParams = getRouteParamsFromPathname<RouteId.BOARD>(
        getLocation().pathname,
      );
      navigate(
        getBoardUrlFromShortLink(routeParams.shortLink, routeParams.shortName),
        { trigger: true },
      );
      Analytics.sendPressedShortcutEvent({
        shortcutName: 'escapeShortcut',
        keyValue: 'esc',
        source: 'boardDirectoryScreen',
        containers: {
          board: { id: this.model.id },
          organization: { id: this.model.get('idOrganization') },
        },
      });
    }
  }

  onOrganizationChange() {
    this.isLoading = true;
    this.renderSubview();

    this.model.loadPlugins().then(() => this.preparePlugins());
  }

  // ------- Data Methods -------
  // Get Power-Ups and prepare search index.
  preparePlugins() {
    let pluginPromise;
    if (this.boardView?.reloadDirectoryPlugins) {
      pluginPromise = this.model.loadPlugins();
      this.boardView.reloadDirectoryPlugins = false;
    } else {
      pluginPromise = this.model.getAvailablePlugins();
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return pluginPromise.then((plugins: any) => {
      const routeParams = getRouteParamsFromPathname<RouteId.BOARD>(
        getLocation().pathname,
      );
      const { shortLink, shortName } = routeParams;
      const powerUpId =
        'powerUpId' in routeParams ? routeParams.powerUpId : null;
      this.plugins = plugins;
      if (this.model.isTemplate()) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        this.plugins = plugins.filter((plugin: any) => plugin.id !== e2bId);
      }

      // pass the plugin objects in as plain objects instead of ModelCache models
      this.index = createSearchIndex(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        this.plugins.map((plugin: any) => plugin.toJSON()),
      );

      this.isLoading = false;

      // Redirect to home if there are no custom plugins
      if (this.section === 'custom' && !this.getCustomPlugins().length) {
        navigate(
          `${getBoardUrlFromShortLink(shortLink, shortName)}/power-ups`,
          { trigger: true },
        );
        return;
      }

      // You shouldn't be able to see the listing of plugin that's hidden or moderated,
      // unless that plugin is also enabled on this board.
      if (powerUpId) {
        const plugin = getPluginById(this.plugins, powerUpId);
        if (!plugin) {
          // Redirect to home if the plugin does not exist
          navigate(
            `${getBoardUrlFromShortLink(shortLink, shortName)}/power-ups`,
            { trigger: true },
          );
          return;
        } else {
          const moderatedState = plugin.get('moderatedState');
          if (
            ['hidden', 'moderated'].includes(moderatedState) &&
            !this.model.idPluginsEnabled().includes(plugin.id)
          ) {
            // redirect to homepage of directory
            navigate(
              `${getBoardUrlFromShortLink(shortLink, shortName)}/power-ups`,
              { trigger: true },
            );
            return;
          }
        }
      }

      return this.renderSubview();
    });
  }

  // Get custom plugins for board
  getCustomPlugins() {
    return _.chain(this.plugins)
      .filter(
        (plugin) => !plugin.get('public') && !!plugin.get('iframeConnectorUrl'),
      )
      .sortBy((plugin) => pluginIOCache.get(plugin).getName().toLowerCase())
      .value();
  }

  // Search logic
  getPluginsForSearch() {
    // results come back as plain objects, not ModelCache models
    return this.index.search(this.search as string).map((result) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      return this.plugins.find((plugin: any) => plugin.id === result.id);
    }) as Plugin[];
  }

  getEnabledPlugins() {
    const combinedPlugins: string[] = _.flatten([
      this.model.idPluginsEnabled(),
      this.model.get('powerUps'),
    ]);
    return _.chain(combinedPlugins)
      .filter((idOrName) => {
        return (
          (!this.model.isButlerCore() || idOrName !== BUTLER_POWER_UP_ID) &&
          (!this.model.isMapCore() || idOrName !== MAP_POWER_UP_ID) &&
          idOrName !== CUSTOM_FIELDS_ID
        );
      })
      .map((idOrName) => {
        return _.find(this.plugins, (plugin) => {
          return (
            plugin.id === idOrName ||
            _.findWhere(directoryLegacyPowerUps, {
              id: plugin.id,
              name: idOrName,
            })
          );
        });
      })
      .compact()
      .sortBy((plugin) =>
        pluginIOCache
          .get(plugin as Plugin)
          .getName()
          .toLowerCase(),
      )
      .value() as Plugin[];
  }

  updateSearch(searchTerm: string) {
    if (searchTerm === this.search) {
      return;
    }
    this.search = searchTerm;
    return this.renderSubview();
  }

  trackSearch() {
    this.trackScreenEvent('boardDirectorySearchResultsScreen');
  }

  trackScreenEvent(
    name: Parameters<typeof Analytics.sendScreenEvent>[0]['name'],
    attributes?: Parameters<typeof Analytics.sendScreenEvent>[0]['attributes'],
  ) {
    return Analytics.sendScreenEvent({
      name,
      attributes: {
        ...attributes,
        ...{
          boardPaidStatus: (() => {
            switch (false) {
              case !this.model.isEnterpriseBoard():
                return 'enterprise';
              case !this.model?.getOrganization()?.isPremium():
                return 'bc';
              case !this.model?.getOrganization()?.isStandard():
                return 'standard';
              default:
                return 'free';
            }
          })(),
          maxUserPaidStatus: !Auth.isLoggedIn()
            ? 'notLoggedIn'
            : Auth?.me()?.getMaxPaidStatus(),
        },
      },
      containers: {
        board: { id: this.model.id },
        organization: { id: this.model.get('idOrganization') },
      },
    });
  }

  activeEventSource() {
    // as long as its loaded, use the more-specific event source
    if (this.activeSubview) {
      return this.activeSubview.eventSource();
    } else {
      return 'boardDirectoryScreen';
    }
  }

  renderDeprecatedPluginsBanner() {
    let deprecatedPluginsBanner = document.querySelector(
      '.directory-listing-deprecated-plugins-banner',
    );

    if (this.section !== 'enabled') {
      deprecatedPluginsBanner?.remove();

      return;
    }

    const deprecatedPlugins = this.getEnabledPlugins().reduce<
      {
        name: string;
        sunsetDate: string;
      }[]
    >((context, currPlugin) => {
      const deprecationInfo = currPlugin.get('deprecation');

      if (deprecationInfo?.sunsetDate) {
        context.push({
          ...deprecationInfo,
          name: pluginIOCache.get(currPlugin).getName(),
        });
      }
      return context;
    }, []);

    if (deprecatedPlugins.length === 0) {
      deprecatedPluginsBanner?.remove();

      return;
    }

    if (!deprecatedPluginsBanner) {
      deprecatedPluginsBanner = document.createElement('div');
      deprecatedPluginsBanner.classList.add(
        'directory-listing-deprecated-plugins-banner',
      );
    }

    this.$directoryBody.prepend(deprecatedPluginsBanner);

    renderReactRoot(
      <ComponentWrapper>
        <LazyPowerUpsDeprecationBanner
          deprecatedPowerUps={deprecatedPlugins}
          showWarningIcon={false}
          centerContent
        />
      </ComponentWrapper>,
      deprecatedPluginsBanner,
    );
  }

  getCurrentSubview() {
    const pathname = getLocation().pathname;
    const routeParams = getRouteParamsFromPathname<RouteId.BOARD>(pathname);
    const category = 'category' in routeParams ? routeParams.category : null;
    const section = 'section' in routeParams ? routeParams.section : null;
    const powerUpId = 'powerUpId' in routeParams ? routeParams.powerUpId : null;
    const isEnabling = pathname.endsWith('/enable');

    if (this.isLoading) {
      return this.subview(DirectorySpinnerView, this.model, {
        section,
        powerUpId,
        isEnabling,
      });
    } else if (this.search) {
      this.trackSearch();
      return this.subview(DirectorySearchView, this.model, {
        plugins: filterUnavailablePlugins(this.getPluginsForSearch(), {
          filterDeprecated: false,
        }),
        searchTerm: this.search,
        directoryView: this,
      });
    } else {
      if (routeParams.view === 'power-ups') {
        if (category) {
          return this.subview(DirectoryPageView, this.model, {
            category,
            plugins: filterUnavailablePlugins(
              getPluginsForCategory(this.plugins, category),
              {
                filterDeprecated: false,
              },
            ),
            directoryView: this,
          });
        } else if (section === 'made-by-trello') {
          return this.subview(DirectoryPageView, this.model, {
            section,
            plugins: filterUnavailablePlugins(getTrelloPlugins(this.plugins), {
              filterDeprecated: false,
            }),
            directoryView: this,
          });
        } else if (section === 'custom') {
          return this.subview(DirectoryPageView, this.model, {
            section,
            plugins: filterUnavailablePlugins(this.getCustomPlugins(), {
              filterDeprecated: false,
            }),
            directoryView: this,
          });
        } else if (section === 'enabled') {
          return this.subview(DirectoryEnabledView, this.model, {
            plugins: this.plugins,
            directoryView: this,
          });
        } else {
          return this.subview(DirectoryHomeView, this.model, {
            directoryView: this,
          });
        }
      } else if (routeParams.view === 'power-up') {
        if (powerUpId && isEnabling) {
          return this.subview(DirectoryPluginEnableView, this.model, {
            plugin: getPluginById(this.plugins, powerUpId),
            directoryView: this,
          });
        } else if (powerUpId) {
          return this.subview(DirectoryListingView, this.model, {
            plugin: getPluginById(this.plugins, powerUpId),
            directoryView: this,
          });
        }
      }
    }

    return this.subview(DirectoryHomeView, this.model, {
      directoryView: this,
    });
  }

  renderSubview() {
    // Clear out current subview
    const $contentContainer = $('#directory-content', this.$el);
    $contentContainer.empty();
    if (this.activeSubview != null) {
      this.deleteSubview(this.activeSubview);
    }

    this.activeSubview = this.getCurrentSubview();

    this.renderDeprecatedPluginsBanner();

    return this.appendSubview(this.activeSubview, $contentContainer);
  }

  // This sets up the layout of the directory.
  renderOnce() {
    this.trackScreenEvent('boardDirectoryScreen', {
      totalEnabled: this.model.powerUpsCount(),
      allPowerUpsEnabled: this.model.idPluginsEnabled(),
    });

    const workspaceId = this.model.get('idOrganization');

    if (this.state.isRenderReactRootEnabled) {
      const unmount = renderReactRoot(
        <ApolloProvider>
          <BoardIdProvider value={this.model.id}>
            <BoardPowerUpsContextProvider>
              <DirectoryHeader />
              <div className="directory-body js-directory-body">
                <LazyDiscoveryAdPowerUpDirectoryContainer
                  workspaceId={workspaceId}
                />
                <div className="directory-body-container">
                  <div className="directory-sidebar js-directory-sidebar">
                    <DirectorySidebarView
                      onSearch={this.updateSearch.bind(this)}
                    />
                  </div>
                  <div id="directory-content"></div>
                </div>
                {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
                <div
                  className="directory-responsive-overlay js-directory-responsive-overlay"
                  onClick={toggleNavigationMenu}
                ></div>
              </div>
            </BoardPowerUpsContextProvider>
          </BoardIdProvider>
        </ApolloProvider>,
        this.el,
        true,
      ).unmount;

      this.unmount = unmount;
    } else {
      ReactDOM.render(
        <ApolloProvider>
          <BoardIdProvider value={this.model.id}>
            <BoardPowerUpsContextProvider>
              <DirectoryHeader />
              <div className="directory-body js-directory-body">
                <LazyDiscoveryAdPowerUpDirectoryContainer
                  workspaceId={workspaceId}
                />
                <div className="directory-body-container">
                  <div className="directory-sidebar js-directory-sidebar">
                    <DirectorySidebarView
                      onSearch={this.updateSearch.bind(this)}
                    />
                  </div>
                  <div id="directory-content"></div>
                </div>
                {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions */}
                <div
                  className="directory-responsive-overlay js-directory-responsive-overlay"
                  onClick={toggleNavigationMenu}
                ></div>
              </div>
            </BoardPowerUpsContextProvider>
          </BoardIdProvider>
        </ApolloProvider>,
        this.el,
      );
    }

    this.$directoryBody = $('.js-directory-body', this.$el);

    deferUntil(
      () => this.$el.find('.js-directory-body').length > 0, // Condition to wait for the directory body to be available
      () => {
        if (!workspaceNavigationHiddenState.value.hidden) {
          if (workspaceNavigationState.value.expanded) {
            this.$el.addClass('directory-wrapper--workspace-nav-expanded');
          } else {
            this.$el.addClass('directory-wrapper--workspace-nav-collapsed');
          }
        }
        this.renderSubview();
      },
    );

    return this;
  }
}

export { DirectoryView };
