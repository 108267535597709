// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS205: Consider reworking code to avoid use of IIFEs
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */

import _ from 'underscore';

import { Analytics } from '@trello/atlassian-analytics';
import { fireConfetti, shouldFireConfetti } from '@trello/confetti';
// eslint-disable-next-line no-restricted-imports
import $ from '@trello/jquery';
import ReactDOM from '@trello/react-dom-wrapper';

import { View } from 'app/scripts/views/internal/View';
import { EmojiPicker } from 'app/src/components/Emoji/EmojiPicker';

interface ReactionSelector {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  options: any;
}

class ReactionSelector extends View {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor(props: any) {
    super(props);
    this.handleReactionClick = this.handleReactionClick.bind(this);
  }

  render() {
    // render the picker within the popover we selected for it
    ReactDOM.render(this.getEmojiPicker(), this.$el[0]);
    this.trackReactionClickEvents();
    return this;
  }

  remove() {
    ReactDOM.unmountComponentAtNode(this.$el[0]);
    return super.remove(...arguments);
  }

  trackReactionClickEvents() {
    // Using these events to handle emoji mart click tracking events until
    // necessary PRs can be put into place for event handlers
    const selectors =
      '.emoji-mart, .emoji-mart-anchor, .emoji-mart-skin-swatch-selected, .emoji-mart-skin-swatches-opened .emoji-mart-skin';
    $(document)
      .off('click', selectors)
      .on('click', selectors, (e) => {
        e.stopPropagation();

        const $currentTarget = $(e.currentTarget);
        const isCategorySelector = $currentTarget.is('.emoji-mart-anchor');

        const target = (() => {
          if (isCategorySelector) {
            return 'reaction selector category button';
          } else if ($currentTarget.is('.emoji-mart-skin-swatch')) {
            return 'reaction selector skin tone button';
          } else if ($currentTarget.is('.emoji-mart-skin')) {
            return 'reaction selector skin tone menu';
          }
        })();
        if (!target) {
          return;
        }
        Analytics.sendUIEvent({
          action: 'clicked',
          actionSubject: 'icon',
          actionSubjectId: 'reactionMenuIcon',
          source: 'reactionSelectorInlineDialog',
          attributes: {
            target,
          },
          containers: {
            card: {
              id: this.options?.trackingContext?.cardId,
            },
            board: {
              id: this.options?.trackingContext?.boardId,
            },
            list: {
              id: this.options?.trackingContext?.listId,
            },
          },
        });
      });
    return $(document).on(
      'input',
      '.emoji-mart-search input',
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      _.debounce((e: any) => {
        e.stopPropagation();
        Analytics.sendTrackEvent({
          action: 'searched',
          actionSubject: 'reaction',
          source: 'reactionSelectorInlineDialog',
          containers: {
            card: {
              id: this.options?.trackingContext?.cardId,
            },
            board: {
              id: this.options?.trackingContext?.boardId,
            },
            list: {
              id: this.options?.trackingContext?.listId,
            },
          },
        });
      }, 1000),
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleReactionClick(emoji: any, e: any) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const trackFn = (isAdded: any) => {
      if (isAdded) {
        Analytics.sendTrackEvent({
          action: 'added',
          actionSubject: 'reaction',
          source: 'reactionSelectorInlineDialog',
          containers: {
            card: {
              id: this.options?.trackingContext?.cardId,
            },
            list: {
              id: this.options?.trackingContext?.listId,
            },
            board: {
              id: this.options?.trackingContext?.boardId,
            },
          },
          attributes: {
            addedTo: 'comment',
            emoji: emoji.id,
            fromPile: false,
          },
        }); // reactions/reaction-piles.js
      } else {
        Analytics.sendTrackEvent({
          action: 'removed',
          actionSubject: 'reaction',
          source: 'reactionSelectorInlineDialog',
          containers: {
            card: {
              id: this.options?.trackingContext?.cardId,
            },
            list: {
              id: this.options?.trackingContext?.listId,
            },
            board: {
              id: this.options?.trackingContext?.boardId,
            },
          },
          attributes: {
            removedFrom: 'comment',
            emoji: emoji.id,
            fromPile: false,
          },
        }); // reactions/reaction-piles.js
      }
    };

    if (shouldFireConfetti(emoji.native)) {
      const idEmoji = emoji.unified.toUpperCase();

      if (!this.options.reactionList.findMyReaction(idEmoji)) {
        fireConfetti({
          x: e.pageX / window.innerWidth,
          y: e.pageY / window.innerHeight,
        });
      }
    }

    this.options.reactionList.toggleReaction(
      this.options.actionId,
      emoji,
      trackFn,
    );
    return this.options.onReactionClick?.(e, emoji);
  }

  getEmojiPicker() {
    return <EmojiPicker onEmojiSelect={this.handleReactionClick} />;
  }

  willBePopped() {
    $('.js-open-reactions').removeClass('active');
    $(document).off(
      'click',
      '.emoji-mart, .emoji-mart-anchor, .emoji-mart-skin-swatch-selected, .emoji-mart-skin-swatches-opened .emoji-mart-skin',
    );
    return $(document).off('input', '.emoji-mart-search input');
  }
}

export { ReactionSelector };
