// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import { makePreviewCachable } from '@trello/image-previews';

import { Helpers as AttachmentHelpers } from 'app/scripts/views/attachment/Helpers';
import { teacupWithHelpers } from 'app/scripts/views/internal/teacupWithHelpers';

const t = teacupWithHelpers('entity');

// eslint-disable-next-line @trello/no-module-logic
export const EntityTemplate = t.renderable(function (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  entity: any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  context: any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  options: any,
) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  let avatarUrl: any,
    id,
    isFriendly,
    isTrello,
    link,
    originalUrl,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    previewUrlForRes: any,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    username: any,
    url,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    nameHtml: any,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    textHtml: any,
    canEdit: boolean,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    truncateComment: any,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    extremeTruncation: any,
    classes,
    title,
    showCompactAttachmentPreview,
    date,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    current: any;
  const { type, text } = entity;

  switch (type) {
    case 'text':
    case 'list':
    case 'checklist':
    case 'plugin':
    case 'date':
      return t.text(text);

    case 'card':
      ({ url, nameHtml } = entity);
      if (nameHtml) {
        return t.span('.js-friendly-links-for-link-card', () =>
          t.raw(nameHtml),
        );
      }
      return t.a('.action-card', { href: url }, () => t.text(text));

    case 'board':
      ({ url } = entity);
      return t.a({ href: url }, () => t.text(text));

    case 'organization':
      ({ url } = entity);
      return t.a({ href: url }, text || t.l('an-organization'));

    case 'checkItem':
      ({ nameHtml } = entity);
      if (nameHtml) {
        return t.span('.js-friendly-links', () => t.raw(nameHtml));
      } else {
        return t.text(text);
      }

    case 'comment':
      ({ textHtml } = entity);
      ({ canEdit } = context);
      ({ truncateComment, extremeTruncation } = options);

      return t.div('.comment-container', function () {
        // eslint-disable-next-line @typescript-eslint/no-shadow
        const classes = {
          'action-comment': true,
          'can-edit': canEdit,
          'can-view-video': true,
          markeddown: true,
          'js-comment': true,
          'is-comments-rewrite': true,
          'is-truncated': truncateComment,
          'extreme-truncation': extremeTruncation,
        };
        return t.div({ class: t.classify(classes), dir: 'auto' }, function () {
          if (truncateComment) {
            t.div('.action-comment-fade-button.js-expand-comment');
          }
          t.div('.current-comment.js-friendly-links.js-open-card', () =>
            t.raw(textHtml),
          );
          if (canEdit) {
            return t.div('.js-edit-comment-react-root');
          }
        });
      });

    case 'customField':
      return t.text(text);

    case 'customFieldItem':
      return t.text(text);

    case 'attachment':
      ({ link, url, isTrello, id, isFriendly } = entity);
      if (link || isFriendly) {
        if (AttachmentHelpers.isViewerable(url)) {
          return t.a(
            '.js-open-attachment-viewer',
            { href: url, 'data-idattachment': id },
            () => t.text(text),
          );
        } else if (isTrello) {
          return t.a('.js-friendly-links', { href: url }, () => t.text(text));
        } else {
          return t.a(
            '.js-friendly-links',
            { href: url, target: '_blank', rel: 'noopener noreferrer' },
            () => t.text(text),
          );
        }
      } else {
        return t.span('.attachment-deleted', () => t.text(text));
      }

    case 'attachmentPreview':
      ({ previewUrlForRes, originalUrl, id } = entity);
      ({ showCompactAttachmentPreview } = options);

      if (previewUrlForRes) {
        classes = {
          'js-open-attachment-viewer': !showCompactAttachmentPreview,
          'js-open-card': showCompactAttachmentPreview,
          'js-friendly-links': true,
        };

        const previewClasses = {
          'attachment-image-preview': true,
          'mod-compact-image-preview': showCompactAttachmentPreview,
        };

        return t.a(
          {
            class: t.classify(classes),
            href: originalUrl,
            target: '_blank',
            'data-idattachment': id,
          },
          () =>
            t.img({
              class: t.classify(previewClasses),
              src: makePreviewCachable(previewUrlForRes),
            }),
        );
      } else {
        return t.raw('&nbsp;');
      }

    case 'member':
      ({ id, avatarUrl, username } = entity);
      return t.span(
        '.inline-member.js-show-mem-menu',
        { idMember: id },
        function () {
          if (avatarUrl) {
            title = `${text} (${username})`;
            t.img('.inline-member-av', {
              src: [avatarUrl, '30.png'].join('/'),
              alt: title,
              title,
            });
          }
          return t.span('.u-font-weight-bold', () => t.text(text));
        },
      );

    case 'relDate':
      ({ date, current } = entity);
      return t.span('.rel-date', { dt: date, text }, () => t.text(current));

    default:
      break;
  }
});
