import type { FunctionComponent, PropsWithChildren } from 'react';
import { createContext, useCallback } from 'react';

import { canEdit as canEditBoard } from '@trello/business-logic/board';

import { usePermissionsContextQuery } from './PermissionsContextQuery.generated';

interface PermissionsContextState {
  canEdit(): boolean;
}

export const PermissionsContext = createContext<PermissionsContextState>({
  canEdit() {
    return false;
  },
});

interface PermissionsContextProviderProps {
  idBoard: string;
}

export const PermissionsContextProvider: FunctionComponent<
  PropsWithChildren<PermissionsContextProviderProps>
> = ({ idBoard, children }) => {
  const { data } = usePermissionsContextQuery({
    variables: {
      idBoard,
    },
    waitOn: ['MemberHeader', 'CurrentBoardInfo'],
  });

  const { board, member } = data ?? {};

  const canEdit = useCallback(() => {
    if (!board || !member) {
      return false;
    }
    return canEditBoard(
      member,
      board,
      board.organization || null,
      board.organization?.enterprise,
    );
  }, [board, member]);

  return (
    <PermissionsContext.Provider value={{ canEdit }}>
      {children}
    </PermissionsContext.Provider>
  );
};
