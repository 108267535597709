/* eslint-disable
    eqeqeq,
*/
// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS206: Consider reworking classes to avoid initClass
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import { sandboxParams } from 'app/scripts/data/plugin-iframe-sandbox';
import { teacupWithHelpers } from 'app/scripts/views/internal/teacupWithHelpers';
import { PluginView } from 'app/scripts/views/plugin/PluginView';
const t = teacupWithHelpers();

// eslint-disable-next-line @trello/no-module-logic, @typescript-eslint/no-explicit-any
const template = t.renderable(function (param: any) {
  if (param == null) {
    param = {};
  }
  const { content } = param;
  switch (content.type) {
    case 'iframe':
      t.iframe('.plugin-iframe', {
        allow: 'microphone; camera',
        sandbox: sandboxParams,
        src: content.url,
      });
      t.div('.plugin-iframe-popover-overlay');
      break;
    default:
  }
});

interface PluginOverlayView {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  content: any;
}

class PluginOverlayView extends PluginView {
  static initClass() {
    // @ts-expect-error TS(2322): Type 'string' is not assignable to type '() => str... Remove this comment to see the full error message
    this.prototype.className = 'plugin-overlay js-plugin-iframe-container';
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  initialize({ content }: any) {
    this.content = content;
    return this.retain(this.content);
  }

  renderOnce() {
    this.$el.html(template({ content: this.content }));
    // @ts-expect-error TS(2555): Expected at least 2 arguments, but got 1.
    this.initIFrames(this.model);
    return this;
  }
}
PluginOverlayView.initClass();
export { PluginOverlayView };
