import { useCallback } from 'react';

import { useBoardPowerUpsContext } from './useBoardPowerUpsContext';

export function useCustomPowerUps() {
  const customPowerUps = useBoardPowerUpsContext(
    useCallback(({ allPowerUps }) => {
      return allPowerUps.filter(
        (powerUp) => powerUp.public === false && !!powerUp.iframeConnectorUrl,
      );
    }, []),
  );

  return customPowerUps;
}
