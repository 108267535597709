/* eslint-disable
    eqeqeq,
*/
// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * DS104: Avoid inline assignments
 * DS204: Change includes calls to have a more natural evaluation order
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */

import type { AllHTMLAttributes, MouseEventHandler } from 'react';
import { FormattedMessage } from 'react-intl';
import { ApolloProvider } from '@apollo/client';
import Promise from 'bluebird';
import _ from 'underscore';

import { Analytics } from '@trello/atlassian-analytics';
import { getMemberId } from '@trello/authentication';
import { dontUpsell } from '@trello/browser';
import { ComponentWrapper, renderReactRoot } from '@trello/component-wrapper';
import {
  e2bId,
  firstPartyPluginsOrg,
  gmailId,
  gmailUrl,
  mapPowerUpId,
  microsoftTeamsId,
  microsoftTeamsUrl,
} from '@trello/config';
import { client } from '@trello/graphql';
import { intl } from '@trello/i18n';
// eslint-disable-next-line no-restricted-imports
import $ from '@trello/jquery';
import { markdownAsText } from '@trello/markdown';
import type { RouteId } from '@trello/router';
import { getLocation, getRouteParamsFromPathname } from '@trello/router';
import { navigate } from '@trello/router/navigate';
import type { PowerUpTestIds } from '@trello/test-ids';
import { getTestId } from '@trello/test-ids';

import { getBoardUrlFromShortLink } from 'app/scripts/controller/urls';
import { Auth } from 'app/scripts/db/Auth';
import {
  sendPluginScreenEvent,
  sendPluginTrackEvent,
  sendPluginUIEvent,
} from 'app/scripts/lib/plugins/plugin-behavioral-analytics';
import type { Board } from 'app/scripts/models/Board';
import type { Plugin } from 'app/scripts/models/Plugin';
import type { PluginIO } from 'app/scripts/views/internal/plugins/PluginIo';
import { pluginIOCache } from 'app/scripts/views/internal/plugins/PluginIoCache';
import { pluginRunner } from 'app/scripts/views/internal/plugins/PluginRunner';
import { pluginsChangedSignal } from 'app/scripts/views/internal/plugins/PluginsChangedSignal';
import { recupWithHelpers } from 'app/scripts/views/internal/recupWithHelpers';
import { View } from 'app/scripts/views/internal/View';
import { Alerts } from 'app/scripts/views/lib/Alerts';
import { Confirm } from 'app/scripts/views/lib/Confirm';
import { PopOver } from 'app/scripts/views/lib/PopOver';
import type { CalendarSettingsView } from 'app/scripts/views/power-ups/CalendarSettingsView';
import type { CardAgingSettingsView } from 'app/scripts/views/power-ups/CardAgingSettingsView';
import type { VotingSettingsView } from 'app/scripts/views/power-ups/VotingSettingsView';
import { DisablePluginSettings } from 'app/src/components/DisablePluginSettings';
import { LazyEmailToBoardButton } from 'app/src/components/EmailToBoardPopover';
import { PowerUp, PowerUpItemType } from 'app/src/components/PowerUp';
import { stopPropagationAndPreventDefault } from 'app/src/stopPropagationAndPreventDefault';
import { ReactRootComponent } from '../internal/ReactRootComponent';
import { DirectoryLegacyPowerUps } from './DirectoryLegacyPowerUps';
import type { DirectoryView } from './DirectoryView';

import defaultPowerUpIcon from 'resources/images/directory/icons/customIcon.png';
// @ts-expect-error TS(2554): Expected 0-1 arguments, but got 2.
const t = recupWithHelpers('directory_power_up_item', 'member');

// eslint-disable-next-line @trello/no-module-logic
const PowerUpSettings = t.renderable(
  ({
    authorize,
    disable,
    supportsSettings,
    showSettings,
    supportsAuth,
    hasPersonalSettings,
    isBoardReadOnly,
    removeData,
    authStatus,
    showListingOption,
    navigateToListing,
  }: {
    authorize: () => void;
    disable: () => void;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    supportsSettings: any;
    showSettings: () => void;
    supportsAuth: boolean;
    hasPersonalSettings: boolean;
    isBoardReadOnly: boolean;
    removeData: () => void;
    authStatus: { authorized: boolean };
    showListingOption: boolean;
    navigateToListing: () => void;
  }) =>
    t.ul('.pop-over-list', function () {
      if (showListingOption && !isBoardReadOnly) {
        t.li({ onClick: navigateToListing }, () =>
          t.a(() => t.format('view-power-up')),
        );
      }
      if (
        supportsAuth &&
        authStatus &&
        !authStatus.authorized &&
        !isBoardReadOnly
      ) {
        t.li({ onClick: authorize }, () =>
          t.a(() => t.format('authorize-account')),
        );
      } else {
        if (supportsSettings && !isBoardReadOnly) {
          t.li({ onClick: showSettings }, () =>
            t.a(() => t.format('edit-power-up-settings')),
          );
        }
        if (hasPersonalSettings) {
          t.li({ onClick: removeData }, () =>
            t.a(() => t.format('remove-personal-settings')),
          );
        }
      }
      return t.li(
        {
          'data-testid': getTestId<PowerUpTestIds>('disable-powerup-link'),
          onClick: disable,
        },
        () => t.a(() => t.format('disable')),
      );
    }),
);

function PupDisableWarning({
  cancel,
  disable,
  powerupName,
}: {
  cancel: () => void;
  disable: () => void;
  powerupName: string;
}) {
  return (
    <div className="disable-warning-container">
      <p className="disable-warning-message-new-sku">
        <FormattedMessage
          id="templates.directory_power_up_item.disable-warning-message-new-sku"
          defaultMessage="If you disable the {powerupName} Power-Up, you will only be able to re-enable it by upgrading your Workspace."
          description="Warning message displayed when a user tries to disable a Power-Up that is part of a Workspace subscription."
          values={{
            powerupName,
          }}
        />
      </p>
      <button
        className="nch-button nch-button--danger directory-warning-disable-button"
        onClick={disable}
      >
        <FormattedMessage
          id="templates.directory_power_up_item.disable-warning-button"
          defaultMessage="Disable {powerupName} Power-Up"
          description="Button that allows a user to confirm deletion of a Power-Up."
          values={{
            powerupName,
          }}
        />
      </button>
      <button className="nch-button directory-cancel-button" onClick={cancel}>
        <FormattedMessage
          id="templates.directory_power_up_item.cancel"
          defaultMessage="Cancel"
          description="Button that allows as user to cancel deletion of a Power-Up"
        />
      </button>
    </div>
  );
}

function EnableButton({
  onClick,
}: {
  onClick: MouseEventHandler<HTMLButtonElement>;
}) {
  return (
    <button
      className="nch-button nch-button--primary directory-enable-button"
      onClick={onClick}
    >
      <FormattedMessage
        id="templates.directory_power_up_item.add"
        defaultMessage="Add"
        description="Button that allows a user to add a Power-Up to a board."
      />
    </button>
  );
}

function DisabledButton({
  enterpriseDisplayName,
}: {
  enterpriseDisplayName: string;
}) {
  return (
    <button
      className="nch-button nch-button--disabled directory-disabled-button"
      title={intl.formatMessage(
        {
          id: 'templates.directory_power_up_item.this-power-up-was-disabled-by-the-enterprise',
          defaultMessage:
            'This Power-Up was disabled by the {enterpriseDisplayName} enterprise.',
          description:
            'Message displayed to a user when a Power-Up is disabled by for their enterprise.',
        },
        {
          enterpriseDisplayName,
        },
      )}
    >
      <FormattedMessage
        id="templates.directory_power_up_item.add"
        defaultMessage="Add"
        description="Button that allows a user to add a Power-Up to a board."
      />
    </button>
  );
}

function SettingsButton({
  onClick,
}: {
  onClick: MouseEventHandler<HTMLAnchorElement>;
}) {
  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <a className="button directory-settings-button" onClick={onClick}>
      <span className="icon-sm icon-gear" />
      <FormattedMessage
        id="templates.directory_power_up_item.settings"
        defaultMessage="Settings"
        description="Button that allows a user to navigate to the settings for a Power-Up."
      />
    </a>
  );
}

function ConfigureButton({
  onClick,
  href,
}: {
  onClick: MouseEventHandler<HTMLAnchorElement>;
  href?: AllHTMLAttributes<HTMLAnchorElement>['href'];
}) {
  return (
    <a
      className="nch-button nch-button--primary directory-enable-button"
      onClick={onClick}
      href={href}
      target="_blank"
    >
      <FormattedMessage
        id="templates.directory_power_up_item.configure"
        defaultMessage="Configure"
        description="Button that allows a user to configure a Power-Up."
      />
    </a>
  );
}

interface PluginDetails {
  author: string;
  description: string;
  displayName: string;
  iconUrl: string;
  moderatedState: string;
  name?: string;
  overview: string;
}

interface LegacyPluginDetails extends PluginDetails {
  name: string;
  settingsView: (
    model: Board,
  ) => CalendarSettingsView | CardAgingSettingsView | VotingSettingsView;
}

interface DirectoryPluginItemView {
  canEnable: boolean;
  directoryView: DirectoryView;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  initialData: any;
  io: PluginIO;
  isEnabled: boolean;
  myMemberId: string | null;
  plugin: Plugin;
  pluginDetails: PluginDetails | LegacyPluginDetails;
  tags: Plugin['attributes']['tags'];
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
class DirectoryPluginItemView extends View<Board, any> {
  className() {
    return 'directory-listing-powerup';
  }

  events() {
    return {
      // @ts-expect-error TS(2322): Type '{ click(e: any): any; }' is not assignable t... Remove this comment to see the full error message
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      click(e: any) {
        // @ts-expect-error TS(2339): Property 'options' does not exist on type '() => {... Remove this comment to see the full error message
        if (this.options.atomType === PowerUpItemType.Listing) {
          return;
        }
        // @ts-expect-error TS(2339): Property '$el' does not exist on type '() => { [ke... Remove this comment to see the full error message
        if (!$(e.target, this.$el).is('a, button')) {
          sendPluginUIEvent({
            // @ts-expect-error TS(2339): Property 'plugin' does not exist on type '() => { ... Remove this comment to see the full error message
            idPlugin: this.plugin.id,
            // @ts-expect-error TS(2339): Property 'model' does not exist on type '() => { [... Remove this comment to see the full error message
            idBoard: this.model.id,
            event: {
              action: 'clicked',
              actionSubject: 'card',
              actionSubjectId: 'powerUpCard',
              source: 'boardDirectory',
              attributes: {
                // @ts-expect-error TS(2339): Property 'tags' does not exist on type '() => { [k... Remove this comment to see the full error message
                tags: this.tags,
              },
            }, // Will need to figure out the exact screen here
          });
        }
        // @ts-expect-error TS(2339): Property 'navigateToListing' does not exist on typ... Remove this comment to see the full error message
        return this.navigateToListing(e);
      },
    };
  }

  initialize({
    plugin,
    directoryView,
  }: {
    plugin: Plugin;
    directoryView: DirectoryView;
  }) {
    this.plugin = plugin;
    this.tags = this.plugin.get('tags');
    this.directoryView = directoryView;
    super.initialize(...arguments);
    this.canEnable = this.model.editable();
    this.myMemberId = getMemberId();

    this.initialData = {
      canEnable: this.model.editable(),
      isLegacy: Array.from(_.pluck(DirectoryLegacyPowerUps, 'id')).includes(
        this.plugin.id,
      ),
    };

    this.io = pluginIOCache.get(this.plugin);

    this.pluginDetails = {
      displayName: this.io.getName(),
      description: this.io.getDescription(),
      iconUrl: this.io.getIconUrl(),
      overview: this.io.getOverview(),
      author: this.plugin.get('author'),
      moderatedState: this.plugin.get('moderatedState'),
    };

    if (this.initialData.isLegacy) {
      const legacyPlugin = _.findWhere(DirectoryLegacyPowerUps, {
        id: this.plugin.id,
      });
      this.pluginDetails = {
        ...this.pluginDetails,
        ...legacyPlugin,
      };
    }

    this.listenTo(
      this.model,
      'change:idOrganization change:powerUps',
      this.frameDebounce(this.render),
    );
    this.listenTo(
      this.model.boardPluginList,
      'add remove reset',
      this.frameDebounce(this.render),
    );
    this.listenTo(this.model, 'cancel-add-to-team', () => PopOver.hide());
    this.subscribe(
      this.model.boardPluginList.snoop(),
      this.frameDebounce(this.render),
    );
    this.subscribe(
      pluginsChangedSignal(this.model),
      this.frameDebounce(this.render),
    );
  }

  showListingOption() {
    return false;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  navigateToListing(e: any) {
    if (!$(e.target, this.$el).is('a, button')) {
      if (PopOver.isVisible) {
        PopOver.hide();
      }
      const { shortLink, shortName } =
        getRouteParamsFromPathname<RouteId.BOARD>(getLocation().pathname);
      navigate(
        `${getBoardUrlFromShortLink(shortLink, shortName)}/power-up/${
          this.plugin.id
        }`,
        { trigger: true },
      );
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  enable(e: any) {
    stopPropagationAndPreventDefault(e);

    const elem = e.target;

    // Enable the Power-Up for the board
    if (this.plugin.get('idOrganizationOwner') === firstPartyPluginsOrg) {
      const pluginAttributes = {
        pluginName: this.plugin.get('name'),
        pluginId: this.plugin.id,
        pluginTags: this.tags,
        installSource: 'boardDirectory',
      };
      const traceId = Analytics.startTask({
        taskName: 'enable-plugin',
        source: 'addPowerUpButton',
        attributes: pluginAttributes,
      });
      this.model.enablePluginWithTracing(this.plugin.id, {
        traceId,
        taskName: 'enable-plugin',
        attributes: pluginAttributes,
        source: 'addPowerUpButton',
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        next: (err: any) => {
          if (err) {
            const errorMessage = err.serverMessage;
            Analytics.taskFailed({
              taskName: 'enable-plugin',
              source: 'addPowerUpButton',
              error: new Error(errorMessage),
              attributes: {
                ...pluginAttributes,
              },
              traceId,
            });
            if (this.isGrandfathered()) {
              this.model.pull('powerUps', this.pluginDetails.name);
            } else {
              const disableTraceId = Analytics.startTask({
                taskName: 'disable-plugin',
                source: 'addPowerUpButton',
                attributes: pluginAttributes,
              });
              this.model.disablePluginWithTracing(this.plugin.id, {
                traceId: disableTraceId,
                taskName: 'disable-plugin',
                attributes: pluginAttributes,
                source: 'addPowerUpButton',
              });
            }
            if (errorMessage === 'PLUGIN_NOT_ALLOWED') {
              Alerts.show(
                'plugin not allowed',
                'error',
                'addpluginerror',
                4000,
              );
            } else {
              Alerts.show(
                'could not add plugin',
                'error',
                'addpluginerror',
                2000,
              );
            }
          } else {
            sendPluginTrackEvent({
              idPlugin: this.plugin.id,
              idBoard: this.model.id,
              event: {
                action: 'added',
                actionSubject: 'powerUp',
                source: 'pupConfigurationInlineDialog',
                // @ts-expect-error TS(2322): Type '{ action: "added"; actionSubject: "powerUp";... Remove this comment to see the full error message
                taskId: traceId,
              },
            });
          }
        },
      });

      if (this.io.supports('on-enable')) {
        pluginRunner
          .one({
            plugin: this.plugin,
            command: 'on-enable',
            board: this.model,
            el: elem,
          })
          .catch((err) => {
            console?.warn('Failed to handle on-enable command.', {
              error: err.message,
              id: this.plugin.id,
            });
          });
      }
    } else {
      const { shortLink, shortName } =
        getRouteParamsFromPathname<RouteId.BOARD>(getLocation().pathname);
      navigate(
        `${getBoardUrlFromShortLink(shortLink, shortName)}/power-up/${
          this.plugin.id
        }/enable`,
        { trigger: true },
      );
    }
  }

  isGrandfathered() {
    return (
      this.initialData.isLegacy &&
      'settingsView' in this.pluginDetails &&
      Array.from(this.model.get('powerUps')).includes(this.pluginDetails.name)
    );
  }

  disable() {
    const traceId = Analytics.startTask({
      taskName: 'disable-plugin',
      source: 'pupConfigurationInlineDialog',
      attributes: {
        pluginName: this.plugin.get('name'),
        pluginId: this.plugin.id,
        pluginTags: this.tags,
      },
    });
    const disableWithTracing = () => {
      return this.model.disablePluginWithTracing(this.plugin.id, {
        traceId,
        taskName: 'disable-plugin',
        source: 'pupConfigurationInlineDialog',
        attributes: {
          pluginName: this.plugin.get('name'),
          pluginId: this.plugin.id,
          pluginTags: this.tags,
        },
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        next: (err: any) => {
          if (!err) {
            sendPluginTrackEvent({
              idPlugin: this.plugin.id,
              idBoard: this.model.id,
              event: {
                action: 'disabled',
                actionSubject: 'powerUp',
                source: 'pupConfigurationInlineDialog',
              },
              // @ts-expect-error TS(2345): Argument of type '{ idPlugin: any; idBoard: any; e... Remove this comment to see the full error message
              attributes: {
                taskId: traceId,
              },
            });
          }
        },
      });
    };
    if (this.io.supports('on-disable')) {
      pluginRunner
        .one({
          plugin: this.plugin,
          command: 'on-disable',
          board: this.model,
          timeout: 500,
        })
        .finally(() => {
          return disableWithTracing();
        });
    } else {
      return disableWithTracing();
    }
  }

  getEnabledState() {
    if (this.initialData.isLegacy && 'settingsView' in this.pluginDetails) {
      return this.model.isPowerUpEnabled(this.pluginDetails.name);
    } else {
      return this.model.isPluginEnabled(this.plugin.id);
    }
  }

  hasPersonalSettings() {
    const pluginData = {
      ...Auth.me().getPluginData(this.plugin.id),
      ...this.model.getPluginData(this.plugin.id),
      ...this.model.getOrganization()?.getPluginData(this.plugin.id),
    };
    return _.any(pluginData, ({ private: privateData }) => privateData != null);
  }

  disablePopover = () => {
    PopOver.hide();
    return this.disable();
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  showDisableWarning(elem: any) {
    const powerupName = this.io.getName();
    PopOver.pushView({
      elem,
      autoPosition: true,
      getViewTitle: () => t.l('disable-warning-header', { powerupName }),
      reactElement: (
        <PupDisableWarning
          key={this.plugin.id}
          cancel={() => {
            PopOver.popView();
          }}
          disable={this.disablePopover}
          powerupName={powerupName}
        />
      ),
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  showPowerUpSettings(elem: any) {
    const traceId = Analytics.startTask({
      taskName: 'run-pluginCommand/showSettings',
      // @ts-expect-error TS(2322): Type '"boardSidebarPupInlineDialog" | "boardDirect... Remove this comment to see the full error message
      source: this.showListingOption()
        ? 'boardSidebarPupInlineDialog'
        : 'boardDirectoryPUpInlineDialog',
      attributes: {
        idPlugin: this.plugin.id,
      },
    });
    sendPluginUIEvent({
      idPlugin: this.plugin.id,
      idBoard: this.model.id,
      event: {
        action: 'clicked',
        actionSubject: 'option',
        actionSubjectId: 'editSettingsOption',
        // @ts-expect-error TS(2322): Type '"boardSidebarPupInlineDialog" | "boardDirect... Remove this comment to see the full error message
        source: this.showListingOption()
          ? 'boardSidebarPupInlineDialog'
          : 'boardDirectoryPUpInlineDialog',
      },
    });

    if (this.directoryView) {
      this.directoryView.trackScreenEvent('powerUpEditInlineDialog', {
        powerUpId: this.plugin.id,
      });
    }

    if (this.initialData.isLegacy && 'settingsView' in this.pluginDetails) {
      PopOver.pushView({
        elem,
        view: this.pluginDetails.settingsView(this.model),
        autoPosition: true,
      });
      Analytics.taskSucceeded({
        taskName: 'run-pluginCommand/showSettings',
        traceId,
        // @ts-expect-error TS(2322): Type '"boardSidebarPupInlineDialog" | "boardDirect... Remove this comment to see the full error message
        source: this.showListingOption()
          ? 'boardSidebarPupInlineDialog'
          : 'boardDirectoryPUpInlineDialog',
        attributes: {
          idPlugin: this.plugin.id,
        },
      });
    } else {
      pluginRunner
        .one({
          plugin: this.plugin,
          command: 'show-settings',
          board: this.model,
          el: elem,
        })
        .then(() => {
          Analytics.taskSucceeded({
            taskName: 'run-pluginCommand/showSettings',
            traceId,
            // @ts-expect-error TS(2322): Type '"boardSidebarPupInlineDialog" | "boardDirect... Remove this comment to see the full error message
            source: this.showListingOption()
              ? 'boardSidebarPupInlineDialog'
              : 'boardDirectoryPUpInlineDialog',
            attributes: {
              idPlugin: this.plugin.id,
            },
          });
        })
        .catch((err) => {
          Analytics.taskFailed({
            taskName: 'run-pluginCommand/showSettings',
            traceId,
            // @ts-expect-error TS(2322): Type '"boardSidebarPupInlineDialog" | "boardDirect... Remove this comment to see the full error message
            source: this.showListingOption()
              ? 'boardSidebarPupInlineDialog'
              : 'boardDirectoryPUpInlineDialog',
            attributes: {
              idPlugin: this.plugin.id,
            },
            error: err,
          });
          return typeof console !== 'undefined' && console !== null
            ? console.warn('Failed to handle show-settings.', {
                error: err.message,
                id: this.plugin.id,
              })
            : undefined;
        })
        .done();
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  showAuthorization(elem: any) {
    const traceId = Analytics.startTask({
      taskName: 'run-pluginCommand/showAuthorization',
      // @ts-expect-error TS(2322): Type '"boardSidebarPupInlineDialog" | "boardDirect... Remove this comment to see the full error message
      source: this.showListingOption()
        ? 'boardSidebarPupInlineDialog'
        : 'boardDirectoryPUpInlineDialog',
      attributes: {
        idPlugin: this.plugin.id,
      },
    });
    sendPluginUIEvent({
      idPlugin: this.plugin.id,
      idBoard: this.model.id,
      event: {
        action: 'clicked',
        actionSubject: 'option',
        // @ts-expect-error TS(2322): Type '"authorizeAccountOption"' is not assignable ... Remove this comment to see the full error message
        actionSubjectId: 'authorizeAccountOption',
        // @ts-expect-error TS(2322): Type '"boardSidebarPupInlineDialog" | "boardDirect... Remove this comment to see the full error message
        source: this.showListingOption()
          ? 'boardSidebarPupInlineDialog'
          : 'boardDirectoryPUpInlineDialog',
      },
    });
    pluginRunner
      .one({
        plugin: this.plugin,
        command: 'show-authorization',
        board: this.model,
        el: elem,
      })
      .then(() => {
        Analytics.taskSucceeded({
          taskName: 'run-pluginCommand/showAuthorization',
          traceId,
          // @ts-expect-error TS(2322): Type '"boardSidebarPupInlineDialog" | "boardDirect... Remove this comment to see the full error message
          source: this.showListingOption()
            ? 'boardSidebarPupInlineDialog'
            : 'boardDirectoryPUpInlineDialog',
          attributes: {
            idPlugin: this.plugin.id,
          },
        });
        return sendPluginScreenEvent({
          idPlugin: this.plugin.id,
          idBoard: this.model.id,
          // @ts-expect-error TS(2322): Type '"pupAuthorizationInlineDialog"' is not assig... Remove this comment to see the full error message
          screenName: 'pupAuthorizationInlineDialog',
        });
      })
      .catch((err) => {
        Analytics.taskFailed({
          taskName: 'run-pluginCommand/showAuthorization',
          traceId,
          // @ts-expect-error TS(2322): Type '"boardSidebarPupInlineDialog" | "boardDirect... Remove this comment to see the full error message
          source: this.showListingOption()
            ? 'boardSidebarPupInlineDialog'
            : 'boardDirectoryPUpInlineDialog',
          attributes: {
            idPlugin: this.plugin.id,
          },
          error: err,
        });
        return typeof console !== 'undefined' && console !== null
          ? console.warn('Failed to handle show-authorization.', {
              error: err.message,
              id: this.plugin.id,
            })
          : undefined;
      })
      .done();
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  clearData(elem: any) {
    sendPluginUIEvent({
      idPlugin: this.plugin.id,
      idBoard: this.model.id,
      event: {
        action: 'clicked',
        actionSubject: 'option',
        // @ts-expect-error TS(2322): Type '"removeDataOption"' is not assignable to typ... Remove this comment to see the full error message
        actionSubjectId: 'removeDataOption',
        // @ts-expect-error TS(2322): Type '"boardSidebarPupInlineDialog" | "boardDirect... Remove this comment to see the full error message
        source: this.showListingOption()
          ? 'boardSidebarPupInlineDialog'
          : 'boardDirectoryPUpInlineDialog',
      },
    });
    Confirm.pushView('clear plugin data', {
      elem,
      model: this.model,
      confirmBtnClass: 'nch-button nch-button--danger',
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      fxConfirm: (e: any) => {
        sendPluginTrackEvent({
          idPlugin: this.plugin.id,
          idBoard: this.model.id,
          event: {
            action: 'removed',
            actionSubject: 'pluginData',
            // @ts-expect-error TS(2322): Type '"boardSidebarPupInlineDialog" | "boardDirect... Remove this comment to see the full error message
            source: this.showListingOption()
              ? 'boardSidebarPupInlineDialog'
              : 'boardDirectoryPUpInlineDialog',
          },
        });
        const traceId = Analytics.startTask({
          taskName: 'run-pluginCommand/removeData',
          // @ts-expect-error TS(2322): Type '"boardSidebarPupInlineDialog" | "boardDirect... Remove this comment to see the full error message
          source: this.showListingOption()
            ? 'boardSidebarPupInlineDialog'
            : 'boardDirectoryPUpInlineDialog',
          attributes: {
            idPlugin: this.plugin.id,
          },
        });
        if (this.io.supports('remove-data')) {
          return pluginRunner
            .one({
              board: this.model,
              plugin: this.plugin,
              command: 'remove-data',
              timeout: 1000,
            })
            .finally(() => {
              Analytics.taskSucceeded({
                taskName: 'run-pluginCommand/removeData',
                traceId,
                // @ts-expect-error TS(2322): Type '"boardSidebarPupInlineDialog" | "boardDirect... Remove this comment to see the full error message
                source: this.showListingOption()
                  ? 'boardSidebarPupInlineDialog'
                  : 'boardDirectoryPUpInlineDialog',
                attributes: {
                  idPlugin: this.plugin.id,
                },
              });
              this.model.clearPluginData(this.plugin.id);
              this.model.getOrganization()?.clearPluginData(this.plugin.id);
              Auth.me().clearPluginData(this.plugin.id);
            });
        } else {
          Analytics.taskSucceeded({
            taskName: 'run-pluginCommand/removeData',
            traceId,
            // @ts-expect-error TS(2322): Type '"boardSidebarPupInlineDialog" | "boardDirect... Remove this comment to see the full error message
            source: this.showListingOption()
              ? 'boardSidebarPupInlineDialog'
              : 'boardDirectoryPUpInlineDialog',
            attributes: {
              idPlugin: this.plugin.id,
            },
          });
          this.model.clearPluginData(this.plugin.id);
          this.model.getOrganization()?.clearPluginData(this.plugin.id);
          Auth.me().clearPluginData(this.plugin.id);
        }
      },
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  showPowerUpDisableSettings(elem: any) {
    const pluginId = this.plugin.id;
    const boardId = this.model.id;

    PopOver.pushView({
      elem,
      autoPosition: true,
      getViewTitle: () => t.l('disable-plugin-header'),
      reactElement: (
        <ApolloProvider client={client}>
          <DisablePluginSettings
            disablePlugin={this.disablePopover}
            boardId={boardId}
            pluginId={pluginId}
            elem={elem}
            name={this.plugin.get('name')}
          />
        </ApolloProvider>
      ),
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  toggleOptions(e: any) {
    stopPropagationAndPreventDefault(e);

    const memberId = getMemberId();
    const elem = this.options.target ? this.options.target : e.target;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const bindElemAction = (action: any) => _.bind(action, this, elem);

    const meMembership = this.model?.attributes?.memberships.find(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (membership: any) => membership.idMember === memberId,
    );

    //check permissions for showing 'Delete pluginData' pop-up
    const isAdmin = () => {
      // @ts-expect-error
      const isBoardAdmin = meMembership?.memberType === 'admin';
      const org = this.model?.getOrganization();
      // @ts-expect-error
      const isMeWorkspaceAdmin = org.owned();
      const isEnterpriseBoard = this.model?.isEnterpriseBoard();
      let boardEnterprise;
      let isEnterpriseAdmin = false;
      if (isEnterpriseBoard) {
        boardEnterprise = this.model?.getEnterprise();
        // @ts-expect-error
        isEnterpriseAdmin = Auth.me().isEnterpriseAdminOf(boardEnterprise);
      }
      return isBoardAdmin || isMeWorkspaceAdmin || isEnterpriseAdmin;
    };

    if (this.directoryView) {
      this.directoryView.trackScreenEvent('pupConfigurationInlineDialog', {
        powerUpId: this.plugin.id,
      });
    }

    return Promise.props({
      supportsAuth: this.io.supports('show-authorization'),
      supportsSettings:
        'settingsView' in this.pluginDetails
          ? this.pluginDetails.settingsView
          : this.io.supports('show-settings'),
      hasPersonalSettings: this.hasPersonalSettings(),
      isBoardReadOnly: this.model.isReadOnly(),
    })
      .then((data) => {
        if (data.supportsAuth) {
          return pluginRunner
            .one({
              timeout: 1000,
              plugin: this.plugin,
              command: 'authorization-status',
              board: this.model,
            })
            .then((authStatus) => ({
              ...data,
              authStatus,
            }))
            .catch((err) => {
              if (typeof console !== 'undefined' && console !== null) {
                console.warn('Failed to get authorization-status.', {
                  error: err.message,
                  id: this.plugin.id,
                });
              }
              return data;
            });
        }
        return data;
      })
      .then((data) => {
        const disablePowerUp =
          this.plugin.id === mapPowerUpId
            ? bindElemAction(this.showDisableWarning)
            : this.disablePopover;
        PopOver.toggle({
          autoPosition: true,
          getViewTitle: () => this.io.getName(),
          elem,
          reactElement: (
            <PowerUpSettings
              key={this.plugin.id}
              disable={
                isAdmin()
                  ? bindElemAction(this.showPowerUpDisableSettings)
                  : disablePowerUp
              }
              authorize={bindElemAction(this.showAuthorization)}
              showSettings={bindElemAction(this.showPowerUpSettings)}
              removeData={bindElemAction(this.clearData)}
              supportsSettings={data.supportsSettings}
              supportsAuth={data.supportsAuth}
              hasPersonalSettings={data.hasPersonalSettings}
              isBoardReadOnly={data.isBoardReadOnly}
              // @ts-expect-error TS(2339): Property 'authStatus' does not exist on type '{ su... Remove this comment to see the full error message
              authStatus={data.authStatus}
              showListingOption={this.showListingOption()}
              navigateToListing={bindElemAction(this.navigateToListing)}
            />
          ),
        });

        return sendPluginScreenEvent({
          idPlugin: this.plugin.id,
          idBoard: this.model.id,
          screenName: 'pupConfigurationInlineDialog',
        });
      });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getIntegrationButton(powerUpItemType: any) {
    const eventSource =
      powerUpItemType === PowerUpItemType.Listing
        ? 'boardDirectoryPowerUpDetailsScreen'
        : 'powerUpCard';

    if (this.plugin.id === e2bId) {
      return (
        <ComponentWrapper>
          <LazyEmailToBoardButton
            boardId={this.model.id}
            pluginId={this.plugin.id}
            eventSource={eventSource}
            isPowerUpDirectory={true}
          />
        </ComponentWrapper>
      );
    }

    let configUrl;
    if (this.plugin.id === microsoftTeamsId) {
      configUrl = microsoftTeamsUrl;
    } else if (this.plugin.id === gmailId) {
      configUrl = gmailUrl;
    } else {
      return '';
    }

    return (
      <ConfigureButton
        onClick={(e) => {
          sendPluginUIEvent({
            idPlugin: this.plugin.id,
            idBoard: this.model.id,
            event: {
              action: 'clicked',
              actionSubject: 'button',
              actionSubjectId: 'powerUpEditButton',
              source: eventSource,
            },
          });
        }}
        href={configUrl}
      />
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getButton(powerUpItemType: any) {
    // Specifically checking for BC2 orgs to not show upsell banner
    const isPaidOrg = this.model.getOrganization()?.hasPaidProduct();

    if (this.tags?.includes('integration')) {
      return this.getIntegrationButton(powerUpItemType);
    }
    // for desktop, we can't upsell members, so we hide the
    // "Enable" button if the member can't enable more power-ups
    // Also adapted for use with BC2 where we don't want to tell
    // them to upgrade to BC as technically they already are on BC
    if (
      (dontUpsell() || isPaidOrg) &&
      !this.model.canEnableAdditionalPowerUps() &&
      !this.isEnabled &&
      !(this.tags || []).includes('promotional')
    ) {
      return;
    }

    // If the board is an enterprise board and the enterprise doesn't allow
    // the plugin to be enabled, show the disabled button.
    if (
      this.model.isEnterpriseBoard() &&
      this.model.getEnterprise() != null &&
      // @ts-expect-error
      !this.model.getEnterprise().isPluginAllowed(this.plugin.id)
    ) {
      return (
        <DisabledButton
          // @ts-expect-error
          enterpriseDisplayName={this.model.getEnterprise()?.get('displayName')}
        />
      );
    }

    let eventSource = 'powerUpCard';
    if (powerUpItemType === PowerUpItemType.Listing) {
      eventSource = 'boardDirectoryPowerUpDetailsScreen';
    }

    if (this.isEnabled) {
      return (
        <SettingsButton
          onClick={(e) => {
            sendPluginUIEvent({
              idPlugin: this.plugin.id,
              idBoard: this.model.id,
              event: {
                action: 'clicked',
                actionSubject: 'button',
                actionSubjectId: 'powerUpEditButton',
                // @ts-expect-error TS(2322): Type 'string' is not assignable to type 'SourceTyp... Remove this comment to see the full error message
                source: eventSource,
              },
            });
            return this.toggleOptions(e);
          }}
        />
      );
    }

    return (
      <EnableButton
        onClick={(e) => {
          sendPluginUIEvent({
            idPlugin: this.plugin.id,
            idBoard: this.model.id,
            event: {
              action: 'clicked',
              actionSubject: 'button',
              actionSubjectId: 'powerUpAddButton',
              // @ts-expect-error TS(2322): Type 'string' is not assignable to type 'SourceTyp... Remove this comment to see the full error message
              source: eventSource,
              attributes: {
                tags: this.tags,
              },
            },
          });
          return this.enable(e);
        }}
      />
    );
  }

  render() {
    const { author, iconUrl, displayName, description, overview } =
      this.pluginDetails;
    // @ts-expect-error TS(2554): Expected 0 arguments, but got 1.
    this.isEnabled = this.getEnabledState({ id: this.plugin.id });
    const atomType = this.options.atomType || PowerUpItemType.Basic;

    renderReactRoot(
      <div>
        <ReactRootComponent>
          <PowerUp
            type={atomType}
            atomProps={{
              subtitle:
                atomType === PowerUpItemType.Listing ? author : undefined,
              overview: markdownAsText(overview || description),
              icon: {
                url: iconUrl || defaultPowerUpIcon,
              },
              // @ts-expect-error
              deprecation: this.plugin.get('deprecation'),
              // @ts-expect-error
              heroImageUrl: this.plugin.get('heroImageUrl'),
              name: displayName,
              // @ts-expect-error TS(2322): Type 'string | DetailedReactHTMLElement<InputHTMLA... Remove this comment to see the full error message
              button: this.getButton(atomType),
              // @ts-expect-error
              usage: this.plugin.get('usageBrackets')?.boards || 0,
              darkMode: (this.tags || []).includes('dark-mode'),
              staffPick: (this.tags || []).includes('staff-pick'),
              integration: (this.tags || []).includes('integration'),
            }}
          />
        </ReactRootComponent>
      </div>,
      this.el,
      true,
    );

    this.$el.data('idPlugin', this.plugin.id);
    return this;
  }
}

export { DirectoryPluginItemView };
