// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.qESCpvzyt0IUcY > span {
  margin: 0px 2px 0px -2px;
  vertical-align: middle;
}
.qESCpvzyt0IUcY svg[role='presentation'] {
  color: var(--ds-icon-warning, #d97008);
}
.hdPqvCIrxRFgMI {
  color: var(--ds-text-subtle, #44546f);
  font-size: 14px;
  line-height: 18px;
}
.hdPqvCIrxRFgMI > span {
  margin: 0px 2px 0px -2px;
  vertical-align: middle;
}
.hdPqvCIrxRFgMI svg[role='presentation'] {
  color: var(--ds-icon-warning, #d97008);
}
.PLGXBEIqOxIinM {
  color: var(--ds-text, #172b4d);
  font-size: 11px;
  line-height: 16px;
}
.PLGXBEIqOxIinM > span {
  margin: 0px 2px 0px -2px;
  vertical-align: middle;
}
.PLGXBEIqOxIinM svg[role='presentation'] {
  color: var(--ds-icon-warning, #d97008);
}
.o48jCle8j_WeeT {
  height: 54px;
  color: var(--ds-text-subtle, #44546f);
  font-size: 14px;
  line-height: 18px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.o48jCle8j_WeeT > span {
  margin: 0px 2px 0px -2px;
  vertical-align: middle;
}
.o48jCle8j_WeeT svg[role='presentation'] {
  color: var(--ds-icon-warning, #d97008);
}
.g9lenG9TvkRAZV {
  height: 100%;
  max-height: 32px;
  color: var(--ds-text, #172b4d);
  font-size: 11px;
  line-height: 16px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
.g9lenG9TvkRAZV > span {
  margin: 0px 2px 0px -2px;
  vertical-align: middle;
}
.g9lenG9TvkRAZV svg[role='presentation'] {
  color: var(--ds-icon-warning, #d97008);
}
`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"icon": `qESCpvzyt0IUcY`,
	"overview": `hdPqvCIrxRFgMI`,
	"overviewSmall": `PLGXBEIqOxIinM`,
	"clampedOverview": `o48jCle8j_WeeT`,
	"clampedOverviewSmall": `g9lenG9TvkRAZV`
};
export default ___CSS_LOADER_EXPORT___;
