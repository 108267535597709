import { useEffect, useMemo, useState } from 'react';

import { useCrossFlow } from '@atlassiansox/cross-flow-support';
import type { TouchpointSourceType } from '@trello/ad-sequencer';
import type { ExperimentVariations } from '@trello/feature-gate-client';
import {
  useWorkspaceNavigation,
  useWorkspaceNavigationHidden,
} from '@trello/workspace-navigation';

import {
  type TrelloCrossFlowExperiment,
  trelloCrossFlowOpen,
} from '../TrelloCrossFlow';
import { useHasEligibleWorkspaces } from './runtimeEligibilityChecks/useHasEligibleWorkspaces';
import { useHasNoAvailableSites } from './runtimeEligibilityChecks/useHasNoAvailableSites';
import { useIsCrossFlowMemberConfirmed } from './runtimeEligibilityChecks/useIsCrossFlowMemberConfirmed';
import { useIsNewUser } from './runtimeEligibilityChecks/useIsNewUser';
import { getSporkCffeExperimentalOption } from './getSporkCffeExperimentalOption';
import { useTrelloCrossFlowExperiment } from './useTrelloCrossFlowExperiment';

const experimentFeatureKey = 'trello_xf_spork_branding_positioning_new_users';

export type CrossFlowForSporkNewUsersExperimentVariations =
  ExperimentVariations<typeof experimentFeatureKey, 'cohort'>;

export interface CrossFlowForNewUsersSporkParams {
  source: TouchpointSourceType;
  workspaceId?: string;
  boardId?: string;
  hasValidAaSession: boolean;
}

export const useCrossFlowForNewUsersSpork = ({
  source,
  workspaceId,
  boardId,
  hasValidAaSession,
}: CrossFlowForNewUsersSporkParams): TrelloCrossFlowExperiment<CrossFlowForSporkNewUsersExperimentVariations> => {
  const crossFlow = useCrossFlow();
  const { isEligible: isNewUserEligible } = useIsNewUser();
  const { isEligible: hasEligibleWorkspaces, eligibleWorkspaceOptions } =
    useHasEligibleWorkspaces({
      workspaceId,
    });

  const [{ expanded: _workspaceNavigationExpanded }] = useWorkspaceNavigation();
  const [{ hidden: _workspaceNavigationHidden }] =
    useWorkspaceNavigationHidden();
  const workspaceNavigationExpanded =
    !_workspaceNavigationHidden && _workspaceNavigationExpanded;

  const [
    isImmediateRuntimeEligibilityChecksPassed,
    setImmediateRuntimeEligibilityChecksPassed,
  ] = useState(false);
  // 4. Check that the member has no available sites
  const { isEligible: hasNoAvailableSites } = useHasNoAvailableSites({
    doLoadAvailableSites: isImmediateRuntimeEligibilityChecksPassed,
    source,
  });

  const { isEligible: isCrossFlowMemberConfirmed } =
    useIsCrossFlowMemberConfirmed();

  const experiment = useTrelloCrossFlowExperiment({
    experimentFeatureKey,
    source,
    eligibilityCheckResults:
      source === 'currentWorkspaceNavigationDrawer'
        ? [
            crossFlow.isEnabled,
            isNewUserEligible,
            hasEligibleWorkspaces,
            workspaceNavigationExpanded,
          ]
        : [crossFlow.isEnabled],
    deferredEligibilityCheckResults:
      source === 'currentWorkspaceNavigationDrawer' &&
      isCrossFlowMemberConfirmed
        ? [hasNoAvailableSites]
        : [],
  });
  useEffect(() => {
    setImmediateRuntimeEligibilityChecksPassed(
      experiment.isImmediateRuntimeEligibilityChecksPassed,
    );
  }, [experiment.isImmediateRuntimeEligibilityChecksPassed]);

  return useMemo(
    () => ({
      ...experiment,
      open: trelloCrossFlowOpen(
        crossFlow,
        source,
        eligibleWorkspaceOptions,
        hasValidAaSession,
        {
          workspaceId,
          boardId,
          isNewTrelloUser: true,
          shouldSkipMarketingPage:
            source === 'currentWorkspaceNavigationDrawer',
          ...getSporkCffeExperimentalOption(experiment.cohort),
        },
      ),
    }),
    [
      boardId,
      hasValidAaSession,
      crossFlow,
      eligibleWorkspaceOptions,
      experiment,
      source,
      workspaceId,
    ],
  );
};
