import { useCallback, useState } from 'react';

import FeatureGates from '@atlaskit/feature-gate-js-client';
import type { SourceType } from '@trello/atlassian-analytics';
import { Analytics } from '@trello/atlassian-analytics';

import type { UpdateWorkspaceReverseTrialTagMutationVariables } from './UpdateWorkspaceReverseTrialTagMutation.generated';
import { useUpdateWorkspaceReverseTrialTagMutation } from './UpdateWorkspaceReverseTrialTagMutation.generated';

type Cohort =
  UpdateWorkspaceReverseTrialTagMutationVariables['reverseTrialTag'];

interface Experiment {
  experimentName: string;
  tags: {
    control: UpdateWorkspaceReverseTrialTagMutationVariables['reverseTrialTag'];
    experiment: UpdateWorkspaceReverseTrialTagMutationVariables['reverseTrialTag'];
  };
}

interface Experiments {
  aaTest: Experiment;
  q4Experiment: Experiment;
}

export const REVERSE_TRIALS_EXPERIMENTS: Experiments = {
  aaTest: {
    experimentName: 'trello_enterprise_reverse_trials_aa',
    tags: {
      control: 'aa_test_control',
      experiment: 'aa_test_experiment',
    },
  },
  q4Experiment: {
    experimentName: 'trello_enterprise_reverse_trials',
    tags: {
      control: 'q4_actual_control',
      experiment: 'q4_actual_experiment',
    },
  },
} as const;

export const useAssignCohortForReverseTrial = () => {
  const [updateWorkspaceReverseTrialTag] =
    useUpdateWorkspaceReverseTrialTagMutation();

  const [allocatedForExperiment, setAllocatedForExperiment] =
    useState<boolean>(false);
  const [allocatedCohort, setAllocatedCohort] = useState<Cohort | null>(null);

  const assignCohortForReverseTrial = useCallback(
    async (workspaceId: string) => {
      const assignCohortAndStore = async (
        experimentName: keyof typeof REVERSE_TRIALS_EXPERIMENTS,
      ): Promise<Cohort | null> => {
        const experimentValue = FeatureGates.getExperimentValue<
          'not-enrolled' | 'experiment' | 'control'
        >(
          REVERSE_TRIALS_EXPERIMENTS[experimentName].experimentName,
          'cohort',
          'not-enrolled',
        );

        if (!experimentValue || experimentValue === 'not-enrolled') {
          return null;
        }

        const experimentTags = REVERSE_TRIALS_EXPERIMENTS[experimentName].tags;

        const reverseTrialTag = experimentTags[experimentValue];

        Analytics.sendTrackEvent({
          action: 'exposed',
          actionSubject: 'feature',
          source: 'trello' as SourceType,
          attributes: {
            experimentName: 'trello_enterprise_reverse_trials',
            value:
              reverseTrialTag === 'q4_actual_control'
                ? 'control'
                : 'experiment',
          },
        });

        const traceId = Analytics.startTask({
          taskName: 'edit-organization/reverseTrialTag',
          source: 'createFirstBoardInviteScreen',
        });

        const updateReverseTrialTagVariables = {
          workspaceId,
          reverseTrialTag,
          traceId,
        };

        try {
          await updateWorkspaceReverseTrialTag({
            variables: updateReverseTrialTagVariables,
          });

          Analytics.taskSucceeded({
            taskName: 'edit-organization/reverseTrialTag',
            source: 'createFirstBoardInviteScreen',
            traceId,
          });

          return reverseTrialTag;
        } catch (error) {
          // silently swallowing the error, so that onboarding can continue
          Analytics.taskFailed({
            taskName: 'edit-organization/reverseTrialTag',
            source: 'createFirstBoardInviteScreen',
            error,
            traceId,
          });
        }

        return reverseTrialTag;
      };

      if (allocatedForExperiment) {
        return allocatedCohort;
      }

      const bucketForAaTest = await assignCohortAndStore('aaTest');
      if (bucketForAaTest) {
        setAllocatedCohort(bucketForAaTest);
        setAllocatedForExperiment(true);
        return bucketForAaTest;
      }

      const q4ExperimentCohort = await assignCohortAndStore('q4Experiment');
      if (q4ExperimentCohort) {
        setAllocatedCohort(q4ExperimentCohort);
        setAllocatedForExperiment(true);
        return q4ExperimentCohort;
      }

      return null;
    },
    [allocatedForExperiment, updateWorkspaceReverseTrialTag, allocatedCohort],
  );

  return assignCohortForReverseTrial;
};
