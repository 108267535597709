// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/**
 * Defines label color variables on a class for each label color name.
 * Consumers of labels outside of their components can import this LESS file,
 * which will load up CSS variables on any element with the corresponding class
 * name (e.g. \`.green\`).
 */
.wy5isJiOZ7myCT {
  --label-background-color: var(--ds-background-accent-green-subtler, #baf3db);
  --label-background-color-hovered: var(--ds-background-accent-green-subtler-hovered, #7ee2b8);
  --label-text-color: var(--ds-text-accent-green-bolder, #164b35);
}
.gnQayeEH3KsaUG {
  --label-background-color: var(--ds-background-accent-green-subtle, #4bce97);
  --label-background-color-hovered: var(--ds-background-accent-green-subtle-hovered, #7ee2b8);
  --label-text-color: var(--ds-text-accent-green-bolder, #164b35);
}
.lRtSXDTdQAxBUG {
  --label-background-color: var(--ds-background-accent-green-bolder, #1f845a);
  --label-background-color-hovered: var(--ds-background-accent-green-bolder-hovered, #216e4e);
  --label-text-color: var(--ds-text-inverse, #ffffff);
}
.W59yCS6KL5KgeW {
  --label-background-color: var(--ds-background-accent-yellow-subtler, #f8e6a0);
  --label-background-color-hovered: var(--ds-background-accent-yellow-subtler-hovered, #f5cd47);
  --label-text-color: var(--ds-text-accent-yellow-bolder, #533f04);
}
.O9SabvuCox4xss {
  --label-background-color: var(--ds-background-accent-yellow-subtle, #e2b203);
  --label-background-color-hovered: var(--ds-background-accent-yellow-subtle-hovered, #f5cd47);
  --label-text-color: var(--ds-text-accent-yellow-bolder, #533f04);
}
.n0NPILmuMAWcTe {
  --label-background-color: var(--ds-background-accent-yellow-bolder, #946f00);
  --label-background-color-hovered: var(--ds-background-accent-yellow-bolder-hovered, #7f5f01);
  --label-text-color: var(--ds-text-inverse, #ffffff);
}
.IgDAULaHga9f9x {
  --label-background-color: var(--ds-background-accent-orange-subtler, #ffe2bd);
  --label-background-color-hovered: var(--ds-background-accent-orange-subtler-hovered, #fec57b);
  --label-text-color: var(--ds-text-accent-orange-bolder, #5f3811);
}
.E70ubEHDHPeM7j {
  --label-background-color: var(--ds-background-accent-orange-subtle, #faa53d);
  --label-background-color-hovered: var(--ds-background-accent-orange-subtle-hovered, #fec57b);
  --label-text-color: var(--ds-text-accent-orange-bolder, #5f3811);
}
.hq5RJyORUBshn1 {
  --label-background-color: var(--ds-background-accent-orange-bolder, #b65c02);
  --label-background-color-hovered: var(--ds-background-accent-orange-bolder-hovered, #974f0c);
  --label-text-color: var(--ds-text-inverse, #ffffff);
}
.j8xQUjrMHuiwD0 {
  --label-background-color: var(--ds-background-accent-red-subtler, #ffd2cc);
  --label-background-color-hovered: var(--ds-background-accent-red-subtler-hovered, #ff9c8f);
  --label-text-color: var(--ds-text-accent-red-bolder, #601e16);
}
.bVRqBkZwtJX5_o {
  --label-background-color: var(--ds-background-accent-red-subtle, #f87462);
  --label-background-color-hovered: var(--ds-background-accent-red-subtle-hovered, #ff9c8f);
  --label-text-color: var(--ds-text-accent-red-bolder, #601e16);
}
.p8VyfcG99tZe53 {
  --label-background-color: var(--ds-background-accent-red-bolder, #ca3521);
  --label-background-color-hovered: var(--ds-background-accent-red-bolder-hovered, #ae2a19);
  --label-text-color: var(--ds-text-inverse, #ffffff);
}
.NA3hMdUkFNeD7y {
  --label-background-color: var(--ds-background-accent-purple-subtler, #dfd8fd);
  --label-background-color-hovered: var(--ds-background-accent-purple-subtler-hovered, #b8acf6);
  --label-text-color: var(--ds-text-accent-purple-bolder, #352c63);
}
.vLT3Ao1aMnfBo4 {
  --label-background-color: var(--ds-background-accent-purple-subtle, #9f8fef);
  --label-background-color-hovered: var(--ds-background-accent-purple-subtle-hovered, #b8acf6);
  --label-text-color: var(--ds-text-accent-purple-bolder, #352c63);
}
.CNCx3lNJVorckS {
  --label-background-color: var(--ds-background-accent-purple-bolder, #6e5dc6);
  --label-background-color-hovered: var(--ds-background-accent-purple-bolder-hovered, #5e4db2);
  --label-text-color: var(--ds-text-inverse, #ffffff);
}
.Tv8VZsbnCaP1WC {
  --label-background-color: var(--ds-background-accent-blue-subtler, #cce0ff);
  --label-background-color-hovered: var(--ds-background-accent-blue-subtler-hovered, #85b8ff);
  --label-text-color: var(--ds-text-accent-blue-bolder, #09326c);
}
.mGYCgTCidbTB1v {
  --label-background-color: var(--ds-background-accent-blue-subtle, #579dff);
  --label-background-color-hovered: var(--ds-background-accent-blue-subtle-hovered, #85b8ff);
  --label-text-color: var(--ds-text-accent-blue-bolder, #09326c);
}
.ILxPqV9CiEHOn8 {
  --label-background-color: var(--ds-background-accent-blue-bolder, #0c66e4);
  --label-background-color-hovered: var(--ds-background-accent-blue-bolder-hovered, #0055cc);
  --label-text-color: var(--ds-text-inverse, #ffffff);
}
.OCOQ5c2yPoHPIx {
  --label-background-color: var(--ds-background-accent-teal-subtler, #c1f0f5);
  --label-background-color-hovered: var(--ds-background-accent-teal-subtler-hovered, #8bdbe5);
  --label-text-color: var(--ds-text-accent-teal-bolder, #1d474c);
}
.fGU1xlLnY7cNHN {
  --label-background-color: var(--ds-background-accent-teal-subtle, #60c6d2);
  --label-background-color-hovered: var(--ds-background-accent-teal-subtle-hovered, #8bdbe5);
  --label-text-color: var(--ds-text-accent-teal-bolder, #1d474c);
}
.zYK21Zh1mmNsHR {
  --label-background-color: var(--ds-background-accent-teal-bolder, #1d7f8c);
  --label-background-color-hovered: var(--ds-background-accent-teal-bolder-hovered, #206b74);
  --label-text-color: var(--ds-text-inverse, #ffffff);
}
.L3xVtIIV1UOy4S {
  --label-background-color: var(--ds-background-accent-lime-subtler, #D3F1A7);
  --label-background-color-hovered: var(--ds-background-accent-lime-subtler-hovered, #b3df72);
  --label-text-color: var(--ds-text-accent-lime-bolder, #37471f);
}
.plDDWxSsrvZIvT {
  --label-background-color: var(--ds-background-accent-lime-subtle, #94c748);
  --label-background-color-hovered: var(--ds-background-accent-lime-subtle-hovered, #b3df72);
  --label-text-color: var(--ds-text-accent-lime-bolder, #37471f);
}
.z2pg9rLkbZDl3y {
  --label-background-color: var(--ds-background-accent-lime-bolder, #5b7f24);
  --label-background-color-hovered: var(--ds-background-accent-lime-bolder-hovered, #4c6b1f);
  --label-text-color: var(--ds-text-inverse, #ffffff);
}
.CooO7zGKO8r4ZV {
  --label-background-color: var(--ds-background-accent-magenta-subtler, #fdd0ec);
  --label-background-color-hovered: var(--ds-background-accent-magenta-subtler-hovered, #f797d2);
  --label-text-color: var(--ds-text-accent-magenta-bolder, #50253f);
}
.pVibEWMo3Lx6NQ {
  --label-background-color: var(--ds-background-accent-magenta-subtle, #e774bb);
  --label-background-color-hovered: var(--ds-background-accent-magenta-subtle-hovered, #f797d2);
  --label-text-color: var(--ds-text-accent-magenta-bolder, #50253f);
}
.ehnEvkDcuyGvxn {
  --label-background-color: var(--ds-background-accent-magenta-bolder, #ae4787);
  --label-background-color-hovered: var(--ds-background-accent-magenta-bolder-hovered, #943d73);
  --label-text-color: var(--ds-text-inverse, #ffffff);
}
.dyMrzFhRjYU0CN {
  --label-background-color: var(--ds-background-accent-gray-subtler, #dcdfe4);
  --label-background-color-hovered: var(--ds-background-accent-gray-subtler-hovered, #b3b9c4);
  --label-text-color: var(--ds-text-accent-gray-bolder, #091e42);
}
.ooU9LC7deFQZp0 {
  --label-background-color: var(--ds-background-accent-gray-subtle, #8590a2);
  --label-background-color-hovered: var(--ds-background-accent-gray-subtle-hovered, #b3b9c4);
  --label-text-color: var(--ds-text-accent-gray-bolder, #091e42);
}
.j4woPxKZpIwqJK {
  --label-background-color: var(--ds-background-accent-gray-bolder, #626f86);
  --label-background-color-hovered: var(--ds-background-accent-gray-bolder-hovered, #44546f);
  --label-text-color: var(--ds-text-inverse, #ffffff);
}
.R2ALs3T3MmBZVl {
  display: inline-block;
  position: relative;
  box-sizing: border-box;
  min-width: 48px;
  max-width: 100%;
  height: 32px;
  margin-bottom: 0;
  padding: 0 12px;
  overflow: hidden;
  border-radius: 3px;
  background-color: var(--label-background-color, var(--ds-skeleton, #091e420f));
  color: var(--label-text-color, var(--ds-text, #172b4d));
  font-size: 14px;
  font-weight: 500;
  line-height: 32px;
  text-align: left;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.body-color-blind-mode-enabled .R2ALs3T3MmBZVl:not(.n1vwcAJ8v20mvB),
.body-color-blind-mode-enabled ~ div .R2ALs3T3MmBZVl:not(.n1vwcAJ8v20mvB) {
  padding-left: 36px;
}
.body-color-blind-mode-enabled .R2ALs3T3MmBZVl:not(.n1vwcAJ8v20mvB)::before,
.body-color-blind-mode-enabled ~ div .R2ALs3T3MmBZVl:not(.n1vwcAJ8v20mvB)::before {
  content: '';
  position: absolute;
  top: 4px;
  bottom: 4px;
  left: 4px;
  width: 24px;
  height: 24px;
  border-radius: 3px;
}
.AEIufSWRE7BGCh {
  width: 100%;
}
.Fu_wPhEdnm4VG2:hover {
  background-color: var(--label-background-color-hovered, var(--ds-skeleton, #091e420f));
  cursor: pointer;
}
.Fu_wPhEdnm4VG2:focus {
  outline: var(--ds-border-focused, #388bff) auto 2px;
}
.mac .Fu_wPhEdnm4VG2:focus {
  outline-color: -webkit-focus-ring-color;
}
.Fu_wPhEdnm4VG2:focus .mac.firefox {
  outline-color: AccentColor;
}
`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"green_light": `wy5isJiOZ7myCT`,
	"green": `gnQayeEH3KsaUG`,
	"green_dark": `lRtSXDTdQAxBUG`,
	"yellow_light": `W59yCS6KL5KgeW`,
	"yellow": `O9SabvuCox4xss`,
	"yellow_dark": `n0NPILmuMAWcTe`,
	"orange_light": `IgDAULaHga9f9x`,
	"orange": `E70ubEHDHPeM7j`,
	"orange_dark": `hq5RJyORUBshn1`,
	"red_light": `j8xQUjrMHuiwD0`,
	"red": `bVRqBkZwtJX5_o`,
	"red_dark": `p8VyfcG99tZe53`,
	"purple_light": `NA3hMdUkFNeD7y`,
	"purple": `vLT3Ao1aMnfBo4`,
	"purple_dark": `CNCx3lNJVorckS`,
	"blue_light": `Tv8VZsbnCaP1WC`,
	"blue": `mGYCgTCidbTB1v`,
	"blue_dark": `ILxPqV9CiEHOn8`,
	"sky_light": `OCOQ5c2yPoHPIx`,
	"sky": `fGU1xlLnY7cNHN`,
	"sky_dark": `zYK21Zh1mmNsHR`,
	"lime_light": `L3xVtIIV1UOy4S`,
	"lime": `plDDWxSsrvZIvT`,
	"lime_dark": `z2pg9rLkbZDl3y`,
	"pink_light": `CooO7zGKO8r4ZV`,
	"pink": `pVibEWMo3Lx6NQ`,
	"pink_dark": `ehnEvkDcuyGvxn`,
	"black_light": `dyMrzFhRjYU0CN`,
	"black": `ooU9LC7deFQZp0`,
	"black_dark": `j4woPxKZpIwqJK`,
	"label": `R2ALs3T3MmBZVl`,
	"colorless": `n1vwcAJ8v20mvB`,
	"label--fullwidth": `AEIufSWRE7BGCh`,
	"label--hoverable": `Fu_wPhEdnm4VG2`
};
export default ___CSS_LOADER_EXPORT___;
