// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.JLqdrKIuUv68ZP {
  padding: 4px;
  background: linear-gradient(79.47deg, var(--ds-background-discovery-bold, #6e5dc6) 25.62%, var(--ds-border-brand, #0c66e4) 99.57%);
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
  font-size: 11px;
  font-weight: bold;
  line-height: 11px;
  text-transform: uppercase;
}
.zkzqQ_HP1_UrKm {
  display: inline-flex;
  position: relative;
  z-index: 0;
  align-items: center;
  justify-content: center;
}
.zkzqQ_HP1_UrKm::before {
  content: '';
  position: absolute;
  border: 1px solid transparent;
  border-radius: 3px;
  background: linear-gradient(112.4deg, var(--ds-background-accent-blue-subtle, #579dff) 0%, var(--ds-background-accent-purple-subtle, #9f8fef) 100%);
  background-size: 110%;
  inset: 0;
  -webkit-mask: linear-gradient(var(--ds-surface-overlay, #ffffff) 0 0) padding-box, linear-gradient(var(--ds-surface-overlay, #ffffff) 0 0);
          mask: linear-gradient(var(--ds-surface-overlay, #ffffff) 0 0) padding-box, linear-gradient(var(--ds-surface-overlay, #ffffff) 0 0);
  -webkit-mask-composite: xor;
          mask-composite: exclude;
  background-position-x: -1px;
}
`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"premiumLozengeText": `JLqdrKIuUv68ZP`,
	"PremiumLozenge": `zkzqQ_HP1_UrKm`
};
export default ___CSS_LOADER_EXPORT___;
