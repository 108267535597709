import type {
  FunctionComponent,
  MouseEvent as ReactMouseEvent,
  PropsWithChildren,
} from 'react';

import { TrelloIntlProvider } from '@trello/i18n';

import { globalClickHandler } from 'app/src/globalClickHandler';

/**
 * NOTE:
 * If you are building components in the new GraphQL stack, you should be
 * using the renderComponent/ComponentWrapper combination. This react root
 * is for legacy React components in the classic stack only
 */

export const ReactRootComponent: FunctionComponent<
  PropsWithChildren<unknown>
> = ({ children }) => {
  const onClick = (e: ReactMouseEvent) => {
    if (e.isDefaultPrevented()) {
      return;
    }
    globalClickHandler(e);
  };

  return (
    // eslint-disable-next-line jsx-a11y/no-static-element-interactions
    <div className="js-react-root" onClick={onClick}>
      <TrelloIntlProvider>{children}</TrelloIntlProvider>
    </div>
  );
};
