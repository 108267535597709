/* eslint-disable
    eqeqeq,
*/
// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS206: Consider reworking classes to avoid initClass
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
// eslint-disable-next-line no-restricted-imports
import $ from '@trello/jquery';

import { templates } from 'app/scripts/views/internal/templates';
import { TooltipContentTemplate } from 'app/scripts/views/templates/TooltipContentTemplate';

interface Tooltip {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  DEFAULT_OPTIONS: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  TOOLTIP_CONTAINER: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  emptyTimer: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  tooltip: any;
}

class Tooltip {
  STYLE = {
    DEFAULT: 'default',
    MENU: 'menu',
    BILLING: 'billing',
  };

  static initClass() {
    this.prototype.TOOLTIP_CONTAINER = 'tooltip-container';

    this.prototype.DEFAULT_OPTIONS = {
      addVerticalOffset: 0,
    };
  }
  constructor() {
    this.tooltip = null;
  }

  show(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    tooltipHtml: any,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    $anchor: any,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    closeable?: any,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    style?: any,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    options?: any,
  ) {
    if (closeable == null) {
      closeable = true;
    }
    if (style == null) {
      style = this.STYLE.DEFAULT;
    }
    if (options == null) {
      options = this.DEFAULT_OPTIONS;
    }
    $(window).on('click', $anchor[0], this.windowClick.bind(this));

    clearTimeout(this.emptyTimer);

    if (typeof tooltipHtml.renderOnce === 'function') {
      tooltipHtml = tooltipHtml.renderOnce().$el.html();
    }

    if (
      (this.tooltip != null ? this.tooltip.tooltipHtml : undefined) ===
        tooltipHtml &&
      (this.tooltip != null ? this.tooltip.$anchor[0] : undefined) ===
        $anchor[0]
    ) {
      return;
    }

    // eslint-disable-next-line @trello/enforce-variable-case
    const $elem = $(
      templates.fill(TooltipContentTemplate, {
        tooltipHtml,
        closeable,
        style,
      }),
    );

    // remove all classes except @TOOLTIP_CONTAINER
    // eslint-disable-next-line @trello/enforce-variable-case
    const $tooltip = $(`.${this.TOOLTIP_CONTAINER}`).attr(
      'class',
      this.TOOLTIP_CONTAINER,
    );

    if (style !== this.STYLE.DEFAULT) {
      $tooltip.addClass(`tooltip-${style}`);
    }

    // @ts-expect-error TS(2345): Argument of type 'JQuery<any>' is not assignable t... Remove this comment to see the full error message
    $tooltip.html($elem);
    this.tooltip = {
      tooltipHtml,
      $anchor,
      $elem,
    };

    const { left: anchorLeft, top: anchorTop } = $anchor.offset();
    const anchorHeight = $anchor.outerHeight();
    // @ts-expect-error TS(2532): Object is possibly 'undefined'.
    const minRightOffset = window.innerWidth - $tooltip.outerWidth();

    $tooltip.css({
      left: Math.min(Math.max(anchorLeft - 5, 5), minRightOffset),
      top: Math.max(anchorTop + anchorHeight + options.addVerticalOffset, 5),
    });

    return $elem.addClass('active');
  }

  // @ts-expect-error TS(7023): 'windowClick' implicitly has return type 'any' bec... Remove this comment to see the full error message
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  windowClick(e: any) {
    // eslint-disable-next-line @trello/enforce-variable-case
    const $target = $(e.target);
    if (
      (e.target !== e.data &&
        $target.closest('.js-tooltip-content').length === 0) ||
      $target.closest('.js-tooltip-close-button').length !== 0
    ) {
      return this.hide();
    }
  }

  // @ts-expect-error TS(7023): 'hide' implicitly has return type 'any' because it... Remove this comment to see the full error message
  hide() {
    this.tooltip = null;
    // eslint-disable-next-line @trello/enforce-variable-case
    const $tooltip = $(`.${this.TOOLTIP_CONTAINER}`);
    $tooltip.find('.tooltip-content').removeClass('active');
    this.emptyTimer = setTimeout(() => {
      return $tooltip.empty();
    }, 300);

    return $(window).off('click', this.windowClick.bind(this));
  }
}
Tooltip.initClass();

// eslint-disable-next-line @trello/no-module-logic
const tooltip = new Tooltip();
export { tooltip as Tooltip };
