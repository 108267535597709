import { useEffect, useState } from 'react';

import type { TouchpointSourceType } from '@trello/ad-sequencer';
import {
  getUserTraits,
  TRELLO_JIRA_CONF_CO_USERS,
} from '@trello/atlassian-personalization';
import { useAaId, useMemberId } from '@trello/authentication';
import { getDateBefore, idToDate } from '@trello/dates';

import { getAccessibleProducts } from '../../getAccessibleProducts';
import type { RuntimeEligibilityCheckResult } from './RuntimeEligibilityCheck';
import { useIsCrossFlowMemberConfirmed } from './useIsCrossFlowMemberConfirmed';

export const useHasNoAvailableSites = ({
  doLoadAvailableSites,
  source,
}: {
  doLoadAvailableSites: boolean;
  source: TouchpointSourceType;
}): RuntimeEligibilityCheckResult => {
  const [loadingAvailableSites, setLoadingAvailableSites] = useState(false);

  const [hasNoAvailableSites, setHasNoAvailableSites] = useState(false);

  const { isEligible: isMemberConfirmed } = useIsCrossFlowMemberConfirmed();
  const memberId = useMemberId();
  const aaId = useAaId();
  const isNewUser = idToDate(memberId) > getDateBefore({ days: 2 });

  useEffect(() => {
    const getCoUserTrait = async () => {
      setLoadingAvailableSites(true);
      try {
        if (!aaId) {
          setHasNoAvailableSites(true);
          return;
        }
        const traits = await getUserTraits(aaId);
        const coUserTrait = traits?.[TRELLO_JIRA_CONF_CO_USERS]
          ? Boolean(traits[TRELLO_JIRA_CONF_CO_USERS])
          : false;
        setHasNoAvailableSites(!coUserTrait);
      } finally {
        setLoadingAvailableSites(false);
      }
    };

    const loadSiteEligibility = async () => {
      try {
        setLoadingAvailableSites(true);
        const { products } = await getAccessibleProducts();
        const hasNoSites =
          products?.length > 0 &&
          products?.filter(({ productId }) => productId !== 'trello').length ===
            0;
        setHasNoAvailableSites(hasNoSites);
      } finally {
        setLoadingAvailableSites(false);
      }
    };

    // if the user isn't confirmed, they won't have any sites
    if (!isMemberConfirmed) {
      setHasNoAvailableSites(true);
      return;
    }
    if (doLoadAvailableSites && !loadingAvailableSites) {
      if (!isNewUser) {
        getCoUserTrait();
      } else {
        loadSiteEligibility();
      }
    }
    // Intentionally ignoring loadingAvailableSites dependency
    // eslint-disable-next-line @trello/exhaustive-deps
  }, [
    source,
    doLoadAvailableSites,
    isMemberConfirmed,
    isNewUser,
    aaId,
    memberId,
  ]);

  return {
    isEligible: hasNoAvailableSites,
  };
};
