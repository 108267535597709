import type { TouchpointSourceType } from '@trello/ad-sequencer';
import type { RegisteredFeatureFlagKey } from '@trello/feature-flag-client';
import type { RegisteredFeatureKey } from '@trello/feature-gate-client';

type Extends<T, U extends T> = U;

/**
 * Once we fully transition to using Statsig, we should remove FeatureFlagsTouchpoints and featureFlagsTouchpoints
 */
export type FeatureFlagsTouchpoints = Extends<
  RegisteredFeatureFlagKey,
  'xf.cffe-for-1p-ads' | 'xf.cffe-for-funnel-experiment'
>;

// For LD flags only
export const featureFlagsTouchpoints: Record<
  FeatureFlagsTouchpoints,
  TouchpointSourceType[]
> = {
  ['xf.cffe-for-1p-ads']: [
    'viewSwitcherAddAndEditViewsInlineDialog',
    'createMenuInlineDialog',
    'createBoardInlineDialog',
  ],
  ['xf.cffe-for-funnel-experiment']: [
    'boardDirectory',
    'cardBackDatePicker',
    'createBoardInlineDialog',
    'memberHomeWorkspaceSidebarSection',
  ],
};

// For Statsig experiments only
export type CrossFlowFeatureKeys = Extends<
  RegisteredFeatureKey,
  | 'trello_xf_paid_workspaces_ads'
  | 'trello_xf_1p_ads_in_madshot_v2'
  | 'trello_xf_explanatory_use_case_selection'
  | 'trello_xf_graduated_new_users_with_ad_sequencer'
  | 'trello_xf_spork_branding_positioning_paid_users'
  | 'trello_xf_spork_branding_positioning_single_player'
  | 'trello_xf_spork_branding_positioning_new_users'
  | 'trello_xf_spork_branding_positioning_multiplayer'
  | 'trello_xf_spork_branding_positioning_free_ppm'
  | 'trello_xf_cta_and_funnel_new_user_in_product_nudge'
  | 'trello_xf_new_users_combinations'
  | 'trello_xf_paid_users_round_3'
  | 'trello_xf_template_gallery_v1_ppm'
  | 'trello_xf_template_gallery_v1_non_ppm'
>;

// For Statsig experiments only
export const featureTouchpoints: Record<
  CrossFlowFeatureKeys,
  TouchpointSourceType[]
> = {
  ['trello_xf_paid_workspaces_ads']: [
    'boardDirectory',
    'cardBackDatePicker',
    'createBoardInlineDialog',
    'memberHomeWorkspaceSidebarSection',
  ],
  ['trello_xf_1p_ads_in_madshot_v2']: ['welcomeTaskSelectionScreen'],
  ['trello_xf_explanatory_use_case_selection']: ['welcomeTaskSelectionScreen'],
  ['trello_xf_spork_branding_positioning_paid_users']: [
    'memberHomeWorkspaceSidebarSection',
    'cardBackDatePicker',
    'boardDirectory',
    'createBoardInlineDialog',
  ],
  ['trello_xf_paid_users_round_3']: [
    'memberHomeWorkspaceSidebarSection',
    'cardBackDatePicker',
    'boardDirectory',
    'createBoardInlineDialog',
    'currentWorkspaceNavigationDrawerJiraSection',
    'boardsHomeCarouselAd',
  ],
  ['trello_xf_spork_branding_positioning_single_player']: ['boardScreen'],
  ['trello_xf_spork_branding_positioning_new_users']: [
    'createFirstBoardWelcomeScreen',
    'currentWorkspaceNavigationDrawer',
    'welcomeTaskSelectionScreen',
  ],
  ['trello_xf_spork_branding_positioning_multiplayer']: ['boardScreen'],
  ['trello_xf_spork_branding_positioning_free_ppm']: [
    'memberHomeWorkspaceSidebarSection',
    'cardBackDatePicker',
    'boardDirectory',
    'createBoardInlineDialog',
  ],
  ['trello_xf_graduated_new_users_with_ad_sequencer']: [
    'cardBackDatePicker',
    'boardDirectory',
    'createBoardInlineDialog',
    'memberHomeWorkspaceSidebarSection',
  ],
  ['trello_xf_cta_and_funnel_new_user_in_product_nudge']: [
    'currentWorkspaceNavigationDrawer',
  ],
  ['trello_xf_new_users_combinations']: [
    'welcomeTaskSelectionScreen',
    'currentWorkspaceNavigationDrawer',
    'memberBoardsHomeScreen',
    'workspaceBoardsHomeScreen',
    'templatesMenuInlineDialog',
    'cardBackDatePicker',
  ],
  ['trello_xf_template_gallery_v1_ppm']: [
    'cardBackDatePicker',
    'boardDirectory',
    'createBoardInlineDialog',
    'memberHomeWorkspaceSidebarSection',
    'templateGalleryScreen',
  ],
  ['trello_xf_template_gallery_v1_non_ppm']: [
    'cardBackDatePicker',
    'boardDirectory',
    'createBoardInlineDialog',
    'memberHomeWorkspaceSidebarSection',
    'templateGalleryScreen',
  ],
};

export const isSourceTargetedByFeatureFlag = ({
  flagName,
  source,
}: {
  flagName: FeatureFlagsTouchpoints;
  source: TouchpointSourceType;
}) => featureFlagsTouchpoints[flagName]?.includes(source);

export const isSourceTargetedByFeatureKey = ({
  featureKey,
  source,
}: {
  featureKey: CrossFlowFeatureKeys;
  source: TouchpointSourceType;
}) => featureTouchpoints[featureKey]?.includes(source);
