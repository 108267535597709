/* eslint-disable react/no-danger */
import type { FunctionComponent } from 'react';
import classNames from 'classnames';

import type { PowerUpTestIds } from '@trello/test-ids';
import { getTestId } from '@trello/test-ids';
import {
  useWorkspaceNavigation,
  useWorkspaceNavigationHidden,
} from '@trello/workspace-navigation';

import { PowerUpItemType } from 'app/src/components/PowerUp';
import { PowerUpItem } from './PowerUpItem';
import type { Plugins } from './types';

import styles from './PowerUpsList.less';

interface PowerUpsListProps {
  title?: string;
  plugins: Plugins;
  type?: PowerUpItemType;
  testId?: PowerUpTestIds;
}

export const PowerUpsList: FunctionComponent<PowerUpsListProps> = ({
  title,
  plugins,
  type = PowerUpItemType.Description,
}) => {
  const [{ expanded: _workspaceNavigationExpanded }] = useWorkspaceNavigation();
  const [{ hidden: _workspaceNavigationHidden }] =
    useWorkspaceNavigationHidden();
  const workspaceNavigationExpanded =
    !_workspaceNavigationHidden && _workspaceNavigationExpanded;

  const powerUpItems = plugins.map((plugin) => {
    return <PowerUpItem key={plugin.id} type={type} plugin={plugin} />;
  });

  return (
    <div className={styles.directoryPowerUpsList}>
      <div
        className={classNames(styles.directoryPowerUpsListHeader, {
          [styles.workspaceNavigationExpanded]: workspaceNavigationExpanded,
        })}
      >
        {title && <h2 dangerouslySetInnerHTML={{ __html: title }} />}
      </div>
      <div
        className={classNames(styles.flexRowWrap, styles.powerUpsList, {
          [styles.workspaceNavigationExpanded]: workspaceNavigationExpanded,
        })}
        data-testid={getTestId<PowerUpTestIds>('pup-list')}
      >
        {powerUpItems}
      </div>
    </div>
  );
};
