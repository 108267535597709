import { useJwmLinkFragment } from './JwmLinkFragment.generated';

/**
 * Will return jwmLink data for the given organization if it has a linked JWM instance.
 */
export const useJwmLinkOrganization = (orgId?: string) => {
  const { data } = useJwmLinkFragment({
    from: { id: orgId },
    variables: { orgId },
  });

  if (!data?.jwmLink) {
    return null;
  }

  return data.jwmLink;
};
