// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS206: Consider reworking classes to avoid initClass
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */

import { Analytics } from '@trello/atlassian-analytics';
// eslint-disable-next-line no-restricted-imports
import $ from '@trello/jquery';

import type { Board } from 'app/scripts/models/Board';
import { View } from 'app/scripts/views/internal/View';
import { PowerUpsCardAgingPrefsTemplate } from 'app/scripts/views/templates/PowerUpsCardAgingPrefsTemplate';
import { stopPropagationAndPreventDefault } from 'app/src/stopPropagationAndPreventDefault';

class CardAgingSettingsView extends View<Board> {
  static initClass() {
    this.prototype.viewTitleKey = 'card aging settings';
  }

  events() {
    return {
      'click .js-select:not(.disabled)': 'selectMode',
    };
  }

  initialize() {
    return this.listenTo(this.model, { 'change:prefs': this.render });
  }

  render() {
    this.$el.html(
      PowerUpsCardAgingPrefsTemplate({
        agingMode: this.model.getPref('cardAging'),
        canChange: this.model.editable(),
      }),
    );

    return this;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  selectMode(e: any) {
    stopPropagationAndPreventDefault(e);
    const traceId = Analytics.startTask({
      taskName: 'edit-plugin/cardAging',
      source: 'cardAgingSettingsInlineDialog',
    });

    // eslint-disable-next-line @trello/enforce-variable-case
    const $target = $(e.target).closest('.js-select');

    const setting = $target.attr('name');
    const prevSetting = this.model.getPref('cardAging');
    return this.model.setPrefWithTracing('cardAging', setting, {
      taskName: 'edit-plugin/cardAging',
      source: 'cardAgingSettingsInlineDialog',
      traceId,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      next: (_err: any, board: any) => {
        if (board) {
          Analytics.sendUpdatedBoardFieldEvent({
            field: 'cardAgingPref',
            value: setting,
            source: 'cardAgingSettingsInlineDialog',
            containers: {
              board: {
                id: this.model.id,
              },
              organization: {
                id: this.model.get('idOrganization'),
              },
              enterprise: {
                id: this.model.get('idEnterprise'),
              },
            },
            attributes: {
              taskId: traceId,
              previous: prevSetting,
            },
          });
        }
      },
    });
  }
}

CardAgingSettingsView.initClass();
export { CardAgingSettingsView };
