import type { FunctionComponent } from 'react';

import type { EmailToBoardTestIds } from '@trello/test-ids';
import { getTestId } from '@trello/test-ids';

import styles from './EmailToBoardButtonSkeleton.less';

export const EmailToBoardButtonSkeleton: FunctionComponent = () => {
  return (
    <div
      data-testid={getTestId<EmailToBoardTestIds>(
        'email-to-board-button-skeleton',
      )}
    >
      <div className={styles.row} />
    </div>
  );
};
