import { Component } from 'react';

import { ModelCache } from 'app/scripts/db/ModelCache';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function ModelCacheListener(ComposedComponent: any) {
  return class extends Component {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    listeners: any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    modelListeners: any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    constructor(props: any) {
      // @ts-expect-error TS(2556): A spread argument must either have a tuple type or... Remove this comment to see the full error message
      super(...arguments);
      this.listeners = [];
      this.modelListeners = [];
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    listenTo(events: any, callback: any) {
      ModelCache.on(events, callback);
      return this.listeners.push({
        events,
        callback,
      });
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    modelListener(model: any, events: any, callback: any) {
      model.on(events, callback);
      return this.modelListeners.push({
        model,
        events,
        callback,
      });
    }

    clearModelListeners() {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      return this.modelListeners.forEach(({ model, events, callback }: any) =>
        model.off(events, callback),
      );
    }

    componentWillUnmount() {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      this.listeners.forEach(({ events, callback }: any) =>
        ModelCache.off(events, callback),
      );
      return this.clearModelListeners();
    }

    render() {
      const newProps = {
        ...this.props,
        listenTo: this.listenTo.bind(this),
        modelListener: this.modelListener.bind(this),
        clearModelListeners: this.clearModelListeners.bind(this),
      };

      return <ComposedComponent {...newProps} />;
    }
  };
}
