// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import _ from 'underscore';

import { renderReactRoot } from '@trello/component-wrapper';

import type { Board } from 'app/scripts/models/Board';
import { ReactRootComponent } from 'app/scripts/views/internal/ReactRootComponent';
import { View } from 'app/scripts/views/internal/View';
import { PowerUpItemType } from 'app/src/components/PowerUp';
import { DirectoryPluginItemView } from './DirectoryPluginItemView';

function DirectoryPageComponent() {
  return (
    <div className="directory-content-body">
      <div className="directory-listing" />
    </div>
  );
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
class DirectoryPageView extends View<Board, any> {
  eventSource() {
    const { title } = this.options;
    if (title === 'custom') {
      return 'boardDirectoryCustomScreen';
    } else if (title === 'made-by-trello') {
      return 'boardDirectoryMadeByTrelloScreen';
    } else {
      return 'boardDirectoryCategoryScreen';
    }
  }

  render() {
    renderReactRoot(
      <div>
        <ReactRootComponent>
          <DirectoryPageComponent />
        </ReactRootComponent>
      </div>,
      this.el,
    );

    const { plugins, directoryView, title } = this.options;

    const pluginViews = _.map(plugins, (plugin) => {
      return this.subview(
        DirectoryPluginItemView,
        this.model,
        {
          atomType: PowerUpItemType.Description,
          plugin,
          directoryView,
        },
        // @ts-expect-error TS(2571): Object is of type 'unknown'.
        plugin.id,
      );
    });

    this.defer(() => {
      this.appendSubviews(pluginViews, this.$el.find('.directory-listing'));
    });

    const enabledPlugins = directoryView.getEnabledPlugins();
    const listedPowerUpsEnabled = _.intersection(
      _.map(plugins, 'id'),
      _.map(enabledPlugins, 'id'),
    );

    const eventPayload = {
      totalListed: plugins.length,
      totalListedEnabled: listedPowerUpsEnabled.length,
      totalEnabled: enabledPlugins.length,
      allPowerUpsEnabled: _.map(enabledPlugins, 'id'),
      listedPowerUpsEnabled,
    };

    const eventName = this.eventSource();
    if (title !== 'custom') {
      // @ts-expect-error TS(2339): Property 'categoryid' does not exist on type '{ to... Remove this comment to see the full error message
      eventPayload.categoryid = title;
    }

    directoryView.trackScreenEvent(eventName, eventPayload);

    return this;
  }
}

export { DirectoryPageView };
