import type { FunctionComponent } from 'react';
import classNames from 'classnames';

import { Badges } from './Badges';
import type { ListingAtomProps } from './types';

// eslint-disable-next-line @trello/less-matches-component
import styles from './PowerUp.less';

export const ListingAtom: FunctionComponent<ListingAtomProps> = ({
  className,
  icon,
  name,
  subtitle,
  darkMode,
  staffPick,
  integration,
  usage,
  deprecation,
  ...props
}) => (
  <div className={classNames(styles.listingAtomGrid, className)}>
    <div className={styles.logoNameGrid}>
      <span
        className={styles.icon}
        style={{
          backgroundImage: `url(${icon.url})`,
        }}
      />
      <div className={styles.nameContainer}>
        <span className={styles.listingName} title={name}>
          {name}
        </span>
        {subtitle && <span className={styles.subtitle}>{subtitle}</span>}
      </div>
    </div>
    <Badges
      button={props.button}
      usage={usage}
      darkMode={darkMode}
      staffPick={staffPick}
      integration={integration}
      hideInstallCues={Boolean(deprecation?.sunsetDate)}
    />
  </div>
);

export const StyledListingAtom: FunctionComponent<ListingAtomProps> = ({
  className,
  ...props
}) => <ListingAtom className={className} {...props} />;
