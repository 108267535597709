import type { TracedError } from '@trello/atlassian-analytics';
import type { Disposer } from '@trello/component-wrapper';
import { sendErrorEvent } from '@trello/error-reporting';

export const friendlyLinksDisposer = async function (
  friendlyLinksPromises:
    | Promise<Disposer[]>
    | Promise<Disposer[]>[]
    | undefined,
) {
  if (!friendlyLinksPromises) return;

  try {
    const friendlyLinksPromiseArray = await Promise.all(
      Array.isArray(friendlyLinksPromises)
        ? friendlyLinksPromises
        : [friendlyLinksPromises],
    );

    friendlyLinksPromiseArray.flat().forEach((disposer) => {
      if (typeof disposer === 'function') disposer();
    });
    return;
  } catch (err) {
    sendErrorEvent(err as TracedError, {
      tags: {
        ownershipArea: 'trello-web-eng',
        feature: 'Friendly Links',
      },
    });
  }
};
