// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.m7unmzL4IcVylQ {
  width: 13px;
}
.m7unmzL4IcVylQ svg {
  width: 12px;
  height: 12px;
}
`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"aiIcon": `m7unmzL4IcVylQ`
};
export default ___CSS_LOADER_EXPORT___;
