// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * DS206: Consider reworking classes to avoid initClass
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */

// eslint-disable-next-line no-restricted-imports
import $ from '@trello/jquery';

import { teacupWithHelpers } from 'app/scripts/views/internal/teacupWithHelpers';
import { templates } from 'app/scripts/views/internal/templates';
import { View } from 'app/scripts/views/internal/View';
import { SelectMemberTemplate } from 'app/scripts/views/templates/SelectMemberTemplate';
const t = teacupWithHelpers();

// eslint-disable-next-line @typescript-eslint/no-explicit-any, @trello/no-module-logic
const template = t.renderable(function (this: any, { newBillableGuests }: any) {
  this.newBillableGuests = newBillableGuests;
  return t.ul('.pop-over-member-list.js-billable-guest-list');
});

interface BillableGuestListPopoverView {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  newBillableGuests: any;
}

class BillableGuestListPopoverView extends View {
  static initClass() {
    this.prototype.viewTitleKey = 'billable guest list';
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  initialize({ newBillableGuests }: any) {
    this.newBillableGuests = newBillableGuests;
  }
  render() {
    this.$el.html(template({ newBillableGuests: this.newBillableGuests }));
    // eslint-disable-next-line @trello/enforce-variable-case
    const $billableGuestList = $('.js-billable-guest-list', this.$el)
      .first()
      .empty();

    for (const member of Array.from(this.newBillableGuests)) {
      // @ts-expect-error TS(2769): No overload matches this call.
      const data = Object.assign(member, {
        // @ts-expect-error TS(2571): Object is of type 'unknown'.
        url: `/${member.username}`,
        // @ts-expect-error TS(2571): Object is of type 'unknown'.
        unconfirmed: !member.confirmed,
      });
      $billableGuestList.append(
        templates.fill(
          SelectMemberTemplate,
          data,
          {},
          // @ts-expect-error TS(2339): Property 'member' does not exist on type '{ mustac... Remove this comment to see the full error message
          { member: templates.member },
        ),
      );
    }
    return this;
  }
}

BillableGuestListPopoverView.initClass();
export { BillableGuestListPopoverView };
