import type {
  ExperimentVariations,
  FeatureExperimentKeys,
} from '@trello/feature-gate-client';

type Extends<T, U extends T> = U;

type SporkExperimentKeys = Extends<
  FeatureExperimentKeys,
  | 'trello_xf_spork_branding_positioning_free_ppm'
  | 'trello_xf_spork_branding_positioning_multiplayer'
  | 'trello_xf_spork_branding_positioning_new_users'
  | 'trello_xf_spork_branding_positioning_paid_users'
  | 'trello_xf_spork_branding_positioning_single_player'
>;

type SporkCohortVariation = ExperimentVariations<SporkExperimentKeys, 'cohort'>;

export const getSporkCffeExperimentalOption = (
  cohort: SporkCohortVariation,
) => {
  if (cohort === 'not-enrolled') {
    return {};
  }

  return { sporkPositioning: cohort };
};
