import type { Board, Member } from '@trello/model-types';

import { getMemberTypeFromBoard } from './getMemberTypeFromBoard';
import { getMyBoards } from './getMyBoards';

export const getMyOwnedBoards = <
  M extends Pick<
    Member,
    'id' | 'idBoards' | 'idPremOrgsAdmin' | 'idEnterprisesAdmin' | 'memberType'
  > & {
    boards: {
      id: string;
      memberships: Pick<
        Board['memberships'][number],
        'id' | 'idMember' | 'memberType' | 'deactivated' | 'unconfirmed'
      >[];
      organization?:
        | (Pick<Board['organization'], 'id' | 'offering'> & {
            memberships: Pick<
              Board['organization']['memberships'][number],
              'idMember' | 'memberType' | 'deactivated' | 'unconfirmed'
            >[];
            enterprise?: Pick<
              Board['organization']['enterprise'],
              'idAdmins'
            > | null;
          })
        | null;
    }[];
  },
>(
  member: M,
): M['boards'] => {
  return getMyBoards(member).filter(
    (board) => getMemberTypeFromBoard(member, board) === 'admin',
  );
};
