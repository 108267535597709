import { renderComponent } from '@trello/component-wrapper';
// eslint-disable-next-line no-restricted-imports
import $ from '@trello/jquery';

import { LazyInviteeOrientation } from 'app/src/components/InviteeOrientation';

export const renderInviteeOrientation = ({
  $el,
  el,
  idBoard,
}: {
  $el: JQuery<HTMLElement>;
  el: HTMLElement;
  idBoard: string;
}) => {
  $('.board-canvas', $el).append(
    '<div class="invitee-orientation-mount-point"></div>',
  );

  const $element = el.querySelector('.invitee-orientation-mount-point');

  if (!$element) {
    return;
  }

  return renderComponent(
    <LazyInviteeOrientation idBoard={idBoard} />,
    $element,
  );
};
