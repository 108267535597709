import type {
  ChangeEventHandler,
  ComponentType,
  EventHandler,
  ForwardRefRenderFunction,
  FunctionComponent,
  MouseEvent as ReactMouseEvent,
  PropsWithChildren,
  PropsWithoutRef,
  ReactNode,
  RefAttributes,
} from 'react';
import { forwardRef } from 'react';
import classNames from 'classnames';

import { useFocusRing } from '@trello/a11y';
import { CheckIcon } from '@trello/nachos/icons/check';
import { RouterLink } from '@trello/router/router-link';
import type { TestId } from '@trello/test-ids';
import { token } from '@trello/theme';

import styles from './PopoverMenu.less';

type ForwardedComponent<T, P> = ComponentType<
  PropsWithoutRef<P> & RefAttributes<T>
>;

const forwardRefComponent = <T, P>(
  displayName: string,
  fn: ForwardRefRenderFunction<T, P>,
): ForwardedComponent<T, P> => {
  fn.displayName = displayName;

  return forwardRef<T, P>(fn);
};

interface ContentProps {
  description?: ReactNode;
  rawDescription?: string;
  title?: ReactNode;
  iconBefore?: JSX.Element;
  iconAfter?: JSX.Element;
}

interface PopoverMenuButtonProps extends ContentProps {
  onClick: EventHandler<ReactMouseEvent<HTMLButtonElement>>;
  className?: string;
  disabled?: boolean;
  testId?: TestId;
}

interface PopoverMenuRadioProps extends ContentProps {
  onChange: ChangeEventHandler<HTMLInputElement>;
  name: string;
  value: string;
  disabled?: boolean;
  defaultChecked?: boolean;
  testId?: TestId;
}

interface PopoverMenuLinkProps extends ContentProps {
  href: string;
  target?: string;
  onClick?: EventHandler<ReactMouseEvent<HTMLAnchorElement>>;
  testId?: TestId;
  className?: string;
  icon?: JSX.Element;
  external?: boolean;
  download?: string;
}

export const PopoverMenuItemSeparator: FunctionComponent<{
  fullWidth?: boolean;
}> = ({ fullWidth }) => (
  <li
    className={classNames({
      [styles.separator]: true,
      [styles['separator--fullWidth']]: fullWidth,
    })}
  ></li>
);

const PopoverMenuItemContents: FunctionComponent<
  PropsWithChildren<ContentProps>
> = ({
  children,
  description,
  rawDescription,
  title,
  iconBefore,
  iconAfter,
}) => (
  <>
    <span
      className={classNames({
        [styles.item]: true,
        [styles.withoutMargins]: !!description,
      })}
    >
      <span className={styles.title}>
        {iconBefore && <span className={styles.iconBefore}>{iconBefore}</span>}
        {title || children}
      </span>
      {iconAfter && <span className={styles.iconAfter}>{iconAfter}</span>}
    </span>
    {(description || rawDescription) && (
      <div className={styles.description}>
        {description ? description : rawDescription}
      </div>
    )}
  </>
);

export const PopoverMenuLink: FunctionComponent<
  PropsWithChildren<PopoverMenuLinkProps>
> = ({
  testId,
  href,
  target,
  className,
  onClick,
  external = false,
  download,
  ...contentProps
}) => {
  const [hasFocusRing, mouseEvents] = useFocusRing();
  const LinkComponent = external ? 'a' : RouterLink;
  const linkComponentProps = {
    href,
    onClick,
    target,
    download,
    ...(LinkComponent === RouterLink ? { testId } : { 'data-testid': testId }),
  };

  return (
    <li>
      <LinkComponent
        className={classNames(
          styles.link,
          className,
          hasFocusRing && styles.linkFocusRing,
        )}
        {...linkComponentProps}
        {...mouseEvents}
      >
        <PopoverMenuItemContents {...contentProps} />
      </LinkComponent>
    </li>
  );
};

export const PopoverMenuButton = forwardRefComponent<
  HTMLButtonElement,
  PropsWithChildren<PopoverMenuButtonProps>
>(
  'PopoverMenuButton',
  (
    {
      onClick,
      className,
      disabled,
      testId,
      ...contentProps
    }: PopoverMenuButtonProps,
    ref,
  ) => {
    const [hasFocusRing, mouseEvents] = useFocusRing();

    return (
      <li>
        <button
          className={classNames(
            styles.link,
            hasFocusRing && styles.linkFocusRing,
            className,
          )}
          disabled={disabled}
          onClick={onClick}
          data-testid={testId}
          ref={ref}
          {...mouseEvents}
        >
          <PopoverMenuItemContents {...contentProps} />
        </button>
      </li>
    );
  },
);

export const PopoverMenuRadio = forwardRefComponent<
  HTMLLabelElement,
  PropsWithChildren<PopoverMenuRadioProps>
>(
  'PopoverMenuRadio',
  (
    {
      onChange,
      defaultChecked,
      value,
      disabled,
      testId,
      name,
      ...contentProps
    },
    ref,
  ) => (
    <li>
      <label
        className={classNames(styles.radio, disabled && styles.disabled)}
        ref={ref}
      >
        <div className={styles.checkmark}>
          <input
            type="radio"
            name={name}
            data-testid={testId}
            defaultChecked={defaultChecked}
            disabled={disabled}
            value={value}
            onChange={onChange}
            className={styles.input}
          />
          <span className={styles.icon}>
            <CheckIcon
              size="small"
              color={
                disabled
                  ? token('color.text.disabled', '#091E424F')
                  : token('color.text', '#172B4D')
              }
            />
          </span>
        </div>
        <div className={styles.content}>
          <PopoverMenuItemContents {...contentProps} />
        </div>
      </label>
    </li>
  ),
);

interface PopoverMenuProps {
  className?: string;
  testId?: TestId;
}

export const PopoverMenu: FunctionComponent<
  PropsWithChildren<PopoverMenuProps>
> = ({ className, children, testId }) => (
  <nav className={classNames(styles.popoverMenu, className)}>
    <ul data-testid={testId}>{children}</ul>
  </nav>
);
