import type { Board, Member } from '@trello/model-types';

export const getMyBoards = <
  M extends Pick<Member, 'id' | 'idBoards'> & {
    boards: {
      id: string;
      memberships: Pick<
        Board['memberships'][number],
        'id' | 'idMember' | 'memberType'
      >[];
    }[];
  },
>(
  member: M,
): M['boards'] => {
  const inIdBoards = new Map<string, boolean>();
  if (member.idBoards) {
    member.idBoards.forEach((id) => inIdBoards.set(id, true));
  }

  return member.boards.filter(
    ({ id, memberships }) =>
      inIdBoards.get(id) &&
      memberships.some(({ idMember }) => idMember === member.id),
  );
};
