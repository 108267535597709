import { useMemo } from 'react';

import { useMemberId } from '@trello/authentication';
import { getMyOwnedBoards } from '@trello/business-logic/board';

import type { MyOwnedBoardsQuery } from './MyOwnedBoardsQuery.generated';
import { useMyOwnedBoardsQuery } from './MyOwnedBoardsQuery.generated';

type Organization = NonNullable<
  MyOwnedBoardsQuery['member']
>['organizations'][number];

export const useMyOwnedBoards = () => {
  const memberId = useMemberId();
  const { data } = useMyOwnedBoardsQuery({
    variables: {
      idMember: memberId,
    },
    fetchPolicy: 'cache-only',
    waitOn: ['MemberBoards'],
  });

  const member = data?.member;

  const boardsWithOrganizations = useMemo(() => {
    if (!member || !data?.member?.organizations) {
      return [];
    }

    const boards = [];
    const organizationsMap = new Map<string, Organization>();
    member.organizations.forEach((organization) =>
      organizationsMap.set(organization.id, organization),
    );

    // eslint-disable-next-line no-unsafe-optional-chaining
    for (const board of data?.member?.boards) {
      boards.push({
        ...board,
        organization: board.idOrganization
          ? organizationsMap.get(board.idOrganization) ?? null
          : null,
      });
    }

    return boards;
  }, [member, data?.member?.organizations, data?.member?.boards]);

  if (!member?.boards) {
    return [];
  }

  return getMyOwnedBoards({
    ...member,
    boards: boardsWithOrganizations,
  });
};
