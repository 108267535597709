import type { FunctionComponent, MouseEventHandler, ReactNode } from 'react';
import { forwardRef, useCallback, useEffect, useState } from 'react';
import { FocusScope } from '@react-aria/focus';
import cx from 'classnames';

import { useForwardRef } from '@trello/dom-hooks/ref';
import { Layers } from '@trello/layer-manager';
import { forNamespace } from '@trello/legacy-i18n';
import { Button } from '@trello/nachos/button';
import type { GlyphProps } from '@trello/nachos/icons/close';
import { CloseIcon } from '@trello/nachos/icons/close';
import type { OverlayAlignment } from '@trello/nachos/overlay';
import { Overlay } from '@trello/nachos/overlay';
import { token } from '@trello/theme';

import styles from './Dialog.less';

const format = forNamespace();

/**
 * Callback function fired when Dialog is shown
 * @callback onShowCallback
 */

/**
 * Callback function fired when Dialog is shown
 * @callback onHideCallback
 */

/**
 * @typedef UseDialogHookConfigOptions
 * @type {object}
 * @property {onShowCallback} onShow
 * @property {onHideCallback} onHide
 */
interface UseDialogHookConfigOptions {
  onShow?: () => void;
  onHide?: () => void;
}

/**
 * useDialog React hook. Used to manage show/hide state for a Dialog component
 *
 * @usage
 * ```js
 * const { show, dialogProps } = useDialog({
 *   onShow: () => console.log('dialog was shown'),
 *   onHide: () => console.log('dialog was hidden'),
 * });
 *
 * return (
 *  <div>
 *    <button onClick={show}>
 *      Click me to show the dialog!
 *    </button>
 *    <Dialog title="Example dialog" {...dialogProps}>
 *      I am a dialog
 *    </Dialog>
 *  </div>
 * );
 * ```
 *
 * @function useDialog
 * @param {UseDialogHookConfigOptions} [opts]
 */
export const useDialog = (opts?: UseDialogHookConfigOptions) => {
  const { onShow, onHide } = (opts ?? {}) as UseDialogHookConfigOptions;

  const [isOpen, setIsOpen] = useState(false);
  const show = useCallback(() => {
    setIsOpen(true);
    onShow?.();
  }, [setIsOpen, onShow]);

  const hide = useCallback(() => {
    setIsOpen(false);
    onHide?.();
  }, [setIsOpen, onHide]);

  const toggle = useCallback(() => {
    if (isOpen) {
      hide();
    } else {
      show();
    }
  }, [isOpen, show, hide]);

  return {
    show,
    hide,
    toggle,
    isOpen,
    dialogProps: {
      show,
      hide,
      toggle,
      isOpen,
    },
  };
};

interface DialogCloseButtonProps {
  className?: string;
  onClick: MouseEventHandler<HTMLButtonElement>;
  size?: GlyphProps['size'];
  /**
   * Set the color of the CloseIcon component.
   *
   * @default token('color.text.accent.gray.bolder')
   */
  iconColor?: string;
}

export const DialogCloseButton: FunctionComponent<DialogCloseButtonProps> = ({
  className,
  onClick,
  size = 'large',
  iconColor = token('color.text.accent.gray.bolder', '#091E42'),
}) => (
  <Button
    aria-label={format('close dialog')}
    iconBefore={
      <CloseIcon
        color={iconColor}
        size={size}
        dangerous_className={styles.closeButtonIcon}
      />
    }
    className={cx({
      [styles.closeButton]: true,
      [String(className)]: !!className,
    })}
    onClick={onClick}
    style={{ color: iconColor }}
  />
);

export interface DialogProps {
  /**
   * Optional classname applied to root Dialog container element
   */
  className?: string;

  /**
   * Width of the Dialog. Defaults to "medium"
   */
  size?: 'small' | 'medium' | 'large';

  /**
   * Contents of the Dialog
   */
  children: ReactNode;

  /**
   * Whether the Dialog is open. Provided by `useDialog` hook via `dialogProps`
   */
  isOpen?: boolean;

  /**
   * Callback function to show the Dialog. Provided by useDialog hook via `dialogProps`
   */
  show: () => void;

  /**
   * Callback function to hide the Dialog. Provided by useDialog hook via `dialogProps`
   */
  hide: () => void;

  /**
   * Callback function to toggle the Dialog. Provided by useDialog hook via `dialogProps`
   */
  toggle: () => void;

  /**
   * Should this Dialog close when the Escape key is pressed?
   */
  closeOnEscape?: boolean;

  /**
   * Should this Dialog close when the outside of the Dialog is clicked?
   */
  closeOnOutsideClick?: boolean;

  /**
   * Specify the alignment of the dialog.
   *
   * @default 'top'
   */
  alignment?: OverlayAlignment;

  /**
   * Whether or not focus should be contained inside the Dialog so users cannot move focus outside without first closing the Dialog.
   *
   * @default true
   */
  trapFocus?: boolean;

  /**
   * Defines the the layer to render the dialog in.
   * Was added as an exception for the Card Back
   */
  layer?: Layers;

  /**
   * https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Attributes/aria-labelledby
   */
  labelledBy?: string;
}

export const Dialog = forwardRef<HTMLDivElement, DialogProps>(
  (
    {
      className,
      size = 'medium',
      isOpen,
      show,
      hide,
      children,
      closeOnEscape = true,
      closeOnOutsideClick = true,
      alignment = 'top',
      trapFocus = true,
      layer = Layers.Overlay,
      labelledBy,
    },
    forwardedRef,
  ) => {
    const ref = useForwardRef(forwardedRef);

    // Autofocus the dialog container element when the Dialog is opened.
    useEffect(() => {
      if (isOpen) {
        ref.current?.focus({
          preventScroll: true,
        });
      }
    }, [ref, isOpen]);

    if (!isOpen) {
      return null;
    }

    return (
      <Overlay
        alignment={alignment}
        closeOnEscape={closeOnEscape}
        closeOnOutsideClick={closeOnOutsideClick}
        onClose={hide}
        layer={layer}
      >
        <FocusScope contain={trapFocus} restoreFocus autoFocus>
          <div
            className={cx({
              [styles.dialog]: true,
              [String(className)]: !!className,
              [styles[size]]: !!size,
            })}
            tabIndex={-1}
            ref={ref}
            role="dialog"
            aria-modal
            aria-labelledby={labelledBy}
          >
            <div className={styles.body}>{children}</div>
          </div>
        </FocusScope>
      </Overlay>
    );
  },
);
