import type { FunctionComponent } from 'react';

import { Atom } from './Atom';
import { StyledDescriptionAtom as DescriptionAtom } from './DescriptionAtom';
import { FeaturedAtom } from './FeaturedAtom';
import { StyledListingAtom as ListingAtom } from './ListingAtom';
import type {
  AtomProps,
  DescriptionAtomProps,
  FeaturedAtomProps,
  ListingAtomProps,
} from './types';
import { PowerUpItemType } from './types';

export interface PowerUpProps {
  type: PowerUpItemType;
  atomProps: AtomProps | DescriptionAtomProps | FeaturedAtomProps;
}

export const PowerUp: FunctionComponent<PowerUpProps> = ({
  type,
  atomProps,
}) => {
  if (type === PowerUpItemType.Featured) {
    return <FeaturedAtom {...(atomProps as FeaturedAtomProps)} />;
  } else if (type === PowerUpItemType.Description) {
    return <DescriptionAtom {...(atomProps as DescriptionAtomProps)} />;
  } else if (type === PowerUpItemType.Listing) {
    return <ListingAtom {...(atomProps as ListingAtomProps)} />;
  }

  return <Atom {...atomProps} />;
};
