import type {
  ChangeEventHandler,
  KeyboardEventHandler,
  MouseEvent,
} from 'react';
import { useCallback, useEffect, useState } from 'react';
import cx from 'classnames';
import { useDebouncedCallback } from 'use-debounce';

import { intl } from '@trello/i18n';
import { getKey, Key } from '@trello/keybindings';
import { asNumber } from '@trello/legacy-i18n/formatters';
import type { RouteId } from '@trello/router';
import { useLocation, useRouteParams } from '@trello/router';
import { RouterLink } from '@trello/router/router-link';
import type { PowerUpTestIds } from '@trello/test-ids';
import { getTestId } from '@trello/test-ids';

import { getBoardUrlFromShortLink } from 'app/scripts/controller/urls';
import { categories } from 'app/scripts/data/directory';
import { toggleNavigationMenu } from './toggleNavigationMenu';
import { useCustomPowerUps } from './useCustomPowerUps';
import { useEnabledPowerUps } from './useEnabledPowerUps';

function DirectorySidebarPlaceholder() {
  return (
    <div>
      <div className="directory-search">
        <div className="directory-navigation-option placeholder" />
      </div>
      <ul>
        <li>
          <div className="directory-navigation-option placeholder" />
        </li>
        <li>
          <div className="directory-navigation-option placeholder" />
        </li>
        <li>
          <div className="directory-navigation-option placeholder" />
        </li>
        <li className="directory-categories">
          <div className="directory-categories-heading placeholder" />
          <ul className="directory-navigation-categories-list">
            {categories.map((categoryKey) => (
              <li key={categoryKey}>
                <div className="directory-navigation-option placeholder" />
              </li>
            ))}
          </ul>
        </li>
      </ul>
    </div>
  );
}

function DirectorySidebar({
  onSearch,
}: {
  onSearch: (searchTerm: string) => void;
}) {
  const [search, setSearch] = useState('');

  const customPowerUps = useCustomPowerUps();
  const showCustomCategory = customPowerUps.length > 0;
  const routeParams = useRouteParams<RouteId.BOARD>();
  const location = useLocation();

  const enabledPowerUps = useEnabledPowerUps();
  let enabledCount = '';
  const totalEnabled = enabledPowerUps.length;

  if (totalEnabled > 0) {
    enabledCount = asNumber(totalEnabled);
  }

  const { shortLink, shortName } = routeParams;

  const category = 'category' in routeParams ? routeParams.category : null;
  const section = 'section' in routeParams ? routeParams.section : null;
  const powerUpId = 'powerUpId' in routeParams ? routeParams.powerUpId : null;

  const debouncedOnSearch = useDebouncedCallback(onSearch, 100);

  const clearSearch = useCallback(
    (e?: MouseEvent<HTMLAnchorElement>) => {
      e?.preventDefault();
      setSearch('');
      debouncedOnSearch('');
    },
    [debouncedOnSearch],
  );

  // Clear the search term when navigating
  useEffect(() => {
    clearSearch();
  }, [location.pathname, clearSearch]);

  const onSearchChange: ChangeEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      setSearch(e.currentTarget.value);
      debouncedOnSearch(e.currentTarget.value);
    },
    [debouncedOnSearch],
  );

  const onSearchKeyUp: KeyboardEventHandler<HTMLInputElement> = useCallback(
    (e) => {
      const key = getKey(e);
      if (key === Key.Escape) {
        clearSearch();
      }

      if (
        key === Key.Enter &&
        document
          .querySelector('.js-directory-sidebar')
          ?.classList.contains('active')
      ) {
        toggleNavigationMenu();
      }
    },
    [clearSearch],
  );

  return (
    <div>
      <div className="directory-search">
        <input
          type="text"
          placeholder={intl.formatMessage({
            id: 'templates.directory.search',
            defaultMessage: 'Search',
            description:
              'A placeholder in the text field when searching for power-ups in the directory.',
          })}
          onChange={onSearchChange}
          onKeyUp={onSearchKeyUp}
          className="js-directory-search"
          value={search}
        />
        <a
          role="button"
          href="#"
          className={cx(
            'icon-sm icon-close dark-hover js-directory-search-clear',
            {
              hide: !search,
            },
          )}
          onClick={clearSearch}
        />
      </div>
      <div className="directory-navigation-section">
        <ul>
          <li>
            <RouterLink
              href={`${getBoardUrlFromShortLink(
                shortLink,
                shortName,
              )}/power-ups`}
              className={cx('directory-navigation-option', {
                active: !section && !category && !powerUpId,
              })}
            >
              <span className="icon-md icon-star" />
              {intl.formatMessage({
                id: 'templates.directory.featured',
                defaultMessage: 'Featured',
                description: 'A link to the featured power-ups section.',
              })}
            </RouterLink>
          </li>
          <li>
            <RouterLink
              href={`${getBoardUrlFromShortLink(
                shortLink,
                shortName,
              )}/power-ups/enabled`}
              className={cx('directory-navigation-option enable-option', {
                active: section === 'enabled',
              })}
              testId={getTestId<PowerUpTestIds>('enabled-powerups-link')}
            >
              <span className="icon-md icon-power-up" />
              {intl.formatMessage({
                id: 'templates.directory.enabled',
                defaultMessage: 'Enabled',
                description: 'A link to the enabled power-ups section.',
              })}
              <span className="directory-navigation-power-up-count">
                {enabledCount}
              </span>
            </RouterLink>
          </li>
          {showCustomCategory && (
            <li>
              <RouterLink
                href={`${getBoardUrlFromShortLink(
                  shortLink,
                  shortName,
                )}/power-ups/custom`}
                className={cx('directory-navigation-option', {
                  active: section === 'custom',
                })}
              >
                <span className="icon-md icon-gear" />
                {intl.formatMessage({
                  id: 'templates.directory.custom',
                  defaultMessage: 'Custom',
                  description: 'A link to the custom power-ups section.',
                })}
              </RouterLink>
            </li>
          )}
          <li>
            <RouterLink
              href={`${getBoardUrlFromShortLink(
                shortLink,
                shortName,
              )}/power-ups/made-by-trello`}
              className={cx('directory-navigation-option trello-option', {
                active: section === 'made-by-trello',
              })}
              testId={getTestId<PowerUpTestIds>('trello-powerups-link')}
            >
              <span className="icon-md icon-board" />
              {intl.formatMessage({
                id: 'templates.directory.made-by-trello',
                defaultMessage: 'Made by Trello',
                description: 'A link to the power-ups that are made by Trello.',
              })}
            </RouterLink>
          </li>
        </ul>
      </div>
      <div className="directory-navigation-section">
        <h4 className="directory-navigation-section-header-title">
          {intl.formatMessage({
            id: 'templates.directory.categories',
            defaultMessage: 'Categories',
            description:
              'A heading for a list of categories in the Power-Up directory.',
          })}
        </h4>
        <ul>
          {categories.map((categoryKey) => (
            <li key={categoryKey}>
              <RouterLink
                href={`${getBoardUrlFromShortLink(
                  shortLink,
                  shortName,
                )}/power-ups/category/${categoryKey}`}
                className={cx('directory-navigation-option category-link', {
                  active: category === categoryKey,
                })}
                dangerouslySetInnerHTML={{
                  // eslint-disable-next-line formatjs/enforce-default-message, formatjs/enforce-description
                  __html: intl.formatMessage({
                    id: `templates.directory.${categoryKey}`,
                  }),
                }}
              />
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}

export function DirectorySidebarView({
  onSearch,
}: {
  onSearch: (searchTerm: string) => void;
}) {
  // TODO: Need to implement loading state in a follow-up. This is difficult to do at the moment because of the
  // split between Backbone and React.
  const isLoading = false;
  return isLoading ? (
    <DirectorySidebarPlaceholder />
  ) : (
    <DirectorySidebar onSearch={onSearch} />
  );
}
