import { LegacyPowerUps } from 'app/scripts/data/legacy-power-ups';
import type { Board } from 'app/scripts/models/Board';
import { CalendarSettingsView } from 'app/scripts/views/power-ups/CalendarSettingsView';
import { CardAgingSettingsView } from 'app/scripts/views/power-ups/CardAgingSettingsView';
import { VotingSettingsView } from 'app/scripts/views/power-ups/VotingSettingsView';

export const DirectoryLegacyPowerUps = [
  {
    name: 'voting',
    settingsView(model: Board) {
      return new VotingSettingsView({ model });
    },
    id: LegacyPowerUps.voting,
  },
  {
    name: 'cardAging',
    settingsView(model: Board) {
      return new CardAgingSettingsView({ model });
    },
    id: LegacyPowerUps.cardAging,
  },
  {
    name: 'calendar',
    settingsView(model: Board) {
      return new CalendarSettingsView({ model });
    },
    id: LegacyPowerUps.calendar,
  },
];
