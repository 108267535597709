import { useEffect, useState } from 'react';

import { getBrightness } from '@trello/a11y';
import type { NullUnionOmit } from '@trello/model-types';
import { usePreviousWhileLoading, useWorkspace } from '@trello/workspaces';

import type { BoardBackgroundQuery } from './BoardBackgroundQuery.generated';
import { useBoardBackgroundQuery } from './BoardBackgroundQuery.generated';

type BoardPrefs = NullUnionOmit<
  NonNullable<BoardBackgroundQuery['board']>['prefs'],
  '__typename'
>;

export interface BoardBackground
  extends Pick<
    NonNullable<BoardPrefs>,
    | 'background'
    | 'backgroundBrightness'
    | 'backgroundImage'
    | 'backgroundBottomColor'
    | 'backgroundTopColor'
    | 'backgroundTile'
    | 'backgroundImageScaled'
  > {
  backgroundColor: string | undefined;
  isBackgroundImage: boolean;
}

export const emptyBoardBackground: BoardBackground = {
  background: '__empty__',
  backgroundImage: null,
  backgroundBrightness: 'unknown',
  backgroundColor: undefined,
  backgroundTopColor: '',
  backgroundTile: false,
  backgroundBottomColor: '',
  isBackgroundImage: false,
  backgroundImageScaled: [],
};

export const isEmptyBoardBackground = (background: BoardBackground) =>
  background.background === '__empty__';

/**
 * Given a board prefs object with background properties defined, get the
 * BoardBackground model in return. Extracted from `useBoardBackground` for
 * usage outside of React.
 */
export const getBoardBackground = (
  prefs: BoardPrefs | undefined,
): BoardBackground => {
  if (!prefs) {
    return emptyBoardBackground;
  }
  const {
    background,
    backgroundColor,
    backgroundTopColor,
    backgroundBottomColor,
    backgroundBrightness,
    backgroundImage,
    backgroundTile,
    backgroundImageScaled,
  } = prefs;

  let backgroundBrightnessComputed = backgroundBrightness;
  if (
    backgroundTopColor &&
    backgroundImage &&
    backgroundBrightness === 'unknown'
  ) {
    // It is possible for backgroundBrightness to be "unknown" in cases where the
    // image preview service times out or fails. Calculate it now.
    backgroundBrightnessComputed = getBrightness(backgroundTopColor);
  } else if (backgroundImage && !backgroundTopColor) {
    // If there is an image background, we need backgroundTopColor to compute
    // the color for nav. If missing, default to empty so text in nav is readable.
    return emptyBoardBackground;
  }

  return {
    background,
    backgroundImage,
    backgroundColor: backgroundColor ?? undefined,
    backgroundTopColor,
    backgroundBottomColor,
    backgroundBrightness: backgroundBrightnessComputed,
    isBackgroundImage: Boolean(backgroundImage),
    backgroundTile,
    backgroundImageScaled,
  };
};

/**
 * Given a board ID, derive the values required to hydrate a usable
 * BoardBackground model. To get these values for the current board, refer to
 * `useCurrentBoardBackground` instead.
 */
export function useBoardBackground({
  idBoard,
  waitOn = ['None'],
}: {
  idBoard: string | null;
  waitOn?: Parameters<typeof useBoardBackgroundQuery>[0]['waitOn'];
}) {
  const skip = !idBoard;

  // `/1/board/{idBoard}?fields=id,prefs&operationName=BoardBackground`
  const { data } = useBoardBackgroundQuery({
    skip,
    variables: { boardId: idBoard || '' },
    waitOn,
  });

  const [boardBackground, setBoardBackground] =
    useState<BoardBackground>(emptyBoardBackground);

  useEffect(() => {
    setBoardBackground(getBoardBackground(data?.board?.prefs));
  }, [data?.board?.prefs]);

  return boardBackground;
}

export function useCurrentBoardBackground() {
  const workspace = useWorkspace();
  const idBoard = usePreviousWhileLoading(
    workspace.idBoard,
    workspace.isLoading,
    null,
  );
  return useBoardBackground({
    idBoard,
    waitOn: ['CurrentBoardInfo'],
  });
}
