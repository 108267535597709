import { l } from 'app/scripts/lib/localize';
import { templates } from 'app/scripts/views/internal/templates';
import { View } from 'app/scripts/views/internal/View';
import { PopOver } from 'app/scripts/views/lib/PopOver';
import { ConfirmTemplate } from 'app/scripts/views/templates/ConfirmTemplate';
import { stopPropagationAndPreventDefault } from 'app/src/stopPropagationAndPreventDefault';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const lazyDefaults = (original: any, defaults: any) => {
  const target = { ...original };

  Object.keys(defaults)
    .filter((key) => !(key in target))
    .forEach((key) => {
      const valueFunction = defaults[key];
      target[key] = valueFunction();
    });

  return target;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const makeOpts = (key: any, oldOpts: any) => {
  const opts = lazyDefaults(oldOpts, {
    title() {
      return l(['confirm', key, 'title'], oldOpts.templateData);
    },
    // We are currently assuming that translations are safe HTML
    html() {
      return l(['confirm', key, 'text'], oldOpts.templateData);
    },
    confirmText() {
      return l(['confirm', key, 'confirm'], oldOpts.templateData);
    },
  });

  if ('fxCancel' in opts && !('cancelText' in opts)) {
    opts.cancelText = l(['confirm', key, 'cancel'], oldOpts.templateData);
  }

  return opts;
};

interface Confirm {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  _events: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  cancel: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  confirm: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  delegateEvents: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  elem: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  maxWidth: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  popDepth: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  popOnClick: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  template: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  templateData: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  title: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  top: any;
}

class Confirm extends View {
  constructor({
    model,
    text,
    html,
    elem,
    top,
    confirmText,
    confirmBtnClass,
    confirmBtnTestId,
    cancelText,
    title,
    template,
    templateData,
    data,
    fxConfirm,
    fxCancel,
    popOnClick,
    popDepth,
    maxWidth,
  }: // eslint-disable-next-line @typescript-eslint/no-explicit-any
  any) {
    super(...arguments);

    this._events = {
      'click .js-confirm': 'confirm',
      'click .js-cancel': 'cancel',
    };

    this.model = model;
    this.elem = elem;
    this.top = top;
    this.title = title;
    this.template = template;
    this.templateData = templateData;
    this.popOnClick = popOnClick;
    this.popDepth = popDepth;
    if (!this.template) {
      this.template = ConfirmTemplate;
    }
    if (!confirmBtnTestId) {
      confirmBtnTestId = '';
    }
    this.confirm = this._handleClick(fxConfirm);
    this.cancel = fxCancel ? this._handleClick(fxCancel) : function () {};
    this.data = {
      text,
      html,
      confirmText,
      confirmBtnClass,
      confirmBtnTestId,
      cancelText,
      ...(data || {}),
    };
    if (!this.templateData) {
      this.templateData = {};
    }
    this.maxWidth = maxWidth;

    this.delegateEvents(this._events);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static toggle(key: any, opts: any) {
    if (arguments.length === 1) {
      opts = key;
    } else {
      opts = makeOpts(key, opts);
    }

    return PopOver.toggle({
      elem: opts.elem,
      top: opts.top,
      alignRight: opts.alignRight,
      view: this,
      options: opts,
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static pushView(key: any, opts: any) {
    if (arguments.length === 1) {
      opts = key;
    } else {
      opts = makeOpts(key, opts);
    }

    return PopOver.pushView({
      view: this,
      options: opts,
    });
  }

  getViewTitle() {
    return this.title;
  }

  render() {
    this.$el.html(
      templates.fill(
        this.template,
        this.data,
        {},
        // @ts-expect-error TS(2339): Property 'member' does not exist on type '{ mustac... Remove this comment to see the full error message
        { member: templates.member },
      ),
    );
    setTimeout((e) => {
      this.$el.find('.js-confirm').focus();
      return 0;
    });
    return this;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  _handleClick(fx: any) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return (e: any) => {
      stopPropagationAndPreventDefault(e);

      if (this.popOnClick) {
        PopOver.popView(this.popDepth);
      } else {
        PopOver.hide();
      }

      if (typeof fx === 'function') {
        fx(e);
      }
      this.remove();
      return false;
    };
  }
}

export { Confirm };
