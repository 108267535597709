import { useContextSelector } from 'use-context-selector';

import type { BoardPowerUpsContextValue } from './BoardPowerUpsContext';
import { BoardPowerUpsContext } from './BoardPowerUpsContext';

/**
 * Access the BoardPowerUpsContext with a context selector to access a specific
 * nested value. This allows consumers to only subscribe to updates on the
 * selected value.
 *
 * @example
 * const customPowerUps = useBoardPowerUpsContext(
 *   useCallback(({ allPowerUps }) => {
 *     return allPowerUps.filter(
 *       (powerUp) => powerUp.public === false && !!powerUp.iframeConnectorUrl,
 *     );
 *   }, []),
 * );
 */
export const useBoardPowerUpsContext = <T>(
  selector: (value: BoardPowerUpsContextValue) => T,
) => useContextSelector(BoardPowerUpsContext, selector);
