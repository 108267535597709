import type { FunctionComponent } from 'react';
import { Suspense } from 'react';

import { useLazyComponent } from '@trello/use-lazy-component';

interface TeamOnboardingPupPopoverProps {
  workspaceId: string;
  boardId: string;
  onShowPowerUps(): void;
  renderTimeout?: number;
}

export const LazyTeamOnboardingPupPopover: FunctionComponent<
  TeamOnboardingPupPopoverProps
> = (props) => {
  const TeamOnboardingPupPopover = useLazyComponent(
    () =>
      import(
        /* webpackChunkName: "team-onboarding-pup-popover" */ './TeamOnboardingPupPopover'
      ),
    { namedImport: 'TeamOnboardingPupPopover' },
  );

  return (
    <Suspense fallback={null}>
      <TeamOnboardingPupPopover {...props} />
    </Suspense>
  );
};
