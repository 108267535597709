/* eslint-disable
    eqeqeq,
    */
// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS206: Consider reworking classes to avoid initClass
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */

import { Analytics, tracingCallback } from '@trello/atlassian-analytics';
import type { Disposer } from '@trello/component-wrapper';
import { renderComponent } from '@trello/component-wrapper';
// eslint-disable-next-line no-restricted-imports
import $ from '@trello/jquery';
import { linksFromMarkdown, markdown } from '@trello/markdown';

import { ActionView, events } from 'app/scripts/views/action/ActionView';
import { AttachLinksView as CardAttachLinksView } from 'app/scripts/views/card/AttachLinksView';
import { AttachmentViewer } from 'app/scripts/views/internal/AttachmentViewer';
import { Confirm } from 'app/scripts/views/lib/Confirm';
import { Layout } from 'app/scripts/views/lib/Layout';
import { PopOver } from 'app/scripts/views/lib/PopOver';
import { editComment, LazyEditComment } from 'app/src/components/Comments';
import { stopPropagationAndPreventDefault } from 'app/src/stopPropagationAndPreventDefault';

interface CommentActionView {
  maxLength: number;
  preventSubmit?: (isTooLong: boolean, isEmpty: boolean) => void;
  unmountEditCommentComponent?: Disposer;
}

class CommentActionView extends ActionView {
  static initClass() {
    this.prototype.events = {
      ...events,
      // @ts-expect-error TS(2322): Type '{ 'click .js-confirm-delete-action': string;... Remove this comment to see the full error message
      'click .js-confirm-delete-action': 'confirmDeleteAction',
      'click .js-edit-action': 'editAction',

      'click .js-attach-link': 'attachLink',
      'click .js-comment img': 'handleImageClick',
    };
  }

  remove() {
    this.unmountEditCommentComponent?.();
    return super.remove();
  }

  potentialAttachments() {
    const card = this.model.getCard();
    const links = linksFromMarkdown(
      this.model.get('data').text,
      markdown.comments,
    );

    return links.filter(({ url }) => {
      // Don't include URLs that are not allowed by the enterprise
      if (card.attachmentUrlRestricted(url)) {
        return false;
      }

      // Don't include things that have already been attached
      return !card.attachmentList.any(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (attachment: any) => attachment.get('url') === url,
      );
    });
  }

  getContext() {
    // @ts-expect-error TS(2556): A spread argument must either have a tuple type or... Remove this comment to see the full error message
    const data = super.getContext(...arguments);
    const card = this.model.getCard();
    // It's possible that comment is for a card that isn't currently cached, in
    // which case we can't attach a link to it
    data.canAttachLink =
      // eslint-disable-next-line @typescript-eslint/prefer-optional-chain
      card != null && card.editable() && this.potentialAttachments().length > 0;
    return data;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  confirmDeleteAction(e: any) {
    stopPropagationAndPreventDefault(e);

    Confirm.toggle('delete comment', {
      elem: $(e.target).closest('.js-confirm-delete-action')[0],
      model: this.model,
      confirmBtnClass: 'nch-button nch-button--danger',
      fxConfirm: () => {
        const traceId = Analytics.startTask({
          taskName: 'delete-comment',
          source: 'cardDetailScreen',
        });
        return this.model.destroyWithTracing(
          {
            traceId,
          },
          tracingCallback(
            {
              taskName: 'delete-comment',
              traceId,
              source: 'cardDetailScreen',
            },
            (err) => {
              if (!err) {
                Analytics.sendTrackEvent({
                  action: 'deleted',
                  actionSubject: 'comment',
                  source: 'cardDetailScreen',
                  attributes: {
                    taskId: traceId,
                  },
                });
              }
            },
          ),
        );
      },
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  editAction(e: any) {
    stopPropagationAndPreventDefault(e);
    if (this.$el.hasClass('is-editing')) {
      return;
    }
    this.$el.addClass('is-editing');

    this.renderEditCommentComponent();
    editComment(this.model.id);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  saveEdit(newValue: any) {
    // So that we don't have to wait for the roundtrip to display the
    // new text, which would be very scary for users.
    this.model.get('display').entities.comment.text = newValue;

    const traceId = Analytics.startTask({
      taskName: 'edit-comment',
      source: 'cardDetailScreen',
    });
    const data = {
      text: newValue,
      traceId,
    };
    this.model.update(
      data,
      tracingCallback(
        {
          taskName: 'edit-comment',
          source: 'cardDetailScreen',
          traceId,
        },
        (err, response) => {
          if (!err && response) {
            Analytics.sendTrackEvent({
              action: 'updated',
              actionSubject: 'comment',
              source: 'cardDetailScreen',
              containers: {
                // @ts-expect-error TS(2571): Object is of type 'unknown'.
                card: { id: response.data?.card?.id },
                // @ts-expect-error TS(2571): Object is of type 'unknown'.
                board: { id: response.data?.board?.id },
                // @ts-expect-error TS(2571): Object is of type 'unknown'.
                list: { id: response.data?.list?.id },
              },
              attributes: {
                taskId: traceId,
                isEditorEnabled: true,
              },
            });
          }
        },
      ),
    );
    Layout.cancelEdit(this.$el);
  }

  showError() {}

  cancelEdit() {
    Layout.cancelEdit(this.$el);
  }

  getComment() {
    return this.model.get('data').text;
  }

  getCard() {
    return this.model.getCard();
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  attachLink(e: any) {
    stopPropagationAndPreventDefault(e);

    const links = this.potentialAttachments();
    return PopOver.toggle({
      elem: $(e.currentTarget),
      view: new CardAttachLinksView({
        model: this.getCard(),
        links,
      }),
    });
  }

  renderEditCommentComponent() {
    const reactRoot = this.el.querySelector('.js-edit-comment-react-root');
    // Because of Backbone hijinks, this can be rendered multiple times, but it
    // doesn't need to be, since the React component is kept fresh.
    if (reactRoot && !reactRoot.querySelector('.js-react-root')) {
      this.unmountEditCommentComponent = renderComponent(
        <LazyEditComment
          boardId={this.model.getBoard()?.id}
          cardId={this.model.getCard()?.id}
          commentId={this.model.id}
          enterpriseId={this.model.getBoard()?.get('idEnterprise')}
          workspaceId={this.model.getBoard()?.get('idOrganization')}
          getComment={this.getComment.bind(this)}
          saveEdit={this.saveEdit.bind(this)}
          cancelEdit={this.cancelEdit.bind(this)}
        />,
        reactRoot,
      );
    }
  }

  handleImageClick(e: MouseEvent) {
    const img = $(e.target as HTMLElement).closest('img');
    const url = img.attr('src');
    const card = this.model.getCard();

    const attachment = card.attachmentList.where({ url })[0];
    if (!AttachmentViewer.isActive() && attachment) {
      stopPropagationAndPreventDefault(e);
      AttachmentViewer.show({
        model: card,
        attachmentModel: attachment,
      });
      Analytics.sendScreenEvent({
        name: 'attachmentViewerModal',
        attributes: {
          type: attachment.getType(),
        },
      });
      return;
    }
  }
}
CommentActionView.initClass();

export { CommentActionView };
