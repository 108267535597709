import { Entitlements } from '@trello/entitlements';
import type { Board } from '@trello/model-types';

/**
 * We consider it to be an enterprise board if one of the
 * following is true:
   - it has an `idEnterprise` set
   - it belongs to an organization that belongs to an
 * enterprise. We also check if it's a real enterprise,
 * because we still have BCPO teams in the enterprises collection
 * @returns Boolean
 */
export const isEnterpriseBoard = (
  board: Pick<Board, 'id'> & {
    idEnterprise?: string | null;
    organization?:
      | (Pick<Board['organization'], 'id' | 'offering'> & {
          idEnterprise?: string | null;
        })
      | null;
  },
): boolean => {
  const organization = board.organization;

  if (!organization) {
    return false;
  }

  if (!board.idEnterprise) {
    return false;
  }

  return (
    !!organization.idEnterprise &&
    Entitlements.isEnterprise(organization.offering)
  );
};
