// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NZqjIt9yPtPyOm {
  color: var(--dynamic-text);
}
.YUhUr94gD6NmuC {
  display: block;
  max-width: 600px;
  margin: 75px auto;
  text-align: center;
}
.YUhUr94gD6NmuC h1 {
  margin-bottom: 12px;
  font-size: 26px;
}
.YUhUr94gD6NmuC p {
  font-size: 18px;
  line-height: 22px;
}
.h5j3acnah9bgoO {
  margin: 36px 0px 36px 0px;
}
`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"quiet": `NZqjIt9yPtPyOm`,
	"bigMessage": `YUhUr94gD6NmuC`,
	"tryAgainButton": `h5j3acnah9bgoO`
};
export default ___CSS_LOADER_EXPORT___;
