// Traits currently populated by the Trait GASv3 builder:

// https://data-portal.internal.atlassian.com/targeting/traits/4f93da14-dc72-4066-98ae-d163ff5f3c20
export const TRELLO_UI_DISCOVERY_AD_DISPLAYED_LATEST_AD_EXPERIENCE =
  'trello_ui_discoveryad_displayed_latest_ad_experience';

// https://data-portal.internal.atlassian.com/targeting/traits/0c0c146b-a917-4112-9df6-5f206ed5b9a6
export const TRELLO_UI_DISCOVERY_AD_DISPLAYED_LATEST_AD =
  'trello_ui_discoveryad_displayed_latest_ad';

// https://data-portal.internal.atlassian.com/targeting/traits/c8569daa-0856-4701-a309-62c776ef858f
export const TRELLO_UI_DISCOVERY_AD_DISPLAYED_LATEST_AD_FIRST_VIEWED =
  'trello_ui_discoveryad_displayed_latest_ad_first_viewed';

// https://data-portal.internal.atlassian.com/targeting/traits/99189c35-0ddc-493d-93a7-31aad70592de
export const TRELLO_AD_SEQUENCER_FIRST_ACTIVATED_DATE =
  'trello_ad_sequencer_first_activated_date';

// https://data-portal.internal.atlassian.com/targeting/traits/99189c35-0ddc-493d-93a7-31aad70592de
export const TRELLO_AD_SEQUENCER_USER_LAST_ACTIVE_DATE =
  'trello_ad_sequencer_user_last_active_date';

// https://data-portal.internal.atlassian.com/targeting/traits/b7eb2842-7cce-47cd-bb7a-ff87a647816e
export const TRELLO_USER_ACTIVE_DAYS_SINCE_AD_SEQUENCER_FIRST_ACTIVATED =
  'trello_user_active_days_since_ad_sequencer_first_activated';

// https://data-portal.internal.atlassian.com/targeting/traits/a1b9c7b1-9351-4291-b7f0-06931ddde664
export const TRELLO_JIRA_CONF_CO_USERS = 'trello_jira_conf_co_users';

export type TraitName =
  | typeof TRELLO_UI_DISCOVERY_AD_DISPLAYED_LATEST_AD_EXPERIENCE
  | typeof TRELLO_UI_DISCOVERY_AD_DISPLAYED_LATEST_AD
  | typeof TRELLO_UI_DISCOVERY_AD_DISPLAYED_LATEST_AD_FIRST_VIEWED
  | typeof TRELLO_AD_SEQUENCER_FIRST_ACTIVATED_DATE
  | typeof TRELLO_AD_SEQUENCER_USER_LAST_ACTIVE_DATE
  | typeof TRELLO_USER_ACTIVE_DAYS_SINCE_AD_SEQUENCER_FIRST_ACTIVATED
  | typeof TRELLO_JIRA_CONF_CO_USERS;

export type Traits = Record<TraitName | string, string | boolean | number>;
