// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NauH5zhD2hZvpP {
  position: relative;
  box-sizing: border-box;
  width: 768px;
  overflow: hidden;
  border-radius: 8px;
  background-color: var(--ds-surface-overlay, #ffffff);
}
.NauH5zhD2hZvpP:focus {
  outline: 0;
}
.NauH5zhD2hZvpP.DVtxDmkCMy5qRd {
  width: 768px;
}
.NauH5zhD2hZvpP.kIQJDf7Zy2oBaX {
  width: 584px;
}
.NauH5zhD2hZvpP.d3UggRlexL2Ogg {
  width: 500px;
}
@media print {
  .NauH5zhD2hZvpP {
    width: 100vw !important;
    border-radius: 0px !important;
  }
}
.Y9J4BArcarEAX9 {
  border: none;
  background-color: transparent;
  box-shadow: none;
  margin: 0 8px auto;
  border-radius: 50%;
}
.Y9J4BArcarEAX9:hover {
  background-color: var(--ds-background-neutral-hovered, #091e4224);
  box-shadow: none;
}
.Y9J4BArcarEAX9:active {
  background-color: var(--ds-background-neutral-pressed, #091e424f);
  box-shadow: none;
}
.Y9J4BArcarEAX9:focus {
  outline: var(--ds-border-focused, #388bff) auto 2px;
}
.mac .Y9J4BArcarEAX9:focus {
  outline-color: -webkit-focus-ring-color;
}
.Y9J4BArcarEAX9:focus .mac.firefox {
  outline-color: AccentColor;
}
.Y9J4BArcarEAX9 .BXekJYFkPyovJz {
  margin: 0;
}
@media only screen and (max-width: 750px) {
  .NauH5zhD2hZvpP {
    width: auto;
    margin: 0 8px;
  }
  .NauH5zhD2hZvpP.DVtxDmkCMy5qRd,
  .NauH5zhD2hZvpP.kIQJDf7Zy2oBaX,
  .NauH5zhD2hZvpP.d3UggRlexL2Ogg {
    width: auto;
  }
  .NauH5zhD2hZvpP .BXekJYFkPyovJz {
    margin: 0 12px 12px 12px;
  }
}
`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"dialog": `NauH5zhD2hZvpP`,
	"large": `DVtxDmkCMy5qRd`,
	"medium": `kIQJDf7Zy2oBaX`,
	"small": `d3UggRlexL2Ogg`,
	"closeButton": `Y9J4BArcarEAX9`,
	"body": `BXekJYFkPyovJz`
};
export default ___CSS_LOADER_EXPORT___;
