import type { Board, Member, Organization } from '@trello/model-types';

import { isAdminOfBoard } from './isAdminOfBoard';

export const canDeleteBoard = (
  member: Pick<
    Member,
    'id' | 'memberType' | 'idEnterprisesAdmin' | 'idPremOrgsAdmin'
  >,
  board: Pick<Board, 'id'> & {
    memberships: Pick<
      Board['memberships'][number],
      'id' | 'idMember' | 'memberType' | 'deactivated' | 'unconfirmed'
    >[];
    organization?:
      | (Pick<Board['organization'], 'id' | 'offering'> & {
          memberships: Pick<
            Board['organization']['memberships'][number],
            'idMember' | 'memberType' | 'deactivated' | 'unconfirmed'
          >[];
        })
      | null;
    prefs?: Pick<Board['prefs'], 'permissionLevel'> | null;
  },
  workspace?: {
    prefs?: {
      boardDeleteRestrict?: Organization['prefs']['boardDeleteRestrict'] | null;
    } | null;
    memberships?:
      | Pick<Organization['memberships'][number], 'idMember' | 'memberType'>[]
      | null;
  },
): boolean => {
  if (!board) {
    return false;
  }

  const isAdmin = isAdminOfBoard(member, board);
  if (!isAdmin) {
    return false;
  }

  if (!workspace) {
    return true;
  }

  const workspaceDeleteRestrictions = workspace?.prefs?.boardDeleteRestrict;
  if (!workspaceDeleteRestrictions) {
    return true;
  }

  const boardPermission = board?.prefs?.permissionLevel;
  if (!boardPermission) {
    return true;
  }

  const workspaceDeleteRestriction =
    workspaceDeleteRestrictions[boardPermission];

  switch (workspaceDeleteRestriction) {
    case 'org':
      return true;

    case 'admin': {
      const isWorkspaceAdmin = (workspace?.memberships || []).some(
        ({ idMember, memberType }) => {
          return idMember === member.id && memberType === 'admin';
        },
      );

      if (isWorkspaceAdmin) {
        return true;
      }

      return false;
    }

    default:
      return false;
  }
};
