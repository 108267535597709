import { useCallback, useMemo } from 'react';

import { useCrossFlow } from '@atlassiansox/cross-flow-support/trello';
import type { TouchpointSourceType } from '@trello/ad-sequencer';
import { Analytics } from '@trello/atlassian-analytics';
import { useMemberId } from '@trello/authentication';
import { useFeatureGate } from '@trello/feature-gate-client';
import { useHasValidAaSession } from '@trello/heartbeat/session';

import {
  type CtaFunnelNewUserNudgesExperimentVariations,
  useCrossFlowForCtaFunnelNewUserNudges,
} from './experimental/useCrossFlowForCtaFunnelNewUserNudges';
import {
  type CrossFlowForSporkFreePpmExperimentVariations,
  useCrossFlowForFreePpmSpork,
} from './experimental/useCrossFlowForFreePpmSpork';
import {
  type GraduatedUsersWithAdSequencerExperimentVariations,
  useCrossFlowForGraduatedUsersWithAdSequencer,
} from './experimental/useCrossFlowForGraduatedUsersWithAdSequencer';
import {
  type CrossFlowForJiraTemplateInTrelloTemplateGalleryNonPpmExperimentVariations,
  useCrossFlowForJiraTemplateInTrelloTemplateGalleryNonPpm,
} from './experimental/useCrossFlowForJiraTemplateInTrelloTemplateGalleryNonPpm';
import {
  type CrossFlowForJiraTemplateInTrelloTemplateGalleryPpmExperimentVariations,
  useCrossFlowForJiraTemplateInTrelloTemplateGalleryPpm,
} from './experimental/useCrossFlowForJiraTemplateInTrelloTemplateGalleryPpm';
import {
  type CrossFlowForSporkMultiplayerExperimentVariations,
  useCrossFlowForMultiplayerSpork,
} from './experimental/useCrossFlowForMultiplayerSpork';
import {
  type NewUserCombinationsExperimentVariations,
  useCrossFlowForNewUserCombinations,
} from './experimental/useCrossFlowForNewUserCombinations';
import {
  type CrossFlowForSporkNewUsersExperimentVariations,
  useCrossFlowForNewUsersSpork,
} from './experimental/useCrossFlowForNewUsersSpork';
import {
  type CrossFlowForSporkPaidUsersRoundThreeExperimentVariations,
  useCrossFlowForPaidUsersRoundThree,
} from './experimental/useCrossFlowForPaidUsersRoundThree';
import {
  type CrossFlowForSporkPaidUsersExperimentVariations,
  useCrossFlowForPaidUsersSpork,
} from './experimental/useCrossFlowForPaidUsersSpork';
import {
  type CrossFlowForSporkSinglePlayerExperimentVariations,
  useCrossFlowForSinglePlayerSpork,
} from './experimental/useCrossFlowForSinglePlayerSpork';
import {
  useCrossFlowForWelcomeScreenTaskSelection,
  type WelcomeScreenTaskSelectionExperimentVariations,
} from './experimental/useCrossFlowForWelcomeScreenTaskSelection';
import {
  useCrossFlowForWelcomeScreenTaskSelectionExplanatoryUseCase,
  type WelcomeScreenTaskSelectionExplanatoryUseCaseExperimentVariations,
} from './experimental/useCrossFlowForWelcomeScreenTaskSelectionExplanatoryUseCase';
import { useCrossFlowMemberFragment } from './CrossFlowMemberFragment.generated';
import {
  type TrelloCrossFlow,
  trelloCrossFlowOpen,
  type TrelloCrossFlowOptions,
} from './TrelloCrossFlow';

/**
 * Union type of all possible variations for crossFlow experiments
 */
export type ExperimentVariations =
  | TrelloCrossFlow<CtaFunnelNewUserNudgesExperimentVariations>
  | TrelloCrossFlow<NewUserCombinationsExperimentVariations>
  | TrelloCrossFlow<WelcomeScreenTaskSelectionExplanatoryUseCaseExperimentVariations>
  | TrelloCrossFlow<CrossFlowForSporkPaidUsersExperimentVariations>
  | TrelloCrossFlow<CrossFlowForSporkPaidUsersRoundThreeExperimentVariations>
  | TrelloCrossFlow<CrossFlowForSporkSinglePlayerExperimentVariations>
  | TrelloCrossFlow<CrossFlowForSporkMultiplayerExperimentVariations>
  | TrelloCrossFlow<CrossFlowForSporkFreePpmExperimentVariations>
  | TrelloCrossFlow<CrossFlowForSporkNewUsersExperimentVariations>
  | TrelloCrossFlow<WelcomeScreenTaskSelectionExperimentVariations>
  | TrelloCrossFlow<CrossFlowForJiraTemplateInTrelloTemplateGalleryPpmExperimentVariations>
  | TrelloCrossFlow<CrossFlowForJiraTemplateInTrelloTemplateGalleryNonPpmExperimentVariations>
  | TrelloCrossFlow<GraduatedUsersWithAdSequencerExperimentVariations>;

export const useTrelloCrossFlow = (
  source: TouchpointSourceType,
  {
    workspaceId,
    boardId,
    isNewTrelloUser,
    jiraTemplateId,
    shouldSkipMarketingPage,
  }: TrelloCrossFlowOptions = {},
): ExperimentVariations => {
  const crossFlow = useCrossFlow();

  const [hasValidAaSession] = useHasValidAaSession();

  const analyticsContainers = useMemo(
    () => ({
      organization: {
        id: workspaceId,
      },
      board: {
        id: boardId,
      },
    }),
    [workspaceId, boardId],
  );

  const { value: doSendOperationalEvent } = useFeatureGate(
    'trello_xf_experiment_analytics_toggle',
  );

  const sendOperationalEvent = useCallback(
    (operationalEventFn: () => void) => {
      if (doSendOperationalEvent) {
        operationalEventFn();
      }
    },
    [doSendOperationalEvent],
  );

  const crossFlowForCtaFunnelNewUserNudgesExperiment =
    useCrossFlowForCtaFunnelNewUserNudges({
      source,
      workspaceId,
      boardId,
      hasValidAaSession,
    });

  const crossFlowForNewUserCombinationsExperiment =
    useCrossFlowForNewUserCombinations({
      source,
      workspaceId,
      boardId,
      hasValidAaSession,
      jiraTemplateId,
    });

  const crossFlowWelcomeScreenTaskSelectionExperiment =
    useCrossFlowForWelcomeScreenTaskSelection({
      source,
      hasValidAaSession,
    });

  const crossFlowWelcomeScreenTaskSelectionExplanatoryUseCaseExperiment =
    useCrossFlowForWelcomeScreenTaskSelectionExplanatoryUseCase({
      source,
      hasValidAaSession,
    });

  const crossFlowForPaidUsersSpork = useCrossFlowForPaidUsersSpork({
    source,
    workspaceId,
    boardId,
    hasValidAaSession,
  });

  const crossFlowForPaidUsersRoundThree = useCrossFlowForPaidUsersRoundThree({
    source,
    workspaceId,
    boardId,
    hasValidAaSession,
  });

  const crossFlowForSinglePlayerSpork = useCrossFlowForSinglePlayerSpork({
    source,
    workspaceId,
    boardId,
    hasValidAaSession,
  });

  const crossFlowForNewUsersSpork = useCrossFlowForNewUsersSpork({
    source,
    workspaceId,
    boardId,
    hasValidAaSession,
  });

  const crossFlowForMultiplayerSpork = useCrossFlowForMultiplayerSpork({
    source,
    workspaceId,
    boardId,
    hasValidAaSession,
  });

  const crossFlowForFreePpmSpork = useCrossFlowForFreePpmSpork({
    source,
    workspaceId,
    hasValidAaSession,
  });

  const crossFlowForJiraTemplateInTrelloTemplateGalleryPpm =
    useCrossFlowForJiraTemplateInTrelloTemplateGalleryPpm({
      source,
      workspaceId,
      hasValidAaSession,
      jiraTemplateId,
    });

  const crossFlowForJiraTemplateInTrelloTemplateGalleryNonPpm =
    useCrossFlowForJiraTemplateInTrelloTemplateGalleryNonPpm({
      source,
      workspaceId,
      hasValidAaSession,
      jiraTemplateId,
    });

  const crossFlowForGraduatedUsersWithAdSequencer =
    useCrossFlowForGraduatedUsersWithAdSequencer({
      source,
      workspaceId,
      boardId,
      hasValidAaSession,
    });

  const memberId = useMemberId();
  const { data: member } = useCrossFlowMemberFragment({
    from: { id: memberId },
    optimistic: false,
  });

  const trelloCrossFlow = {
    open: trelloCrossFlowOpen(crossFlow, source, [], hasValidAaSession, {
      workspaceId,
      boardId,
      isNewTrelloUser,
      shouldSkipMarketingPage,
    }),
    getIsTargeted: () =>
      crossFlowForCtaFunnelNewUserNudgesExperiment.getIsTargeted() ||
      crossFlowForNewUserCombinationsExperiment.getIsTargeted() ||
      crossFlowWelcomeScreenTaskSelectionExperiment.getIsTargeted() ||
      crossFlowWelcomeScreenTaskSelectionExplanatoryUseCaseExperiment.getIsTargeted() ||
      crossFlowForPaidUsersRoundThree.getIsTargeted() ||
      crossFlowForJiraTemplateInTrelloTemplateGalleryPpm.getIsTargeted() ||
      crossFlowForJiraTemplateInTrelloTemplateGalleryNonPpm.getIsTargeted() ||
      crossFlowForPaidUsersSpork.getIsTargeted() ||
      crossFlowForFreePpmSpork.getIsTargeted() ||
      crossFlowForSinglePlayerSpork.getIsTargeted() ||
      crossFlowForNewUsersSpork.getIsTargeted() ||
      crossFlowForMultiplayerSpork.getIsTargeted() ||
      crossFlowForGraduatedUsersWithAdSequencer.getIsTargeted(), // Leave last!
    getExperimentCohort: () => null,
  };

  // Return early if crossFlow is disabled (missing context provider, incompatible version...)
  if (!crossFlow.isEnabled) {
    return trelloCrossFlow;
  }

  // Check if the current source is eligible for the the cta funnel new user nudges experiment
  if (crossFlowForCtaFunnelNewUserNudgesExperiment.isEnrolled) {
    sendOperationalEvent(() => {
      Analytics.sendOperationalEvent({
        action: 'enrolled',
        actionSubject: 'crossFlowExperiment',
        source,
        containers: analyticsContainers,
        attributes: {
          experiment: 'crossFlowForCtaFunnelNewUserNudgesExperiment',
        },
      });
    });
    return crossFlowForCtaFunnelNewUserNudgesExperiment;
  }

  // check if the current source is eligible for the new user combinations experiment
  if (crossFlowForNewUserCombinationsExperiment.isEnrolled) {
    sendOperationalEvent(() => {
      Analytics.sendOperationalEvent({
        action: 'enrolled',
        actionSubject: 'crossFlowExperiment',
        source,
        containers: analyticsContainers,
        attributes: {
          experiment: 'crossFlowForNewUserCombinationsExperiment',
        },
      });
    });
    return crossFlowForNewUserCombinationsExperiment;
  }

  // Check if the current source is eligible for the madshot v2 experiment
  // This experiment shows a questionnaire during the user onboarding flow, so the user might be unverified at this stage.
  if (crossFlowWelcomeScreenTaskSelectionExperiment.isEnrolled) {
    sendOperationalEvent(() => {
      Analytics.sendOperationalEvent({
        action: 'enrolled',
        actionSubject: 'crossFlowExperiment',
        source,
        containers: analyticsContainers,
        attributes: {
          experiment: 'crossFlowForWelcomeScreenTaskSelectionExperiment',
        },
      });
    });
    return crossFlowWelcomeScreenTaskSelectionExperiment;
  }

  // Check if the current source is eligible for the explanatory use-case selection experiment
  // This experiment shows a questionnaire during the user onboarding flow, so the user might be unverified at this stage.
  if (
    crossFlowWelcomeScreenTaskSelectionExplanatoryUseCaseExperiment.isEnrolled
  ) {
    sendOperationalEvent(() => {
      Analytics.sendOperationalEvent({
        action: 'enrolled',
        actionSubject: 'crossFlowExperiment',
        source,
        containers: analyticsContainers,
        attributes: {
          experiment:
            'crossFlowForWelcomeScreenTaskSelectionExplanatoryUseCaseExperiment',
        },
      });
    });
    return crossFlowWelcomeScreenTaskSelectionExplanatoryUseCaseExperiment;
  }

  // Check if the current source is eligible for the paid users round 3 experiment
  if (crossFlowForPaidUsersRoundThree.isEnrolled) {
    sendOperationalEvent(() => {
      Analytics.sendOperationalEvent({
        action: 'enrolled',
        actionSubject: 'crossFlowExperiment',
        source,
        containers: analyticsContainers,
        attributes: {
          experiment: 'crossFlowForPaidUsersRoundThree',
        },
      });
    });
    return crossFlowForPaidUsersRoundThree;
  }

  if (crossFlowForJiraTemplateInTrelloTemplateGalleryPpm.isEnrolled) {
    sendOperationalEvent(() => {
      Analytics.sendOperationalEvent({
        action: 'enrolled',
        actionSubject: 'crossFlowExperiment',
        source,
        containers: analyticsContainers,
        attributes: {
          experiment: 'crossFlowForJiraTemplateInTrelloTemplateGalleryPpm',
        },
      });
    });
    return crossFlowForJiraTemplateInTrelloTemplateGalleryPpm;
  }

  if (crossFlowForJiraTemplateInTrelloTemplateGalleryNonPpm.isEnrolled) {
    sendOperationalEvent(() => {
      Analytics.sendOperationalEvent({
        action: 'enrolled',
        actionSubject: 'crossFlowExperiment',
        source,
        containers: analyticsContainers,
        attributes: {
          experiment: 'crossFlowForJiraTemplateInTrelloTemplateGalleryNonPpm',
        },
      });
    });
    return crossFlowForJiraTemplateInTrelloTemplateGalleryNonPpm;
  }

  // Check if the current source is eligible for the paid users spork experiment
  if (crossFlowForPaidUsersSpork.isEnrolled) {
    sendOperationalEvent(() => {
      Analytics.sendOperationalEvent({
        action: 'enrolled',
        actionSubject: 'crossFlowExperiment',
        source,
        containers: analyticsContainers,
        attributes: {
          experiment: 'crossFlowForPaidUsersSpork',
        },
      });
    });
    return crossFlowForPaidUsersSpork;
  }

  // Check if the current source is eligible for the free ppm spork experiment
  if (crossFlowForFreePpmSpork.isEnrolled) {
    sendOperationalEvent(() => {
      Analytics.sendOperationalEvent({
        action: 'enrolled',
        actionSubject: 'crossFlowExperiment',
        source,
        containers: analyticsContainers,
        attributes: {
          experiment: 'crossFlowForFreePpmSpork',
        },
      });
    });
    return crossFlowForFreePpmSpork;
  }

  // Check if the current source is eligible for the singleplayer spork experiment
  if (crossFlowForSinglePlayerSpork.isEnrolled) {
    sendOperationalEvent(() => {
      Analytics.sendOperationalEvent({
        action: 'enrolled',
        actionSubject: 'crossFlowExperiment',
        source,
        containers: analyticsContainers,
        attributes: {
          experiment: 'crossFlowForSinglePlayerSpork',
        },
      });
    });
    return crossFlowForSinglePlayerSpork;
  }

  // Check if the current source is eligible for the new users spork experiment
  if (crossFlowForNewUsersSpork.isEnrolled) {
    sendOperationalEvent(() => {
      Analytics.sendOperationalEvent({
        action: 'enrolled',
        actionSubject: 'crossFlowExperiment',
        source,
        containers: analyticsContainers,
        attributes: {
          experiment: 'crossFlowForNewUsersSpork',
        },
      });
    });
    return crossFlowForNewUsersSpork;
  }
  // Check if the current source is eligible for the multiplayer spork experiment
  if (crossFlowForMultiplayerSpork.isEnrolled) {
    sendOperationalEvent(() => {
      Analytics.sendOperationalEvent({
        action: 'enrolled',
        actionSubject: 'crossFlowExperiment',
        source,
        containers: analyticsContainers,
        attributes: {
          experiment: 'crossFlowForMultiplayerSpork',
        },
      });
    });
    return crossFlowForMultiplayerSpork;
  }

  // Check if the current user is eligible for the ad sequencer experiment. Leave this experiment last.
  if (crossFlowForGraduatedUsersWithAdSequencer.isEnrolled) {
    sendOperationalEvent(() => {
      Analytics.sendOperationalEvent({
        action: 'enrolled',
        actionSubject: 'crossFlowExperiment',
        source,
        containers: analyticsContainers,
        attributes: {
          experiment: 'crossFlowForGraduatedUsersWithAdSequencer',
        },
      });
    });
    return crossFlowForGraduatedUsersWithAdSequencer;
  }

  // Disable crossFlow for users who have not yet confirmed their email address
  if (!member?.confirmed) {
    return trelloCrossFlow;
  }

  return trelloCrossFlow;
};
