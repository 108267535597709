import { TrelloMarkdownWrapper } from './TrelloMarkdownWrapper';

export type MarkdownContentType =
  | 'comment'
  | 'description'
  | 'checkitems'
  | 'bio';

// eslint-disable-next-line @trello/no-module-logic
export const markdown = new TrelloMarkdownWrapper();
