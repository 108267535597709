import type { ReactNode } from 'react';
import { Component } from 'react';
import type { Sheet as EmojiMartSheet } from '@emoji-mart/data';

import { Spinner } from '@trello/nachos/spinner';

import { EmojiProvider } from './EmojiProvider';

export interface EmojiProviderProps {
  children: (
    sheet: EmojiMartSheet,
    backgroundImageFn: typeof EmojiProvider.getSpritesheetsUrl | undefined,
  ) => ReactNode;
  isSmallSpinner: boolean;
}

interface EmojiProviderState {
  sheet: EmojiMartSheet | null;
}

export class EmojiProviderComponent extends Component<
  EmojiProviderProps,
  EmojiProviderState
> {
  static defaultProps = {
    isSmallSpinner: false,
  };

  // @ts-expect-error
  mounted: boolean;

  constructor(props: EmojiProviderProps) {
    super(props);

    this.state = {
      sheet: EmojiProvider.getSheetDataSync(),
    };
  }

  componentDidMount() {
    this.mounted = true;
    if (!this.state.sheet) {
      EmojiProvider.getSheetData().then((sheet) => {
        if (this.mounted && sheet) {
          return this.setState({ sheet });
        }
      });
    }
  }

  componentWillUnmount() {
    this.mounted = false;
  }

  getBackgroundImageFn() {
    const spriteSheets = EmojiProvider.getSpritesheetsSync();
    if (spriteSheets) {
      return function () {
        return EmojiProvider.getSpritesheetsUrl.apply(EmojiProvider, [
          'twitter',
          64, // currently, the picker uses a large emoji as the preview
        ]);
      };
    }

    return undefined;
  }

  render() {
    const { children, isSmallSpinner } = this.props;
    const { sheet } = this.state;

    if (sheet) {
      return children(sheet, this.getBackgroundImageFn());
    } else {
      return (
        <div>
          <Spinner small={isSmallSpinner} centered={!isSmallSpinner} />
        </div>
      );
    }
  }
}
