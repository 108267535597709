import { useMemo } from 'react';

import { PremiumFeatures } from '@trello/entitlements';

import { useBoardPremiumFeaturesFragment } from 'app/src/components/FileSizeAlert/BoardPremiumFeaturesFragment.generated';

export const useIsCollapsibleListsEnabled = (
  boardId: string | undefined,
): boolean => {
  const { data } = useBoardPremiumFeaturesFragment({ from: { id: boardId } });

  return useMemo(
    () => !!data?.premiumFeatures?.includes(PremiumFeatures.collapsibleLists),
    [data?.premiumFeatures],
  );
};
