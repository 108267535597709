import { type FunctionComponent, type PropsWithChildren, useMemo } from 'react';
import { createContext } from 'use-context-selector';

import { useMemberId } from '@trello/authentication';
import { canEdit, canRename } from '@trello/business-logic/board';
import { canComment } from '@trello/business-logic/notification';
import { useBoardId, useWorkspaceId } from '@trello/id-context';
import { useWorkspaceUserLimit } from '@trello/workspaces/user-limit';

import { useBoardPermissionsContextQuery } from './BoardPermissionsContextQuery.generated';

export interface BoardPermissionsContextValue {
  canEditBoard: boolean;
  canRenameBoard: boolean;
  canCommentOnBoard: boolean;
}

const defaultContextValue: BoardPermissionsContextValue = {
  canEditBoard: false,
  canRenameBoard: false,
  canCommentOnBoard: false,
};

export const BoardPermissionsContext =
  createContext<BoardPermissionsContextValue>(defaultContextValue);

export const BoardPermissionsContextProvider: FunctionComponent<
  PropsWithChildren<unknown>
> = ({ children }) => {
  const workspaceId = useWorkspaceId();
  const boardId = useBoardId();
  const memberId = useMemberId();

  let contextValue = defaultContextValue;

  const { isWorkspaceReadOnly } = useWorkspaceUserLimit({
    workspaceId,
  });

  const { data } = useBoardPermissionsContextQuery({
    variables: { boardId, memberId },
    waitOn: ['CurrentBoardInfo', 'MemberHeader'],
    fetchPolicy: 'cache-only',
  });

  const board = data?.board ?? null;
  const member = data?.member ?? null;
  const doesBoardAndMemberExist = !!board && !!member;

  const workspace = board?.organization ?? null;
  const enterprise = workspace?.enterprise ?? null;

  let canEditBoard = false;
  let canRenameBoard = false;
  let canCommentOnBoard = false;

  if (doesBoardAndMemberExist && !isWorkspaceReadOnly) {
    canEditBoard = canEdit(member, board, workspace, enterprise);
    canRenameBoard =
      !board.closed && canRename(member, board, workspace, enterprise);
    canCommentOnBoard = canComment(member, board);
  }

  contextValue = useMemo(
    () => ({ canEditBoard, canRenameBoard, canCommentOnBoard }),
    [canEditBoard, canRenameBoard, canCommentOnBoard],
  );

  // If not for the need to memoize the context value, we could have done this bail out sooner
  // TODO - m.f: revisit after React Compiler is added
  if (!doesBoardAndMemberExist) {
    contextValue = defaultContextValue;
  }

  return (
    <BoardPermissionsContext.Provider value={contextValue}>
      {children}
    </BoardPermissionsContext.Provider>
  );
};
