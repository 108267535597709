import { featureFlagClient } from '@trello/feature-flag-client';

const featureFlag = 'fep.native-current-board-full';
type featureFlagType = 'true' | 'false';
const defaultValue = 'true';

/**
 * Check if the feature flag `'fep.native-current-board-full'` is enabled.
 * @returns true if the feature flag is enabled, false otherwise.
 */
export const isNativeCurrentBoardInfoEnabled = () => {
  const flagValue = featureFlagClient.get<featureFlagType>(
    featureFlag,
    defaultValue,
  );
  return flagValue === 'true';
};

/**
 * Subscribe to updates to the `'fep.native-current-board-full'` feature flag, and run the callback when the flag changes.
 * @param callback - The function to run when the feature flag changes.
 * @returns A function to unsubscribe from the feature flag updates.
 */
export const subscribeToNativeCurrentBoardInfo = (
  callback: (current?: featureFlagType, previous?: featureFlagType) => void,
) => {
  featureFlagClient.on(featureFlag, defaultValue, callback);
  return () => featureFlagClient.off(featureFlag, callback);
};
