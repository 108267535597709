import type { FunctionComponent, MouseEvent } from 'react';
import { Suspense, useCallback, useEffect, useState } from 'react';
import { IntlProvider as PlatformIntlProvider } from 'react-intl';

import FabricAnalyticsListeners from '@atlaskit/analytics-listeners';
import { AnalyticsContext } from '@atlaskit/analytics-next';
import type { CardProps as SmartCardProps } from '@atlaskit/smart-card';
import { Analytics } from '@trello/atlassian-analytics';
import { ChunkLoadErrorBoundary } from '@trello/error-boundaries';
import { useLanguage } from '@trello/locale';
import type { UGCString } from '@trello/privacy';
import { isTrelloUrl } from '@trello/urls';
import { useLazyComponent } from '@trello/use-lazy-component';

import type { SmartCardClientResponse } from './SmartCardClient';
import { getSmartCardClient } from './SmartCardClient';
import type { SmartCardProviderProps } from './SmartCardProvider';
import { SmartCardProvider } from './SmartCardProvider';
import { useSmartCardLocale } from './useSmartCardLocale';
import { useSmartCardProviderRefreshKey } from './useSmartCardProviderRefreshKey';

interface AnalyticsContextDataType {
  attributes: {
    component?: string;
    definitionId?: string;
    accessType?: string | UGCString;
    cloudId?: string;
  };
}

interface Props {
  url: string;
  plainLink: () => JSX.Element;
  isFlexLinkCard?: boolean;
  showCover?: boolean;
  smartCardProps: SmartCardProps;
  onLinkResolved?: (resolved: SmartCardClientResponse) => void;
  onLinkUnresolved?: () => void;
  onSmartCardClick?: (e: MouseEvent) => void;
  shouldRender: boolean;
  analyticsContextData: AnalyticsContextDataType;
  handleTrelloLinkClick: (
    e: React.MouseEvent,
    linkElem: HTMLAnchorElement,
  ) => void;
  smartCardProviderFeatureFlags?: SmartCardProviderProps['featureFlags'];
}

export const SmartCardWrapper: FunctionComponent<Props> = ({
  url,
  plainLink,
  isFlexLinkCard = false,
  showCover = true,
  onSmartCardClick,
  onLinkResolved,
  onLinkUnresolved,
  smartCardProps,
  shouldRender = false,
  analyticsContextData,
  handleTrelloLinkClick,
  smartCardProviderFeatureFlags,
}) => {
  const { language } = useLanguage();
  const { messages, isLocaleReady } = useSmartCardLocale();

  const SmartCard = useLazyComponent(
    () =>
      import(
        /* webpackChunkName: "atlaskit-smart-card" */ '@atlaskit/smart-card'
      ),
    {
      preload: false,
      namedImport: 'Card',
    },
  );
  const FlexLinkCard = useLazyComponent(
    () =>
      import(/* webpackChunkName: "atlaskit-smart-card" */ './FlexLinkCard'),
    {
      preload: false,
      namedImport: 'FlexLinkCard',
    },
  );

  const smartCardClient = getSmartCardClient();
  const [resolvedUrl, setResolvedUrl] = useState<
    SmartCardClientResponse | undefined
  >();

  useEffect(() => {
    const checkIfSmartCard = async () => {
      const resolved = await smartCardClient.fetchData(url).catch(() => {});
      setResolvedUrl(resolved ?? undefined);

      if (resolved) {
        onLinkResolved?.(resolved);
      } else {
        onLinkUnresolved?.();
      }
    };

    checkIfSmartCard();
  }, [url, onLinkResolved, onLinkUnresolved, smartCardClient]);

  const onClick = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();

      const isModifierKeyPressedOnEvent =
        e.altKey || e.ctrlKey || e.metaKey || e.shiftKey;

      const shouldNavigateInApp =
        isTrelloUrl(url) && !isModifierKeyPressedOnEvent;

      shouldNavigateInApp
        ? handleTrelloLinkClick(e, e.currentTarget as HTMLAnchorElement)
        : window.open(url, '_blank');

      onSmartCardClick?.(e);
    },
    [handleTrelloLinkClick, onSmartCardClick, url],
  );

  const refreshKey = useSmartCardProviderRefreshKey(resolvedUrl);

  const onError = useCallback(({ err }: { err?: Error }) => {
    if (err) {
      throw err;
    }
  }, []);

  if (!shouldRender || !isLocaleReady) {
    return plainLink();
  }

  return (
    <ChunkLoadErrorBoundary fallback={plainLink()}>
      <Suspense fallback={plainLink()}>
        <FabricAnalyticsListeners
          // This only fails in strict mode, so when this is compiled by `app/`,
          // `@ts-expect-error` won't detect any errors.
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          client={Analytics.dangerouslyGetAnalyticsWebClient()}
        >
          <AnalyticsContext data={analyticsContextData}>
            <SmartCardProvider
              client={smartCardClient}
              key={refreshKey}
              featureFlags={smartCardProviderFeatureFlags}
            >
              <PlatformIntlProvider locale={language} messages={messages}>
                {isFlexLinkCard ? (
                  <FlexLinkCard
                    onClick={onClick}
                    onError={onError}
                    showActions={Boolean(smartCardProps.showActions)}
                    showCover={showCover}
                    url={url}
                  />
                ) : (
                  <SmartCard
                    {...smartCardProps}
                    onClick={onClick}
                    onError={onError}
                    platform="web"
                    url={url}
                  />
                )}
              </PlatformIntlProvider>
            </SmartCardProvider>
          </AnalyticsContext>
        </FabricAnalyticsListeners>
      </Suspense>
    </ChunkLoadErrorBoundary>
  );
};
