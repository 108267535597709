import type { FunctionComponent } from 'react';

import { LazyConfirmEmailModal } from './LazyConfirmEmailModal';
import { useConfirmEmailModal } from './useConfirmEmailModal';

export const PossiblyRenderConfirmEmailModal: FunctionComponent<{
  boardId: string;
}> = ({ boardId }) => {
  const { wouldRender } = useConfirmEmailModal({ boardId });

  if (!wouldRender) {
    return null;
  }

  return <LazyConfirmEmailModal boardId={boardId} />;
};
