// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.iwX2OrLHCU9ULn {
  margin-top: 28px;
  color: var(--ds-text, #172b4d);
  font-size: 20px;
  letter-spacing: -0.008em;
  line-height: 24px;
  font-weight: 600;
  display: block;
  margin-top: 64px;
  margin-bottom: 16px;
  text-align: center;
}
.JiwVp1ifBnRVap {
  display: block;
  color: var(--ds-text, #172b4d);
  font-size: 14px;
  line-height: 20px;
  text-align: center;
}
`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"headerMessage": `iwX2OrLHCU9ULn`,
	"secondaryMessage": `JiwVp1ifBnRVap`
};
export default ___CSS_LOADER_EXPORT___;
