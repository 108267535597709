// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.KUlBLINXmfxwTv {
  width: 100%;
  height: 60px;
  animation: UYZuT10Hk_VFWk 1.5s infinite;
  border-radius: 3px;
}
@keyframes UYZuT10Hk_VFWk {
  0% {
    background-color: var(--ds-skeleton-subtle, #091e4208);
  }
  100% {
    background-color: var(--ds-skeleton, #091e420f);
  }
}
.kQYA_9fGgY1n4Y {
  position: relative;
  box-sizing: border-box;
  width: calc(100% - 2px);
  margin: 0 auto;
  padding: 16px;
  overflow: hidden;
  transition: background 0.3s ease;
  border-radius: 3px;
  box-shadow: var(--ds-shadow-raised, 0px 1px 1px #091e4240, 0px 0px 1px #091e424f);
  text-rendering: geometricPrecision;
}
.XA_AGbPTHcOX1p {
  background: var(--ds-surface-raised, #ffffff);
}
.XA_AGbPTHcOX1p .M8ZF6xlo8dPDOD {
  color: var(--ds-text, #172b4d);
}
.XA_AGbPTHcOX1p .FcwqU_vUB15jea {
  color: var(--ds-text-subtle, #44546f);
}
.XA_AGbPTHcOX1p .A7bHqfj9QUaYBm svg {
  color: var(--ds-icon-inverse, #ffffff);
}
.XA_AGbPTHcOX1p .F89PtwW6j0WPn6 {
  background: var(--ds-background-discovery-bold, #6e5dc6);
}
.XA_AGbPTHcOX1p .KIZWvglMg3hWri {
  background: var(--ds-background-neutral, #091e420f);
}
.XA_AGbPTHcOX1p .HtFZgNEW0hMdUV:hover {
  background-color: var(--ds-background-neutral-hovered, #091e4224);
}
.pg4zjlh2oAcaWN {
  background: var(--ds-background-discovery-bold, #6e5dc6);
}
.pg4zjlh2oAcaWN .M8ZF6xlo8dPDOD {
  color: var(--ds-text-inverse, #ffffff);
}
.pg4zjlh2oAcaWN .FcwqU_vUB15jea {
  color: var(--ds-text-inverse, #ffffff);
}
.pg4zjlh2oAcaWN .A7bHqfj9QUaYBm svg {
  color: var(--ds-icon-discovery, #8270db);
}
.pg4zjlh2oAcaWN .F89PtwW6j0WPn6 {
  background: var(--ds-surface-raised, #ffffff);
}
.pg4zjlh2oAcaWN .KIZWvglMg3hWri {
  background: var(--ds-background-accent-purple-subtle, #9f8fef);
}
.pg4zjlh2oAcaWN .HtFZgNEW0hMdUV:hover {
  background: var(--ds-background-accent-purple-subtle, #583f68);
}
.M8ZF6xlo8dPDOD {
  margin: 0 0 12px;
  color: var(--ds-text, #172b4d);
  font-size: 14px;
  font-weight: 600;
  letter-spacing: -0.003em;
  line-height: 16px;
}
.FcwqU_vUB15jea {
  margin-bottom: 12px;
}
.A7bHqfj9QUaYBm {
  width: 26px;
  height: 26px;
  padding: 2px;
}
.A7bHqfj9QUaYBm svg {
  width: 22px;
  height: 22px;
  line-height: 24px;
}
.F89PtwW6j0WPn6 {
  position: absolute;
  z-index: 2;
  right: 8px;
  bottom: 8px;
  width: 26px;
  height: 26px;
  border-radius: 3px;
}
.KIZWvglMg3hWri {
  position: absolute;
  z-index: 1;
  right: -28px;
  bottom: -64px;
  width: 120px;
  height: 80px;
  transform: rotate(-50deg);
  border-radius: 12px;
}
.HtFZgNEW0hMdUV {
  display: flex;
  position: absolute;
  top: 8px;
  right: 8px;
  width: 22px;
  height: 22px;
  margin: 0;
  padding: 4px;
  border: 0;
  background: none;
}
.F2CpCDD8ZNx0AZ {
  margin-left: -1px;
  line-height: 14px;
}
`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"animation": `KUlBLINXmfxwTv`,
	"pulse": `UYZuT10Hk_VFWk`,
	"upgradePromptFull": `kQYA_9fGgY1n4Y`,
	"upgradePromptFullLightTheme": `XA_AGbPTHcOX1p`,
	"upgradePromptFullHeadline": `M8ZF6xlo8dPDOD`,
	"upgradePromptFullContent": `FcwqU_vUB15jea`,
	"upgradePromptFullIcon": `A7bHqfj9QUaYBm`,
	"upgradePromptFullIconBackground": `F89PtwW6j0WPn6`,
	"upgradePromptFullIconClippedBackground": `KIZWvglMg3hWri`,
	"upgradePromptFullCloseButtonWrapper": `HtFZgNEW0hMdUV`,
	"upgradePromptFullDarkTheme": `pg4zjlh2oAcaWN`,
	"upgradePromptFullCloseButton": `F2CpCDD8ZNx0AZ`
};
export default ___CSS_LOADER_EXPORT___;
