import { useMemberId } from '@trello/authentication';
import { getDateBefore, idToDate } from '@trello/dates';

import { useNewUserFragment } from './NewUserFragment.generated';
import type { RuntimeEligibilityCheckResult } from './RuntimeEligibilityCheck';

export const useIsNewUser = (): RuntimeEligibilityCheckResult => {
  const memberId = useMemberId();
  const { data: memberData } = useNewUserFragment({ from: { id: memberId } });
  const campaigns = memberData?.campaigns ?? [];
  const moonshotCampaign = campaigns?.find(
    (c: { name: string }) => c.name === 'moonshot',
  );
  const wentThroughMadshot =
    moonshotCampaign?.dateDismissed !== undefined &&
    moonshotCampaign.dateDismissed !== null;

  const isNewUser = idToDate(memberId) > getDateBefore({ days: 7 });

  return {
    isEligible: wentThroughMadshot && isNewUser,
  };
};
