// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * DS201: Simplify complex destructure assignments
 * DS206: Consider reworking classes to avoid initClass
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */

import { Component } from 'react';
import _ from 'underscore';

import { Analytics } from '@trello/atlassian-analytics';
import { fireConfetti, shouldFireConfetti } from '@trello/confetti';
// eslint-disable-next-line no-restricted-imports
import $ from '@trello/jquery';

import { Auth } from 'app/scripts/db/Auth';
import { ModelCache } from 'app/scripts/db/ModelCache';
import { l } from 'app/scripts/lib/localize';
import { ModelCacheListener } from 'app/scripts/views/internal/ModelCacheListener';
import { recupWithHelpers } from 'app/scripts/views/internal/recupWithHelpers';
import { Alerts } from 'app/scripts/views/lib/Alerts';
import { PopOver } from 'app/scripts/views/lib/PopOver';
import { Tooltip } from 'app/scripts/views/lib/Tooltip';
import { ReactionIcon } from 'app/scripts/views/reactions/ReactionIcon';
import { ReactionSelector } from 'app/scripts/views/reactions/ReactionSelector';
import { ReactionTooltip } from 'app/scripts/views/reactions/ReactionTooltip';
import { Emoji } from 'app/src/components/Emoji';
import { stopPropagationAndPreventDefault } from 'app/src/stopPropagationAndPreventDefault';
const t = recupWithHelpers('reactions');
class ReactionPiles extends Component {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  shouldSuppressTracking: any;
  static initClass() {
    // @ts-expect-error TS(2339): Property 'displayName' does not exist on type 'Rea... Remove this comment to see the full error message
    this.prototype.displayName = 'ReactionPiles';

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    this.prototype.render = t.renderable(function (this: any) {
      const { canReact, renderReactIconWithBorder } = this.props;

      const reactionPiles = this.mapReactionListToPiles();

      return t.div(
        '.reaction-piles',
        {
          class: t.classify({
            'reaction-piles-empty': !reactionPiles.length,
            disabled: !canReact,
          }),
        },
        () => {
          // @ts-expect-error TS(2339): Property 'reaction' does not exist on type 'unknow... Remove this comment to see the full error message
          for (const { reaction, count, mine } of Array.from(reactionPiles)) {
            t.div(
              '.reaction-pile-item',
              {
                class: t.classify({ 'my-reaction': mine }),
                'data-reaction': reaction,
              },
              () => {
                t.addElement(
                  <Emoji
                    emoji={reaction}
                    onClick={this.handleEmojiClick}
                    onOver={this.handleEmojiOver}
                    onLeave={this.hideReactionTooltip}
                  />,
                );
                return t.span('.reaction-pile-count', function () {
                  return t.text(count > 99 ? '99+' : count);
                });
              },
            );
          }
          if (reactionPiles.length || renderReactIconWithBorder) {
            return this.renderReactIcon();
          } else {
            return this.renderInlineReactIcon();
          }
        },
      );
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor(props: any) {
    super(props);
    this.state = {
      reactionSelectorOpen: false,
      // @ts-expect-error TS(2339): Property 'reactionList' does not exist on type 'Re... Remove this comment to see the full error message
      reactionList: this.props.reactionList,
    };

    this.toggleReaction = this.toggleReaction.bind(this);
    this.showReactionTooltip = this.showReactionTooltip.bind(this);
    this.handleEmojiClick = this.handleEmojiClick.bind(this);
    this.handleEmojiOver = this.handleEmojiOver.bind(this);
    this.openReactionSelector = this.openReactionSelector.bind(this);
    this.renderInlineReactIcon = this.renderInlineReactIcon.bind(this);

    this._listen();
  }

  _listen() {
    this._alertEvent('uniqueReactionLimit', 'unique-reaction-limit-exceeded');
    this._alertEvent('totalReactionLimit', 'total-reaction-limit-exceeded');
    this._alertEvent('addReactionError', 'add-reaction-error');
    this._alertEvent('removeReactionError', 'remove-reaction-error');

    // @ts-expect-error TS(2339): Property 'modelListener' does not exist on type 'R... Remove this comment to see the full error message
    this.props.modelListener(
      // @ts-expect-error TS(2339): Property 'reactionList' does not exist on type 'Re... Remove this comment to see the full error message
      this.props.reactionList,
      'add remove reset',
      () => {
        // @ts-expect-error TS(2339): Property 'onReactionChanged' does not exist on typ... Remove this comment to see the full error message
        this.props.onReactionChanged?.();
        // @ts-expect-error TS(2339): Property 'reactionList' does not exist on type 'Re... Remove this comment to see the full error message
        return this.setState({ reactionList: this.props.reactionList });
      },
    );

    // @ts-expect-error TS(2339): Property 'modelListener' does not exist on type 'R... Remove this comment to see the full error message
    return this.props.modelListener(
      // @ts-expect-error TS(2339): Property 'reactionList' does not exist on type 'Re... Remove this comment to see the full error message
      this.props.reactionList,
      'add remove reset',
      () => {
        // @ts-expect-error TS(2339): Property 'reactionList' does not exist on type 'Re... Remove this comment to see the full error message
        return this.setState({ reactionList: this.props.reactionList });
      },
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  _alertEvent(eventName: any, message: any) {
    // @ts-expect-error TS(2339): Property 'modelListener' does not exist on type 'R... Remove this comment to see the full error message
    return this.props.modelListener(
      // @ts-expect-error TS(2339): Property 'reactionList' does not exist on type 'Re... Remove this comment to see the full error message
      this.props.reactionList,
      eventName,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (limit: any) => {
        const text = limit
          ? t.l(message, {
              number: limit.disableAt,
              heart: '❤',
            })
          : t.l(message);
        Alerts.showLiteralText(text, 'error', 'reaction-piles', 3000);
      },
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  toggleReaction(emoji: any, e: any) {
    stopPropagationAndPreventDefault(e);

    // @ts-expect-error TS(2339): Property 'canReact' does not exist on type 'Readon... Remove this comment to see the full error message
    if (!this.props.canReact) {
      return;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const trackFn = (isAdded: any) => {
      if (isAdded) {
        Analytics.sendTrackEvent({
          action: 'added',
          actionSubject: 'reaction',
          source: 'reactionSelectorInlineDialog',
          containers: {
            card: {
              // @ts-expect-error TS(2339): Property 'trackingContext' does not exist on type ... Remove this comment to see the full error message
              id: this.props?.trackingContext?.cardId,
            },
            list: {
              // @ts-expect-error TS(2339): Property 'trackingContext' does not exist on type ... Remove this comment to see the full error message
              id: this.props?.trackingContext?.listId,
            },
            board: {
              // @ts-expect-error TS(2339): Property 'trackingContext' does not exist on type ... Remove this comment to see the full error message
              id: this.props?.trackingContext?.boardId,
            },
          },
          attributes: {
            addedTo: 'comment',
            fromPile: true,
          },
        });
      } else {
        Analytics.sendTrackEvent({
          action: 'removed',
          actionSubject: 'reaction',
          source: 'reactionSelectorInlineDialog',
          containers: {
            card: {
              // @ts-expect-error TS(2339): Property 'trackingContext' does not exist on type ... Remove this comment to see the full error message
              id: this.props?.trackingContext?.cardId,
            },
            list: {
              // @ts-expect-error TS(2339): Property 'trackingContext' does not exist on type ... Remove this comment to see the full error message
              id: this.props?.trackingContext?.listId,
            },
            board: {
              // @ts-expect-error TS(2339): Property 'trackingContext' does not exist on type ... Remove this comment to see the full error message
              id: this.props?.trackingContext?.boardId,
            },
          },
          attributes: {
            removedFrom: 'comment',
            fromPile: true,
          },
        });
      }
    };

    if (shouldFireConfetti(emoji.native)) {
      const idEmoji = emoji.unified.toUpperCase();

      // @ts-expect-error TS(2339): Property 'reactionList' does not exist on type 'Re... Remove this comment to see the full error message
      if (!this.props.reactionList.findMyReaction(idEmoji)) {
        fireConfetti({
          x: e.pageX / window.innerWidth,
          y: e.pageY / window.innerHeight,
        });
      }
    }

    // @ts-expect-error TS(2339): Property 'reactionList' does not exist on type 'Re... Remove this comment to see the full error message
    return this.props.reactionList.toggleReaction(
      // @ts-expect-error TS(2339): Property 'actionId' does not exist on type 'Readon... Remove this comment to see the full error message
      this.props.actionId,
      emoji,
      trackFn,
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  showReactionTooltip(emoji: any, e: any) {
    const $btn = $(e.currentTarget);
    const reaction = emoji.shortcodes;

    let currentUserReacted = false;
    // Get all the reactions for the current hover
    // @ts-expect-error TS(2339): Property 'reactionList' does not exist on type 'Re... Remove this comment to see the full error message
    let names = this.props.reactionList
      .chain()
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .filter((model: any) => model.toEmojiMart().shortcodes === reaction)
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .omit(function (testReaction: any) {
        const isCurrentUser = testReaction.get('idMember') === Auth.myId();
        if (isCurrentUser) {
          currentUserReacted = true;
        }
        return isCurrentUser;
      })
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .map((testReaction: any) =>
        ModelCache.get('Member', testReaction.get('idMember'))?.get('fullName'),
      )
      .compact()
      .value();

    if (currentUserReacted) {
      names = [l('You'), ...Array.from(names)];
    }

    const tooltipHtml = new ReactionTooltip({ names, reaction });

    return Tooltip.show(tooltipHtml, $btn, false, Tooltip.STYLE.MENU);
  }

  hideReactionTooltip() {
    return Tooltip.hide();
  }

  mapReactionListToPiles() {
    return (
      // @ts-expect-error
      this.props.reactionList
        .chain()
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .groupBy((reaction: any) => reaction.toEmojiMart().shortcodes)
        .pairs()
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .map(function (...args: any[]) {
          const [emoji, entries] = Array.from(args[0]);
          return {
            reaction: emoji,
            // @ts-expect-error
            count: entries.length,
            mine: _.any(
              // @ts-expect-error
              entries,
              // @ts-expect-error TS(2571): Object is of type 'unknown'.
              (reaction) => reaction.get('idMember') === Auth.myId(),
            ),
          };
        })
        .value()
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleEmojiClick(emoji: any, e: any) {
    this.hideReactionTooltip();
    this.toggleReaction(emoji, e);
    // @ts-expect-error TS(2339): Property 'onEmojiClick' does not exist on type 'Re... Remove this comment to see the full error message
    return this.props.onEmojiClick?.(emoji, e);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleEmojiOver(emoji: any, e: any) {
    this.showReactionTooltip(emoji, e);
    // @ts-expect-error TS(2339): Property 'onEmojiOver' does not exist on type 'Rea... Remove this comment to see the full error message
    return this.props.onEmojiOver?.(emoji, e);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  openReactionSelector(e: any) {
    this.shouldSuppressTracking = false;
    stopPropagationAndPreventDefault(e);

    this.setState({ reactionSelectorOpen: true });

    // @ts-expect-error TS(2339): Property 'reactionSelectorOpen' does not exist on ... Remove this comment to see the full error message
    if (this.state.reactionSelectorOpen) {
      this.shouldSuppressTracking = true;
      PopOver.popView();
      this.setState({ reactionSelectorOpen: false });
      Analytics.sendClosedComponentEvent({
        componentType: 'inlineDialog',
        componentName: 'reactionSelectorInlineDialog',
        source: 'cardDetailScreen',
        attributes: {
          method: 'clicked picker button',
        },
        containers: {
          card: {
            // @ts-expect-error TS(2339): Property 'trackingContext' does not exist on type ... Remove this comment to see the full error message
            id: this.props?.trackingContext?.cardId,
          },
          list: {
            // @ts-expect-error TS(2339): Property 'trackingContext' does not exist on type ... Remove this comment to see the full error message
            id: this.props?.trackingContext?.listId,
          },
          board: {
            // @ts-expect-error TS(2339): Property 'trackingContext' does not exist on type ... Remove this comment to see the full error message
            id: this.props?.trackingContext?.boardId,
          },
        },
      });
      return;
    }

    Analytics.sendScreenEvent({
      name: 'reactionSelectorInlineDialog',
      containers: {
        card: {
          // @ts-expect-error TS(2339): Property 'trackingContext' does not exist on type ... Remove this comment to see the full error message
          id: this.props?.trackingContext?.cardId,
        },
        list: {
          // @ts-expect-error TS(2339): Property 'trackingContext' does not exist on type ... Remove this comment to see the full error message
          id: this.props?.trackingContext?.listId,
        },
        board: {
          // @ts-expect-error TS(2339): Property 'trackingContext' does not exist on type ... Remove this comment to see the full error message
          id: this.props?.trackingContext?.boardId,
        },
      },
    });

    const reactionSelectorSettings = {
      // @ts-expect-error TS(2339): Property 'actionId' does not exist on type 'Readon... Remove this comment to see the full error message
      actionId: this.props.actionId,
      // @ts-expect-error TS(2339): Property 'reactionList' does not exist on type 'Re... Remove this comment to see the full error message
      reactionList: this.props.reactionList,
      // @ts-expect-error TS(2339): Property 'trackingContext' does not exist on type ... Remove this comment to see the full error message
      trackingContext: this.props.trackingContext,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      onReactionClick: (event: any, emoji: any) => {
        stopPropagationAndPreventDefault(event);
        this.shouldSuppressTracking = true;
        this.setState({ reactionSelectorOpen: false });
        return PopOver.popView();
      },
    };

    return PopOver.show({
      elem: $(e.currentTarget),
      hideHeader: true,
      displayType: 'mod-reaction-selector',
      view: new ReactionSelector(reactionSelectorSettings),
      showImmediately: true,
      hidden: () => {
        return _.defer(() => {
          this.setState({ reactionSelectorOpen: false });
          if (!this.shouldSuppressTracking) {
            Analytics.sendClosedComponentEvent({
              componentType: 'inlineDialog',
              componentName: 'reactionSelectorInlineDialog',
              source: 'cardDetailScreen',
              attributes: {
                method: 'clicked outside the inline dialog',
              },
              containers: {
                card: {
                  // @ts-expect-error TS(2339): Property 'trackingContext' does not exist on type ... Remove this comment to see the full error message
                  id: this.props?.trackingContext?.cardId,
                },
                list: {
                  // @ts-expect-error TS(2339): Property 'trackingContext' does not exist on type ... Remove this comment to see the full error message
                  id: this.props?.trackingContext?.listId,
                },
                board: {
                  // @ts-expect-error TS(2339): Property 'trackingContext' does not exist on type ... Remove this comment to see the full error message
                  id: this.props?.trackingContext?.boardId,
                },
              },
            });
          }
        });
      },
    });
  }

  renderReactIcon() {
    return t.div('.reaction-pile-selector', () => {
      return t.createElement(ReactionIcon, {
        // @ts-expect-error TS(2339): Property 'canReact' does not exist on type 'Readon... Remove this comment to see the full error message
        disabled: !this.props.canReact,
        withBorder: true,
        onClick: this.openReactionSelector,
        // @ts-expect-error TS(2339): Property 'reactionSelectorOpen' does not exist on ... Remove this comment to see the full error message
        active: this.state.reactionSelectorOpen,
      });
    });
  }

  renderInlineReactIcon() {
    return t.span('.inline-add-reaction.meta-add-reaction.quiet', () => {
      return t.createElement(ReactionIcon, {
        // @ts-expect-error TS(2339): Property 'canReact' does not exist on type 'Readon... Remove this comment to see the full error message
        disabled: !this.props.canReact,
        withBorder: false,
        onClick: this.openReactionSelector,
        // @ts-expect-error TS(2339): Property 'reactionSelectorOpen' does not exist on ... Remove this comment to see the full error message
        active: this.state.reactionSelectorOpen,
      });
    });
  }
}

ReactionPiles.initClass();
/* eslint-disable-next-line @trello/no-module-logic */
const ReactionPilesListener = ModelCacheListener(ReactionPiles);

export { ReactionPilesListener as ReactionPiles };
