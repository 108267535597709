import * as Types from '@trello/graphql/generated';

import DocumentNode from '@trello/graphql/src/documentNode';
import * as Apollo from '@apollo/client';
export const UpdateWorkspaceReverseTrialTagDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"UpdateWorkspaceReverseTrialTag"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"reverseTrialTag"}},"type":{"kind":"NamedType","name":{"kind":"Name","value":"ReverseTrialTag"}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"traceId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"String"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"workspaceId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"updateOrganization"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"orgId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"workspaceId"}}},{"kind":"Argument","name":{"kind":"Name","value":"reverseTrialTag"},"value":{"kind":"Variable","name":{"kind":"Name","value":"reverseTrialTag"}}},{"kind":"Argument","name":{"kind":"Name","value":"traceId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"traceId"}}}],"directives":[{"kind":"Directive","name":{"kind":"Name","value":"client"}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"reverseTrialTag"}}]}}]}}]} as unknown as DocumentNode;
const defaultOptions = {"context":{"operationName":"UpdateWorkspaceReverseTrialTag","document":UpdateWorkspaceReverseTrialTagDocument}} as const;
export type UpdateWorkspaceReverseTrialTagMutationVariables = Types.Exact<{
  reverseTrialTag?: Types.InputMaybe<Types.ReverseTrialTag>;
  traceId: Types.Scalars['String']['input'];
  workspaceId: Types.Scalars['ID']['input'];
}>;


export type UpdateWorkspaceReverseTrialTagMutation = (
  { __typename: 'Mutation' }
  & { updateOrganization?: Types.Maybe<(
    { __typename: 'Organization' }
    & Pick<Types.Organization, 'id' | 'reverseTrialTag'>
  )> }
);

export type UpdateWorkspaceReverseTrialTagMutationFn = Apollo.MutationFunction<
  UpdateWorkspaceReverseTrialTagMutation,
  UpdateWorkspaceReverseTrialTagMutationVariables
>;

/**
 * __useUpdateWorkspaceReverseTrialTagMutation__
 *
 * To run a mutation, you first call `useUpdateWorkspaceReverseTrialTagMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateWorkspaceReverseTrialTagMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateWorkspaceReverseTrialTagMutation, { data, loading, error }] = useUpdateWorkspaceReverseTrialTagMutation({
 *   variables: {
 *      reverseTrialTag: // value for 'reverseTrialTag'
 *      traceId: // value for 'traceId'
 *      workspaceId: // value for 'workspaceId'
 *   },
 * });
 */
export function useUpdateWorkspaceReverseTrialTagMutation(
  baseOptions?: Apollo.MutationHookOptions<
    UpdateWorkspaceReverseTrialTagMutation,
    UpdateWorkspaceReverseTrialTagMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    UpdateWorkspaceReverseTrialTagMutation,
    UpdateWorkspaceReverseTrialTagMutationVariables
  >(UpdateWorkspaceReverseTrialTagDocument, options);
}
export type UpdateWorkspaceReverseTrialTagMutationHookResult = ReturnType<
  typeof useUpdateWorkspaceReverseTrialTagMutation
>;
export type UpdateWorkspaceReverseTrialTagMutationResult =
  Apollo.MutationResult<UpdateWorkspaceReverseTrialTagMutation>;
export type UpdateWorkspaceReverseTrialTagMutationOptions =
  Apollo.BaseMutationOptions<
    UpdateWorkspaceReverseTrialTagMutation,
    UpdateWorkspaceReverseTrialTagMutationVariables
  >;
