import { type FunctionComponent, useEffect } from 'react';
import cx from 'classnames';

import { Analytics } from '@trello/atlassian-analytics';
import { getScreenFromUrl } from '@trello/marketing-screens';

import { useHasReverseTrialExperience } from './useHasReverseTrialExperience';

import styles from './PremiumLozenge.less';

interface PremiumLozengeProps {
  className?: string;
  featureHighlighted?: string;
}

export interface MaybePremiumLozengeProps extends PremiumLozengeProps {
  onRender?: (willRender: boolean) => void;
}

export const PremiumLozenge: FunctionComponent<PremiumLozengeProps> = ({
  className,
  featureHighlighted,
}) => {
  useEffect(() => {
    if (featureHighlighted) {
      Analytics.sendOperationalEvent({
        action: 'rendered',
        actionSubject: 'reverseTrialPremiumLozenge',
        source: getScreenFromUrl(),
        attributes: {
          featureHighlighted,
        },
      });
    }
  }, [featureHighlighted]);
  return (
    <div className={cx(styles.PremiumLozenge, className)}>
      <span className={styles.premiumLozengeText}>premium</span>
    </div>
  );
};

export const MaybePremiumLozenge: FunctionComponent<
  MaybePremiumLozengeProps
> = ({ onRender, ...args }) => {
  const hasReverseTrialExperience = useHasReverseTrialExperience();

  useEffect(() => {
    onRender?.(hasReverseTrialExperience);
  }, [onRender, hasReverseTrialExperience]);

  return hasReverseTrialExperience ? <PremiumLozenge {...args} /> : null;
};
