// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("resources/images/chevron.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.B1uWdim9Jd0dJ9 {
  position: relative;
  overflow: visible;
  line-height: 10px;
  vertical-align: top;
  white-space: nowrap;
}
.B1uWdim9Jd0dJ9.hZt8F_SxS3x9ts {
  font-size: 8px;
}
.B1uWdim9Jd0dJ9.Y73NuAT7seZfmx {
  padding: unset;
  border: 0;
  border-radius: 100%;
}
.u0XUHdMJe85h0q:hover {
  background-color: var(--ds-background-accent-gray-subtler, #dcdfe4);
  cursor: pointer;
}
.u0XUHdMJe85h0q.S7RWiPL9Qgl9P9 {
  position: relative;
}
.u0XUHdMJe85h0q.S7RWiPL9Qgl9P9:hover::before {
  opacity: 0.2;
}
.u0XUHdMJe85h0q.S7RWiPL9Qgl9P9::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  opacity: 0;
  background-color: var(--ds-background-accent-gray-subtler, #dcdfe4);
}
.LlfbkG0J1IJ8uH {
  position: absolute;
  z-index: 3;
  right: 1px;
  background-size: 100%;
}
.LlfbkG0J1IJ8uH {
  bottom: 0;
  width: 9px;
  height: 9px;
  background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
}
.kFZ3hS99jGmKWk {
  background-color: transparent;
}
`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"memberAvatar": `B1uWdim9Jd0dJ9`,
	"longInitials": `hZt8F_SxS3x9ts`,
	"clickableAvatar": `Y73NuAT7seZfmx`,
	"hoverable": `u0XUHdMJe85h0q`,
	"withImage": `S7RWiPL9Qgl9P9`,
	"admin": `LlfbkG0J1IJ8uH`,
	"transparentBackground": `kFZ3hS99jGmKWk`
};
export default ___CSS_LOADER_EXPORT___;
