import { useEffect, useMemo, useState } from 'react';

import { useCrossFlow } from '@atlassiansox/cross-flow-support';
import type { TouchpointSourceType } from '@trello/ad-sequencer';
import { isDesktop } from '@trello/browser';
import type { ExperimentVariations } from '@trello/feature-gate-client';

import {
  type TrelloCrossFlowExperiment,
  trelloCrossFlowOpen,
} from '../TrelloCrossFlow';
import { useHasEligibleWorkspaces } from './runtimeEligibilityChecks/useHasEligibleWorkspaces';
import { useHasNoAvailableSites } from './runtimeEligibilityChecks/useHasNoAvailableSites';
import { useIsCrossFlowMemberConfirmed } from './runtimeEligibilityChecks/useIsCrossFlowMemberConfirmed';
import { useTrelloCrossFlowExperiment } from './useTrelloCrossFlowExperiment';

export type PaidExperimentFeatureKey =
  | 'trello_xf_spork_branding_positioning_paid_users'
  | 'trello_xf_paid_users_round_3';

export type CrossFlowForPaidUsersExperimentVariations<
  T extends PaidExperimentFeatureKey,
> = ExperimentVariations<T, 'cohort'>;

export interface CrossFlowForPaidUsersParams {
  source: TouchpointSourceType;
  workspaceId?: string;
  boardId?: string;
  hasValidAaSession: boolean;
}

export const useCrossFlowForPaidUsers = <T extends PaidExperimentFeatureKey>(
  experimentFeatureKey: T,
  {
    source,
    workspaceId,
    boardId,
    hasValidAaSession,
  }: CrossFlowForPaidUsersParams,
): TrelloCrossFlowExperiment<CrossFlowForPaidUsersExperimentVariations<T>> => {
  const crossFlow = useCrossFlow();

  // 1. Check that the member is confirmed
  const { isEligible: isMemberConfirmed } = useIsCrossFlowMemberConfirmed();

  // 2. Check that the member has eligible workspaces
  const { isEligible: hasEligibleWorkspaces, eligibleWorkspaceOptions } =
    useHasEligibleWorkspaces({
      workspaceId,
      entitlementRequired: 'standardOrPremium',
    });

  // 3. Check that the user is not on desktop
  const isWeb = !isDesktop();

  const [
    isImmediateRuntimeEligibilityChecksPassed,
    setImmediateRuntimeEligibilityChecksPassed,
  ] = useState(false);
  // 4. Check that the member has no available sites
  const { isEligible: hasNoAvailableSites } = useHasNoAvailableSites({
    doLoadAvailableSites: isImmediateRuntimeEligibilityChecksPassed,
    source,
  });

  const experiment = useTrelloCrossFlowExperiment({
    experimentFeatureKey,
    source,
    eligibilityCheckResults: [
      isMemberConfirmed,
      hasEligibleWorkspaces,
      crossFlow.isEnabled,
      isWeb,
    ],
    deferredEligibilityCheckResults: [hasNoAvailableSites],
  });

  useEffect(() => {
    setImmediateRuntimeEligibilityChecksPassed(
      experiment.isImmediateRuntimeEligibilityChecksPassed,
    );
  }, [experiment.isImmediateRuntimeEligibilityChecksPassed]);

  return useMemo(
    () => ({
      ...experiment,
      open: trelloCrossFlowOpen(
        crossFlow,
        source,
        eligibleWorkspaceOptions,
        hasValidAaSession,
        {
          workspaceId,
          boardId,
          sporkPositioning: 'customization',
        },
      ),
    }),
    [
      boardId,
      hasValidAaSession,
      crossFlow,
      eligibleWorkspaceOptions,
      experiment,
      source,
      workspaceId,
    ],
  );
};
