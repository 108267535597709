import type { FunctionComponent } from 'react';
import { Suspense } from 'react';

import { useLazyComponent } from '@trello/use-lazy-component';

import { LoadingSpinner } from './LoadingSpinner';
import type { TrelloEditorProps } from './TrelloEditor';

export const LazyTrelloEditor: FunctionComponent<TrelloEditorProps> = (
  props,
) => {
  const TrelloEditor = useLazyComponent(
    () => import(/* webpackChunkName: "trello-editor" */ './TrelloEditor'),
    { namedImport: 'TrelloEditor', preload: false },
  );

  return (
    <Suspense fallback={<LoadingSpinner />}>
      <TrelloEditor {...props} />
    </Suspense>
  );
};
