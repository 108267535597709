// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.lXZJWzOPFgxbpA {
  padding: 8px;
  border-radius: 5px;
  background: linear-gradient(225deg, var(--ds-background-discovery, #f3f0ff) 0%, var(--ds-background-discovery, #e9f2ff) 100%);
}
.lXZJWzOPFgxbpA .meVlJ4Raqjdy_c {
  margin: 8px 0 4px;
  font-size: 14px;
  font-weight: 500;
  line-height: 16px;
}
.lXZJWzOPFgxbpA .qnPtSRqRTcz1Sb {
  align-self: stretch;
  font-size: 11px;
  font-weight: 400;
  line-height: 14px;
}
`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"PremiumBanner": `lXZJWzOPFgxbpA`,
	"title": `meVlJ4Raqjdy_c`,
	"description": `qnPtSRqRTcz1Sb`
};
export default ___CSS_LOADER_EXPORT___;
