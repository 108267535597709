import { markdown } from './markdown';

export function markdownAsHtml(s: string, args?: object) {
  if (!args) {
    args = {};
  }
  /*
  There were some old accounts that couldn't view their account page
  because they had a null bio.  That needed to be fixed, but we may
  as well not crash the client if we run into a case like this.
  */
  if (!s) {
    return '';
  }
  return markdown.description.format(s, args).output;
}
