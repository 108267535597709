import type { FunctionComponent } from 'react';
import { useEffect } from 'react';

import type { SourceType } from '@trello/atlassian-analytics';
import { Analytics } from '@trello/atlassian-analytics';
import { forTemplate } from '@trello/legacy-i18n';

import styles from './ViewsErrorMessage.less';
const format = forTemplate('error');

interface ViewsErrorMessageProps {
  headerMessage?: string;
  secondaryMessage?: string;
  screenEventName: SourceType;
  analyticsContainers?: Parameters<
    typeof Analytics.sendScreenEvent
  >[0]['containers'];
  analyticsAttributes?: Parameters<
    typeof Analytics.sendScreenEvent
  >[0]['attributes'];
}

export const ViewsErrorMessage: FunctionComponent<ViewsErrorMessageProps> = ({
  headerMessage = format('we-couldnt-load-this-view'),
  secondaryMessage = format('try-refreshing-the-page'),
  screenEventName,
  analyticsContainers = {},
  analyticsAttributes = {},
}: ViewsErrorMessageProps) => {
  useEffect(() => {
    Analytics.sendScreenEvent({
      name: screenEventName,
      containers: analyticsContainers,
      attributes: analyticsAttributes,
    });
  }, [screenEventName, analyticsContainers, analyticsAttributes]);

  return (
    <>
      <h1 className={styles.headerMessage}>{headerMessage}</h1>
      <p className={styles.secondaryMessage}>{secondaryMessage}</p>
    </>
  );
};
