import type { FunctionComponent, PropsWithChildren } from 'react';
import { useMemo } from 'react';

import { useBoardMembers } from '@trello/business-logic-react/board';
import { useBoardId } from '@trello/id-context';

import { BoardMembersContext } from './BoardMembersContext';

export const BoardMembersContextProvider: FunctionComponent<
  PropsWithChildren<unknown>
> = ({ children }) => {
  const boardId = useBoardId();
  const { isMemberDeactivated, isMember, canDeleteCommentFromMember } =
    useBoardMembers(boardId);

  const value = useMemo(
    () => ({ isMember, isMemberDeactivated, canDeleteCommentFromMember }),
    [isMember, isMemberDeactivated, canDeleteCommentFromMember],
  );

  return (
    <BoardMembersContext.Provider value={value}>
      {children}
    </BoardMembersContext.Provider>
  );
};
