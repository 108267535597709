// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS206: Consider reworking classes to avoid initClass
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
// eslint-disable-next-line no-restricted-imports
import moment from 'moment';
import _ from 'underscore';

// eslint-disable-next-line no-restricted-imports
import $ from '@trello/jquery';
import { importWithRetry } from '@trello/use-lazy-component';

import { l } from 'app/scripts/lib/localize';
import { VIGOR } from 'app/scripts/views/internal/View';
import { PopOver } from 'app/scripts/views/lib/PopOver';
import { PluginView } from 'app/scripts/views/plugin/PluginView';
import { PluginDatePickerTemplate } from 'app/scripts/views/templates/PluginDatePickerTemplate';

const getPikadayStrings = () => ({
  previousMonth: l('prev month button text'),
  nextMonth: l('next month button text'),
  months: moment.months(),
  weekdays: moment.weekdays(),
  weekdaysShort: moment.weekdaysMin(),
});
const timeFormat = 'LT';
const parseFormat = [timeFormat, 'h:mm A'];
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const isTimeStringValid = (string: any) =>
  moment(string, parseFormat).isValid();

interface PluginPopOverDateTimeView {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  Pikaday: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  content: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dateOnly: any;
  // @ts-expect-error TS(2300): Duplicate identifier 'pickDate'.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  pickDate: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  picker: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  pikadayLoadPromise: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  title: any;
}

class PluginPopOverDateTimeView extends PluginView {
  vigor = VIGOR.NONE;
  static initClass() {
    // @ts-expect-error TS(2339): Property 'keepInDOM' does not exist on type 'Plugi... Remove this comment to see the full error message
    this.prototype.keepInDOM = true;
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  initialize({ title, content }: any) {
    this.title = title;
    this.content = content;
    this.retain(this.content);
    this.dateOnly = this.content.type === 'date';
    return this.loadPikadayLibrary();
  }

  loadPikadayLibrary() {
    return (
      this.pikadayLoadPromise ??
      (this.pikadayLoadPromise = importWithRetry(
        () => import(/* webpackChunkName: "pikaday" */ 'pikaday'),
      )
        .then((m) => m.default)
        .then((library) => {
          return (this.Pikaday = library);
        }))
    );
  }

  getViewTitle() {
    return this.title;
  }

  events() {
    return {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      'click a[href]'(e: any) {
        return PopOver.hide();
      },
      'input .js-dpicker-time-input': 'validateTimeInput',
      'change .js-dpicker-time-input': 'normalizeTimeInput',
      // @ts-expect-error TS(7023): ''click input.nch-button.nch-button--primary'' imp... Remove this comment to see the full error message
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      'click input.nch-button.nch-button--primary'(e: any) {
        // @ts-expect-error TS(2339): Property 'pickDate' does not exist on type '{ 'cli... Remove this comment to see the full error message
        return this.pickDate(e);
      },
      // @ts-expect-error TS(7023): 'submit' implicitly has return type 'any' because ... Remove this comment to see the full error message
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      submit(e: any) {
        e.preventDefault();
        // @ts-expect-error TS(2339): Property 'pickDate' does not exist on type '{ 'cli... Remove this comment to see the full error message
        return this.pickDate(e);
      },
    };
  }

  getSelectedDateMoment() {
    const date = this.picker.getMoment();
    if (!date.isValid()) {
      return null;
    }

    // Pikaday requires a single date format, and will parse it strictly (which
    // is good). However, this means an entry like '2/14/15' will parse as
    // '02/14/0015', which isn't great.
    if (date.year() < 1000) {
      date.add(2000, 'years');
    }

    const time = this.selectedTimeMoment();
    return date.hour(time.hour()).minute(time.minute());
  }

  // @ts-expect-error TS(2300): Duplicate identifier 'pickDate'.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  pickDate(e: any) {
    const dateMoment = this.getSelectedDateMoment();
    if (_.isFunction(this.content.callback)) {
      return (
        this.content
          .callback({
            el: e.currentTarget,
            options: {
              date: dateMoment?.toISOString(),
            },
          })
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          .catch((err: any) =>
            typeof console !== 'undefined' && console !== null
              ? console.warn(
                  `Error running Power-Up date picker callback function: ${err.message}`,
                )
              : undefined,
          )
      );
    } else {
      return PopOver.popView();
    }
  }
  renderOnce() {
    const tomorrowAtNoon = moment()
      .startOf('day')
      .add(1, 'days')
      .hour(12)
      .toDate();
    let date = tomorrowAtNoon;
    if (_.isString(this.content.date) && moment(this.content.date).isValid()) {
      date = moment(this.content.date).toDate();
    }

    const minDate =
      _.isString(this.content.minDate) && moment(this.content.minDate).isValid()
        ? moment(this.content.minDate).toDate()
        : undefined;
    const maxDate =
      _.isString(this.content.maxDate) && moment(this.content.maxDate).isValid()
        ? moment(this.content.maxDate).toDate()
        : undefined;
    let yearRange = 10; // default behavior of Pikaday
    if (minDate || maxDate) {
      // @ts-expect-error TS(2322): Type 'number[]' is not assignable to type 'number'... Remove this comment to see the full error message
      yearRange = [
        minDate ? minDate.getFullYear() : new Date().getFullYear() - 10,
        maxDate ? maxDate.getFullYear() : new Date().getFullYear() + 10,
      ];
    }

    this.$el.html(PluginDatePickerTemplate({ dateOnly: this.dateOnly }));

    this.loadPikadayLibrary().then(() => {
      this.picker = new this.Pikaday({
        field: _.first($('.js-dpicker-date-input', this.$el)),
        container: _.first($('.js-dpicker-cal', this.$el)),
        bound: false,
        format: 'l',
        firstDay: moment.localeData().firstDayOfWeek(),
        i18n: getPikadayStrings(),
        keyboardInput: false,
        minDate,
        maxDate,
        yearRange,
      });

      this.picker.setDate(date);

      if (!this.dateOnly) {
        return this.$timeInput().val(moment(date).format(timeFormat));
      }
    });

    return this;
  }

  validateTimeInput() {
    return this.$timeInput().toggleClass(
      'input-error',
      !this.isTimeInputValid(),
    );
  }

  normalizeTimeInput() {
    this.$timeInput().val(this.selectedTimeMoment().format(timeFormat));
    return this.validateTimeInput();
  }

  selectedTimeMoment() {
    if (this.dateOnly) {
      return moment().hours(0).minutes(0);
    } else if (this.isTimeInputValid()) {
      return moment(this.$timeInput().val(), parseFormat);
    } else {
      return moment().hours(12).minutes(0);
    }
  }

  $timeInput() {
    return $('.js-dpicker-time-input', this.$el);
  }

  isTimeInputValid() {
    return isTimeStringValid(this.$timeInput().val());
  }
}
PluginPopOverDateTimeView.initClass();
export { PluginPopOverDateTimeView };
