// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * DS104: Avoid inline assignments
 * DS204: Change includes calls to have a more natural evaluation order
 * DS206: Consider reworking classes to avoid initClass
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import { FormattedMessage } from 'react-intl';
import _ from 'underscore';

import { Analytics } from '@trello/atlassian-analytics';
import { renderReactRoot } from '@trello/component-wrapper/';
import type { RouteId } from '@trello/router';
import { getLocation, getRouteParamsFromPathname } from '@trello/router';
import { navigate } from '@trello/router/navigate';

import { getBoardUrlFromShortLink } from 'app/scripts/controller/urls';
import { atlassianPowerUps } from 'app/scripts/data/directory';
import { sendPluginTrackEvent } from 'app/scripts/lib/plugins/plugin-behavioral-analytics';
import type { Board } from 'app/scripts/models/Board';
import type { Plugin } from 'app/scripts/models/Plugin';
import type { PluginIO } from 'app/scripts/views/internal/plugins/PluginIo';
import { pluginIOCache } from 'app/scripts/views/internal/plugins/PluginIoCache';
import { pluginRunner } from 'app/scripts/views/internal/plugins/PluginRunner';
import { ReactRootComponent } from 'app/scripts/views/internal/ReactRootComponent';
import { recupWithHelpers } from 'app/scripts/views/internal/recupWithHelpers';
import { View } from 'app/scripts/views/internal/View';
import { Alerts } from 'app/scripts/views/lib/Alerts';
import { PowerUp, PowerUpItemType } from 'app/src/components/PowerUp';
import { stopPropagationAndPreventDefault } from 'app/src/stopPropagationAndPreventDefault';
import { DirectoryLegacyPowerUps as directoryLegacyPowerUps } from './DirectoryLegacyPowerUps';
import type { DirectoryView } from './DirectoryView';

import compliantIcon from 'resources/images/directory/icons/compliant.svg';
import notCompliantIcon from 'resources/images/directory/icons/notCompliant.svg';

const c = recupWithHelpers('directory_data_consent');

function PermissionsList({ pluginName }: { pluginName: string }) {
  return (
    <div>
      <p>
        <FormattedMessage
          id="templates.directory_data_consent.by-you-clicking-add"
          defaultMessage="By you clicking add, {pluginName} will be able to:"
          description="Message displayed when a user is about to give a power-up permissions."
          values={{
            pluginName,
          }}
        />
      </p>
      <ul>
        <li>
          <FormattedMessage
            id="templates.directory_data_consent.access-this-board"
            defaultMessage="Access this board"
            description="An item in a list of permissions a power-up has."
          />
        </li>
        <li>
          <FormattedMessage
            id="templates.directory_data_consent.add-content-to-this-board-such-as-attachments-to-cards"
            defaultMessage="Add content to this board such as attachments to cards"
            description="An item in a list of permissions a power-up has."
          />
        </li>
        <li>
          <FormattedMessage
            id="templates.directory_data_consent.take-actions-on-this-board-such-as-moving-sorting-filtering-cards-or-lists"
            defaultMessage="Take actions on this board such as moving, sorting, or filtering cards or lists"
            description="An item in a list of permissions a power-up has."
          />
        </li>
        <li>
          <FormattedMessage
            id="templates.directory_data_consent.access-user-information-such-as-username-avatar-initials-and-full-name-for-users-on-this-board"
            defaultMessage="Access user information such as username, avatar, initials, and full name for users on this board"
            description="An item in a list of permissions a power-up has."
          />
        </li>
      </ul>
    </div>
  );
}

/* eslint-disable-next-line @trello/no-module-logic */
const PrivacyPolicy = c.renderable(function ({
  pluginName,
  privacyPolicyUrl,
}: {
  pluginName: string;
  privacyPolicyUrl: string;
}) {
  if (privacyPolicyUrl) {
    return c.format(
      'for-more-information-please-see-power-ups-privacy-policy',
      {
        pluginName,
        privacyPolicyUrl,
      },
    );
  }
  return c.format(
    'for-more-information-please-see-power-ups-privacy-practices',
    { pluginName },
  );
});

function AtlassianDataConsent({
  pluginName,
  privacyPolicyUrl,
}: {
  pluginName: string;
  privacyPolicyUrl: string;
}) {
  return (
    <div className="directory-data-consent">
      <p>
        <FormattedMessage
          id="templates.directory_data_consent.power-up-is-developed-and-maintained-by-another-company-within-the-atlassian-corporate-family"
          defaultMessage="{pluginName} is developed and maintained by another company within the Atlassian corporate family."
          description="Message displayed when a user is about to add an Atlassian-owned power-up."
          values={{
            pluginName,
          }}
        />
      </p>
      <PermissionsList pluginName={pluginName} />
      <PrivacyPolicy
        pluginName={pluginName}
        privacyPolicyUrl={privacyPolicyUrl}
      />
    </div>
  );
}

/* eslint-disable-next-line @trello/no-module-logic */
const PersonalDataCompliance = c.renderable(
  ({
    pluginName,
    isCompliant,
  }: {
    pluginName: string;
    isCompliant: boolean;
  }) => {
    return c.div('.directory-compliance', () =>
      c.p(function () {
        const src = isCompliant === false ? notCompliantIcon : compliantIcon;

        c.img('.directory-compliance-icon', {
          src,
          alt: '',
          role: 'presentation',
        });
        if (isCompliant === false) {
          return c.format(
            'power-up-does-not-comply-with-our-data-privacy-standards',
            { pluginName },
          );
        } else if (isCompliant === true) {
          return c.format(
            'power-up-stores-data-complies-with-our-data-privacy-standards',
            { pluginName },
          );
        } else {
          return c.format('power-up-complies-with-our-data-privacy-standards', {
            pluginName,
          });
        }
      }),
    );
  },
);

function ThirdPartyDataConsent({
  pluginName,
  privacyPolicyUrl,
  isPublic,
  isCompliant,
}: {
  pluginName: string;
  privacyPolicyUrl: string;
  isPublic: boolean;
  isCompliant: boolean;
}) {
  return (
    <div className="directory-data-consent">
      <p>
        <FormattedMessage
          id="templates.directory_data_consent.power-up-is-not-developed-or-maintained-by-trello"
          defaultMessage="{pluginName} is not developed or maintained by Trello, and Trello is not responsible for its operation or security."
          description="Message displayed when a user is about to add a power-up that is not developed or maintained by Trello."
          values={{
            pluginName,
          }}
        />
      </p>
      {isPublic && (
        <PersonalDataCompliance
          pluginName={pluginName}
          isCompliant={isCompliant}
        />
      )}
      <PermissionsList pluginName={pluginName} />
      <PrivacyPolicy
        pluginName={pluginName}
        privacyPolicyUrl={privacyPolicyUrl}
      />
    </div>
  );
}

interface DirectoryPluginEnableView {
  directoryView: DirectoryView;
  io: PluginIO;
  plugin: Plugin;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  pluginDetails: any;
  tags: Plugin['attributes']['tags'];
}

class DirectoryPluginEnableView extends View<
  Board,
  {
    directoryView: DirectoryView;
    plugin: Plugin;
  }
> {
  className() {
    return 'directory-listing-wrapper';
  }

  eventSource() {
    return 'powerUpAddDetailsScreen' as const;
  }

  initialize({
    directoryView,
    plugin,
  }: {
    directoryView: DirectoryView;
    plugin: Plugin;
  }) {
    this.directoryView = directoryView;
    this.plugin = plugin;
    this.tags = this.plugin.get('tags');
    super.initialize(...arguments);
    this.io = pluginIOCache.get(this.plugin);

    this.pluginDetails = {
      isLegacy: Array.from(_.pluck(directoryLegacyPowerUps, 'id')).includes(
        this.plugin.id,
      ),
    };

    if (this.pluginDetails.isLegacy) {
      const legacyPlugin = _.findWhere(directoryLegacyPowerUps, {
        id: this.plugin.id,
      });
      this.pluginDetails = {
        ...this.pluginDetails,
        ...legacyPlugin,
      };
    }
  }

  isGrandfathered() {
    return (
      this.pluginDetails.isLegacy &&
      Array.from(this.model.get('powerUps')).includes(this.pluginDetails.name)
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  enable(e: any) {
    stopPropagationAndPreventDefault(e);
    const pluginAttributes = {
      pluginId: this.plugin.id,
      // @ts-expect-error
      pluginName: this.plugin.name,
      pluginTags: this.tags,
      installSource: 'boardDirectory',
    };
    const traceId = Analytics.startTask({
      taskName: 'enable-plugin',
      source: 'addPowerUpButton',
    });

    const elem = e.target;

    this.model.enablePluginWithTracing(this.plugin.id, {
      traceId,
      attributes: pluginAttributes,
      taskName: 'enable-plugin',
      source: 'addPowerUpButton',
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      next: (err: any) => {
        if (err) {
          const errorMessage = err.serverMessage;
          Analytics.taskFailed({
            taskName: 'enable-plugin',
            source: 'addPowerUpButton',
            error: new Error(errorMessage),
            attributes: {
              ...pluginAttributes,
            },
            traceId,
          });

          if (this.isGrandfathered()) {
            this.model.pull('powerUps', this.pluginDetails.name);
          } else {
            const disableTraceId = Analytics.startTask({
              taskName: 'disable-plugin',
              source: 'addPowerUpButton',
              attributes: pluginAttributes,
            });
            this.model.disablePluginWithTracing(this.plugin.id, {
              traceId: disableTraceId,
              attributes: pluginAttributes,
              taskName: 'disable-plugin',
              source: 'addPowerUpButton',
            });
          }
          if (errorMessage === 'PLUGIN_NOT_ALLOWED') {
            Alerts.show('plugin not allowed', 'error', 'addpluginerror', 4000);
          } else {
            Alerts.show(
              'could not add plugin',
              'error',
              'addpluginerror',
              2000,
            );
          }
        }
      },
    });

    if (this.io.supports('on-enable')) {
      return pluginRunner
        .one({
          plugin: this.plugin,
          command: 'on-enable',
          board: this.model,
          el: elem,
        })
        .catch((err) => {
          console?.warn('Failed to handle on-enable command.', {
            error: err.message,
            id: this.plugin.id,
          });
        })
        .finally(() => {
          return this.pluginEnabled(traceId);
        });
    } else {
      return this.pluginEnabled(traceId);
    }
  }

  navigateToPluginListing = () => {
    const { shortLink, shortName } = getRouteParamsFromPathname<RouteId.BOARD>(
      getLocation().pathname,
    );
    navigate(
      `${getBoardUrlFromShortLink(shortLink, shortName)}/power-up/${
        this.plugin.id
      }`,
      {
        trigger: true,
      },
    );
  };

  pluginEnabled(traceId: string) {
    sendPluginTrackEvent({
      idPlugin: this.plugin.id,
      idBoard: this.model.id,
      event: {
        action: 'added',
        actionSubject: 'powerUp',
        attributes: {
          installSource: 'boardDirectory',
          tags: this.tags,
          taskId: traceId,
        },
        source: 'addPowerUpButton',
      },
    });

    this.navigateToPluginListing();
  }

  render() {
    const pluginName = this.io.getName();
    const iconUrl = this.io.getIconUrl();
    const author = this.plugin.get('author');
    const privacyPolicyUrl = this.plugin.get('privacyUrl');
    const isAtlassianOwned = Array.from(atlassianPowerUps).includes(
      this.plugin.id,
    );
    const isPublic = this.plugin.get('public');
    const isCompliant = this.plugin.get('isCompliantWithPrivacyStandards');

    renderReactRoot(
      <div>
        <ReactRootComponent>
          <div className="directory-individual-listing">
            <div>
              <PowerUp
                type={PowerUpItemType.Listing}
                // @ts-expect-error
                atomProps={{
                  subtitle: author,
                  icon: {
                    url: iconUrl,
                  },
                  name: pluginName,
                }}
              />
            </div>
            {isAtlassianOwned ? (
              <AtlassianDataConsent
                pluginName={pluginName}
                privacyPolicyUrl={privacyPolicyUrl}
              />
            ) : (
              <ThirdPartyDataConsent
                pluginName={pluginName}
                privacyPolicyUrl={privacyPolicyUrl}
                isPublic={isPublic}
                isCompliant={isCompliant}
              />
            )}
            <div className="directory-enable-page-buttons">
              <button
                className="button link"
                onClick={this.navigateToPluginListing}
              >
                <FormattedMessage
                  id="templates.directory_power_up_item.cancel"
                  defaultMessage="Cancel"
                  description="Button to cancel adding a power-up."
                />
              </button>
              <button
                className="nch-button nch-button--primary mt-4 mr-4"
                onClick={this.enable.bind(this)}
              >
                <FormattedMessage
                  id="templates.directory_power_up_item.add"
                  defaultMessage="Add"
                  description="Button to add a power-up."
                />
              </button>
            </div>
          </div>
        </ReactRootComponent>
      </div>,
      this.el,
      true,
    );

    this.directoryView.trackScreenEvent(this.eventSource(), {
      powerUpId: this.plugin.id,
      tags: this.tags,
    });

    return this;
  }
}

export { DirectoryPluginEnableView };
