import type { MouseEvent as ReactMouseEvent } from 'react';
import { Component } from 'react';

import type { EmojiMartEmoji } from '@trello/emoji';

import { EmojiProvider } from './EmojiProvider';
import { EmojiProviderComponent } from './EmojiProviderComponent';

export interface EmojiProps {
  emoji: string;
  onClick?: (emoji: EmojiMartEmoji, e: ReactMouseEvent) => void;
  onLeave?: (emoji: EmojiMartEmoji, e: ReactMouseEvent) => void;
  onOver?: (emoji: EmojiMartEmoji, e: ReactMouseEvent) => void;
  set?: 'twitter' | 'native';
  size?: number;
}

export class Emoji extends Component<EmojiProps> {
  static defaultProps = {
    set: 'twitter',
    size: 16,
  };

  onClick(emoji: EmojiMartEmoji, e: ReactMouseEvent) {
    if (this.props.onClick) {
      this.props.onClick(emoji, e);
    }
  }

  onHover(emoji: EmojiMartEmoji, e: ReactMouseEvent) {
    if (this.props.onOver) {
      this.props.onOver(emoji, e);
    }
  }

  onLeave(emoji: EmojiMartEmoji, e: ReactMouseEvent) {
    if (this.props.onLeave) {
      this.props.onLeave(emoji, e);
    }
  }

  render() {
    return (
      <EmojiProviderComponent isSmallSpinner>
        {(sheet, getBackgroundImageFn) => {
          const spriteSheetUrl = getBackgroundImageFn
            ? getBackgroundImageFn('twitter')
            : '';

          const emoji = EmojiProvider.getEmojiDataSync(this.props.emoji);
          if (!emoji) {
            // We shouldn't get here if data is already loaded, fail gracefully
            // with the aria label as the emoji shortcode passed in
            return (
              <span
                aria-label={this.props.emoji}
                className="emoji-mart-emoji"
              />
            );
          }

          const nativeEmoji = spriteSheetUrl === '' ? emoji.native : '';
          const emojiStyle =
            nativeEmoji === ''
              ? {
                  display: 'inline-block',
                  width: this.props.size + 'px',
                  height: this.props.size + 'px',
                  backgroundImage: `url(${spriteSheetUrl})`,
                  backgroundSize: `${100 * sheet.cols}% ${100 * sheet.rows}%`,
                  backgroundPosition: `${
                    (100 / (sheet.cols - 1)) * emoji.x!
                  }% ${(100 / (sheet.rows - 1)) * emoji.y!}%`,
                }
              : {
                  display: 'block',
                  lineHeight: this.props.size + 'px',
                };

          // currently renders button tag for comment reactions, span for notification reactions
          return this.props.onClick ? (
            <button
              aria-label={emoji.label}
              className="emoji-mart-emoji"
              type="button"
              onClick={this.onClick.bind(this, emoji)}
              onMouseEnter={this.onHover.bind(this, emoji)}
              onMouseLeave={this.onLeave.bind(this, emoji)}
            >
              <span style={emojiStyle}>{nativeEmoji}</span>
            </button>
          ) : (
            <span aria-label={emoji.label} className="emoji-mart-emoji">
              <span style={emojiStyle}>{nativeEmoji}</span>
            </span>
          );
        }}
      </EmojiProviderComponent>
    );
  }
}
