import type { FunctionComponent } from 'react';
import { Suspense, useMemo } from 'react';

import { useTrelloCrossFlow } from '@trello/cross-flow';
import { useLazyComponent } from '@trello/use-lazy-component';

import { useIsEligibleForJwmAds } from '../useIsEligibleForJwmAds';
import type { DiscoveryAdPowerUpDirectoryProps } from './DiscoveryAdPowerUpDirectory';

const source = 'boardDirectory';

export const LazyDiscoveryAdPowerUpDirectoryContainer: FunctionComponent<
  DiscoveryAdPowerUpDirectoryProps
> = ({ workspaceId }) => {
  const DiscoveryAdPowerUpDirectoryContainer = useLazyComponent(
    () =>
      import(
        /* webpackChunkName: "discovery-ad-power-up-directory-container" */ './DiscoveryAdPowerUpDirectoryContainer'
      ),
    { preload: false, namedImport: 'DiscoveryAdPowerUpDirectoryContainer' },
  );

  const isEligibleForJwmAds = useIsEligibleForJwmAds(workspaceId);
  const { getIsTargeted } = useTrelloCrossFlow(source);

  const isTargetedForExperiment = useMemo(
    () => getIsTargeted(),
    [getIsTargeted],
  );

  if (!isEligibleForJwmAds || !isTargetedForExperiment) {
    return null;
  }

  return (
    <Suspense fallback={null}>
      <DiscoveryAdPowerUpDirectoryContainer workspaceId={workspaceId} />
    </Suspense>
  );
};
