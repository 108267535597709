import { getApiGatewayUrl } from '@trello/api-gateway';
import { Analytics } from '@trello/atlassian-analytics';
import { clientVersion } from '@trello/config';
import { getErrorTextFromFetchResponse } from '@trello/error-handling';
import { fetch } from '@trello/fetch';

export interface Attribute {
  name: string;
  value: string | boolean | number;
}

export const fetchUserPersonalization = async (
  userId: string,
): Promise<{ attributes: Attribute[] }> => {
  const url = getApiGatewayUrl(
    `/tap-delivery/api/v3/personalization/user/${userId}`,
  );

  const response = await fetch(url, {
    method: 'GET',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      'X-Trello-Client-Version': clientVersion,
    },
  });

  if (response.ok) {
    return response.json();
  } else {
    if (response.status === 401) {
      return { attributes: [] };
    }

    // we expect this is if the user's Aa is not valid
    Analytics.sendOperationalEvent({
      action: 'errored',
      actionSubject: 'fetchUserPersonalization',
      source: '@trello/atlassian-personalization',
    });

    throw new Error(await getErrorTextFromFetchResponse(response));
  }
};
