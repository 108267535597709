// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.S1FKoIuPQxvORO {
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  flex-direction: column;
  margin: 12px;
  margin-top: 0;
  overflow: hidden;
  border-radius: 8px;
  background-color: var(--ds-surface, #ffffff);
  -webkit-overflow-scrolling: touch;
  -webkit-transform: translate3d(0, 0, 0);
  scrollbar-color: var(--ds-background-neutral-hovered, #091e4224) var(--ds-background-neutral, #091e420f);
  scrollbar-width: thin;
}
.S1FKoIuPQxvORO::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
.S1FKoIuPQxvORO::-webkit-scrollbar-button:start:decrement,
.S1FKoIuPQxvORO::-webkit-scrollbar-button:end:increment {
  display: none;
  background: transparent;
}
.S1FKoIuPQxvORO::-webkit-scrollbar-track-piece {
  background: var(--ds-background-neutral, #091e420f);
}
.S1FKoIuPQxvORO::-webkit-scrollbar-track-piece:vertical:start {
  border-radius: 4px 4px 0 0;
}
.S1FKoIuPQxvORO::-webkit-scrollbar-track-piece:vertical:end {
  border-radius: 0 0 4px 4px;
}
.S1FKoIuPQxvORO::-webkit-scrollbar-track-piece:horizontal:start {
  border-radius: 4px 0 0 4px;
}
.S1FKoIuPQxvORO::-webkit-scrollbar-track-piece:horizontal:end {
  border-radius: 0 4px 4px 0;
}
.S1FKoIuPQxvORO::-webkit-scrollbar-thumb:vertical,
.S1FKoIuPQxvORO::-webkit-scrollbar-thumb:horizontal {
  display: block;
  height: 48px;
  border-radius: 4px;
  background: var(--ds-background-neutral-hovered, #091e4224);
}
.S1FKoIuPQxvORO h1 {
  margin-top: auto;
  margin-bottom: auto;
}
.S1FKoIuPQxvORO > div {
  height: 100%;
}
@media print {
  .S1FKoIuPQxvORO {
    position: relative;
  }
}
`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"sbtvWrapper": `S1FKoIuPQxvORO`
};
export default ___CSS_LOADER_EXPORT___;
