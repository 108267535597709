import type { featureExperiments } from '@trello/feature-gate-client';
import { SharedState, useSharedState } from '@trello/shared-state';

import type { AdTouchpointSourceType } from './TouchpointSourceType';

interface NextAdSource {
  experience?: keyof typeof featureExperiments;
  source?: AdTouchpointSourceType;
}

const nextAdSource = new SharedState<NextAdSource>({});

/**
 * Exposes a value in state that indicates whether the user
 **/
export const useNextAdSource = () => useSharedState(nextAdSource);
