import type TrelloMarkdown from '@atlassian/trello-markdown';

export const linksFromMarkdown = function (
  text: string,
  formatter: typeof TrelloMarkdown,
) {
  let tokens: Array<typeof TrelloMarkdown.inlineLex> = [];

  for (const block of formatter.blockLex(text, {})) {
    if (block.children) {
      tokens = [
        ...Array.from(tokens),
        ...Array.from(formatter.inlineLex(block.children.text)),
      ];
    }
  }

  return tokens
    .filter((token) => token.type === 'link')
    .map(function (token) {
      if (token.children?.text) {
        return {
          url: token.url,
          text: formatter.textInline(token.children?.text),
          title: token.title,
        };
      } else {
        return { url: token.url };
      }
    });
};
