import type { FunctionComponent, ReactNode } from 'react';
import { cloneElement } from 'react';
import classNames from 'classnames';

import { CloseIcon } from '@trello/nachos/icons/close';
import { token } from '@trello/theme';

import styles from './UpgradePromptFullBase.less';

/**
 * UpgradePromptFull props
 *
 * content: prompt copy, optionally rendered
 * cta: call to action of prompt
 * headline: prompt header
 * iconName: name of icon
 * isDarkTheme: controls dark theme
 * onDismiss: function expected to unmount prompt and make dismissal persistent for the user
 */

export interface UpgradePromptFullBaseProps {
  className?: string;
  content?: ReactNode;
  cta: ReactNode;
  headline?: ReactNode;
  icon: JSX.Element;
  isDarkTheme?: boolean;
  onDismiss?: () => void;
  loading?: boolean;
  allowUpsell?: boolean;
}

export const UpgradePromptFullBase: FunctionComponent<
  UpgradePromptFullBaseProps
> = ({
  loading,
  className,
  content,
  cta,
  headline,
  icon,
  isDarkTheme,
  onDismiss,
}) => {
  const promptClasses = classNames(
    styles.upgradePromptFull,
    isDarkTheme
      ? styles.upgradePromptFullDarkTheme
      : styles.upgradePromptFullLightTheme,
    className,
  );

  if (loading) {
    return <div className={styles.animation}></div>;
  }

  return (
    <div className={promptClasses}>
      <div className={styles.upgradePromptFullHeadline}>{headline}</div>
      <div className={styles.upgradePromptFullContent}>{content}</div>
      {cta}
      <div className={styles.upgradePromptFullIconBackground}>
        {cloneElement(icon, {
          dangerous_className: styles.upgradePromptFullIcon,
        })}
      </div>
      <div className={styles.upgradePromptFullIconClippedBackground} />
      {onDismiss && (
        <button
          className={styles.upgradePromptFullCloseButtonWrapper}
          onClick={onDismiss}
        >
          <CloseIcon
            color={
              isDarkTheme
                ? token('color.icon.inverse', '#FFFFFF')
                : token('color.icon.subtle', '#626F86')
            }
            size="small"
            dangerous_className={styles.upgradePromptFullCloseButton}
          />
        </button>
      )}
    </div>
  );
};
