import { useMemo } from 'react';

import { Analytics } from '@trello/atlassian-analytics';
import { useMemberId } from '@trello/authentication';
import { Entitlements } from '@trello/entitlements';

import type { EligibleWorkspacesForProvisioningFragment } from './EligibleWorkspacesForProvisioningFragment.generated';
import { useEligibleWorkspacesForProvisioningFragment } from './EligibleWorkspacesForProvisioningFragment.generated';

type Organization = NonNullable<
  NonNullable<EligibleWorkspacesForProvisioningFragment['organizations']>
>[number];

const isWorkspaceFree = (organization: Pick<Organization, 'offering'>) =>
  organization.offering && Entitlements.isFree(organization.offering);

const isWorkspaceStandardOrPremium = (
  organization: Pick<Organization, 'offering'>,
) =>
  organization.offering &&
  (Entitlements.isStandard(organization.offering) ||
    Entitlements.isPremium(organization.offering));

const isWorkspaceEnterprise = (organization: Pick<Organization, 'offering'>) =>
  organization.offering && Entitlements.isEnterprise(organization.offering);

const isWorkspaceLinkedToJwmSite = (
  organization: Pick<Organization, 'jwmLink'>,
) => !!organization.jwmLink?.idCloud;

const isWorkspaceEligible = ({
  organization,
  entitlementRequired,
}: {
  organization: Organization;
  entitlementRequired: 'free' | 'standardOrPremium' | undefined;
}) => {
  switch (entitlementRequired) {
    case 'free':
      return (
        isWorkspaceFree(organization) &&
        !isWorkspaceLinkedToJwmSite(organization)
      );
    case 'standardOrPremium':
      return (
        isWorkspaceStandardOrPremium(organization) &&
        !isWorkspaceLinkedToJwmSite(organization)
      );
    default:
      return (
        !isWorkspaceEnterprise(organization) &&
        !isWorkspaceLinkedToJwmSite(organization)
      );
  }
};

export type EligibleWorkspaceOptions = Array<{
  id: string;
  displayName: string;
}>;

/**
 * Derives eligible workspace options for the provisioning and linking flow.
 *
 * Users with multiple eligible workspaces are presented with a workspace
 * selector in the site creation screen, indicating which Trello workspace
 * should be linked to the created JWM site.
 *
 * This hook populates the values for the workspace selector in CFFE, which are
 * represented as `id` and `displayName`.
 */
export const useEligibleWorkspacesForProvisioning = ({
  workspaceId,
  entitlementRequired,
}: {
  workspaceId?: string;
  entitlementRequired?: 'free' | 'standardOrPremium';
} = {}): EligibleWorkspaceOptions => {
  const memberId = useMemberId();
  const { data } = useEligibleWorkspacesForProvisioningFragment({
    from: { id: memberId },
  });
  const organizations = data?.organizations;

  return useMemo(() => {
    if (!organizations?.length) {
      return [];
    }
    if (workspaceId) {
      const currentWorkspace = organizations.find(
        (organization) => organization.id === workspaceId,
      );

      // Hide all touchpoints for users that are not members of the current workspace (1)
      // or that are a member of an ineligible workspace (2)

      // 1. If the workspaceId is not found in the list of organizations,
      // it means the user is not a member of that workspace (ie. a guest).
      if (!currentWorkspace) {
        Analytics.sendOperationalEvent({
          action: 'excluded',
          actionSubject: 'workspace',
          actionSubjectId: 'workspaceEligibilityForProvisioning',
          attributes: {
            workspaceId,
            reason: 'notMemberOfWorkspace',
          },
          source: 'crossFlowEssentials',
        });
        return [];
      }

      // 2. If the current workspace is ineligible, use this layer as a redundancy
      // to essentially disqualify all workspaces from being eligible, in effect
      // hiding touchpoints. This is a bit undesirable, and we should eventually
      // move this logic up into a different part of the eligibility evaluation.
      if (
        !isWorkspaceEligible({
          organization: currentWorkspace,
          entitlementRequired,
        })
      ) {
        Analytics.sendOperationalEvent({
          action: 'excluded',
          actionSubject: 'workspace',
          actionSubjectId: 'workspaceEligibilityForProvisioning',
          attributes: {
            workspaceId,
            reason: 'currentWorkspaceIneligible',
          },
          source: 'crossFlowEssentials',
        });
        return [];
      }
    }
    return organizations
      .filter((organization) =>
        isWorkspaceEligible({ organization, entitlementRequired }),
      )
      .map(({ id, displayName }) => ({ id, displayName }));
  }, [organizations, workspaceId, entitlementRequired]);
};
