// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import { teacupWithHelpers } from 'app/scripts/views/internal/teacupWithHelpers';
const t = teacupWithHelpers('board_header_warnings');

// eslint-disable-next-line @trello/no-module-logic
export const BoardHeaderWarningsTemplate = t.renderable(function ({
  canDismiss,
  warning,
}: // eslint-disable-next-line @typescript-eslint/no-explicit-any
any) {
  const { key, dismiss } = warning;
  return t.div('.board-warning', function () {
    t.format(key);
    if (canDismiss) {
      return t.span(
        '.js-dismiss-warning.board-warning-close.icon-sm.icon-close',
        { 'data-dismiss': dismiss },
      );
    }
  });
});
