import { SharedState } from '@trello/shared-state';

interface ModelIdToElementState {
  lists: Map<string, HTMLElement>;
  cards: Map<string, HTMLElement>;
}

/**
 * Map of model IDs to HTML element outputs.
 * This is required for maintaining legacy lists and cards, but is deprecated
 * and should not be brought into the new stack.
 * @deprecated
 */
export const boardModelIdToElementState =
  new SharedState<ModelIdToElementState>({
    lists: new Map(),
    cards: new Map(),
  });
