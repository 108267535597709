// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ThEu8pfRZh4EmY {
  display: inline-block;
  margin-bottom: 16px;
  padding: 0;
  background: transparent;
  text-decoration: underline;
}
.ThEu8pfRZh4EmY:focus {
  outline: var(--ds-border-focused, #388bff) auto 2px;
}
.mac .ThEu8pfRZh4EmY:focus {
  outline-color: -webkit-focus-ring-color;
}
.ThEu8pfRZh4EmY:focus .mac.firefox {
  outline-color: AccentColor;
}
.ThEu8pfRZh4EmY:hover {
  background-color: transparent;
}
.ThEu8pfRZh4EmY:active {
  background-color: transparent;
}
.YzMoZViXVRMmUy {
  color: var(--ds-text-subtle, #44546f);
}
.YzMoZViXVRMmUy:hover {
  background-color: transparent;
  color: var(--ds-text, #172b4d);
}
.e3Icd5nFmsLqc0 {
  z-index: 2;
  color: var(--ds-text-inverse, #ffffff);
}
.e3Icd5nFmsLqc0:hover {
  color: var(--ds-text-inverse, #ffffff);
}
.UCDzz648q1qC_3 {
  margin: 4px 0;
}
.Nm0HIR8zF6JaEN {
  margin-top: 4px;
  margin-bottom: 16px;
}
.MCWea7KpTQpTcl {
  margin-top: 8px;
}
.hehlpulFYPNCM2 {
  margin-right: 30px;
  background: none;
  color: var(--ds-text-disabled, #091e424f);
  text-align: left;
  cursor: default;
}
.hehlpulFYPNCM2:hover {
  background: none;
  color: var(--ds-text-disabled, #091e424f);
}
.hehlpulFYPNCM2:active {
  background: none;
  color: var(--ds-text-disabled, #091e424f);
}
.ha00VFABdPLnu_ {
  margin-top: 8px;
}
.n2RnFxIXim9wwz {
  color: var(--ds-text-subtle, #44546f);
  font-size: 12px;
  line-height: 16px;
}
.uAi7syELZTRH4p {
  margin-top: 4px;
}
`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cta": `ThEu8pfRZh4EmY`,
	"lightModeCta": `YzMoZViXVRMmUy`,
	"darkModeCta": `e3Icd5nFmsLqc0`,
	"boardMenuPromptFullWrapper": `UCDzz648q1qC_3`,
	"butlerUpsellPopoverPromptFullWrapper": `Nm0HIR8zF6JaEN`,
	"createBoardModalWrapper": `MCWea7KpTQpTcl`,
	"disabledCta": `hehlpulFYPNCM2`,
	"customFieldUpgradeSection": `ha00VFABdPLnu_`,
	"customFieldUpgradeParagraph": `n2RnFxIXim9wwz`,
	"customFieldUpgradePrompt": `uAi7syELZTRH4p`
};
export default ___CSS_LOADER_EXPORT___;
