import type { FunctionComponent } from 'react';
import { Suspense } from 'react';

import { ApolloProvider } from '@trello/graphql';
import { useLazyComponent } from '@trello/use-lazy-component';

import type { Props } from './BoardProfileCard';
import { ProfileCardSkeleton } from './ProfileCardSkeleton';

export const LazyBoardProfileCard: FunctionComponent<Props> = (props) => {
  const BoardProfileCard = useLazyComponent(
    () =>
      import(/* webpackChunkName: "board-profile-card" */ './BoardProfileCard'),
    { namedImport: 'BoardProfileCard' },
  );
  return (
    <Suspense fallback={<ProfileCardSkeleton onClose={props.onClose} />}>
      <ApolloProvider>
        <BoardProfileCard {...props} />
      </ApolloProvider>
    </Suspense>
  );
};
