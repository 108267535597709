export const categories = [
  'analytics-reporting',
  'automation',
  'board-utilities',
  'communication-collaboration',
  'developer-tools',
  'file-management',
  'hr-operations',
  'it-project-management',
  'marketing-social-media',
  'product-design',
  'sales-support',
];

export const atlassianPowerUps = [
  '588a331cc86ffbe08f5d2c67', // Bitbucket Cloud
];
