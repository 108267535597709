// eslint-disable-next-line no-restricted-imports
import moment from 'moment';

import type { DueFilterCriteriaOption } from 'app/src/components/FilterPopover/FilterCriteriaOptions';
import type { FilterableCard } from 'app/src/components/ViewFilters/types';
import type { CardFilterCriteria } from './ViewFilter';
import { DateType, SerializableViewFilter } from './ViewFilter';

// eslint-disable-next-line no-restricted-syntax
export enum SortingOption {
  Ascending = 0,
  Descending = 1,
}

export const DAY_MILLIS = 86400000;

// eslint-disable-next-line no-restricted-syntax
export enum RangeFilter {
  Null = 0, //Has no range filter
  NextDay = DAY_MILLIS,
  NextWeek = DAY_MILLIS * 7,
  NextMonth = DAY_MILLIS * 30,
}

// eslint-disable-next-line no-restricted-syntax
export enum CompleteFilter {
  None = 0,
  Complete = 1,
  Incomplete = 2,
}

export type DueFilterValue =
  | RangeFilter
  | CompleteFilter
  | boolean
  | SortingOption;

export const DUE_FILTER_OPTIONS = [
  'notdue',
  'day',
  'week',
  'month',
  'overdue',
  'complete',
  'incomplete',
] as const;

export type BoardDueFilterString = (typeof DUE_FILTER_OPTIONS)[number] | null;

const dueMap = {
  [RangeFilter.NextDay]: 1,
  [RangeFilter.NextWeek]: 7,
  [RangeFilter.NextMonth]: 28,
};

interface DueFilterParameters {
  range?: RangeFilter;
  complete?: CompleteFilter;
  overdue?: boolean;
  notDue?: boolean;
}

export class DueFilter extends SerializableViewFilter {
  public readonly filterType: 'due' = 'due';

  public readonly range: RangeFilter;
  public readonly complete: CompleteFilter;
  public readonly overdue: boolean;
  public readonly notDue: boolean;

  constructor({
    range = RangeFilter.Null,
    complete = CompleteFilter.None,
    overdue = false,
    notDue = false,
  }: DueFilterParameters = {}) {
    super();
    this.range = range;
    this.complete = complete;
    this.overdue = overdue;
    this.notDue = notDue;
  }

  filterLength(): number {
    const urlParams = this.toUrlParams();
    const dueLength = urlParams['due']?.split(',').length;
    const dueCompleteLength = urlParams['dueComplete'] ? 1 : 0;
    return (dueLength ? dueLength : 0) + dueCompleteLength;
  }

  isEmpty(): boolean {
    return (
      this.range === RangeFilter.Null &&
      this.complete === CompleteFilter.None &&
      !this.overdue &&
      !this.notDue
    );
  }

  setRangeFilter(range: RangeFilter) {
    return new DueFilter({ ...this, range });
  }

  setCompleteFilter(complete: CompleteFilter, isMultiBoard: boolean = false) {
    if (isMultiBoard) {
      return new DueFilter({
        ...this,
        complete,
        notDue: false,
        ...(complete === CompleteFilter.Complete && { overdue: false }),
      });
    }
    return new DueFilter({ ...this, complete });
  }

  setOverdue(overdue: boolean, isMultiBoard: boolean = false) {
    if (isMultiBoard) {
      return new DueFilter({
        ...this,
        overdue,
        ...(this.complete === CompleteFilter.Complete && {
          complete: CompleteFilter.None,
        }),
        notDue: false,
      });
    }
    return new DueFilter({ ...this, overdue });
  }

  setNotDue(notDue: boolean, isMultiBoard: boolean = false) {
    if (isMultiBoard) {
      return new DueFilter({
        ...this,
        overdue: false,
        complete: CompleteFilter.None,
        notDue,
      });
    }
    return new DueFilter({ ...this, notDue });
  }

  getMinDue() {
    switch (this.range) {
      case RangeFilter.NextDay:
      case RangeFilter.NextWeek:
      case RangeFilter.NextMonth:
        return moment().toISOString();
      case RangeFilter.Null:
      default:
        return null;
    }
  }

  getMaxDue() {
    switch (this.range) {
      case RangeFilter.NextDay:
        return moment().add(1, 'days').toISOString();
      case RangeFilter.NextWeek:
        return moment().add(7, 'days').toISOString();
      case RangeFilter.NextMonth:
        return moment().add(30, 'days').toISOString();
      case RangeFilter.Null:
      default:
        return null;
    }
  }

  getDueComplete(): boolean | null {
    if (this.complete === CompleteFilter.None) {
      return null;
    }

    return this.complete === CompleteFilter.Complete;
  }

  getOverdue(): boolean {
    return Boolean(this.overdue);
  }

  getNotDue(): boolean {
    return Boolean(this.notDue);
  }

  satisfiesDueFilter({
    due,
    complete,
    isAnd = false,
  }: {
    due: FilterableCard['due'];
    complete: FilterableCard['complete'];
    isAnd?: boolean;
  }): boolean {
    if (this.isEmpty()) {
      return true;
    }

    const checks = [];
    const isCardComplete = complete === CompleteFilter.Complete;
    const isCardIncomplete = complete === CompleteFilter.Incomplete;

    if (this.getDueComplete()) checks.push(isCardComplete);
    if (this.getDueComplete() === false) checks.push(isCardIncomplete);
    if (this.notDue) checks.push(due === null);

    if (this.range !== RangeFilter.Null) {
      if (!due) {
        checks.push(false);
      } else {
        const maxDate = moment().add(dueMap[this.range], 'day');
        const cardDueMoment = moment(due);
        const failsDateCheck = this.overdue
          ? cardDueMoment.isAfter(maxDate) || isCardComplete
          : !cardDueMoment.isBetween(Date.now(), maxDate);

        checks.push(!failsDateCheck);
      }
    }
    if (this.overdue)
      checks.push(
        this.overdue &&
          due &&
          isCardIncomplete &&
          moment(due).isBefore(Date.now()),
      );

    return isAnd ? checks.every(Boolean) : checks.some(Boolean);
  }

  toUrlParams(): {
    due: string | null;
    dueComplete: string | null;
  } {
    const rangeString = this.getDueFromRange() ?? null;
    const notDueString = this.notDue ? 'notdue' : null;
    const overdueString = this.overdue ? 'overdue' : null;
    const dueArray = [rangeString, overdueString, notDueString].filter(
      (s) => s !== null,
    );
    const due = dueArray.join(',') || null;

    const dueComplete = (() => {
      switch (this.complete) {
        case CompleteFilter.Complete:
          return 'true';
        case CompleteFilter.Incomplete:
          return 'false';
        case CompleteFilter.None:
        default:
          return null;
      }
    })();

    return {
      due,
      dueComplete,
    };
  }

  static fromBoardString(boardString: BoardDueFilterString): DueFilter {
    let rangeFilter = RangeFilter.Null;
    let notDue = null; //This "notdue" logic is here to support backwards compatability with certain url forms
    switch (boardString) {
      case 'day':
        rangeFilter = RangeFilter.NextDay;
        break;
      case 'week':
        rangeFilter = RangeFilter.NextWeek;
        break;
      case 'month':
        rangeFilter = RangeFilter.NextMonth;
        break;
      case 'notdue':
        notDue = true;
        break;
      default:
        break;
    }

    return new DueFilter({ range: rangeFilter, ...(notDue && { notDue }) });
  }

  static fromUrlParams({ due, dueComplete }: { [key: string]: string | null }) {
    const [first, second, third] = due?.split(',') || [];
    const overdue = second === 'overdue' || (!second && first === 'overdue');
    const notDue =
      third === 'notdue' ||
      (!third && second === 'notdue') ||
      (!second && first === 'notdue');
    const rangeString =
      first !== 'overdue' && first !== 'notdue' ? first : null;

    let rangeFilter: RangeFilter = RangeFilter.Null;

    switch (rangeString) {
      case 'day':
        rangeFilter = RangeFilter.NextDay;
        break;
      case 'week':
        rangeFilter = RangeFilter.NextWeek;
        break;
      case 'month':
        rangeFilter = RangeFilter.NextMonth;
        break;
      default:
        rangeFilter = RangeFilter.Null;
        break;
    }

    let completeFilter: CompleteFilter = CompleteFilter.None;

    switch (dueComplete) {
      case 'true':
      case '1':
        completeFilter = CompleteFilter.Complete;
        break;
      case 'false':
      case '0':
        completeFilter = CompleteFilter.Incomplete;
        break;
      default:
        completeFilter = CompleteFilter.None;
        break;
    }

    return new DueFilter({
      range: rangeFilter,
      overdue,
      notDue,
      complete: completeFilter,
    });
  }

  toCardFilterCriteria() {
    const overdue = this.getOverdue();
    const rangeFilter = this.range;
    let start,
      end = null;

    const special = this.notDue ? ('none' as const) : undefined;

    if (
      [
        RangeFilter.NextDay,
        RangeFilter.NextWeek,
        RangeFilter.NextMonth,
      ].includes(rangeFilter)
    ) {
      start = { dateType: DateType.RELATIVE, value: 0 };
      end = { dateType: DateType.RELATIVE, value: rangeFilter };
    }

    if (overdue) {
      start = null;
      // Only set the end range if not already set by the rangeFilter
      end = end
        ? end
        : {
            dateType: DateType.RELATIVE,
            value: 0,
          };
    }

    const due =
      start || end || special
        ? {
            start,
            end,
            special,
          }
        : null;

    return {
      due,
      dueComplete: this.getDueComplete(),
    };
  }

  static fromCardFilterCriteria(cardFilterCriteria: CardFilterCriteria) {
    const overdue =
      !!cardFilterCriteria.due &&
      cardFilterCriteria.due?.start === null &&
      !cardFilterCriteria.due?.special;

    let rangeFilter: RangeFilter = RangeFilter.Null;
    switch (cardFilterCriteria.due?.end?.value) {
      case RangeFilter.NextDay:
        rangeFilter = RangeFilter.NextDay;
        break;
      case RangeFilter.NextWeek:
        rangeFilter = RangeFilter.NextWeek;
        break;
      case RangeFilter.NextMonth:
        rangeFilter = RangeFilter.NextMonth;
        break;
      default:
        rangeFilter = RangeFilter.Null;
        break;
    }

    const notDue = cardFilterCriteria.due?.special === 'none';

    let completeFilter: CompleteFilter = CompleteFilter.None;
    switch (cardFilterCriteria.dueComplete) {
      case true:
        completeFilter = CompleteFilter.Complete;
        break;
      case false:
        completeFilter = CompleteFilter.Incomplete;
        break;
      default:
        completeFilter = CompleteFilter.None;
        break;
    }

    return new DueFilter({
      overdue,
      range: rangeFilter,
      complete: completeFilter,
      notDue,
    });
  }

  getDueFromRange(): 'day' | 'week' | 'month' | undefined {
    let due: 'day' | 'week' | 'month' | undefined;

    switch (this.range) {
      case RangeFilter.NextDay:
        due = 'day';
        break;
      case RangeFilter.NextWeek:
        due = 'week';
        break;
      case RangeFilter.NextMonth:
        due = 'month';
        break;
      default:
        break;
    }

    return due;
  }

  serializeToBackboneFilter() {
    const due = this.getDueFromRange();

    return {
      due,
      overdue: this.getOverdue() || undefined,
      dueComplete: this.getDueComplete() ?? undefined,
      notDue: this.getNotDue() || undefined,
    };
  }

  /**
   * Parse a DueFilter into valid MBAPI query parameters
   *
   * If "notdue" is selected, due will be "none"; otherwise:
   * due will be of the form 2021-07-26T20:24:00.182Z...2021-08-01T20:24:00.182Z
   * where up to two ISOStrings are separated by ...
   *
   * @returns {due: string, dueComplete: boolean|null}
   */
  toMbapiFormat(): { due: string; dueComplete: boolean | null } {
    if (this.getNotDue()) {
      return { due: 'none', dueComplete: null };
    }

    if (this.getOverdue()) {
      const endOfRange = this.getMaxDue() || new Date().toISOString();
      return { due: `...${endOfRange}`, dueComplete: false };
    }

    const startOfRange = this.getMinDue();
    const endOfRange = this.getMaxDue();
    const due =
      startOfRange || endOfRange ? `${startOfRange || ''}...${endOfRange}` : '';
    return { due, dueComplete: this.getDueComplete() };
  }

  // These are functions used by the board filtering, which uses different url parameter formatting and
  // mutates the legacy board filter backbone model. If we deprecate the board filtering in favor of
  // view filtering, we can delete these functions
  isDueOptionActive(value: DueFilterCriteriaOption['value']) {
    switch (value) {
      case 'complete':
        return this.complete === CompleteFilter.Complete;
      case 'incomplete':
        return this.complete === CompleteFilter.Incomplete;
      case 'overdue':
        return this.getOverdue() === true;
      case 'notdue':
        return this.getNotDue() === true;
      case 'day':
        return this.range === RangeFilter.NextDay;
      case 'week':
        return this.range === RangeFilter.NextWeek;
      case 'month':
        return this.range === RangeFilter.NextMonth;
      default:
        return false;
    }
  }
}
