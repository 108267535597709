import type { FunctionComponent } from 'react';
import classNames from 'classnames';

import { forTemplate } from '@trello/legacy-i18n';
import { BoardIcon } from '@trello/nachos/icons/board';

import type { BadgeProps } from './types';

// eslint-disable-next-line @trello/less-matches-component
import styles from './PowerUp.less';

const format = forTemplate('directory_power_up_item');

export const Badges: FunctionComponent<BadgeProps> = ({
  button,
  usage,
  darkMode,
  staffPick,
  integration,
  hideInstallCues = false,
}) => (
  <div className={styles.badges}>
    {button}
    {!hideInstallCues && typeof usage === 'number' && usage > 0 && (
      <div
        className={styles.pupBadge}
        title={format('usage-explanation', { count: usage.toLocaleString() })}
      >
        <BoardIcon dangerous_className={styles.usageBadgeIcon} />
        <span>{usage.toLocaleString()}+</span>
      </div>
    )}
    {integration && (
      <div
        className={classNames(styles.pupBadge, styles.pupIntegrationBadge)}
        title={format('integration-explanation')}
      >
        <span>{format('integration')}</span>
      </div>
    )}
    {darkMode && (
      <div className={classNames(styles.pupBadge, styles.darkModeBadge)}>
        <span>{format('dark-mode')}</span>
      </div>
    )}
    {!hideInstallCues && staffPick && (
      <div
        className={classNames(styles.pupBadge, styles.genericBadge)}
        title={format('staff-pick-explanation')}
      >
        <span>{format('staff-pick')}</span>
      </div>
    )}
  </div>
);
