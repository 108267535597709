import { useMemo } from 'react';

import { useMemberId } from '@trello/authentication';

import { getJwmEntityNavigationUrl } from './getJwmEntityNavigationUrl';
import { useMemberEmailFragment } from './MemberEmailFragment.generated';
import { useIsInstanceMember } from './useIsInstanceMember';
import { useJwmLinkOrganization } from './useJwmLinkOrganization';

/**
 * Will return the correct url for JWM navigation links depending on the user's instance permissions
 * Also returns a boolean to indicate if the join button should be rendered beside the navigation link
 */

export type JwmLinkNavData =
  | {
      jwmSiteUrl: undefined;
      jwmProjectsUrl: undefined;
      showJoinButton: false;
    }
  | {
      jwmSiteUrl: string;
      jwmProjectsUrl: string;
      showJoinButton: boolean;
    };

export const useJwmLinkNavigationData = (orgId?: string): JwmLinkNavData => {
  const memberId = useMemberId();
  const { data: memberData } = useMemberEmailFragment({
    from: { id: memberId },
    variables: { memberId },
  });
  const email = memberData?.email || undefined;

  const data = useJwmLinkOrganization(orgId);
  const cloudId = data?.idCloud;
  const entityUrl = data?.entityUrl || undefined;
  const online = !data?.inaccessible;

  const { loading, isInstanceMember } = useIsInstanceMember(cloudId);

  const linkData: JwmLinkNavData = useMemo(() => {
    if (!entityUrl || !online) {
      return {
        jwmSiteUrl: undefined,
        jwmSiteJoinedUrl: undefined,
        showJoinButton: false,
      };
    } else {
      // Don't show the join button until it's confirmed they are not a member (loading is finished)
      const showJoinButton = !loading && !isInstanceMember;

      const { jwmYourWorkUrl, jwmProjectsUrl } = getJwmEntityNavigationUrl({
        entityUrl,
        email,
      });
      return {
        // TODO there shouldn't be a deicison made here, but in the consumer instead
        jwmSiteUrl: showJoinButton ? jwmYourWorkUrl : jwmProjectsUrl,
        jwmProjectsUrl,
        showJoinButton,
      };
    }
  }, [entityUrl, online, loading, isInstanceMember, email]);

  return linkData;
};
