import type { FunctionComponent, SyntheticEvent } from 'react';
import { useCallback, useState } from 'react';
import classNames from 'classnames';

import { forTemplate } from '@trello/legacy-i18n';
import { Button } from '@trello/nachos/button';
import type { PowerUpTestIds } from '@trello/test-ids';
import { getTestId } from '@trello/test-ids';

import { useDeleteSharedPluginDataMutation } from './DeleteSharedPluginDataMutation.generated';

import styles from './DisablePluginSettings.less';

const format = forTemplate('directory_power_up_item');
interface Props {
  disablePlugin: (e: SyntheticEvent<EventTarget>) => void;
  boardId: string;
  pluginId: string;
  elem: SyntheticEvent<EventTarget>;
  name: string;
}

export const DisablePluginSettings: FunctionComponent<Props> = ({
  disablePlugin,
  boardId,
  pluginId,
  elem,
  name,
}) => {
  const [checked, setChecked] = useState(false);
  const [deleteSharedPluginData] = useDeleteSharedPluginDataMutation();

  const handleDisablePlugin = useCallback(async () => {
    disablePlugin(elem);
    if (!checked) {
      await deleteSharedPluginData({
        variables: {
          boardId,
          pluginId,
        },
      });
    }
  }, [boardId, pluginId, deleteSharedPluginData, checked, elem, disablePlugin]);

  return (
    <>
      <div>
        <p className={styles.deleteTitle}>
          {format('delete-plugin-data-explain')}
        </p>
        <p>
          {format('plugin-data-detail', { powerupName: name })}{' '}
          <a
            className={styles.learnMoreSubtle}
            href="https://support.atlassian.com/trello/docs/enabling-power-ups/"
            rel="noopener noreferrer"
            target="blank"
          >
            {format('learn-more')}
          </a>
        </p>
      </div>
      <div className={classNames('check-div', styles.checkbox)}>
        <input
          id="checkbox_id"
          type="checkbox"
          checked={checked}
          onChange={() => setChecked(!checked)}
        />
        <label htmlFor="checkbox_id">{format('keep-plugin-data')}</label>
      </div>
      <Button
        appearance="danger"
        size="fullwidth"
        onClick={handleDisablePlugin}
        data-testid={getTestId<PowerUpTestIds>('disable-powerup-button')}
      >
        {format('disable-plugin-button')}
      </Button>
    </>
  );
};
