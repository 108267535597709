import * as Types from '@trello/graphql/generated';

import DocumentNode from '@trello/graphql/src/documentNode';
import * as Apollo from '@apollo/client';
export type BoardMembersMemberFragment = (
  { __typename: 'Member' }
  & Pick<Types.Member, 'id' | 'idEnterprisesAdmin' | 'idPremOrgsAdmin' | 'memberType'>
  & { enterpriseLicenses?: Types.Maybe<Array<(
    { __typename: 'Member_EnterpriseLicense' }
    & Pick<Types.Member_EnterpriseLicense, 'idEnterprise'>
  )>>, organizations: Array<(
    { __typename: 'Organization' }
    & Pick<Types.Organization, 'id' | 'idEnterprise'>
  )> }
);

export const BoardMembersMemberFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BoardMembersMember' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Member' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'enterpriseLicenses' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'idEnterprise' },
                },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'idEnterprisesAdmin' },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'idPremOrgsAdmin' } },
          { kind: 'Field', name: { kind: 'Name', value: 'memberType' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organizations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'idEnterprise' },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

interface UseBoardMembersMemberFragmentOptions
  extends Omit<
    Apollo.UseFragmentOptions<
      BoardMembersMemberFragment,
      Apollo.OperationVariables
    >,
    'fragment' | 'fragmentName' | 'from'
  > {
  from: Apollo.StoreObject | Apollo.Reference;
  returnPartialData?: boolean;
}

interface UseBoardMembersMemberFragmentResult
  extends Omit<Apollo.UseFragmentResult<BoardMembersMemberFragment>, 'data'> {
  data?: BoardMembersMemberFragment;
}

export const useBoardMembersMemberFragment = ({
  from,
  returnPartialData,
  ...options
}: UseBoardMembersMemberFragmentOptions): UseBoardMembersMemberFragmentResult => {
  const result = Apollo.useFragment<BoardMembersMemberFragment>({
    ...options,
    fragment: BoardMembersMemberFragmentDoc,
    fragmentName: 'BoardMembersMember',
    from: {
      __typename: 'Member',
      ...from,
    },
  });

  // Ensure that the fragment result is not typed as a DeepPartial.
  if (!result.complete && !returnPartialData) {
    return {
      ...result,
      data: undefined,
    };
  }

  return { ...result, data: result.data as BoardMembersMemberFragment };
};
