import type {
  ComponentType,
  ForwardRefRenderFunction,
  PropsWithoutRef,
  RefAttributes,
} from 'react';
import { forwardRef } from 'react';

export type ForwardedComponent<T, P> = ComponentType<
  PropsWithoutRef<P> & RefAttributes<T>
>;

export const forwardRefComponent = <T, P>(
  displayName: string,
  fn: ForwardRefRenderFunction<T, P>,
): ForwardedComponent<T, P> => {
  fn.displayName = displayName;

  return forwardRef<T, P>(fn);
};
