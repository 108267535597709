// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.G_oTX2t3FoLUnO {
  display: flex;
  flex-direction: row;
}
.H2t1ErLunidGwn {
  display: flex;
  flex-direction: column;
}
.kCMhoovUShU3f5 {
  position: relative;
}
.eSxPrFhGIapuh9 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.IfpvKPX8q6nfER {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.l_ACdizgZu7qSg {
  display: grid;
}
.nGxTVskizG33Qz {
  display: flex;
  flex-direction: column;
  justify-content: left;
  gap: 8px;
}
.CRzLwldedgbpl_ {
  display: grid;
  grid-auto-rows: 40px auto;
  margin-bottom: 8px;
  row-gap: 12px;
}
.z_2dpunNWoawUd {
  display: grid;
  grid-template-columns: 40px 1fr;
  align-items: center;
  max-height: 40px;
}
.k8hoit1x4fIgen {
  display: grid;
  grid-template-columns: 1fr auto;
}
.yTJdMKv0yx6M1b {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
}
.tbaLwxmcmFFhij {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  max-height: 40px;
  margin-left: 8px;
  font-size: 16px;
  font-weight: bold;
  line-height: 20px;
  text-overflow: ellipsis;
}
.tbaLwxmcmFFhij.OPqBrmQCn800du {
  min-width: 100px;
  height: 18px;
  margin-bottom: 4px;
}
.RJOvcaXofwJcob {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  max-height: 20px;
  margin-left: 8px;
  font-size: 16px;
  font-weight: bold;
  line-height: 20px;
  text-overflow: ellipsis;
}
.ffpHDGXWHCCRmI {
  margin-left: 7px;
  overflow: hidden;
  font-size: 14px;
  letter-spacing: -0.3px;
  line-height: normal;
  text-overflow: ellipsis;
  white-space: no-wrap;
}
.ef5Ow3wEp2hV_4 {
  display: inline-block;
  width: 40px;
  height: 40px;
  border-radius: 4px;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  text-align: center;
}
.lDLnCCYDs47Y8u {
  display: flex;
  flex-wrap: wrap;
  margin-top: auto;
  gap: 8px;
}
.k9ojPYuL3PPdHn {
  display: flex;
  align-items: center;
  height: 24px;
  padding: 0 8px;
  border-radius: 3px;
  color: var(--ds-text-subtle, #44546f);
  font-size: 12px;
  line-height: 14px;
  white-space: nowrap;
}
.k9ojPYuL3PPdHn.vLZSlh9P1ty7yX {
  background-color: var(--ds-background-accent-blue-subtlest, #e9f2ff);
}
.DZRnKlHLfAcIE3 {
  margin-right: 8px;
}
.Ck9gmZ2Uw87Mmq {
  width: 100%;
  padding: 8px;
  border-radius: 4px;
  background-color: var(--ds-background-neutral, #091e420f);
}
.Ck9gmZ2Uw87Mmq:hover {
  background: var(--ds-background-neutral-hovered, rgba(0, 0, 0, 0.05));
}
.zFrCb4laTkOb9D {
  display: grid;
  grid-template-rows: auto 1fr;
  border-radius: 4px;
  background: var(--ds-surface-sunken, #f7f8f9);
}
.SWO8Z0NxwWvvzb {
  padding: 10px;
}
.aRLylRBUxfePmQ {
  width: 100%;
  height: 240px;
  border-radius: 4px 4px 0 0;
  object-fit: cover;
}
@keyframes eJBzYjHdvkYRBw {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
.OPqBrmQCn800du {
  animation-name: eJBzYjHdvkYRBw;
  animation-duration: 1.5s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  background: var(--ds-skeleton, #091e420f);
  background-size: 800px 104px;
  animation-fill-mode: forwards;
}
.z44iXTUrr29ixq {
  min-width: 64px;
  height: 24px;
  margin-top: 3px;
  border-radius: 3px;
}
.directory-individual-listing .KkbpiBe6uuSR2V {
  margin-left: 8px;
}
`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"flexRow": `G_oTX2t3FoLUnO`,
	"flexColumn": `H2t1ErLunidGwn`,
	"relative": `kCMhoovUShU3f5`,
	"flexRowSpaceBetween": `eSxPrFhGIapuh9`,
	"flexColumnCenter": `IfpvKPX8q6nfER`,
	"gridColumn": `l_ACdizgZu7qSg`,
	"descriptionAtomGrid": `nGxTVskizG33Qz`,
	"listingAtomGrid": `CRzLwldedgbpl_`,
	"logoNameGrid": `z_2dpunNWoawUd`,
	"twoColumnGrid": `k8hoit1x4fIgen`,
	"nameContainer": `yTJdMKv0yx6M1b`,
	"name": `tbaLwxmcmFFhij`,
	"loadingSkeleton": `OPqBrmQCn800du`,
	"listingName": `RJOvcaXofwJcob`,
	"subtitle": `ffpHDGXWHCCRmI`,
	"icon": `ef5Ow3wEp2hV_4`,
	"badges": `lDLnCCYDs47Y8u`,
	"pupBadge": `k9ojPYuL3PPdHn`,
	"darkModeBadge": `vLZSlh9P1ty7yX`,
	"usageBadgeIcon": `DZRnKlHLfAcIE3`,
	"descriptionAtom": `Ck9gmZ2Uw87Mmq`,
	"featuredAtomContainer": `zFrCb4laTkOb9D`,
	"featuredAtom": `SWO8Z0NxwWvvzb`,
	"heroImage": `aRLylRBUxfePmQ`,
	"loadingSkeletonAnimation": `eJBzYjHdvkYRBw`,
	"loadingSkeletonButton": `z44iXTUrr29ixq`,
	"pupIntegrationBadge": `KkbpiBe6uuSR2V`
};
export default ___CSS_LOADER_EXPORT___;
