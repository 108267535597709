/* eslint-disable
    eqeqeq,
*/
// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS206: Consider reworking classes to avoid initClass
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */

import BluebirdPromise from 'bluebird';
import _ from 'underscore';
// eslint-disable-next-line no-restricted-imports
import parseURL from 'url-parse';

import type {
  ActionSubjectIdType,
  EventAttributes,
} from '@trello/atlassian-analytics';
import {
  Analytics,
  formatContainers,
  tracingCallback,
} from '@trello/atlassian-analytics';
import { Backgrounds } from '@trello/boards';
import { isEmbeddedInAtlassian, isTouch } from '@trello/browser';
import {
  ComponentWrapper,
  renderComponent,
  renderReactRoot,
} from '@trello/component-wrapper';
import { customFieldsId as CUSTOM_FIELDS_ID } from '@trello/config';
import { getDateBefore } from '@trello/dates';
import { setDynamicTokens } from '@trello/dynamic-tokens';
import { getBoardBackground } from '@trello/dynamic-tokens/dynamic-board-tokens';
import { PremiumFeatures } from '@trello/entitlements';
import { assert } from '@trello/error-handling';
import {
  sendChunkLoadErrorEvent,
  sendErrorEvent,
} from '@trello/error-reporting';
import { FavIcon } from '@trello/favicon';
import { featureFlagClient } from '@trello/feature-flag-client';
import { ApolloProvider } from '@trello/graphql';
import { TrelloIntlProvider } from '@trello/i18n';
import { idCache } from '@trello/id-cache';
import {
  BoardIdProvider,
  EnterpriseIdProvider,
  WorkspaceIdProvider,
} from '@trello/id-context';
import {
  biggestPreview,
  smallestPreviewBiggerThan,
  waitForImageLoad,
} from '@trello/image-previews';
// eslint-disable-next-line no-restricted-imports
import $ from '@trello/jquery';
import type { registerShortcutHandler } from '@trello/keybindings';
import { getKey, Key, unregisterShortcutHandler } from '@trello/keybindings';
import { showLabelsState } from '@trello/labels';
import { getScreenFromUrl } from '@trello/marketing-screens';
import { dangerouslyConvertPrivacyString } from '@trello/privacy';
import ReactDOM from '@trello/react-dom-wrapper';
import { navigate } from '@trello/router/navigate';
import { TrelloStorage } from '@trello/storage';
import { getGlobalTheme, GlobalThemeObserver } from '@trello/theme';
import { UnsplashTracker } from '@trello/unsplash';
import { isUrl } from '@trello/urls';
import { importWithRetry } from '@trello/use-lazy-component';
import { workspaceNavigationState } from '@trello/workspace-navigation';

// eslint-disable-next-line no-restricted-imports
import { Controller } from 'app/scripts/controller';
import { errorPage } from 'app/scripts/controller/errorPage';
import {
  isShowingBoardViewSection,
  isShowingBoardViewThatHasCards,
  showingCalendar,
} from 'app/scripts/controller/getCurrentBoardView';
import { getQueryParamsToKeepOnBoardView } from 'app/scripts/controller/getQueryParamsToKeepOnBoardView';
import { setBoardLocation } from 'app/scripts/controller/setBoardLocation';
import { setTitleAndLocation } from 'app/scripts/controller/setTitleAndLocation';
import { getBoardUrl } from 'app/scripts/controller/urls';
import { BUTLER_POWER_UP_ID } from 'app/scripts/data/butler-id';
import { LegacyPowerUps } from 'app/scripts/data/legacy-power-ups';
import { Auth } from 'app/scripts/db/Auth';
import { ModelLoader } from 'app/scripts/db/model-loader';
import { ModelCache } from 'app/scripts/db/ModelCache';
import { redoAction, undoAction } from 'app/scripts/lib/last-action';
import { filterByKeys, sortBySeverity } from 'app/scripts/lib/limits';
import { l } from 'app/scripts/lib/localize';
import { sendPluginScreenEvent } from 'app/scripts/lib/plugins/plugin-behavioral-analytics';
import { Util } from 'app/scripts/lib/util';
import { WindowSize } from 'app/scripts/lib/window-size';
import type { Board } from 'app/scripts/models/Board';
import { Card } from 'app/scripts/models/Card';
import type { List } from 'app/scripts/models/List';
import type { Member } from 'app/scripts/models/Member';
import Payloads from 'app/scripts/network/payloads';
import { LabelState } from 'app/scripts/view-models/LabelState';
import { BoardCleanUpView } from 'app/scripts/views/board/BoardCleanUpView';
import { ReopenBoardPopoverView } from 'app/scripts/views/board/ReopenBoardPopoverView';
import type { BoardSidebarView } from 'app/scripts/views/board-menu/BoardSidebarView';
import type { ButlerView } from 'app/scripts/views/butler/ButlerView';
import type { CalendarControllerView } from 'app/scripts/views/calendar/CalendarControllerView';
import { canAddCard } from 'app/scripts/views/card/CardLimitsError';
import { DirectoryView } from 'app/scripts/views/directory/DirectoryView';
import {
  getPermLevelAltTextForBoard,
  getPermLevelAltTextForTemplate,
  getPermLevelIconClassForBoard,
} from 'app/scripts/views/internal/BoardDisplayHelpers';
import { pluginRunner } from 'app/scripts/views/internal/plugins/PluginRunner';
import { pluginsChangedSignal } from 'app/scripts/views/internal/plugins/PluginsChangedSignal';
import { templates } from 'app/scripts/views/internal/templates';
import type { ViewOptions } from 'app/scripts/views/internal/View';
import { View } from 'app/scripts/views/internal/View';
import { Confirm } from 'app/scripts/views/lib/Confirm';
import { Dialog } from 'app/scripts/views/lib/Dialog';
import { DragSort } from 'app/scripts/views/lib/DragSort';
import { Layout } from 'app/scripts/views/lib/Layout';
import { PopOver } from 'app/scripts/views/lib/PopOver';
import type { MapView } from 'app/scripts/views/map/MapView';
import { MemberBoardProfileView } from 'app/scripts/views/member/MemberBoardProfileView';
import { PluginViewView } from 'app/scripts/views/plugin/PluginViewView';
import { BoardHeaderPermissionsTemplate } from 'app/scripts/views/templates/BoardHeaderPermissionsTemplate';
import { BoardHeaderWarningsTemplate } from 'app/scripts/views/templates/BoardHeaderWarningsTemplate';
import { BoardTemplate } from 'app/scripts/views/templates/BoardTemplate';
import { boardModelIdToElementState } from 'app/src/boardModelIdToElementState';
import { PossiblyRenderAboutThisBoardModal } from 'app/src/components/AboutThisBoardModal';
import { SingleBoardCalendarView } from 'app/src/components/BoardCalendarView';
import { LazyBoardClosed } from 'app/src/components/BoardClosed';
import { LazyBoardHeader } from 'app/src/components/BoardHeader';
import {
  dialogProps,
  LazyBoardInviteModal,
} from 'app/src/components/BoardInviteModal';
import { LazyBoardListView } from 'app/src/components/BoardListView';
import { BoardMembersContextProvider } from 'app/src/components/BoardMembersContext';
import { BoardPermissionsContextProvider } from 'app/src/components/BoardPermissionsContext';
import { BoardPluginsContextProvider } from 'app/src/components/BoardPluginsContext';
import { BoardSidebarLoadingError } from 'app/src/components/BoardSidebarLoadingError';
import { LazyTeamOnboardingPupPopover } from 'app/src/components/BusinessClassTeamOnboardingPupPopover';
import { AutomaticReports } from 'app/src/components/Butler';
import { collapsedListsState } from 'app/src/components/CollapsedListsState';
import { PossiblyRenderConfirmEmailModal } from 'app/src/components/ConfirmEmailModal';
import { setDocumentTitle } from 'app/src/components/DocumentTitle';
import { LazyLegacyJoinBoardModal as LegacyJoinBoardModal } from 'app/src/components/JoinBoardModal';
import {
  LabelsPopoverState,
  LazyLabelsPopover,
} from 'app/src/components/LabelsPopover';
import { DEFAULT_LIST_COLOR } from 'app/src/components/ListColorPicker';
import { SingleBoardTableView } from 'app/src/components/TableView/SingleBoardTableView';
import { TimelineViewWrapper } from 'app/src/components/TimelineViewWrapper';
import { toQueryStringWithDecodedFilterParams } from 'app/src/components/ViewFilters/toQueryStringWithDecodedFilterParams';
import { viewFiltersContextSharedState } from 'app/src/components/ViewFilters/viewFiltersContextSharedState';
import { getMarketingScreenInfo } from 'app/src/getMarketingScreenInfo';
import { stopPropagationAndPreventDefault } from 'app/src/stopPropagationAndPreventDefault';
import {
  BOARD_VIEW_BACKGROUND_CLASSES,
  CUSTOM_BOARD_BACKGROUND_CLASS,
  CUSTOM_BOARD_BACKGROUND_TILED_CLASS,
  DARK_BOARD_BACKGROUND_CLASS,
  GRADIENT_BOARD_BACKGROUND_CLASS,
  LIGHT_BOARD_BACKGROUND_CLASS,
  STATIC_COLOR_BACKGROUND_CLASS,
} from './boardViewBackgroundClasses';
import { DisplayBoardError } from './displayBoardError';
import { renderInviteeOrientation } from './renderInviteeOrientation';

const CALENDAR_POWER_UP_ID = LegacyPowerUps.calendar;

function showCardOnBoard(
  idCard: string,
  highlight: string | undefined,
  board: Board,
  replyToComment: string | undefined,
  loadCardData: (idCard: string) => BluebirdPromise<Card>,
) {
  return BluebirdPromise.try(function () {
    let card;
    if ((card = board.getCard(idCard)) != null) {
      // We're making the assumption here that if we can find the card, then we
      // don't need to call loadCardData.  If we don't have e.g.
      // the idBoard, idList or name then we know for sure that we've
      // violated that assumption.
      assert(
        card.get('idBoard') != null &&
          card.get('idList') != null &&
          card.get('name') != null,
        'Tried to display a card that was only partially loaded',
      );
      return card;
    } else {
      // eslint-disable-next-line @typescript-eslint/no-shadow
      return loadCardData(idCard).tap(function (card) {
        if (card.get('idBoard') !== board.id) {
          BluebirdPromise.reject(
            DisplayBoardError.CardNotFoundOnThisBoard('', {
              idBoard: board.id,
            }),
          );
        }
      });
    }
  }).then((card) => {
    const cardRole = card.getCardRole();

    const cardBackRoot = document.getElementById('react-root-card-back');
    if (cardBackRoot) {
      ReactDOM.unmountComponentAtNode(cardBackRoot);
    }

    if (cardRole === 'board') {
      navigate(parseURL(card.get('name')).pathname, { trigger: true });
    } else if (cardRole === 'separator' || cardRole === 'link') {
      navigate(getBoardUrl(card.get('idBoard')), { trigger: true });
    } else {
      Controller.showCardDetail(card, { highlight, replyToComment });
    }
  });
}

const clearBoardBackground = function () {
  $('#trello-root').removeClass(BOARD_VIEW_BACKGROUND_CLASSES).css({
    'background-image': '',
    'background-color': '',
  });
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const getBoardBackgroundUrl = (prefs: any) => {
  let url = prefs.backgroundImage;
  if (Object.prototype.hasOwnProperty.call(Backgrounds, prefs.background)) {
    // Special handling for our default backgrounds, to avoid using the
    // really huge versions
    url =
      smallestPreviewBiggerThan(prefs.backgroundImageScaled, 640, 480)?.url ||
      url;
  } else {
    url =
      biggestPreview(prefs.backgroundImageScaled, prefs.backgroundImage)?.url ||
      url;
  }

  let smallUrl = url;
  if (!prefs.backgroundTile) {
    smallUrl =
      smallestPreviewBiggerThan(prefs.backgroundImageScaled, 64, 64)?.url ||
      prefs.backgroundImage;
  }

  return { url, smallUrl };
};

const renderModernizedLists = function (this: BoardView) {
  // If the corresponding unmount method has already been defined, the lists
  // view may have already been rendered, so first unmount it.
  // We've observed that this method can be called multiple times in rapid
  // succession, notably when creating a board, so this safety mechanism is
  // important for ensuring that this component is always unmounted.
  this.unmountLists?.call(this);

  const mountPoint = $('.js-list-container', this.$el);
  const { unmount } = renderReactRoot(
    <TrelloIntlProvider>
      <ApolloProvider>
        <EnterpriseIdProvider value={this.model.get('idEnterprise') ?? null}>
          <WorkspaceIdProvider value={this.model.get('idOrganization') ?? null}>
            <BoardIdProvider
              value={{
                boardId: this.model.get('id'),
                boardNodeId: this.model.get('nodeId'),
              }}
            >
              <BoardMembersContextProvider>
                <BoardPermissionsContextProvider>
                  <BoardPluginsContextProvider>
                    <LazyBoardListView />
                  </BoardPluginsContextProvider>
                </BoardPermissionsContextProvider>
              </BoardMembersContextProvider>
            </BoardIdProvider>
          </WorkspaceIdProvider>
        </EnterpriseIdProvider>
      </ApolloProvider>
    </TrelloIntlProvider>,
    mountPoint[0],
  );

  this.unmountLists = function (this: BoardView) {
    unmount();
    delete this.unmountLists;
  };

  return this;
};

const getButlerUrl = function (
  idBoard: string,
  { tab = null, edit = null, log = null, usage = null, account = null } = {},
) {
  const extras = [];
  if (tab) {
    extras.push(tab);
  }
  if (edit && edit === 'new' && tab) {
    extras.push('new');
  } else if (edit) {
    extras.push('edit', edit);
  } else if (log) {
    extras.push('log', log);
  } else if (usage) {
    extras.push('usage', usage);
  } else if (account) {
    extras.push('account', account);
  }
  const boardUrl = getBoardUrl(idBoard, 'butler', extras);
  return boardUrl;
};

const showBoardListView = () => {
  $('.js-list-container').removeClass('hide');
};

const hideBoardListView = () => {
  // When the board canvas modernization flag is enabled, we should hide the
  // _container_ for lists, rather than the element with board ID, as the latter
  // is rendered via React, and may not be targetable yet.
  // The board controller refactor will hopefully make this redundant.
  $('.js-list-container').addClass('hide');
};

interface BoardView {
  butlerView: ButlerView | null;
  calendarView: CalendarControllerView | null;
  calendarReturnCard: Card | null | undefined;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  collectionSubview: <T extends new (...args: any) => any>(
    view: T,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    collection: any,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    options: any,
  ) => InstanceType<T>;
  directoryView: DirectoryView | null;
  directoryReturnCard: Card | null | undefined;
  globalThemeObserver: GlobalThemeObserver;
  ignoreMouseCardSelects: boolean;
  mapCenterTo: {
    lat: string;
    lng: string;
  } | null;
  mapReturnCard: Card | null | undefined;
  mapView: MapView | null;
  memberTypeMe: string;
  model: Board;
  pluginView: PluginViewView | null;
  refreshOrganizationDebounced: typeof BoardView.prototype.refreshOrganization;
  reloadCustomFieldsDebounced: typeof BoardView.prototype.reloadCustomFields;
  reloadDirectoryPlugins: boolean;
  renderBoardBackgroundDebounced: typeof BoardView.prototype.renderBoardBackground;
  renderReactBoardHeaderDebounced: typeof BoardView.prototype.renderReactBoardHeader;
  renderBoardWarningsDebounced: typeof BoardView.prototype.renderBoardWarnings;
  renderDebounced: () => void;
  rerenderListsDebounced: typeof BoardView.prototype.rerenderLists;
  renderPermissionLevelDebounced: typeof BoardView.prototype.renderPermissionLevel;
  setDynamicTokensOnBoardViewDebounced: () => void;
  sidebarView: BoardSidebarView;
  sidebarViewPromise: Promise<{ BoardSidebarView: typeof BoardSidebarView }>;
  subscribe: (eventName: string, callback: () => void) => void;
  teamOnboardingPupPopoverDiv: HTMLDivElement | null;
  unmountBoardClosed: ReturnType<typeof renderComponent> | null;
  unmountConfirmEmailModal: ReturnType<typeof renderComponent> | null;
  unmountJoinBoardModal: ReturnType<typeof renderComponent> | null;
  unmountLabelsPopover: ReturnType<typeof renderComponent> | null;
  unmountAboutThisBoardModel: ReturnType<typeof renderComponent> | null;
  unmountLists?: ReturnType<typeof renderComponent> | null;
  unsubscribeViewFiltersContext: ReturnType<
    typeof viewFiltersContextSharedState.subscribe
  >;
  _isFirstBoardEverLoaded(): boolean;
  _shouldCloseBoardMenu(): boolean;
  shouldCloseBoardMenu: boolean;
  _isGhostUsingInviteLink(): boolean;
  showSidebar(): void;
  hideSidebar(): void;
  setSidebarShow(): void;
  showSidebarOnOpen(): void;
  renderBoardWarnings(): void;
  renderLists(): void;
  _sidebarHideTimeout: number;
  zoom: number;
}

interface BoardViewOptions extends ViewOptions {
  settings?: {
    menu: 'closed' | 'filter' | 'activity';
    filter: string;
  } | null;
}

class BoardView extends View<Board, BoardViewOptions> {
  static initClass() {
    // @ts-expect-error TS(2322): Type 'string' is not assignable to type '() => str... Remove this comment to see the full error message
    this.prototype.className = 'board-wrapper';

    // @ts-expect-error TS(2339): Property 'displayName' does not exist on type 'Boa... Remove this comment to see the full error message
    this.prototype.displayName = 'BoardView';

    this.prototype.events = {
      // @ts-expect-error TS(2322): Type '{ sortcommit: string; 'keydown .js-board-nam... Remove this comment to see the full error message
      sortcommit: 'sortCommit',

      'click .js-close-calendar': 'toggleCalendar',
      'click .js-calendar': 'toggleCalendar',
      'click .js-disable-feedback': 'dismissFeedback',
      'click .js-close-dashboard': 'navigateToBoard',

      'click .js-dismiss-warning'(e: JQuery.TriggeredEvent) {
        TrelloStorage.set(
          // @ts-expect-error TS(2339): Property '$el' does not exist on type '() => { [ke... Remove this comment to see the full error message
          $(e.currentTarget, this.$el).attr('data-dismiss'),
          Date.now(),
        );
        // @ts-expect-error TS(2339): Property 'renderBoardWarnings' does not exist on t... Remove this comment to see the full error message
        return this.renderBoardWarnings();
      },

      'dblclick #board': 'dblClickAddListMenu',
      'click #board': 'blurInputsOnBoardClick',

      'click .js-reopen': 'reopen',
      'click .js-delete': 'delete',

      'click .js-clean-up': 'showCleanUp',

      'prevent-scroll-selection': 'preventScrollSelection',

      'click .js-close-directory': 'clickCloseDirectory',
    };
  }

  isTemplate() {
    return this.model.isTemplate();
  }

  _applySettings() {
    if (this.options.settings == null) {
      return;
    }

    const { menu, filter } = this.options.settings;

    switch (menu) {
      case 'closed':
        this.model.viewState.setShowSidebar(false);
        break;
      case 'filter':
      case 'activity':
        this.sidebarViewPromise.then(() => {
          this.setSidebarShow();
          return this.sidebarView.pushView(menu);
        });
        break;
      default:
    }

    if (filter) {
      return this.model.filter.fromQueryString(filter);
    }
  }

  permChange(newPerm: string) {
    if (newPerm === 'none') {
      // TODO: Need to replace this with a navigate(...) call.
      Controller.memberHomePage();
    } else {
      // Non-board views and the powerups screen aren't re-rendered when
      // this.render() is called. This is a workaround to avoid a bug where
      // permissions were changing shortly after loading a board and the views
      // were disappearing.
      if (!isShowingBoardViewSection('board')) {
        this.render();
      }

      // If we can't edit the board, hide the card composer (if it's open)
      if (!this.model.editable()) {
        this.model.composer.save('vis', false);
      }
    }
  }

  openListComposer() {
    this.model.viewState.openListComposer();
  }

  openCardComposerInFirstList() {
    const firstList = this.model.listList.first();

    this.model.composer.save({
      list: firstList,
      index: firstList.openCards().length,
      vis: true,
    });
    Analytics.sendUIEvent({
      action: 'opened',
      actionSubject: 'cardComposer',
      source: 'boardScreen',
      attributes: {
        method: 'firstList',
      },
    });
  }

  scrollToList(list: List) {
    const listElement = boardModelIdToElementState.value.lists.get(
      list.get('id'),
    );
    if (!listElement) {
      return;
    }

    listElement.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
  }

  scrollToCard(card: Card) {
    const list = card.getList();
    this.scrollToList(list);

    const cardElement = boardModelIdToElementState.value.cards.get(
      card.get('id'),
    );

    if (!cardElement) {
      return;
    }

    cardElement.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
  }

  preventScrollSelection() {
    // So here's the deal.
    //
    // When you're selecting cards with the keyboard, we'll scroll the
    // board and the lists on it to ensure the currently selected card
    // is always visible.
    //
    // As a result of this scrolling, it's possible that the mouseenter event
    // will fire on a CardView -- which, if you look above at the handling of
    // mouseEnterCardView, will cause us to mark the newly hovered card as
    // selected, even though you didn't actually move the mouse at all.
    //
    // So we remember that we've just selected something with the keyboard,
    // and we should ignore mouse events until the next time the user moves
    // the mouse.
    //
    // But.
    //
    // Chrome will fire mousemove events even when the mouse *hasn't* moved,
    // as a result of the scroll.
    //
    // https://code.google.com/p/chromium/issues/detail?id=241476
    //
    // So we can't just wait for the next mousemove event -- we have to wait
    // for the next mousemove event that has occurred as the result of the
    // user moving the mouse.
    //
    // Detecting that is a bit of a hack, but appears to work well enough for
    // now.
    //
    // IE 10 will apparently just fire mousemove events constantly, even
    // when a scroll doesn't occur. I don't know how to fix that. I can't find
    // a good workaround for IE 10. So that's just not gonna work.

    if (this.ignoreMouseCardSelects) {
      return;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const isResultOfActualPhysicalMouseMovement = function (e: any) {
      const { movementX: x, movementY: y } = e.originalEvent;
      return x !== 0 || y !== 0;
    };

    this.ignoreMouseCardSelects = true;

    const bindOnce = () => {
      return $(document).one('mousemove', (e) => {
        if (isResultOfActualPhysicalMouseMovement(e)) {
          this.ignoreMouseCardSelects = false;
        } else {
          bindOnce();
        }
      });
    };

    bindOnce();
  }

  moveCard(direction: 'up' | 'down' | 'left' | 'right') {
    let card, newCardIndex;
    this.preventScrollSelection();

    const selectedCard = this.model.viewState.getCard();

    if (selectedCard == null) {
      // Select the first card in the first list that has a card
      const listWithCards = this.model.listList.find(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (list: any) => !list.openCards().isEmpty(),
      );
      if (listWithCards != null) {
        listWithCards.selectFirstCardInList();
      }
      return;
    }

    const selectedList = selectedCard.getList();

    const openCards = selectedList.openCards();
    const cardIndex = openCards.indexOf(selectedCard);

    const findNextGoodIndex = (
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      currentIndex: any,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      indexDelta: any,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      upperBound: any,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      fxIndexIsGood: any,
    ) => {
      let newIndex = currentIndex + indexDelta;
      if (this.model.filter.isFiltering()) {
        while (
          0 <= newIndex &&
          newIndex < upperBound &&
          !fxIndexIsGood(newIndex)
        ) {
          newIndex += indexDelta;
        }
      }
      // We just return something out of bounds if there's no index that works
      return newIndex;
    };

    if (['up', 'down'].includes(direction)) {
      const indexDelta = direction === 'up' ? -1 : 1;
      newCardIndex = findNextGoodIndex(
        cardIndex,
        indexDelta,
        openCards.length,
        /* eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-shadow */
        (newCardIndex: any) => {
          return this.model.filter.satisfiesFilter(openCards.at(newCardIndex));
        },
      );

      if (0 <= newCardIndex && newCardIndex < openCards.length) {
        card = openCards.at(newCardIndex);
        this.scrollToCard(card);
        return this.model.viewState.selectCard(card);
      }
    } else if (['left', 'right'].includes(direction)) {
      const openLists = this.model.listList;
      const listIndex = openLists.indexOf(selectedList);

      const listIndexDelta = direction === 'left' ? -1 : 1;
      const newListIndex = findNextGoodIndex(
        listIndex,
        listIndexDelta,
        openLists.length,
        /* eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-shadow */
        (newListIndex: any) => {
          return (
            openLists
              .at(newListIndex)
              .openCards()
              /* eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-shadow */
              .any((card: any) => {
                return this.model.filter.satisfiesFilter(card);
              })
          );
        },
      );
      if (0 <= newListIndex && newListIndex < openLists.length) {
        const newList = openLists.at(newListIndex);
        const newListOpenCards = newList.openCards();
        const newCardIndexStart = Math.min(
          cardIndex,
          newListOpenCards.length - 1,
        );
        return (() => {
          const result = [];
          for (const delta of [1, -1]) {
            newCardIndex = findNextGoodIndex(
              newCardIndexStart - delta,
              delta,
              newListOpenCards.length,
              /* eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-shadow */
              (newCardIndex: any) => {
                return this.model.filter.satisfiesFilter(
                  newListOpenCards.at(newCardIndex),
                );
              },
            );
            if (0 <= newCardIndex && newCardIndex < newListOpenCards.length) {
              card = newListOpenCards.at(newCardIndex);
              this.model.viewState.selectCard(card);
              this.scrollToCard(card);
              break;
            } else {
              result.push(undefined);
            }
          }
          return result;
        })();
      }
    }
  }

  renderTeamOnboardingPupPopover() {
    try {
      const workspace = this.model.getOrganization();

      if (workspace == null) {
        return;
      }

      if (this.teamOnboardingPupPopoverDiv == null) {
        const targetDiv = this.el.querySelector(
          '#team-onboarding-pup-popover-target',
        ) as HTMLDivElement;

        if (targetDiv != null) {
          this.teamOnboardingPupPopoverDiv = targetDiv;
        } else {
          $('.board-main-content', this.$el).append(
            "<div id='team-onboarding-pup-popover-target'></div>",
          );
          this.teamOnboardingPupPopoverDiv = this.el.querySelector(
            '#team-onboarding-pup-popover-target',
          );
        }
      }

      const boardId = this.model.get('id');

      return renderComponent(
        <LazyTeamOnboardingPupPopover
          workspaceId={workspace.id}
          boardId={boardId}
          onShowPowerUps={() => this.toggleDirectory()}
        />,
        this.teamOnboardingPupPopoverDiv,
      );
    } catch (err) {
      sendErrorEvent(err, {
        tags: {
          ownershipArea: 'trello-web-eng',
          feature: 'Team Onboarding',
        },
        extraData: {
          component: 'board-view-team-onboarding-pups',
        },
      });
    }
  }

  unmountTeamOnboardingPupPopover() {
    if (this.teamOnboardingPupPopoverDiv != null) {
      return ReactDOM.unmountComponentAtNode(this.teamOnboardingPupPopoverDiv);
    }
  }

  _shouldCloseBoardMenu() {
    // @ts-expect-error TS(2362): The left-hand side of an arithmetic operation must... Remove this comment to see the full error message
    const isNewBoard = new Date() - Util.idToDate(this.model.id) < 180000;

    // if a previous render of this board marked it as needing its menu closed,
    // keep it closed until the user opens it themselves
    return (
      this.shouldCloseBoardMenu ||
      // close the menu of the first board they ever load
      !Auth.me().isDismissed('close-menu-of-first-board') ||
      // close menu of first board ever created, which may not be the same as the
      // first board they ever load, because of invitations
      (isNewBoard && this.model.isFirstOwnedBoard())
    );
  }

  _isFirstBoardEverLoaded() {
    return (
      !Auth.me().isDismissed('close-menu-of-first-board') &&
      !this.model.isWelcomeBoard()
    );
  }

  _isGhostUsingInviteLink() {
    return Util.hasValidInviteTokenFor(this.model);
  }

  renderOpen() {
    this.showSidebarOnOpen();

    const data: {
      url: string;
      canJoin: boolean;
      canInviteMembers: boolean;
      confirmed: boolean;
    } = {
      url: getBoardUrl(this.model),
      canJoin: this.model.canJoin(),
      canInviteMembers: this.model.canInviteMembers(),
      confirmed: Auth.me().get('confirmed'),
    };
    for (const preference of Array.from(
      this.model.prefNames.concat(this.model.myPrefNames),
    )) {
      // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      data[`${preference}_${this.model.getPref(preference)}`] = true;
    }

    this.$el.html(templates.fillFromModel(BoardTemplate, this.model));

    this.renderReactBoardHeader();
    this.renderBoardWarnings();
    this.renderBoardBackground();

    // On Backbone renders, running showBoardDetailView mounts the correct view
    // (table, maps, powerups, etc) from the URL, otherwise it will disappear.
    // @ts-expect-error TS(2339): Property 'boardPageSettings' does not exist on typ... Remove this comment to see the full error message
    const args = { ...this.options, ...Controller.boardPageSettings };
    this.showBoardDetailView(args);

    // this prevents the sidebar from animating in when first rendering.
    if (this.model.viewState.get('showSidebar') && !isEmbeddedInAtlassian()) {
      this.$el.addClass('is-show-menu');
    }

    this.sidebarViewPromise.then(() => {
      this.appendSubview(this.sidebarView, $('.js-fill-board-menu', this.$el));
      return this.renderSidebarState();
    });

    this.renderLists();
    renderInviteeOrientation({
      $el: this.$el,
      el: this.el,
      idBoard: this.model.id,
    });

    // @ts-expect-error TS(2345): Argument of type '{ title: any; }' is not assignab... Remove this comment to see the full error message
    setTitleAndLocation({ title: data.name });
  }

  showSidebarOnOpen() {
    // Orient visitors to public boards if About This Board is enabled
    const fromPrefOrNotLoggedIn = Auth.me().get('notLoggedIn');
    const hasDesc = !!this.model.get('desc');
    const isMember = this.model.isMember(Auth.me());

    if (
      this.model.isPublic() &&
      !isMember &&
      hasDesc &&
      fromPrefOrNotLoggedIn &&
      !isTouch()
    ) {
      return this.model.viewState.set('showSidebar', true);
    } else if (this._shouldCloseBoardMenu() || isTouch()) {
      this.model.viewState.set('showSidebar', false);
      if (this._isFirstBoardEverLoaded() && !this._isGhostUsingInviteLink()) {
        this.shouldCloseBoardMenu = true;
        if (Auth.isLoggedIn()) {
          return Auth.me().dismiss('close-menu-of-first-board');
        }
      }
    } else {
      return this.model.viewState.setShowSidebarFromPref();
    }
  }

  renderSidebarState() {
    if (this.model.viewState.get('showSidebar') && !isEmbeddedInAtlassian()) {
      this.showSidebar();
    } else {
      this.hideSidebar();
    }
  }

  toggleSidebar() {
    return this.model.viewState.setShowSidebar(
      !this.model.viewState.get('showSidebar'),
    );
  }

  setSidebarShow() {
    this.model.viewState.setShowSidebar(true);
    this.shouldCloseBoardMenu = false;
  }

  showSidebar() {
    // Hacky... the entire transition will be hidden if
    // there isn't a slight delay between un-hiding and
    // enabling the transition class.
    clearTimeout(this._sidebarHideTimeout);
    $('.js-fill-board-menu', this.$el).removeClass('hide');
    this.defer(() => {
      return this.$el.addClass('is-show-menu');
    });

    this.sidebarViewPromise.then(() => {
      return this.sidebarView.sendDrawerScreenEvent(
        this.sidebarView.topSidebarViewName(),
        'showSidebar',
      );
    });
  }

  hideSidebar() {
    this.$el.removeClass('is-show-menu');
    clearTimeout(this._sidebarHideTimeout);
    // Even though the is-show-menu class moves the menu out of view, we want
    // to also *actually* hide it, so the browser doesn't does something weird
    // when the user does something like Ctrl+F for text that appears in the
    // sidebar.
    //
    // We're delaying the hide because we're giving the CSS transition time to
    // complete.  We could try to trigger off of the transitionEnd event but
    // that can be problematic (if another transition starts before the first
    // one finishes or doesn't happen at all because there isn't actually a
    // change in the property being transitioned)
    //
    // The timeout value we're using here isn't meant to match the length of the
    // transition, but hopefully it's longer than whatever the transition is,
    // but not so short that someone could reasonably hide the menu and then
    // Ctrl+F for something that was on it
    // @ts-expect-error
    this._sidebarHideTimeout = this.setTimeout(() => {
      $('.js-fill-board-menu', this.$el).addClass('hide');
    }, 250);
  }

  renderOrgChanges() {
    if (this.model.get('closed')) {
      return this.renderClosed();
    } else {
      return this.renderReactBoardHeader();
    }
  }

  refreshOrganization() {
    const oldOrganization = ModelCache.get(
      'Organization',
      this.model.previous('idOrganization'),
    );
    const organization = this.model.getOrganization();

    if (oldOrganization != null) {
      this.stopListening(oldOrganization);
    }

    if (organization != null) {
      for (const prop of [
        'name',
        'logoHash',
        'displayName',
        'limits',
        'products',
      ]) {
        this.listenTo(
          organization,
          `change:${prop}`,
          this.frameDebounce(this.renderOrgChanges),
        );
      }
    }

    return this.renderOrgChanges();
  }

  reloadCustomFields() {
    if (this.model.isPluginEnabled(CUSTOM_FIELDS_ID)) {
      // we need to give the server a second to prevent any race conditions
      // mainly if we were the ones to enable custom fields
      return setTimeout(() => {
        return ModelLoader.loadCustomFields(this.model.id);
      }, 1000);
    }
  }

  renderReactBoardHeader() {
    const headerTarget = this.el.querySelector('.js-board-header');

    if (!headerTarget) {
      return;
    }

    const sidebarVisible = this.model.viewState.get('showSidebar');
    const showSidebar = () => this.model.viewState.setShowSidebar(true);
    renderComponent(
      <BoardIdProvider
        value={{
          boardId: this.model.get('id'),
          boardNodeId: this.model.get('nodeId'),
        }}
      >
        <LazyBoardHeader
          legacyBoardModel={this.model}
          isSidebarVisible={sidebarVisible}
          showSidebar={showSidebar}
        />
      </BoardIdProvider>,
      headerTarget,
    );
  }

  removeReactBoardHeader() {
    if ($('.js-board-header', this.$el).length) {
      return ReactDOM.unmountComponentAtNode(
        // @ts-expect-error
        this.el.querySelector('.js-board-header'),
      );
    }
  }

  renderBoardWarnings() {
    const $warnings = $('.js-board-warnings', this.$el);

    const perBoardLimits = sortBySeverity(
      filterByKeys(this.model.get('limits'), [
        'cards.openPerBoard',
        'cards.totalPerBoard',
        'checklists.perBoard',
        'labels.perBoard',
        'lists.openPerBoard',
        'lists.totalPerBoard',
        'attachments.perBoard',
      ]).filter((limit) => limit.status !== 'ok'),
    );
    const templateMap = {
      'cards.openPerBoard': 'open-cards-per-board-limit',
      'cards.totalPerBoard': 'total-cards-per-board-limit',
      'checklists.perBoard': 'checklists-per-board-limit',
      'labels.perBoard': 'labels-per-board-limit',
      'lists.openPerBoard': 'open-lists-per-board-limit',
      'lists.totalPerBoard': 'total-lists-per-board-limit',
      'attachments.perBoard': 'attachments-per-board-limit',
    };
    const minDismiss = getDateBefore({ months: 6 });

    const sortedWarnings: [string, { key: string; minDismiss: Date }][] =
      perBoardLimits.map(function (limit) {
        const name = limit.key;
        const status = limit.status === 'warn' ? 'warn' : 'disabled';
        const key = `${templateMap[limit.key]}-${status}`;
        return [name, { key, minDismiss }];
      });

    const dismissWarningName = (name: string) => {
      return `BoardWarning-${name}-${this.model.id}`;
    };

    const highestPriorityNonDismissedWarning = sortedWarnings
      .filter((warning) => {
        const [name, { minDismiss: _minDismiss }] = warning;
        if (this.model.editable()) {
          const dismissed = TrelloStorage.get(dismissWarningName(name));
          return !dismissed || dismissed < _minDismiss;
        } else {
          return false;
        }
      })
      .map(function (warning) {
        const [name, { key }] = warning;
        return { name, key, dismiss: dismissWarningName(name) };
      })[0];

    // Unset values in local storage for boards that the user can no longer edit
    sortedWarnings.forEach((warning) => {
      const [name] = warning;
      if (
        !this.model.editable() &&
        TrelloStorage.get(dismissWarningName(name)) != null
      ) {
        TrelloStorage.unset(dismissWarningName(name));
      }
    });

    if (highestPriorityNonDismissedWarning) {
      $warnings.removeClass('hide').html(
        BoardHeaderWarningsTemplate({
          canDismiss: TrelloStorage.isEnabled(),
          warning: highestPriorityNonDismissedWarning,
        }),
      );
    } else {
      $warnings.addClass('hide');
    }

    return this;
  }

  renderBoardBackground(waitForImage: boolean = false) {
    clearBoardBackground();

    const prefs = this.model.get('prefs');
    const {
      background,
      backgroundBrightness,
      backgroundImage,
      backgroundColor,
      backgroundTile,
      backgroundTopColor,
      backgroundBottomColor,
    } = prefs;

    const $body = $('#trello-root');

    if (backgroundBrightness === 'light') {
      $body.addClass(LIGHT_BOARD_BACKGROUND_CLASS);
    } else if (backgroundBrightness === 'dark') {
      $body.addClass(DARK_BOARD_BACKGROUND_CLASS);
    }

    // @ts-expect-error
    if (background.startsWith('gradient-')) {
      const gradientUrl = backgroundImage;

      $body.addClass(GRADIENT_BOARD_BACKGROUND_CLASS);

      // @ts-expect-error
      $body.css({
        'background-color': backgroundColor,
        'background-image': `url("${gradientUrl}")`,
      });

      FavIcon.setBackground({
        color: backgroundColor,
        topColor: backgroundTopColor,
        bottomColor: backgroundBottomColor,
      });

      return;
    }

    if (backgroundColor) {
      $body.addClass(STATIC_COLOR_BACKGROUND_CLASS);

      $body.css({
        'background-color': backgroundColor,
      });

      FavIcon.setBackground({ color: backgroundColor });

      return;
    }

    const { url, smallUrl } = getBoardBackgroundUrl(prefs);

    const changeBackgroundImage = () => {
      $body.addClass(CUSTOM_BOARD_BACKGROUND_CLASS);

      if (backgroundTile) {
        $body.addClass(CUSTOM_BOARD_BACKGROUND_TILED_CLASS);
      }

      $body.css({
        'background-image': `url("${url}")`,
      });

      UnsplashTracker.trackOncePerInterval(url);

      FavIcon.setBackground({
        url: smallUrl,
        tiled: backgroundTile,
      });
    };

    if (waitForImage) {
      waitForImageLoad(url).then(changeBackgroundImage);
    } else {
      changeBackgroundImage();
    }
  }

  renderPermissionLevel() {
    const $perm = $('#permission-level', this.$el);
    const data = {
      permIconClass: getPermLevelIconClassForBoard(this.model),
      permText: l(['board perms', this.model.getPermLevel(), 'name']),
      showText: !isEmbeddedInAtlassian(),
    };

    $perm.html(BoardHeaderPermissionsTemplate(data));

    if (this.model.isTemplate()) {
      $perm.attr('title', getPermLevelAltTextForTemplate(this.model));
    } else {
      $perm.attr('title', getPermLevelAltTextForBoard(this.model));
    }

    const canInvite = this.model.canInvite(Auth.me());
    $('.js-manage-board-members', this.$el).toggle(canInvite);

    return this;
  }

  postListListRender() {
    this.renderFilteringStatus();
    return DragSort.refreshListCardSortable();
  }

  /**
   * Renders lists on the board view. This method should only be called once, on
   * initial load, with subsequent calls deferred to {@link rerenderLists}, as
   * the modernized board canvas does not need to be rerendered.
   */
  renderLists() {
    return renderModernizedLists.call(this);
  }

  rerenderLists() {
    // The modernized board canvas does not need to be explicitly rerendered.
    return this;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  sortCommit(e: any, ui: any) {
    ui.item.trigger('movelist', ui);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  openAddMembers(e: any) {
    if (e) {
      stopPropagationAndPreventDefault(e);
    }

    const idOrganization = this.model.getOrganization()?.id;
    Analytics.sendClickedButtonEvent({
      buttonName: 'shareButton',
      source: 'boardScreen',
      containers: formatContainers({
        idBoard: this.model.id,
        idOrganization,
        workspaceId: idOrganization,
      }),
    });

    Dialog.show({
      reactElement: (
        <ComponentWrapper>
          <LazyBoardInviteModal
            idBoard={this.model.id}
            idOrg={idOrganization}
            onClose={Dialog.hide}
          />
        </ComponentWrapper>
      ),
      ...dialogProps,
    });
  }

  toggleCalendar() {
    if (this.calendarView) {
      if (this.calendarReturnCard) {
        this.closeCalendar(false);
        Controller.showCardDetail(this.calendarReturnCard);
        return (this.calendarReturnCard = null);
      } else {
        return this.navigateToBoardEvent();
      }
    } else {
      return this.navigateToCalendarEvent();
    }
  }

  dismissFeedback() {
    Auth.me().setPluginDataByKey(
      CALENDAR_POWER_UP_ID,
      'private',
      'dismissedFeedback',
      true,
    );
    $('.calendar-header-toolbar-feedback', this.$el).addClass('hide');
  }

  navigateToCalendarEvent() {
    sendPluginScreenEvent({
      idPlugin: LegacyPowerUps.calendar,
      idBoard: this.model.id,
      screenName: 'calendarViewScreen',
    });
    return this.navigateToCalendar();
  }

  navigateToCalendar(returnCard?: Card) {
    this.calendarReturnCard = returnCard;
    const calendarBoardUrl = getBoardUrl(this.model.id, 'calendar');
    return navigate(calendarBoardUrl, { trigger: true });
  }

  navigateToBoardEvent() {
    return this.navigateToBoard();
  }

  navigateToBoard() {
    const boardUrl = getBoardUrl(this.model.id);
    return navigate(boardUrl, { trigger: true });
  }

  isShowingCalendar() {
    return this.calendarView != null;
  }

  navigateToMap(
    centerTo: {
      lat: string;
      lng: string;
    },
    returnCard: Card,
  ) {
    this.mapCenterTo = centerTo;
    this.mapReturnCard = returnCard;
    const mapBoardUrl = getBoardUrl(this.model.id, 'map');
    return navigate(mapBoardUrl, { trigger: true });
  }

  isButlerViewActive() {
    return !!this.butlerView;
  }

  /**
   * Toggles the display of butler-client. If currently displayed, this will
   * close it; otherwise this will open it to the default tab
   */
  toggleButlerView() {
    if (this.butlerView) {
      return this.closeButler();
    }
    const butlerUrl = getButlerUrl(this.model.id);
    return navigate(butlerUrl, { trigger: true });
  }

  /**
   * Retrieves the iframe url for butler-client
   * this should only be called by showBoardDetailView.
   * To open butler-client from elsewhere, either navigateToButlerView or
   * toggleButlerView should be used
   * @param {Object} [obj] optional Butler parameters
   * @param {string} [obj.butlerTab] the Butler tab to open
   * @param {string} [obj.butlerCmdEdit] the Butler automation to open in edit mode
   * @param {string} [obj.butlerCmdLog] the Butler automation log to open
   * @param {string} [obj.newCommand] the string (lz-compressed or not) for a new Butler automation
   * @param {string} [obj.newIcon] the icon for a new Butler automation
   * @param {string} [obj.newLabel] the label for a new Butler automation
   * @param {string | undefined} [obj.taskId] the traceId of a capability to track opening the dashboard
   * @param {string | undefined} [obj.source] the source for the capability
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  showButlerView(options?: any) {
    // Since we defined type this:any in the function signature, we need to move
    // the defaults out of the signature to avoid incorrect TS transpilation
    const {
      butlerTab,
      butlerCmdEdit,
      butlerCmdLog,
      newCommand,
      butlerUsage,
      butlerAccount,
      newIcon,
      newLabel,
      taskId,
      source,
    } = options || {};
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let traceId: any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let traceSource: any;
    if (this.butlerView != null) {
      return;
    }
    if (!this.model.editable()) {
      this.navigateToBoardEvent();
      return;
    }
    if (taskId) {
      // get rid of the taskId/source url parameters
      this.navigateToButlerView(
        {
          tab: butlerTab,
          edit: butlerCmdEdit,
          log: butlerCmdLog,
          usage: butlerUsage,
          account: butlerAccount,
          newCommand,
          newIcon,
          newLabel,
        },
        false,
      );
      traceId = taskId;
      traceSource = source;
    } else {
      // no task Id, so this was probably a deep link
      traceSource = 'boardScreen';
      traceId = Analytics.startTask({
        taskName: 'view-butlerDashboard',
        source: traceSource,
      });
    }

    pluginRunner
      .one({
        plugin: BUTLER_POWER_UP_ID,
        command: 'show-settings',
        board: this.model,
        options: {
          butlerTab,
          butlerCmdEdit,
          butlerCmdLog,
          butlerUsage,
          butlerAccount,
          newCommand,
          newIcon,
          newLabel,
        },
      })
      .then((res) => {
        const showButler = this.model.canShowButlerUI();
        // In the case showButler is true lets open the Butler dashboard
        if (showButler) {
          return this.setButlerView(res, traceId, traceSource);
        }
        // Otherwise let's close the Butler dashboard and redirect them to the
        // board. They will still receive an alert letting them know that the
        // Butler dashboard has been restricted to only enterprise admins by their
        // Enterprise.
        this.closeButler();
        this.navigateToBoard();
      })
      .catch((error) => {
        Analytics.taskFailed({
          taskName: 'view-butlerDashboard',
          traceId,
          source: traceSource,
          error,
        });
        throw error;
      });
  }

  /**
   * Creates and renders the butler view
   * this should only be called by showButlerView
   * @param {string} url iframe url for butler-client
   * @param {string | undefined} [taskId] the traceId of a capability to track opening the dashboard
   * @param {string | undefined} [source] the source for the capability
   */
  setButlerView(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    url: any,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    taskId: any,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    source: any,
  ) {
    if (!isUrl(url)) {
      this.navigateToBoard();
      return;
    }
    hideBoardListView();
    return importWithRetry(
      () =>
        import(
          /* webpackChunkName: "butler-view" */ 'app/scripts/views/butler/ButlerView'
        ),
    ).then(({ ButlerView }) => {
      if (this.butlerView) {
        this.butlerView.remove();
      }
      this.butlerView = new ButlerView({ butlerUrl: url });
      $('.board-canvas', this.$el).append(this.butlerView.render().el);
      if (taskId && source) {
        Analytics.taskSucceeded({
          taskName: 'view-butlerDashboard',
          traceId: taskId,
          source,
        });
      }
    });
  }

  navigateToButler() {
    const butlerBoardUrl = getBoardUrl(this.model.id, 'butler');
    return navigate(butlerBoardUrl, { trigger: true });
  }

  /**
   * Navigates the user to butler-client for a given board with optional parameters
   * @param {string} idBoard the id of the board
   * @param {Object} [obj] optional Butler parameters
   * @param {ButlerTab | undefined} [obj.tab] the Butler tab to open
   * @param {string | undefined} [obj.edit] the Butler automation to open in edit mode
   * @param {string | undefined} [obj.log] the Butler automation log to open
   * @param {string | undefined} [obj.newCommand] the string (lz-compressed or not) for a new Butler automation
   * @param {string | undefined} [obj.newIcon] the icon for a new Butler automation
   * @param {string | undefined} [obj.newLabel] the label for a new Butler automation
   * @param {string | undefined} [obj.taskId] the traceId of a capability to track opening the dashboard
   * @param {string | undefined} [obj.source] the source for the capability
   * @param {boolean} [trigger=true] whether to trigger navigation or just update the url
   */
  navigateToButlerView(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    options?: any,
    trigger?: boolean,
  ) {
    // Since we defined type this:any in the function signature, we need to move
    // the defaults out of the signature to avoid incorrect TS transpilation
    const {
      tab,
      log,
      usage,
      account,
      newCommand,
      newIcon,
      newLabel,
      taskId,
      source,
    } = options || {};
    let edit = options?.edit;
    trigger = trigger !== undefined ? trigger : true;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const params = {} as { [key: string]: any };
    if (newCommand) {
      params['c'] = newCommand;
      edit = 'new';
      if (newIcon) {
        params['i'] = newIcon;
      }
      if (newLabel) {
        params['l'] = newLabel;
      }
    }
    if (taskId) {
      params['taskId'] = taskId;
      params['source'] = source;
    }
    const butlerUrl = getButlerUrl(this.model.id, {
      tab,
      edit,
      log,
      usage,
      account,
    });
    const paramsString =
      newCommand || taskId ? `?${new URLSearchParams(params).toString()}` : '';
    navigate(`${butlerUrl}${paramsString}`, { trigger });
  }

  showMemberProfile(member: Member) {
    setDocumentTitle(dangerouslyConvertPrivacyString(member.get('fullName')));
    if (!Dialog.isVisible) {
      Dialog.show({
        view: new MemberBoardProfileView({
          model: member,
          board: this.model,
        }),
        maxWidth: 500,
        hide: () => setBoardLocation(this.model.id),
      });
    }
  }

  showCalendar(date: string) {
    if (!this.model.isPowerUpEnabled('calendar') || this.isShowingCalendar()) {
      return;
    }

    $('.calendar-btn', this.$el).addClass('board-header-btn-enabled');
    hideBoardListView();

    return importWithRetry(
      () =>
        import(
          /* webpackChunkName: "calendar-controller-view" */ 'app/scripts/views/calendar/CalendarControllerView'
        ),
    ).then(({ CalendarControllerView }) => {
      this.calendarView = new CalendarControllerView({
        model: this.model,
      });
      $('.js-board-view-container', this.$el).html(
        this.calendarView.render(date).el,
      );
      this.calendarView.addedToDOM();
    });
  }

  showMap({
    mapCenterTo,
    zoom,
  }: {
    mapCenterTo: {
      lat: string;
      long: string;
    };
    zoom: number;
  }) {
    if (!this.model.isMapPowerUpEnabled() || this.mapView != null) {
      return;
    }

    $('.js-map-btn', this.$el).addClass('board-header-btn-enabled');
    hideBoardListView();

    return importWithRetry(
      () =>
        import(
          /* webpackChunkName: "map-view" */ 'app/scripts/views/map/MapView'
        ),
    ).then(({ MapView }) => {
      const render = () => {
        this.mapView = new MapView({
          model: this.model,
        });
        $('.js-board-view-container', this.$el).html(this.mapView.render().el);
      };

      // Re-initializes map view when theme changes.
      this.globalThemeObserver = new GlobalThemeObserver(() => {
        if (this.mapView) {
          render();
        }
      });

      render();
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async showPowerUpView({ idPlugin, viewKey }: any) {
    if (!this.model.isPluginEnabled(idPlugin)) {
      return;
    }

    const availableViews = await pluginRunner.one({
      plugin: idPlugin,
      command: 'board-views',
      board: this.model,
    });

    // @ts-expect-error TS(2571): Object is of type 'unknown'.
    const selectedView = availableViews.find(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (availableView: any) => availableView.key === viewKey,
    );

    if (!selectedView) {
      console.warn('Power-Up View not found.');
      this.navigateToBoard();
      return;
    }

    this.pluginView = new PluginViewView({
      model: this.model,
      content: selectedView,
    });

    hideBoardListView();
    $('.js-board-view-container', this.$el).html(this.pluginView.render().el);
  }

  closePowerUpView(navigateToBoard?: boolean) {
    if (navigateToBoard == null) {
      navigateToBoard = true;
    }
    if (this.pluginView != null) {
      this.pluginView.remove();
      this.pluginView = null;
      if (navigateToBoard) {
        this.navigateToBoard();
      }
      showBoardListView();
      $('.js-list-name-input', this.$el).trigger('autosize.resize', false);
    }
  }

  async showDashboard() {
    hideBoardListView();

    const board = this.model;

    const hasViewsFeature = board.isFeatureEnabled(PremiumFeatures.views);

    const canShowDashboard = hasViewsFeature;

    if (canShowDashboard) {
      const { BoardDashboardView } = await importWithRetry(
        () =>
          import(
            /* webpackChunkName: "board-dashboard-view" */ 'app/src/components/BoardDashboardView'
          ),
      );
      renderComponent(
        <BoardIdProvider
          value={{
            boardId: this.model.id,
            boardNodeId: this.model.get('nodeId'),
          }}
        >
          <BoardDashboardView
            navigateToBoardView={this.navigateToBoard.bind(this)}
          />
        </BoardIdProvider>,
        // @ts-expect-error TS(2345): Argument of type 'HTMLElement | undefined' is not ... Remove this comment to see the full error message
        $('.js-board-view-container', this.$el).get(0),
      );
    } else {
      this.navigateToBoard();
    }
  }

  closeDetailView() {
    Dialog.hide(true);
    this.closeCalendar(false);
    this.closeDirectory(false);
    this.closeMap(false);
    this.closeButler(false);
    this.closeSingleBoardView();
    this.closePowerUpView(false);
  }

  closeCalendar(navigateToBoard?: boolean) {
    if (navigateToBoard == null) {
      navigateToBoard = true;
    }
    if (this.calendarView != null) {
      this.calendarView.remove();
      this.calendarView = null;
      if (navigateToBoard) {
        this.navigateToBoard();
      }
      $('.calendar-btn', this.$el).removeClass('board-header-btn-enabled');
      showBoardListView();

      // Hack to resolve a bug where the lists won't have been
      // sized properly if the calendar was visible on initial
      // render (and the board and it's lists were hidden)
      $('.js-list-name-input', this.$el).trigger('autosize.resize', false);
    }
  }

  closeMap(navigateToBoard?: boolean) {
    if (this.globalThemeObserver) {
      this.globalThemeObserver.unsubscribe();
    }
    if (navigateToBoard == null) {
      navigateToBoard = true;
    }
    if (this.mapView != null) {
      this.mapView.remove();
      this.mapView = null;
      this.mapCenterTo = null;
      if (navigateToBoard) {
        this.navigateToBoard();
      }
      $('.js-map-btn', this.$el).removeClass('board-header-btn-enabled');
      showBoardListView();
      $('.js-list-name-input', this.$el).trigger('autosize.resize', false);
    }
  }

  closeButler(navigateToBoard?: boolean) {
    if (navigateToBoard == null) {
      navigateToBoard = true;
    }
    if (this.butlerView != null) {
      this.butlerView.remove();
      this.butlerView = null;
      if (navigateToBoard) {
        this.navigateToBoard();
      }
      showBoardListView();
      $('.js-list-name-input', this.$el).trigger('autosize.resize', false);
    }
  }

  clickCloseDirectory() {
    if (this.directoryView) {
      Analytics.sendClickedButtonEvent({
        buttonName: 'boardDirectoryCloseButton',
        attributes: {
          boardId: this.model.id,
          organizationId: this.model.get('idOrganization'),
        },
        source: this.directoryView.activeEventSource(),
      });
    }

    return this.toggleDirectory();
  }

  toggleDirectory() {
    if (this.directoryView) {
      if (this.directoryReturnCard) {
        this.closeDirectory(false);
        Controller.showCardDetail(this.directoryReturnCard);
        return (this.directoryReturnCard = null);
      } else {
        return this.navigateToBoard();
      }
    } else {
      return this.navigateToDirectory();
    }
  }
  navigateToDirectory(returnCard?: Card) {
    const directoryUrl = getBoardUrl(this.model.id, 'power-ups');
    navigate(directoryUrl, { trigger: true });

    this.directoryReturnCard = returnCard;

    // Close the sidebar menu for window sizes that aren't extra large.
    if (!WindowSize.fExtraLarge) {
      return this.sidebarView.hideSidebar();
    }
  }

  showDirectory(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    section: any,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    category: any,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    idPlugin: any,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    isEnabling: any,
  ) {
    hideBoardListView();

    this.directoryView = new DirectoryView({
      model: this.model,
      section,
      category,
      idPlugin,
      isEnabling,
      boardView: this,
    });
    return $('.js-board-view-container', this.$el).html(
      this.directoryView.render().el,
    );
  }

  closeDirectory(navigateToBoard?: boolean) {
    if (navigateToBoard == null) {
      navigateToBoard = true;
    }
    if (this.directoryView != null) {
      this.directoryView.remove();
      this.directoryView = null;
      if (navigateToBoard) {
        this.navigateToBoard();
      }
      showBoardListView();
      $('.js-list-name-input', this.$el).trigger('autosize.resize', false);
    }
  }

  showTimeline() {
    const hasViewsFeature = this.model.isFeatureEnabled(PremiumFeatures.views);

    if (hasViewsFeature) {
      hideBoardListView();
      const container = this.el.querySelector('.js-board-view-container');
      renderComponent(
        <TimelineViewWrapper
          idBoard={this.model.id}
          /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
          navigateToCard={(id: any, params: any) => {
            // @ts-expect-error
            Controller.showCardDetail(ModelCache.get('Card', id), params);
          }}
        />,
        container,
      );
    } else {
      const boardUrl = getBoardUrl(this.model.id);
      navigate(boardUrl, { trigger: true });
    }
  }

  showCalendarView() {
    const hasViewsFeature = this.model.isFeatureEnabled(PremiumFeatures.views);

    const canShowCalendarView = hasViewsFeature;

    if (canShowCalendarView) {
      hideBoardListView();
      const container = this.el.querySelector('.js-board-view-container');
      renderComponent(
        <SingleBoardCalendarView
          idBoard={this.model.id}
          navigateToCard={(id) => {
            // @ts-expect-error TS(2554): Expected 2 arguments, but got 1.
            Controller.showCardDetail(ModelCache.get('Card', id));
          }}
        />,
        container,
      );
    } else {
      const boardUrl = getBoardUrl(this.model.id);
      // @ts-expect-error TS(2345): Argument of type '{ trigger: true; shouldTrack: bo... Remove this comment to see the full error message
      navigate(boardUrl, { trigger: true, shouldTrack: false });
    }
  }

  showTable() {
    const hasViewsFeature = this.model.isFeatureEnabled(PremiumFeatures.views);

    if (hasViewsFeature) {
      hideBoardListView();

      const container = this.el.querySelector('.js-board-view-container');
      renderComponent(
        <BoardIdProvider
          value={{
            boardId: this.model.id,
            boardNodeId: this.model.get('nodeId'),
          }}
        >
          <SingleBoardTableView
            idBoard={this.model.id}
            navigateToCard={(id) => {
              // @ts-expect-error TS(2554): Expected 2 arguments, but got 1.
              Controller.showCardDetail(ModelCache.get('Card', id));
            }}
          />
        </BoardIdProvider>,
        container,
      );
    } else {
      const boardUrl = getBoardUrl(this.model.id);
      navigate(boardUrl, { trigger: true });
    }
  }

  closeSingleBoardView() {
    const viewContainer = this.el.querySelector('.js-board-view-container');
    if (viewContainer) {
      ReactDOM.unmountComponentAtNode(viewContainer);
    }
    showBoardListView();
    $('.js-list-name-input', this.$el).trigger('autosize.resize', false);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  clearFilters(e: any) {
    stopPropagationAndPreventDefault(e);
    Analytics.sendClickedButtonEvent({
      buttonName: 'clearBoardFilterButton',
      source: 'boardScreen',
      containers: {
        board: {
          id: this.model.id,
        },
        organization: {
          id: this.model.getOrganization()?.id,
        },
        enterprise: {
          id: this.model.getEnterprise()?.id,
        },
      },
    });

    this.model.filter.clear();
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  dblClickAddListMenu(e: any) {
    return;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  reopen(e: any) {
    stopPropagationAndPreventDefault(e);
    const org = this.model.getOrganization();
    return (
      this.model
        .getNewBillableGuests()
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .then(({ newBillableGuests, availableLicenseCount }: any) => {
          if (newBillableGuests.length > 0) {
            return PopOver.show({
              elem: $('.js-reopen', this.$el),
              view: new ReopenBoardPopoverView({
                board: this.model,
                org,
                newBillableGuests,
                availableLicenseCount,
              }),
            });
          } else {
            const traceId = Analytics.startTask({
              taskName: 'edit-board/closed',
              source: 'closedBoardScreen',
            });
            return this.model.reopen(
              { traceId },
              tracingCallback(
                {
                  taskName: 'edit-board/closed',
                  source: 'closedBoardScreen',
                  traceId,
                },
                (err) => {
                  if (!err) {
                    Analytics.sendTrackEvent({
                      action: 'reopened',
                      actionSubject: 'board',
                      source: 'closedBoardScreen',
                      containers: {
                        board: {
                          id: this.model.id,
                        },
                        organization: {
                          id: this.model.getOrganization()?.id,
                        },
                      },
                      attributes: {
                        taskId: traceId,
                      },
                    });
                  }
                },
              ),
            );
          }
        })
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  delete(e: any) {
    stopPropagationAndPreventDefault(e);

    return Confirm.toggle('delete board', {
      elem: e.target,
      model: this.model,
      confirmBtnClass: 'nch-button nch-button--danger',
      /* eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-shadow */
      fxConfirm: (e: any) => {
        const traceId = Analytics.startTask({
          taskName: 'delete-board',
          source: 'closedBoardScreen',
        });
        return this.model.startDelete(
          traceId,
          tracingCallback(
            {
              taskName: 'delete-board',
              source: 'closedBoardScreen',
              traceId,
            },
            (err) => {
              if (!err) {
                Analytics.sendTrackEvent({
                  action: 'deleted',
                  actionSubject: 'board',
                  source: 'closeBoardScreen',
                  containers: {
                    board: {
                      id: this.model.id,
                    },
                    organization: {
                      id: this.model.getOrganization()?.id,
                    },
                  },
                  attributes: {
                    taskId: traceId,
                  },
                });
              }
            },
          ),
        );
      },
    });
  }

  // Returns the idList/index to move a card to.  It prefers to move the card
  // above the card that is 'active', and will fall back to inserting the card
  // at the bottom of idListDefault if no card is active
  _insertCardPosition(idListDefault: string) {
    const activeCard = this.model.viewState.getCard();
    if (activeCard != null) {
      return {
        idList: activeCard.get('idList'),
        index: activeCard.getIndexInList(),
      };
    } else {
      return {
        idList: idListDefault,
        index: this.model.listList.get(idListDefault).cardList.length,
      };
    }
  }

  moveCardRequested(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    listView: any,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    { shortLink, idList }: any,
  ) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let index: any, left;
    ({ idList, index } = this._insertCardPosition(idList));
    const idCard =
      (left = idCache.getId('Card', shortLink)) != null ? left : shortLink;

    return (
      Card.load(idCard, Payloads.cardMinimal, this.modelCache)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .then((card: any) => {
          const list = this.model.listList.get(idList);
          if (
            card.get('idList') === idList ||
            canAddCard({ destinationList: list })
          ) {
            return card.moveToList(list, index);
          }
        })
        .done()
    );
  }

  copyCardRequested(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    listView: any,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    { shortLink, idList, traceId }: any,
  ) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let index: any, left;
    ({ idList, index } = this._insertCardPosition(idList));
    const idCard =
      (left = idCache.getId('Card', shortLink)) != null ? left : shortLink;

    return (
      Card.load(idCard, Payloads.cardMinimal, this.modelCache)
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .then((card: any) => {
          const list = this.model.listList.get(idList);

          const options = {
            hasChecklists: card.checklistList.length > 0,
            hasAttachments: card.attachmentList.length > 0,
            destinationBoard: this.model,
            destinationList: list,
          };
          if (canAddCard(options)) {
            return card.copyTo({
              idList,
              pos: this.model.listList.get(idList).calcPos(index, card),
              traceId,
            });
          }
        })
        .done()
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  showCleanUp(e: any) {
    stopPropagationAndPreventDefault(e);

    const view = new BoardCleanUpView({
      model: this.model,
    });
    Dialog.show({
      view,
      maxWidth: 800,
      hide() {
        return view.abort();
      },
    });
  }

  navigateToAutomaticReports(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    reportType: any,
  ) {
    const automaticReportView = getBoardUrl(
      this.model.id,
      `butler/reports/${reportType}`,
    );

    navigate(automaticReportView, { trigger: true });

    // Close the sidebar menu for window sizes that aren't extra large.

    if (!WindowSize.fExtraLarge) {
      this.sidebarView.hideSidebar();
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  showAutomaticReports(reportType: any) {
    const idBoard = this.model.id;
    const container = this.el.querySelector('.js-board-view-container');
    hideBoardListView();

    renderComponent(
      <AutomaticReports
        reportType={reportType}
        idBoard={idBoard}
        closeView={() => this.closeAutomaticReports(idBoard)}
      />,
      container,
    );
  }

  // This is actually pretty generic and just closes any overlays;
  // if it ever needs reuse, feel free to rename it.
  closeAutomaticReports(idBoard?: string) {
    const boardUrl = getBoardUrl(idBoard || this.model.id);
    navigate(boardUrl, { trigger: true });
  }

  initializeFilterCountMonitoring() {
    return;
  }

  constructor(options: BoardViewOptions) {
    super(...arguments);
    this.onShortcut = this.onShortcut.bind(this);
    this.renderConfirmEmailModal = this.renderConfirmEmailModal.bind(this);
  }

  initialize() {
    super.initialize(...arguments);

    this.sidebarViewPromise = importWithRetry(
      () =>
        import(
          /* webpackChunkName: "board-sidebar-view" */ 'app/scripts/views/board-menu/BoardSidebarView'
        ),
    );
    this.sidebarViewPromise
      .then(({ BoardSidebarView }) => {
        this.sidebarView = this.subview(BoardSidebarView, this.model, {
          boardView: this,
        });
      })
      .catch(function (error) {
        if (error.name === 'ChunkLoadError') {
          sendChunkLoadErrorEvent(error);
          const container = document.querySelector('.board-menu');
          if (container) {
            return renderComponent(<BoardSidebarLoadingError />, container);
          }
        } else {
          throw error;
        }
      });

    this.teamOnboardingPupPopoverDiv = null;

    this.makeDebouncedMethods(
      'refreshOrganization',
      'reloadCustomFields',
      'render',
      'renderBoardBackground',
      'renderReactBoardHeader',
      'renderBoardWarnings',
      'renderPermissionLevel',
      'rerenderLists',
      'setDynamicTokensOnBoardView',
    );

    this.listenTo(this.model, {
      'change:closed deleting': this.renderDebounced,
      'change:idOrganization': () => {
        this.reloadDirectoryPlugins = true;
        this.refreshOrganizationDebounced();
      },
      'change:memberships': this.renderPermissionLevelDebounced,
      'change:limits': this.renderBoardWarningsDebounced,
      'change:myPrefs': () => {
        this.renderReactBoardHeaderDebounced();
      },
      'change:name': () => {
        this.renderReactBoardHeaderDebounced();
      },
      'change:powerUps': () => {
        this.renderReactBoardHeaderDebounced();
        if (!this.model.isMapPowerUpEnabled()) {
          this.closeMap();
        }
        if (!this.model.isPowerUpEnabled('calendar')) {
          this.closeCalendar();
        }
      },
      'change:prefs.cardCovers': this.rerenderListsDebounced,
      'change:prefs.permissionLevel': () => {
        this.rerenderListsDebounced();
        this.renderPermissionLevelDebounced();
        this.renderReactBoardHeaderDebounced();
      },
      'change:prefs.background change:prefs.backgroundTile change:prefs.backgroundBrightness':
        () => {
          this.renderBoardBackgroundDebounced(true);
          this.renderReactBoardHeaderDebounced();
          this.setDynamicTokensOnBoardViewDebounced();
        },
      'change:prefs.isTemplate': this.renderReactBoardHeaderDebounced,
      'change:prefs.selfJoin': this.renderReactBoardHeaderDebounced,
      permChange: this.permChange,
      ownedChange: this.renderReactBoardHeaderDebounced,
      destroy: () => {
        errorPage({
          errorType: 'boardNotFound',
        });
      },
    });

    this.listenTo(this.model.memberList, {
      'change:status': this.renderReactBoardHeaderDebounced,
    });

    this.listenTo(this.model.boardPluginList, 'add reset', () => {
      this.renderReactBoardHeaderDebounced();
      this.reloadCustomFieldsDebounced();
    });

    this.subscribe(pluginsChangedSignal(this.model), () => {
      this.renderReactBoardHeaderDebounced();
      if (!this.model.isMapPowerUpEnabled()) {
        this.closeMap();
      }
      if (!this.model.isPowerUpEnabled('calendar')) {
        this.closeCalendar();
      }
    });

    this.listenTo(this.model.viewState, {
      'change:showSidebar': this.renderSidebarState,
    });

    this.memberTypeMe = this.model.getMemberType(Auth.me());

    this.refreshOrganization();

    this.initializeFilterCountMonitoring();

    this._applySettings();

    this.reloadDirectoryPlugins = false;

    // Rerender the board header when the global theme changes.
    // This is used to update the `setDynamicTokens` call.
    // When the board header is migrated to React, all of this will be handled
    // by the `useDynamicTokens` hook.
    this.globalThemeObserver = new GlobalThemeObserver(() =>
      // Optional only to protect from the very niche outside possibility that
      // we somehow trigger this event before `makeDebouncedMethods` resolves.
      this.setDynamicTokensOnBoardViewDebounced?.(),
    );

    const { colorMode, effectiveColorMode } = getGlobalTheme();

    // Analytics migration -- move this to controller to capture all URL changes
    const { url, referrerUrl, referrerScreenName } = getMarketingScreenInfo();

    const attributes: EventAttributes = {
      colorMode,
      effectiveColorMode,
      areCardCoversDisabled: this.model.getPref('cardCovers') === false,
      isColorBlindModeEnabled: Auth.me().get('prefs')?.colorBlind ?? false,
      isFirstTimeViewingBoard: Controller.isFirstTimeViewingBoard,
      isShowingSidebar: this.model.viewState.get('showSidebar'),
      isShowingWorkspaceNavigation: workspaceNavigationState.value.expanded,
      isTemplate: this.model.isTemplate(),
      labelsState: showLabelsState.value.showText ? 'expanded' : 'collapsed',
      numOpenLists: this.model.listList.length,
      numOpenCards: this.model.listList.reduce(
        (sum, list) => sum + list.openCards().length,
        0,
      ),
      numOpenCardsPerList: this.model.listList.map(
        (list) => list.openCards().length,
      ),
      numPowerups: this.model.powerUpsCount(),
      screen: getScreenFromUrl(),
      viewerIsBoardCreator: this.model.get('idMemberCreator') === Auth.myId(),
      visibility: this.model.getPermLevel(),
    };

    const isListColorsEnabled = this.model.isFeatureEnabled(
      PremiumFeatures.listColors,
    );

    // Add tracking for board views that have used the list color picker:
    if (isListColorsEnabled) {
      try {
        const hasColoredLists = this.model.listList.some((list) => {
          const color = list.get('color');
          return Boolean(color && color !== DEFAULT_LIST_COLOR);
        });
        attributes.hasColoredLists = hasColoredLists;
      } catch (e) {
        //
      }
    }

    // Add tracking for board views that have collapsed lists on them:
    if (this.model.isFeatureEnabled(PremiumFeatures.collapsibleLists)) {
      const collapsedLists = collapsedListsState.value[this.model.id];
      attributes.hasCollapsedLists = Boolean(
        collapsedLists && Object.values(collapsedLists).some(Boolean),
      );
    }

    Analytics.sendMarketingScreenEvent({
      url,
      referrerUrl,
      referrerScreenName,
      event: {
        source: 'boardScreen',
        name: 'boardScreenV2',
        attributes,
        containers: formatContainers({
          idBoard: this.model.id,
          idOrganization: this.model.getOrganization()?.id,
        }),
      },
    });

    this.unsubscribeViewFiltersContext =
      viewFiltersContextSharedState.subscribe(() => {
        this.model.filter.viewFiltersContext =
          viewFiltersContextSharedState.value;
      });
  }

  onShortcut = (
    event: Parameters<Parameters<typeof registerShortcutHandler>[0]>[0],
  ) => {
    const allowedViewsShortcuts = [Key.f, Key.q, Key.x];
    const key = getKey(event);

    if (
      isShowingBoardViewThatHasCards() &&
      !allowedViewsShortcuts.includes(key)
    ) {
      return;
    }

    const editable = this.model.editable();
    const isLoggedIn = Auth.isLoggedIn();

    const sendShortcutEvent = (
      shortcutName: ActionSubjectIdType,
      {
        shortcutKey = key,
        toggleValue,
        isHoveringCard,
      }: {
        shortcutKey?: string;
        toggleValue?: 'show' | 'hide';
        isHoveringCard?: boolean;
      } = {},
    ) => {
      let source: 'boardScreen' | 'timelineViewScreen' | 'calendarViewScreen' =
        'boardScreen';

      if (isShowingBoardViewSection('timeline')) {
        source = 'timelineViewScreen';
      }

      if (isShowingBoardViewSection('calendar-view')) {
        source = 'calendarViewScreen';
      }

      Analytics.sendPressedShortcutEvent({
        shortcutName,
        source,
        keyValue: shortcutKey,
        containers: {
          board: { id: this.model.id },
          organization: { id: this.model.get('idOrganization') },
          enterprise: { id: this.model.get('idEnterprise') },
        },
        attributes: {
          toggleValue:
            toggleValue !== undefined ? toggleValue.toLowerCase() : undefined,
          isHoveringCard:
            isHoveringCard !== undefined ? isHoveringCard : undefined,
        },
      });
    };

    switch (key) {
      case Key.q:
        if (isLoggedIn) {
          const traceId = Analytics.startTask({
            taskName: 'edit-board/filter',
            source: 'boardScreen',
          });

          try {
            this.model.filter.toggleQuietMode();
            event.preventDefault();
            sendShortcutEvent('myCardsShortcut');

            Analytics.taskSucceeded({
              taskName: 'edit-board/filter',
              source: 'boardScreen',
              traceId,
            });
          } catch (error) {
            Analytics.taskFailed({
              taskName: 'edit-board/filter',
              source: 'boardScreen',
              traceId,
              error,
            });
          }
        }
        return;

      case Key.x: {
        const traceId = Analytics.startTask({
          taskName: 'remove-boardFilter',
          source: 'boardScreen',
        });

        try {
          this.model.filter.clear();
          event.preventDefault();
          sendShortcutEvent('clearAllFiltersShortcut');

          Analytics.taskSucceeded({
            taskName: 'remove-boardFilter',
            source: 'boardScreen',
            traceId,
          });
        } catch (error) {
          Analytics.taskFailed({
            taskName: 'remove-boardFilter',
            source: 'boardScreen',
            traceId,
            error,
          });
        }

        return;
      }

      case Key.ClosedBracket:
      case Key.w:
        this.toggleSidebar();
        event.preventDefault();
        sendShortcutEvent('toggleBoardMenuDrawerShortcut', {
          toggleValue: this.model.viewState.get('showSidebar')
            ? 'show'
            : 'hide',
        });
        return;

      case Key.SemiColon: {
        const wasShowingLabelText = LabelState.getShowText();
        LabelState.toggleText();
        event.preventDefault();
        const toggleValue = wasShowingLabelText ? 'hide' : 'show';
        sendShortcutEvent('toggleLabelTextShortcut', { toggleValue });
        return;
      }

      case Key.ArrowDown:
      case Key.j:
        this.moveCard('down');
        event.preventDefault();
        sendShortcutEvent('moveDownShortcut');
        return;

      case Key.ArrowUp:
      case Key.k:
        this.moveCard('up');
        event.preventDefault();
        sendShortcutEvent('moveUpShortcut');
        return;

      case Key.ArrowRight:
        this.moveCard('right');
        event.preventDefault();
        sendShortcutEvent('moveRightShortcut');
        return;

      case Key.ArrowLeft:
        this.moveCard('left');
        event.preventDefault();
        sendShortcutEvent('moveLeftShortcut');
        return;

      case Key.n:
        if (editable) {
          const list = this.model.viewState.getList();
          const card = this.model.viewState.getCard();

          if (list != null) {
            const index =
              card != null
                ? list.openCards().indexOf(card) + 1
                : list.openCards().length;

            this.model.composer.save({
              list,
              index,
              vis: true,
            });

            event.preventDefault();
            sendShortcutEvent('insertCardShortcut', {
              isHoveringCard: card ? true : false,
            });
          }
        }
        return;

      case Key.z:
      case Key.Z:
        if (editable) {
          event.preventDefault();
          // Check shiftKey > Key.Z for consistency in case of caps lock.
          if (event.shiftKey) {
            redoAction({ source: 'boardScreen', idBoard: this.model.id });
            sendShortcutEvent('redoActionShortcut', {
              shortcutKey: 'Shift+Z',
            });
          } else {
            undoAction({ source: 'boardScreen', idBoard: this.model.id });
            sendShortcutEvent('undoActionShortcut');
          }
        }
        return;

      case Key.Escape:
        if (editable) {
          if (DragSort.sorting) {
            DragSort.abort();
          } else if (
            !(Layout.isEditing() || PopOver.isVisible || Dialog.isVisible)
          ) {
            if (this.model.composer.get('vis')) {
              this.model.composer.save('vis', false);
            } else if (this.model.viewState.get('listComposerOpen')) {
              this.model.viewState.set('listComposerOpen', false);
            } else {
              this.sidebarViewPromise.then(() => this.sidebarView.popView());
            }
          }

          if (showingCalendar()) {
            $('.calendar-day.active').removeClass('active');
          }

          event.preventDefault();
        }
        return;

      default:
        return;
    }
  };

  renderClosed() {
    // If the board is closed, we should unmount the board canvas.
    this.unmountLists?.call(this);

    this.unmountBoardClosed = renderComponent(
      <BoardIdProvider
        value={{
          boardId: this.model.get('id'),
          boardNodeId: this.model.get('nodeId'),
        }}
      >
        <LazyBoardClosed />
      </BoardIdProvider>,
      this.el,
    );
    this.renderBoardBackground();
  }

  render() {
    if (this.model.get('closed')) {
      this.renderClosed();
    } else {
      this.unmountBoardClosed?.();
      this.renderOpen();
    }
    this.renderLabelsPopover();
    this.renderAboutThisBoardModal();
    this.setDynamicTokensOnBoardView();
    this.renderConfirmEmailModal();

    return this;
  }

  // Render the new Labels Popover in React.
  renderLabelsPopover() {
    const reactRoot = this.el.querySelector(
      '.js-board-labels-popover-container',
    );
    /**
     * This render function sometimes gets called in rapid succession,
     * too quickly to actually render the component, so check if the unmount
     * function has been defined before rendering again.
     */
    if (reactRoot && !this.unmountLabelsPopover) {
      this.unmountLabelsPopover = renderComponent(
        <WorkspaceIdProvider value={this.model.get('idOrganization') || null}>
          <BoardIdProvider
            value={{
              boardId: this.model.get('id'),
              boardNodeId: this.model.get('nodeId'),
            }}
          >
            <BoardPermissionsContextProvider>
              <LazyLabelsPopover />
            </BoardPermissionsContextProvider>
          </BoardIdProvider>
        </WorkspaceIdProvider>,
        reactRoot,
      );
    }
  }

  renderAboutThisBoardModal() {
    if (this.unmountAboutThisBoardModel) {
      return;
    }

    const reactRoot = this.el.querySelector('.js-about-this-board-container');

    if (!reactRoot) {
      return;
    }

    this.unmountAboutThisBoardModel = renderComponent(
      <PossiblyRenderAboutThisBoardModal boardId={this.model.id} />,
      reactRoot,
    );
  }

  renderConfirmEmailModal() {
    const reactRoot = this.el.querySelector('.js-confirm-email-modal');

    if (reactRoot && !this.unmountConfirmEmailModal) {
      this.unmountConfirmEmailModal = renderComponent(
        <PossiblyRenderConfirmEmailModal boardId={this.model.id} />,
        reactRoot,
      );
    }
  }

  renderJoinBoardModal() {
    $('#content').append('<div class="join-board-modal-container"></div>');
    const reactRoot = document.querySelector('.join-board-modal-container');

    if (reactRoot && !this.unmountJoinBoardModal) {
      this.unmountJoinBoardModal = renderComponent(
        <LegacyJoinBoardModal idBoard={this.model.id} />,
        reactRoot,
      );
    }
  }

  renderFilteringStatus() {
    const isFiltering = this.model.filter.isFiltering();

    $('.js-num-cards', this.$el).toggleClass('hide', !isFiltering);
    $('#board', this.$el).toggleClass('filtering', isFiltering);

    // Don't pull in Board Filters and overwrite Query Params if on a Board View
    if (isShowingBoardViewThatHasCards()) {
      return;
    }

    const filterQueryString = this.model.filter.toQueryString();
    const filterQueryParams = new URLSearchParams(filterQueryString);

    const currentQueryParams = new URLSearchParams(location.search);

    // Calling replaceState too many times on a large board render will break
    // the progressive rendering of lists on a board
    if (filterQueryParams.get('filter') !== currentQueryParams.get('filter')) {
      if (filterQueryParams.has('filter'))
        // @ts-expect-error TS(2345): Argument of type 'string | null' is not assignable... Remove this comment to see the full error message
        currentQueryParams.set('filter', filterQueryParams.get('filter'));
      else currentQueryParams.delete('filter');
      // Hack: The URL api auto-encodes commas and colons, but we want to use
      // "invalid" urls with `,` and `:` characters in them.
      const queryString =
        toQueryStringWithDecodedFilterParams(currentQueryParams);
      const query = queryString.length ? `?${queryString}` : '';
      // @ts-expect-error TS(2345): Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
      history.replaceState(null, null, location.pathname + query);
    }
  }

  /**
   * Sets dynamic tokens on the .board-wrapper element.
   * At present, these tokens are used for coloring the board header buttons.
   */
  setDynamicTokensOnBoardView() {
    const {
      backgroundBrightness,
      backgroundColor,
      backgroundTopColor,
      isBackgroundImage,
      // @ts-expect-error
    } = getBoardBackground(this.model.get('prefs'));
    const { effectiveColorMode } = getGlobalTheme();

    setDynamicTokens({
      element: this.el,
      dynamicTokens: [
        'dynamic.button',
        'dynamic.star',
        'dynamic.text',
        'dynamic.background',
      ],
      background: {
        backgroundBrightness,
        backgroundColor: isBackgroundImage
          ? backgroundTopColor
          : backgroundColor,
        isBackgroundImage,
      },
      colorMode: effectiveColorMode,
    });
  }

  remove() {
    if (this.calendarView != null) {
      this.calendarView.remove();
    }
    unregisterShortcutHandler(this.onShortcut);
    this.unmountTeamOnboardingPupPopover();
    this.unmountBoardClosed?.();
    this.unmountLabelsPopover?.();
    this.unmountAboutThisBoardModel?.();
    this.unmountLists?.call(this);
    this.unmountConfirmEmailModal?.();
    this.unmountJoinBoardModal?.();
    this.removeReactBoardHeader();
    this.globalThemeObserver.unsubscribe();
    this.unsubscribeViewFiltersContext();

    return super.remove(...arguments);
  }

  // Because we are using mouse event handlers that stop propagation on mousedown events
  // (enableDragScroll.ts and jquery.sortable), we need to handle blurring of inputs
  // and textareas ourselves.
  blurInputsOnBoardClick(e: JQuery.TriggeredEvent) {
    const $target = $(e.target);

    // Ignore clicks on inputs and textareas. Note that inputs here can also be submit buttons
    // that are 'programmatically' clicked by the DOM upon form submission (on enter keypress)
    const clickedInput = $target.is('input') || $target.is('textarea');
    if (clickedInput) {
      return e?.stopPropagation();
    } else {
      return $('input, textarea').blur();
    }
  }

  /**
   * Normally, we evaluate mouse move to determine which card is currently
   * "selected", which essentially means the card gets hover styling, and that
   * keyboard shortcuts apply to it. Under the following circumstances, we don't
   * want that to happen. This is critical for things like maintaining a card's
   * "selected" state when a popover is opened in the quick edit menu.
   */
  _ignoreMouseMove() {
    return (
      this.ignoreMouseCardSelects ||
      PopOver.isVisible ||
      DragSort.sorting ||
      LabelsPopoverState.value.isOpen
    );
  }

  showBoardDetailView(args: {
    idCard?: string;
    section?:
      | 'board'
      | 'butler'
      | 'calendar-view'
      | 'dashboard'
      | 'map'
      | 'power-up'
      | 'power-ups'
      | 'table'
      | 'timeline';
    highlight?: string;
    replyToComment?: string;
    loadCardData: () => BluebirdPromise<Card>;
    usernameBoardProfile?: string | null;
    powerUpViewKey?: string;
    directoryIdPowerUp?: string;
    directorySection?: string;
    directoryCategory?: string;
    directoryIsEnabling?: boolean;
    mapCenterTo: {
      lat: string;
      long: string;
    };
    zoom: number;
    openListComposer?: boolean;
    openCardComposerInFirstList?: boolean;
    referrerUsername?: string;
    reportType?: string;
    calendarDate?: string;
    butlerTab?: string;
    butlerCmdEdit?: string;
    butlerCmdLog?: string;
    butlerNewCommand?: string;
    butlerUsage?: string;
    butlerAccount?: string;
    butlerNewIcon?: string;
    butlerNewLabel?: string;
    taskId?: string;
    source?: string;
  }) {
    return BluebirdPromise.try(() => {
      let date, idCard, member;
      const board = this.model;

      if ((idCard = args.idCard) != null) {
        showCardOnBoard.call(
          this,
          idCard,
          args.highlight,
          board,
          args.replyToComment,
          args.loadCardData,
        );
      } else if (
        args.usernameBoardProfile &&
        (member = board.modelCache.findOne(
          'Member',
          'username',
          args.usernameBoardProfile,
        )) != null
      ) {
        this.showMemberProfile(member);
      } else if (
        typeof args.section === 'string' &&
        ['power-up', 'power-ups'].includes(args.section)
      ) {
        const powerUpViewsEnabled = featureFlagClient.get(
          'ecosystem.power-up-views',
          false,
        );
        if (args.powerUpViewKey && powerUpViewsEnabled) {
          this.showPowerUpView({
            idPlugin: args.directoryIdPowerUp,
            viewKey: args.powerUpViewKey,
          });
        } else if (board.editable()) {
          this.showDirectory(
            args.directorySection,
            args.directoryCategory,
            args.directoryIdPowerUp,
            args.directoryIsEnabling,
          );
        } else {
          setBoardLocation(board.id);
        }
      } else if (args.section === 'map') {
        if (!board.isMapPowerUpEnabled()) {
          const boardUrl = getBoardUrl(board.id);
          navigate(boardUrl, { trigger: true });
        } else {
          this.showMap({
            mapCenterTo: args.mapCenterTo,
            zoom: args.zoom,
          });
        }
      } else if (args.section === 'dashboard') {
        this.showDashboard();
      } else if ((date = args.calendarDate) != null) {
        this.showCalendar(date);
      } else if (args.section === 'butler' && args.reportType) {
        this.showAutomaticReports(args.reportType);
      } else if (args.section === 'butler') {
        this.showButlerView({
          butlerTab: args.butlerTab,
          butlerCmdEdit: args.butlerCmdEdit,
          butlerCmdLog: args.butlerCmdLog,
          newCommand: args.butlerNewCommand,
          butlerUsage: args.butlerUsage,
          butlerAccount: args.butlerAccount,
          newIcon: args.butlerNewIcon,
          newLabel: args.butlerNewLabel,
          taskId: args.taskId,
          source: args.source,
        });
      } else if (args.section === 'table') {
        this.showTable();
      } else if (args.section === 'timeline') {
        this.showTimeline();
      } else if (args.section === 'calendar-view') {
        this.showCalendarView();
      } else {
        if (args.openListComposer) {
          this.openListComposer();
        }
        if (args.openCardComposerInFirstList) {
          // Deferred to ensure "Add a card" button below composer is hidden properly
          _.defer(() => this.openCardComposerInFirstList());
        }
        const extra = args.referrerUsername
          ? [args.referrerUsername, 'recommend']
          : undefined;
        const search = getQueryParamsToKeepOnBoardView();
        setBoardLocation(board.id, args.section, extra, { search });
      }

      return this;
    });
  }
}

BoardView.initClass();
export { BoardView };
