// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fK3rMsSh1eRpZK {
  background-color: var(--ds-surface, #ffffff);
  background-repeat: no-repeat;
  cursor: progress;
  background-origin: border-box;
  animation-name: WdSose7u7Iq5Mu;
  animation-duration: 1.5s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  display: block;
  position: relative;
  width: 100%;
  height: 32px;
  margin-bottom: 4px;
  border-radius: 8px;
}
@keyframes WdSose7u7Iq5Mu {
  from {
    background-color: var(--ds-skeleton, #091e420f);
  }
  to {
    background-color: var(--ds-skeleton-subtle, #091e4208);
  }
}
@media (prefers-reduced-motion: reduce) {
  .fK3rMsSh1eRpZK {
    animation: none;
  }
}
`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"row": `fK3rMsSh1eRpZK`,
	"shimmer": `WdSose7u7Iq5Mu`
};
export default ___CSS_LOADER_EXPORT___;
