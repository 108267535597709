// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS206: Consider reworking classes to avoid initClass
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import { Component } from 'react';

import { recupWithHelpers } from 'app/scripts/views/internal/recupWithHelpers';
const t = recupWithHelpers('reactions');

class ReactionIcon extends Component {
  static initClass() {
    // @ts-expect-error TS(2339): Property 'displayName' does not exist on type 'Rea... Remove this comment to see the full error message
    this.prototype.displayName = 'ReactionIcon';
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    this.prototype.render = t.renderable(function (this: any) {
      const { disabled, withBorder, active, onClick } = this.props;

      return t.span(
        {
          class: t.classify({
            'reactions-add': true,
            'is-disabled': disabled,
            'mod-with-border': withBorder,
            'is-active': active,
          }),
          onClick,
        },
        () =>
          t.icon('add-reaction', {
            class: t.classify({ 'reactions-add-icon': true }),
            title: t.l('add-reaction'),
          }),
      );
    });
  }
}

ReactionIcon.initClass();
export { ReactionIcon };
