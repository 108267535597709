import * as Types from '@trello/graphql/generated';

import DocumentNode from '@trello/graphql/src/documentNode';
import * as Apollo from '@apollo/client';
export type EligibleWorkspacesForProvisioningFragment = (
  { __typename: 'Member' }
  & Pick<Types.Member, 'id'>
  & { organizations: Array<(
    { __typename: 'Organization' }
    & Pick<Types.Organization, 'id' | 'displayName' | 'idEnterprise' | 'offering'>
    & { jwmLink?: Types.Maybe<(
      { __typename: 'JwmWorkspaceLink' }
      & Pick<Types.JwmWorkspaceLink, 'idCloud'>
    )> }
  )> }
);

export const EligibleWorkspacesForProvisioningFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'EligibleWorkspacesForProvisioning' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Member' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organizations' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'displayName' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'idEnterprise' },
                },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'jwmLink' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'idCloud' },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'offering' } },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

interface UseEligibleWorkspacesForProvisioningFragmentOptions
  extends Omit<
    Apollo.UseFragmentOptions<
      EligibleWorkspacesForProvisioningFragment,
      Apollo.OperationVariables
    >,
    'fragment' | 'fragmentName' | 'from'
  > {
  from: Apollo.StoreObject | Apollo.Reference;
  returnPartialData?: boolean;
}

interface UseEligibleWorkspacesForProvisioningFragmentResult
  extends Omit<
    Apollo.UseFragmentResult<EligibleWorkspacesForProvisioningFragment>,
    'data'
  > {
  data?: EligibleWorkspacesForProvisioningFragment;
}

export const useEligibleWorkspacesForProvisioningFragment = ({
  from,
  returnPartialData,
  ...options
}: UseEligibleWorkspacesForProvisioningFragmentOptions): UseEligibleWorkspacesForProvisioningFragmentResult => {
  const result = Apollo.useFragment<EligibleWorkspacesForProvisioningFragment>({
    ...options,
    fragment: EligibleWorkspacesForProvisioningFragmentDoc,
    fragmentName: 'EligibleWorkspacesForProvisioning',
    from: {
      __typename: 'Member',
      ...from,
    },
  });

  // Ensure that the fragment result is not typed as a DeepPartial.
  if (!result.complete && !returnPartialData) {
    return {
      ...result,
      data: undefined,
    };
  }

  return {
    ...result,
    data: result.data as EligibleWorkspacesForProvisioningFragment,
  };
};
