import { useMemo } from 'react';

import type { EventContainer, SourceType } from '@trello/atlassian-analytics';

import type { SmartCardClientResponse } from './SmartCardClient';
import type { SmartLinkAnalyticsContextType } from './SmartLinkAnalyticsContext';

type Status = 'forbidden' | 'not_found' | 'unauthorized' | 'resolved';
// Duplicated from non-exported @atlaskit/link-provider/src/helpers
const getStatus = ({ meta }: SmartCardClientResponse): Status => {
  const { access, visibility } = meta;
  switch (access) {
    case 'forbidden':
      if (visibility === 'not_found') {
        return 'not_found';
      } else {
        return 'forbidden';
      }
    case 'unauthorized':
      return 'unauthorized';
    default:
      return 'resolved';
  }
};

interface TrelloSmartLinkAnalyticsResult {
  source: SourceType;
  containers: EventContainer | undefined;
  attributes: Record<string, string | undefined>;
}

export const useTrelloSmartLinkAnalytics = (
  resolvedUrl: SmartCardClientResponse | undefined,
  additionalContext: SmartLinkAnalyticsContextType,
): TrelloSmartLinkAnalyticsResult => {
  const { source, attributes, containers } = additionalContext;

  return useMemo(
    () => ({
      source,
      containers,
      attributes: {
        definitionId: resolvedUrl?.meta?.definitionId,
        accessType: resolvedUrl?.meta?.requestAccess?.accessType,
        cloudId: resolvedUrl?.meta?.requestAccess?.cloudId,
        urlAccess: resolvedUrl?.meta?.access,
        extensionKey: resolvedUrl?.meta?.key,
        status: resolvedUrl ? getStatus(resolvedUrl) : undefined,
        ...(attributes && attributes),
      },
    }),
    // For some reason, enumerating the exact dependencies makes the test "useSmartLinkAnalytics hook › returns same value when param remains the same instance" fail

    [resolvedUrl, attributes, source, containers],
  );
};
