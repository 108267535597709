import * as Types from '@trello/graphql/generated';

import DocumentNode from '@trello/graphql/src/documentNode';
import * as Apollo from '@apollo/client';
export const DeleteSharedPluginDataDocument = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"mutation","name":{"kind":"Name","value":"DeleteSharedPluginData"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"boardId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}},{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"pluginId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"ID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"deleteSharedPluginData"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"boardId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"boardId"}}},{"kind":"Argument","name":{"kind":"Name","value":"pluginId"},"value":{"kind":"Variable","name":{"kind":"Name","value":"pluginId"}}}],"directives":[{"kind":"Directive","name":{"kind":"Name","value":"client"}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"success"}}]}}]}}]} as unknown as DocumentNode;
const defaultOptions = {"context":{"operationName":"DeleteSharedPluginData","document":DeleteSharedPluginDataDocument}} as const;
export type DeleteSharedPluginDataMutationVariables = Types.Exact<{
  boardId: Types.Scalars['ID']['input'];
  pluginId: Types.Scalars['ID']['input'];
}>;


export type DeleteSharedPluginDataMutation = (
  { __typename: 'Mutation' }
  & { deleteSharedPluginData?: Types.Maybe<(
    { __typename: 'Shared_Plugin_Data_DeleteResponse' }
    & Pick<Types.Shared_Plugin_Data_DeleteResponse, 'success'>
  )> }
);

export type DeleteSharedPluginDataMutationFn = Apollo.MutationFunction<
  DeleteSharedPluginDataMutation,
  DeleteSharedPluginDataMutationVariables
>;

/**
 * __useDeleteSharedPluginDataMutation__
 *
 * To run a mutation, you first call `useDeleteSharedPluginDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteSharedPluginDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteSharedPluginDataMutation, { data, loading, error }] = useDeleteSharedPluginDataMutation({
 *   variables: {
 *      boardId: // value for 'boardId'
 *      pluginId: // value for 'pluginId'
 *   },
 * });
 */
export function useDeleteSharedPluginDataMutation(
  baseOptions?: Apollo.MutationHookOptions<
    DeleteSharedPluginDataMutation,
    DeleteSharedPluginDataMutationVariables
  >,
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<
    DeleteSharedPluginDataMutation,
    DeleteSharedPluginDataMutationVariables
  >(DeleteSharedPluginDataDocument, options);
}
export type DeleteSharedPluginDataMutationHookResult = ReturnType<
  typeof useDeleteSharedPluginDataMutation
>;
export type DeleteSharedPluginDataMutationResult =
  Apollo.MutationResult<DeleteSharedPluginDataMutation>;
export type DeleteSharedPluginDataMutationOptions = Apollo.BaseMutationOptions<
  DeleteSharedPluginDataMutation,
  DeleteSharedPluginDataMutationVariables
>;
