// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DweEFaF5owOe02 {
  display: inline-flex;
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  width: 28px;
  max-width: 128px;
  height: 28px;
  max-height: 128px;
  overflow: hidden;
  border: 0;
  border-radius: 100%;
  opacity: 1;
  background-color: var(--ds-background-accent-gray-subtler, #dcdfe4);
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  color: var(--ds-icon, #44546f);
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Noto Sans', 'Ubuntu', 'Droid Sans', 'Helvetica Neue', sans-serif;
  font-size: 12px;
  font-weight: bold;
  line-height: 28px;
  white-space: nowrap;
  -webkit-print-color-adjust: exact;
}
.DweEFaF5owOe02.pDQ13XeuqNHubf {
  background-color: var(--ds-background-accent-gray-subtlest, #f1f2f4);
  color: var(--ds-icon-subtle, #626f86);
}
.DweEFaF5owOe02.TJUpPn0jtraRiv {
  color: var(--ds-text-subtle, #44546f);
}
.DweEFaF5owOe02.yx5_x_4I9Oxa31 {
  border: 1px solid var(--ds-background-accent-gray-subtler, #dcdfe4);
  background-color: var(--ds-background-accent-gray-subtler, #dcdfe4);
}
.DweEFaF5owOe02.yx5_x_4I9Oxa31.pDQ13XeuqNHubf {
  background-color: var(--ds-background-accent-gray-subtlest, #f1f2f4);
}
.aAavNeba2Z7ob4.DweEFaF5owOe02 {
  margin-top: 4px;
  margin-left: 4px;
  float: right;
}
.FZeOmAUAJECpqh.DweEFaF5owOe02 {
  flex: 0 0 auto;
  align-self: flex-start;
}
`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"avatar": `DweEFaF5owOe02`,
	"deactivated": `pDQ13XeuqNHubf`,
	"isGhost": `TJUpPn0jtraRiv`,
	"lightBackground": `yx5_x_4I9Oxa31`,
	"cardFront": `aAavNeba2Z7ob4`,
	"detailHeader": `FZeOmAUAJECpqh`
};
export default ___CSS_LOADER_EXPORT___;
