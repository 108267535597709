import type { FunctionComponent, MouseEvent } from 'react';
import { useCallback } from 'react';

import { ErrorBoundary } from '@trello/error-boundaries';

import { SmartLink } from './SmartLink';
import type { SmartLinkAnalyticsContextType } from './SmartLinkAnalyticsContext';

interface SmartEmbedPreviewsProps {
  urls: string[];
  handleTrelloLinkClick: (e: MouseEvent, linkElem: HTMLAnchorElement) => void;
  analyticsContext: SmartLinkAnalyticsContextType;
}

export const SmartEmbedPreviews: FunctionComponent<SmartEmbedPreviewsProps> = ({
  urls,
  handleTrelloLinkClick,
  analyticsContext,
}) => {
  const plainLink = useCallback(() => <></>, []);

  return (
    <ErrorBoundary
      tags={{
        ownershipArea: 'trello-web-eng',
        feature: 'Smart Embed Previews',
      }}
      errorHandlerComponent={plainLink}
    >
      {urls.map((url) => (
        <SmartLink
          key={url}
          url={url}
          plainLink={plainLink}
          showFrame={false}
          appearance="embed"
          handleTrelloLinkClick={handleTrelloLinkClick}
          analyticsContext={analyticsContext}
        />
      ))}
    </ErrorBoundary>
  );
};
