import { createContext } from 'use-context-selector';

import type { BoardPowerUp } from './BoardPowerUpDirectory.types';

export interface BoardPowerUpsContextValue {
  allPowerUps: BoardPowerUp[];
  enabledPowerUps: BoardPowerUp[];
}

export const emptyBoardPowerUpsContext: BoardPowerUpsContextValue = {
  allPowerUps: [],
  enabledPowerUps: [],
};

export const BoardPowerUpsContext = createContext<BoardPowerUpsContextValue>(
  emptyBoardPowerUpsContext,
);
