import { useCallback } from 'react';

import MenuIcon from '@atlaskit/icon/glyph/menu';
import { intl } from '@trello/i18n';
import { Button } from '@trello/nachos/button';
import { CloseIcon } from '@trello/nachos/icons/close';
import type { BoardPowerUpsViewParams, RouteId } from '@trello/router';
import { useRouteParams } from '@trello/router';

import { returnToBoard } from 'app/src/components/Board/returnToBoard';
import { toggleNavigationMenu } from './toggleNavigationMenu';

function getDirectoryHeaderTitle({
  category,
  section,
}: {
  category?: BoardPowerUpsViewParams['category'] | null;
  section?: BoardPowerUpsViewParams['section'] | null;
}) {
  if (category) {
    switch (category) {
      case 'analytics-reporting':
        return intl.formatMessage({
          id: 'templates.directory.analytics-reporting',
          defaultMessage: 'Analytics &amp; reporting',
          description:
            'Title for the "Analytics & Reporting" category in the Power-Up directory header',
        });
      case 'automation':
        return intl.formatMessage({
          id: 'templates.directory.automation',
          defaultMessage: 'Automation',
          description:
            'Title for the "Automation" category in the Power-Up directory header',
        });
      case 'board-utilities':
        return intl.formatMessage({
          id: 'templates.directory.board-utilities',
          defaultMessage: 'Board utilities',
          description:
            'Title for the "Board Utilities" category in the Power-Up directory header',
        });
      case 'communication-collaboration':
        return intl.formatMessage({
          id: 'templates.directory.communication-collaboration',
          defaultMessage: 'Communication &amp; collaboration',
          description:
            'Title for the "Communication & Collaboration" category in the Power-Up directory header',
        });
      case 'developer-tools':
        return intl.formatMessage({
          id: 'templates.directory.developer-tools',
          defaultMessage: 'Developer tools',
          description:
            'Title for the "Developer Tools" category in the Power-Up directory header',
        });
      case 'file-management':
        return intl.formatMessage({
          id: 'templates.directory.file-management',
          defaultMessage: 'File management',
          description:
            'Title for the "File Management" category in the Power-Up directory header',
        });
      case 'hr-operations':
        return intl.formatMessage({
          id: 'templates.directory.hr-operations',
          defaultMessage: 'HR &amp; operations',
          description:
            'Title for the "HR & Operations" category in the Power-Up directory header',
        });
      case 'it-project-management':
        return intl.formatMessage({
          id: 'templates.directory.it-project-management',
          defaultMessage: 'IT &amp; project management',
          description:
            'Title for the "IT & Project Management" category in the Power-Up directory header',
        });
      case 'marketing-social-media':
        return intl.formatMessage({
          id: 'templates.directory.marketing-social-media',
          defaultMessage: 'Marketing &amp; social media',
          description:
            'Title for the "Marketing & Social Media" category in the Power-Up directory header',
        });
      case 'product-design':
        return intl.formatMessage({
          id: 'templates.directory.product-design',
          defaultMessage: 'Product &amp; design',
          description:
            'Title for the "Product & Design" category in the Power-Up directory header',
        });
      case 'sales-support':
        return intl.formatMessage({
          id: 'templates.directory.sales-support',
          defaultMessage: 'Sales &amp; support',
          description:
            'Title for the "Sales & Support" category in the Power-Up directory header',
        });
      default:
        return intl.formatMessage({
          id: 'templates.directory.power-ups',
          defaultMessage: 'Power-Ups',
          description:
            'Title for the Power-Up directory header when the category is unknown',
        });
    }
  } else if (section) {
    switch (section) {
      case 'custom':
        return intl.formatMessage({
          id: 'templates.directory.custom',
          defaultMessage: 'Custom',
          description:
            'Title of the page for the "Custom" section in the Power-Up directory',
        });
      case 'enabled':
        return intl.formatMessage({
          id: 'templates.directory.enabled',
          defaultMessage: 'Enabled',
          description:
            'Title of the page for the "Enabled" section in the Power-Up directory',
        });
      case 'made-by-trello':
        return intl.formatMessage({
          id: 'templates.directory.made-by-trello',
          defaultMessage: 'Made by Trello',
          description:
            'Title of the page for the "Made by Trello" section in the Power-Up directory',
        });
      default:
        return intl.formatMessage({
          id: 'templates.directory.power-ups',
          defaultMessage: 'Power-Ups',
          description:
            'Title for the Power-Up directory header when the section is unknown',
        });
    }
  }

  return intl.formatMessage({
    id: 'templates.directory.power-ups',
    defaultMessage: 'Power-Ups',
    description: 'Title for the Power-Up directory header',
  });
}

export function DirectoryHeader() {
  const routeParams = useRouteParams<RouteId.BOARD>();
  const category = 'category' in routeParams ? routeParams.category : null;
  const section = 'section' in routeParams ? routeParams.section : null;

  // TODO: Need to set Power-Up directory title back to "Power-Ups" when searching.

  const handleCloseClick = useCallback(() => {
    returnToBoard();
  }, []);

  return (
    <div className="directory-header">
      <div className="directory-header-toolbar">
        <h2
          className="directory-header-toolbar-title"
          // It is safe to use dangerouslySetInnerHTML here because the translations do not include user-generated content
          // eslint-disable-next-line react/no-danger
          dangerouslySetInnerHTML={{
            __html: getDirectoryHeaderTitle({ category, section }),
          }}
        />
        <div className="directory-header-toolbar-left">
          <div className="directory-hamburger">
            <Button
              // eslint-disable-next-line @atlaskit/design-system/icon-label
              iconBefore={<MenuIcon size="medium" label="" />}
              onClick={toggleNavigationMenu}
              appearance="subtle"
            />
          </div>
        </div>
        <div className="directory-header-toolbar-right">
          <Button
            iconBefore={<CloseIcon size="large" />}
            onClick={handleCloseClick}
            appearance="subtle"
            className="js-close-directory"
          />
        </div>
      </div>
    </div>
  );
}
