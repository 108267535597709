/* eslint-disable @trello/export-matches-filename */

import type { FunctionComponent } from 'react';
import { Suspense } from 'react';

import {
  ChunkLoadErrorBoundary,
  ErrorBoundary,
} from '@trello/error-boundaries';
import { DeferredSuspense, useLazyComponent } from '@trello/use-lazy-component';

import type { ButlerCardButtonsProps } from './Buttons/ButlerCardButtons';
import type { ButlerListMenuSectionProps } from './ListRule/ButlerListMenuSection';
import type { AutomaticReportsViewProps } from './Reports/types';

// Entry points required for lazily loading Butler components in non-React contexts.

export const ButlerCardButtonsWrapper: FunctionComponent<
  ButlerCardButtonsProps
> = (props) => {
  const ButlerCardButtons = useLazyComponent(
    () =>
      import(
        /* webpackChunkName: "butler-card-buttons" */ './Buttons/ButlerCardButtons'
      ),
    { namedImport: 'ButlerCardButtons', preload: false },
  );

  return (
    <DeferredSuspense fallback={null}>
      <ErrorBoundary
        tags={{
          ownershipArea: 'trello-web-eng',
          feature: 'Butler on Boards',
        }}
      >
        <ChunkLoadErrorBoundary fallback={null}>
          <ButlerCardButtons {...props} />
        </ChunkLoadErrorBoundary>
      </ErrorBoundary>
    </DeferredSuspense>
  );
};

export const AutomaticReportsWrapper: FunctionComponent<
  AutomaticReportsViewProps
> = (props) => {
  const AutomaticReportsView = useLazyComponent(
    () =>
      import(
        /* webpackChunkName: "automatic-reports-view" */ './Reports/AutomaticReportsView'
      ),
    { namedImport: 'AutomaticReportsView', preload: false },
  );

  return (
    <Suspense fallback={null}>
      <ChunkLoadErrorBoundary fallback={null}>
        <AutomaticReportsView {...props} />
      </ChunkLoadErrorBoundary>
    </Suspense>
  );
};

export const ButlerListMenuSectionWrapper: FunctionComponent<
  ButlerListMenuSectionProps
> = (props) => {
  const ButlerListMenuSection = useLazyComponent(
    () =>
      import(
        /* webpackChunkName: "butler-list-menu-section" */ './ListRule/ButlerListMenuSection'
      ),
    { namedImport: 'ButlerListMenuSection', preload: false },
  );

  return (
    <Suspense fallback={null}>
      <ChunkLoadErrorBoundary fallback={null}>
        <ButlerListMenuSection {...props} />
      </ChunkLoadErrorBoundary>
    </Suspense>
  );
};

export const ButlerFrameWrapper: FunctionComponent<{ boardId: string }> = ({
  boardId,
}) => {
  const ButlerFrame = useLazyComponent(
    () => import(/* webpackChunkName: "butler" */ './ButlerFrame'),
    { namedImport: 'ButlerFrame', preload: false },
  );

  return (
    <Suspense fallback={null}>
      <ChunkLoadErrorBoundary fallback={null}>
        <ButlerFrame boardId={boardId} />
      </ChunkLoadErrorBoundary>
    </Suspense>
  );
};
