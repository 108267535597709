export const joinn = (
  inputArray: string[],
  glue = ', ',
  lastGlue = ' and ',
) => {
  const array = [...inputArray];
  if (array.length === 0) {
    return '';
  } else if (array.length === 1) {
    return array.pop();
  } else {
    const last = array.pop();
    return array.join(glue) + lastGlue + last;
  }
};
