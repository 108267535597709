// this component was automatically generated by IconGlyph.template.js
import type { FunctionComponent } from 'react';

import type { TestId } from '@trello/test-ids';

import type { IconSize } from '../src/components/Icon';
import { Icon } from '../src/components/Icon';

export interface GlyphProps {
  /**
   * A string that is applied as an aria attribute on the icon.
   */
  label?: string;
  /**
   * The color that the Icon should be rendered as.
   * @default token('color.icon', '#42526E')
   */
  color?: string;
  /**
   * The size to render the Icon size.
   * @default "medium"
   */
  size?: IconSize;
  /**
   * A string that gets placed as a data attribute (data-testid) onto the
   * Icon wrapper so that our
   * smoketest can properly target and test the component
   * @default undefined
   */
  testId?: TestId | string;
  // Escape hatches
  /**
   * ⚠️ DO NOT USE THIS PROP UNLESS YOU REALLY REALLY HAVE TO.
   *
   * Places a class name on the Icon (more specifically, the svg itself). This
   * is placed in addition to the classes already placed on the Icon. This is
   * placed directly onto the SVG via the glyph templates that are
   * generated by IconGlyph.template.js
   *
   * Please refrain from using this unless absolutely necessary.
   * @default undefined
   */
  dangerous_className?: string;
  /**
   * The switch for the icon to be centered in the dedicated space with padding around it.
   * Designed for cases when icon is not inline.
   */
  block?: boolean;
}

const AtlassianIntelligenceIconGlyph = () => {
  return (
    <svg
      width="24"
      height="24"
      role="presentation"
      focusable="false"
      viewBox="-2 -2 22 22"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.1699 9C14.1699 8.44772 14.6176 8 15.1699 8H16.9999C17.5522 8 17.9999 8.44772 17.9999 9C17.9999 9.55228 17.5522 10 16.9999 10H15.1699C14.6176 10 14.1699 9.55228 14.1699 9Z"
        fill="url(#a)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 0C9.55228 0 10 0.447715 10 1V2.83C10 3.38228 9.55228 3.83 9 3.83C8.44772 3.83 8 3.38228 8 2.83V1C8 0.447715 8.44772 0 9 0Z"
        fill="url(#b)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 9C0 8.44772 0.447715 8 1 8H2.83C3.38228 8 3.83 8.44772 3.83 9C3.83 9.55228 3.38228 10 2.83 10H1C0.447715 10 0 9.55228 0 9Z"
        fill="url(#c)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9 14.17C9.55228 14.17 10 14.6177 10 15.17V17C10 17.5523 9.55228 18 9 18C8.44772 18 8 17.5523 8 17V15.17C8 14.6177 8.44772 14.17 9 14.17Z"
        fill="url(#d)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.2929 11.2929C11.6834 10.9024 12.3166 10.9024 12.7071 11.2929L16.7071 15.2929C17.0976 15.6834 17.0976 16.3166 16.7071 16.7071C16.3166 17.0976 15.6834 17.0976 15.2929 16.7071L11.2929 12.7071C10.9024 12.3166 10.9024 11.6834 11.2929 11.2929Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.7071 1.29289C17.0976 1.68342 17.0976 2.31658 16.7071 2.70711L12.7071 6.70711C12.3166 7.09763 11.6834 7.09763 11.2929 6.70711C10.9024 6.31658 10.9024 5.68342 11.2929 5.29289L15.2929 1.29289C15.6834 0.902369 16.3166 0.902369 16.7071 1.29289Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.29289 1.29289C1.68342 0.902369 2.31658 0.902369 2.70711 1.29289L6.70711 5.29289C7.09763 5.68342 7.09763 6.31658 6.70711 6.70711C6.31658 7.09763 5.68342 7.09763 5.29289 6.70711L1.29289 2.70711C0.902369 2.31658 0.902369 1.68342 1.29289 1.29289Z"
        fill="currentColor"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.70711 11.2929C7.09763 11.6834 7.09763 12.3166 6.70711 12.7071L2.70711 16.7071C2.31658 17.0976 1.68342 17.0976 1.29289 16.7071C0.902369 16.3166 0.902369 15.6834 1.29289 15.2929L5.29289 11.2929C5.68342 10.9024 6.31658 10.9024 6.70711 11.2929Z"
        fill="currentColor"
      />
      <defs>
        <linearGradient
          id="a"
          x1="17.9999"
          y1="9"
          x2="14.4999"
          y2="9"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.270833" stopColor="currentColor" />
          <stop offset="1" stopColor="currentColor" />
        </linearGradient>
        <linearGradient
          id="b"
          x1="9.5"
          y1="6.93061e-08"
          x2="9.5"
          y2="3.5"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0.1875" stopColor="currentColor" />
          <stop offset="1" stopColor="currentColor" />
        </linearGradient>
        <linearGradient
          id="c"
          x1="3.82996"
          y1="9"
          x2="0.329956"
          y2="9"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="currentColor" />
          <stop offset="0.791667" stopColor="currentColor" />
        </linearGradient>
        <linearGradient
          id="d"
          x1="9.5"
          y1="14"
          x2="9.5"
          y2="18"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="currentColor" />
          <stop offset="0.802083" stopColor="currentColor" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export const AtlassianIntelligenceIcon: FunctionComponent<GlyphProps> = (
  props,
) => {
  const { testId, dangerous_className, size, color, label, block } = props;
  return (
    <Icon
      testId={testId || 'AtlassianIntelligenceIcon'}
      size={size}
      dangerous_className={dangerous_className}
      color={color}
      block={block}
      label={label}
      glyph={AtlassianIntelligenceIconGlyph}
    />
  );
};
