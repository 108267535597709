export function toggleNavigationMenu() {
  const directorySidebar = $('.js-directory-sidebar');
  const responsiveOverlay = $('.js-directory-responsive-overlay');

  if (directorySidebar.hasClass('active')) {
    directorySidebar.removeClass('active');
    return responsiveOverlay.removeClass('active');
  } else {
    directorySidebar.addClass('active');
    return responsiveOverlay.addClass('active');
  }
}
