import * as Types from '@trello/graphql/generated';

import DocumentNode from '@trello/graphql/src/documentNode';
import * as Apollo from '@apollo/client';
export type CardListNameFragment = (
  { __typename: 'List' }
  & Pick<Types.List, 'id' | 'name'>
);

export type CardNameFragment = (
  { __typename: 'Card' }
  & Pick<Types.Card, 'id' | 'name'>
);

export const CardListNameFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CardListName' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'List' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;
export const CardNameFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'CardName' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Card' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'name' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

interface UseCardListNameFragmentOptions
  extends Omit<
    Apollo.UseFragmentOptions<CardListNameFragment, Apollo.OperationVariables>,
    'fragment' | 'fragmentName' | 'from'
  > {
  from: Apollo.StoreObject | Apollo.Reference;
  returnPartialData?: boolean;
}

interface UseCardListNameFragmentResult
  extends Omit<Apollo.UseFragmentResult<CardListNameFragment>, 'data'> {
  data?: CardListNameFragment;
}

export const useCardListNameFragment = ({
  from,
  returnPartialData,
  ...options
}: UseCardListNameFragmentOptions): UseCardListNameFragmentResult => {
  const result = Apollo.useFragment<CardListNameFragment>({
    ...options,
    fragment: CardListNameFragmentDoc,
    fragmentName: 'CardListName',
    from: {
      __typename: 'List',
      ...from,
    },
  });

  // Ensure that the fragment result is not typed as a DeepPartial.
  if (!result.complete && !returnPartialData) {
    return {
      ...result,
      data: undefined,
    };
  }

  return { ...result, data: result.data as CardListNameFragment };
};
