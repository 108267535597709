// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS206: Consider reworking classes to avoid initClass
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */

// eslint-disable-next-line no-restricted-imports
import $ from '@trello/jquery';

import { ActionList } from 'app/scripts/models/collections/ActionList';
import Payloads from 'app/scripts/network/payloads';
import { ActionListView } from 'app/scripts/views/action/ActionListView';
import { templates } from 'app/scripts/views/internal/templates';
import { View } from 'app/scripts/views/internal/View';
import { ViewWithActions } from 'app/scripts/views/internal/ViewWithActions';
import { BoardMemberProfileTemplate } from 'app/scripts/views/templates/BoardMemberProfileTemplate';

interface MemberBoardProfileView {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  actionListView: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  board: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  idModels: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  nextPage: any;
}
class MemberBoardProfileView extends View {
  static initClass() {
    // @ts-expect-error TS(2322): Type '{ 'click .js-more-actions': string; }' is no... Remove this comment to see the full error message
    this.prototype.events = { 'click .js-more-actions': 'loadMoreActions' };

    // @ts-expect-error TS(2339): Property 'limit' does not exist on type 'MemberBoa... Remove this comment to see the full error message
    this.prototype.limit = ViewWithActions.limit;
    this.prototype.nextPage = ViewWithActions.nextPage;
    // @ts-expect-error TS(2339): Property 'shortLoad' does not exist on type 'Membe... Remove this comment to see the full error message
    this.prototype.shortLoad = ViewWithActions.shortLoad;
    // @ts-expect-error TS(2339): Property 'loading' does not exist on type 'MemberB... Remove this comment to see the full error message
    this.prototype.loading = ViewWithActions.loading;
    this.prototype.idModels = ViewWithActions.idModels;
  }

  initialize() {
    this.board = this.options.board;
    this.listenTo(
      this.board.memberList,
      'add remove reset',
      this.frameDebounce(this.render),
    );

    this.actionListView = this.collectionSubview(
      ActionListView,
      new ActionList(this.model.getActions(this.board), {
        modelCache: this.modelCache,
      }),
      {
        renderOpts: {
          context: this.model,
          readOnly: true,
          source: 'boardScreen',
        },
      },
    );

    this.listenTo(
      this.modelCache,
      `add:Action:${this.model.id} remove:Action:${this.model.id}`,
      () => {
        this.actionListView.collection.reset(
          this.model.getActions(this.board),
          { silent: true },
        );
        return this.renderActions();
      },
    );

    this.idModels = [this.board.id];
    this.nextPage = 0;
    return this.loadMoreActions();
  }

  render() {
    this.$el.html(
      templates.fill(BoardMemberProfileTemplate, this.model.toJSON()),
    );
    this.actionListView.setElement(this.el.querySelector('.list-actions'));
    return this.renderActions();
  }

  renderActions() {
    this.actionListView.render();
    $('.js-more-actions', this.$el).toggle(this.hasMoreActions());
    return this;
  }

  getActionsFilter() {
    return Payloads.memberActions;
  }

  loadMoreActions() {
    return ViewWithActions.loadMoreActions.call(this);
  }
  hasMoreActions() {
    return ViewWithActions.hasMoreActions.call(this);
  }
  getActions() {
    return ViewWithActions.getActions.call(this);
  }
}
MemberBoardProfileView.initClass();

export { MemberBoardProfileView };
