// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.a1FeBaCc1kb4nU {
  position: absolute;
  top: 0;
  right: var(--ds-space-150, 0.75rem);
  bottom: var(--ds-space-150, 0.75rem);
  left: var(--ds-space-150, 0.75rem);
  border-radius: 8px;
}
.a1FeBaCc1kb4nU > div {
  border-radius: 8px;
}
@media print {
  .a1FeBaCc1kb4nU {
    position: relative;
    right: auto;
    left: auto;
  }
}
`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": `a1FeBaCc1kb4nU`
};
export default ___CSS_LOADER_EXPORT___;
