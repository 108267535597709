// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import { FormattedMessage } from 'react-intl';
import _ from 'underscore';

import { renderReactRoot } from '@trello/component-wrapper';
import { localizeCount } from '@trello/legacy-i18n';

import type { Board } from 'app/scripts/models/Board';
import { ReactRootComponent } from 'app/scripts/views/internal/ReactRootComponent';
import { View } from 'app/scripts/views/internal/View';
import { PowerUpItemType } from 'app/src/components/PowerUp';
import { DirectoryPluginItemView } from './DirectoryPluginItemView';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
class DirectorySearchView extends View<Board, any> {
  eventSource() {
    return 'boardDirectorySearch' as const;
  }

  render() {
    // @ts-expect-error TS(2556): A spread argument must either have a tuple type or... Remove this comment to see the full error message
    super.render(...arguments);

    const { searchTerm, plugins, directoryView } = this.options;

    const enabledPlugins = directoryView.getEnabledPlugins();
    const listedPowerUpsEnabled = _.intersection(
      _.map(plugins, 'id'),
      _.map(enabledPlugins, 'id'),
    );

    directoryView.trackScreenEvent(
      this.eventSource(),
      {
        totalListed: plugins.length,
        totalListedEnabled: listedPowerUpsEnabled.length,
        totalEnabled: enabledPlugins.length,
        allPowerUpsEnabled: _.map(enabledPlugins, 'id'),
      },
      listedPowerUpsEnabled,
    );

    renderReactRoot(
      <div>
        <ReactRootComponent>
          <div className="directory-content-body">
            {_.isEmpty(plugins) ? (
              <div className="directory-search-empty">
                <p>
                  <FormattedMessage
                    id="templates.directory.bummer-no-search-results-for"
                    defaultMessage="Bummer! No search results for "
                    description="Message displayed when a user searches for a power-up and there are no results."
                  />
                  <span>{`"${searchTerm}"`}</span>
                </p>
              </div>
            ) : (
              <div className="results-header">
                <h2>
                  {localizeCount('count-results-for', plugins.length, {
                    query: searchTerm,
                  })}
                </h2>
              </div>
            )}
            <div className="directory-listing" />
          </div>
        </ReactRootComponent>
      </div>,
      this.el,
    );

    this.defer(() => {
      const contentContainer = this.$el.find('.directory-listing');

      const pluginViews = _.map(plugins, (plugin) => {
        return this.subview(
          DirectoryPluginItemView,
          this.model,
          {
            atomType: PowerUpItemType.Description,
            plugin,
            directoryView,
          },
          // @ts-expect-error TS(2571): Object is of type 'unknown'.
          plugin.id,
        );
      });

      this.appendSubviews(pluginViews, contentContainer);
    });

    return this;
  }
}

export { DirectorySearchView };
