import { useEffect, useMemo, useState } from 'react';

import { useCrossFlow } from '@atlassiansox/cross-flow-support';
import type { TouchpointSourceType } from '@trello/ad-sequencer';
import { useMemberId } from '@trello/authentication';
import { isDesktop } from '@trello/browser';
import { getDateBefore, idToDate } from '@trello/dates';
import type { ExperimentVariations } from '@trello/feature-gate-client';

import { type JiraTemplateIdType } from '../JiraTemplateId.types';
import { trelloCrossFlowOpen } from '../TrelloCrossFlow';
import { useHasEligibleWorkspaces } from './runtimeEligibilityChecks/useHasEligibleWorkspaces';
import { useHasNoAvailableSites } from './runtimeEligibilityChecks/useHasNoAvailableSites';
import { useTrelloCrossFlowExperiment } from './useTrelloCrossFlowExperiment';

const experimentFeatureKey = 'trello_xf_template_gallery_v1_non_ppm';

export type CrossFlowForJiraTemplateInTrelloTemplateGalleryNonPpmExperimentVariations =
  ExperimentVariations<typeof experimentFeatureKey, 'cohort'>;

export interface CrossFlowForTemplateGalleryParams {
  source: TouchpointSourceType;
  workspaceId?: string;
  hasValidAaSession: boolean;
  jiraTemplateId?: JiraTemplateIdType;
}

export const useCrossFlowForJiraTemplateInTrelloTemplateGalleryNonPpm = ({
  source,
  workspaceId,
  hasValidAaSession,
  jiraTemplateId,
}: CrossFlowForTemplateGalleryParams) => {
  const crossFlow = useCrossFlow();

  const memberId = useMemberId();

  /**
   * Eligibility criteria:
   * 1. Signed up more than 21 days ago
   * 2. Web MAU
   * 3. Non-PPM (captured in targeting gate)
   * 4. No other Atlassian products
   * 5. Not paid workspace
   * 6. Not > 10 users in a workspace (captured in targeting gate)
   * 7. Is not enrolled in Ad Sequencer experiment (captured in targeting gate)
   */

  // 1. Check that the user is an existing user (i.e. they signed up more than 21 days ago)
  const signedUpMoreThan21DaysAgo =
    idToDate(memberId) < getDateBefore({ days: 21 });

  // 2. Check that the user is not on desktop
  const isWeb = !isDesktop();

  const [
    isImmediateRuntimeEligibilityChecksPassed,
    setImmediateRuntimeEligibilityChecksPassed,
  ] = useState(false);
  // 4. Check that the member has no other Atlassian products
  const { isEligible: hasNoAvailableSites } = useHasNoAvailableSites({
    doLoadAvailableSites: isImmediateRuntimeEligibilityChecksPassed,
    source,
  });

  // 5. Check that the user is not in any paid workspace
  const { isEligible: hasPaidWorkspaces, eligibleWorkspaceOptions } =
    useHasEligibleWorkspaces({
      entitlementRequired: 'standardOrPremium',
    });

  const experiment = useTrelloCrossFlowExperiment({
    experimentFeatureKey,
    source,
    eligibilityCheckResults: [
      !hasPaidWorkspaces,
      signedUpMoreThan21DaysAgo,
      crossFlow.isEnabled,
      isWeb,
    ],
    deferredEligibilityCheckResults: [hasNoAvailableSites],
  });

  useEffect(() => {
    setImmediateRuntimeEligibilityChecksPassed(
      experiment.isImmediateRuntimeEligibilityChecksPassed,
    );
  }, [experiment.isImmediateRuntimeEligibilityChecksPassed]);

  return useMemo(
    () => ({
      isEnrolled: experiment.isEnrolled,
      getExperimentCohort: experiment.getExperimentCohort,
      experimentName: experiment.experimentName,
      getIsTargeted: experiment.getIsTargeted,
      open: trelloCrossFlowOpen(
        crossFlow,
        source,
        eligibleWorkspaceOptions,
        hasValidAaSession,
        {
          workspaceId,
          jiraTemplateId,
        },
      ),
    }),
    [
      crossFlow,
      eligibleWorkspaceOptions,
      experiment.experimentName,
      experiment.getExperimentCohort,
      experiment.getIsTargeted,
      experiment.isEnrolled,
      hasValidAaSession,
      jiraTemplateId,
      source,
      workspaceId,
    ],
  );
};
