// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */

import { FormattedMessage } from 'react-intl';
import _ from 'underscore';

import { deferUntil, renderReactRoot } from '@trello/component-wrapper';

import type { Board } from 'app/scripts/models/Board';
import type { Plugin } from 'app/scripts/models/Plugin';
import { ReactRootComponent } from 'app/scripts/views/internal/ReactRootComponent';
import { View } from 'app/scripts/views/internal/View';
import { PowerUpItemType } from 'app/src/components/PowerUp';
import { DirectoryPluginItemView } from './DirectoryPluginItemView';
import type { DirectoryView } from './DirectoryView';
import { filterUnavailablePlugins } from './filterUnavailablePlugins';

function DirectoryEnabledComponent() {
  return (
    <div className="directory-content-body">
      <div className="directory-listing"></div>
    </div>
  );
}

function DirectoryEnabledEmptyComponent() {
  return (
    <div className="directory-content-body directory-enabled-empty">
      <div className="directory-enabled-empty-text">
        <FormattedMessage
          tagName="p"
          id="templates.directory_enabled.there-are-no-power-ups-enabled-for-this-board"
          defaultMessage="There are no Power-Ups enabled for this board."
          description="Message displayed when there are no Power-Ups enabled for this board"
        />
        <FormattedMessage
          tagName="p"
          id="templates.directory_enabled.get-started-with-these-great-power-ups"
          defaultMessage="Get started with these great Power-Ups"
          description="A call-to-action to get started with Power-Ups by selecting one from the list below"
        />
      </div>
      <div className="directory-listing" />
    </div>
  );
}

interface DirectoryEnabledView {
  directoryView: DirectoryView;
  enabledPlugins: Plugin[];
  plugins: Plugin[];
}

class DirectoryEnabledView extends View<Board> {
  eventSource() {
    return 'boardDirectoryEnabledScreen' as const;
  }

  initialize({
    plugins,
    directoryView,
  }: {
    plugins: Plugin[];
    directoryView: DirectoryView;
  }) {
    this.plugins = plugins;
    this.directoryView = directoryView;
    super.initialize(...arguments);
    this.listenTo(
      this.model,
      'change:idOrganization change:powerUps',
      this.frameDebounce(this.render),
    );
    this.listenTo(
      this.model.boardPluginList,
      'add remove reset',
      this.frameDebounce(this.render),
    );
  }

  // Returns 3 random plugins, but filter hidden/moderated/deprecated ones first
  getPluginSuggestions(): Plugin[] {
    const filteredPlugins = filterUnavailablePlugins(this.plugins);
    return _.sample(filteredPlugins, 3);
  }

  render() {
    this.enabledPlugins = this.directoryView.getEnabledPlugins();

    renderReactRoot(
      <div>
        <ReactRootComponent>
          {this.enabledPlugins.length ? (
            <DirectoryEnabledComponent />
          ) : (
            <DirectoryEnabledEmptyComponent />
          )}
        </ReactRootComponent>
      </div>,
      this.el,
    );

    deferUntil(
      () => this.$el.find('.directory-listing').length > 0,
      () => {
        let listedPlugins;
        const contentContainer = this.$el.find('.directory-listing');

        if (this.enabledPlugins.length > 0) {
          listedPlugins = this.enabledPlugins;
          const enabledPluginViews = _.map(this.enabledPlugins, (plugin) => {
            return this.subview(
              DirectoryPluginItemView,
              this.model,
              {
                atomType: PowerUpItemType.Description,
                plugin,
                directoryView: this.directoryView,
              },
              plugin.id,
            );
          });

          this.appendSubviews(enabledPluginViews, contentContainer);
        } else {
          const pluginSuggestions = this.getPluginSuggestions();
          listedPlugins = pluginSuggestions;
          const powerUpSuggestionViews = _.map(pluginSuggestions, (plugin) => {
            return this.subview(
              DirectoryPluginItemView,
              this.model,
              {
                atomType: PowerUpItemType.Description,
                plugin,
                directoryView: this.directoryView,
              },
              plugin.id,
            );
          });

          this.appendSubviews(powerUpSuggestionViews, contentContainer);
        }

        const listedPowerUpsEnabled = _.intersection(
          _.map(listedPlugins, 'id'),
          _.map(this.enabledPlugins, 'id'),
        );

        this.directoryView.trackScreenEvent(this.eventSource(), {
          totalListed: listedPlugins.length,
          totalListedEnabled: listedPowerUpsEnabled.length,
          totalEnabled: this.enabledPlugins.length,
          allPowerUpsEnabled: this.enabledPlugins.map((plugin) => plugin.id),
        });
      },
    );

    return this;
  }
}

export { DirectoryEnabledView };
