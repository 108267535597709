import * as Types from '@trello/graphql/generated';

import DocumentNode from '@trello/graphql/src/documentNode';
import * as Apollo from '@apollo/client';
export type AboutThisBoardFragment = (
  { __typename: 'Board' }
  & Pick<Types.Board, 'id' | 'closed' | 'desc' | 'descData' | 'idMemberCreator' | 'idOrganization' | 'premiumFeatures'>
  & { boardPlugins: Array<(
    { __typename: 'BoardPlugin' }
    & Pick<Types.BoardPlugin, 'id' | 'idPlugin'>
  )>, prefs?: Types.Maybe<(
    { __typename: 'Board_Prefs' }
    & Pick<Types.Board_Prefs, 'isTemplate' | 'permissionLevel'>
  )> }
);

export const AboutThisBoardFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'AboutThisBoard' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Board' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'boardPlugins' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                { kind: 'Field', name: { kind: 'Name', value: 'idPlugin' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'closed' } },
          { kind: 'Field', name: { kind: 'Name', value: 'desc' } },
          { kind: 'Field', name: { kind: 'Name', value: 'descData' } },
          { kind: 'Field', name: { kind: 'Name', value: 'idMemberCreator' } },
          { kind: 'Field', name: { kind: 'Name', value: 'idOrganization' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'prefs' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'isTemplate' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'permissionLevel' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'premiumFeatures' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

interface UseAboutThisBoardFragmentOptions
  extends Omit<
    Apollo.UseFragmentOptions<
      AboutThisBoardFragment,
      Apollo.OperationVariables
    >,
    'fragment' | 'fragmentName' | 'from'
  > {
  from: Apollo.StoreObject | Apollo.Reference;
  returnPartialData?: boolean;
}

interface UseAboutThisBoardFragmentResult
  extends Omit<Apollo.UseFragmentResult<AboutThisBoardFragment>, 'data'> {
  data?: AboutThisBoardFragment;
}

export const useAboutThisBoardFragment = ({
  from,
  returnPartialData,
  ...options
}: UseAboutThisBoardFragmentOptions): UseAboutThisBoardFragmentResult => {
  const result = Apollo.useFragment<AboutThisBoardFragment>({
    ...options,
    fragment: AboutThisBoardFragmentDoc,
    fragmentName: 'AboutThisBoard',
    from: {
      __typename: 'Board',
      ...from,
    },
  });

  // Ensure that the fragment result is not typed as a DeepPartial.
  if (!result.complete && !returnPartialData) {
    return {
      ...result,
      data: undefined,
    };
  }

  return { ...result, data: result.data as AboutThisBoardFragment };
};
