import { useCallback, useMemo, useState } from 'react';

import { useCrossFlow } from '@atlassiansox/cross-flow-support';
import type { TouchpointSourceType } from '@trello/ad-sequencer';
import type { ExperimentVariations } from '@trello/feature-gate-client';
import { useGetExperimentValue } from '@trello/feature-gate-client';

import {
  type TrelloCrossFlowExperiment,
  trelloCrossFlowOpen,
} from '../TrelloCrossFlow';
import { isSourceTargetedByFeatureKey } from './isSourceTargetedByFeatureFlag';

const experimentFeatureKey = 'trello_xf_1p_ads_in_madshot_v2';

export type WelcomeScreenTaskSelectionExperimentVariations =
  ExperimentVariations<typeof experimentFeatureKey, 'cohort'>;

export interface CrossFlowForWelcomeScreenTaskSelectionParams {
  source: TouchpointSourceType;
  hasValidAaSession: boolean;
}

export const useCrossFlowForWelcomeScreenTaskSelection = ({
  source,
  hasValidAaSession,
}: CrossFlowForWelcomeScreenTaskSelectionParams): TrelloCrossFlowExperiment<WelcomeScreenTaskSelectionExperimentVariations> => {
  const crossFlow = useCrossFlow();

  const [fireExposureEvent, setFireExposureEvent] = useState(false);

  const { cohort } = useGetExperimentValue({
    experimentName: experimentFeatureKey,
    parameter: 'cohort',
    fireExposureEvent,
  });

  const isRuntimeEligibilityChecksPassed = useMemo(
    () =>
      crossFlow.isEnabled &&
      cohort !== 'not-enrolled' &&
      isSourceTargetedByFeatureKey({
        featureKey: experimentFeatureKey,
        source,
      }),
    [cohort, crossFlow.isEnabled, source],
  );

  const getIsTargeted = useCallback(() => {
    return cohort !== 'not-enrolled';
  }, [cohort]);

  const getExperimentCohort = useCallback(() => {
    if (isRuntimeEligibilityChecksPassed) {
      setFireExposureEvent(true);
      return cohort;
    }

    return 'not-enrolled';
  }, [cohort, isRuntimeEligibilityChecksPassed]);

  return useMemo(
    () => ({
      isEnrolled: isRuntimeEligibilityChecksPassed,
      experimentName: experimentFeatureKey,
      getIsTargeted,
      getExperimentCohort,
      open: trelloCrossFlowOpen(crossFlow, source, [], hasValidAaSession, {
        isNewTrelloUser: true,
      }),
    }),
    [
      crossFlow,
      getExperimentCohort,
      getIsTargeted,
      isRuntimeEligibilityChecksPassed,
      source,
      hasValidAaSession,
    ],
  );
};
