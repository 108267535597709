import type { ReactElement } from 'react';

import TrelloMarkdown from '@atlassian/trello-markdown';
import { getMemberId } from '@trello/authentication';
import { getNonPublicIfAvailable } from '@trello/business-logic/member';
import { TrelloEmoji } from '@trello/emoji/trello';
import { client } from '@trello/graphql';
import { mentionToIdMap } from '@trello/mentions/cache';
import { dangerouslyConvertPrivacyString } from '@trello/privacy';
import { shouldOpenLinkInNewTab } from '@trello/urls';

import type { MentionFragment } from './MentionFragment.generated';
import { MentionFragmentDoc } from './MentionFragment.generated';

/*
Optional Block Rules:
  blockquote    # >>>
  def           # [1] https://trello.com
  code          #     code
  fencedCode    # ```
  heading       # ## Heading
  hr            # ----
  lheading      # Heading followed by -----
  list          # - foo
  newline       # (Just separates paragraphs)
  paragraph     #

Optional Inline Rules:
  atMention     # @someone
  autolink      # <google.com> or <foo@bar.com>
  break         # single newline
  code          # `code`
  em            # *em*
  emoji         # :facepalm:
  image         # ![something](http:///)
  link          # [something](http://...) or ![something](http:///)
  nolink        # [something] or ![something]
  strong        # **strong**
  reflink       # [something[1] or ![something][1]
  url           # https://trello.com
  colorChip     # #ABC123 or rgb(123, 12, 5) or rgba(120, 250, 3, 0.5)
*/

export interface ParserOptions {
  textInline: (text: string) => string;
  format: (
    text: string,
    options?: object,
  ) => {
    output: string;
    locations: [{ rule: string; start: number; stop: number }];
  };
  text: (
    text: string,
    options?: object,
  ) => {
    output: string;
    locations: [{ rule: string; start: number; stop: number }];
  };
  analyze: (
    text: string,
    options?: object,
  ) => [{ rule: string; start: number; stop: number }];
  options: {
    restrict?: {
      block?: string[];
      inline?: string[];
    };
  };
}

export interface TrelloMarkdownWrapper {
  bio: ParserOptions;
  checkItems: ParserOptions;
  comments: ParserOptions;
  description: ParserOptions;
  name: ParserOptions;
  powerUpDescription: ParserOptions;
}

interface EmojiState {
  textData?: {
    emoji: { [key: string]: string };
  };
}

export class TrelloMarkdownWrapper {
  constructor() {
    const defaults = {
      lookupEmoji: this.lookupEmoji.bind(this),
      lookupMember: this.lookupMember.bind(this),
      shouldOpenLinkInNewTab,
    };

    this.description = new TrelloMarkdown(defaults);

    this.comments = new TrelloMarkdown({
      restrict: {
        block: [
          'blockquote',
          'code',
          'fencedCode',
          'hr',
          'list',
          'newline',
          'paragraph',
          'heading',
          'lheading',
        ],
        inline: [
          'atMention',
          'autolink',
          'break',
          'code',
          'colorChip',
          'em',
          'email',
          'emailLink',
          'emoji',
          'image',
          'link',
          'strong',
          'strikethrough',
          'url',
        ],
      },
      ...defaults,
    });

    // Checklist Items
    this.checkItems = new TrelloMarkdown({
      inlineOnly: true,
      restrict: {
        inline: [
          'atMention',
          'autolink',
          'code',
          'colorChip',
          'em',
          'email',
          'emailLink',
          'emoji',
          'link',
          'strong',
          'strikethrough',
          'url',
        ],
      },
      ...defaults,
    });

    this.bio = new TrelloMarkdown({
      inlineOnly: true,
      restrict: {
        inline: [
          'atMention',
          'autolink',
          'colorChip',
          'em',
          'email',
          'emailLink',
          'emoji',
          'link',
          'strong',
          'strikethrough',
          'url',
        ],
      },
      ...defaults,
    });

    this.name = new TrelloMarkdown({
      inlineOnly: true,
      restrict: {
        inline: ['colorChip', 'url'],
      },
    });
  }

  lookupEmoji(
    emoji: string,
    state: EmojiState,
  ): string | undefined | Record<string, ReactElement<object, string> | null> {
    emoji = emoji.toLowerCase();
    //nameData is set for some users as an empty object,
    // which evaluates truthy and results in a TypeError
    const customEmoji = state.textData?.emoji?.[emoji];

    if (customEmoji) return customEmoji;
    return TrelloEmoji[emoji];
  }

  lookupMember(username: string): [string | null | undefined, boolean] {
    if (['card', 'board'].includes(username)) {
      return [username, true];
    }

    const mentionId = mentionToIdMap.get(username);
    const isMe = getMemberId() === mentionId;

    const member = client.readFragment<MentionFragment>({
      id: `Member:${mentionId}`,
      fragment: MentionFragmentDoc,
    });

    if (member) {
      return [
        dangerouslyConvertPrivacyString(
          getNonPublicIfAvailable(member, 'fullName'),
        ),
        isMe,
      ];
    } else if (/^[a-z0-9_]{3,}/.test(username)) {
      // Even if we couldn't find a member record, assume that it's still a
      // mention if it looks like a username.  It's possible, e.g. in a
      // notification, that we haven't loaded the member that's being mentioned
      return [username, false];
    } else {
      return [null, false];
    }
  }

  addLineNumbers(codeBlockParentHTMLNode: HTMLElement) {
    const preNodes = codeBlockParentHTMLNode.querySelectorAll('pre');
    const lineNumbersExist =
      // eslint-disable-next-line @trello/no-query-selector
      codeBlockParentHTMLNode.querySelector('.linenumbers');
    // Don't add line numbers if they already exist
    if (preNodes.length && !lineNumbersExist) {
      preNodes.forEach((preNode, index) => {
        // eslint-disable-next-line @trello/no-query-selector
        const code = preNode.querySelector('code');
        const linenumbers = document.createElement('span');
        linenumbers.className = 'linenumbers';
        const lines = code?.innerHTML.split('\n');
        lines?.forEach((line) => {
          const num = document.createElement('i');
          linenumbers.appendChild(num);
        });
        preNode.prepend(linenumbers);
      });
    }
  }
}
