// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import { l } from 'app/scripts/lib/localize';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function getPermLevelAltTextForBoard(board: any) {
  const level = board.getPermLevel();
  if (!level) {
    return '';
  }
  return l(['board perms', level, 'short summary']);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function getPermLevelAltTextForTemplate(board: any) {
  const level = board.getPermLevel();
  if (!level) {
    return '';
  }
  return l(['template perms', level, 'short summary']);
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function getPermLevelIconClass(level: any) {
  if (level === 'org') {
    return 'organization-visible';
  } else if (level === 'enterprise') {
    return 'enterprise';
  } else {
    return level;
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function getPermLevelIconClassForBoard(board: any) {
  return getPermLevelIconClass(board.getPermLevel());
}

export {
  getPermLevelAltTextForBoard,
  getPermLevelAltTextForTemplate,
  getPermLevelIconClass,
  getPermLevelIconClassForBoard,
};
