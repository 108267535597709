import { getApiGatewayUrl } from '@trello/api-gateway';
import { Analytics } from '@trello/atlassian-analytics';
import { clientVersion } from '@trello/config';
import { sendErrorEvent } from '@trello/error-reporting';

import {
  cacheAccessibleProducts,
  getCachedAccessibleProducts,
} from './getCachedAccessibleProducts';

type IDPAFPermissionIdType =
  | 'write'
  | 'manage'
  | 'external-collaborator-write'
  | 'add-products'
  | 'invite-users';

interface Workspace {
  workspaceAri: string;
  workspacePermissionIds: IDPAFPermissionIdType[];
  workspaceUrl: string;
  workspaceDisplayName: string;
  workspaceAvatarUrl: string;
  orgId: string;
}

interface Product {
  productId: string;
  productDisplayName: string;
  workspaces: Workspace[];
}

const productIds = [
  'confluence.ondemand',
  'jira-software.ondemand',
  'jira-servicedesk.ondemand',
  'jira-core.ondemand',
  'opsgenie',
  'trello',
  'bitbucket',
  'statuspage',
  'compass',
  'jira-product-discovery',
  'beacon',
  'atlassian-analytics-free',
  'mercury',
  'unified-help',
];

export interface AccessibleProductsResponse {
  products: Product[];
}

export const getAccessibleProductsUrl = () =>
  getApiGatewayUrl('/v2/accessible-products');

export const getAccessibleProducts =
  async (): Promise<AccessibleProductsResponse> => {
    try {
      const cachedAccessibleProducts = getCachedAccessibleProducts();
      if (cachedAccessibleProducts !== null) {
        return Promise.resolve({ products: cachedAccessibleProducts });
      }
      const url = getAccessibleProductsUrl();
      const response = await fetch(url, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'X-Trello-Client-Version': clientVersion,
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ productIds }),
      });
      if (response.ok) {
        Analytics.sendOperationalEvent({
          action: 'fetched',
          actionSubject: 'fetchAccessibleProducts',
          source: 'crossFlowEssentials',
        });
        const resolvedResponse = await response.json();
        cacheAccessibleProducts(resolvedResponse.data);
        return Promise.resolve(resolvedResponse.data);
      }
      throw new Error('Error fetching accessible products');
    } catch (error) {
      Analytics.sendOperationalEvent({
        action: 'errored',
        actionSubject: 'fetchAccessibleProducts',
        source: 'crossFlowEssentials',
      });
      sendErrorEvent(error, {
        tags: {
          ownershipArea: 'trello-cross-flow',
          feature: 'Atlassian Switcher',
        },
      });
      return { products: [] };
    }
  };
