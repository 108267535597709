/* eslint-disable
    eqeqeq,
*/
// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import { joinn } from '@trello/arrays';

import { l } from 'app/scripts/lib/localize';
import { teacupWithHelpers } from 'app/scripts/views/internal/teacupWithHelpers';
import { View } from 'app/scripts/views/internal/View';
const t = teacupWithHelpers('reactions');

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const formattedName = (name: any) =>
  t.render(() => t.span('.reaction-fullname', () => t.text(name)));
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const formattedReaction = (reaction: any) =>
  t.render(() => t.strong(() => t.text(reaction)));
// eslint-disable-next-line @trello/no-module-logic, @typescript-eslint/no-explicit-any
const template = t.renderable(function ({ names, reaction }: any) {
  const justYou = names?.[0] === l('You') && names.length === 1;
  names = names.map(formattedName);
  return t.div('.reaction-tooltip', function () {
    if (names !== '' && reaction == null) {
      return t.raw(joinn(names, ', ', ` ${t.l('and')} `));
    } else if (justYou) {
      return t.raw(
        t.l(
          'reaction-tooltip-you',
          {
            reaction: formattedReaction(reaction),
          },
          { raw: true },
        ),
      );
    } else if (names.length === 1) {
      return t.raw(
        t.l(
          'reaction-tooltip',
          {
            name: names[0],
            reaction: formattedReaction(reaction),
          },
          { raw: true },
        ),
      );
    } else if (names.length > 1) {
      return t.raw(
        t.l(
          'reaction-tooltip-plural',
          {
            names: joinn(names, ', ', ` ${t.l('and')} `),
            reaction: formattedReaction(reaction),
          },
          { raw: true },
        ),
      );
    } else {
      return t.raw(formattedReaction(reaction));
    }
  });
});

interface ReactionTooltip {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  options: any;
}

class ReactionTooltip extends View {
  renderOnce() {
    let { names } = this.options;
    const { reaction } = this.options;
    if (names == null) {
      names = [];
    }

    this.$el.append(template({ names, reaction }));
    return this;
  }
}

export { ReactionTooltip };
