/* eslint-disable
    eqeqeq,
*/
// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS206: Consider reworking classes to avoid initClass
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */

import { Analytics } from '@trello/atlassian-analytics';
// eslint-disable-next-line no-restricted-imports
import $ from '@trello/jquery';

import { Auth } from 'app/scripts/db/Auth';
import type { Board } from 'app/scripts/models/Board';
import { View } from 'app/scripts/views/internal/View';
import { PowerUpsCalendarPrefsTemplate } from 'app/scripts/views/templates/PowerUpsCalendarPrefsTemplate';
import { stopPropagationAndPreventDefault } from 'app/src/stopPropagationAndPreventDefault';

interface CalendarSettingsView {
  generating: boolean;
}

class CalendarSettingsView extends View<Board> {
  static initClass() {
    this.prototype.viewTitleKey = 'calendar settings';
  }

  events() {
    return {
      'click .js-calendar-pref-feed:not(.disabled)': 'selectFeedEnabled',
      'click .js-calendar-feed-url:not(:disabled)': 'focusInput',
      'click .js-regen-calendar-url:not(.disabled)': 'generateNewFeedURL',
    };
  }

  initialize() {
    this.generating = false;
    return this.listenTo(this.model, {
      'change:prefs': this.render,
      'change:myPrefs.calendarKey': this.generationComplete,
    });
  }

  generationComplete() {
    this.generating = false;
    return this.render();
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  generateNewFeedURL(e: any) {
    stopPropagationAndPreventDefault(e);
    this.generating = true;
    this.model.generateCalendarKey();
    return this.render();
  }

  render() {
    const { prefs } = this.model.toJSON({ prefs: true });
    const key = this.model.getPref('calendarKey');
    // eslint-disable-next-line @trello/enforce-variable-case
    const feedURL =
      `https://${location.host}/calendar/` +
      `${Auth.me().id}/${this.model.id}/${key}.ics`;

    if (!key) {
      this.generating = true;
      this.model.generateCalendarKey();
    }

    this.$el.html(
      PowerUpsCalendarPrefsTemplate({
        isEnabled: prefs.calendarFeedEnabled,
        isGenerated: key != null && !this.generating,
        url: feedURL,
        canChange: this.model.editable(),
      }),
    );

    return this;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  focusInput(e: any) {
    return $('.js-calendar-feed-url', this.$el).focus().select();
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  selectFeedEnabled(e: any) {
    stopPropagationAndPreventDefault(e);
    // eslint-disable-next-line @trello/enforce-variable-case
    const $target = $(e.target).closest('a');
    const setting = $target.attr('name') === 'enabled';
    const traceId = Analytics.startTask({
      taskName: 'edit-plugin/calendar',
      source: 'calendarSettingsInlineDialog',
    });
    const previous = this.model.getPref('calendarFeedEnabled');

    return this.model.setPrefWithTracing('calendarFeedEnabled', setting, {
      taskName: 'edit-plugin/calendar',
      source: 'calendarSettingsInlineDialog',
      traceId,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      next: (err: any) => {
        if (!err) {
          Analytics.sendUpdatedBoardFieldEvent({
            field: 'calendarFeedEnabledPref',
            source: 'calendarSettingsInlineDialog',
            containers: {
              board: {
                id: this.model.id,
              },
              organization: {
                id: this.model?.getOrganization()?.id,
              },
            },
            attributes: {
              taskId: traceId,
              change: previous !== setting,
              previous,
            },
          });
        }
      },
    });
  }
}

CalendarSettingsView.initClass();
export { CalendarSettingsView };
