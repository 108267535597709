// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import { teacupWithHelpers } from 'app/scripts/views/internal/teacupWithHelpers';
const t = teacupWithHelpers('board_header_permissions');

// eslint-disable-next-line @trello/no-module-logic
export const BoardHeaderPermissionsTemplate = t.renderable(function ({
  permIconClass,
  permText,
  showText,
}: // eslint-disable-next-line @typescript-eslint/no-explicit-any
any) {
  t.span({
    class: t.classify({
      'board-header-btn-icon': true,
      'icon-sm': true,
      [`icon-${permIconClass}`]: true,
    }),
  });
  return showText && t.span('.board-header-btn-text', () => t.text(permText));
});
