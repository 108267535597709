// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SX1stdQPUDyFxT {
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: 8px 8px;
}
.Qr1ziXMlQya7dy {
  display: flex;
  flex-direction: row;
  order: -1;
  gap: 8px;
}
.Qr1ziXMlQya7dy .HanhcI5BOAaEIg {
  border-radius: 3px;
  background-color: var(--ds-skeleton, #091e420f);
  animation-name: UCzOtt8NyMuWhv;
  animation-duration: 1.5s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  cursor: progress;
  width: 125px;
  height: 35px;
}
@keyframes UCzOtt8NyMuWhv {
  from {
    background-color: var(--ds-skeleton, #091e420f);
  }
  to {
    background-color: var(--ds-skeleton-subtle, #091e4208);
  }
}
@media (prefers-reduced-motion: reduce) {
  .Qr1ziXMlQya7dy .HanhcI5BOAaEIg {
    animation: none;
  }
}
.cQbNieHmQV9Y1h {
  border-radius: 3px;
  background-color: var(--ds-skeleton, #091e420f);
  animation-name: UCzOtt8NyMuWhv;
  animation-duration: 1.5s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  cursor: progress;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  margin-top: 8px;
}
@keyframes UCzOtt8NyMuWhv {
  from {
    background-color: var(--ds-skeleton, #091e420f);
  }
  to {
    background-color: var(--ds-skeleton-subtle, #091e4208);
  }
}
@media (prefers-reduced-motion: reduce) {
  .cQbNieHmQV9Y1h {
    animation: none;
  }
}
`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"skeleton": `SX1stdQPUDyFxT`,
	"skeletonHeader": `Qr1ziXMlQya7dy`,
	"skeletonColumn": `HanhcI5BOAaEIg`,
	"shimmer": `UCzOtt8NyMuWhv`,
	"skeletonBody": `cQbNieHmQV9Y1h`
};
export default ___CSS_LOADER_EXPORT___;
