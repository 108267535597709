// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import { teacupWithHelpers } from 'app/scripts/views/internal/teacupWithHelpers';
const t = teacupWithHelpers('popover_limit_exceeded');

// eslint-disable-next-line @trello/no-module-logic, @typescript-eslint/no-explicit-any
export const PopoverLimitExceededTemplate = t.renderable((limitType: any) =>
  t.div(
    '.error.js-limit-exceeded',
    { style: 'margin: 12px 0 6px;' },
    function () {
      if (limitType === 'perCard') {
        return t.format('attachments-per-card-exceeded');
      } else if (limitType === 'perBoard') {
        return t.format('attachments-per-board-exceeded');
      }
    },
  ),
);
