import { SharedState } from '@trello/shared-state';

interface NewCommentState {
  /**
   * The defaultValue is named to align with the Editor's API.
   * It represents the current value of the text within the Editor.
   */
  defaultValue: string;
  /**
   * The imperative value is meant to be used like a dispatch trigger, for
   * one-time updates to the comment's value. When making an external change to
   * the comment, like inserting a mention for a reply, we set this to a value.
   * The NewComment component should react to this value, apply it to its own
   * state, and reset the imperative value to null.
   */
  imperativeValue: string | null;
  isFocused: boolean;
}

export const initialState: NewCommentState = {
  defaultValue: '',
  imperativeValue: null,
  isFocused: false,
};

/**
 * The NewComment component can be interacted with from a non-React context.
 * For example, when clicking the Reply button under a comment, the NewComment
 * component will be focused, with its text value pre-filled with a member tag.
 * As a result, its UI state needs to be accessible outside of the component
 * itself.
 */
export const newCommentState = new SharedState<NewCommentState>(initialState);
