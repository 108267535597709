import type { FunctionComponent } from 'react';
import classNames from 'classnames';

import { PowerUpListingOverview } from 'app/src/components/PowerUpListingOverview';
import { Badges } from './Badges';
import type { DescriptionAtomProps } from './types';

// eslint-disable-next-line @trello/less-matches-component
import styles from './PowerUp.less';

export const DescriptionAtom: FunctionComponent<DescriptionAtomProps> = ({
  className,
  overview,
  icon,
  name,
  darkMode,
  staffPick,
  integration,
  usage,
  deprecation,
  ...props
}) => (
  <div className={classNames(styles.descriptionAtomGrid, className)}>
    <div className={styles.logoNameGrid}>
      <span
        className={styles.icon}
        style={{
          backgroundImage: `url(${icon.url})`,
        }}
      />
      <span className={styles.name} title={name}>
        {name}
      </span>
    </div>
    {props.button}
    <PowerUpListingOverview overview={overview} deprecation={deprecation} />
    <Badges
      // purposefully don't render dark mode badge here
      darkMode={false}
      usage={usage}
      staffPick={staffPick}
      integration={integration}
      hideInstallCues={Boolean(deprecation?.sunsetDate)}
    />
  </div>
);

export const StyledDescriptionAtom: FunctionComponent<DescriptionAtomProps> = ({
  className,
  ...props
}) => (
  <DescriptionAtom
    className={classNames(styles.descriptionAtom, className)}
    {...props}
  />
);
