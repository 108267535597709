import { useMemo } from 'react';

import { useCrossFlow } from '@atlassiansox/cross-flow-support';
import type { TouchpointSourceType } from '@trello/ad-sequencer';
import { isDesktop } from '@trello/browser';
import type { ExperimentVariations } from '@trello/feature-gate-client';
import {
  useWorkspaceNavigation,
  useWorkspaceNavigationHidden,
} from '@trello/workspace-navigation';

import {
  type TrelloCrossFlowExperiment,
  trelloCrossFlowOpen,
} from '../TrelloCrossFlow';
import { useHasEligibleWorkspaces } from './runtimeEligibilityChecks/useHasEligibleWorkspaces';
import { useIsCrossFlowMemberConfirmed } from './runtimeEligibilityChecks/useIsCrossFlowMemberConfirmed';
import { useIsNewUser } from './runtimeEligibilityChecks/useIsNewUser';
import { useTrelloCrossFlowExperiment } from './useTrelloCrossFlowExperiment';

const experimentFeatureKey =
  'trello_xf_cta_and_funnel_new_user_in_product_nudge';

export type CtaFunnelNewUserNudgesExperimentVariations = ExperimentVariations<
  typeof experimentFeatureKey,
  'cohort'
>;

export interface CrossFlowForCtaFunnelNewUserNudgesExperimentParams {
  source: TouchpointSourceType;
  workspaceId?: string;
  boardId?: string;
  hasValidAaSession: boolean;
}

export const useCrossFlowForCtaFunnelNewUserNudges = ({
  source,
  workspaceId,
  boardId,
  hasValidAaSession,
}: CrossFlowForCtaFunnelNewUserNudgesExperimentParams): TrelloCrossFlowExperiment<CtaFunnelNewUserNudgesExperimentVariations> => {
  const crossFlow = useCrossFlow();
  const isWeb = !isDesktop();
  const { isEligible: isNewUserEligible } = useIsNewUser();
  const { isEligible: hasEligibleWorkspaces, eligibleWorkspaceOptions } =
    useHasEligibleWorkspaces({
      workspaceId,
    });
  const [{ expanded: _workspaceNavigationExpanded }] = useWorkspaceNavigation();
  const [{ hidden: _workspaceNavigationHidden }] =
    useWorkspaceNavigationHidden();
  const workspaceNavigationExpanded =
    !_workspaceNavigationHidden && _workspaceNavigationExpanded;

  const { isEligible: isMemberConfirmed } = useIsCrossFlowMemberConfirmed();

  const experiment = useTrelloCrossFlowExperiment({
    experimentFeatureKey,
    source,
    eligibilityCheckResults: [
      crossFlow.isEnabled,
      isNewUserEligible,
      isWeb,
      isMemberConfirmed,
      hasEligibleWorkspaces,
      workspaceNavigationExpanded,
    ],
  });

  return useMemo(
    () => ({
      ...experiment,
      open: trelloCrossFlowOpen(
        crossFlow,
        source,
        eligibleWorkspaceOptions,
        hasValidAaSession,
        {
          workspaceId,
          boardId,
          isNewTrelloUser: true,
          sporkPositioning: 'customization',
          shouldSkipMarketingPage: true,
        },
      ),
    }),
    [
      boardId,
      hasValidAaSession,
      crossFlow,
      eligibleWorkspaceOptions,
      experiment,
      source,
      workspaceId,
    ],
  );
};
