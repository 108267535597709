// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import { teacupWithHelpers } from 'app/scripts/views/internal/teacupWithHelpers';
const t = teacupWithHelpers('power_ups_calendar_prefs');

// eslint-disable-next-line @trello/no-module-logic
export const PowerUpsCalendarPrefsTemplate = t.renderable(function ({
  isEnabled,
  isGenerated,
  url,
  canChange,
}: // eslint-disable-next-line @typescript-eslint/no-explicit-any
any) {
  t.p(() =>
    t.format('sync-this-trello-boards-calendar-with-your-personal-calendar'),
  );

  if (isEnabled) {
    t.strong(() => t.format('icalendar-feed'));

    t.div('.icalendar-url', function () {
      t.input('.full.js-calendar-feed-url', {
        type: 'text',
        value: isGenerated ? url : t.l('generating'),
      });
      return t.a(
        '.button.js-regen-calendar-url',
        { href: '#', class: t.classify({ disabled: !isGenerated }) },
        () => t.span('.icon-sm.icon-refresh.dark-hover'),
      );
    });

    t.div('.u-text-align-center', () =>
      t.a(
        '.js-select.js-calendar-pref-feed',
        {
          href: '#',
          class: t.classify({ disabled: !canChange }),
          name: 'disabled',
        },
        () => t.span('.name', () => t.format('disable-sync')),
      ),
    );

    t.hr();

    return t.p(
      { style: 'margin-bottom: 4px;' },
      '.u-quiet.text-align-center',
      () => t.format('sync-permission-warning'),
    );
  } else {
    return t.div('.u-text-align-center', () =>
      t.a(
        '.js-select.js-calendar-pref-feed',
        {
          href: '#',
          class: t.classify({ disabled: !canChange }),
          name: 'enabled',
        },
        () => t.span('.name', () => t.format('enable-sync')),
      ),
    );
  }
});
