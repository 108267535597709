/* eslint-disable
    eqeqeq,
*/
// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * DS104: Avoid inline assignments
 * DS206: Consider reworking classes to avoid initClass
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */

import Promise from 'bluebird';
// eslint-disable-next-line no-restricted-imports
import moment from 'moment';
import _ from 'underscore';
// eslint-disable-next-line no-restricted-imports
import parseURL from 'url-parse';

import { Analytics } from '@trello/atlassian-analytics';
import { isHighDPI } from '@trello/browser';
import { ComponentWrapper, renderReactRoot } from '@trello/component-wrapper';
import { siteDomain } from '@trello/config';
import { TrelloIntlProvider } from '@trello/i18n';
// eslint-disable-next-line no-restricted-imports
import $ from '@trello/jquery';
import {
  applyBidiTooltip,
  linksFromMarkdown,
  markdown,
} from '@trello/markdown';
import { MemberAvatar } from '@trello/member-avatar';
import { navigate } from '@trello/router/navigate';
import {
  getSmartCardAppearanceFromTitle,
  SmartEmbedPreviews,
} from '@trello/smart-card';
import { getMemberProfileUrl } from '@trello/urls';

import { getActionUrl } from 'app/scripts/controller/urls';
import { BUTLER_POWER_UP_ID } from 'app/scripts/data/butler-id';
import { Auth } from 'app/scripts/db/Auth';
import { KnownServices } from 'app/scripts/db/known-services';
import { ModelCache } from 'app/scripts/db/ModelCache';
import { Dates } from 'app/scripts/lib/dates';
import { EntityDisplay } from 'app/scripts/lib/entity-display';
import { l } from 'app/scripts/lib/localize';
import { isValidUrlUnfurl } from 'app/scripts/lib/plugins/pluginValidators';
import { hasSelection } from 'app/scripts/lib/util/selection/has-selection';
import { getDisplayWithMemberDetails } from 'app/scripts/models/internal/getDisplayWithMemberDetails';
import { Helpers as AttachmentHelpers } from 'app/scripts/views/attachment/Helpers';
import { AttachmentViewer } from 'app/scripts/views/internal/AttachmentViewer';
import {
  getPermLevelAltTextForBoard,
  getPermLevelIconClassForBoard,
} from 'app/scripts/views/internal/BoardDisplayHelpers';
import { pluginRunner } from 'app/scripts/views/internal/plugins/PluginRunner';
import { View } from 'app/scripts/views/internal/View';
import { PopOver } from 'app/scripts/views/lib/PopOver';
import { ReactionPiles } from 'app/scripts/views/reactions/ReactionPiles';
import { ActionTemplate } from 'app/scripts/views/templates/ActionTemplate';
import { CommentUnfurlPreviewTemplate } from 'app/scripts/views/templates/CommentUnfurlPreviewTemplate';
import {
  ActionAppCreator,
  type ActionAppCreatorProps,
} from 'app/src/components/Action/ActionAppCreator';
import { switchCurrentBoardView } from 'app/src/components/Board/switchCurrentBoardView';
import { LazyProfileCard } from 'app/src/components/ProfileCard';
import { friendlyLinks } from 'app/src/friendlyLinks';
import { friendlyLinksDisposer } from 'app/src/friendlyLinksDisposer';
import { stopPropagationAndPreventDefault } from 'app/src/stopPropagationAndPreventDefault';

const events = {
  'click .js-open-attachment-viewer': 'openAttachmentViewer',
  'click .js-open-card': 'openCard',
  'click .js-reply-to-action': 'reply',
  'click .js-reply-to-all-action': 'replyAll',
  'click .js-expand-comment': 'expandComment',
  'click .js-app-creator-link': 'onAppCreatorLinkClicked',
  'click a': 'checkAttachment',
};

interface ActionView {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  context: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  friendlyLinksUnmountCardPromises: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  friendlyLinksUnmountNonBoardPromises: any;
  highlighted: boolean;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  reactRootElem: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  reactRoot: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  source: any;
  unmountActionAppCreatorComponent: () => void;
  unmountAvatarComponent: () => void;
  unmountEmbedPreviewsComponent: () => void;
}

class ActionView extends View {
  static initClass() {
    // @ts-expect-error TS(2322): Type 'string' is not assignable to type '() => str... Remove this comment to see the full error message
    this.prototype.className = 'phenom';
    // @ts-expect-error TS(2322): Type '{ 'click .js-open-attachment-viewer': string... Remove this comment to see the full error message
    this.prototype.events = events;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  initialize({ context, highlighted, source }: any) {
    let checklist;
    this.context = context;
    this.highlighted = highlighted;
    this.source = source;
    this.listenTo(this.model, 'takingTooLong change', this.render);
    const data = this.model.get('data');
    if ((data.card != null ? data.card.id : undefined) != null && data.board) {
      let card;
      this.listenTo(
        this.modelCache,
        `remove:Action:${this.model.get('id')}`,
        () => {
          return this.remove();
        },
      );

      if (
        (card = this.model.getCard()) != null &&
        card.id !== this.context.id
      ) {
        this.listenTo(card, 'change:name', this.render);
      }
    }

    if ((checklist = this.model.getChecklist()) != null) {
      this.listenTo(checklist, 'change:name', this.render);
    }

    this.listenTo(
      this.model.reactionList,
      'add remove reset',
      this.renderReactions,
    );
  }

  getData() {
    const entities = new EntityDisplay('action').getEntities(
      getDisplayWithMemberDetails({
        display: this.model.get('display'),
        modelCache: this.modelCache,
      }),
      this.context.id,
    );

    return {
      saved: this.model.id != null,
      entities: _.map(entities, this._withEntityInfo.bind(this)),
      date: this.model.get('date'),
      dateLastEdited: this.model.get('data').dateLastEdited,
      textData: this.model.get('data').textData,
      url: this.model.getCard() != null ? getActionUrl(this.model) : null,
      appCreator: this.model.getAppCreator(),
    };
  }

  getTrackingContext() {
    const { card, list, board } = this.model.get('data');

    const category =
      this.context.typeName === 'Card' ? 'card detail' : 'board sidebar';

    return {
      actionId: this.model.id,
      cardId: card != null ? card.id : undefined,
      listId: list != null ? list.id : undefined,
      boardId: board != null ? board.id : undefined,
      orgId: this.model.getBoard()?.getOrganization()?.id,
      category,
    };
  }

  getContext() {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const context: any = {};
    const me = Auth.me();
    const isOnCardDetailView = this.context.typeName === 'Card';

    const board = ModelCache.get('Board', this.model.get('data')?.board?.id);
    if (this.options.showBoard) {
      if (board != null) {
        context.board = board.toJSON({ prefs: true, url: true });
        context.boardPLevelIconClass = getPermLevelIconClassForBoard(board);
        context.boardPLevelAltText = getPermLevelAltTextForBoard(board);
      }
    }

    if (this.options.showOrganization) {
      const organization = ModelCache.get(
        'Organization',
        this.model.get('data')?.organization?.id,
      );
      if (organization != null) {
        context.organization = organization.toJSON({
          prefs: true,
          url: true,
        });
        context.orgPLevelIconClass = organization.getPermLevel();
        context.orgPLevelAltText = l([
          'org perms',
          organization.getPermLevel(),
          'text',
        ]);
      }
    }

    const memberCreator = ModelCache.get(
      'Member',
      this.model.get('idMemberCreator'),
    );

    const currentBoard = this.model.getBoard();
    context.isPublicUser = currentBoard?.isMemberPublic(Auth.me());
    context.canReply =
      (board != null ? board.canComment(me) : undefined) &&
      (this.model.isAddAttachment() ||
        (this.model.isCommentLike() &&
          memberCreator != null &&
          !Auth.isMe(memberCreator)));
    context.canReplyAll =
      context.canReply &&
      new RegExp(/@([a-z0-9_]+)/).test(this.model.get('data').text);
    context.canDelete = this.model.deletable();
    context.canEdit = this.model.editable() && isOnCardDetailView;
    context.member = memberCreator != null ? memberCreator.toJSON() : undefined;
    if (context.member != null) {
      context.member.url = getMemberProfileUrl(context.member.id);
    }
    if (context.member != null) {
      context.member.isDeactivated =
        board != null ? board.isDeactivated(context.member) : undefined;
    }
    context.showReactions =
      isOnCardDetailView && this.model.get('type') === 'commentCard';
    context.canReact = this.model.canReact();
    context.showInlineReactionButton =
      context.showReactions && !this.model.reactionList.length;
    context.canLinkAppCreator = this.source !== 'memberActivityScreen';
    context.isOnCardDetailView = isOnCardDetailView;

    return context;
  }

  getOptions() {
    let left;
    return {
      showCompactAttachmentPreview: this.options.compact,
      truncateComment:
        (left =
          typeof this.options.truncateComments === 'function'
            ? this.options.truncateComments(this.model)
            : undefined) != null
          ? left
          : false,
      showOptions:
        !this.model.isPlaceholder() &&
        !this.options.compact &&
        !this.options.readOnly,
      useEmbedPreviews: !this.options.compact && this.model.isCommentLike(),
      usePreviews: !this.options.compact && this.model.isAddAttachment(),
      useCommentUnfurl:
        this.options.compact != null &&
        !this.options.compact &&
        this.model.isCommentLike(),
      extremeTruncation: this.options.extremeTruncation,
    };
  }

  render() {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let board: any;
    const data = this.getData();
    const options = this.getOptions();
    const context = this.getContext();
    const source = this.source;
    const isComment = this.model.isCommentLike();

    const noEntityLength = !data.entities.length;

    this.$el.addClass(
      noEntityLength
        ? 'hide'
        : isComment
        ? 'mod-comment-type'
        : 'mod-attachment-type',
    );

    this.$el.html(ActionTemplate(data, context, options));
    applyBidiTooltip(this.el);

    const containers = {
      ...(this.model?.getCard?.()?.getAnalyticsContainers?.() ?? {}),
      ...(this.model?.getBoard?.()?.getAnalyticsContainers?.() ?? {}),
    };

    const analyticsContext = {
      source,
      containers,
      attributes: {
        fromSection: isComment ? 'comment' : 'attachment',
      },
    };

    // Some actions wouldn't have a board associated with them
    if ((board = this.model.getBoard()) != null) {
      this.friendlyLinksUnmountNonBoardPromises = $(
        '.js-friendly-links',
        this.$el,
      )
        .toArray()
        .map(function (el) {
          return friendlyLinks(el, board, {
            analyticsContext,
            renderInline: !context.isOnCardDetailView,
          });
        });

      this.friendlyLinksUnmountCardPromises = $(
        '.js-friendly-links-for-link-card',
        this.$el,
      )
        .toArray()
        .map(function (el) {
          return friendlyLinks(el, board, {
            analyticsContext,
            renderInline: !context.isOnCardDetailView,
          });
        });

      if (options.useCommentUnfurl) {
        $('.js-friendly-links', this.$el).each(() =>
          // @ts-expect-error TS(2322): Type 'Bluebird<JQuery<HTMLElement> | undefined>' i... Remove this comment to see the full error message
          this.renderCommentPreviews(this, board),
        );
      }
    }

    if (options.useEmbedPreviews) {
      const embedElem = $('.js-embed-previews', this.$el)[0];
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const embedUrls: any = [];

      const LOOM_REGEX = /^https:\/\/(?:www\.)?loom\.com\/share\/[\w\d]+/i;
      const YOUTUBE_REGEX =
        /^https:\/\/(?:www.)?(youtube\.com\/watch|youtu\.be\/[\d\w]).+/i;

      for (const entity of Array.from(data.entities)) {
        if (entity.type === 'comment') {
          const links = linksFromMarkdown(entity.text, markdown.comments);

          links.forEach(({ url, title }) => {
            const appearance = getSmartCardAppearanceFromTitle(title);
            if (embedUrls.includes(url)) {
              return;
            }
            if (appearance === 'block' || appearance === 'embed') {
              return;
            }
            if (LOOM_REGEX.test(url) || YOUTUBE_REGEX.test(url)) {
              embedUrls.push(url);
            }
          });
        }
      }

      if (embedUrls.length) {
        const { unmount } = renderReactRoot(
          // @ts-expect-error TS(2741): Property 'handleTrelloLinkClick' is missing in typ... Remove this comment to see the full error message
          <SmartEmbedPreviews
            urls={embedUrls}
            analyticsContext={analyticsContext}
          />,
          embedElem,
        );
        this.unmountEmbedPreviewsComponent = unmount;
      }
    }

    if (options.usePreviews) {
      const attachments = _.filter(
        data.entities,
        (entity) => entity.type === 'attachment',
      );

      if (!_.isEmpty(attachments)) {
        const $previews = $('.js-previews', this.$el);
        KnownServices.previewHtml(
          attachments[0].url,
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          function (err: any, preview: any) {
            $previews.empty();
            if (err != null) {
              if (err.login != null) {
                $('<div>')
                  .addClass('attachment-extra-info-login')
                  .html(err.login)
                  .appendTo($previews);

                return $previews.removeClass('hide');
              }
            } else if (preview != null) {
              $('<div>')
                .addClass('attachment-extra-info')
                .html(preview)
                .appendTo($previews);

              return $previews.removeClass('hide');
            }
          },
        );
      }
    }

    Dates.update(this.el);

    this.$el.toggleClass('unsent', !!this.model.isTakingTooLong);
    this.renderActionAppCreator({
      appCreator: data.appCreator,
      canLinkAppCreator: context.canLinkAppCreator,
    });
    this.renderAvatarComponent(context.member);
    this.renderHighlighted();
    this.renderReactions();

    return this;
  }

  renderActionAppCreator(props: ActionAppCreatorProps) {
    const actionAppCreatorReactRoot = $(
      '.js-action-app-footer-react-root',
      this.$el,
    )[0];
    if (!actionAppCreatorReactRoot) {
      return;
    }
    const { unmount } = renderReactRoot(
      // Can't use ComponentWrapper because it wraps everything in a div
      // and we need this to be an inline span
      <TrelloIntlProvider>
        <ActionAppCreator {...props} />
      </TrelloIntlProvider>,
      actionAppCreatorReactRoot,
    );
    this.unmountActionAppCreatorComponent = unmount;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  renderAvatarComponent(member: any) {
    const memberAvatarRoot = $('.js-member-avatar-root', this.$el)[0];
    if (!memberAvatarRoot || !member?.id) {
      return;
    }

    const { unmount } = renderReactRoot(
      <ComponentWrapper>
        <MemberAvatar
          idMember={member.id}
          size={32}
          deactivated={member.isDeactivated || member.activityBlocked}
          onClick={this.viewMemberActionMenu.bind(this)}
          testId="action-view-member-avatar"
        />
      </ComponentWrapper>,
      memberAvatarRoot,
    );
    this.unmountAvatarComponent = unmount;
  }

  renderHighlighted() {
    this.$el.toggleClass('mod-highlighted', !!this.highlighted);
    return this;
  }

  renderReactions() {
    const context = this.getContext();

    if ((this.reactRootElem = this.el.querySelector('.js-reaction-piles'))) {
      this.reactRoot = renderReactRoot(
        <ReactionPiles
          // @ts-expect-error
          actionId={this.model.id}
          reactionList={this.model.reactionList}
          canReact={context.canReact}
          trackingContext={this.getTrackingContext()}
        />,
        this.reactRootElem,
      );
      return this.reactRoot;
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  renderCommentPreviews(els: any, board: any) {
    const urls = _.flatten(
      $('.js-friendly-links', this.$el)
        .toArray()
        .map(function (el) {
          // @ts-expect-error TS(2367): This condition will always return 'false' since th... Remove this comment to see the full error message
          if ((el.tagName != null) === 'A') {
            return [el];
          } else {
            return _.toArray(el.querySelectorAll('a'));
          }
        }),
    )
      .map((a) => a.href)
      .filter((url) => url != null);

    return Promise.map(urls, (url) =>
      Promise.try(() => {
        const formatRequest = pluginRunner.one({
          command: 'format-url',
          board,
          options: {
            url,
          },
        });
        return formatRequest;
      })
        .then(function (response) {
          if (response != null) {
            return { ...response, url };
          } else {
            return null;
          }
        })
        // @ts-expect-error TS(2339): Property 'Error' does not exist on type 'PluginRun... Remove this comment to see the full error message
        .catch(pluginRunner.Error.NotHandled, () => null),
    ).then((formattedUrls) => {
      if (formattedUrls != null && formattedUrls.length > 0) {
        const subviews = formattedUrls.filter(isValidUrlUnfurl).map((data) => {
          // @ts-expect-error TS(2339): Property 'actions' does not exist on type '{ url: any; }'
          if ((data != null ? data.actions : undefined)?.length > 2) {
            // @ts-expect-error TS(18047): 'data' is possibly 'null'.
            data.actions = data.actions.slice(0, 2);
          }
          return this.subview(
            CommentUnfurlPreviewTemplate,
            this.model,
            { commentUnfurl: data },
            // @ts-expect-error TS(18047): 'data' is possibly 'null'.
            data.url,
          );
        });

        const $commentPreview = $('.comment-preview', this.$el);
        this.ensureSubviews(subviews, $commentPreview);
        return $commentPreview.show();
      }
    });
  }

  remove() {
    friendlyLinksDisposer(this.friendlyLinksUnmountNonBoardPromises);
    friendlyLinksDisposer(this.friendlyLinksUnmountCardPromises);

    this.unmountActionAppCreatorComponent?.();
    this.unmountAvatarComponent?.();
    this.unmountEmbedPreviewsComponent?.();
    if (this.reactRoot) {
      this.reactRoot.unmount();
    }
    return super.remove(...arguments);
  }

  // TrelloFlavoredMarkdown opts for this action entity
  _markdownFormatOpts() {
    ({
      card: this.model.get('data').card,
      board: this.model.get('data').board,
    });
    siteDomain;
    return { textData: this.model.get('data').textData };
  }

  // Add additional info to an entity for rendering
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  _withEntityInfo(entityIn: any) {
    let card, checklist;
    const entity = _.clone(entityIn);

    if (entity.type === 'attachment') {
      entity.url = this.model.get('data').attachment?.url;

      // Handle friendly deleted link
      if (!entity.url && entity.isFriendly) {
        entity.url = entity.text;
      }
    }

    if (entity.type === 'attachmentPreview') {
      // Set preview URL to use for the browser's DPI
      // The entity has preview URLs already, but those don't always do the right thing with EXIF rotation
      let attachment, left, left1;
      entity.previewUrlForRes =
        (card = ModelCache.get('Card', this.model.get('data').card?.id)) !=
          null &&
        (attachment = card.attachmentList.get(
          this.model.get('data').attachment?.id,
        ))
          ? isHighDPI()
            ? // @ts-expect-error TS(2554): Expected 2 arguments, but got 1.
              ((left = attachment.smallestPreviewBiggerThan(1000)) != null
                ? left
                : attachment.biggestPreview()
              )?.url
            : (left1 =
                // @ts-expect-error TS(2554): Expected 2 arguments, but got 1.
                attachment.smallestPreviewBiggerThan(500) != null
                  ? left1
                  : attachment.biggestPreview())?.url
          : undefined;

      if (entity.previewUrlForRes == null) {
        entity.previewUrlForRes =
          isHighDPI() && entity.previewUrl2x != null
            ? entity.previewUrl2x
            : entity.previewUrl;
      }
    }

    if (entity.type === 'comment') {
      const wrapper = document.createElement('div');
      wrapper.innerHTML = markdown.comments.format(
        entity.text,
        this._markdownFormatOpts(),
      ).output;
      markdown.addLineNumbers(wrapper);
      entity.textHtml = wrapper.innerHTML;
    }

    if (entity.type === 'checkItem') {
      entity.nameHtml = markdown.checkItems.format(
        entity.text,
        this._markdownFormatOpts(),
      ).output;
    }

    if (entity.type === 'date') {
      entity.text = moment(entity.date).calendar();
    }

    // Use the most recent name for the card, if available
    if (
      entity.type === 'card' &&
      (card = ModelCache.get('Card', entity.id)) != null
    ) {
      if (card.get('cardRole') === 'link') {
        entity.nameHtml = markdown.name.format(
          card.get('name'),
          this._markdownFormatOpts(),
        ).output;
      } else {
        entity.text = card.get('name');
      }
    }

    if (
      entity.type === 'checklist' &&
      (checklist = ModelCache.get('Checklist', entity.id)) != null
    ) {
      entity.text = checklist.get('name');
    }

    return entity;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  viewMemberActionMenu(e: any) {
    let left;
    stopPropagationAndPreventDefault(e);

    const member = ModelCache.get(
      'Member',
      (left = $(e.target).closest('[idmember]').attr('idmember')) != null
        ? left
        : this.model.get('idMemberCreator'),
    );

    PopOver.toggle({
      elem: $(e.target as HTMLElement).closest('.js-show-mem-menu'),
      reactElement: (
        <ComponentWrapper>
          <LazyProfileCard
            key="profile"
            onClose={() => PopOver.hide()}
            // @ts-expect-error
            memberId={member.id}
          />
        </ComponentWrapper>
      ),
      hideHeader: true,
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  expandComment(e: any) {
    stopPropagationAndPreventDefault(e);
    return $('.js-comment', this.$el).removeClass('is-truncated');
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  openCard(e: any) {
    let url;
    if (this.context.typeName === 'Card' || $(e.target).is('a,textarea')) {
      // Let the browser do the normal thing, e.g. open the link or edit
      return;
    }

    // If they've just selected some text, they aren't trying to open the card
    if (hasSelection()) {
      return;
    }

    stopPropagationAndPreventDefault(e);
    // Navigate to the action URL
    if ((url = getActionUrl(this.model)) != null) {
      navigate(url, { trigger: true });
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  openAttachmentViewer(e: any) {
    if (e.metaKey || e.ctrlKey) {
      return;
    }

    const $target = $(e.target);
    if ($target.closest('.window-main-col').length > 0) {
      stopPropagationAndPreventDefault(e);
      if (!AttachmentViewer.isActive()) {
        const idAttachment = $target
          .closest('.js-open-attachment-viewer')
          .attr('data-idattachment');
        AttachmentViewer.show({
          model: this.model.getCard(),
          idAttachment,
        });
      }
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  reply(e: any) {
    stopPropagationAndPreventDefault(e);
    this.$el.trigger('replyToAction', this.model);
    const { actionId, cardId, listId, boardId, orgId, category } =
      this.getTrackingContext();
    return Analytics.sendClickedButtonEvent({
      buttonName: 'replyButton',
      source: this.source,
      containers: {
        card: {
          id: cardId,
        },
        board: {
          id: boardId,
        },
        list: {
          id: listId,
        },
        organization: {
          id: orgId,
        },
      },
      attributes: {
        actionId,
        actionType: this.model.get('type'),
        category,
        replyType: 'reply to comment',
      },
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  replyAll(e: any) {
    stopPropagationAndPreventDefault(e);
    this.$el.trigger('replyToAllAction', this.model);
    const { actionId, cardId, listId, boardId, orgId, category } =
      this.getTrackingContext();
    return Analytics.sendClickedButtonEvent({
      buttonName: 'replyButton',
      source: this.source,
      containers: {
        card: {
          id: cardId,
        },
        board: {
          id: boardId,
        },
        list: {
          id: listId,
        },
        organization: {
          id: orgId,
        },
      },
      attributes: {
        actionId,
        actionType: this.model.get('type'),
        category,
        replyType: 'reply to all comment',
      },
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setHighlighted(highlighted: any) {
    if (highlighted !== this.highlighted) {
      this.highlighted = highlighted;
      this.renderHighlighted();
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  checkAttachment(e: any) {
    if (e.metaKey || e.ctrlKey) {
      return;
    }

    const card = this.model.getCard();
    const { href } = e.currentTarget;
    const hrefPath = parseURL(href).pathname;
    const attachment = card?.attachmentList?.find(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (att: any) => parseURL(att.get('url')).pathname === hrefPath,
    );
    if (attachment && AttachmentHelpers.isViewerable(attachment.get('url'))) {
      e.preventDefault();

      return AttachmentViewer.show({
        model: card,
        idAttachment: attachment.id,
      });
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onAppCreatorLinkClicked(e: any) {
    stopPropagationAndPreventDefault(e);
    const board = this.model.getBoard();
    if (!board) {
      return;
    }
    const appCreator = this.model.getAppCreator();

    if (appCreator?.idPlugin) {
      const idMe = Auth.myId();
      if (
        (board.get('memberships') || []).some(
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          ({ idMember }: any) => idMember === idMe,
        )
      ) {
        if (appCreator.idPlugin === BUTLER_POWER_UP_ID) {
          switchCurrentBoardView({
            routeParams: {
              view: 'butler',
              tab: 'schedule',
              screen: 'usage',
            },
            navigateOptions: {
              trigger: true,
            },
          });
        } else {
          // Member is a board member, so can run a plugin
          pluginRunner.one({
            plugin: appCreator.idPlugin,
            command: 'show-settings',
            board,
            el: e.currentTarget,
          });
        }
      } else {
        // Member is not a board member, take them to the plugin listing
        window.open('/power-ups/' + appCreator.idPlugin);
      }
    }

    const { actionId, cardId, listId, boardId, orgId } =
      this.getTrackingContext();

    Analytics.sendClickedLinkEvent({
      linkName: 'appCreatorLink',
      source: this.source,
      attributes: {
        appCreatorId: appCreator?.id,
        appCreatorIdPlugin: appCreator?.idPlugin,
        actionType: this.model.get('type'),
        actionId,
      },
      containers: {
        card: {
          id: cardId,
        },
        board: {
          id: boardId,
        },
        list: {
          id: listId,
        },
        organization: {
          id: orgId,
        },
      },
    });
  }
}

ActionView.initClass();

export { ActionView, events };
