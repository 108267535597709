/* eslint-disable
    eqeqeq,
*/
// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * DS201: Simplify complex destructure assignments
 * DS205: Consider reworking code to avoid use of IIFEs
 * DS206: Consider reworking classes to avoid initClass
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */

import BluebirdPromise from 'bluebird';
import _ from 'underscore';

import { Analytics } from '@trello/atlassian-analytics';
// eslint-disable-next-line no-restricted-imports
import $ from '@trello/jquery';

import { ModelCache } from 'app/scripts/db/ModelCache';
import { Dates } from 'app/scripts/lib/dates';
import { Util } from 'app/scripts/lib/util';
import { teacupWithHelpers } from 'app/scripts/views/internal/teacupWithHelpers';
import { View } from 'app/scripts/views/internal/View';
import { Confirm } from 'app/scripts/views/lib/Confirm';
import { stopPropagationAndPreventDefault } from 'app/src/stopPropagationAndPreventDefault';
const t = teacupWithHelpers('board_clean_up');

// eslint-disable-next-line @typescript-eslint/no-explicit-any, @trello/no-module-logic
const template = t.renderable(function ({ ageCategories, lists }: any) {
  const canCleanLists = !_.isEmpty(lists);
  const canCleanOldCards = !_.isEmpty(ageCategories);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const cardsSummary = function (cards: any, canSelect: any) {
    if (canSelect) {
      t.div('.board-clean-up-cards-options', function () {
        t.span('.board-clean-up-cards-option.js-select-all', () =>
          t.format('select-all'),
        );
        return t.span('.board-clean-up-cards-option.js-select-none', () =>
          t.format('select-none'),
        );
      });
    }

    return t.div('.board-clean-up-cards', () =>
      Array.from(cards).map((card) =>
        t.div('.board-clean-up-card', function () {
          if (canSelect) {
            t.div(() =>
              t.span(
                '.board-clean-up-checkbox.js-toggle-checkbox',
                {
                  'data-checked': 'checked',
                  // @ts-expect-error TS(2571): Object is of type 'unknown'.
                  'data-card': card.id,
                },
                () =>
                  t.icon('check', { class: 'board-clean-up-checkbox-check' }),
              ),
            );
          }
          // @ts-expect-error TS(2571): Object is of type 'unknown'.
          return t.a('.list-card', { href: card.get('url') }, () =>
            // @ts-expect-error TS(2571): Object is of type 'unknown'.
            t.span('.board-clean-up-card-name', () => t.text(card.get('name'))),
          );
        }),
      ),
    );
  };

  t.div('.window-header.u-clearfix', function () {
    t.span('.window-header-icon.icon-lg.icon-board');

    return t.div('.window-title', () =>
      t.h2('.window-title-text', () => t.format('board-clean-up')),
    );
  });

  return t.div('.window-main-col.window-main-col-full', function () {
    if (canCleanLists) {
      t.p('.board-clean-up-explanation', () =>
        t.format(
          'it-looks-like-you-have-some-lists-that-havent-been-updated-recently',
        ),
      );

      t.table('.board-clean-up-table', () =>
        t.tbody(function () {
          t.tr(function () {
            t.th(() => t.format('list'));
            t.th(() => t.format('cards'));
            t.th(() => t.format('last-updated'));
            return t.th(function () {});
          });

          return (() => {
            const result = [];
            for (const {
              // @ts-expect-error TS(2339): Property 'id' does not exist on type 'unknown'.
              id,
              // @ts-expect-error TS(2339): Property 'listName' does not exist on type 'unknow... Remove this comment to see the full error message
              listName,
              // @ts-expect-error TS(2339): Property 'cards' does not exist on type 'unknown'.
              cards,
              // @ts-expect-error TS(2339): Property 'cardCount' does not exist on type 'unkno... Remove this comment to see the full error message
              cardCount,
              // @ts-expect-error TS(2339): Property 'lastModified' does not exist on type 'un... Remove this comment to see the full error message
              lastModified,
            } of Array.from(lists)) {
              t.tr('.board-clean-up-top', function () {
                t.td(() => t.text(listName));
                t.td(() => t.text(cardCount));
                t.td(() => t.text(Dates.toDateString(lastModified)));
                return t.td('.board-clean-up-button-cell', () =>
                  t.button(
                    '.board-clean-up-button.nch-button.js-archive-list',
                    { 'data-list': id },
                    () => t.format('archive-list'),
                  ),
                );
              });
              result.push(
                t.tr('.board-clean-up-bottom', () =>
                  t.td({ colspan: 4 }, () => cardsSummary(cards, false)),
                ),
              );
            }
            return result;
          })();
        }),
      );
    }

    if (canCleanLists && canCleanOldCards) {
      t.hr();
    }

    if (canCleanOldCards) {
      t.p('.board-clean-up-explanation', function () {
        if (canCleanLists) {
          return t.format(
            'you-can-also-clean-things-up-by-archiving-cards-that-havent-been-updated-in-a-while',
          );
        } else {
          return t.format(
            'you-can-clean-things-up-by-archiving-cards-that-havent-been-updated-in-a-while',
          );
        }
      });

      t.table('.board-clean-up-table', () =>
        t.tbody(function () {
          t.tr(function () {
            t.th(() => t.format('cards-updated'));
            t.th(() => t.format('cards'));
            return t.th(function () {});
          });

          return (() => {
            const result = [];
            for (const key in ageCategories) {
              const cards = ageCategories[key];
              t.tr(
                '.board-clean-up-top.js-category-heading',
                { 'data-category': key },
                function () {
                  t.td(() => t.format(['more-than', key]));
                  t.td('.js-card-count', () => t.text(cards.length));
                  return t.td('.board-clean-up-button-cell', () =>
                    t.button(
                      '.board-clean-up-button.nch-button.js-archive-category',
                      { 'data-category': key },
                      () => t.format('archive-selected-cards'),
                    ),
                  );
                },
              );
              result.push(
                t.tr(
                  '.board-clean-up-bottom.js-category-cards',
                  { 'data-category': key },
                  () => t.td({ colspan: 3 }, () => cardsSummary(cards, true)),
                ),
              );
            }
            return result;
          })();
        }),
      );
    }

    if (!(canCleanLists || canCleanOldCards)) {
      return t.p('.board-clean-up-explanation', () =>
        t.format('it-doesnt-look-like-there-are-any-old-cards-to-clean-up'),
      );
    }
  });
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any, @trello/no-module-logic
const progressTemplate = t.renderable(function ({ count, total }: any) {
  t.div('.window-header.u-clearfix', function () {
    t.span('.window-header-icon.icon-lg.icon-board');

    return t.div('.window-title', () =>
      t.h2('.window-title-text', () => t.format('board-clean-up')),
    );
  });

  return t.div('.window-main-col.window-main-col-full', () =>
    t.div('.board-clean-up-progress', () =>
      t.format('archiving', { count, total }),
    ),
  );
});

const CATEGORIES = {
  threeMonths: {
    // eslint-disable-next-line @trello/no-module-logic
    age: Util.getMs({ days: 90 }),
  },
  month: {
    // eslint-disable-next-line @trello/no-module-logic
    age: Util.getMs({ days: 30 }),
  },
  week: {
    // eslint-disable-next-line @trello/no-module-logic
    age: Util.getMs({ days: 7 }),
  },
};

// Minimum amount of time to spend per close, to keep from
// hitting rate limits
// With these settings we'd close 300 cards per minute
const MINIMUM_TIME = 1000;
const CONCURRENCY = 5;

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const filterByAttr = (attr: any, value: any) => (index: any, el: any) =>
  el.getAttribute(attr) === value;

interface BoardCleanUpView {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  aborted: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  model: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  progress: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  renderDebounced: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  traceId: any;
}

class BoardCleanUpView extends View {
  static initClass() {
    this.prototype.events = {
      // @ts-expect-error TS(2322): Type '{ 'click .js-archive-category': string; 'cli... Remove this comment to see the full error message
      'click .js-archive-category': 'archiveCategory',
      'click .js-archive-list': 'archiveList',
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      'click .js-toggle-checkbox'(e: any) {
        // @ts-expect-error TS(2339): Property '$el' does not exist on type '() => { [ke... Remove this comment to see the full error message
        // eslint-disable-next-line @trello/enforce-variable-case
        const $checkbox = $(e.currentTarget, this.$el);
        // @ts-expect-error TS(2339): Property 'is' does not exist on type 'JQueryStatic... Remove this comment to see the full error message
        if ($checkbox.is('[data-checked]')) {
          // @ts-expect-error TS(2551): Property 'removeAttr' does not exist on type 'JQue... Remove this comment to see the full error message
          $checkbox.removeAttr('data-checked');
        } else {
          // @ts-expect-error TS(2339): Property 'attr' does not exist on type 'JQueryStat... Remove this comment to see the full error message
          $checkbox.attr('data-checked', 'checked');
        }

        // @ts-expect-error TS(2339): Property '_updateCategory' does not exist on type ... Remove this comment to see the full error message
        return this._updateCategory(
          // @ts-expect-error TS(2339): Property 'closest' does not exist on type 'JQueryS... Remove this comment to see the full error message
          $checkbox.closest('.js-category-cards').attr('data-category'),
        );
      },

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      'click .js-select-all'(e: any) {
        // @ts-expect-error TS(2339): Property '$el' does not exist on type '() => { [ke... Remove this comment to see the full error message
        // eslint-disable-next-line @trello/enforce-variable-case
        const $container = $(e.currentTarget, this.$el).closest(
          '.js-category-cards',
        );
        $container.find('.js-toggle-checkbox').attr('data-checked', 'checked');
        // @ts-expect-error TS(2339): Property '_updateCategory' does not exist on type ... Remove this comment to see the full error message
        return this._updateCategory($container.attr('data-category'));
      },

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      'click .js-select-none'(e: any) {
        // @ts-expect-error TS(2339): Property '$el' does not exist on type '() => { [ke... Remove this comment to see the full error message
        // eslint-disable-next-line @trello/enforce-variable-case
        const $container = $(e.currentTarget, this.$el).closest(
          '.js-category-cards',
        );
        $container.find('.js-toggle-checkbox').removeAttr('data-checked');
        // @ts-expect-error TS(2339): Property '_updateCategory' does not exist on type ... Remove this comment to see the full error message
        return this._updateCategory($container.attr('data-category'));
      },
    };
  }

  initialize() {
    this.progress = null;
    this.aborted = false;
    this.traceId = null;

    this.makeDebouncedMethods('render');

    this.listenTo(
      this.model.listList,
      'add remove reset change:closed change:idBoard',
      this.renderDebounced,
    );
    this.listenForCards();
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  _updateCategory(category: any) {
    const matchesCategory = filterByAttr('data-category', category);
    // eslint-disable-next-line @trello/enforce-variable-case
    const $heading = $('.js-category-heading', this.$el).filter(
      matchesCategory,
    );
    // eslint-disable-next-line @trello/enforce-variable-case
    const $cards = $('.js-category-cards', this.$el).filter(matchesCategory);

    const uncheckedCards = $cards.find(
      '.js-toggle-checkbox:not([data-checked])',
    );
    const checkedCards = $cards.find('.js-toggle-checkbox[data-checked]');

    $cards.find('.js-select-all').toggleClass('hide', !uncheckedCards.length);
    $cards.find('.js-select-none').toggleClass('hide', !checkedCards.length);

    $heading.find('.js-card-count').text(checkedCards.length);
    return $heading
      .find('.js-archive-category')
      .toggleClass('hide', !checkedCards.length);
  }

  listenForCards() {
    return this.listenTo(
      this.modelCache,
      'add:Card remove:Card change:Card:closed change:Card:idList',
      this.renderDebounced,
    );
  }

  stopListeningForCards() {
    return this.stopListening(this.modelCache);
  }

  _ageCategories() {
    const visibleCards = ModelCache.find('Card', (card) => {
      return card.get('idBoard') === this.model.id && card.isVisible();
    });

    return _.chain(CATEGORIES)
      .pairs()
      .map(function (...args) {
        const [key, { age }] = Array.from(args[0]);
        const cards = visibleCards.filter(
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          (card: any) =>
            // @ts-expect-error TS(2363): The right-hand side of an arithmetic operation mus... Remove this comment to see the full error message
            Date.now() - new Date(card.get('dateLastActivity')) > age,
        );

        return [key, cards];
      })
      .filter(function (...args) {
        const [, cards] = Array.from(args[0]);
        // @ts-expect-error
        return cards.length > 0;
      })
      .object()
      .value();
  }

  render() {
    Analytics.sendScreenEvent({
      name: 'boardCleanUpModal',
      containers: {
        board: {
          id: this.model.id,
        },
        workspace: {
          id: this.model.getOrganization()?.id,
        },
        enterprise: {
          id: this.model.getEnterprise()?.id,
        },
      },
    });

    if (this.progress != null) {
      this.$el.html(progressTemplate(this.progress));
      return this;
    }

    const lists = this.model.listList
      .chain()
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .filter((list: any) => list.isOpen())
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .map(function (list: any) {
        const openCards = list.openCards().models;

        const newestCard = _.chain(openCards)
          .sortBy((card) => card.get('dateLastActivity'))
          .reverse()
          .first()
          .value();

        return {
          id: list.id,
          listName: list.get('name'),
          cardCount: openCards.length,
          cards: openCards,
          lastModified:
            newestCard != null ? newestCard.get('dateLastActivity') : undefined,
        };
      })
      .filter(
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (entry: any) =>
          entry.lastModified != null &&
          // @ts-expect-error TS(2365): Operator '<' cannot be applied to types 'Date' and... Remove this comment to see the full error message
          new Date(entry.lastModified) < Date.now() - Util.getMs({ days: 30 }),
      )
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .sortBy((entry: any) => -entry.cardCount)
      .first(5)
      .value();

    this.$el.html(
      template({
        ageCategories: this._ageCategories(),
        lists,
      }),
    );

    _.keys(CATEGORIES).forEach((category) => {
      return this._updateCategory(category);
    });

    return this;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  archiveCategory(e: any) {
    stopPropagationAndPreventDefault(e);
    const category = $(e.currentTarget, this.$el).attr('data-category');

    const checked = {};

    $('.js-category-cards', this.$el)
      .filter(filterByAttr('data-category', category))
      .find('.js-toggle-checkbox')
      .each((index, el) => {
        // @ts-expect-error TS(2769): No overload matches this call.
        // eslint-disable-next-line @trello/enforce-variable-case
        const $checkbox = $(el, this.$el);
        // @ts-expect-error TS(2339): Property 'attr' does not exist on type 'JQueryStat... Remove this comment to see the full error message
        const idCard = $checkbox.attr('data-card');
        // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
        checked[idCard] = $checkbox.is('[data-checked]');
      });

    // @ts-expect-error Type 'undefined' cannot be used as an index type.ts(2538)
    const cards = this._ageCategories()[category].filter(
      // @ts-expect-error TS(7053): Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      (card) => checked[card.id],
    );

    if (cards != null) {
      const boardModel = this.model;
      return Confirm.toggle('archive cards', {
        elem: e.target,
        model: this.model,
        /* eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-shadow */
        fxConfirm: (e: any) => {
          this.stopListeningForCards();
          this.progress = {
            count: 0,
            total: cards.length,
          };
          this.render();

          this.traceId = Analytics.startTask({
            taskName: 'archive-cards',
            source: 'boardCleanUpModal',
          });

          this.aborted = false;
          return BluebirdPromise.map(
            cards,
            (card) => {
              if (this.aborted) {
                return;
              }

              const started = Date.now();

              return BluebirdPromise.fromNode((callback) =>
                // @ts-expect-error
                card.close(callback),
              )
                .then(function () {
                  Analytics.sendTrackEvent({
                    action: 'archived',
                    actionSubject: 'card',
                    // @ts-expect-error
                    actionSubjectId: card.id,
                    source: 'boardCleanUpModal',
                    containers: {
                      board: {
                        id: boardModel.id,
                      },
                      workspace: {
                        id: boardModel.getOrganization()?.id,
                      },
                      enterprise: {
                        id: boardModel.getEnterprise()?.id,
                      },
                    },
                    attributes: {
                      isBulkAction: true,
                    },
                  });

                  const elapsed = Date.now() - started;
                  const remaining = MINIMUM_TIME - elapsed;
                  if (remaining > 0) {
                    return BluebirdPromise.delay(remaining);
                  }
                })
                .then(() => {
                  this.progress.count++;
                  return this.render();
                });
            },
            { concurrency: CONCURRENCY },
          )
            .then(() => {
              Analytics.taskSucceeded({
                taskName: 'archive-cards',
                source: 'boardCleanUpModal',
                traceId: this.traceId,
              });
            })
            .catch(() => {
              // @ts-expect-error TS(2345): Argument of type '{ taskName: "archive-cards"; sou... Remove this comment to see the full error message
              Analytics.taskFailed({
                taskName: 'archive-cards',
                source: 'boardCleanUpModal',
                traceId: this.traceId,
              });
            })
            .then(() => {
              this.progress = null;
              this.listenForCards();
              return this.render();
            })
            .done();
        },
      });
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  archiveList(e: any) {
    stopPropagationAndPreventDefault(e);
    const idList = $(e.currentTarget, this.$el).attr('data-list');

    const list = this.model.listList.get(idList);

    Analytics.sendTrackEvent({
      action: 'archived',
      actionSubject: 'list',
      actionSubjectId: idList,
      source: 'boardCleanUpModal',
      containers: {
        board: {
          id: this.model.id,
        },
        workspace: {
          id: this.model.getOrganization()?.id,
        },
        enterprise: {
          id: this.model.getEnterprise()?.id,
        },
      },
    });

    return list.close();
  }

  abort() {
    this.aborted = true;
    if (this.progress) {
      Analytics.taskAborted({
        taskName: 'archive-cards',
        source: 'boardCleanUpModal',
        traceId: this.traceId,
      });
    }
  }
}

BoardCleanUpView.initClass();
export { BoardCleanUpView };
