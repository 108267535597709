import { useEffect, useState } from 'react';

import {
  sendChunkLoadErrorEvent,
  sendErrorEvent,
} from '@trello/error-reporting';
import { currentLocale, languageParts } from '@trello/locale';
import { importWithRetry } from '@trello/use-lazy-component';

const getSmartCardMessages = async (msgType: string) => {
  switch (msgType) {
    case 'cs':
      return importWithRetry(
        () =>
          import(
            /*webpackChunkName: "smart-card-messages-cs" */ '@atlaskit/smart-card/dist/esm/i18n/cs'
          ),
      );
    case 'da':
      return importWithRetry(
        () =>
          import(
            /*webpackChunkName: "smart-card-messages-da" */ '@atlaskit/smart-card/dist/esm/i18n/da'
          ),
      );
    case 'de':
      return importWithRetry(
        () =>
          import(
            /*webpackChunkName: "smart-card-messages-de" */ '@atlaskit/smart-card/dist/esm/i18n/de'
          ),
      );
    case 'en_GB':
      return importWithRetry(
        () =>
          import(
            /*webpackChunkName: "smart-card-messages-en_GB" */ '@atlaskit/smart-card/dist/esm/i18n/en_GB'
          ),
      );
    case 'es':
      return importWithRetry(
        () =>
          import(
            /*webpackChunkName: "smart-card-messages-es" */ '@atlaskit/smart-card/dist/esm/i18n/es'
          ),
      );
    case 'et':
      return importWithRetry(
        () =>
          import(
            /*webpackChunkName: "smart-card-messages-et" */ '@atlaskit/smart-card/dist/esm/i18n/et'
          ),
      );
    case 'fi':
      return importWithRetry(
        () =>
          import(
            /*webpackChunkName: "smart-card-messages-fi" */ '@atlaskit/smart-card/dist/esm/i18n/fi'
          ),
      );
    case 'fr':
      return importWithRetry(
        () =>
          import(
            /*webpackChunkName: "smart-card-messages-fr" */ '@atlaskit/smart-card/dist/esm/i18n/fr'
          ),
      );
    case 'hu':
      return importWithRetry(
        () =>
          import(
            /*webpackChunkName: "smart-card-messages-hu" */ '@atlaskit/smart-card/dist/esm/i18n/hu'
          ),
      );
    case 'is':
      return importWithRetry(
        () =>
          import(
            /*webpackChunkName: "smart-card-messages-is" */ '@atlaskit/smart-card/dist/esm/i18n/is'
          ),
      );
    case 'it':
      return importWithRetry(
        () =>
          import(
            /*webpackChunkName: "smart-card-messages-it" */ '@atlaskit/smart-card/dist/esm/i18n/it'
          ),
      );
    case 'ja':
      return importWithRetry(
        () =>
          import(
            /*webpackChunkName: "smart-card-messages-ja" */ '@atlaskit/smart-card/dist/esm/i18n/ja'
          ),
      );
    case 'ko':
      return importWithRetry(
        () =>
          import(
            /*webpackChunkName: "smart-card-messages-ko" */ '@atlaskit/smart-card/dist/esm/i18n/ko'
          ),
      );
    case 'nb':
      return importWithRetry(
        () =>
          import(
            /*webpackChunkName: "smart-card-messages-nb" */ '@atlaskit/smart-card/dist/esm/i18n/nb'
          ),
      );
    case 'nl':
      return importWithRetry(
        () =>
          import(
            /*webpackChunkName: "smart-card-messages-nl" */ '@atlaskit/smart-card/dist/esm/i18n/nl'
          ),
      );
    case 'pl':
      return importWithRetry(
        () =>
          import(
            /*webpackChunkName: "smart-card-messages-pl" */ '@atlaskit/smart-card/dist/esm/i18n/pl'
          ),
      );
    case 'pt_BR':
      return importWithRetry(
        () =>
          import(
            /*webpackChunkName: "smart-card-messages-pt_BR" */ '@atlaskit/smart-card/dist/esm/i18n/pt_BR'
          ),
      );
    case 'pt_PT':
      return importWithRetry(
        () =>
          import(
            /*webpackChunkName: "smart-card-messages-pt_PT" */ '@atlaskit/smart-card/dist/esm/i18n/pt_PT'
          ),
      );
    case 'ro':
      return importWithRetry(
        () =>
          import(
            /*webpackChunkName: "smart-card-messages-ro" */ '@atlaskit/smart-card/dist/esm/i18n/ro'
          ),
      );
    case 'ru':
      return importWithRetry(
        () =>
          import(
            /*webpackChunkName: "smart-card-messages-ru" */ '@atlaskit/smart-card/dist/esm/i18n/ru'
          ),
      );
    case 'sk':
      return importWithRetry(
        () =>
          import(
            /*webpackChunkName: "smart-card-messages-sk" */ '@atlaskit/smart-card/dist/esm/i18n/sk'
          ),
      );
    case 'sv':
      return importWithRetry(
        () =>
          import(
            /*webpackChunkName: "smart-card-messages-sv" */ '@atlaskit/smart-card/dist/esm/i18n/sv'
          ),
      );
    case 'th':
      return importWithRetry(
        () =>
          import(
            /*webpackChunkName: "smart-card-messages-th" */ '@atlaskit/smart-card/dist/esm/i18n/th'
          ),
      );
    case 'tr':
      return importWithRetry(
        () =>
          import(
            /*webpackChunkName: "smart-card-messages-tr" */ '@atlaskit/smart-card/dist/esm/i18n/tr'
          ),
      );
    case 'uk':
      return importWithRetry(
        () =>
          import(
            /*webpackChunkName: "smart-card-messages-uk" */ '@atlaskit/smart-card/dist/esm/i18n/uk'
          ),
      );
    case 'vi':
      return importWithRetry(
        () =>
          import(
            /*webpackChunkName: "smart-card-messages-vi" */ '@atlaskit/smart-card/dist/esm/i18n/vi'
          ),
      );
    case 'zh':
    case 'zh-Hans':
      return importWithRetry(
        () =>
          import(
            /*webpackChunkName: "smart-card-messages-zh" */ '@atlaskit/smart-card/dist/esm/i18n/zh'
          ),
      );
    case 'zh_TW':
    case 'zh-Hant':
      return importWithRetry(
        () =>
          import(
            /*webpackChunkName: "smart-card-messages-zh-tw" */ '@atlaskit/smart-card/dist/esm/i18n/zh_TW'
          ),
      );
    default:
      return importWithRetry(
        () =>
          import(
            /* webpackChunkName: "smart-card-messages-en" */ '@atlaskit/smart-card/dist/esm/i18n/en'
          ),
      );
  }
};

export const useSmartCardLocale = () => {
  const [messages, setMessages] = useState({});
  const [isLocaleReady, setIsLocaleReady] = useState(false);
  const { language, region, script } = languageParts(currentLocale);

  useEffect(() => {
    const smartCardMsgType = region
      ? `${language}_${region}`
      : script
      ? `${language}-${script}`
      : language;
    const setLocaleData = async () => {
      try {
        const smartCardMessages = await getSmartCardMessages(smartCardMsgType);

        setMessages(smartCardMessages.default);
        setIsLocaleReady(true);
      } catch (error) {
        setIsLocaleReady(true);
        setMessages({});
        if (error instanceof Error) {
          if (error.name === 'ChunkLoadError') {
            sendChunkLoadErrorEvent(error);
          } else {
            sendErrorEvent(error, {
              tags: {
                ownershipArea: 'trello-web-eng',
                feature: 'Smart Link',
              },
            });
          }
        }
      }
    };

    setLocaleData();
  }, [setMessages, script, region, language]);

  return {
    messages,
    isLocaleReady,
  };
};
