// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS206: Consider reworking classes to avoid initClass
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */

import { Analytics } from '@trello/atlassian-analytics';
// eslint-disable-next-line no-restricted-imports
import $ from '@trello/jquery';

import type { Board } from 'app/scripts/models/Board';
import { View } from 'app/scripts/views/internal/View';
import { PowerUpsVotingPrefsTemplate } from 'app/scripts/views/templates/PowerUpsVotingPrefsTemplate';
import { stopPropagationAndPreventDefault } from 'app/src/stopPropagationAndPreventDefault';

class VotingSettingsView extends View<Board> {
  static initClass() {
    this.prototype.viewTitleKey = 'voting settings';
  }

  events() {
    return {
      'click .js-voting-select:not(.disabled)': 'selectPerm',
      'click .js-vote-count-select:not(.disabled)': 'hideVotes',
    };
  }

  initialize() {
    return this.listenTo(this.model, { 'change:prefs': this.render });
  }

  render() {
    this.$el.html(
      PowerUpsVotingPrefsTemplate({
        canChange: this.model.editable(),
        canSetObservers: this.model
          .getOrganization()
          ?.isFeatureEnabled('observers'),
        canSetOrganization: this.model.hasOrganization(),
        canSetPublic: this.model.isPublic(),
        votingMode: this.model.getPref('voting'),
        hideVotes: this.model.getPref('hideVotes'),
        isPrivate: this.model.isPrivate(),
      }),
    );

    return this;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  selectPerm(e: any) {
    stopPropagationAndPreventDefault(e);
    const traceId = Analytics.startTask({
      taskName: 'edit-plugin/voting',
      source: 'votingSettingsInlineDialog',
    });
    // eslint-disable-next-line @trello/enforce-variable-case
    const $target = $(e.target).closest('.js-voting-select');
    const setting = $target.attr('name');
    this.model.setPrefWithTracing('voting', setting, {
      taskName: 'edit-plugin/voting',
      source: 'votingSettingsInlineDialog',
      traceId,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      next: (_err: any, board: any) => {
        if (board) {
          Analytics.sendUpdatedBoardFieldEvent({
            field: 'votingPref',
            value: setting,
            source: 'votingSettingsInlineDialog',
            containers: {
              board: {
                id: this.model.id,
              },
              organization: {
                id: this.model.getOrganization()?.id,
              },
            },
            attributes: {
              previous: this.model.getPref('voting'),
              taskId: traceId,
            },
          });
        }
      },
    });
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  hideVotes(e: any) {
    stopPropagationAndPreventDefault(e);
    const traceId = Analytics.startTask({
      taskName: 'edit-plugin/voting',
      source: 'votingSettingsInlineDialog',
    });

    this.model.setPrefWithTracing(
      'hideVotes',
      !this.model.getPref('hideVotes'),
      {
        taskName: 'edit-plugin/voting',
        source: 'votingSettingsInlineDialog',
        traceId,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        next: (_err: any, board: any) => {
          if (board) {
            Analytics.sendUpdatedBoardFieldEvent({
              field: 'hideVotesPref',
              value: this.model.getPref('hideVotes'),
              source: 'votingSettingsInlineDialog',
              attributes: {
                taskId: traceId,
              },
              containers: {
                board: {
                  id: this.model.id,
                },
                organization: {
                  id: this.model.getOrganization()?.id,
                },
              },
            });
          }
        },
      },
    );
  }
}

VotingSettingsView.initClass();
export { VotingSettingsView };
