// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * DS104: Avoid inline assignments
 * DS204: Change includes calls to have a more natural evaluation order
 * DS206: Consider reworking classes to avoid initClass
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */

import { Component } from 'react';
import _ from 'underscore';

import { renderReactRootAsync } from '@trello/component-wrapper';
// eslint-disable-next-line no-restricted-imports
import $ from '@trello/jquery';
import { applyBidiTooltip, markdownAsHtml } from '@trello/markdown';
import type { RouteId } from '@trello/router';
import { getLocation, getRouteParamsFromPathname } from '@trello/router';

import { getBoardUrlFromShortLink } from 'app/scripts/controller/urls';
import { isValidHttp } from 'app/scripts/lib/plugins/pluginValidators';
import type { Board } from 'app/scripts/models/Board';
import type { Plugin } from 'app/scripts/models/Plugin';
import type { PluginIO } from 'app/scripts/views/internal/plugins/PluginIo';
import { pluginIOCache } from 'app/scripts/views/internal/plugins/PluginIoCache';
import { ReactRootComponent } from 'app/scripts/views/internal/ReactRootComponent';
import { recupWithHelpers } from 'app/scripts/views/internal/recupWithHelpers';
import { View } from 'app/scripts/views/internal/View';
import { PowerUpItemType } from 'app/src/components/PowerUp';
import { LazyPowerUpsDeprecationBanner } from 'app/src/components/PowerUpsDeprecationBanner/LazyPowerUpsDeprecationBanner';
import { DirectoryLegacyPowerUps as directoryLegacyPowerUps } from './DirectoryLegacyPowerUps';
import { DirectoryPluginItemView } from './DirectoryPluginItemView';
import type { DirectoryView } from './DirectoryView';

import compliantIcon from 'resources/images/directory/icons/compliant.svg';
import notCompliantIcon from 'resources/images/directory/icons/notCompliant.svg';

const t = recupWithHelpers('directory');
const c = recupWithHelpers('directory_data_consent');

interface DirectoryListingProps {
  displayName: string;
  description: string;
  supportEmail?: string;
  privacyPolicy?: string;
  categories: string[];
  isGrandfathered: boolean;
  isCompliant?: boolean;
  isDeprecated: string;
  isIntegration: boolean;
  onRender?: (value?: unknown) => void;
}
class DirectoryListing extends Component<DirectoryListingProps> {
  static initClass() {
    // @ts-expect-error TS(2339): Property 'displayName' does not exist on type 'Dir... Remove this comment to see the full error message
    this.prototype.displayName = 'Directory Listing';

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    this.prototype.render = t.renderable(function (this: any) {
      const { shortLink, shortName } =
        getRouteParamsFromPathname<RouteId.BOARD>(getLocation().pathname);
      const {
        supportEmail,
        description,
        displayName,
        isCompliant,
        privacyPolicy,
        categories,
        isDeprecated,
      } = this.props;

      return t.div('.directory-individual-listing', function () {
        t.div('.js-directory-listing');
        if (supportEmail) {
          let supportLink = supportEmail;

          const EMAIL_REGEX = /^[^@]+@[a-z0-9-]+(\.[a-z0-9-]+)+$/i;

          if (EMAIL_REGEX.test(supportLink)) {
            supportLink = `mailto:${supportEmail}?subject=${encodeURIComponent(
              displayName,
            )}`;
          }

          t.div('.directory-listing-support', () =>
            t.div('.support-list', function () {
              t.icon('email', { class: 'icon-lg' });
              t.a('.support-link', { href: supportLink }, () =>
                t.format('contact-support'),
              );
              if (isValidHttp(privacyPolicy)) {
                t.icon('information', { class: 'icon-lg' });
                return t.a('.support-link', { href: privacyPolicy }, () =>
                  t.format('privacy-policy'),
                );
              }
            }),
          );
        } else {
          t.div('.directory-listing-categories', function () {
            t.icon('information', { class: 'icon-lg' });
            return t.div('.categories-list', () =>
              categories.map((category: string, index: number) => {
                if (index > 0) {
                  t.span('.category-link-delimiter', () => t.text('•'));
                }
                t.a(
                  '.category-link',
                  {
                    href: `${getBoardUrlFromShortLink(
                      shortLink,
                      shortName,
                    )}/power-ups/category/${category}`,
                  },
                  () => t.format(category),
                );
              }),
            );
          });
        }
        if (isDeprecated) {
          t.div('.directory-listing-deprecation-warning');
        }
        t.div(
          '.directory-listing-content.markeddown',
          {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            ref: (node: any) => {
              if (node) {
                applyBidiTooltip(node);
              }
            },
          },
          () => t.raw(markdownAsHtml(description)),
        );
        return t.div('.directory-listing-compliance', function () {
          const src = isCompliant === false ? notCompliantIcon : compliantIcon;

          c.img('.directory-listing-compliance-icon', {
            src,
            alt: '',
            role: 'presentation',
          });
          if (isCompliant === false) {
            return c.format(
              'power-up-does-not-comply-with-our-data-privacy-standards',
              { pluginName: displayName },
            );
          } else if (isCompliant === true) {
            return c.format(
              'power-up-stores-data-complies-with-our-data-privacy-standards',
              { pluginName: displayName },
            );
          } else {
            return c.format(
              'power-up-complies-with-our-data-privacy-standards',
              { pluginName: displayName },
            );
          }
        });
      });
    });
  }
}

DirectoryListing.initClass();

interface DirectoryListingView {
  directoryView: DirectoryView;
  io: PluginIO;
  isDeprecated: string;
  isGrandfathered: boolean;
  plugin: Plugin;
}

class DirectoryListingView extends View<Board> {
  className() {
    return 'directory-listing-wrapper';
  }
  eventSource() {
    return 'boardDirectoryPowerUpDetailsScreen' as const;
  }

  initialize({
    directoryView,
    plugin,
  }: {
    directoryView: DirectoryView;
    plugin: Plugin;
  }) {
    this.directoryView = directoryView;
    this.plugin = plugin;

    super.initialize(...arguments);

    this.isDeprecated = this.plugin.get('deprecation')?.sunsetDate;

    this.io = pluginIOCache.get(this.plugin);

    const powerUp = _.findWhere(directoryLegacyPowerUps, {
      id: this.plugin.id,
    });

    if (powerUp) {
      let needle;
      const { name } = powerUp;
      return (this.isGrandfathered =
        ((needle = name),
        Array.from(this.model.get('powerUps')).includes(needle)));
    }
  }
  render() {
    this.renderAsync();
    return this;
  }

  async renderAsync() {
    try {
      await renderReactRootAsync(
        <div>
          <ReactRootComponent>
            <DirectoryListing
              displayName={this.io.getName()}
              description={this.io.getDescription()}
              supportEmail={this.plugin.get('supportEmail')}
              privacyPolicy={this.plugin.get('privacyUrl')}
              categories={this.plugin.get('categories')}
              isGrandfathered={this.isGrandfathered}
              isCompliant={this.plugin.get('isCompliantWithPrivacyStandards')}
              isDeprecated={this.isDeprecated}
              isIntegration={(this.plugin.get('tags') || []).includes(
                'integration',
              )}
            />
          </ReactRootComponent>
        </div>,
        this.el,
        'js-directory-listing',
        true,
      );

      if (this.isDeprecated) {
        const deprecatedBannerReactRoot = this.el.querySelector(
          '.directory-listing-deprecation-warning',
        );
        await renderReactRootAsync(
          <LazyPowerUpsDeprecationBanner
            deprecatedPowerUps={[
              {
                ...this.plugin.get('deprecation'),
                name: this.io.getName(),
              },
            ]}
          />,
          deprecatedBannerReactRoot,
          'js-directory-listing',
        );
      }

      this.appendSubview(
        this.subview(
          DirectoryPluginItemView,
          this.model,
          {
            atomType: PowerUpItemType.Listing,
            plugin: this.plugin,
            directoryView: this.directoryView,
          },
          this.plugin.id,
        ),
        $('.js-directory-listing', this.$el),
      );

      const enabledPlugins = this.directoryView.getEnabledPlugins();

      this.directoryView.trackScreenEvent(this.eventSource(), {
        powerUpId: this.plugin.id,
        // @ts-expect-error TS(2769): No overload matches this call.
        isEnabled: _.some(enabledPlugins, { id: this.plugin.id })
          ? true
          : false,
        tags: this.plugin.get('tags'),
      });
    } catch (error) {
      console.error('Error rendering DirectoryListingView:', error);
    }
  }
}

export { DirectoryListingView };
