import { useEffect, useMemo, useState } from 'react';

import { useCrossFlow } from '@atlassiansox/cross-flow-support';
import type { TouchpointSourceType } from '@trello/ad-sequencer';
import { useOneTimeMessagesDismissed } from '@trello/business-logic-react/member';
import type { ExperimentVariations } from '@trello/feature-gate-client';

import type { JiraTemplateIdType } from '../JiraTemplateId.types';
import {
  type TrelloCrossFlowExperiment,
  trelloCrossFlowOpen,
} from '../TrelloCrossFlow';
import { useHasEligibleWorkspaces } from './runtimeEligibilityChecks/useHasEligibleWorkspaces';
import { useHasNoAvailableSites } from './runtimeEligibilityChecks/useHasNoAvailableSites';
import { useIsNewUser } from './runtimeEligibilityChecks/useIsNewUser';
import { useTrelloCrossFlowExperiment } from './useTrelloCrossFlowExperiment';

const experimentFeatureKey = 'trello_xf_new_users_combinations';

export type NewUserCombinationsExperimentVariations = ExperimentVariations<
  typeof experimentFeatureKey,
  'cohort'
>;
const oneTimeMessageSources: Partial<Record<TouchpointSourceType, string>> = {
  memberBoardsHomeScreen: '1p-ads-jira-template-picker-boards-home',
  workspaceBoardsHomeScreen:
    '1p-ads-jira-template-picker-workspace-boards-home',
  templatesMenuInlineDialog: '1p-ads-jira-template-picker-template-nav',
  cardBackDatePicker: '1p-discovery-ad-card-date-range-picker',
};
export interface CrossFlowForNewUserCombinationsExperimentParams {
  source: TouchpointSourceType;
  workspaceId?: string;
  boardId?: string;
  hasValidAaSession: boolean;
  jiraTemplateId?: JiraTemplateIdType;
}

export const useCrossFlowForNewUserCombinations = ({
  source,
  workspaceId,
  boardId,
  hasValidAaSession,
  jiraTemplateId,
}: CrossFlowForNewUserCombinationsExperimentParams): TrelloCrossFlowExperiment<NewUserCombinationsExperimentVariations> => {
  const crossFlow = useCrossFlow();
  const { isEligible: isNewUserEligible } = useIsNewUser();
  const { isEligible: hasEligibleWorkspaces, eligibleWorkspaceOptions } =
    useHasEligibleWorkspaces({
      workspaceId,
    });
  const [
    isImmediateRuntimeEligibilityChecksPassed,
    setImmediateRuntimeEligibilityChecksPassed,
  ] = useState(false);
  const { isEligible: hasNoAvailableSites } = useHasNoAvailableSites({
    doLoadAvailableSites: isImmediateRuntimeEligibilityChecksPassed,
    source,
  });
  const { isOneTimeMessageDismissed } = useOneTimeMessagesDismissed();
  const isSourceOneTimeMessageDismissed = useMemo(
    () => isOneTimeMessageDismissed(oneTimeMessageSources?.[source] || ''),
    [source, isOneTimeMessageDismissed],
  );

  const experiment = useTrelloCrossFlowExperiment({
    experimentFeatureKey,
    source,
    eligibilityCheckResults:
      source === 'welcomeTaskSelectionScreen'
        ? [crossFlow.isEnabled]
        : [
            crossFlow.isEnabled,
            isNewUserEligible,
            hasEligibleWorkspaces,
            !isSourceOneTimeMessageDismissed,
          ],
    deferredEligibilityCheckResults: [hasNoAvailableSites],
  });
  useEffect(() => {
    setImmediateRuntimeEligibilityChecksPassed(
      experiment.isImmediateRuntimeEligibilityChecksPassed,
    );
  }, [experiment.isImmediateRuntimeEligibilityChecksPassed]);
  return useMemo(
    () => ({
      ...experiment,
      open: trelloCrossFlowOpen(
        crossFlow,
        source,
        eligibleWorkspaceOptions,
        hasValidAaSession,
        {
          workspaceId,
          boardId,
          isNewTrelloUser: true,
          sporkPositioning: 'customization',
          jiraTemplateId,
        },
      ),
    }),
    [
      experiment,
      crossFlow,
      source,
      eligibleWorkspaceOptions,
      hasValidAaSession,
      workspaceId,
      boardId,
      jiraTemplateId,
    ],
  );
};
