// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SwJc0NvpTPcBrE,
.TEvqlv2vcinu0_ {
  color: var(--ds-text-subtle, #44546f);
  font-size: 12px;
  font-weight: 600;
  line-height: 20px;
}
.TEvqlv2vcinu0_ {
  display: flex;
  justify-content: space-between;
  padding: 0;
}
.TEvqlv2vcinu0_:hover,
.TEvqlv2vcinu0_:active {
  background-color: transparent;
}
.aTRnIWYi7l3CE3 {
  margin-right: 6px;
  transition: transform 0.2s ease-out;
}
.aTRnIWYi7l3CE3.NwPuZRUIKhVT0l {
  transform: rotate(180deg);
}
.qjbbzNjvSmnmvj {
  height: auto;
}
.qjbbzNjvSmnmvj.NwPuZRUIKhVT0l {
  visibility: hidden;
  height: 0;
  overflow: hidden;
}
`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"title": `SwJc0NvpTPcBrE`,
	"button": `TEvqlv2vcinu0_`,
	"icon": `aTRnIWYi7l3CE3`,
	"collapsed": `NwPuZRUIKhVT0l`,
	"content": `qjbbzNjvSmnmvj`
};
export default ___CSS_LOADER_EXPORT___;
