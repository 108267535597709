import type { Attribute } from './fetchUserPersonalization';
import { fetchUserPersonalization } from './fetchUserPersonalization';
import type { Traits } from './traits';

const cache: Record<string, Traits> = {};

export const getUserTraits = async (
  userId: string,
  key?: string,
): Promise<Traits> => {
  if (cache[userId] && !key) {
    return cache[userId];
  }
  if (key && cache[userId]?.[key]) {
    return cache[userId];
  }

  const { attributes } = await fetchUserPersonalization(userId);

  const traits = attributes.reduce((obj: Traits, item: Attribute) => {
    obj[item.name] = item.value;
    return obj;
  }, {});

  return (cache[userId] = {
    ...cache[userId],
    ...traits,
  });
};
