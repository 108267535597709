import type { ExperimentVariations } from '@trello/feature-gate-client';

import type { CrossFlowForPaidUsersParams } from './useCrossFlowForPaidUsers';
import { useCrossFlowForPaidUsers } from './useCrossFlowForPaidUsers';

const experimentFeatureKey = 'trello_xf_spork_branding_positioning_paid_users';

export type CrossFlowForSporkPaidUsersExperimentVariations =
  ExperimentVariations<typeof experimentFeatureKey, 'cohort'>;

export const useCrossFlowForPaidUsersSpork = ({
  source,
  workspaceId,
  boardId,
  hasValidAaSession,
}: CrossFlowForPaidUsersParams) => {
  return useCrossFlowForPaidUsers(experimentFeatureKey, {
    source,
    workspaceId,
    boardId,
    hasValidAaSession,
  });
};
