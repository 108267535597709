import type { CardFrontTestIds } from '@trello/test-ids';
import { getTestId } from '@trello/test-ids';

import { teacupWithHelpers } from 'app/scripts/views/internal/teacupWithHelpers';

const t = teacupWithHelpers('member');

interface TemplateProps {
  id: string;
  avatarUrl: string;
  viewTitle: string;
  initials: string;
  isDeactivated: boolean;
  isBoardAdmin: boolean;
  isOrganizationAdmin: boolean;
  hideBadges: boolean;
  testId?: string;
}

// eslint-disable-next-line @trello/no-module-logic
export const MemberTemplate = t.renderable(function ({
  id,
  avatarUrl,
  viewTitle,
  initials,
  isDeactivated,
  isBoardAdmin,
  isOrganizationAdmin,
  hideBadges,
  testId,
}: TemplateProps) {
  if (avatarUrl) {
    t.img('.member-avatar', {
      height: '30',
      width: '30',
      src: [avatarUrl, '30.png'].join('/'),
      srcset: [
        [avatarUrl, '30.png 1x'].join('/'),
        [avatarUrl, '50.png 2x'].join('/'),
      ].join(', '),
      alt: viewTitle,
      title: viewTitle,
      'data-testid': getTestId<CardFrontTestIds>('card-front-member'),
    });
  } else if (initials) {
    t.span(
      '.member-initials',
      {
        title: viewTitle,
        'aria-label': viewTitle,
      },
      () => t.text(initials),
    );
  } else {
    t.div('.member-icon-container', () => t.icon('member'));
  }

  if (id) {
    t.span(`#${id}-avatar`, { 'data-testid': testId });
  }

  if (!isDeactivated && !hideBadges) {
    if (isBoardAdmin) {
      t.span('.member-type.admin', {
        title: t.l('this-member-is-an-admin-of-this-board'),
        'aria-label': t.l('this-member-is-an-admin-of-this-board'),
      });
    }
    if (isOrganizationAdmin) {
      t.span('.member-type.admin', {
        title: t.l('this-member-is-an-admin-of-this-organization'),
        'aria-label': t.l('this-member-is-an-admin-of-this-organization'),
      });
    }
  }
});
