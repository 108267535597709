import type { FunctionComponent } from 'react';
import classNames from 'classnames';

import { forTemplate } from '@trello/legacy-i18n';
import type { PowerUpTestIds } from '@trello/test-ids';
import { getTestId } from '@trello/test-ids';
import {
  useWorkspaceNavigation,
  useWorkspaceNavigationHidden,
} from '@trello/workspace-navigation';

import styles from './Banner.less';

const format = forTemplate('directory');
interface BannerProps {
  readonly heading: string;
  readonly paragraph: string;
}

export const Banner: FunctionComponent<BannerProps> = ({
  heading,
  paragraph,
}) => {
  const [{ expanded: _workspaceNavigationExpanded }] = useWorkspaceNavigation();
  const [{ hidden: _workspaceNavigationHidden }] =
    useWorkspaceNavigationHidden();
  const workspaceNavigationExpanded =
    !_workspaceNavigationHidden && _workspaceNavigationExpanded;

  return (
    <div
      className={classNames(styles.directoryBannerContainer, {
        [styles.workspaceNavigationExpanded]: workspaceNavigationExpanded,
      })}
      data-testid={getTestId<PowerUpTestIds>('pup-banner')}
    >
      <div
        className={classNames(styles.directoryBanner, {
          [styles.workspaceNavigationExpanded]: workspaceNavigationExpanded,
        })}
      >
        <div>
          <h1
            className={styles.bannerHeading}
            data-testid={getTestId<PowerUpTestIds>('pup-banner-header')}
          >
            {format(heading)}
          </h1>
          <p
            className={styles.bannerParagraph}
            data-testid={getTestId<PowerUpTestIds>('pup-banner-paragraph')}
          >
            {format(paragraph)}
          </p>
          <div />
        </div>
      </div>
    </div>
  );
};
