import _ from 'underscore';

import { TrelloMarkdown } from '@trello/markdown';

import { getIndex } from 'app/src/components/Navigation/SearchUtils';
import type { Plugin, Plugins } from './types';

export const createSearchIndex = (plugins: Plugins) => {
  const markdown = new TrelloMarkdown();

  // Power-Up directory search logic.
  // 1. We use 3 indexes (name, overview, desc) to display results for, in that order
  // 2. We randomize the order of the input plugins so the results alphabetical

  const nameIndex = getIndex();
  const overviewIndex = getIndex();
  const descriptionIndex = getIndex();

  const shuffledPlugins = _.shuffle<Plugin>(plugins);

  shuffledPlugins.forEach((plugin) => {
    if (!plugin.listing) {
      return;
    }
    if (plugin.listing.name) {
      nameIndex.add(plugin.id, plugin.listing.name);
    }
    if (plugin.listing.overview) {
      overviewIndex.add(
        plugin.id,
        markdown.text(plugin.listing.overview).output,
      );
    }
    if (plugin.listing.description) {
      descriptionIndex.add(
        plugin.id,
        markdown.text(plugin.listing.description).output,
      );
    }
  });

  return {
    search(query: string) {
      const nameResults = nameIndex.search(query, 20);
      const overviewResults = overviewIndex.search(query, 15);
      const descriptionResults = descriptionIndex.search(query, 10);

      const combinedResults = nameResults.concat(
        overviewResults,
        descriptionResults,
      );
      return (
        _.uniq(combinedResults)
          // @ts-expect-error
          .map((idPlugin: string) =>
            plugins.find((plugin) => plugin.id === idPlugin),
          )
          .filter(<T>(plugin?: T): plugin is T => Boolean(plugin))
      );
    },
  };
};

export const SearchHelper = {
  createSearchIndex,
};
