import type { FunctionComponent } from 'react';
import { Suspense } from 'react';

import {
  ChunkLoadErrorBoundary,
  ErrorBoundary,
} from '@trello/error-boundaries';
import { useLazyComponent } from '@trello/use-lazy-component';

import type { EditCommentProps } from './EditComment';

export const LazyEditComment: FunctionComponent<EditCommentProps> = (props) => {
  const EditComment = useLazyComponent(
    () => import(/* webpackChunkName: "edit-comment" */ './EditComment'),
    { namedImport: 'EditComment' },
  );

  return (
    <ErrorBoundary
      tags={{ ownershipArea: 'trello-web-eng', feature: 'Comments' }}
    >
      <Suspense fallback={null}>
        <ChunkLoadErrorBoundary fallback={null}>
          <EditComment {...props} />
        </ChunkLoadErrorBoundary>
      </Suspense>
    </ErrorBoundary>
  );
};
