import type {
  CompletionStatus,
  CrossFlowContextType,
  Options,
} from '@atlassiansox/cross-flow-support';
import type { AdTouchpointSourceType } from '@trello/ad-sequencer';
import { isDesktop } from '@trello/browser';
import {
  type FeatureExperimentKeys,
  getExperimentValueAsync,
} from '@trello/feature-gate-client';
import { TrelloStorage } from '@trello/storage';

import { isCrossFlowIFrameOpenState } from './experimental/isCrossFlowIFrameOpenState';
import type { FeatureFlagsTouchpoints } from './experimental/isSourceTargetedByFeatureFlag';
import type { EligibleWorkspaceOptions } from './experimental/useEligibleWorkspacesForProvisioning';
import { AUTO_OPEN_CFFE_STORAGE_KEY } from './crossFlowEssentials';
import type { JiraTemplateIdType } from './JiraTemplateId.types';

export interface TrelloCrossFlowOptions {
  workspaceId?: string;
  boardId?: string;
  isNewTrelloUser?: boolean;
  sporkPositioning?: 'collaboration' | 'customization';
  shouldSkipMarketingPage?: boolean;
  jiraTemplateId?: JiraTemplateIdType;
  hideWorkspaceSelector?: boolean;
}

export interface TrelloCrossFlow<T> {
  open: (options?: Options) => Promise<CompletionStatus | undefined>;
  /**
   * getIsTargeted returns whether the user is targeted, but does not necessarily mean anything about eligibility.
   * Targeted users may then be checked under more criteria and possibly allocated to a not enrolled cohort.
   */
  getIsTargeted: () => boolean;
  /**
   * Returns the experiment cohort after all runtime criteria have been checked.
   * May return null or the not enrolled variation if the user is not enrolled in the experiment.
   * Emits an exposure event for the user and their cohort if the user is enrolled in the experiment.
   */
  getExperimentCohort: () => T | null;

  /**
   * Returns the name of the experiment being used.
   * May return undefined if the user is not enrolled in any experiment(s).
   */
  experimentName?: FeatureExperimentKeys | FeatureFlagsTouchpoints;
}

export interface TrelloCrossFlowExperiment<T> extends TrelloCrossFlow<T> {
  /**
   * Returns whether the user is enrolled in the experiment.
   */
  isEnrolled: boolean;
}

export const trelloCrossFlowOpen = (
  crossflow: CrossFlowContextType,
  source: AdTouchpointSourceType,
  eligibleWorkspaceOptions: EligibleWorkspaceOptions,
  hasValidAaSession: boolean,
  {
    workspaceId,
    boardId,
    isNewTrelloUser,
    sporkPositioning,
    shouldSkipMarketingPage,
    jiraTemplateId,
    hideWorkspaceSelector,
  }: TrelloCrossFlowOptions = {},
) => {
  return async () => {
    // Disable crossFlow on desktop due to compliance risks
    if (isDesktop()) {
      return;
    }

    if (!crossflow.isEnabled) {
      return;
    }

    // Redirect to login if AA session is invalid
    if (!hasValidAaSession) {
      const queryParams = `?returnUrl=${encodeURIComponent(
        window.location.pathname + window.location.search,
      )}`;

      //Set source value in local storage to auto open CFFE on return from login
      TrelloStorage.set(AUTO_OPEN_CFFE_STORAGE_KEY, source);

      window.location.assign(`/login${queryParams}`);
      return;
    }

    isCrossFlowIFrameOpenState.setValue(true);

    const targetProduct = 'jira-software.ondemand';

    const getOverrideOptionsForExperiment = async (): Promise<object> => {
      if (source === 'currentWorkspaceNavigationDrawer') {
        const cohort = await getExperimentValueAsync(
          'trello_xf_site_creation_copy_change',
          'cohort',
        );

        if (cohort !== 'not-enrolled') {
          return {
            siteCreationOptions: {
              trello_xf_site_creation_copy_change: cohort,
            },
          };
        }
      }

      return {};
    };

    const getIsJiraPremiumTiral = async (): Promise<boolean> => {
      const nonPpmSources = ['boardScreen'];
      const paidSources = [
        'cardBackDatePicker',
        'boardDirectory',
        'currentWorkspaceNavigationDrawerJiraSection',
        'boardsHomeCarouselAd',
      ];

      if (!paidSources.includes(source) && !nonPpmSources.includes(source)) {
        return false;
      }
      const [paidCohort, nonPPMCohort] = await Promise.all([
        getExperimentValueAsync('xf_jira_premium_trial_paid_users', 'cohort'),
        getExperimentValueAsync(
          'xf_jira_premium_trial_non_ppm_single_player',
          'cohort',
        ),
      ]);
      return (
        (paidCohort === 'experiment' && paidSources.includes(source)) ||
        (nonPPMCohort === 'experiment' && nonPpmSources.includes(source))
      );
    };

    const response = await crossflow.api.open({
      targetProduct,
      journey: 'get-started',
      sourceComponent: source,
      sourceContext: 'get-started',
      experimentalOptions: {
        trelloWorkspaceId: workspaceId || null,
        trelloWorkspaceOptions: eligibleWorkspaceOptions,
        trelloBoardId: boardId || null,
        isNewTrelloUser: isNewTrelloUser || false,
        shouldSkipMarketingPage: shouldSkipMarketingPage || false,
        jiraTemplateId: jiraTemplateId || '',
        hideWorkspaceSelector: hideWorkspaceSelector || false,
        isJiraPremiumTrial: await getIsJiraPremiumTiral(),
        ...(sporkPositioning ? { sporkPositioning } : {}),
        ...(await getOverrideOptionsForExperiment()),
      },
    });

    isCrossFlowIFrameOpenState.setValue(false);
    return response;
  };
};
