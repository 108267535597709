// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("resources/images/directory/power-ups-banner-v2.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.POeTjw4301HlVd {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
}
.YiFp1e57MVeRfn {
  display: flex;
  justify-content: center;
}
.eD3v_EyvHFNw4S {
  flex: 1 1 auto;
  min-height: calc(100vh - 40px);
  padding-bottom: 32px;
  background-color: var(--ds-surface, #ffffff);
}
.z6ZgqOREP1BrDg {
  display: flex;
  justify-content: center;
  padding-top: 32px;
}
@media only screen and (max-width: 600px) {
  .z6ZgqOREP1BrDg {
    display: block;
    padding-top: 0;
  }
}
@media only screen and (max-width: 860px) {
  .z6ZgqOREP1BrDg.__J77XI7Q33E13 {
    display: block;
    padding-top: 0;
  }
}
.M5jrj5oUT1KjNj {
  width: 960px;
}
@media only screen and (max-width: 1242px) {
  .M5jrj5oUT1KjNj {
    width: 640px;
  }
}
@media only screen and (max-width: 1007px) {
  .M5jrj5oUT1KjNj {
    width: 100%;
  }
}
@media only screen and (max-width: 600px) {
  .M5jrj5oUT1KjNj.kkyVYPShvC6K0m {
    display: none;
  }
}
.M5jrj5oUT1KjNj.__J77XI7Q33E13 {
  width: 960px;
}
@media only screen and (max-width: 1520px) {
  .M5jrj5oUT1KjNj.__J77XI7Q33E13 {
    width: 640px;
  }
}
@media only screen and (max-width: 1267px) {
  .M5jrj5oUT1KjNj.__J77XI7Q33E13 {
    width: 100%;
  }
}
@media only screen and (max-width: 860px) {
}
.DYlcTskDTgT8tV {
  display: flex;
  align-items: center;
  justify-content: center;
}
.D4FWUcxbhb8VYi {
  padding: 0 15%;
  color: var(--ds-text, #172b4d);
  font-size: 14px;
  line-height: 24px;
}
@media only screen and (max-width: 1242px) {
  .D4FWUcxbhb8VYi {
    padding: 0 10%;
  }
}
.D4FWUcxbhb8VYi .XU0rsv0jAryl3b {
  width: 100%;
  margin: 16px auto;
}
.hhJmWwwieX_2ys {
  width: 100%;
}
@media only screen and (max-width: 951px) {
  .hhJmWwwieX_2ys {
    display: none;
  }
}
@media only screen and (max-width: 1204px) {
  .hhJmWwwieX_2ys.__J77XI7Q33E13 {
    display: none;
  }
}
.V_ifR53tKny838 {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  min-width: 272px;
  max-width: 912px;
  height: 240px;
  margin-bottom: 16px;
  padding: 16px;
  border-radius: 4px;
  background: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  background-repeat: no-repeat;
  background-position: top;
  background-size: 100%;
  box-shadow: var(--ds-shadow-raised, 0px 1px 1px #091e4240, 0px 0px 1px #091e424f);
  color: var(--ds-text, #172b4d);
  text-align: center;
  background-origin: content-box;
}
@media only screen and (max-width: 1242px) {
  .V_ifR53tKny838 {
    max-width: 592px;
  }
}
@media only screen and (max-width: 951px) {
  .V_ifR53tKny838 {
    max-width: 272px;
    background-position-y: 14px;
  }
}
.V_ifR53tKny838 .AUTyVyMbkYMRy0 {
  margin-bottom: 32px;
  font-size: 48px;
}
@media only screen and (max-width: 951px) {
  .V_ifR53tKny838 .AUTyVyMbkYMRy0 {
    margin-bottom: 16px;
    font-size: 32px;
  }
}
.V_ifR53tKny838 .eg7dCqlu9b2C0b {
  max-width: 600px;
  padding: 8px;
  color: var(--ds-text-subtle, #44546f);
  font-size: 16px;
  line-height: 24px;
}
@media only screen and (max-width: 951px) {
  .V_ifR53tKny838 .eg7dCqlu9b2C0b {
    font-size: 13px;
  }
}
.V_ifR53tKny838 .g_7CYTNiqcJ50O {
  max-width: 600px;
  padding: 8px;
  color: var(--ds-text-subtle, #44546f);
  font-size: 16px;
  line-height: 24px;
  padding-top: 16px;
  padding-bottom: 0;
  font-size: 18px;
  font-weight: bold;
}
@media only screen and (max-width: 951px) {
  .V_ifR53tKny838 .g_7CYTNiqcJ50O {
    font-size: 13px;
  }
}
@media only screen and (max-width: 1520px) {
  .V_ifR53tKny838.__J77XI7Q33E13 {
    max-width: 592px;
  }
}
@media only screen and (max-width: 1204px) {
  .V_ifR53tKny838.__J77XI7Q33E13 {
    max-width: 272px;
    background-position-y: 14px;
  }
}
@media only screen and (max-width: 1204px) {
  .V_ifR53tKny838.__J77XI7Q33E13 .AUTyVyMbkYMRy0 {
    margin-bottom: 16px;
    font-size: 32px;
  }
}
@media only screen and (max-width: 1204px) {
  .V_ifR53tKny838.__J77XI7Q33E13 .eg7dCqlu9b2C0b {
    font-size: 13px;
  }
}
`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"flexRowWrap": `POeTjw4301HlVd`,
	"flexAndCenter": `YiFp1e57MVeRfn`,
	"directoryBody": `eD3v_EyvHFNw4S`,
	"navigationAndContent": `z6ZgqOREP1BrDg`,
	"workspaceNavigationExpanded": `__J77XI7Q33E13`,
	"directoryContent": `M5jrj5oUT1KjNj`,
	"navigationExpanded": `kkyVYPShvC6K0m`,
	"directorySpinnerContainer": `DYlcTskDTgT8tV`,
	"listingWrapper": `D4FWUcxbhb8VYi`,
	"upgradePromptFullPupPage": `XU0rsv0jAryl3b`,
	"directoryBannerContainer": `hhJmWwwieX_2ys`,
	"directoryBanner": `V_ifR53tKny838`,
	"bannerHeading": `AUTyVyMbkYMRy0`,
	"bannerParagraph": `eg7dCqlu9b2C0b`,
	"buildYourOwnPowerUp": `g_7CYTNiqcJ50O`
};
export default ___CSS_LOADER_EXPORT___;
