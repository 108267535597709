import { useEffect, useState } from 'react';

import { useSharedState } from '@trello/shared-state';

import type { SmartCardClientResponse } from './SmartCardClient';
import { getSmartCardClient } from './SmartCardClient';

// This is hacky way to re-render the Smart Cards after the user has completed the authorization
// flow for a provider. This can go away when @atlaskit/smart-card supports a singleton store prop
export const useSmartCardProviderRefreshKey = (
  resolvedUrl: SmartCardClientResponse | undefined,
) => {
  const smartCardClient = getSmartCardClient();
  const [authorizedProviders] = useSharedState(
    smartCardClient.authorizedProviders,
  );

  const [refreshKey, setRefreshKey] = useState(new Date().getTime());

  const definitionId = resolvedUrl?.meta?.definitionId;
  const shouldRefreshKey = !!(
    definitionId && authorizedProviders.has(definitionId)
  );

  useEffect(() => {
    if (shouldRefreshKey) {
      setRefreshKey(new Date().getTime());
    }
  }, [shouldRefreshKey]);

  return refreshKey;
};
