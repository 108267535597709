import type { FunctionComponent, ReactNode } from 'react';
import { cloneElement } from 'react';
import classNames from 'classnames';

import { dontUpsell } from '@trello/browser';
import { ComponentWrapper } from '@trello/component-wrapper';
import { RouterLink } from '@trello/router/router-link';

import { WithEnterpriseManagedOverride } from './WithEnterpriseManagedOverride';

import styles from './UpgradePromptButton.less';

/**
 * UpgradePromptButton props
 *
 * cta: call to action of prompt, navigates user within the page
 * ctaLink: link for call to action
 * ctaOnClick: function expected to fire side-effects like a trackUe event,
 *             fired on click of cta
 * icon: the icon to render
 * theme: controls theme
 * justify: controls justification of button text
 */

type Theme = 'light' | 'dark' | 'gradient';
type Justify = 'left' | 'center';

interface UpgradePromptButtonProps {
  cta: ReactNode;
  ctaLink?: string;
  ctaOnClick?: () => void;
  icon?: JSX.Element;
  theme?: Theme;
  justify?: Justify;
  shouldFitContainer?: boolean;
  openInNewTab?: boolean;
  allowUpsell?: boolean;
  testId?: string;
}

const getTheme = (theme?: Theme) => {
  switch (theme) {
    case 'dark':
      return styles.upgradePromptButtonDarkTheme;
    case 'light':
      return styles.upgradePromptButtonLightTheme;
    case 'gradient':
      return styles.upgradePromptButtonGradientTheme;
    default:
      return styles.upgradePromptButtonLightTheme;
  }
};

const getJustification = (justify?: Justify) => {
  switch (justify) {
    case 'left':
      return styles.upgradePromptJustifyLeft;
    case 'center':
      return styles.upgradePromptJustifyCenter;
    default:
      return styles.upgradePromptJustifyCenter;
  }
};

export const UpgradePromptButton: FunctionComponent<
  UpgradePromptButtonProps
> = ({
  cta,
  ctaLink,
  ctaOnClick,
  icon,
  theme,
  justify,
  shouldFitContainer,
  openInNewTab,
  allowUpsell,
  testId,
}) => {
  if (dontUpsell() && !allowUpsell) {
    return null;
  }

  // eslint-disable-next-line @trello/enforce-variable-case
  const onClickCTA = () => {
    if (ctaOnClick) {
      ctaOnClick();
    }
  };

  const promptClasses = classNames(
    styles.upgradePromptButton,
    getTheme(theme),
    shouldFitContainer && styles.upgradePromptFitContainer,
    getJustification(justify),
  );

  let ClickableComponent = ctaLink === '/business-class' ? 'a' : RouterLink;

  const isButton = !ctaLink;

  if (isButton) {
    ClickableComponent = 'button';
  }

  return (
    <ClickableComponent
      data-testid={testId}
      href={ctaLink}
      className={promptClasses}
      onClick={onClickCTA}
      {...(openInNewTab ? { target: '_blank' } : {})}
    >
      {icon !== undefined && (
        <div className={styles.upgradePromptButtonIconBackground}>
          {cloneElement(icon, {
            dangerous_className: styles.upgradePromptButtonIcon,
            size: 'small',
          })}
        </div>
      )}
      <div className={styles.upgradePromptButtonCTA}>
        {isButton && (
          <WithEnterpriseManagedOverride>{cta}</WithEnterpriseManagedOverride>
        )}

        {!isButton && cta}
      </div>
    </ClickableComponent>
  );
};

export const UpgradePromptButtonConnected: FunctionComponent<
  UpgradePromptButtonProps
> = (props) => (
  <ComponentWrapper>
    <UpgradePromptButton {...props} />
  </ComponentWrapper>
);
