import type { FunctionComponent } from 'react';

import {
  ChunkLoadErrorBoundary,
  ErrorBoundary,
} from '@trello/error-boundaries';
import { DeferredSuspense, useLazyComponent } from '@trello/use-lazy-component';

import type { NewCommentProps } from './NewComment';

export const LazyNewComment: FunctionComponent<NewCommentProps> = (props) => {
  const NewComment = useLazyComponent(
    () => import(/* webpackChunkName: "new-comment" */ './NewComment'),
    { namedImport: 'NewComment' },
  );

  return (
    <ErrorBoundary
      tags={{ ownershipArea: 'trello-web-eng', feature: 'Comments' }}
    >
      <DeferredSuspense fallback={null}>
        <ChunkLoadErrorBoundary fallback={null}>
          <NewComment {...props} />
        </ChunkLoadErrorBoundary>
      </DeferredSuspense>
    </ErrorBoundary>
  );
};
