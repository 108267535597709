// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.kioi3MuYy16w0o {
  padding: 16px;
  border: 1px solid var(--ds-border-input, #091e4224);
  border-radius: 3px;
  background: var(--ds-background-input, #ffffff);
}
`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"loading": `kioi3MuYy16w0o`
};
export default ___CSS_LOADER_EXPORT___;
