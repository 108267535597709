// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.oZE_M7pq5ZY9BJ {
  border-radius: 3px;
  background-color: var(--ds-surface, #ffffff);
  background-repeat: no-repeat;
  cursor: progress;
  background-origin: border-box;
  animation-name: ZrsaKG4ndrgEkK;
  animation-duration: 1.5s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  width: 100%;
  height: 36px;
  margin: 8px 0;
}
@keyframes ZrsaKG4ndrgEkK {
  from {
    background-color: var(--ds-skeleton, #091e420f);
  }
  to {
    background-color: var(--ds-skeleton-subtle, #091e4208);
  }
}
@media (prefers-reduced-motion: reduce) {
  .oZE_M7pq5ZY9BJ {
    animation: none;
  }
}
.oZE_M7pq5ZY9BJ:last-child {
  margin-bottom: 12px;
}
.gA7DvcbHG7KRq0 {
  border-radius: 3px;
  background-color: var(--ds-surface, #ffffff);
  background-repeat: no-repeat;
  cursor: progress;
  background-origin: border-box;
  animation-name: ZrsaKG4ndrgEkK;
  animation-duration: 1.5s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  width: 100%;
  height: 32px;
  margin: 12px 0 6px;
}
@keyframes ZrsaKG4ndrgEkK {
  from {
    background-color: var(--ds-skeleton, #091e420f);
  }
  to {
    background-color: var(--ds-skeleton-subtle, #091e4208);
  }
}
@media (prefers-reduced-motion: reduce) {
  .gA7DvcbHG7KRq0 {
    animation: none;
  }
}
`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"row": `oZE_M7pq5ZY9BJ`,
	"shimmer": `ZrsaKG4ndrgEkK`,
	"removeColorButton": `gA7DvcbHG7KRq0`
};
export default ___CSS_LOADER_EXPORT___;
