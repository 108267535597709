import type { Plugin } from 'app/scripts/models/Plugin';

export function filterUnavailablePlugins(
  plugins: Plugin[],
  options = { filterDeprecated: true },
) {
  return plugins.filter((plugin) => {
    if (['moderated', 'hidden'].includes(plugin.get('moderatedState'))) {
      return false;
    }

    if (options.filterDeprecated) {
      return !plugin.get('deprecation')?.sunsetDate;
    }

    return true;
  });
}
