import type { CardAppearance as SmartCardAppearance } from '@atlaskit/smart-card';

/*
 * Takes a string and verfies that it begins with the "smartCard-" text, then
 * returns a valid Smart Card appearance value, falling back to "inline". Used
 * to render the various smartCard types in card descriptions and in Editor.
 */
export const getSmartCardAppearanceFromTitle = (
  title?: string,
): SmartCardAppearance | null => {
  if (!title) {
    return 'inline';
  }
  const validAppearances = ['inline', 'block', 'embed'];
  const [type, appearance] = title.split('-');
  if (type === 'smartCard') {
    if (validAppearances.includes(appearance)) {
      return appearance as SmartCardAppearance;
    }
    return 'inline' as SmartCardAppearance;
  }
  return null;
};
