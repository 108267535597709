// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */

import { teacupWithHelpers } from 'app/scripts/views/internal/teacupWithHelpers';

const t = teacupWithHelpers('board');

export const BoardTemplate = () => {
  t.div('.board-main-content', function () {
    t.div(`.board-header.u-clearfix.js-board-header`);
    t.div('.board-warnings.u-clearfix.js-board-warnings');
    t.div('.board-canvas', function () {
      t.div('.js-board-view-container');
      t.div('.js-list-container');
      t.div('.js-about-this-board-container');
      t.div('.js-confirm-email-modal');
    });
    t.div('.js-board-labels-popover-container');
  });

  return t.div('.board-menu.js-fill-board-menu');
};
