// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.FQxo4h35wlOlfG {
  display: flex;
  width: 304px;
  cursor: pointer;
}
.JhfWmT9Vdqn5n4 {
  width: 100%;
  margin: 16px 0 0 0;
  cursor: default;
}
.I2W3IUMAh67fAN {
  justify-self: start;
  width: max-content;
  text-decoration: none;
}
.PW8GjCYBnWbVTp {
  min-width: 64px;
  height: 24px;
  margin: 0;
  padding: 4px 8px;
  border-radius: 3px;
  font-weight: normal;
  line-height: 16px;
}
`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"powerUpItem": `FQxo4h35wlOlfG`,
	"listingPowerUpItem": `JhfWmT9Vdqn5n4`,
	"buttonAnchor": `I2W3IUMAh67fAN`,
	"addButton": `PW8GjCYBnWbVTp`
};
export default ___CSS_LOADER_EXPORT___;
