import * as Types from '@trello/graphql/generated';

import DocumentNode from '@trello/graphql/src/documentNode';
import * as Apollo from '@apollo/client';
export type IsTemplateBoardFragment = (
  { __typename: 'Board' }
  & Pick<Types.Board, 'premiumFeatures'>
  & { prefs?: Types.Maybe<(
    { __typename: 'Board_Prefs' }
    & Pick<Types.Board_Prefs, 'isTemplate' | 'permissionLevel'>
  )> }
);

export const IsTemplateBoardFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'IsTemplateBoard' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Board' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'prefs' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'isTemplate' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'permissionLevel' },
                },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'premiumFeatures' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

interface UseIsTemplateBoardFragmentOptions
  extends Omit<
    Apollo.UseFragmentOptions<
      IsTemplateBoardFragment,
      Apollo.OperationVariables
    >,
    'fragment' | 'fragmentName' | 'from'
  > {
  from: Apollo.StoreObject | Apollo.Reference;
  returnPartialData?: boolean;
}

interface UseIsTemplateBoardFragmentResult
  extends Omit<Apollo.UseFragmentResult<IsTemplateBoardFragment>, 'data'> {
  data?: IsTemplateBoardFragment;
}

export const useIsTemplateBoardFragment = ({
  from,
  returnPartialData,
  ...options
}: UseIsTemplateBoardFragmentOptions): UseIsTemplateBoardFragmentResult => {
  const result = Apollo.useFragment<IsTemplateBoardFragment>({
    ...options,
    fragment: IsTemplateBoardFragmentDoc,
    fragmentName: 'IsTemplateBoard',
    from: {
      __typename: 'Board',
      ...from,
    },
  });

  // Ensure that the fragment result is not typed as a DeepPartial.
  if (!result.complete && !returnPartialData) {
    return {
      ...result,
      data: undefined,
    };
  }

  return { ...result, data: result.data as IsTemplateBoardFragment };
};
