import { useEffect, useState } from 'react';

import { useFeatureFlag } from '@trello/feature-flag-client';
import { useFeatureGate } from '@trello/feature-gate-client';

import { isNativeCurrentBoardInfoEnabled } from './isNativeCurrentBoardInfoEnabled';

const featureGateName = 'trello_native_graphql_migration_milestone_1';
const defaultGateValue = false;

/**
 * Custom hook to check if the feature gate `'trello_native_graphql_migration_milestone_1'`
 * and its prerequisites are enabled.
 * @returns true if the feature gate is enabled, false otherwise.
 */
export const useNativeGraphqlMigrationMilestone1 = () => {
  const [isFeatureEnabled, setIsFeatureEnabled] = useState(false);
  const quickloadFlagEnabled = isNativeCurrentBoardInfoEnabled();
  // eslint-disable-next-line @trello/no-feature-flag
  const subscriptionsFlagEnabled = useFeatureFlag<boolean>(
    'gp.client-subscriptions',
    false,
  );
  const milestoneGateEnabled = useFeatureGate(featureGateName);
  useEffect(() => {
    setIsFeatureEnabled(
      (quickloadFlagEnabled &&
        subscriptionsFlagEnabled &&
        milestoneGateEnabled.value) ??
        defaultGateValue,
    );
  }, [
    milestoneGateEnabled.value,
    quickloadFlagEnabled,
    subscriptionsFlagEnabled,
  ]);

  return isFeatureEnabled;
};
