/* eslint-disable
    eqeqeq,
*/
// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * DS104: Avoid inline assignments
 * DS204: Change includes calls to have a more natural evaluation order
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */

import Promise from 'bluebird';
import _ from 'underscore';
// eslint-disable-next-line no-restricted-imports
import parseURL from 'url-parse';

import { isDesktop, isTouch as isTouchFn } from '@trello/browser';
import { bytes } from '@trello/strings';
import { isTrelloAttachmentUrl } from '@trello/urls';

import { KnownServices } from 'app/scripts/db/known-services';
import { l } from 'app/scripts/lib/localize';
import { tryBabble } from 'app/scripts/lib/try-babble';
import { Util } from 'app/scripts/lib/util';
import type { Attachment } from 'app/scripts/models/Attachment';

export const Helpers = {
  getAttachmentData(attachment: Attachment) {
    const attachmentName = attachment.get('name');
    const attachmentUrl = attachment.get('url');
    const serviceKey = attachment.getServiceKey();

    let promise;
    if (serviceKey === 'trello') {
      // Explicitly don't send trello attachments through the known services
      // interpretation - we already generate previews for these, and don't
      // want to send them through a platform service.
      promise = Promise.resolve();
    } else {
      promise = KnownServices.interpret(attachmentUrl, attachment.modelCache);
    }

    return (
      promise
        // @ts-expect-error TS(2349): This expression is not callable.
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .then((known: any) => {
          if (known != null) {
            let name = attachmentName || known.text;
            if (name === attachmentUrl) {
              name = known.text;
            }
            return {
              isKnownService: true,
              meta: known.name,
              previewClass: known.previewClass,
              name,
              type: known.type,
            };
          }

          switch (serviceKey) {
            case 'trello':
              return {
                meta: bytes(attachment.get('bytes')),
                openText: l(
                  isDesktop()
                    ? 'attachments.trello.download'
                    : 'attachments.trello.open',
                ),
                openIconClass: isDesktop() ? 'download' : 'external-link',
                isExternal: false,
              };
            case 'gdrive':
              return {
                meta: tryBabble(['mime', attachment.get('mimeType')]),
                previewClass: 'attachment-thumbnail-preview-google-drive-logo',
              };
            case 'dropbox':
              return {
                previewClass: 'attachment-thumbnail-preview-dropbox-logo',
              };
            case 'onedrive':
              return {
                previewClass: 'attachment-thumbnail-preview-one-drive-logo',
              };
            case 'box':
              return { previewClass: 'attachment-thumbnail-preview-box-logo' };
            case 'other':
              return { ext: 'LINK' };
            default:
              return {};
          }
        })
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        .then(function (thumbnailData: any) {
          const data = {
            isKnownService: false,
            isExternal: true,
            openIconClass: 'external-link',
            ...attachment.toJSON(),
            ...thumbnailData,
          };

          // Only try to evaluate these if they haven't already been set
          if (data.meta == null) {
            data.meta = l(['attachments', serviceKey, 'type']);
          }
          if (data.openText == null) {
            data.openText = l(['attachments', serviceKey, 'open']);
          }
          if (data.removeText == null) {
            data.removeText = l(['attachments', serviceKey, 'remove']);
          }

          if (!data.name) {
            data.name = data.url;
          }

          if (data.ext == null) {
            data.ext = Util.fileExt(parseURL(attachmentUrl).pathname);
            // we probably guessed wrong in this case
            if (data.ext != null && data.ext.length > 6) {
              data.ext = null;
            }
          }

          return data;
        })
    );
  },

  // @ts-expect-error TS(7023): 'availableExts' implicitly has return type 'any' b... Remove this comment to see the full error message
  availableExts() {
    // @ts-expect-error TS(2551): Property '_availableExts' does not exist on type '... Remove this comment to see the full error message
    if (this._availableExts == null) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      let exts: any = [];
      for (const mimetype of Array.from(navigator.mimeTypes)) {
        exts = exts.concat(mimetype.suffixes.split(','));
      }

      // @ts-expect-error TS(2551): Property '_availableExts' does not exist on type '... Remove this comment to see the full error message
      this._availableExts = _.compact(_.uniq(exts));
    }

    // @ts-expect-error TS(2551): Property '_availableExts' does not exist on type '... Remove this comment to see the full error message
    return this._availableExts;
  },

  imageExts() {
    return ['bmp', 'gif', 'jpeg', 'jpg', 'png', 'webp'];
  },

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getPlayableExts(codecs: any, mediaType: any) {
    // You can pass a mime type to canPlayType on a audio or video element to
    // detect if it can be played. http://stackoverflow.com/questions/7451635

    const testEl = document.createElement(mediaType);

    return _.chain(codecs)
      .filter(({ types }) => _.any(types, (t) => '' !== testEl.canPlayType(t)))
      .pluck('exts')
      .flatten()
      .value();
  },

  // @ts-expect-error TS(7023): 'audioExts' implicitly has return type 'any' becau... Remove this comment to see the full error message
  audioExts() {
    // @ts-expect-error TS(2551): Property '_audioExts' does not exist on type '{ ge... Remove this comment to see the full error message
    if (this._audioExts) {
      // @ts-expect-error TS(2551): Property '_audioExts' does not exist on type '{ ge... Remove this comment to see the full error message
      return this._audioExts;
    }

    const codecs = [
      { exts: ['m4a'], types: ['audio/x-m4a;', 'audio/aac;'] },
      { exts: ['mp3'], types: ['audio/mpeg;'] },
      { exts: ['opus'], types: ['audio/ogg; codecs="opus"'] },
      { exts: ['ogg'], types: ['audio/ogg; codecs="vorbis"'] },
      { exts: ['wav'], types: ['audio/wav; codecs="1"'] },
    ];

    // @ts-expect-error TS(2551): Property '_audioExts' does not exist on type '{ ge... Remove this comment to see the full error message
    return (this._audioExts = this.getPlayableExts(codecs, 'audio'));
  },

  videoExts() {
    // @ts-expect-error TS(2551): Property '_videoExts' does not exist on type '{ ge... Remove this comment to see the full error message
    if (this._videoExts) {
      // @ts-expect-error TS(2551): Property '_videoExts' does not exist on type '{ ge... Remove this comment to see the full error message
      return this._videoExts;
    }

    const codecs = [
      { exts: ['mp4'], types: ['video/mp4; codecs="mp4v.20.8"'] },
      {
        exts: ['mov', 'h264'],
        types: [
          'video/mp4; codecs="avc1.42E01E"',
          'video/mp4; codecs="avc1.42E01E, mp4a.40.2"',
        ],
      },
      { exts: ['ogv'], types: ['video/ogg; codecs="theora"'] },
      { exts: ['webm'], types: ['video/webm; codecs="vp8, vorbis"'] },
    ];

    // @ts-expect-error TS(2551): Property '_videoExts' does not exist on type '{ ge... Remove this comment to see the full error message
    return (this._videoExts = this.getPlayableExts(codecs, 'video'));
  },

  googleViewerableExts() {
    // https://support.google.com/drive/answer/2423485?p=docs_viewer&rd=1
    const defaultExts = ['doc', 'docx', 'ppt', 'pptx', 'rtf', 'xls', 'xlsx'];

    // if you can't natively view pdfs, show them in google viewer.

    if (!Array.from(this.availableExts()).includes('pdf')) {
      defaultExts.push('pdf');
    }

    // @ts-expect-error TS(2551): Property '_googleViewerableExts' does not exist on... Remove this comment to see the full error message
    return this._googleViewerableExts != null
      ? // @ts-expect-error TS(2551): Property '_googleViewerableExts' does not exist on... Remove this comment to see the full error message
        this._googleViewerableExts
      : // @ts-expect-error TS(2551): Property '_googleViewerableExts' does not exist on... Remove this comment to see the full error message
        (this._googleViewerableExts = defaultExts);
  },

  iFrameableExts() {
    const defaultExts = ['css', 'js', 'txt'];

    // NOTE: We know that using <iframe src="foo.pdf"></iframe> will possibly allow
    // the PDF to run some limited script.  The script runs on a different origin, so
    // it's not really a terrible risk.  There is the possibility that the PDF will
    // display an alert, which is maybe annoying.  (Because we load the whole carosel
    // the script may get run even if the user is previewing another attachment)
    //
    // We attempted to address this by always using Google's viewer, but that resulted
    // in a lot of PDFs being unviewable for unknown reasons (possibly because they
    // were too large, or too complicated) and caused more of a support problem than
    // the theoretical security issue it was trying to fix.
    //
    // We may be able to resolve this someday by using the atlassian media viewer
    // @ts-expect-error TS(2551): Property '_iFrameableExts' does not exist on type ... Remove this comment to see the full error message
    return this._iFrameableExts != null ? this._iFrameableExts : defaultExts;
  },

  allViewerableExts({ excludeGoogleExts = false } = {}) {
    return [
      ...this.imageExts(),
      ...this.audioExts(),
      ...this.videoExts(),
      ...(excludeGoogleExts ? [] : this.googleViewerableExts()),
      ...this.iFrameableExts(),
    ];
  },

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  isViewerable(url: any) {
    let pathname;
    try {
      pathname = new URL(url).pathname;
    } catch (ignored) {
      // If we aren't able to parse the URL, it isn't viewerable
      return false;
    }

    const fileExtension = Util.fileExt(pathname);

    if (fileExtension === 'pdf') {
      // We are doubling down on some checks, but that is to make sure that
      // future developments will take into consideration the original conditions
      return this.isViewerablePDF(url);
    }

    const isTouch = isTouchFn();

    const isAuthAttachmentLink =
      /^\/1\/cards\/[a-f0-9]{24}\/attachments\/[a-f0-9]{24}\/download\/.*/.test(
        pathname,
      );

    const isViewerableExtension = this.allViewerableExts({
      excludeGoogleExts: isAuthAttachmentLink,
    }).includes(fileExtension);

    const _isTrelloAttachmentUrl = isTrelloAttachmentUrl(url);

    return !isTouch && _isTrelloAttachmentUrl && isViewerableExtension;
  },

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  isViewerablePDF(url: any) {
    if (isTouchFn()) {
      return false;
    }

    if (!isTrelloAttachmentUrl(url)) {
      return false;
    }

    let pathname;

    try {
      pathname = new URL(url).pathname;
    } catch (ignored) {
      // If we aren't able to parse the URL, it isn't viewerable

      return false;
    }
    return Util.fileExt(pathname) === 'pdf';
  },

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  stripAndShortenName(name: any) {
    let nameShortened = false;
    let newName = name.replace(/\s+/g, ' ').trim();
    if (newName.length > 256) {
      newName = newName.substring(0, 256);
      nameShortened = true;
    }
    return { newName, nameShortened };
  },

  validateUrlAndName(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    url: any,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    name: any,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    originalUrl: any,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    originalName: any,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    type: any,
  ) {
    let canUpdate = false;
    let newName = originalName;

    // case of no protocol in url from edit attachment input
    const parsed = parseURL(url, {});
    if (!parsed.protocol) {
      url = parsed.set('protocol', 'http').href;
    }

    if (type === 'other') {
      if (parsed.hostname && url.length > 0) {
        if (name.length === 0) {
          newName = url;
        } else {
          if (originalUrl === originalName && originalName === name) {
            newName = url;
          } else {
            newName = name;
          }
        }
        canUpdate = true;
      }
    } else {
      if (name.length > 0) {
        newName = name;
        canUpdate = true;
      }
    }

    return { newName, canUpdate, newUrl: url };
  },
};
