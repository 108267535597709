import { Analytics } from '@trello/atlassian-analytics';
import { markdown } from '@trello/markdown';
import type { PIIString } from '@trello/privacy';

import { Auth } from 'app/scripts/db/Auth';
import { ModelCache } from 'app/scripts/db/ModelCache';
import type { Action } from 'app/scripts/models/Action';
import type { Attachment } from 'app/scripts/models/Attachment';
import type { Card } from 'app/scripts/models/Card';
import { newCommentState } from './newCommentState';

const updateComment = (value: string) =>
  newCommentState.setValue(({ defaultValue }) => ({
    defaultValue,
    imperativeValue: `${defaultValue} ${value} `.trimStart(),
    isFocused: true,
  }));

const focusComment = () => newCommentState.setValue({ isFocused: true });

const trackMemberMentionedEvent = (mentionCount: number) => {
  Analytics.sendTrackEvent({
    action: 'mentioned',
    actionSubject: 'member',
    attributes: {
      mentionCount,
      method: 'reply',
    },
    source: 'cardDetailScreen',
  });
};

const attachmentAsMarkdown = (attachment: Attachment) =>
  `[${attachment.get('name')}](${attachment.get('url')})`;

interface ActionData {
  action: Action;
  card: Card;
}

function replyToAction({ action, card }: ActionData) {
  const { defaultValue } = newCommentState.value;
  const memberCreator = ModelCache.get('Member', action.get('idMemberCreator'));
  const username = memberCreator?.get('username');

  if (!memberCreator || !username) return;

  if (action.isCommentLike() && username) {
    const regex = new RegExp(`@${username}(\\W|$)`);
    // If the comment already mentions the reply target, don't modify it;
    // the user may have just clicked "Reply" twice.
    if (regex.test(defaultValue)) {
      focusComment();
    } else {
      updateComment(`@${username}`);
      trackMemberMentionedEvent(1);
    }
    return;
  }

  // Actions can also be attachments, which are somehow different from the
  // 'attachment' reply type. This one can prepend the reply with a username.
  // I'm not sure why the distinction exists.
  const idAttachment = action.get('data').attachment?.id;
  if (action.isAddAttachment() && idAttachment) {
    // Something to look into: do we NEED the card model here? It seems like we
    // use it to restrict the markdown to attachments that are attached to the
    // card. If we enable inline images in comments, is it possible that we
    // could get rid of this and simply use the attachment in the action?
    const attachment: Attachment = card.attachmentList.get(idAttachment);
    if (!attachment) return;

    const attachmentMarkdown = attachmentAsMarkdown(attachment);
    if (Auth.isMe(memberCreator.id)) {
      updateComment(attachmentMarkdown);
    } else {
      updateComment(`@${username} ${attachmentMarkdown}`);
      trackMemberMentionedEvent(1);
    }
    return;
  }
}

function replyToAll(action: Action) {
  const { defaultValue } = newCommentState.value;
  const memberCreator = ModelCache.get('Member', action.get('idMemberCreator'));
  const username = memberCreator?.get('username');

  if (!memberCreator || !username) return;

  const getMentions = (text: string): string[] =>
    // @ts-expect-error
    markdown.comments.getMatches(text, { atMention: true }).atMention;

  const normalizeMention = (mention: string) =>
    mention.replace('@', '').toLowerCase();

  const mentionsToExclude = new Set<string | PIIString>([
    'card',
    'board',
    Auth.myUsername(),
    ...getMentions(defaultValue).map(normalizeMention),
  ]);
  const mentionsToAdd = [
    `@${username}`,
    ...getMentions(action.get('data').text),
  ].filter((_mention) => {
    const mention = normalizeMention(_mention);
    if (mentionsToExclude.has(mention)) {
      return false;
    }
    mentionsToExclude.add(mention);
    return true;
  });

  if (mentionsToAdd.length) {
    updateComment(mentionsToAdd.join(' '));
    trackMemberMentionedEvent(mentionsToAdd.length);
  } else {
    focusComment();
  }
  return;
}

// For convenience, export a single replyToComment function with overloads.
function replyToComment(type: 'action', data: ActionData): void;
function replyToComment(type: 'all', action: Action): void;
function replyToComment(type: 'attachment', attachment: Attachment): void;
function replyToComment(type: 'username', username: string): void;

/**
 * A reply can be triggered from any of five paths, with the following outcomes:
 *
 * - Reply to a comment without mentions: mention the commenter
 * - Reply to a comment with mentions: mention the commenter and the mentions
 * - Reply to an attachment action: mention the attacher and the attachment
 * - Reply to an attachment: mention the attachment
 * - Reply to a specific person via query param: mention the username
 *
 * A lot of the logic for determining which reply "type" to use is done in
 * archaic Backbone world, so we're kind of just doing our best to fulfill
 * the contract for each of these flows in the rewrite.
 *
 * In all cases, we can insert text directly into the new comment shared state.
 * This will also focus the text input, causing the editor to render.
 */
function replyToComment(
  type: 'action' | 'all' | 'attachment' | 'username',
  data: unknown,
) {
  try {
    switch (type) {
      // The 'action' type is used to reply to most actions, including comments.
      case 'action': {
        replyToAction(data as { action: Action; card: Card });
        return;
      }
      // The 'all' type is used to reply to a comment with existing mentions.
      // It tries to parse all viable mentions to include in the reply.
      case 'all': {
        replyToAll(data as Action);
        return;
      }
      // The 'attachment' type is used to reply directly to an attachment.
      case 'attachment': {
        updateComment(attachmentAsMarkdown(data as Attachment));
        return;
      }
      // Replies can also be triggered directly via query parameter.
      // URLs look like `/c/:id/:slug?replyToComment=<username>`.
      case 'username': {
        const username = data as string;
        // When coming directly from query param, clear the draft and set the new
        // comment value directly.
        newCommentState.setValue({
          imperativeValue: `@${username} `,
          isFocused: true,
        });
        trackMemberMentionedEvent(1);
        return;
      }
      default: {
        throw new Error('Unknown reply type.');
      }
    }
  } catch (e) {
    // If we can't populate the comment for whatever reason, just focus the
    // Editor anyway. You can write your reply by hand. It's not a big deal.
    focusComment();
  }
}

export { replyToComment };
