import type { Board, Member } from '@trello/model-types';

import { isMemberOfOrganization } from '../organization/isMemberOfOrganization';
import { isPremOrganizationAdmin } from '../organization/isPremOrganizationAdmin';
import { allowsSelfJoin } from './allowsSelfJoin';
import { isMemberTypeOfBoard } from './isMemberTypeOfBoard';

export const isEditableByOrganizationMember = (
  member: Pick<
    Member,
    'id' | 'idPremOrgsAdmin' | 'idEnterprisesAdmin' | 'memberType'
  >,
  board: {
    organization?:
      | (Pick<Board['organization'], 'id' | 'offering'> & {
          memberships: Pick<
            Board['organization']['memberships'][number],
            'id' | 'idMember' | 'memberType' | 'deactivated' | 'unconfirmed'
          >[];
          enterprise?: {
            id?: Board['organization']['enterprise']['id'];
            idAdmins?: Board['organization']['enterprise']['idAdmins'];
          } | null;
        })
      | null;
    prefs?: Pick<Board['prefs'], 'selfJoin' | 'isTemplate'> | null;
    memberships: Pick<
      Board['memberships'][number],
      'id' | 'idMember' | 'memberType' | 'deactivated' | 'unconfirmed'
    >[];
  },
  organization: Parameters<typeof isMemberOfOrganization>[1] | null,
) => {
  if (!organization) {
    return false;
  }

  if (isMemberTypeOfBoard(member, board, 'observer')) {
    return false;
  }

  const isOrgMember = isMemberOfOrganization(member, organization);
  return (
    isPremOrganizationAdmin(member, organization.id) ||
    (allowsSelfJoin(board) && isOrgMember)
  );
};
