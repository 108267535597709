import { useNonPpmFreeMultiplayerFragment } from '../NonPpmFreeMultiplayerFragment.generated';
import type { RuntimeEligibilityCheckResult } from './RuntimeEligibilityCheck';

export const useIsMultiplayerUser = (
  boardId?: string,
): RuntimeEligibilityCheckResult => {
  const { data: boardData } = useNonPpmFreeMultiplayerFragment({
    from: { id: boardId },
    optimistic: false,
  });

  const workspaceMembers = boardData?.organization?.members;

  // Users is in a workspace with other guests/members
  const isMultiplayerUser =
    (workspaceMembers &&
      workspaceMembers.length > 1 &&
      workspaceMembers.length < 10) ??
    false;

  return {
    isEligible: isMultiplayerUser,
  };
};
