import { useCallback, useEffect, useMemo, useState } from 'react';

import { useCrossFlow } from '@atlassiansox/cross-flow-support';
import {
  type TouchpointSourceType,
  useAdSequencer,
} from '@trello/ad-sequencer';
import { useNextAdSource } from '@trello/ad-sequencer';
import { Analytics } from '@trello/atlassian-analytics';
import {
  getUserTraits,
  TRELLO_UI_DISCOVERY_AD_DISPLAYED_LATEST_AD_EXPERIENCE,
} from '@trello/atlassian-personalization';
import { useMemberId } from '@trello/authentication';
import { isDesktop } from '@trello/browser';
import { useOneTimeMessagesDismissed } from '@trello/business-logic-react/member';
import { getDateBefore, idToDate } from '@trello/dates';
import { Entitlements } from '@trello/entitlements';
import type { ExperimentVariations } from '@trello/feature-gate-client';
import { useGetExperimentValue } from '@trello/feature-gate-client';

import { getAccessibleProducts } from '../getAccessibleProducts';
import {
  type TrelloCrossFlowExperiment,
  trelloCrossFlowOpen,
} from '../TrelloCrossFlow';
import { useGraduatedUserFragment } from './GraduatedUserFragment.generated';
import {
  featureTouchpoints,
  isSourceTargetedByFeatureKey,
} from './isSourceTargetedByFeatureFlag';
import { useEligibleWorkspacesForProvisioning } from './useEligibleWorkspacesForProvisioning';

const oneTimeMessageSource: Partial<Record<TouchpointSourceType, string>> = {
  memberHomeWorkspaceSidebarSection: '1p-discovery-ad-left-hand-nav-board-home',
  createBoardInlineDialog: '1p-discovery-ad-left-hand-nav-board-create',
  cardBackDatePicker: '1p-discovery-ad-card-date-range-picker',
  boardDirectory: '1p-discovery-ad-powerup-directory',
};

const experimentFeatureKey = 'trello_xf_graduated_new_users_with_ad_sequencer';

export type GraduatedUsersWithAdSequencerExperimentVariations =
  ExperimentVariations<typeof experimentFeatureKey, 'cohort'>;

export interface CrossFlowForGraduatedUsersWithAdSequencerExperimentParams {
  source: TouchpointSourceType;
  workspaceId?: string;
  boardId?: string;
  hasValidAaSession: boolean;
}

export const useCrossFlowForGraduatedUsersWithAdSequencer = ({
  source,
  workspaceId,
  hasValidAaSession,
}: CrossFlowForGraduatedUsersWithAdSequencerExperimentParams): TrelloCrossFlowExperiment<GraduatedUsersWithAdSequencerExperimentVariations> => {
  const crossFlow = useCrossFlow();
  const [fireExposureEvent, setFireExposureEvent] = useState(false);
  const { cohort } = useGetExperimentValue({
    experimentName: experimentFeatureKey,
    parameter: 'cohort',
    fireExposureEvent,
  });
  const memberId = useMemberId();
  const { data: memberData } = useGraduatedUserFragment({
    from: { id: memberId },
  });

  const signedUpMoreThan21DaysAgo =
    idToDate(memberId) < getDateBefore({ days: 21 });

  const isWeb = !isDesktop();

  const eligibleWorkspaceOptions = useEligibleWorkspacesForProvisioning({
    workspaceId,
    entitlementRequired: 'free',
  });

  const workspaceHasLessThanTenUsers = useMemo(() => {
    if (!memberData?.organizations) {
      return false;
    }

    return memberData?.organizations.every((organization) => {
      return (
        organization.offering &&
        Entitlements.isFree(organization.offering) &&
        organization.memberships.length > 0 &&
        organization.memberships.length < 10
      );
    });
  }, [memberData?.organizations]);

  const [loadingAvailableSites, setLoadingAvailableSites] = useState(false);
  const [hasNoOtherAtlassianProducts, setHasNoOtherAtlassianProducts] =
    useState(false);
  const [hasNotSeenOtherAds, setHasNotSeenOtherAds] = useState(false);

  const { isOneTimeMessageDismissed } = useOneTimeMessagesDismissed();
  const isSourceOneTimeMessageDismissed = useCallback(
    () => isOneTimeMessageDismissed(oneTimeMessageSource?.[source] || ''),
    [source, isOneTimeMessageDismissed],
  );

  const [, setNextAdSource] = useNextAdSource();
  const { getNextAd } = useAdSequencer({
    experience: experimentFeatureKey,
    adTouchpointSources: featureTouchpoints[experimentFeatureKey],
    adRotationIntervalInDays: 3,
  });

  /**
   * Eligibility criteria:
   * - Signed up more than 21 days ago
   * - Web MAU
   * - No other Atlassian products
   * - Not paid workspace
   * - Not > 10 users in a workspace
   * - Has not seen other ads except for new user onboarding and
   *   new user nudge ads
   */
  const isRuntimeEligibilityChecksPassed = useMemo(
    () =>
      signedUpMoreThan21DaysAgo &&
      isWeb &&
      hasNoOtherAtlassianProducts &&
      eligibleWorkspaceOptions.length > 0 &&
      workspaceHasLessThanTenUsers &&
      hasNotSeenOtherAds &&
      isSourceTargetedByFeatureKey({
        featureKey: experimentFeatureKey,
        source,
      }) &&
      !isSourceOneTimeMessageDismissed(),
    [
      signedUpMoreThan21DaysAgo,
      isWeb,
      hasNoOtherAtlassianProducts,
      eligibleWorkspaceOptions.length,
      workspaceHasLessThanTenUsers,
      hasNotSeenOtherAds,
      source,
      isSourceOneTimeMessageDismissed,
    ],
  );

  const getIsTargeted = useCallback(() => {
    return cohort !== 'not-enrolled';
  }, [cohort]);

  const getExperimentCohort = useCallback(() => {
    if (isRuntimeEligibilityChecksPassed) {
      setFireExposureEvent(true);

      if (cohort === 'experiment') {
        Analytics.sendUIEvent({
          action: 'triggered',
          actionSubject: 'adSequencer',
          source,
        });
      }

      return cohort;
    }
    return 'not-enrolled';
  }, [cohort, isRuntimeEligibilityChecksPassed, source]);

  useEffect(() => {
    const loadSiteEligibility = async () => {
      try {
        setLoadingAvailableSites(true);
        const { products } = await getAccessibleProducts();
        const hasNoSites =
          products?.length > 0 &&
          products?.filter(({ productId }) => productId !== 'trello').length ===
            0;
        setHasNoOtherAtlassianProducts(hasNoSites);
      } finally {
        setLoadingAvailableSites(false);
      }
    };

    if (
      !loadingAvailableSites &&
      crossFlow.isEnabled &&
      isWeb &&
      getIsTargeted() &&
      isSourceTargetedByFeatureKey({
        featureKey: experimentFeatureKey,
        source,
      })
    ) {
      loadSiteEligibility();
    }
    // Ignore loadingAvailableSites dependency
    // eslint-disable-next-line @trello/exhaustive-deps
  }, [source, workspaceId, crossFlow.isEnabled, getIsTargeted, isWeb]);

  useEffect(() => {
    if (!hasValidAaSession || !getIsTargeted() || !memberData?.aaId) {
      return;
    }

    const checkUserHasNotSeenOtherAds = async (aaId: string) => {
      try {
        const traits = await getUserTraits(aaId);

        const lastAdSeenExperience: string | undefined = traits?.[
          TRELLO_UI_DISCOVERY_AD_DISPLAYED_LATEST_AD_EXPERIENCE
        ]
          ? String(
              traits[TRELLO_UI_DISCOVERY_AD_DISPLAYED_LATEST_AD_EXPERIENCE],
            )
          : undefined;
        if (
          !lastAdSeenExperience ||
          [
            'trello_xf_1p_ads_in_madshot_v2',
            'trello_jwm_crossflow_nudge_after_madshot',
            'trello_xf_graduated_new_users_with_ad_sequencer',
          ].includes(lastAdSeenExperience)
        ) {
          setHasNotSeenOtherAds(true);
        }
      } catch (e) {
        // we couldn't retrieve traits to determine whether the user saw other
        // ads so return early.

        Analytics.sendOperationalEvent({
          action: 'errored',
          actionSubject: 'getNextAd',
          source: '@trello/ad-sequencer',
          attributes: {
            errorMessage: (e as Error).message,
          },
        });

        return;
      }
    };

    checkUserHasNotSeenOtherAds(memberData.aaId);
  }, [hasValidAaSession, memberData?.aaId, getIsTargeted]);

  useEffect(() => {
    if (!hasValidAaSession || !getIsTargeted()) {
      return;
    }

    const loadNextAd = async () => {
      if (getExperimentCohort() !== 'not-enrolled') {
        if (getExperimentCohort() === 'experiment') {
          const nextAd = await getNextAd();
          setNextAdSource({
            experience: experimentFeatureKey,
            source: nextAd,
          });
        } else {
          setNextAdSource({
            experience: experimentFeatureKey,
            source,
          });
        }
      }
    };

    loadNextAd();
  }, [
    hasValidAaSession,
    cohort,
    getNextAd,
    source,
    setNextAdSource,
    getExperimentCohort,
    getIsTargeted,
  ]);

  return useMemo(
    () => ({
      isEnrolled: isRuntimeEligibilityChecksPassed,
      experimentName: experimentFeatureKey,
      getIsTargeted,
      getExperimentCohort,
      open: trelloCrossFlowOpen(
        crossFlow,
        source,
        eligibleWorkspaceOptions,
        hasValidAaSession,
      ),
    }),
    [
      crossFlow,
      eligibleWorkspaceOptions,
      getExperimentCohort,
      getIsTargeted,
      hasValidAaSession,
      isRuntimeEligibilityChecksPassed,
      source,
    ],
  );
};
