/* eslint-disable
    eqeqeq,
*/
// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
// eslint-disable-next-line no-restricted-imports
import $ from '@trello/jquery';

import { controllerEvents } from 'app/scripts/controller/controllerEvents';
import { AttachmentViewerView } from 'app/scripts/views/attachment/AttachmentViewerView';

export class AttachmentViewer {
  static clear = () => {
    // @ts-expect-error TS(2339): Property 'off' does not exist on type 'ControllerE... Remove this comment to see the full error message
    controllerEvents.off('clearAttachmentViewer', this.clear);
    if (this.isActive()) {
      // @ts-expect-error TS(2339): Property 'viewer' does not exist on type 'typeof A... Remove this comment to see the full error message
      this.viewer.$el.off('remove.attachment-viewer', this._handleViewRemove);
      // @ts-expect-error TS(2339): Property 'viewer' does not exist on type 'typeof A... Remove this comment to see the full error message
      this.viewer.close();
      // @ts-expect-error TS(2339): Property 'viewer' does not exist on type 'typeof A... Remove this comment to see the full error message
      this.viewer = null;
    }
  };
  static isActive = () => {
    // @ts-expect-error TS(2339): Property 'viewer' does not exist on type 'typeof A... Remove this comment to see the full error message
    return this.viewer != null;
  };
  static _handleViewRemove = () => {
    // @ts-expect-error TS(2339): Property 'viewer' does not exist on type 'typeof A... Remove this comment to see the full error message
    return (this.viewer = null);
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  static show = (options: any) => {
    this.clear();
    // @ts-expect-error TS(2769): No overload matches this call.
    controllerEvents.on('clearAttachmentViewer', this.clear);

    // @ts-expect-error TS(2339): Property 'viewer' does not exist on type 'typeof A... Remove this comment to see the full error message
    this.viewer = new AttachmentViewerView(options);
    // The attachment viewer may remove itself from the DOM
    // @ts-expect-error TS(2339): Property 'viewer' does not exist on type 'typeof A... Remove this comment to see the full error message
    this.viewer.$el.on(
      'remove.attachment-viewer',
      this._handleViewRemove.bind(this),
    );

    // The attachment viewer view will .remove() itself when it's done
    // @ts-expect-error TS(2339): Property 'viewer' does not exist on type 'typeof A... Remove this comment to see the full error message
    $('#chrome-container').append(this.viewer.render().el);
  };
}
