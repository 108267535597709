// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Xt1_WDfw3pmm6e {
  display: flex;
  position: relative;
  box-sizing: content-box;
  align-items: flex-start;
  margin-right: -12px;
  margin-left: -12px;
  padding: 24px 12px 8px 14px;
  background-image: linear-gradient(to bottom, var(--ds-background-brand-bold, #0c66e4) 0%, var(--ds-background-brand-bold, #0c66e4) 100%);
  background-repeat: no-repeat;
  background-size: 100% 82px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.pJW8DUFHgh_qNL {
  flex: 1;
  min-width: 0;
  margin-top: 8px;
  margin-left: 8px;
}
.VtV5gSQpGjYPuf {
  overflow: hidden;
  color: var(--ds-text-inverse, #ffffff);
  font-size: 16px;
  font-weight: 500;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.VtV5gSQpGjYPuf::selection {
  background: var(--ds-background-inverse-subtle, #00000029);
}
.CfwvHjigylLnTt {
  border-radius: 3px;
  background-color: var(--ds-background-accent-blue-subtle, hsla(0, 0%, 100%, 0.3));
  width: 75%;
  height: 16px;
}
.tEKZGoudPZ6X5e {
  overflow: hidden;
  color: var(--ds-text-inverse, #ffffff);
  font-size: 12px;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: flex;
  align-items: center;
  -webkit-user-select: none;
          user-select: none;
}
.tEKZGoudPZ6X5e::selection {
  background: var(--ds-background-inverse-subtle, #00000029);
}
.psxQTZBwNirnUM {
  border-radius: 3px;
  background-color: var(--ds-background-accent-blue-subtle, hsla(0, 0%, 100%, 0.3));
  width: 75%;
  height: 12px;
  margin-left: 4px;
}
.FEKasnw3f3XYG1 {
  min-width: 88px;
  min-height: 88px;
}
`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"cardTop": `Xt1_WDfw3pmm6e`,
	"profileInfo": `pJW8DUFHgh_qNL`,
	"fullName": `VtV5gSQpGjYPuf`,
	"fullNameSkeleton": `CfwvHjigylLnTt`,
	"atName": `tEKZGoudPZ6X5e`,
	"atNameSkeleton": `psxQTZBwNirnUM`,
	"avatar": `FEKasnw3f3XYG1`
};
export default ___CSS_LOADER_EXPORT___;
