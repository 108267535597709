import type { FunctionComponent } from 'react';
import { useMemo } from 'react';

import type { ProviderProps as CardProviderProps } from '@atlaskit/link-provider';
import { SmartCardProvider as LinkingPlatformSmartCardProvider } from '@atlaskit/link-provider';

export const SmartCardProvider: FunctionComponent<CardProviderProps> = ({
  children,
  ...props
}) => {
  // Using an un-memoized object for this caused some URLs to never update from
  // "pending" to "resolved". We have to memoize this even though it's a simple
  // object to avoid that bug. See: TRELP-7372
  const featureFlags = useMemo(() => {
    return {
      showHoverPreview: true,
      embedModalSize: 'large',
      enableFlexibleBlockCard: true,
      ...props.featureFlags,
    };
  }, [props.featureFlags]);

  return (
    <LinkingPlatformSmartCardProvider {...props} featureFlags={featureFlags}>
      {children}
    </LinkingPlatformSmartCardProvider>
  );
};

export type { ProviderProps as SmartCardProviderProps } from '@atlaskit/link-provider';
