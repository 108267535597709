// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.oLb9oRv0wAkdbx {
  display: inline-flex;
  margin-bottom: 0;
  padding: 4px;
  border-radius: 3px;
  font-size: 12px;
  line-height: 12px;
  text-decoration: none;
}
.HeE4llJGwguPJk {
  display: flex;
  justify-content: center;
  width: 12px;
  height: 12px;
  margin-right: 4px;
  line-height: 12px;
}
.HeE4llJGwguPJk > span {
  width: 12px;
  height: 12px;
}
.uUfmodcndJkKpb {
  background-color: var(--ds-background-discovery, #f3f0ff);
  color: var(--ds-text-subtle, #44546f);
}
.uUfmodcndJkKpb .HeE4llJGwguPJk svg {
  color: var(--ds-icon-discovery, #8270db);
}
.uUfmodcndJkKpb:hover {
  background-color: var(--ds-background-discovery-hovered, #dfd8fd);
  color: var(--ds-text-subtle, #44546f);
}
.Oc_N8XGD1zcuo7 {
  background-color: var(--ds-background-discovery-bold, #6e5dc6);
  color: var(--ds-text-inverse, #ffffff);
}
.Oc_N8XGD1zcuo7 .HeE4llJGwguPJk svg {
  color: var(--ds-icon-inverse, #ffffff);
}
.Oc_N8XGD1zcuo7:hover {
  background-color: var(--ds-background-discovery-bold-hovered, #583f68);
  color: var(--ds-text-inverse, #ffffff);
}
`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"upgradePromptPill": `oLb9oRv0wAkdbx`,
	"upgradePromptPillIcon": `HeE4llJGwguPJk`,
	"upgradeLightTheme": `uUfmodcndJkKpb`,
	"upgradeDarkTheme": `Oc_N8XGD1zcuo7`
};
export default ___CSS_LOADER_EXPORT___;
