import type { FunctionComponent } from 'react';
import { useEffect } from 'react';
import _ from 'underscore';

import { Analytics } from '@trello/atlassian-analytics';
import { forTemplate } from '@trello/legacy-i18n';

import { PowerUpItemType } from 'app/src/components/PowerUp';
import { getMarketingScreenInfo } from 'app/src/getMarketingScreenInfo';
import { Banner } from './Banner';
import { PowerUpsList } from './PowerUpsList';
import type { Plugin, Plugins } from './types';

const format = forTemplate('directory_home');

export function getPluginsByTag(plugins: Plugins, tag: string): Plugins {
  if (!plugins) {
    return [];
  }

  return plugins.filter((plugin: Plugin) => plugin.tags.includes(tag));
}

interface HomePageProps {
  readonly plugins: Plugins;
}

export const sortFeaturedPowerUps = (
  featuredPowerUps: Pick<Plugins[0], 'tags'>[],
) => {
  const toSort = [];
  const toShuffle = [];

  for (const powerUp of featuredPowerUps) {
    if (powerUp.tags.find((tag) => /^pos-\d+$/.test(tag))) {
      toSort.push(powerUp);
    } else {
      toShuffle.push(powerUp);
    }
  }

  const sortedPowerUps = _.sortBy(toSort, (powerUp) => {
    const numericTag = powerUp.tags.find((tag) => /^pos-\d+$/.test(tag));
    if (!numericTag) {
      return 100;
    }
    const numericValue = /^pos-(\d+)$/.exec(numericTag);
    return numericValue ? parseInt(numericValue[1], 10) : 100;
  });

  const shuffledPowerUps = _.shuffle(toShuffle);

  return [...sortedPowerUps, ...shuffledPowerUps] as Plugins;
};

export const HomePage: FunctionComponent<HomePageProps> = ({ plugins }) => {
  const essentialPowerUps = getPluginsByTag(plugins, 'essential');
  const integrations = getPluginsByTag(plugins, 'integration');
  const featuredPowerUps = sortFeaturedPowerUps(
    getPluginsByTag(plugins, 'featured'),
  );

  useEffect(() => {
    const { url, referrerUrl, referrerScreenName } = getMarketingScreenInfo();
    Analytics.sendMarketingScreenEvent({
      url,
      referrerUrl,
      referrerScreenName,
      event: {
        source: 'publicDirectoryHomeScreen',
        name: 'publicDirectoryHomeScreenV2',
        attributes: {
          totalListed: featuredPowerUps.length + essentialPowerUps.length,
        },
      },
    });
  });

  return (
    <>
      <Banner
        heading="power-ups-for-trello"
        paragraph="calendars-custom-fields-repeating-cards-and-so-much-more-with-integrations-like-jira-slack-google-drive-invision-get-your-trello-superpowers-now"
      />
      <PowerUpsList
        title={format('featured-power-ups')}
        plugins={featuredPowerUps}
        type={PowerUpItemType.Featured}
      />

      <PowerUpsList
        title={format('integrations')}
        plugins={integrations}
        type={PowerUpItemType.Featured}
      />

      <PowerUpsList
        title={format('essential-power-ups')}
        plugins={essentialPowerUps}
        type={PowerUpItemType.Featured}
      />
    </>
  );
};
