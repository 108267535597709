/* eslint-disable
    eqeqeq,
*/
// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
/*
 * decaffeinate suggestions:
 * DS102: Remove unnecessary code created because of implicit returns
 * DS205: Consider reworking code to avoid use of IIFEs
 * DS206: Consider reworking classes to avoid initClass
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import _ from 'underscore';

// eslint-disable-next-line no-restricted-imports
import $ from '@trello/jquery';

import { sendPluginUIEvent } from 'app/scripts/lib/plugins/plugin-behavioral-analytics';
import {
  isValidUrlForCSSImage,
  isValidUrlForImage,
} from 'app/scripts/lib/plugins/pluginValidators';
import { pluginIOCache } from 'app/scripts/views/internal/plugins/PluginIoCache';
import { teacupWithHelpers } from 'app/scripts/views/internal/teacupWithHelpers';
import { PluginView } from 'app/scripts/views/plugin/PluginView';
const t = teacupWithHelpers('plugin_section');

// eslint-disable-next-line @trello/no-module-logic
const template = t.renderable(function ({
  icon,
  text,
  image,
  url,
  subtext,
  thumbnail,
  idPlugin,
  actions,
}: // eslint-disable-next-line @typescript-eslint/no-explicit-any
any) {
  const plugin = pluginIOCache.fromId(idPlugin);
  t.div('.comment-unfurl.js-comment-unfurl', function () {
    t.div('.comment-unfurl-thumb', {
      style: t.stylify({
        'background-image': (() => {
          if (thumbnail) {
            return t.urlify(thumbnail);
          } else if (image != null ? image.url : undefined) {
            return t.urlify(image.url);
          } else if (icon) {
            return t.urlify(icon);
          } else if (
            isValidUrlForCSSImage(
              (plugin != null ? plugin.icon : undefined)?.url,
            )
          ) {
            return t.urlify(plugin.icon.url);
          }
        })(),
        'background-position-y':
          (image != null ? image.y : undefined) === 'top' ? '0px' : undefined,
        'background-position-x':
          (image != null ? image.x : undefined) === 'left' ? '0px' : undefined,
        'background-size': (() => {
          if (!thumbnail && !(image != null ? image.url : undefined)) {
            return 'auto 40px';
          } else {
            switch (image != null ? image.size : undefined) {
              case 'contain':
                return 'contain';
              case 'original':
                return 'inherit';
              default:
                return 'cover';
            }
          }
        })(),
      }),
    });
    return t.div('.comment-unfurl-meta', function () {
      t.span('.comment-unfurl-title', () => t.text(text));
      t.span('.comment-unfurl-subtext', { title: subtext }, () =>
        t.text(subtext),
      );
      return t.div('.comment-unfurl-media', function () {
        t.div('.comment-unfurl-plugin-icon', function () {
          if (
            isValidUrlForImage((plugin != null ? plugin.icon : undefined)?.url)
          ) {
            t.img({ src: plugin.icon.url });
          }
          return t.span('.comment-unfurl-plugin-name', () =>
            t.text(pluginIOCache.fromId(idPlugin).name),
          );
        });
        return t.div('.comment-unfurl-button-block', () =>
          actions != null
            ? // eslint-disable-next-line @typescript-eslint/no-explicit-any
              actions.map(function (action: any, i: any) {
                if (action.url) {
                  return t.a(
                    {
                      href: action.url,
                      title: text,
                      rel: 'noopener noreferrer',
                      target: '_blank',
                    },
                    () => action.text,
                  );
                } else {
                  return t.button(
                    '.js-comment-preview-button',
                    { 'data-index': i, title: action.text },
                    action.text,
                  );
                }
              })
            : undefined,
        );
      });
    });
  });
});

class CommentUnfurlPreviewTemplate extends PluginView {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor(props: any) {
    super(props);
    this.render = this.render.bind(this);
  }

  events() {
    return {
      'click .js-comment-unfurl': 'clickCommentUnfurl',
      'click .js-comment-preview-button': 'executeActionCallback',
    };
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  initialize({ commentUnfurl }: any) {
    // @ts-expect-error
    this.commentUnfurl = commentUnfurl;
    // @ts-expect-error
    return this.retain(this.commentUnfurl);
  }

  render() {
    // @ts-expect-error
    this.$el.html(template(this.commentUnfurl));
    return this;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  clickCommentUnfurl(e: any) {
    // @ts-expect-error
    if (_.isFunction(this.commentUnfurl.callback)) {
      e.preventDefault();
      // @ts-expect-error
      return this.commentUnfurl.callback({
        el: e.currentTarget,
      });
    } else if (e.target.tagName !== 'A') {
      // @ts-expect-error
      return window.open(this.commentUnfurl.url);
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  executeActionCallback(e: any) {
    e.stopPropagation();
    const index = parseInt(
      // @ts-expect-error TS(2345): Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
      $(e.currentTarget, this.$el).attr('data-index'),
      10,
    );
    // @ts-expect-error
    const action = this.commentUnfurl.actions[index];
    // @ts-expect-error TS(2554): Expected 1 arguments, but got 2.
    sendPluginUIEvent(this.commentUnfurl.idPlugin, {
      action: 'clicked',
      actionSubject: 'button',
      source: 'cardDetailScreen',
      actionSubjectId: 'unfurlActionButton',
      objectType: 'powerUp',
      // @ts-expect-error
      objectId: this.commentUnfurl.idPlugin,
    });
    if (action.callback != null) {
      e.preventDefault();
      return action.callback({
        el: e.currentTarget,
      });
    }
  }
}
CommentUnfurlPreviewTemplate.initClass();
export { CommentUnfurlPreviewTemplate };
