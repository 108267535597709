import * as Types from '@trello/graphql/generated';

import DocumentNode from '@trello/graphql/src/documentNode';
import * as Apollo from '@apollo/client';
export type NonPpmFreeMultiplayerFragment = (
  { __typename: 'Board' }
  & { organization?: Types.Maybe<(
    { __typename: 'Organization' }
    & { members: Array<(
      { __typename: 'Member' }
      & Pick<Types.Member, 'id'>
    )> }
  )> }
);

export const NonPpmFreeMultiplayerFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'NonPpmFreeMultiplayer' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Board' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organization' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'members' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

interface UseNonPpmFreeMultiplayerFragmentOptions
  extends Omit<
    Apollo.UseFragmentOptions<
      NonPpmFreeMultiplayerFragment,
      Apollo.OperationVariables
    >,
    'fragment' | 'fragmentName' | 'from'
  > {
  from: Apollo.StoreObject | Apollo.Reference;
  returnPartialData?: boolean;
}

interface UseNonPpmFreeMultiplayerFragmentResult
  extends Omit<
    Apollo.UseFragmentResult<NonPpmFreeMultiplayerFragment>,
    'data'
  > {
  data?: NonPpmFreeMultiplayerFragment;
}

export const useNonPpmFreeMultiplayerFragment = ({
  from,
  returnPartialData,
  ...options
}: UseNonPpmFreeMultiplayerFragmentOptions): UseNonPpmFreeMultiplayerFragmentResult => {
  const result = Apollo.useFragment<NonPpmFreeMultiplayerFragment>({
    ...options,
    fragment: NonPpmFreeMultiplayerFragmentDoc,
    fragmentName: 'NonPpmFreeMultiplayer',
    from: {
      __typename: 'Board',
      ...from,
    },
  });

  // Ensure that the fragment result is not typed as a DeepPartial.
  if (!result.complete && !returnPartialData) {
    return {
      ...result,
      data: undefined,
    };
  }

  return { ...result, data: result.data as NonPpmFreeMultiplayerFragment };
};
