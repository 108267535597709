import type { Board, Member } from '@trello/model-types';

import { isMemberTypeOfBoard } from './isMemberTypeOfBoard';

/**
 * Checks if a member has admin access to the board.
 */
export const isAdminOfBoard = (
  member: Pick<Member, 'id' | 'memberType' | 'idPremOrgsAdmin'> & {
    idEnterprisesAdmin?: string[] | null;
  },
  board: {
    memberships: Pick<
      Board['memberships'][number],
      'idMember' | 'memberType' | 'deactivated' | 'unconfirmed'
    >[];
    organization?:
      | (Pick<Board['organization'], 'id' | 'offering'> & {
          memberships: Pick<
            Board['organization']['memberships'][number],
            'idMember' | 'memberType' | 'deactivated' | 'unconfirmed'
          >[];
        })
      | null;
  },
  implicitAdmin = true,
) => {
  return isMemberTypeOfBoard(member, board, 'admin', implicitAdmin);
};
