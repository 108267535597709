// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.UmulKDyWYetvZt {
  margin: 18px 6px;
  padding-left: 24px;
}
.UmulKDyWYetvZt input[type='checkbox']:focus + label:before {
  border: 1px solid var(--ds-border-accent-blue, #1d7afc);
}
.FXVfjPJpWvSrZJ {
  font-weight: bold;
}
.P_DBphNhjgRCUN {
  margin-right: 8px;
  color: var(--ds-link, #0c66e4);
}
`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"checkbox": `UmulKDyWYetvZt`,
	"deleteTitle": `FXVfjPJpWvSrZJ`,
	"learnMoreLink": `P_DBphNhjgRCUN`
};
export default ___CSS_LOADER_EXPORT___;
