import type { FunctionComponent } from 'react';

import { Spinner } from '@trello/nachos/spinner';
import type { EditorTestIds } from '@trello/test-ids';
import { getTestId } from '@trello/test-ids';

import styles from './LoadingSpinner.less';

export const LoadingSpinner: FunctionComponent = () => {
  return (
    <Spinner
      centered
      wrapperClassName={styles.loading}
      testId={getTestId<EditorTestIds>('editor-loading-spinner')}
    />
  );
};
