/* eslint-disable
    eqeqeq,
*/
// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * DS104: Avoid inline assignments
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import { ModelLoader } from 'app/scripts/db/model-loader';

export const ViewWithActions = {
  limit: 50,
  nextPage: 1,
  shortLoad: false,
  loading: false,
  idModels: null,

  // @ts-expect-error TS(7023): 'loadMoreActions' implicitly has return type 'any'... Remove this comment to see the full error message
  loadMoreActions() {
    if (this.loading) {
      return;
    }
    this.loading = true;

    // @ts-expect-error TS(2339): Property 'waitForId' does not exist on type '{ lim... Remove this comment to see the full error message
    return this.waitForId(this.model, () => {
      let left;
      return ModelLoader.loadMoreActionData(
        // @ts-expect-error TS(2339): Property 'model' does not exist on type '{ limit: ... Remove this comment to see the full error message
        this.model.typeName.toLowerCase(),
        // @ts-expect-error TS(2339): Property 'model' does not exist on type '{ limit: ... Remove this comment to see the full error message
        this.model.id,
        this.nextPage,
        this.limit,
        // @ts-expect-error TS(2345): Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
        this.idModels,
        (left =
          // @ts-expect-error TS(2339): Property 'getActionsFilter' does not exist on type... Remove this comment to see the full error message
          typeof this.getActionsFilter === 'function'
            ? // @ts-expect-error TS(2339): Property 'getActionsFilter' does not exist on type... Remove this comment to see the full error message
              this.getActionsFilter()
            : undefined) != null
          ? left
          : 'all',
      )
        .finally(() => {
          return (this.loading = false);
        })
        .then((actions) => {
          // @ts-expect-error TS(2339): Property 'length' does not exist on type 'Action'.
          this.shortLoad = actions.length < this.limit;
          // @ts-expect-error TS(2339): Property 'renderActions' does not exist on type '{... Remove this comment to see the full error message
          this.renderActions();
          return ++this.nextPage;
        })
        .done();
    });
  },

  // @ts-expect-error TS(7023): 'hasMoreActions' implicitly has return type 'any' ... Remove this comment to see the full error message
  hasMoreActions() {
    return (
      !this.loading && !this.shortLoad && this.getActions().length >= this.limit
    );
  },

  // @ts-expect-error TS(7023): 'getActions' implicitly has return type 'any' beca... Remove this comment to see the full error message
  getActions() {
    let left;
    // @ts-expect-error TS(7022): 'filteringModels' implicitly has type 'any' becaus... Remove this comment to see the full error message
    const filteringModels =
      (left =
        // @ts-expect-error TS(2339): Property 'getFilteringModels' does not exist on ty... Remove this comment to see the full error message
        typeof this.getFilteringModels === 'function'
          ? // @ts-expect-error TS(2339): Property 'getFilteringModels' does not exist on ty... Remove this comment to see the full error message
            this.getFilteringModels()
          : undefined) != null
        ? left
        : [];
    // @ts-expect-error TS(2339): Property 'model' does not exist on type '{ limit: ... Remove this comment to see the full error message
    return this.model.getActions(...Array.from(filteringModels || []));
  },
};
