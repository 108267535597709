import * as Types from '@trello/graphql/generated';

import DocumentNode from '@trello/graphql/src/documentNode';
import * as Apollo from '@apollo/client';
export type MemberEmailFragment = (
  { __typename: 'Member' }
  & Pick<Types.Member, 'id' | 'email'>
);

export const MemberEmailFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'MemberEmail' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Member' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'email' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

interface UseMemberEmailFragmentOptions
  extends Omit<
    Apollo.UseFragmentOptions<MemberEmailFragment, Apollo.OperationVariables>,
    'fragment' | 'fragmentName' | 'from'
  > {
  from: Apollo.StoreObject | Apollo.Reference;
  returnPartialData?: boolean;
}

interface UseMemberEmailFragmentResult
  extends Omit<Apollo.UseFragmentResult<MemberEmailFragment>, 'data'> {
  data?: MemberEmailFragment;
}

export const useMemberEmailFragment = ({
  from,
  returnPartialData,
  ...options
}: UseMemberEmailFragmentOptions): UseMemberEmailFragmentResult => {
  const result = Apollo.useFragment<MemberEmailFragment>({
    ...options,
    fragment: MemberEmailFragmentDoc,
    fragmentName: 'MemberEmail',
    from: {
      __typename: 'Member',
      ...from,
    },
  });

  // Ensure that the fragment result is not typed as a DeepPartial.
  if (!result.complete && !returnPartialData) {
    return {
      ...result,
      data: undefined,
    };
  }

  return { ...result, data: result.data as MemberEmailFragment };
};
