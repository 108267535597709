import { useCallback } from 'react';
import { parseISO } from 'date-fns';

import { Analytics } from '@trello/atlassian-analytics';
import type { Traits } from '@trello/atlassian-personalization';
import {
  getUserTraits,
  TRELLO_AD_SEQUENCER_FIRST_ACTIVATED_DATE,
  TRELLO_AD_SEQUENCER_USER_LAST_ACTIVE_DATE,
  TRELLO_UI_DISCOVERY_AD_DISPLAYED_LATEST_AD,
  TRELLO_UI_DISCOVERY_AD_DISPLAYED_LATEST_AD_EXPERIENCE,
  TRELLO_UI_DISCOVERY_AD_DISPLAYED_LATEST_AD_FIRST_VIEWED,
  TRELLO_USER_ACTIVE_DAYS_SINCE_AD_SEQUENCER_FIRST_ACTIVATED,
} from '@trello/atlassian-personalization';
import { useAaId } from '@trello/authentication';
import { TrelloStorage } from '@trello/storage';

import type { AdTouchpointSourceType } from './TouchpointSourceType';

interface AdSequencerParams {
  experience: string;
  adTouchpointSources: AdTouchpointSourceType[];
  adRotationIntervalInDays?: number;
}

interface AdSequencerAttributes {
  lastAdSeenExperience?: string;
  lastAdSeen?: AdTouchpointSourceType;
  lastAdSeenTimestamp?: Date;
  adSequencerActivatedDate?: Date;
  adSequencerUserLastActiveDate?: Date;
  daysUserActiveSinceAdSequencerActivated?: number;
}

const extractAttributesFromTraits = (traits: Traits): AdSequencerAttributes => {
  return {
    lastAdSeenExperience: traits?.[
      TRELLO_UI_DISCOVERY_AD_DISPLAYED_LATEST_AD_EXPERIENCE
    ]
      ? String(traits[TRELLO_UI_DISCOVERY_AD_DISPLAYED_LATEST_AD_EXPERIENCE])
      : undefined,
    lastAdSeen: traits?.[
      TRELLO_UI_DISCOVERY_AD_DISPLAYED_LATEST_AD
    ] as AdTouchpointSourceType,
    lastAdSeenTimestamp: traits?.[
      TRELLO_UI_DISCOVERY_AD_DISPLAYED_LATEST_AD_FIRST_VIEWED
    ]
      ? parseISO(
          String(
            traits[TRELLO_UI_DISCOVERY_AD_DISPLAYED_LATEST_AD_FIRST_VIEWED],
          ),
        )
      : undefined,
    adSequencerActivatedDate: traits?.[TRELLO_AD_SEQUENCER_FIRST_ACTIVATED_DATE]
      ? parseISO(String(traits[TRELLO_AD_SEQUENCER_FIRST_ACTIVATED_DATE]))
      : undefined,
    adSequencerUserLastActiveDate: traits?.[
      TRELLO_AD_SEQUENCER_USER_LAST_ACTIVE_DATE
    ]
      ? parseISO(String(traits[TRELLO_AD_SEQUENCER_USER_LAST_ACTIVE_DATE]))
      : undefined,
    daysUserActiveSinceAdSequencerActivated: traits?.[
      TRELLO_USER_ACTIVE_DAYS_SINCE_AD_SEQUENCER_FIRST_ACTIVATED
    ]
      ? Number(
          traits[TRELLO_USER_ACTIVE_DAYS_SINCE_AD_SEQUENCER_FIRST_ACTIVATED],
        )
      : undefined,
  };
};

const LAST_AD_SEEN_ATTRIBUTES_CACHE_KEY_PREFIX = 'lastAdSeenAttributes';

const getLastAdSeenAttributesCacheKey = (aaId: string) => {
  return `${LAST_AD_SEEN_ATTRIBUTES_CACHE_KEY_PREFIX}_${aaId}`;
};

/**
 * Ad Sequencer hook.
 *
 * Returns the advertisement the user should see based on how many days they
 * have been active.
 *
 * @param experience the ad experience that the user was enrolled in (currently this is the experiment)
 * @param adTouchpointSources an array containing the ads for the specified experience
 * @param adRotationIntervalInMinutes the number of minutes between each ad rotation
 * @returns {Object} [obj] exported members
 * @returns {Promise<String>} [obj.getNextAd] returns the next ad
 */
export const useAdSequencer = ({
  experience,
  adTouchpointSources,
  adRotationIntervalInDays = 3,
}: AdSequencerParams) => {
  const aaId = useAaId();

  const getNextAd = useCallback(async () => {
    if (!aaId) {
      return;
    }

    // Clean up cache from previous implementation.
    if (getLastAdSeenAttributesCacheKey(aaId)) {
      TrelloStorage.unset(getLastAdSeenAttributesCacheKey(aaId));
    }

    try {
      const traits = await getUserTraits(
        aaId,
        TRELLO_AD_SEQUENCER_FIRST_ACTIVATED_DATE,
      );
      const attributes = extractAttributesFromTraits(traits);

      if (
        attributes.adSequencerActivatedDate &&
        attributes.daysUserActiveSinceAdSequencerActivated
      ) {
        const elapsedAdRotationPeriods = Math.floor(
          attributes.daysUserActiveSinceAdSequencerActivated /
            adRotationIntervalInDays,
        );

        const advertisement =
          adTouchpointSources[
            elapsedAdRotationPeriods % adTouchpointSources.length
          ];

        Analytics.sendOperationalEvent({
          action: 'succeeded',
          actionSubject: 'getNextAd',
          source: '@trello/ad-sequencer',
          attributes: {
            daysUserActive: attributes.daysUserActiveSinceAdSequencerActivated,
            experience,
            suggestedNextAd: advertisement,
          },
        });

        return advertisement;
      }

      return undefined;
    } catch (e) {
      Analytics.sendOperationalEvent({
        action: 'errored',
        actionSubject: 'getNextAd',
        source: '@trello/ad-sequencer',
        attributes: {
          errorMessage: (e as Error).message,
        },
      });

      return undefined;
    }
  }, [aaId, adRotationIntervalInDays, experience, adTouchpointSources]);

  return {
    getNextAd,
  };
};
