import type { EligibleWorkspaceOptions } from '../useEligibleWorkspacesForProvisioning';
import { useEligibleWorkspacesForProvisioning } from '../useEligibleWorkspacesForProvisioning';
import type { RuntimeEligibilityCheckResult } from './RuntimeEligibilityCheck';

export const useHasEligibleWorkspaces = ({
  workspaceId,
  entitlementRequired,
}: {
  workspaceId?: string;
  entitlementRequired?: 'free' | 'standardOrPremium';
}): RuntimeEligibilityCheckResult<{
  eligibleWorkspaceOptions: EligibleWorkspaceOptions;
}> => {
  const eligibleWorkspaceOptions = useEligibleWorkspacesForProvisioning({
    workspaceId,
    entitlementRequired,
  });

  return {
    isEligible: eligibleWorkspaceOptions.length > 0,
    eligibleWorkspaceOptions,
  };
};
