// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/**
 * Defines label color variables on a class for each label color name.
 * Consumers of labels outside of their components can import this LESS file,
 * which will load up CSS variables on any element with the corresponding class
 * name (e.g. \`.green\`).
 */
.WV2LHwa2txQAGD {
  --label-background-color: var(--ds-background-accent-green-subtler, #baf3db);
  --label-background-color-hovered: var(--ds-background-accent-green-subtler-hovered, #7ee2b8);
  --label-text-color: var(--ds-text-accent-green-bolder, #164b35);
}
.dw6dSeDTUO7W7U {
  --label-background-color: var(--ds-background-accent-green-subtle, #4bce97);
  --label-background-color-hovered: var(--ds-background-accent-green-subtle-hovered, #7ee2b8);
  --label-text-color: var(--ds-text-accent-green-bolder, #164b35);
}
.cLQF8JphaVSOec {
  --label-background-color: var(--ds-background-accent-green-bolder, #1f845a);
  --label-background-color-hovered: var(--ds-background-accent-green-bolder-hovered, #216e4e);
  --label-text-color: var(--ds-text-inverse, #ffffff);
}
.jlv8XICK2ozRDB {
  --label-background-color: var(--ds-background-accent-yellow-subtler, #f8e6a0);
  --label-background-color-hovered: var(--ds-background-accent-yellow-subtler-hovered, #f5cd47);
  --label-text-color: var(--ds-text-accent-yellow-bolder, #533f04);
}
.Rx3aIjYWpC8QVh {
  --label-background-color: var(--ds-background-accent-yellow-subtle, #e2b203);
  --label-background-color-hovered: var(--ds-background-accent-yellow-subtle-hovered, #f5cd47);
  --label-text-color: var(--ds-text-accent-yellow-bolder, #533f04);
}
.Gt6Z2TYLNWg2dg {
  --label-background-color: var(--ds-background-accent-yellow-bolder, #946f00);
  --label-background-color-hovered: var(--ds-background-accent-yellow-bolder-hovered, #7f5f01);
  --label-text-color: var(--ds-text-inverse, #ffffff);
}
.MZqetDeftMh2Wt {
  --label-background-color: var(--ds-background-accent-orange-subtler, #ffe2bd);
  --label-background-color-hovered: var(--ds-background-accent-orange-subtler-hovered, #fec57b);
  --label-text-color: var(--ds-text-accent-orange-bolder, #5f3811);
}
.SS9OHlzuoLVqS0 {
  --label-background-color: var(--ds-background-accent-orange-subtle, #faa53d);
  --label-background-color-hovered: var(--ds-background-accent-orange-subtle-hovered, #fec57b);
  --label-text-color: var(--ds-text-accent-orange-bolder, #5f3811);
}
.QbGsu1h2KImNHi {
  --label-background-color: var(--ds-background-accent-orange-bolder, #b65c02);
  --label-background-color-hovered: var(--ds-background-accent-orange-bolder-hovered, #974f0c);
  --label-text-color: var(--ds-text-inverse, #ffffff);
}
.QuMzx2xy_LhhID {
  --label-background-color: var(--ds-background-accent-red-subtler, #ffd2cc);
  --label-background-color-hovered: var(--ds-background-accent-red-subtler-hovered, #ff9c8f);
  --label-text-color: var(--ds-text-accent-red-bolder, #601e16);
}
.yuJNNxl_DN8j38 {
  --label-background-color: var(--ds-background-accent-red-subtle, #f87462);
  --label-background-color-hovered: var(--ds-background-accent-red-subtle-hovered, #ff9c8f);
  --label-text-color: var(--ds-text-accent-red-bolder, #601e16);
}
.YlTu3CRWFBNXnj {
  --label-background-color: var(--ds-background-accent-red-bolder, #ca3521);
  --label-background-color-hovered: var(--ds-background-accent-red-bolder-hovered, #ae2a19);
  --label-text-color: var(--ds-text-inverse, #ffffff);
}
.bucKbY50lsKXaQ {
  --label-background-color: var(--ds-background-accent-purple-subtler, #dfd8fd);
  --label-background-color-hovered: var(--ds-background-accent-purple-subtler-hovered, #b8acf6);
  --label-text-color: var(--ds-text-accent-purple-bolder, #352c63);
}
.gNAsY_dqdqzU0U {
  --label-background-color: var(--ds-background-accent-purple-subtle, #9f8fef);
  --label-background-color-hovered: var(--ds-background-accent-purple-subtle-hovered, #b8acf6);
  --label-text-color: var(--ds-text-accent-purple-bolder, #352c63);
}
.DsifJCopCBEumx {
  --label-background-color: var(--ds-background-accent-purple-bolder, #6e5dc6);
  --label-background-color-hovered: var(--ds-background-accent-purple-bolder-hovered, #5e4db2);
  --label-text-color: var(--ds-text-inverse, #ffffff);
}
.qrgNimxi_gzqQT {
  --label-background-color: var(--ds-background-accent-blue-subtler, #cce0ff);
  --label-background-color-hovered: var(--ds-background-accent-blue-subtler-hovered, #85b8ff);
  --label-text-color: var(--ds-text-accent-blue-bolder, #09326c);
}
.NppK5ZR2kIY1gQ {
  --label-background-color: var(--ds-background-accent-blue-subtle, #579dff);
  --label-background-color-hovered: var(--ds-background-accent-blue-subtle-hovered, #85b8ff);
  --label-text-color: var(--ds-text-accent-blue-bolder, #09326c);
}
.dC3eyYZsZ_yK4E {
  --label-background-color: var(--ds-background-accent-blue-bolder, #0c66e4);
  --label-background-color-hovered: var(--ds-background-accent-blue-bolder-hovered, #0055cc);
  --label-text-color: var(--ds-text-inverse, #ffffff);
}
.vCcp_RyfRqZFgz {
  --label-background-color: var(--ds-background-accent-teal-subtler, #c1f0f5);
  --label-background-color-hovered: var(--ds-background-accent-teal-subtler-hovered, #8bdbe5);
  --label-text-color: var(--ds-text-accent-teal-bolder, #1d474c);
}
.vwv080FmuhASmN {
  --label-background-color: var(--ds-background-accent-teal-subtle, #60c6d2);
  --label-background-color-hovered: var(--ds-background-accent-teal-subtle-hovered, #8bdbe5);
  --label-text-color: var(--ds-text-accent-teal-bolder, #1d474c);
}
.PyqAcz_HsOnUtf {
  --label-background-color: var(--ds-background-accent-teal-bolder, #1d7f8c);
  --label-background-color-hovered: var(--ds-background-accent-teal-bolder-hovered, #206b74);
  --label-text-color: var(--ds-text-inverse, #ffffff);
}
.XVp4CwkvSDet4E {
  --label-background-color: var(--ds-background-accent-lime-subtler, #D3F1A7);
  --label-background-color-hovered: var(--ds-background-accent-lime-subtler-hovered, #b3df72);
  --label-text-color: var(--ds-text-accent-lime-bolder, #37471f);
}
.H0I3iSI6T6F3Yh {
  --label-background-color: var(--ds-background-accent-lime-subtle, #94c748);
  --label-background-color-hovered: var(--ds-background-accent-lime-subtle-hovered, #b3df72);
  --label-text-color: var(--ds-text-accent-lime-bolder, #37471f);
}
.jlPUQzkfm8oYZu {
  --label-background-color: var(--ds-background-accent-lime-bolder, #5b7f24);
  --label-background-color-hovered: var(--ds-background-accent-lime-bolder-hovered, #4c6b1f);
  --label-text-color: var(--ds-text-inverse, #ffffff);
}
.lXNOSjhbeLVNJ_ {
  --label-background-color: var(--ds-background-accent-magenta-subtler, #fdd0ec);
  --label-background-color-hovered: var(--ds-background-accent-magenta-subtler-hovered, #f797d2);
  --label-text-color: var(--ds-text-accent-magenta-bolder, #50253f);
}
.yJpAhgRjeQFf17 {
  --label-background-color: var(--ds-background-accent-magenta-subtle, #e774bb);
  --label-background-color-hovered: var(--ds-background-accent-magenta-subtle-hovered, #f797d2);
  --label-text-color: var(--ds-text-accent-magenta-bolder, #50253f);
}
.HgiR1IHw0SvNkK {
  --label-background-color: var(--ds-background-accent-magenta-bolder, #ae4787);
  --label-background-color-hovered: var(--ds-background-accent-magenta-bolder-hovered, #943d73);
  --label-text-color: var(--ds-text-inverse, #ffffff);
}
.CBEy52bQdAuNaU {
  --label-background-color: var(--ds-background-accent-gray-subtler, #dcdfe4);
  --label-background-color-hovered: var(--ds-background-accent-gray-subtler-hovered, #b3b9c4);
  --label-text-color: var(--ds-text-accent-gray-bolder, #091e42);
}
.fa84ttvdoYEM_1 {
  --label-background-color: var(--ds-background-accent-gray-subtle, #8590a2);
  --label-background-color-hovered: var(--ds-background-accent-gray-subtle-hovered, #b3b9c4);
  --label-text-color: var(--ds-text-accent-gray-bolder, #091e42);
}
.Q_TO1T_p7lzNJs {
  --label-background-color: var(--ds-background-accent-gray-bolder, #626f86);
  --label-background-color-hovered: var(--ds-background-accent-gray-bolder-hovered, #44546f);
  --label-text-color: var(--ds-text-inverse, #ffffff);
}
.CNnougcEO0WaO3 {
  display: inline-block;
  position: relative;
  box-sizing: border-box;
  min-width: 56px;
  max-width: 100%;
  height: 16px;
  margin-bottom: 0;
  padding: 0 8px;
  overflow: hidden;
  border-radius: 4px;
  background-color: var(--label-background-color);
  color: var(--label-text-color);
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  text-align: left;
  text-overflow: ellipsis;
  vertical-align: middle;
  white-space: nowrap;
}
.body-color-blind-mode-enabled .CNnougcEO0WaO3:not(._dSqyhWfoSrsMO),
.body-color-blind-mode-enabled ~ div .CNnougcEO0WaO3:not(._dSqyhWfoSrsMO) {
  --adjusted-padding-left: calc((8px / 2) + 16px);
  padding-left: var(--adjusted-padding-left);
}
.body-color-blind-mode-enabled .CNnougcEO0WaO3:not(._dSqyhWfoSrsMO)::before,
.body-color-blind-mode-enabled ~ div .CNnougcEO0WaO3:not(._dSqyhWfoSrsMO)::before {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 16px;
  height: 16px;
  border-radius: 4px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  background-color: var(--label-text-color);
  -webkit-mask-repeat: repeat-y;
          mask-repeat: repeat-y;
}
.YjsahfcWMjpk4W {
  min-width: 40px;
  max-width: 40px;
  height: 8px;
  margin-top: 0;
  margin-bottom: 0;
  padding-right: 0;
  padding-left: 0;
  color: transparent;
}
.body-color-blind-mode-enabled .YjsahfcWMjpk4W:not(._dSqyhWfoSrsMO)::before,
.body-color-blind-mode-enabled ~ div .YjsahfcWMjpk4W:not(._dSqyhWfoSrsMO)::before {
  right: 0;
  width: 100%;
  height: 100%;
  border-radius: 0;
  -webkit-mask-repeat: repeat;
          mask-repeat: repeat;
}
.IDeXAnQiLBnlva:hover {
  background-color: var(--label-background-color-hovered);
  cursor: pointer;
}
@keyframes SSUJ2iJ1cX3mtJ {
  0% {
    padding-right: 0;
    padding-left: 0;
    transform: scaleY(0.5);
    color: transparent;
  }
  66% {
    padding-right: 8px;
    padding-left: 8px;
    transform: scaleY(1);
  }
  100% {
    padding-right: 8px;
    padding-left: var(--adjusted-padding-left, 8px);
    transform: scaleY(1);
    color: transparent;
  }
}
@keyframes RsE6rgAmSnPzc8 {
  0% {
    max-width: var(--expanded-max-width, 100%);
    transform: scaleY(1.5);
    color: transparent;
  }
  66% {
    transform: scaleY(1.5);
  }
  100% {
    max-width: 40px;
    transform: scaleY(1);
    color: transparent;
  }
}
@keyframes PjpTR7psgKNRbi {
  0%,
  99% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.body-card-label-text-on .CNnougcEO0WaO3 {
  animation-name: SSUJ2iJ1cX3mtJ;
  animation-duration: 450ms;
  animation-timing-function: ease-out;
}
.body-card-label-text-on.body-color-blind-mode-enabled .CNnougcEO0WaO3:not(._dSqyhWfoSrsMO)::before,
.body-card-label-text-on.body-color-blind-mode-enabled ~ div .CNnougcEO0WaO3:not(._dSqyhWfoSrsMO)::before {
  animation-name: PjpTR7psgKNRbi;
  animation-duration: 450ms;
  animation-timing-function: ease-out;
}
.body-card-label-text-off .CNnougcEO0WaO3 {
  animation-name: RsE6rgAmSnPzc8;
  animation-duration: 450ms;
  animation-timing-function: ease-in;
  animation-fill-mode: forwards;
}
.body-card-label-text-off.body-color-blind-mode-enabled .CNnougcEO0WaO3:not(._dSqyhWfoSrsMO)::before,
.body-card-label-text-off.body-color-blind-mode-enabled ~ div .CNnougcEO0WaO3:not(._dSqyhWfoSrsMO)::before {
  animation-name: PjpTR7psgKNRbi;
  animation-duration: 450ms;
  animation-timing-function: ease-in;
  animation-fill-mode: forwards;
}
`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"green_light": `WV2LHwa2txQAGD`,
	"green": `dw6dSeDTUO7W7U`,
	"green_dark": `cLQF8JphaVSOec`,
	"yellow_light": `jlv8XICK2ozRDB`,
	"yellow": `Rx3aIjYWpC8QVh`,
	"yellow_dark": `Gt6Z2TYLNWg2dg`,
	"orange_light": `MZqetDeftMh2Wt`,
	"orange": `SS9OHlzuoLVqS0`,
	"orange_dark": `QbGsu1h2KImNHi`,
	"red_light": `QuMzx2xy_LhhID`,
	"red": `yuJNNxl_DN8j38`,
	"red_dark": `YlTu3CRWFBNXnj`,
	"purple_light": `bucKbY50lsKXaQ`,
	"purple": `gNAsY_dqdqzU0U`,
	"purple_dark": `DsifJCopCBEumx`,
	"blue_light": `qrgNimxi_gzqQT`,
	"blue": `NppK5ZR2kIY1gQ`,
	"blue_dark": `dC3eyYZsZ_yK4E`,
	"sky_light": `vCcp_RyfRqZFgz`,
	"sky": `vwv080FmuhASmN`,
	"sky_dark": `PyqAcz_HsOnUtf`,
	"lime_light": `XVp4CwkvSDet4E`,
	"lime": `H0I3iSI6T6F3Yh`,
	"lime_dark": `jlPUQzkfm8oYZu`,
	"pink_light": `lXNOSjhbeLVNJ_`,
	"pink": `yJpAhgRjeQFf17`,
	"pink_dark": `HgiR1IHw0SvNkK`,
	"black_light": `CBEy52bQdAuNaU`,
	"black": `fa84ttvdoYEM_1`,
	"black_dark": `Q_TO1T_p7lzNJs`,
	"compactLabel": `CNnougcEO0WaO3`,
	"colorless": `_dSqyhWfoSrsMO`,
	"compactLabel--collapsed": `YjsahfcWMjpk4W`,
	"compactLabel--hoverable": `IDeXAnQiLBnlva`,
	"expandLabels": `SSUJ2iJ1cX3mtJ`,
	"togglePattern": `PjpTR7psgKNRbi`,
	"collapseLabels": `RsE6rgAmSnPzc8`
};
export default ___CSS_LOADER_EXPORT___;
