/* eslint-disable
    eqeqeq,
*/
// TODO: This file was created by bulk-decaffeinate.
// Fix any style issues and re-enable lint.
/*
 * decaffeinate suggestions:
 * DS101: Remove unnecessary use of Array.from
 * DS102: Remove unnecessary code created because of implicit returns
 * DS201: Simplify complex destructure assignments
 * DS207: Consider shorter variations of null checks
 * Full docs: https://github.com/decaffeinate/decaffeinate/blob/master/docs/suggestions.md
 */
import _ from 'underscore';

import { siteDomain } from '@trello/config';
import { getDateDeltaString } from '@trello/dates/i18n';
import { sendErrorEvent } from '@trello/error-reporting';
import { isTrelloAttachmentUrl, isTrelloUrl, isUrl } from '@trello/urls';

import { makeVisitor, visit } from 'app/scripts/lib/babble';

const makeTextEntity = function (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  text: any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  idContext: any,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  hideIfContext: any,
) {
  if (hideIfContext == null) {
    hideIfContext = false;
  }
  return { type: 'text', text, hideIfContext, idContext };
};

// eslint-disable-next-line @trello/no-module-logic
const entityListVisitor = makeVisitor(
  makeTextEntity,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (key: any, display: any) => display.entities[key],
);

// eslint-disable-next-line @trello/no-module-logic
const stringVisitor = makeVisitor(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (o: any) => o,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function (key: any, subs: any) {
    if (Object.prototype.hasOwnProperty.call(subs, key)) {
      return subs[key];
    } else {
      sendErrorEvent(new Error(`substitution key ${key} not found!`));
      return '';
    }
  },
);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const makeEntityFriendly = function (entity: any) {
  if (!entity.url && (entity.urlContext || isUrl(entity.text))) {
    entity.url = entity.urlContext || entity.text;
    entity.isFriendly = true;

    if (entity.type === 'text') {
      entity.type = 'attachment';
    }
  }

  if (entity.url != null) {
    entity.isTrelloAttachment = isTrelloAttachmentUrl(entity.url);
    entity.isTrello = isTrelloUrl(entity.url);
  }

  return entity;
};

// Common info our views expect to be part of 'display'
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const withAdditionalInfo = function (entityIn: any) {
  const entity = _.clone(entityIn);
  // There's no proper library for getting short links right now, and we would
  // prefer not to refer to controller here. This should be fixed as soon as
  // we have a shortlink lib.
  switch (entity.type) {
    case 'card':
      entity.url = `${siteDomain}/c/${entity.shortLink ?? entity.id}`;
      break;
    case 'board':
      entity.url = `${siteDomain}/b/${entity.shortLink ?? entity.id}`;
      break;
    case 'organization':
      entity.url = `${siteDomain}/${entity.name ?? entity.id}`;
      break;
    case 'enterprise':
      entity.url = `${siteDomain}/e/${entity.name ?? entity.id}/admin/teams`;
      break;
    case 'attachment':
      makeEntityFriendly(entity);
      break;
    case 'text':
      makeEntityFriendly(entity);
      break;
    default:
      break;
  }

  return entity;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const applyTo = (type: any, fn: any) =>
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function (entity: any) {
    if (entity.type === type) {
      return fn(entity);
    } else {
      return entity;
    }
  };

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const addUrlContext = function (entities: any, urlContext: any) {
  _.each(entities, function (entity) {
    // @ts-expect-error TS(2571): Object is of type 'unknown'.
    if (entity.type === 'text' && !entity.idContext && urlContext) {
      // @ts-expect-error TS(2571): Object is of type 'unknown'.
      return (entity.urlContext = urlContext);
    }
  });

  return entities;
};

export class EntityDisplay {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  type: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  constructor(type: any) {
    this.type = type;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  translationKeys(display: any, idContext: any) {
    // FIX ME: this hack for changing the string key
    // for removing due date on a card. Normally this would be
    // provided by the server, so once this is fixed serverside,
    // we should remove this condition
    // cache the variable here to 'break' the reference to the original object.
    let { translationKey } = display;
    if (
      translationKey === 'notification_changed_due_date' &&
      display.entities.card?.due === null &&
      this.type === 'notificationsGrouped'
    ) {
      translationKey = 'notification_removed_due_date';
    }
    // We also manually provide a date entity for use in the string keys
    if (
      [
        'notification_changed_due_date',
        'notification_added_a_due_date',
      ].includes(translationKey)
    ) {
      display.entities.date = {
        type: 'date',
        text: getDateDeltaString(display.entities.card.due, new Date()),
        date: display.entities.card.due,
      };
    }

    const nonContextKeys = [translationKey];
    if (idContext == null) {
      return nonContextKeys;
    }

    const matchingPair = _.find(_.pairs(display.entities), function (...args) {
      const [, entity] = Array.from(args[0]);
      return entity.id === idContext;
    });

    if (matchingPair != null) {
      const matchingType = matchingPair[0];
      return [
        [translationKey, matchingType].join('@'),
        ...Array.from(nonContextKeys),
      ];
    } else {
      return nonContextKeys;
    }
  }

  // Converts a 'display' object to a localized list of 'entity' objects
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getEntities(display: any, idContext: any) {
    if (!display) {
      return [];
    }
    if (display.translationKey === 'unknown') {
      return [];
    }

    const urlContext = _.find(
      display.entities,
      ({ type }) => ['attachment', 'attachmentPreview'].includes(type),
      // @ts-expect-error TS(2339): Property 'originalUrl' does not exist on type '{ t... Remove this comment to see the full error message
    )?.originalUrl;

    const entityList = _.chain(this.translationKeys(display, idContext))
      .map((key) =>
        visit(
          // @ts-expect-error TS(2339): Property '__locale' does not exist on type 'Window... Remove this comment to see the full error message
          window.__locale,
          `${
            this.type === 'action' || this.type === 'notification'
              ? this.type + 's'
              : this.type
          }.${key}`,
          entityListVisitor,
          display,
        ),
      )
      .compact()
      .first()
      // @ts-expect-error TS(2339): Property 'tap' does not exist on type '_ChainSingl... Remove this comment to see the full error message
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      .tap((entities: any) => addUrlContext(entities, urlContext))
      .value();

    if (entityList == null) {
      sendErrorEvent(
        new Error(`display key '${display.translationKey}' not found!`),
      );
      return [];
    }

    return entityList
      .map(applyTo('translatable', this._textFromTranslatable.bind(this)))
      .map(applyTo('relDate', this._localizedRelDate.bind(this)))
      .map(withAdditionalInfo);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  _format(key: any, subs: any) {
    if (subs == null) {
      subs = {};
    }
    return visit(
      // @ts-expect-error TS(2339): Property '__locale' does not exist on type 'Window... Remove this comment to see the full error message
      window.__locale,
      `${
        this.type === 'action' || this.type === 'notification'
          ? this.type + 's'
          : this.type
      }.${key}`,
      stringVisitor,
      subs,
    ).join('');
  }

  // Takes a translatable and returns its translation in this context
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  _textFromTranslatable(entity: any) {
    // @ts-expect-error TS(2554): Expected 2 arguments, but got 1.
    const text = this._format(entity.translationKey);
    return makeTextEntity(text, entity.idContext, entity.hideIfContext);
  }

  // Replaces entity.current from the server with a localized version
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  _localizedRelDate(entity: any) {
    // @ts-expect-error TS(2554): Expected 2 arguments, but got 1.
    return _.defaults({ current: this.relDateText(entity.date) }, entity);
  }

  // Generate the localized message for a relDate entity
  // Exposed so that the client can update relative dates as time progresses
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  relDateText(dateStr: any, now: any) {
    if (now == null) {
      now = new Date();
    }
    const date = new Date(dateStr);
    const period = getDateDeltaString(date, now);
    const relDateKey =
      date > now ? 'notification_is_due' : 'notification_was_due';
    return this._format(relDateKey, { period });
  }
}
