import * as Types from '@trello/graphql/generated';

import DocumentNode from '@trello/graphql/src/documentNode';
import * as Apollo from '@apollo/client';
export type BoardCommentPermissionsFragment = (
  { __typename: 'Board' }
  & Pick<Types.Board, 'id' | 'closed' | 'idOrganization' | 'premiumFeatures'>
  & { organization?: Types.Maybe<(
    { __typename: 'Organization' }
    & Pick<Types.Organization, 'id' | 'offering'>
    & { limits: (
      { __typename: 'Organization_Limits' }
      & { orgs: (
        { __typename: 'Organization_Limits_Orgs' }
        & { usersPerFreeOrg: (
          { __typename: 'Limit' }
          & Pick<Types.Limit, 'status'>
        ) }
      ) }
    ) }
  )>, prefs?: Types.Maybe<(
    { __typename: 'Board_Prefs' }
    & Pick<Types.Board_Prefs, 'comments' | 'isTemplate' | 'permissionLevel' | 'selfJoin' | 'voting'>
  )> }
);

export const BoardCommentPermissionsFragmentDoc = {
  kind: 'Document',
  definitions: [
    {
      kind: 'FragmentDefinition',
      name: { kind: 'Name', value: 'BoardCommentPermissions' },
      typeCondition: {
        kind: 'NamedType',
        name: { kind: 'Name', value: 'Board' },
      },
      selectionSet: {
        kind: 'SelectionSet',
        selections: [
          { kind: 'Field', name: { kind: 'Name', value: 'id' } },
          { kind: 'Field', name: { kind: 'Name', value: 'closed' } },
          { kind: 'Field', name: { kind: 'Name', value: 'idOrganization' } },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'organization' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'id' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'limits' },
                  selectionSet: {
                    kind: 'SelectionSet',
                    selections: [
                      {
                        kind: 'Field',
                        name: { kind: 'Name', value: 'orgs' },
                        selectionSet: {
                          kind: 'SelectionSet',
                          selections: [
                            {
                              kind: 'Field',
                              name: { kind: 'Name', value: 'usersPerFreeOrg' },
                              selectionSet: {
                                kind: 'SelectionSet',
                                selections: [
                                  {
                                    kind: 'Field',
                                    name: { kind: 'Name', value: 'status' },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'offering' } },
              ],
            },
          },
          {
            kind: 'Field',
            name: { kind: 'Name', value: 'prefs' },
            selectionSet: {
              kind: 'SelectionSet',
              selections: [
                { kind: 'Field', name: { kind: 'Name', value: 'comments' } },
                { kind: 'Field', name: { kind: 'Name', value: 'isTemplate' } },
                {
                  kind: 'Field',
                  name: { kind: 'Name', value: 'permissionLevel' },
                },
                { kind: 'Field', name: { kind: 'Name', value: 'selfJoin' } },
                { kind: 'Field', name: { kind: 'Name', value: 'voting' } },
              ],
            },
          },
          { kind: 'Field', name: { kind: 'Name', value: 'premiumFeatures' } },
        ],
      },
    },
  ],
} as unknown as DocumentNode;

interface UseBoardCommentPermissionsFragmentOptions
  extends Omit<
    Apollo.UseFragmentOptions<
      BoardCommentPermissionsFragment,
      Apollo.OperationVariables
    >,
    'fragment' | 'fragmentName' | 'from'
  > {
  from: Apollo.StoreObject | Apollo.Reference;
  returnPartialData?: boolean;
}

interface UseBoardCommentPermissionsFragmentResult
  extends Omit<
    Apollo.UseFragmentResult<BoardCommentPermissionsFragment>,
    'data'
  > {
  data?: BoardCommentPermissionsFragment;
}

export const useBoardCommentPermissionsFragment = ({
  from,
  returnPartialData,
  ...options
}: UseBoardCommentPermissionsFragmentOptions): UseBoardCommentPermissionsFragmentResult => {
  const result = Apollo.useFragment<BoardCommentPermissionsFragment>({
    ...options,
    fragment: BoardCommentPermissionsFragmentDoc,
    fragmentName: 'BoardCommentPermissions',
    from: {
      __typename: 'Board',
      ...from,
    },
  });

  // Ensure that the fragment result is not typed as a DeepPartial.
  if (!result.complete && !returnPartialData) {
    return {
      ...result,
      data: undefined,
    };
  }

  return { ...result, data: result.data as BoardCommentPermissionsFragment };
};
