import type { FunctionComponent } from 'react';
import { useEffect, useRef } from 'react';
import type { Picker as EmojiMartPicker } from 'emoji-mart';

import { ChunkLoadErrorBoundary } from '@trello/error-boundaries';
import type { EmojiTestIds } from '@trello/test-ids';
import { getTestId } from '@trello/test-ids';
import { importWithRetry } from '@trello/use-lazy-component';

import type { EmojiPickerProps } from './EmojiPicker';

type EmojiMartPickerDiv = EmojiMartPicker & HTMLDivElement;

// this is a modified version of @emoji-mart/react to avoid lazy loading two packages
export const LazyEmojiMartPicker: FunctionComponent<EmojiPickerProps> = (
  props,
) => {
  const ref = useRef<EmojiMartPickerDiv>(null);
  const instance = useRef<EmojiMartPickerDiv | null>(null);
  const lazyLoadPicker = async () => {
    const { Picker } = await importWithRetry(
      () => import(/* webpackChunkName: "emoji-mart" */ 'emoji-mart'),
    );

    const emPicker = new Picker({ ...props, ref });

    instance.current = emPicker as EmojiMartPickerDiv;
  };

  useEffect(() => {
    lazyLoadPicker();

    return () => {
      instance.current = null;
    };
  });

  if (instance.current) {
    instance.current.update(props);
  }

  return (
    <ChunkLoadErrorBoundary fallback={null}>
      <div
        data-testid={getTestId<EmojiTestIds>('emoji-mart-picker')}
        ref={ref}
      />
    </ChunkLoadErrorBoundary>
  );
};
