import type { Ref } from 'react';
import { forwardRef } from 'react';
import classNames from 'classnames';

import type { TooltipPrimitiveProps } from '@atlaskit/tooltip';
import { TooltipPrimitive } from '@atlaskit/tooltip';

import styles from './TooltipPrimitiveLight.less';

export const TooltipPrimitiveLight = forwardRef(
  (props: TooltipPrimitiveProps, ref: Ref<HTMLDivElement>) => {
    const { className, ref: wrongRef, ...rest } = props;
    return (
      <TooltipPrimitive
        // eslint-disable-next-line @atlaskit/design-system/no-unsafe-style-overrides
        className={classNames(className, styles.tooltipPrimitiveLight)}
        ref={ref}
        {...rest}
      />
    );
  },
);
