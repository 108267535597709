import type { TriggeredCommand } from '@atlassian/butler-command-parser';
import type { SourceType } from '@trello/atlassian-analytics';
import { Analytics } from '@trello/atlassian-analytics';

import { switchCurrentBoardView } from 'app/src/components/Board/switchCurrentBoardView';
import { getTriggerMetadata } from './CommandMetadata';

// eslint-disable-next-line no-restricted-syntax
export enum ButlerTab {
  Rules = 'rules',
  BoardButton = 'board-buttons',
  CardButton = 'card-buttons',
  Schedule = 'schedule',
  DueDate = 'on-dates',
}

export const getButlerTabForRule = (
  command: TriggeredCommand,
): ButlerTab | undefined => {
  const commandType = getTriggerMetadata(command.TRIGGER)?.commandType;
  switch (commandType) {
    case 'rule':
      return ButlerTab.Rules;
    case 'schedule':
      return ButlerTab.Schedule;
    case 'on-date':
      return ButlerTab.DueDate;
    default:
      return;
  }
};

export const ButlerNewCommandId = 'new';

export const showButlerDirectory = async (
  source: SourceType,
  butlerTab?: ButlerTab,
  butlerCmdEdit?: string,
): Promise<void> => {
  // taskSucceeded will be called in setButlerView
  const taskId = Analytics.startTask({
    taskName: 'view-butlerDashboard',
    source,
  });
  try {
    switchCurrentBoardView({
      routeParams: {
        view: 'butler',
        tab: butlerTab,
        screen: butlerCmdEdit ? 'edit' : undefined,
      },
      navigateOptions: {
        trigger: true,
      },
      searchParams: {
        source,
        taskId,
      },
    });
  } catch (error) {
    Analytics.taskFailed({
      taskName: 'view-butlerDashboard',
      traceId: taskId,
      source,
      error,
    });
  }
};
