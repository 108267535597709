// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.n_G1K_4N_AxpIH {
  box-sizing: content-box;
  max-width: 300px;
  max-height: 300px;
  padding: 8px 12px;
  border-radius: 4px;
  background: var(--ds-surface-overlay, #ffffff);
  box-shadow: var(--ds-shadow-raised, 0px 1px 1px #091e4240, 0px 0px 1px #091e424f);
  color: var(--ds-text, #172b4d);
}
`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"tooltipPrimitiveLight": `n_G1K_4N_AxpIH`
};
export default ___CSS_LOADER_EXPORT___;
