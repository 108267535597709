// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ZDRpPFIRhxUte2 {
  padding-bottom: 12px;
}
.pop-over-content .ZDRpPFIRhxUte2 {
  padding-bottom: 0;
}
.RFfGTgjAr1Yi60 {
  top: 0;
  right: 0;
  padding: 10px 12px 10px 8px;
  position: absolute;
  z-index: 2;
}
.RFfGTgjAr1Yi60:hover {
  filter: brightness(75%);
}
.FSNVMjwRhsUdPP {
  top: 0;
  left: 0;
  padding: 10px 8px 10px 12px;
  position: absolute;
  z-index: 2;
}
.FSNVMjwRhsUdPP:hover {
  filter: brightness(75%);
}
.jff15rhakwA0aM {
  display: block;
  position: relative;
  z-index: 1;
  box-sizing: border-box;
  margin: 0 0 6px 0;
  padding: 0 32px;
  overflow: hidden;
  border-bottom: 1px solid var(--ds-border, #091e4224);
  color: var(--ds-text-subtle, #44546f);
  line-height: 40px;
  text-align: center;
  text-overflow: ellipsis;
  white-space: nowrap;
}
`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `ZDRpPFIRhxUte2`,
	"closeButton": `RFfGTgjAr1Yi60`,
	"backButton": `FSNVMjwRhsUdPP`,
	"title": `jff15rhakwA0aM`
};
export default ___CSS_LOADER_EXPORT___;
